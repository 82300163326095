/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react'
import {
  TextField,
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
} from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import PropTypes from 'prop-types'

const MDInput = ({
  fullWidth,
  isPassword,
  onClick,
  visibility,
  margin = '',
  ...props
}) => {
  return (
    <>
      {isPassword ? (
        <FormControl fullWidth variant="outlined" size="small" margin="none">
          <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
          <TextField
            {...props}
            autoComplete="off"
            margin={margin}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={onClick}
                  onMouseDown={event => event.preventDefault()}
                  edge="end"
                >
                  {visibility ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      ) : (
        <TextField {...props} size="small" autoComplete="off" />
      )}
    </>
  )
}
MDInput.defaultProps = {
  fullWidth: false,
  isPassword: false,
  visibility: false,
  onClick: () => 0,
  margin: 'none',
}
MDInput.propTypes = {
  fullWidth: PropTypes.bool,
  isPassword: PropTypes.bool,
  visibility: PropTypes.bool,
  margin: PropTypes.string,
  onClick: PropTypes.func,
  props: PropTypes.shape(),
}

export default MDInput
