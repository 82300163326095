import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { lineups as lineupsModule, matches } from 'redux/modules'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import { InnrHead } from 'containers'
import { Button, Grid, Typography } from '@material-ui/core'
import Input from 'components/inputes/Input'
import { Field, reduxForm } from 'redux-form'
import { compose } from 'redux'
import createJoiValidator from 'utils/createJoiValidator'
import Joi from 'joi'
import PropTypes from 'prop-types'

const validationSchema = Joi.object().keys({
  title: Joi.string().max(255).required(),
})

const AddLineupDialog = ({
  open,
  handleClose,
  saveMatchLineup,
  teamUuid,
  currentMatchUuid,
  handleSubmit,
  updateMatch,
  lineups,
}) => {
  const [titleError, setTitleError] = useState(false)
  const handleSaveLineup = (values) => {
    // Check lineups with same title if existed then show error and return. Otherwise save lineup
    const isExist = lineups.some(
      (lineup) => lineup.group === '%TEAM%' && lineup.title === values.title
    )
    if (isExist) {
      setTitleError(true)

      return
    }

    saveMatchLineup(teamUuid, {
      match_uuid: currentMatchUuid,
      title: values.title,
    }).then((res) => {
      const { data } = res.response

      handleClose()

      // Update match lineup on new custom lineup save.
      // Also with this match lineup will automatically switched to new saved lineup in UI
      updateMatch(currentMatchUuid, {
        lineup_uuid: data.uuid,
        preventToastNotification: true,
      })
    })
  }

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="xs" fullWidth>
      <InnrHead title={<Translate id="matches.save-lineup" />}>
        <IconButton onClick={handleClose} size="small">
          <CloseIcon />
        </IconButton>
      </InnrHead>
      <DialogContent>
        <form onSubmit={handleSubmit(handleSaveLineup)}>
          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item xs={12}>
              <Field
                name="title"
                label={<Translate id="articles.title" />}
                component={Input}
                margin="dense"
                onChange={() => setTitleError(false)}
              />
            </Grid>
            {titleError && (
              <Grid item xs={12}>
                <Typography variant="caption" color="error">
                  <Translate id="matches.lineup-title-error" />
                </Typography>
              </Grid>
            )}
            <Grid item>
              <Button color="primary" onClick={handleClose}>
                <Translate id="button.cancel" />
              </Button>
              <Button variant="contained" color="primary" type="submit">
                <Translate id="button.save" />
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  )
}
AddLineupDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  teamUuid: PropTypes.string.isRequired,
  currentMatchUuid: PropTypes.string.isRequired,
  saveMatchLineup: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  updateMatch: PropTypes.func.isRequired,
  lineups: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default compose(
  connect(
    ({ matches: { current }, team, lineups }) => ({
      currentMatchUuid: current.uuid,
      teamUuid: team.uuid,
      lineups: lineups.items,
    }),
    {
      saveMatchLineup: lineupsModule.saveMatchLineup,
      updateMatch: matches.updateMatch,
    }
  ),
  reduxForm({
    form: 'addLineupForm',
    validate: createJoiValidator(validationSchema),
  })
)(AddLineupDialog)
