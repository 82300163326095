import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { drillsCategories } from 'redux/modules'
import Loader from './Loader'

const DrillCategoriesLoader = ({
  children,
  club,
  clubId,
  fetchDrillsCategories,
}) => {
  return (
    <Loader fetchMethod={() => fetchDrillsCategories(clubId)}>
      {children}
    </Loader>
  )
}

DrillCategoriesLoader.propTypes = {
  fetchDrillsCategories: PropTypes.func.isRequired,
}

export default connect(
  ({ clubs }) => ({
    club: clubs.current,
  }),
  {
    fetchDrillsCategories: drillsCategories.fetchDrillsCategories,
  }
)(DrillCategoriesLoader)
