import React, { useState } from 'react'
import { Box, Avatar, IconButton } from '@material-ui/core'
import ImageUploading from 'react-images-uploading'
import { useStyles } from './styled'
import {
  PhotoLibraryOutlined as PhotoLibraryOutlinedIcon,
  DeleteOutlineOutlined as DeleteOutlineOutlinedIcon,
} from '@material-ui/icons'
import EditIcon from '@material-ui/icons/Edit'
import PropTypes from 'prop-types'
import propTypes from 'constants/propTypes'

const AvatarUploader = ({
  images,
  onChange,
  maxNumber,
  imageCompression,
  acceptType,
  maxFileSize,
  srcUrl,
  onImageUpdate,
  onImageRemove,
}) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      {images.length === 0 && (
        <Box className={classes.avatarupload}>
          <Avatar alt="logo" className={classes.large} src={srcUrl} />
        </Box>
      )}
      <ImageUploading
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        imageCompression={imageCompression}
        maxFileSize={maxFileSize}
        acceptType={acceptType}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageUpdate: handleImageUpdate,
          onImageRemove: handleImageRemove,
        }) => (
          <>
            <Box className={classes.editiconwrap}>
              {imageList.length === 0 && (
                <IconButton onClick={onImageUpload}>
                  <EditIcon />
                </IconButton>
              )}
            </Box>

            {imageList.map((image, index) => (
              <Box className={classes.avatarupload} key={index}>
                <Avatar
                  alt={image.data_url}
                  className={classes.large}
                  src={image.data_url}
                />

                <Box>
                  <IconButton
                    onClick={() =>
                      onImageUpdate
                        ? onImageUpdate(index)
                        : handleImageUpdate(index)
                    }
                  >
                    <PhotoLibraryOutlinedIcon />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      onImageRemove
                        ? onImageRemove(index)
                        : handleImageRemove(index)
                    }
                  >
                    <DeleteOutlineOutlinedIcon />
                  </IconButton>
                </Box>
              </Box>
            ))}
          </>
        )}
      </ImageUploading>
    </Box>
  )
}
AvatarUploader.defaultProps = {
  maxNumber: 1,
  maxFileSize: 5 * 1024 * 1024, //MB
  imageCompression: 0.8,
  acceptType: ['jpg', 'gif', 'png', 'jpeg'],
  onImageUpdate: null,
  onImageRemove: null,
}
AvatarUploader.propTypes = {
  images: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  srcUrl: PropTypes.node.isRequired,
  acceptType: PropTypes.array,
  maxNumber: PropTypes.number,
  imageCompression: PropTypes.number,
  maxFileSize: PropTypes.number,
  onImageUpdate: PropTypes.func,
  onImageRemove: PropTypes.func,
}
export default AvatarUploader
