import { store } from '../redux/store'
import {
  itErrors,
  enErrors,
  deErrors,
  esErrors,
  plErrors,
  jpErrors,
} from 'constants/joiValidationErrors'

export default (schema) => (values) => {
  // Get locale from redux store
  const state = store.getState()
  const { locale } = state.auth

  // Options object for Joi validation with custom error messages
  const options = {
    errors: {
      language: locale,
    },
    messages: {
      it: { ...itErrors },
      en: { ...enErrors },
      de: { ...deErrors },
      es: { ...esErrors },
      pl: { ...plErrors },
      jp: { ...jpErrors },
    },
  }
  // Validate the provided values against the Joi schema with custom messages
  const result = schema.validate(values, { ...options, abortEarly: false })

  if (result.error === null) {
    return {}
  }

  // If there are validation errors, map them to a more readable format
  return result.error
    ? result.error.details.reduce((errors, { path, message }) => {
        // Extract the key from the path (e.g., 'name' from ['name'])
        const key = path[path.length - 1]

        // Combine existing error message with the new message
        const customMessage = errors[key]
          ? `${errors[key]} ${message}`
          : message

        return {
          ...errors,
          [key]: customMessage.replace(/"/g, ''), // Remove double quotes from the message
        }
      }, {})
    : null
}
