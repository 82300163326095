import React from 'react'
import { Translate } from 'react-localize-redux'
import { Divider } from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'
import PaymentIcon from '@material-ui/icons/Payment'
import FavoriteIcon from '@material-ui/icons/Favorite'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import SwapHoriz from '@material-ui/icons/SwapHoriz'
import { Link } from 'react-router-dom'
import { styled } from '@material-ui/core/styles'
import  NavLink from './components/NavLink'
import {
  TEAMS_ROUTE,
  FAVORITES_ROUTE,
  TEAM_ACCESS_ROUTE,
  TEAM_SUBSCRIPTION_UPGRADE_ROUTE,
  LOGOUT_ROUTE,
  USER_ROUTE,
} from 'constants/routes'


const StyledLink = styled(Link)(({ theme }) => ({
'&:hover': {
  textDecoration: 'none',
  color: theme.palette.primary.main,
}
}))

export default () => [
  {
    LinkComponent: NavLink,
    linkTo: USER_ROUTE,
    icon: <PersonIcon />,
    text: <Translate id="navbar.profile"/>,
    divider: <Divider />
  },
  {
    LinkComponent: NavLink,
    linkTo: TEAM_ACCESS_ROUTE,
    icon: <GroupAddIcon/>,
    text: <Translate id="userRoles.team-access"/>,
    divider: <Divider />
  },
  {
    LinkComponent: StyledLink,
    linkTo: TEAMS_ROUTE,
    icon: <SwapHoriz/>,
    text: <Translate id="navbar.my_teams"/>,
    divider: <Divider />
  },
  {
    LinkComponent: NavLink,
    linkTo: TEAM_SUBSCRIPTION_UPGRADE_ROUTE,
    icon: <PaymentIcon/>,
    text: <Translate id="navbar.pricing_billing"/>,
    divider: <Divider />
  },
  {
    LinkComponent: NavLink,
    linkTo: FAVORITES_ROUTE,
    icon: <FavoriteIcon/>,
    text: <Translate id="navbar.favorites"/>,
    divider: <Divider/>
  },
  {
    LinkComponent: StyledLink,
    linkTo: LOGOUT_ROUTE,
    icon: <ExitToAppIcon/>,
    text: <Translate id="navbar.logout"/>,
    divider:<Divider />
  },
]
