import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  Grid,
  DialogActions,
} from '@material-ui/core'
import { fetchSeasonTrainings } from 'containers/ActivitiesContainer/api'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import LoadingSpinner from 'components/loader/LoadingSpinner'
import CloseIcon from '@material-ui/icons/Close'
import {
  formatFullDateWithDayAndUserDateFormat,
  formatUtcDateToDateTime,
  formatDateTimeToUtcDateTimeWithUserTimezone,
  formateDate,
  getWeekStartAndEndByDate,
  SERVER_DATETIME,
} from 'utils/formatDate'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import DateRangeIcon from '@material-ui/icons/DateRange'
import moment from 'moment'
import { Translate } from 'react-localize-redux'
import { InnrHead } from 'containers'
import CreateTrainingDialog from 'components/DrillCard/components/CreateTrainingDialog'
import TrainingSessionRow from './TrainingSessionRow'
import { DialogContent } from './StyledComponents'

const AddTrainingPackageToTrainingDialog = ({
  open,
  handleClose,
  trainingPackage,
  match: {
    params: { seasonId },
  },
  seasons,
  timezone,
  dateTimeFormat,
}) => {
  const [startOfWeek, setStartOfWeek] = useState()
  const [endOfWeek, setEndOfWeek] = useState()
  const [trainings, setTrainings] = useState([])
  const [seasonUuid, setSeasonUuid] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isCurrentWeek, setIsCurrentWeek] = useState(false)
  const [currentStartDate, setCurrentStartDate] = useState()
  const [currentEndDate, setCurrentEndDate] = useState()
  const [error, setError] = useState(null)
  const [createTrainingModalOpen, setCreateTrainingModalOpen] = useState(false)
  const [createdTrainingUuid, setCreatedTrainingUuid] = useState(null)

  const handleCreateTrainingDialogOpen = () => {
    setCreateTrainingModalOpen(!createTrainingModalOpen)
  }

  const setWeekDates = () => {
    setCurrentStartDate(startOfWeek)
    setCurrentEndDate(endOfWeek)
  }

  const handleResetWeek = () => {
    setWeekDates()
  }

  const calculateWeekDates = (weeks) => {
    const newStartDate = moment(currentStartDate)
      .startOf('isoWeek')
      .add(weeks, 'weeks')
      .format(SERVER_DATETIME)
    const newEndDate = moment(currentEndDate)
      .endOf('isoWeek')
      .add(weeks, 'weeks')
      .format(SERVER_DATETIME)
    setCurrentStartDate(newStartDate)
    setCurrentEndDate(newEndDate)
  }

  const handleNextWeek = () => {
    calculateWeekDates(1)
  }

  const handlePreviousWeek = () => {
    calculateWeekDates(-1)
  }

  const fetchTrainings = () => {
    setIsLoading(true)
    setError(null)

    const params = {
      from_datetime: formatDateTimeToUtcDateTimeWithUserTimezone(
        currentStartDate,
        timezone
      ),
      to_datetime: formatDateTimeToUtcDateTimeWithUserTimezone(
        currentEndDate,
        timezone
      ),
    }

    fetchSeasonTrainings(seasonUuid, params)
      .then((response) => {
        const trainingData = response.data
          .filter((item) => item.type === 'training')
          .map((item) => ({
            date: formatFullDateWithDayAndUserDateFormat(
              item.event.start_datetime,
              dateTimeFormat
            ),
            time: formatUtcDateToDateTime(
              item.event.start_datetime,
              timezone
            )[1],
            focus: item.event.focus,
            location: item.event.location,
            intensity: item.event.intensity,
            uuid: item.event.uuid,
          }))
        setTrainings(trainingData)
      })
      .catch((err) => {
        console.error('Failed to fetch trainings:', err)
        setError('Something went wrong!')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleCreateTrainingClose = (training = null) => {
    // Handle create training dialog box close after training created
    if (training) {
      const { startOfWeek: weekStart, endOfWeek: weekEnd } =
        getWeekStartAndEndByDate(training.date)

      if (weekStart === currentStartDate && weekEnd === currentEndDate) {
        fetchTrainings()
      } else {
        setCurrentStartDate(weekStart)
        setCurrentEndDate(weekEnd)
      }
      setCreatedTrainingUuid(training.uuid)
    }

    // Close the dialog box
    setCreateTrainingModalOpen(false)
  }

  useEffect(() => {
    const { startOfWeek: weekStart, endOfWeek: weekEnd } =
      getWeekStartAndEndByDate(moment())

    if (startOfWeek && endOfWeek) {
      setWeekDates()
    } else {
      setStartOfWeek(weekStart)
      setEndOfWeek(weekEnd)
    }
  }, [startOfWeek, endOfWeek])

  useEffect(() => {
    if (open && seasonUuid && currentStartDate && currentEndDate) {
      if (currentEndDate > currentStartDate) {
        fetchTrainings()
      }

      if (
        moment().format(SERVER_DATETIME) >= currentStartDate &&
        moment().format(SERVER_DATETIME) <= currentEndDate
      ) {
        setIsCurrentWeek(true)
      } else {
        setIsCurrentWeek(false)
      }
    }
  }, [open, seasonUuid, currentStartDate, currentEndDate])

  // @todo Once season uuid can be accessed from url this can be removed
  useEffect(() => {
    if (seasons) {
      const season = seasons.find((s) => s.id === +seasonId)
      setSeasonUuid(season.uuid)
    }
  }, [seasons])

  return (
    <>
      {createTrainingModalOpen && (
        <CreateTrainingDialog
          open={createTrainingModalOpen}
          handleClose={handleCreateTrainingClose}
        />
      )}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
        fullWidth
      >
        <InnrHead title={<Translate id="training.choose-training-title" />}>
          <IconButton onClick={handleClose} size="small">
            <CloseIcon />
          </IconButton>
        </InnrHead>
        <DialogContent dividers>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={1}>
              {!isCurrentWeek && (
                <IconButton onClick={handleResetWeek}>
                  <DateRangeIcon fontSize="small" />
                </IconButton>
              )}
            </Grid>
            <Grid item>
              <Grid
                container
                alignItems="center"
                spacing={1}
                justifyContent="center"
              >
                <Grid item>
                  <IconButton onClick={handlePreviousWeek}>
                    <NavigateBeforeIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography variant="body1">
                    {`${formateDate(currentStartDate)[3]} ${
                      formateDate(currentStartDate)[4]
                    }`}{' '}
                    -{' '}
                    {`${formateDate(currentEndDate)[3]} ${
                      formateDate(currentEndDate)[4]
                    }`}
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={handleNextWeek}>
                    <NavigateNextIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={handleCreateTrainingDialogOpen}
              >
                <Translate id="button.create-plan-training" />
              </Button>
            </Grid>
          </Grid>
          <Box overflow="auto">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h4" component="h4">
                      <Translate id="training.date" />
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h4" component="h4">
                      <Translate id="training.time" />
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h4" component="h4">
                      <Translate id="training.focus" />
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h4" component="h4">
                      <Translate id="training.location" />
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h4" component="h4">
                      <Translate id="training.intensity" />
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!isLoading && trainings.length > 0 && (
                  <>
                    {trainings.map((training) => (
                      <TrainingSessionRow
                        key={training.uuid}
                        training={training}
                        trainingPackage={trainingPackage}
                        defaultOpened={training.uuid === createdTrainingUuid}
                        setCreatedTrainingUuid={setCreatedTrainingUuid}
                      />
                    ))}
                  </>
                )}
              </TableBody>
            </Table>
            {!isLoading && !error && trainings.length === 0 && (
              <Box py={5} textAlign="center">
                <Typography variant="body1">
                  <Translate id="training.no-trainings-message" />
                </Typography>
              </Box>
            )}
            {isLoading && <LoadingSpinner />}
            {error && (
              <Box py={5} textAlign="center">
                <Typography variant="body1">{error}</Typography>
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button onClick={handleClose} variant="outlined">
              <Translate id="button.done" />
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  )
}

AddTrainingPackageToTrainingDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  trainingPackage: PropTypes.shape().isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      seasonId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  seasons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      uuid: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  timezone: PropTypes.string.isRequired,
  dateTimeFormat: PropTypes.string.isRequired,
}

export default compose(
  connect(({ team, auth, seasons: { items } }) => ({
    team,
    seasons: items[team.id],
    timezone: auth.user.time_zone,
    dateTimeFormat: auth.user.datetime_format,
  })),
  withRouter
)(AddTrainingPackageToTrainingDialog)
