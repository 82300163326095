/**
 * This component is responsible for rendering specific match task groups
 *
 * @todo Adjust this with training and other categories too and then rename this component too.
 */

import React, { useEffect, useState } from 'react'
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { AvatarGroup } from '@material-ui/lab'
import { Translate } from 'react-localize-redux'
import { taskGroups as tasksModule } from 'redux/modules'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import AlertDialog from 'components/AlertDialog'
import SubLink from 'containers/SubLink'
import { LoadingSpinner } from 'components'
import AddTaskGroupDialog from './AddTaskGroupDialog'
import PROP_TYPES from 'constants/propTypes'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: 'auto',
    height: 204,
    padding: theme.spacing(0),
    '& .MuiListItemSecondaryAction-root': {
      right: 12,
    },
    '& .MuiListItemIcon-root': {
      minWidth: 15,
    },
    '& .MuiAvatarGroup-avatar': {
      width: theme.spacing(3),
      height: theme.spacing(3),
      fontSize: 10,
    },
  },
  colorBadge: {
    width: 5,
    height: 5,
    borderRadius: 50,
    backgroundColor: theme.palette.primary.main,
  },
  listItemText: {
    maxWidth: '50%',
  },
  listItemTextEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}))

const MatchTaskGroupsWidget = ({
  deleteTaskGroup,
  players,
  teamTaskGroups,
  loader,
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [taskGroup, setTaskGroup] = useState(null)
  const [openAlert, setOpenAlert] = useState(false)
  const [deleteTaskId, setDeleteTaskId] = useState(false)
  const [taskGroupsState, setTaskGroupsState] = useState([])
  const [isLoading, setLoading] = useState(false)

  const handleOpen = (selectedTaskGroup) => {
    setTaskGroup(selectedTaskGroup)
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    setOpenAlert(false)
  }
  const handleAlertOpen = () => {
    setOpenAlert(true)
  }
  const openAlertBox = (taskId) => {
    if (taskId) {
      setDeleteTaskId(taskId)
      handleAlertOpen()
    }
  }
  const handleDelete = () => {
    deleteTaskGroup(deleteTaskId)
    setOpenAlert(false)
  }

  useEffect(() => {
    setLoading(true)
    loader().then(() => {
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    if (players) {
      setTaskGroupsState(
        teamTaskGroups.map((t) => {
          return {
            ...t,
            assignees: t.model_ids
              .map((model) => players.find((player) => player.id === +model))
              .filter((m) => m !== undefined),
          }
        })
      )
    }
  }, [teamTaskGroups, players])

  return (
    <Card>
      {open && (
        <AddTaskGroupDialog
          open={open}
          onClose={handleClose}
          selectedTaskGroup={taskGroup}
          isMatchEvent
        />
      )}
      <CardHeader
        title={
          <Typography variant="h4" component="h4">
            <Translate id="task-management.title" />
          </Typography>
        }
        action={
          <>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => handleOpen(null)}
            >
              <Translate id="task-management.add-task" />
            </Button>
          </>
        }
      />
      <CardContent className={classes.root}>
        <List>
          {isLoading && <LoadingSpinner />}
          {!isLoading && taskGroupsState.map((element) => (
            <ListItem key={element.id} dense="true" disablePadding="true">
              <ListItemIcon>
                <Box component="span" className={classes.colorBadge} />
              </ListItemIcon>
              <ListItemText
                className={classes.listItemText}
                primary={
                  <Tooltip title={element.title} placement="top" arrow>
                    <Box className={classes.listItemTextEllipsis}>
                      <SubLink to={`/task-group/${element.id}`}>
                        {element.title}
                      </SubLink>
                    </Box>
                  </Tooltip>
                }
              />
              <ListItemSecondaryAction>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Box>
                    <Tooltip title="Assignee" placement="top" arrow>
                      <AvatarGroup>
                        {element?.assignees?.slice(0, 2).map((player) => (
                          <Avatar
                            key={player?.id}
                            alt={player?.first_name}
                            src={player?.avatar?.full_url}
                          />
                        ))}
                      </AvatarGroup>
                    </Tooltip>
                  </Box>
                  <Box>
                    {element?.assignees.length > 2
                      ? `+${element?.assignees.length - 2}`
                      : ''}
                  </Box>
                  <Box>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={() => handleOpen(element)}
                      disabled={element.completed}
                    >
                      <Tooltip
                        title={<Translate id="button.edit" />}
                        placement="top"
                        arrow
                      >
                        <EditIcon fontSize="small" />
                      </Tooltip>
                    </IconButton>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => openAlertBox(element.id)}
                    >
                      <Tooltip
                        title={<Translate id="button.delete" />}
                        placement="top"
                        arrow
                      >
                        <DeleteIcon fontSize="small" />
                      </Tooltip>
                    </IconButton>
                  </Box>
                </Box>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </CardContent>
      {openAlert && (
        <AlertDialog
          open={openAlert}
          onClose={handleClose}
          onSuccess={handleDelete}
          onCancel={handleClose}
          title={<Translate id="button.delete" />}
          subTitle={<Translate id="events.are-you-sure-you-want-to-delete" />}
          submitButtonLabel={<Translate id="button.ok" />}
          cancelButtonLabel={<Translate id="button.close" />}
        />
      )}
    </Card>
  )
}

MatchTaskGroupsWidget.propTypes = {
  teamTaskGroups: PROP_TYPES.arrayOf(PROP_TYPES.shape().isRequired).isRequired,
  deleteTaskGroup: PROP_TYPES.func.isRequired,
  players: PROP_TYPES.arrayOf(PROP_TYPES.shape().isRequired).isRequired,
  loader: PROP_TYPES.func.isRequired,
}
export default compose(
  withRouter,
  connect(
    ({ taskGroups, players }) => ({
      teamTaskGroups: taskGroups.items,
      players: players.items,
    }),
    {
      deleteTaskGroup: tasksModule.deleteTaskGroup,
    }
  )
)(MatchTaskGroupsWidget)
