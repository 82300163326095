import React from 'react'
import { connect } from 'react-redux'
import { LineupsLoader } from 'loaders'
import MatchDashboardLayout from './MatchDashboardLayout'
import PROP_TYPES from 'constants/propTypes'

const MatchDashboardContainer = ({ currentMatch }) => {
  return (
    <>
      {currentMatch && (
        <LineupsLoader>
          <MatchDashboardLayout />
        </LineupsLoader>
      )}
    </>
  )
}

MatchDashboardContainer.propTypes = {
  currentMatch: PROP_TYPES.shape().isRequired,
}
export default connect(
  ({ matches: { current } }) => ({
    currentMatch: current,
  }),
  {}
)(MatchDashboardContainer)
