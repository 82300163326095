import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { matches } from 'redux/modules'
import Loader from './Loader'
import PROP_TYPES from 'constants/propTypes'

const MatchesLoader = props => {
  const {
    children,
    fetchSeasonMatches,
    match: {
      params: { seasonId },
    },
    ...restProps
  } = props

  const fetchMethod = () => fetchSeasonMatches(seasonId)
  const triggers = [seasonId]

  return (
    <Loader fetchMethod={fetchMethod} triggers={triggers} {...restProps}>
      {children}
    </Loader>
  )
}

MatchesLoader.propTypes = {
  children: PROP_TYPES.children.isRequired,
  fetchSeasonMatches: PropTypes.func.isRequired,
  match: PROP_TYPES.match.isRequired,
}

export default compose(
  withRouter,
  connect(
    null,
    { fetchSeasonMatches: matches.fetchSeasonMatches }
  )
)(MatchesLoader)
