import { styled } from '@material-ui/core/styles';

export const Wrapper = styled('div')({
  display: 'flex',
})

export const Navigation = styled('div')({
  '.video-mini-calendar &': {
    fontSize: 16,
  },
});

export const NavPrev = styled('div')({
  height: 20,
  width: 20,
  margin: '13px 0',
  cursor: 'pointer',
  '& img': {
    width: '100%',
  },
  '&:after': {
    marginLeft: '1.5rem',
    fontSize: '1rem',
    fontWeight: 400,
  },
});

export const NavNext = styled('div')({
  height: 20,
  width: 20,
  margin: '13px 0',
  cursor: 'pointer',
  '& img': {
    width: '100%',
  },
  '&:before': {
    marginLeft: '-1.8rem',
    fontSize: '1rem',
    fontWeight: 400,
  },
});

export const Items = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '0 10px',
  '@media (max-width: 640px)': {
    padding: '0 2px',
  },
});

export const Item = styled('div')(({ status, isAnotherMonth, theme, isToday, isCurrent }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 68,
  height: 45,
  margin: '0 7px',
  padding: '0 7px',
  borderWidth: 0,
  borderStyle: status === 'draft' ? 'dashed' : 'solid',
  borderColor: isAnotherMonth
    ? 'rgba(255, 255, 255, 0.38)'
    : theme.palette.primary.main,
  color: isAnotherMonth ? 'rgba(0,0,0,0.78)' : '#333',
  fontWeight: 600,
  letterSpacing: 0.1,
  cursor: 'pointer',
  backgroundColor: 'transparent',
  borderBottom: isToday ? `2px solid ${theme.palette.primary.main}` : '',
  fontWeight: isToday ? 'bold' : '',
  borderBottom: isCurrent ? `2px solid ${theme.palette.primary.main}` : '',
  fontWeight: isCurrent ? 'bold' : '',
  '.video-mini-calendar &': {
    width: 30,
    height: 30,
  },
  '@media (max-width: 640px)': {
    margin: '0 3px',
    fontSize: '0.8rem',
    padding: '0 3px',
    width: 50,
  },
}));
