import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { videos as videosModule } from 'redux/modules'

const CheckNotProcessedVideoSequences = ({
  children,
  videoId,
  video,
  fetchVideo,
}) => {
  const [isChecking, changeIsChecking] = useState(false)

  let isMounted = true
  // TODO: dose not check right, stop after a few checks
  useEffect(() => {
    if (!isChecking) {
      if (
        video.sequences
          .filterById('new')
          .some(({ processed }) => processed === 0 || processed === false)
      ) {
        changeIsChecking(true)

        setTimeout(() => {
          fetchVideo(videoId, { fields: 'sequences' }).then(() => {
            if (isMounted) {
              changeIsChecking(false)
            }
          })
        }, 30000)
      } else {
        changeIsChecking(false)
      }
    }

    return () => {
      isMounted = false
    }
  }, [video.sequences, isChecking])

  return children
}

export default connect(
  ({ videos }) => ({ video: videos.current }),
  {
    fetchVideo: videosModule.fetchVideo,
  }
)(CheckNotProcessedVideoSequences)
