import React from 'react'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import PublishIcon from '@material-ui/icons/Publish'
import { useDropzone } from 'react-dropzone'
import { Box, Typography, Paper } from '@material-ui/core'
import { DroppableArea } from 'components'
import { videos as videosModule } from 'redux/modules'
import { InnrHead } from 'containers'
import VideosList from './VideosList'
import PROP_TYPES from 'constants/propTypes'

const VIDEO_MAX_SIZE = 10240 * 1024 * 1024 // 10GB
const ALLOWED_ACCEPT = [
  '.mp4',
  '.mov',
  '.avi',
  '.mpeg',
  '.mpg',
  '.wmv',
  '.vob',
  '.mts',
]

const NotCompleted = ({ videos, changeVideoUploadProgress, uploadVideo }) => {
  const onProgress = event =>
    changeVideoUploadProgress(Math.ceil((event.loaded / event.total) * 100))

  const onDropAccepted = acceptedFiles => {
    acceptedFiles.forEach(file => {
      uploadVideo({ video: file }, onProgress).catch(
        ({ errors: { video } }) => {
          const errorMessage = video.find(Boolean) || ''

          toast(errorMessage)
        }
      )
    })
  }

  const onDropRejected = rejectedFiles => {
    const firstFile = rejectedFiles.find(Boolean)

    const fileType = firstFile.path.split('.').pop()

    if (!ALLOWED_ACCEPT.includes(`.${fileType}`)) {
      toast(
        'Only video files .mp4, .mov, .avi, .mpeg, .mpg, .wmv, .vob, .mts supported!'
      )

      return
    }

    if (firstFile.size > VIDEO_MAX_SIZE) {
      toast('Only video files less then 10GB supported!')
    }
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
  } = useDropzone({
    onDropAccepted,
    onDropRejected,
    maxSize: VIDEO_MAX_SIZE,
    accept: ALLOWED_ACCEPT,
    multiple: false,
  })

  const onDragMessage = isDragAccept ? (
    'Drop the files here ...'
  ) : (
    <Typography variant="h6" color="error">
      <Translate id="videos.video-files-supported" />
    </Typography>
  )

  return (
    <Paper>
      <Box {...getRootProps()}>
        <input {...getInputProps()} />
        <DroppableArea relation="notCompleted">
          {isDragActive ? (
            <span>{onDragMessage}</span>
          ) : (
            <>
              <Typography variant="h6">
                <Translate id="videos.choose-video" />
              </Typography>
              <PublishIcon />
            </>
          )}
        </DroppableArea>
      </Box>
      <InnrHead title={<Translate id="videos.not-completed" />} />

      <VideosList videos={videos} relation="notCompleted" />
    </Paper>
  )
}

NotCompleted.defaultProps = {
  videos: [],
}

NotCompleted.propTypes = {
  videos: PROP_TYPES.arrayOfObjects,
  changeVideoUploadProgress: PROP_TYPES.func.isRequired,
  uploadVideo: PROP_TYPES.func.isRequired,
}

export default connect(
  null,
  {
    uploadVideo: videosModule.uploadVideo,
  }
)(NotCompleted)
