import React from 'react'
import { Box, InputAdornment, TextField } from '@material-ui/core'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'

const TimeSection = ({
  existingData,
  setMinute,
  setErrorMinute,
  errorMinute,
  minute,
}) => {

  const validateMinute = (e) => {
    if (e.target.value > 199 || e.target.value < 1) {
      return setErrorMinute(true)
    }
    setMinute(parseInt(e.target.value, 10))
    setErrorMinute(false)

    return true
  }

  return (
        <TextField
          error={errorMinute}
          helperText={
            errorMinute ? (
              <Translate id="matches.highlights.please-enter-minute" />
            ) : (
              ''
            )
          }
          id="textbox-minute"
          label={<Translate id="time.minute" />}
          name="minute"
          variant="outlined"
          type="number"
          margin="dense"
          defaultValue={existingData ? existingData.minute : minute}
          onChange={validateMinute}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <AccessTimeIcon />
              </InputAdornment>
            ),
            inputProps: { min: 0}
          }}
        />
  )
}
TimeSection.defaultProps = {
    existingData:null,
  }
TimeSection.propTypes = {
  existingData: PropTypes.shape(),
  errorMinute: PropTypes.bool.isRequired,
  minute: PropTypes.number.isRequired,
  setErrorMinute: PropTypes.func.isRequired,
  setMinute: PropTypes.func.isRequired,
}
export default TimeSection
