import React from 'react';
import { FormControlLabel, Checkbox as MUICheckbox } from '@material-ui/core';
import PROP_TYPES from 'constants/propTypes';

const Checkbox = ({ id, label, checked, onChange }) => (
  <FormControlLabel
    control={
      <MUICheckbox
        id={id}
        checked={checked}
        onChange={onChange}
        inputProps={{ 'aria-label': label }}
      />
    }
    label={label}
  />
);

Checkbox.defaultProps = {
  checked: false,
};

Checkbox.propTypes = {
  id: PROP_TYPES.id.isRequired,
  label: PROP_TYPES.string.isRequired,
  checked: PROP_TYPES.bool,
  onChange: PROP_TYPES.func.isRequired,
};

export default Checkbox;