import React from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { auth as authModule, themes as themesModule } from 'redux/modules'
import {
  setLocale,
  getLocale,
  getLoginURL,
  setClub,
} from '../utils/localstorage'
import PROP_TYPES from 'constants/propTypes'

const Logout = ({ handleLogout, history, getClubTheme }) => {
  const locale = getLocale()
  const clubData = localStorage.getItem('club')
  localStorage.clear()
  setLocale(locale)
  setClub(JSON.parse(clubData))

  React.useEffect(() => {
    getClubTheme()

    handleLogout().then(() => {
      history.push(getLoginURL())
    })
  }, [])

  return <Redirect to={getLoginURL()} />
}

Logout.propTypes = {
  handleLogout: PROP_TYPES.func.isRequired,
  history: PROP_TYPES.history.isRequired,
  getClubTheme: PROP_TYPES.func.isRequired,
}

export default compose(
  withRouter,
  connect(
    null,
    {
      handleLogout: authModule.logout,
      getClubTheme: themesModule.getClubTheme,
    }
  )
)(Logout)
