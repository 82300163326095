import React, { useCallback } from 'react'
import { Translate } from 'react-localize-redux'
import {
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Chip,
  Divider,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import SequenceModal from './SequenceModal'
import FavoriteIcon from '@material-ui/icons/Favorite'
import { PlayIcon } from 'components'
import { useSequencesStyles, MuiCard, CardActions } from '../styledComponents'
import PropTypes from 'prop-types'

const SequencesCard = ({
  sequence,
  onFavoriteToggle,
  selectedSequence,
  selectedSequenceUuid,
  changeSelectedSequenceUuid,
}) => {
  const onClose = useCallback(() => changeSelectedSequenceUuid(null), [])
  const classes = useSequencesStyles()

  return (
    <>
      {selectedSequenceUuid && (
        <SequenceModal
          onHide={onClose}
          sequence={selectedSequence}
          onFavoriteToggle={onFavoriteToggle}
          open={Boolean(selectedSequenceUuid)}
        />
      )}
      <MuiCard>
        <CardActionArea>
          <CardMedia className={classes.media} image={sequence.thumbnail_url} />

          <CardActionArea className={classes.PlayCircle}>
            <PlayIcon
              onClick={() => changeSelectedSequenceUuid(sequence.uuid)}
            />
          </CardActionArea>
        </CardActionArea>
        <CardContent>
          <Typography
            gutterBottom
            className={classes.title}
            variant="h4"
            component="h4"
            noWrap
          >
            {sequence.title}
          </Typography>
        </CardContent>

        <Divider />
        <CardActions>
          <Box>
            <Chip
              label={sequence.tag}
              size="small"
              color="primary"
              component={Box}
              mr={0.5}
            />
          </Box>
          <Tooltip
            title={<Translate id="videos.favorite" />}
            placement="top"
            arrow
          >
            <IconButton onClick={onFavoriteToggle} size="small">
              <FavoriteIcon
                color={sequence.favorite ? 'primary' : 'default'}
                fontSize="small"
              />
            </IconButton>
          </Tooltip>
        </CardActions>
      </MuiCard>
    </>
  )
}

SequencesCard.propTypes = {
  sequence: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    thumbnail_url: PropTypes.bool.isRequired,
    video_url: PropTypes.string.isRequired,
    tag: PropTypes.string.isRequired,
  }),
  onFavoriteToggle: PropTypes.func.isRequired,
}

export default SequencesCard
