import React, { useState } from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { team } from 'redux/modules'
import { toast } from 'react-toastify'
import CreateTeamForm from 'containers/CreateTeamForm'
import CircularSpinner from 'components/loader/CircularSpinner'
import PROP_TYPES from 'constants/propTypes'

const CreateTeamPopup = ({
  open,
  handleClose,
  selectedClubUuid,
  createTeamByClub,
  ageClasses,
  fetchTeamsByParams,
  setTeams,
  setFilteredTeams,
  commonFetchParams,
  selectedAgeClassFilter,
  handleAgeClassFilterSelect,
}) => {
  const [loader, setLoader] = useState(false)

  const handleSubmit = (values) => {
    setLoader(true)
    const ageClassUuidFromForm = values.age_class_uuid

    createTeamByClub(selectedClubUuid, values)
      .then(() => {
        if (selectedClubUuid) {
          const filters = {
            ...commonFetchParams,
            'filter[club.uuid]': selectedClubUuid,
          }
          fetchTeamsByParams(filters)
            .then((fetchTeamsRes) => {
              setTeams(fetchTeamsRes.response)
              setFilteredTeams(fetchTeamsRes.response)
              if (selectedAgeClassFilter !== ageClassUuidFromForm) {
                handleAgeClassFilterSelect(ageClassUuidFromForm)
              }
            })
            .catch((error) => {
              console.error('Error fetching teams', error)
            })
          setLoader(false)
          handleClose()
        }
      })
      .catch((error) => {
        setLoader(false)
        toast(error.error)
        handleClose()
      })
  }

  return (
    <div>
      {loader ? (
        <CircularSpinner />
      ) : (
        <Translate>
          {({ translate }) => (
            <CreateTeamForm
              form="createTeamForm"
              initialValues={{
                name: '',
                age_class_uuid: '',
                gender: 'm',
              }}
              title={translate('myteams.create-team')}
              open={open}
              onClose={handleClose}
              onSubmit={handleSubmit}
              ageClasses={ageClasses}
            />
          )}
        </Translate>
      )}
    </div>
  )
}

CreateTeamPopup.defaultProps = {
  open: false,
  selectedClubUuid: null,
}

CreateTeamPopup.propTypes = {
  handleClose: PROP_TYPES.func.isRequired,
  open: PROP_TYPES.bool,
  selectedClubUuid: PropTypes.string,
  createTeamByClub: PropTypes.func.isRequired,
  ageClasses: PropTypes.arrayOf.isRequired,
  fetchTeamsByParams: PropTypes.func.isRequired,
  setTeams: PropTypes.func.isRequired,
  setFilteredTeams: PropTypes.func.isRequired,
  commonFetchParams: PropTypes.shape().isRequired,
}

export default connect(null, {
  createTeamByClub: team.createTeamByClub,
})(CreateTeamPopup)
