import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import { articles } from 'redux/modules'
import Loader from './Loader'
import PROP_TYPES from 'constants/propTypes'

const ArticleLoader = ({
  children,
  articleId,
  fetchArticle,
  activeLanguage: { code: locale },
  ...restProps
}) => (
  <Loader
    fetchMethod={() => fetchArticle(articleId, { locale })}
    triggers={[locale]}
    {...restProps}
  >
    {children}
  </Loader>
)

ArticleLoader.propTypes = {
  children: PROP_TYPES.children.isRequired,
  articleId: PROP_TYPES.id.isRequired,
  fetchArticle: PROP_TYPES.func.isRequired,
  activeLanguage: PROP_TYPES.shape().isRequired,
}

export default compose(
  connect(
    null,
    { fetchArticle: articles.fetchArticle }
  ),
  withLocalize
)(ArticleLoader)
