import { handleActions, combineActions } from 'redux-actions'
import {
  actionsTypesGenerator,
  onRequest,
  onSuccess,
  onFailure,
} from 'utils/reduxHelpers'
import initialState from '../initialState'

const namespace = 'GENERAL_EVENT'

const CREATE_EVENT = actionsTypesGenerator(`CREATE_${namespace}`)
const UPDATE_EVENT = actionsTypesGenerator(`UPDATE_${namespace}`)
const FETCH_EVENT = actionsTypesGenerator(`FETCH_${namespace}`)
const DELETE_EVENT = actionsTypesGenerator(`DELETE_${namespace}`)
const FETCH_TICKERS =  actionsTypesGenerator(`FETCH_TICKERS`)
const UPDATE_EVENT_PARTICIPANT = actionsTypesGenerator(
  `UPDATE_${namespace}_PARTICIPANT`
)

export const fetchEvent = (eventUuid, params) => ({
  types: FETCH_EVENT,
  callAPI: {
    method: 'GET',
    entity: 'Event',
    path: `/v2/general-events/${eventUuid}`,
    params,
  },
})

export const createEvent = (seasonUuid, data) => ({
  types: CREATE_EVENT,
  callAPI: {
    method: 'POST',
    entity: 'Event',
    path: `/v2/seasons/${seasonUuid}/general-events`,
    data,
  },
})
export const updateEvent = (uuid, data) => ({
  types: UPDATE_EVENT,
  callAPI: {
    method: 'PATCH',
    entity: 'Event',
    path: `/v2/general-events/${uuid}`,
    data,
  },
})

export const updateEventParticipant = (eventPlayer, data) => ({
  types: UPDATE_EVENT_PARTICIPANT,
  callAPI: {
    method: 'PATCH',
    entity: 'Event Participant',
    path: `/v2/general-event-players/${eventPlayer}/`,
    data: { ...data, preventToastNotification: true },
    showErrorToastOnly: true,
  },
})

export const deleteEvent = (eventUuid) => ({
  types: DELETE_EVENT,
  callAPI: {
    method: 'DELETE',
    entity: 'Event',
    path: `/v2/general-events/${eventUuid}`,
  },
})

export const fetchEventTickers = (eventUuid) => ({
  types: FETCH_TICKERS,
  callAPI: {
    method: 'GET',
    entity: 'Ticker',
    path: `/general_events/${eventUuid}/tickers`,
    data: {
      preventToastNotification: true,
    }
  },
})

export default handleActions(
  {
    [combineActions(
      CREATE_EVENT.request,
      UPDATE_EVENT.request,
      FETCH_EVENT.request,
      DELETE_EVENT.request,
      UPDATE_EVENT_PARTICIPANT.request,
      FETCH_TICKERS.request,
    )]: onRequest,
    [combineActions(FETCH_EVENT.success)]: (
      state,
      { response: { data: current = {} } }
    ) => onSuccess({ ...state, current }),
    [combineActions(CREATE_EVENT.success, UPDATE_EVENT.success)]: (
      state,
      { response: { data = {} }, id }
    ) => {
      const items = state.items.updateById(id, { ...data, isNew: false })

      return onSuccess({
        ...state,
        items,
        current: { ...state.current, ...data },
      })
    },
    [DELETE_EVENT.success]: (state, { id }) =>
      onSuccess({ ...state, items: state.items.filterById(id) }),
    [UPDATE_EVENT_PARTICIPANT.success]: (
      state,
      { response: { data = {} } }
    ) => {
      const items = state.current.relationships.participants.updateByUuid(
        data.uuid,
        {
          ...data,
        }
      )

      return onSuccess({
        ...state,
        current: {
          ...state.current,
          relationships: {
            ...state.current.relationships,
            participants: items,
          },
        },
      })
    },
    [combineActions(FETCH_TICKERS.success)]: (state) => {
      return onSuccess({
        ...state
      })
    },
    [combineActions(
      CREATE_EVENT.failure,
      UPDATE_EVENT.failure,
      FETCH_EVENT.failure,
      DELETE_EVENT.failure,
      FETCH_TICKERS.failure,
      UPDATE_EVENT_PARTICIPANT.failure,
    )]: onFailure,
  },
  initialState.generalEvents
)
