import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Paper, Toolbar, Box, Breadcrumbs, Link, Typography, IconButton } from '@material-ui/core'
import KnowledgeBaseIcon from '@material-ui/icons/School'
import { ARTICLES_DASHBOARD_ROUTE } from 'constants/routes'
import PROP_TYPES from 'constants/propTypes'

const CategoryHead = ({articlesCategories, selectedCategory}) => {

    const linkBack = `${ARTICLES_DASHBOARD_ROUTE}`

    const getTitle = id =>
    articlesCategories.filter(
      categories => categories.id === parseInt(id, 10)
    )[0]

    return (
        <>
          <Box mb={2}>
      <Toolbar component={Paper}>
       {articlesCategories
        .filter(categories => categories.id === parseInt(selectedCategory, 10))
        .map(cat =>
          cat.parent_id != null ? (
          
                <Box display="flex" alignItems="center">
                  <IconButton component={Link} to={linkBack}>
                    <KnowledgeBaseIcon fontSize="small" />
                  </IconButton>
                  {getTitle(cat.parent_id).parent_id != null ? (
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link
                        color="inherit"
                        href={getTitle(cat.parent_id).parent_id}
                      >
                        {getTitle(getTitle(cat.parent_id).parent_id).title}{' '}
                      </Link>
                      <Link color="inherit" href={cat.parent_id}>
                        {' '}
                        {getTitle(cat.parent_id).title}{' '}
                      </Link>
                      <Typography color="textPrimary" variant="span">
                        {cat.title}
                      </Typography>
                    </Breadcrumbs>
                  ) : (
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link color="inherit" href={cat.parent_id}>
                        {getTitle(cat.parent_id).title}
                      </Link>
                      <Typography color="textPrimary" variant="span">
                        {cat.title}
                      </Typography>
                    </Breadcrumbs>
                  )}
                </Box>
                
              
        
          ) : (
       
                <Box display="flex" alignItems="center">
                  <IconButton component={Link} to={linkBack}>
                    <KnowledgeBaseIcon fontSize="small" />
                  </IconButton>
                  <Typography color="textPrimary" variant="span">
                    {cat.title}
                  </Typography>
                </Box>
          
          )
        )}
   </Toolbar>
    
    </Box>
        </>
    );
};

CategoryHead.defaultProps = {
    articlesCategories: [],
  }

CategoryHead.propTypes = {
articlesCategories: PROP_TYPES.func,
selectedCategory: PROP_TYPES.number.isRequired,
};

export default compose(
    withRouter,
    connect(({ articles: { current: { articlesCategories } } }) => ({
      articlesCategories,
    }))
  )(CategoryHead)