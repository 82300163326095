import React from 'react'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useStyles } from '../styles'
import { DialogContent } from './StyledComponents'
import DrillDetailsSection from './DrillDetailsSection'
import MediaTabSection from './MediaTabSection'

const DrillBody = ({ drill }) => {
  const classes = useStyles()

  return (
    <DialogContent className={classes.dialogContent}>
      <Grid
        container
        maxWidth="lg"
        spacing={5}
        className={classes.gridContainer}
      >
        <Grid item md={6} xs={12} className={classes.mediaSection}>
          <MediaTabSection drill={drill} />
        </Grid>
        <Grid item md={6} xs={12} className={classes.detailsSection}>
          <DrillDetailsSection drill={drill} />
        </Grid>
      </Grid>
    </DialogContent>
  )
}

DrillBody.propTypes = {
  drill: PropTypes.shape().isRequired,
}

export default DrillBody
