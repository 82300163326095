import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { CardMedia, Box, Typography } from '@material-ui/core'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import CloseIcon from '@material-ui/icons/Close'

const MuiFab = withStyles((theme) => ({
  root: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: '10',
  },
}))(Fab)

const useStyles = makeStyles((theme) => ({
  root: { width: '100%' },
  dropZone: {
    outline: 'none',
    display: 'flex',
    width: '100%',
    height: '250px',
    overflow: 'hidden',
    textAlign: 'center',
    position: 'relative',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 'auto',
    padding: theme.spacing(5, 0),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#F4F6F8',
    border: '1px dashed rgba(0, 0, 0, 0.12)',
    '&:hover': {
      opacity: 0.72,
      cursor: 'pointer',
    },
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
      flexDirection: 'row',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '15px',
    },
  },
  dropZoneLessHeight: {
    width: 'calc(100% - 16px)',
    height: 'calc(100% - 16px)',
  },
  isDragActive: {
    opacity: 0.72,
  },
  isDragReject: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.light,
    backgroundColor: theme.palette.error.lighter,
  },
  isDragAccept: {},
  media: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'calc(100% - 16px)',
    height: 'calc(100% - 16px)',
    top: 8,
    borderRadius: 8,
    objectFit: 'cover',
    position: 'absolute',
  },
  dropZoneText: {
    textAlign: 'center',
    padding: theme.spacing('30%', 0),
    marginLeft: 0,
  },
  dropZoneTextLessHeight: {
    padding: theme.spacing('5%', 0),
  },
  invalidHelperText: {
    display: 'block',
    color: '#f44336',
  },
}))

const IMAGE_MAX_SIZE = 5 * 1024 * 1024 // 5MB in bytes
const VIDEO_MAX_SIZE = 100 * 1024 * 1024 // 50MB in bytes
const AUDIO_MAX_SIZE = 100 * 1024 * 1024 // 20MB in bytes

const validFileTypes = {
  image: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'],
  video: [
    'video/mp4',
    'video/mpeg',
    'video/quicktime',
  ],
  audio: [
    'audio/mpeg',
    'audio/mp4',
    'audio/wav',
  ],
}

const getFileType = (fileType) => {
  if (validFileTypes.image.includes(fileType)) {
    return 'image'
  }
  if (validFileTypes.video.includes(fileType)) {
    return 'video'
  }
  if (validFileTypes.audio.includes(fileType)) {
    return 'audio'
  }

  return 'unsupported'
}

const ImageUploader = ({
  input,
  meta = {},
  isMulti,
  fileType,
  dropOrSelectMessage,
}) => {
  const [preview, changePreview] = useState(null)
  const classes = useStyles()

  const getAcceptedFileTypeInfo = () => {
    if (fileType === 'image') {
      return ''
    }
    if (fileType === 'video') {
      return <Translate id="uploader.supported-video-type-info" />
    }
    if (fileType === 'audio') {
      return <Translate id="uploader.supported-audio-type-info" />
    }

    return ''
  }

  useEffect(() => {
    const newPreview =
      input.value &&
      (typeof input.value === 'string'
        ? input.value
        : URL.createObjectURL(input.value))

    changePreview(newPreview)

    return () => {
      if (newPreview && typeof input.value !== 'string') {
        URL.revokeObjectURL(newPreview)
      }
    }
  }, [input.value])

  const removeFile = (e) => {
    e.stopPropagation()

    input.onChange('')
    changePreview(null)
  }

  // Check media size and show error accordingly
  const checkSizeValidation = (rejectedFile, selectedFileType) => {
    let isInValid = false

    // @tofix Use translation messages to show error
    if (rejectedFile.size > IMAGE_MAX_SIZE && selectedFileType === 'image') {
      toast.error('File size exceeds the limit of 5MB')
      isInValid = true
    } else if (
      rejectedFile.size > VIDEO_MAX_SIZE &&
      selectedFileType === 'video'
    ) {
      toast.error('File size exceeds the limit of 100MB')
      isInValid = true
    } else if (
      rejectedFile.size > AUDIO_MAX_SIZE &&
      selectedFileType === 'audio'
    ) {
      toast.error('File size exceeds the limit of 100MB')
      isInValid = true
    }

    return isInValid
  }

  const handleFileChange = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      const rejectedFile = rejectedFiles[0].file
      const selectedFileType = getFileType(rejectedFile.type)

      // Return and do not check for other errors if selected file size is greater than expected
      if (checkSizeValidation(rejectedFile, selectedFileType)) {
        return false
      }

      // @tofix Use translation messages to show error
      // If file rejected and its not a size error then show file type error accordingly
      switch (selectedFileType) {
        case 'image':
          toast.error(
            'Invalid file type. Please upload an image (JPG, JPEG, PNG, GIF)'
          )
          break
        case 'video':
          toast.error(
            'Invalid file type. Please upload a video (MP4, MOV, WMV, AVI, AVCHD, FLV, F4V, SWF, MKV, MPEG)'
          )
          break
        case 'audio':
          toast.error(
            'Invalid file type. Please upload an audio (MP3, MP4, AIFF, WAV, PODCAST, ALAC, WMA)'
          )
          break
        default:
          toast.error('Unsupported file type.')
      }
    } else if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0]

      input.onChange(file)
      const newPreview =
        typeof file === 'string' ? file : URL.createObjectURL(file)
      changePreview(newPreview)
    } else {
      toast.error('Please select a file.')
    }

    return true
  }

  // Get max file size according to the file selected
  const getMaxFileSizeAllowed = () => {
    let maxFileSize = 0

    if (fileType === 'audio') {
      maxFileSize = AUDIO_MAX_SIZE
    } else if (fileType === 'video') {
      maxFileSize = VIDEO_MAX_SIZE
    } else {
      maxFileSize = IMAGE_MAX_SIZE
    }

    return maxFileSize
  }

  // Get accepted file types according to the file selected
  const getAcceptedFileTypes = () => {
    let acceptedFileTypes = ''

    if (fileType === 'audio') {
      acceptedFileTypes = validFileTypes.audio.join(',')
    } else if (fileType === 'video') {
      acceptedFileTypes = validFileTypes.video.join(',')
    } else {
      acceptedFileTypes = validFileTypes.image.join(',')
    }

    return acceptedFileTypes
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    isDragAccept,
  } = useDropzone({
    onDrop: handleFileChange,
    maxSize: getMaxFileSizeAllowed(),
    accept: getAcceptedFileTypes(),
    multiple: isMulti,
  })

  const onDragMessage = isDragAccept ? (
    <Translate id="uploader.drop-or-select-file" />
  ) : (
    <b>
      <Translate id="uploader.only-image-files-supports" />
      <br />
      &#9888;
    </b>
  )

  const isInvalid = meta.touched && meta.error

  return (
    <>
      <div
        className={clsx(classes.dropZone, {
          [classes.isDragActive]: isDragActive,
          [classes.isDragAccept]: isDragAccept,
          [classes.isDragReject]: isDragReject,
        })}
      >
        <CardMedia
          image={preview}
          isInvalid={isInvalid}
          className={clsx(classes.media)}
          {...getRootProps()}
          sx={{
            top: 8,
            borderRadius: 1,
            objectFit: 'cover',
            position: 'absolute',
            width: 'calc(100% - 16px)',
            height: 'calc(100% - 16px)',
          }}
        >
          {!isMulti && <input {...getInputProps()} />}
          {!preview && (
            <>
              <Box
                sx={{ ml: { md: 5 } }}
                className={clsx(classes.dropZoneText)}
              >
                {isDragActive ? (
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {onDragMessage}
                  </Typography>
                ) : (
                  <>
                    <Box component="span" m={1}>
                      <CloudUploadIcon />
                    </Box>
                    <Box>
                      <Box fontSize={12}>{dropOrSelectMessage}</Box>
                    </Box>
                    <Box fontSize={10} color="text.secondary" mt={1}>
                      {getAcceptedFileTypeInfo()}
                    </Box>
                  </>
                )}
              </Box>
            </>
          )}
          {preview && (
            <>
              {fileType === 'image' && (
                <MuiFab size="small" color="secondary" onClick={removeFile}>
                  <CloseIcon />
                </MuiFab>
              )}
              {fileType === 'video' && (
                <>
                  <MuiFab size="small" color="secondary" onClick={removeFile}>
                    <CloseIcon />
                  </MuiFab>
                  <video
                    controls
                    style={{ width: '100%', height: '230px' }}
                    preload="metadata"
                  >
                    <source
                      src={preview}
                      type="video/mp4"
                    />
                    <track kind="captions" />
                    Your browser does not support the video tag.
                  </video>
                </>
              )}
              {fileType === 'audio' && (
                <>
                  <MuiFab size="small" color="secondary" onClick={removeFile}>
                    <CloseIcon />
                  </MuiFab>
                  <audio controls style={{ width: '100%' }}>
                    <source
                      src={preview}
                    />
                    <track kind="captions" />
                    Your browser does not support the audio element.
                  </audio>
                </>
              )}
            </>
          )}
        </CardMedia>

        {isMulti && (
          <Box>
            <input
              {...getInputProps({
                accept: '.jpg, .jpeg, .png, .gif',
                style: {
                  display: 'block',
                  visibility: 'hidden',
                },
              })}
            />
          </Box>
        )}
      </div>
      {isInvalid && (
        <Box type="invalid" className={classes.invalidHelperText}>
          {isInvalid}
        </Box>
      )}
    </>
  )
}

ImageUploader.defaultProps = {
  meta: {},
  isMulti: false,
  fileType: 'image',
  dropOrSelectMessage: <Translate id="uploader.drop-or-select-file" />,
}

ImageUploader.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape(),
  isMulti: PropTypes.bool,
  fileType: PropTypes.string,
  dropOrSelectMessage: PropTypes.shape(),
}

export default ImageUploader
