import React, { useContext } from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import {
  Dialog,
  Button,
  Typography,
  Backdrop,
  CircularProgress,
} from '@material-ui/core'
import NavigationPrompt from 'react-router-navigation-prompt'
import { trainings } from 'redux/modules'
import filterObject from 'utils/filterObject'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import { formatDateTimeToServer } from 'utils/formatDate'
import { Context } from './TrainingStateContext'
import TrainingDashboardLayout from './TrainingDashboardLayout'
import GROUPS from './groupConstant'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})
const useStyles = makeStyles((theme) => ({
  wBoardButton: {
    display: 'block',
    margin: '1.2rem auto',
    width: '14rem',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h4">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const createPlayersForServer = (players) => {
  return players.map((player) => {
    const groupIndex = GROUPS.findIndex(
      (group) => group.value === player.relation
    )

    return {
      id: player.id,
      available: player.relation !== 'notAvailable' ? 1 : 0,
      group_id: groupIndex !== -1 ? groupIndex : null,
    }
  })
}

const createTrainingPlayersForServer = (players) => {
  return players.map((player) => {
    const groupIndex = GROUPS.findIndex(
      (group) => group.value === player.relation
    )

    return {
      uuid: player.uuid,
      availability: player.availability,
      group_id: groupIndex !== -1 ? groupIndex : null,
    }
  })
}

const TrainingDashboardContainer = ({ updateTraining, currentTrainingId }) => {
  const {
    trainingState: {
      sessionDrills,
      normalizedPlayers,
      notes,
      tickers,
      series_uuid: seriesUuid,
      ...trainingState
    },
    anyChange,
    setAnyChange,
    onTwoFieldChange,
  } = useContext(Context)

  const classes = useStyles()
  const [loading, setLoading] = React.useState(false)

  const handleSave = (applyOn) => {
    setLoading(true)
    setAnyChange(false)

    const response = updateTraining(
      currentTrainingId,
      {
        ...filterObject(trainingState, Boolean),
        date: formatDateTimeToServer(trainingState.date),
        players: createPlayersForServer(normalizedPlayers),
        training_players: createTrainingPlayersForServer(
          trainingState.training_players
        ),
      },
      { apply_on: applyOn }
    )
    response.then((res) => {
      onTwoFieldChange(
        'notes',
        'tickers',
        res.response.data.note,
        res.response.data.tickers
      )
      setAnyChange(false)
      setLoading(false)
    })

    return response
  }

  return (
    <div>
      {sessionDrills && (
        <>
          <NavigationPrompt when={anyChange}>
            {({ onConfirm, onCancel }) => {
              return (
                <>
                  <Dialog aria-labelledby="customized-dialog-title" open>
                    <DialogTitle
                      id="customized-dialog-title"
                      onClose={onCancel}
                    >
                      <Translate id="dialog.unsaved.title" />
                    </DialogTitle>
                    <DialogContent dividers>
                      <Typography variant="p" component="p">
                        <Translate id="dialog.unsaved.content" />
                      </Typography>
                    </DialogContent>
                    <DialogActions
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      {seriesUuid ? (
                        <>
                          <Button
                            onClick={() => handleSave('SINGLE').then(onCancel)}
                            size="large"
                            variant="contained"
                            color="primary"
                          >
                            <Translate id="dialog.unsaved.update-this" />
                          </Button>
                          <Button
                            onClick={() =>
                              handleSave('SINGLE_AND_FOLLOWING').then(onCancel)
                            }
                            size="large"
                            variant="contained"
                            color="primary"
                          >
                            <Translate id="dialog.unsaved.update-this-following" />
                          </Button>
                        </>
                      ) : (
                        <Button
                          onClick={() => handleSave('SINGLE').then(onCancel)}
                          size="large"
                          variant="contained"
                          className={classes.wBoardButton}
                          color="primary"
                        >
                          <Translate id="dialog.unsaved.save" />
                        </Button>
                      )}
                      <Button onClick={onConfirm} size="large">
                        <Translate id="dialog.unsaved.leave" />
                      </Button>
                    </DialogActions>
                  </Dialog>
                </>
              )
            }}
          </NavigationPrompt>
          <TrainingDashboardLayout
            setLoading={setLoading}
            handleSave={handleSave}
          />
          {loading && (
            <Backdrop className={classes.backdrop} open>
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
        </>
      )}
    </div>
  )
}

TrainingDashboardContainer.propTypes = {
  updateTraining: PropTypes.func.isRequired,
  currentTrainingId: PropTypes.number.isRequired,
}

export default connect(
  ({ trainings: { current } }) => ({
    currentTrainingId: current.id,
  }),
  {
    updateTraining: trainings.updateTraining,
  }
)(TrainingDashboardContainer)
