import React from 'react'
import TeamEditContainer from './TeamEditContainer'
import PROP_TYPES from 'constants/propTypes'

const TeamEdit = ({ match, history }) => (
  <TeamEditContainer match={match} history={history} />
)

TeamEdit.propTypes = {
  match: PROP_TYPES.match.isRequired,
  history: PROP_TYPES.history.isRequired,
}

export default TeamEdit
