import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@material-ui/core/styles'
import { TextField } from '@material-ui/core'
import { Translate } from 'react-localize-redux'

const NumberField = styled(TextField)(({ theme }) => ({
  '& .is-invalid': {
    paddingRight: theme.spacing(2),
    backgroundPositionX: 'right',
  },
}))

const InputNumber = ({
  input,
  meta = {},
  label,
  className,
  minLimit,
  maxLimit,
  textAlign,
  ...rest
}) => {
  const inputError = meta.touched && meta.error

  return (
    <NumberField
      className={className}
      label={label && label}
      type="number"
      error={inputError}
      variant="outlined"
      margin="dense"
      InputProps={{
        inputProps: { min: minLimit, max: maxLimit, style: { textAlign } },
      }}
      {...input}
      helperText={inputError && inputError ? <Translate id={`joi.error.${meta.error}`} /> : ''}
      {...rest}
    />
  )
}

InputNumber.defaultProps = {
  meta: {},
  className: '',
  minLimit: 0,
  maxLimit: 0,
  textAlign: 'center',
  label: '',
}

InputNumber.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape(),
  label: PropTypes.node,
  className: PropTypes.string,
  minLimit: PropTypes.number,
  maxLimit: PropTypes.number,
  textAlign: PropTypes.string,
}

export default InputNumber
