import {
  AppBar,
  Dialog,
  DialogContent,
  IconButton,
  Tab,
  Tabs,
} from '@material-ui/core'
import InnrHead from 'containers/InnrHead'
import React, { useEffect, useState } from 'react'
import { Translate } from 'react-localize-redux'
import CloseIcon from '@material-ui/icons/Close'
import TabPanel from 'components/material/TabPanel'
import EventIcon from '@material-ui/icons/Event'
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import CircularSpinner from 'components/loader/CircularSpinner'
import moment from 'moment'
import { taskGroups as tasksModule } from '../../redux/modules'
import { formatDateForServer } from '../../utils/formatDate'
import AddEditTrainingTaskGroup from './AddEditTrainingTaskGroup'
import AddEditMatchTaskGroup from './AddEditMatchTaskGroup'
import AddEditGeneralTaskGroup from './AddEditGeneralTaskGroup'
import PROP_TYPES from 'constants/propTypes'
import { TRAININGS_GAME } from 'constants/calender'
import { PLAYER, PLAYER_CAMEL_CASE } from 'constants/player'

const AddTaskGroupDialog = ({
  onClose,
  open,
  team,
  createTaskGroup,
  updateTaskGroup,
  selectedTaskGroup,
  dateFormat,
  isLoading,
  players,
  isTrainingEvent,
  isMatchEvent,
  eventId,
}) => {
  const [activeTabName, setActiveTabName] = useState(TRAININGS_GAME.training)

  const splitType = (value) => {
    let type = ''
    if (value?.includes(TRAININGS_GAME.training_camel_case)) {
      type = TRAININGS_GAME.training
    } else if (value?.includes(TRAININGS_GAME.match_camel_case)) {
      type = TRAININGS_GAME.match
    } else if (value?.includes(PLAYER_CAMEL_CASE)) {
      type = PLAYER
    } else {
      type = TRAININGS_GAME.general
    }

    return type
  }
  const [taskGroup, setTaskGroup] = useState({
    id: selectedTaskGroup?.id || 0,
    title: selectedTaskGroup?.title || '',
    modelIds: selectedTaskGroup?.model_ids?.map((el) => +el) || [],
    modelType: selectedTaskGroup
      ? splitType(selectedTaskGroup?.model_type)
      : PLAYER,
    eventType: selectedTaskGroup
      ? splitType(selectedTaskGroup?.event_type)
      : TRAININGS_GAME.training,
    eventIds: selectedTaskGroup?.event_ids?.map((el) => +el) || null,
    description: selectedTaskGroup?.description || '',
    dueDate: selectedTaskGroup?.due_date || 0,
    dueDateOffset: selectedTaskGroup?.due_date_offset || 0,
    events: selectedTaskGroup?.events,
    isEditable: selectedTaskGroup ? selectedTaskGroup?.isEditable : true,
    createdAt: selectedTaskGroup?.created_at || null,
    startData: selectedTaskGroup?.start_date || null,
    endData: selectedTaskGroup?.end_date || null,
  })

  const [questions, setQuestions] = useState(
    selectedTaskGroup && selectedTaskGroup.questions
      ? selectedTaskGroup.questions.map(
          ({ id, question_type: questionType, question }) => ({
            id,
            questionType,
            question,
          })
        )
      : []
  )
  const [playersList, setPlayersList] = useState()

  const [selectAllPlayers, setSelectAllPlayers] = useState(
    selectedTaskGroup
      ? taskGroup?.modelIds?.length === playersList?.length
      : true
  )
  const [taskSeries, setTaskSeries] = useState({
    isTaskSeries: selectedTaskGroup?.start_date || false,
    start_date: selectedTaskGroup?.start_date || new Date(),
    end_date: selectedTaskGroup?.end_date || new Date(),
    day_of_week: selectedTaskGroup?.day_of_week || 1,
  })

  const handleTaskSeriesCheckBox = (event) => {
    const today = moment()
    const endDate = moment(today).add('days', 7)

    setTaskSeries({
      ...taskSeries,
      isTaskSeries: event.target.checked,
      start_date: today,
      end_date: endDate,
    })
  }
  const handleCheckboxSelectAllPlayers = (event) => {
    const { checked } = event.target
    setSelectAllPlayers(checked)
    if (checked) {
      setTaskGroup({
        ...taskGroup,
        modelIds: [...new Set(playersList?.map((p) => p.id))],
        [`error_modelIds`]: playersList?.length <= 0,
      })
    }
  }

  const handleTabChange = (event, newValue) => {
    setActiveTabName(newValue)
    setTaskGroup({
      ...taskGroup,
      eventIds: [],
    })
  }
  const handleInput = (event) => {
    const {
      target: { name, value },
    } = { ...event }

    setTaskGroup({
      ...taskGroup,
      [name]: value,
      [`error_${name}`]: value === '' || value === null || value === undefined,
    })
  }
  const handlePlayerChange = (modelIds) => {
    setTaskGroup({
      ...taskGroup,
      modelIds: [...new Set(modelIds.map((p) => +p.id))],
      [`error_modelIds`]: modelIds.length <= 0,
    })
  }
  const handleEventChange = (value) => {
    setTaskGroup({
      ...taskGroup,
      eventIds: [...new Set(value.map((p) => p.id))],
      [`error_eventIds`]: value.length <= 0,
    })
  }

  const validationCheck = () => {
    if (
      taskGroup.title === '' ||
      taskGroup.error_title ||
      taskGroup.modelIds.length <= 0 ||
      taskGroup.error_modelIds ||
      taskGroup.eventIds === '' ||
      taskGroup.error_eventIds
    ) {
      setTaskGroup({
        ...taskGroup,
        error_title: taskGroup.title === '',
        error_modelIds: taskGroup.modelIds.length <= 0,
        error_eventIds: taskGroup.eventIds === 0,
      })

      return false
    }
    if (questions.length > 0) {
      const temp = questions.filter(
        (e) => e.questionType == null || e.question == null || e.question === ''
      )
      if (temp.length > 0) {
        setTaskGroup({
          ...taskGroup,
          error_questions: true,
        })

        return false
      }
    }

    return true
  }

  const handleCreate = (eventType) => {
    if (!validationCheck()) {
      return
    }
    const { title, modelIds, modelType, eventIds, description, dueDateOffset } =
      {
        ...taskGroup,
      }
    const body = {
      title,
      model_ids: modelIds,
      model_type: modelType,
      event_ids: eventIds,
      event_type: eventType,
      description,
      due_date: dueDateOffset,
    }
    const tempQues = questions
      .filter((e) => e.questionType != null && e.question != null)
      .map(({ id, questionType, question }) => ({
        id,
        question_type: questionType,
        question,
      }))
    if (tempQues.length > 0) {
      body.questions = tempQues
    }
    if (
      taskSeries.isTaskSeries &&
      taskSeries.start_date &&
      taskSeries.end_date
    ) {
      body.start_date = formatDateForServer(taskSeries.start_date)
      body.end_date = formatDateForServer(taskSeries.end_date)
      body.day_of_week = taskSeries.day_of_week
    }

    createTaskGroup(team.id, body).then(() => {
      onClose()
    })
  }
  const handleUpdate = (eventType, taskId) => {
    if (!validationCheck()) {
      return
    }
    const { title, modelIds, modelType, eventIds, description, dueDateOffset } =
      {
        ...taskGroup,
      }
    const body = {
      title,
      model_ids: modelIds,
      model_type: modelType,
      event_ids: eventIds,
      event_type: eventType,
      description,
      due_date: dueDateOffset,
    }
    const tempQues = questions
      .filter((e) => e.questionType != null && e.question != null)
      .map(({ id, questionType, question }) => {
        if (id === null || id === '' || id === 0) {
          return {
            question_type: questionType,
            question,
          }
        }

        return {
          id,
          question_type: questionType,
          question,
        }
      })
    if (tempQues.length > 0) {
      body.questions = tempQues
    }
    updateTaskGroup(taskId, body).then(() => {
      onClose()
    })
  }

  const addNewQuestion = () => {
    setQuestions([...questions, { id: null, questionType: null, question: '' }])
  }
  const handleQuestion = (event, index) => {
    const {
      target: { name, value },
    } = { ...event }

    setQuestions((u) =>
      u.map((element, i) => {
        if (index === i) {
          return { ...element, [name]: value }
        }

        return element
      })
    )
  }

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    }
  }
  useEffect(() => {
    const tempPlayers = players.length > 0 ? players : team.players
    setPlayersList(
      tempPlayers
        .filter(
          (player) =>
            player.first_name &&
            player.status !== 'DEACTIVATED'
        )
        .map((player) => ({
          id: player.id,
          label: `@${player.first_name} ${
            player.last_name ? player.last_name : ''
          }`,
        }))
    )
    if (!selectedTaskGroup) {
      setTaskGroup({
        ...taskGroup,
        modelIds: [...new Set(tempPlayers.map((p) => p.id))],
        [`error_modelIds`]: tempPlayers.length <= 0,
      })
    }
  }, [players])
  useEffect(() => {
    if (isMatchEvent || taskGroup?.eventType === TRAININGS_GAME.match) {
      setActiveTabName(TRAININGS_GAME.match)
    } else if (
      isTrainingEvent ||
      taskGroup?.eventType === TRAININGS_GAME.training
    ) {
      setActiveTabName(TRAININGS_GAME.training)
    } else {
      setActiveTabName(TRAININGS_GAME.general)
    }
  }, [])

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
      maxWidth="sm"
    >
      {isLoading && <CircularSpinner />}
      <InnrHead title={<Translate id="task-management.add-task" />}>
        {' '}
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </InnrHead>
      <DialogContent>
        <AppBar position="static" color="transparent">
          <Tabs
            value={activeTabName}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab
              icon={<EventIcon color="action" />}
              label={<Translate id="menu.training" />}
              {...a11yProps(TRAININGS_GAME.training)}
              style={{
                display:
                  (selectedTaskGroup &&
                    taskGroup.eventType !== TRAININGS_GAME.training) ||
                  isMatchEvent
                    ? 'none'
                    : 'block',
              }}
              value={TRAININGS_GAME.training}
            />
            <Tab
              icon={<EmojiEventsIcon color="action" />}
              label={<Translate id="button.match" />}
              {...a11yProps(TRAININGS_GAME.match)}
              style={{
                display:
                  (selectedTaskGroup &&
                    taskGroup.eventType !== TRAININGS_GAME.match) ||
                  isTrainingEvent
                    ? 'none'
                    : 'block',
              }}
              value={TRAININGS_GAME.match}
            />
            <Tab
              icon={<EventIcon color="action" />}
              label={<Translate id="task-management.general-task" />}
              {...a11yProps(TRAININGS_GAME.general)}
              style={{
                display:
                  (selectedTaskGroup &&
                    taskGroup.eventType === TRAININGS_GAME.training) ||
                  taskGroup.eventType === TRAININGS_GAME.match ||
                  isMatchEvent ||
                  isTrainingEvent
                    ? 'none'
                    : 'block',
              }}
              value={TRAININGS_GAME.general}
            />
          </Tabs>
        </AppBar>
        <TabPanel value={activeTabName} index={TRAININGS_GAME.training}>
          {taskGroup && playersList && (
            <AddEditTrainingTaskGroup
              taskGroup={taskGroup}
              playersList={playersList}
              handlePlayerChange={handlePlayerChange}
              handleInput={handleInput}
              handleCreate={handleCreate}
              handleUpdate={handleUpdate}
              isEditMode={!!selectedTaskGroup}
              dateFormat={dateFormat}
              questions={questions}
              setQuestions={setQuestions}
              handleQuestion={handleQuestion}
              addNewQuestion={addNewQuestion}
              setTaskGroup={setTaskGroup}
              handleCheckboxSelectAllPlayers={handleCheckboxSelectAllPlayers}
              selectAllPlayers={selectAllPlayers}
              eventId={+eventId}
            />
          )}
        </TabPanel>
        <TabPanel value={activeTabName} index={TRAININGS_GAME.match}>
          {taskGroup && playersList && (
            <AddEditMatchTaskGroup
              taskGroup={taskGroup}
              playersList={playersList}
              handleEventChange={handleEventChange}
              handlePlayerChange={handlePlayerChange}
              handleInput={handleInput}
              handleCreate={handleCreate}
              handleUpdate={handleUpdate}
              isEditMode={!!selectedTaskGroup}
              dateFormat={dateFormat}
              questions={questions}
              setQuestions={setQuestions}
              handleQuestion={handleQuestion}
              addNewQuestion={addNewQuestion}
              setTaskGroup={setTaskGroup}
              handleCheckboxSelectAllPlayers={handleCheckboxSelectAllPlayers}
              selectAllPlayers={selectAllPlayers}
              eventId={+eventId}
            />
          )}
        </TabPanel>
        <TabPanel value={activeTabName} index={TRAININGS_GAME.general}>
          {taskGroup && playersList && (
            <AddEditGeneralTaskGroup
              taskGroup={taskGroup}
              playersList={playersList}
              handleEventChange={handleEventChange}
              handlePlayerChange={handlePlayerChange}
              handleInput={handleInput}
              handleCreate={handleCreate}
              handleUpdate={handleUpdate}
              isEditMode={!!selectedTaskGroup}
              dateFormat={dateFormat}
              questions={questions}
              setQuestions={setQuestions}
              handleQuestion={handleQuestion}
              addNewQuestion={addNewQuestion}
              setTaskGroup={setTaskGroup}
              handleCheckboxSelectAllPlayers={handleCheckboxSelectAllPlayers}
              selectAllPlayers={selectAllPlayers}
              handleTaskSeriesCheckBox={handleTaskSeriesCheckBox}
              taskSeries={taskSeries}
              handleSeriesChange={setTaskSeries}
            />
          )}
        </TabPanel>
      </DialogContent>
    </Dialog>
  )
}
AddTaskGroupDialog.defaultProps = {
  selectedTaskGroup: {},
  isTrainingEvent: false,
  isMatchEvent: false,
  eventId: 0,
}
AddTaskGroupDialog.propTypes = {
  team: PROP_TYPES.shape().isRequired,
  open: PROP_TYPES.bool.isRequired,
  onClose: PROP_TYPES.func.isRequired,
  createTaskGroup: PROP_TYPES.func.isRequired,
  updateTaskGroup: PROP_TYPES.func.isRequired,
  selectedTaskGroup: PROP_TYPES.shape(),
  dateFormat: PROP_TYPES.string.isRequired,
  isLoading: PROP_TYPES.bool.isRequired,
  players: PROP_TYPES.arrayOf(PROP_TYPES.shape()).isRequired,
  isMatchEvent: PROP_TYPES.bool,
  isTrainingEvent: PROP_TYPES.bool,
  eventId: PROP_TYPES.number,
}
export default compose(
  withRouter,
  connect(
    ({ taskGroups, team, auth, players }) => ({
      isLoading: taskGroups.isLoading,
      team,
      dateFormat: auth.user.datetime_format,
      players: players.items,
    }),
    {
      createTaskGroup: tasksModule.createTaskGroup,
      updateTaskGroup: tasksModule.updateTaskGroup,
    }
  )
)(AddTaskGroupDialog)
