import { Checkbox, makeStyles, styled, Paper, TextField } from '@material-ui/core'
import React from 'react'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import grey from '@material-ui/core/colors/grey'
import { Autocomplete } from '@material-ui/lab'
import PropTypes from 'prop-types'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiInputBase-root': {
      minHeight: '40px',
      height: 'auto',
    },
  },
}))

const MenuPaper = styled(Paper)({
  border: `1px solid ${grey[300]}`,
  boxShadow: `1px 1px 12px 0 ${grey[400]}`,
})

const MultiSelect = ({
  options,
  label,
  handleSelection,
  selectedValues,
  error,
  helperText,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <Autocomplete
      multiple
      className={classes.root}
      options={options}
      disableCloseOnSelect
      getOptionLabel={(option) => option.label}
      onChange={(event, newValue) => handleSelection(newValue)}
      getOptionSelected={(option, tempvalue) => option.id === tempvalue.id}
      value={selectedValues}
      PaperComponent={MenuPaper}
      renderOption={(option, { selected }) => (
        <>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.label}
        </>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          variant="outlined"
          label={label}
          margin="dense"
          helperText={helperText}
          required
        />
      )}
      {...rest}
    />
  )
}
MultiSelect.defaultProps = {
  error: false,
  helperText: '',
}
MultiSelect.propTypes = {
  handleSelection: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  label: PropTypes.string.isRequired,
  selectedValues: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
}
export default MultiSelect
