/* eslint-disable import/prefer-default-export */
export const TRAININGS_GAME = {
  training: 'TRAINING',
  game: 'GAME',
  event: 'EVENT',
  multiTraining: 'MULTI_TRAINING',
  match:'MATCH',
  general:'GENERAL',
  training_camel_case:'Training',
  match_camel_case:'Match',
  general_camel_case:'General',
}
