import React from 'react'
import { Avatar } from 'containers'
import PersonIcon from '@material-ui/icons/Person'

const renderAvatar = (params) => {
  return params.row.avatar ?
    <Avatar alt="avatar" src={params.row.avatar} /> :
    <Avatar alt="avatar" src={params.row.avatar_image}>
      <PersonIcon />
    </Avatar>
}

export default renderAvatar

