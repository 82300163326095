import React, { useState } from 'react'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { Paper, Grid, Tabs, Tab, Toolbar } from '@material-ui/core'
import TabPanel from 'components/material/TabPanel'
import { Players, Staffs, useStyles } from './components'
import { TAB_OPTIONS } from './components/Constants'
import PROP_TYPES from 'constants/propTypes'

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  }
}

const TeamAccessDashboard = ({ allStaff, players, theme }) => {
  const classes = useStyles(theme)

  const [activeTabName, setActiveTabName] = useState(TAB_OPTIONS.players)

  const handleChange = (event, newValue) => {
    setActiveTabName(newValue)
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Toolbar
            disableGutters="false"
            component={Paper}
            className={classes.toolbar}
          >
            <Tabs
              value={activeTabName}
              className={classes.title}
              onChange={handleChange}
              indicatorColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab
                label={<Translate id="userRoles.player-access" />}
                value={TAB_OPTIONS.players}
                {...a11yProps(TAB_OPTIONS.players)}
              />
              <Tab
                label={<Translate id="userRoles.staff-access" />}
                value={TAB_OPTIONS.staffs}
                {...a11yProps(TAB_OPTIONS.staffs)}
              />

            </Tabs>
          </Toolbar>
        </Grid>
      </Grid>
      <Paper>
        <TabPanel value={activeTabName} index={TAB_OPTIONS.players}>
          <Players players={players.items} />
        </TabPanel>
        <TabPanel value={activeTabName} index={TAB_OPTIONS.staffs}>
          <Staffs allStaff={allStaff} />
        </TabPanel>
      </Paper>
    </>
  )
}

TeamAccessDashboard.defaultProps = {
  allStaff: [],
  players: [],
}

TeamAccessDashboard.propTypes = {
  allStaff: PROP_TYPES.arrayOfObjects,
  players: PROP_TYPES.arrayOfObjects,
  theme: PROP_TYPES.theme.isRequired
}

export default connect((
  { staff,
    players,
    deleteStaff
  }) => (
  {
    allStaff: staff.items,
    players,
    deleteStaff
  }
))(TeamAccessDashboard)
