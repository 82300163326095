import React, { useState } from 'react'
import hasSubscription from 'utils/hasSubscription'
import { withRouter } from 'react-router-dom'
import SubLink from 'containers/SubLink'
import { ListItem, ListItemIcon, ListItemText, Box } from '@material-ui/core'
import urlConstructor from 'utils/urlConstructor'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withLocalize } from 'react-localize-redux'
import { List } from './styledComponents'
import mainMenu from './constants/mainMenu'
import mainMenuBasic from './constants/mainMenuBasic'
import mainMenuPro from './constants/mainMenuPro'
import PROP_TYPES from 'constants/propTypes'
import { DEFAULT_CLUB_ID } from 'constants/club'
import { BASIC, PLUS } from 'constants/plans'

const Sidebar = ({
  match: {
    params: { teamId, seasonId },
  },
  location: { pathname },
  onClose,
  currentClubId
}) => {
  const [mainMenuOption] = useState(() => {
    if (hasSubscription() === BASIC) {
      return mainMenuBasic;
    } if (hasSubscription() === PLUS) {
      return mainMenuPro;
    }

    return mainMenu;

  });
  const mainPagePathname = urlConstructor(teamId, seasonId)

  return (
    <>
      <List component="nav">
        {
          mainMenuOption.map(({ to, title, icon, teaser, isFullPath, rootPath, target, id, showOnlyForDefaultClub }) => {
            return (
              <Box style={{ position: 'relative', overflow: 'hidden' }}
                id={id}
                key={to}
                hidden={showOnlyForDefaultClub && currentClubId !== DEFAULT_CLUB_ID}
              >
                {teaser}
                <ListItem
                  to={to}
                  button
                  component={SubLink}
                  isFullPath={isFullPath}
                  target={target}
                  isActive={!rootPath && pathname === mainPagePathname}
                  className={
                    (!rootPath && pathname === mainPagePathname) ||
                      (rootPath && pathname.includes(mainPagePathname+rootPath))
                      ? 'selected'
                      : ''
                  }
                >
                  <ListItemIcon onClick={onClose}>{icon}</ListItemIcon>
                  <ListItemText onClick={onClose} primary={title} />
                </ListItem>
              </Box>
            )
          })}
      </List>

    </>
  )
}

Sidebar.defaultProps = {}

Sidebar.propTypes = {
  match: PROP_TYPES.match.isRequired,
  location: PROP_TYPES.location.isRequired,
  onClose: PROP_TYPES.location.isRequired,
  currentClubId: PROP_TYPES.number.isRequired
}

export default compose(
  connect(
    ({ clubs }) => ({
      currentClubId: clubs.current.id,
    }),
    null
  ),
  withLocalize,
  withRouter
)(Sidebar)