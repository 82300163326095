import React from 'react'
import { Translate } from 'react-localize-redux'
import { SubLink, Avatar } from 'containers'
import { PlayerStaffItem as StaffItem } from 'components'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import PersonIcon from '@material-ui/icons/Person'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import { TEAM_ACCESS_EDIT_ROUTE, TEAM_ACCESS_CREATE_ROUTE } from 'constants/routes'

const Staff = ({ member }) => {
  const fullName = member.first_name
    ? `${member.first_name} ${member.last_name}`
    : member.email

  return (
    <StaffItem.Container>
      <SubLink to={TEAM_ACCESS_EDIT_ROUTE.populateURLParams({ staffId: member.id })}>
        <Avatar>
          <PersonIcon />
        </Avatar>
      </SubLink>
      <StaffItem.FullName>{`${fullName}`} </StaffItem.FullName>
      <div>
        <StaffItem.Text>{member.position}</StaffItem.Text>
        <SubLink to={TEAM_ACCESS_EDIT_ROUTE.populateURLParams({ staffId: member.id })}>
          <Button variant="contained" color="primary">
            <Translate id="team.edit-staff" />
          </Button>
        </SubLink>
      </div>
    </StaffItem.Container>
  )
}

Staff.CreateNew = () => (
  <StaffItem.Container>
    <SubLink to={TEAM_ACCESS_CREATE_ROUTE}>
      <StaffItem.StaffDummyBody>
        <IconButton color="primary">
          <AddCircleIcon />
        </IconButton>
      </StaffItem.StaffDummyBody>
    </SubLink>
  </StaffItem.Container>
)

export default Staff
