import { makeStyles } from '@material-ui/core/styles'


export const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTableCell-stickyHeader': {
      backgroundColor: 'white',
      padding: theme.spacing(0, 2),
      textAlign: 'center',
    },
    '& .MuiTableCell-stickyHeader:nth-child(2)': {
      textAlign: 'left',
      padding: theme.spacing(0, 2),
    },
    '& .MuiAvatar-root': {
      marginRight: theme.spacing(1.5),
      height: 30,
      width: 30,
    },
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  reason: {
    fontWeight: 'bold',
  },
  tableRow: {
    verticalAlign: 'baseline',
  },
  [theme.breakpoints.down('sm')]: {
    root: {
      '& .MuiTableCell-sizeSmall': {
        padding: theme.spacing(0.6, 1.2),
      },
      '& .MuiButton-sizeSmall': {
        padding: theme.spacing(0.4, 0.8),
        minWidth: 20,
        '& .MuiSvgIcon-fontSizeSmall': {
          fontSize: 16,
        },
      },
      '& .MuiButton-endIcon.MuiButton-iconSizeSmall': {
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0),
      },
      '& .MuiAvatar-root': {
        marginRight: theme.spacing(0.6),
        height: 26,
        width: 26,
      },
      '& .MuiTypography-noWrap': {
        width: 90,
      },
    },
  },
}))



