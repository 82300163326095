import React from 'react'
import { Translate } from 'react-localize-redux'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import HelpIcon from '@material-ui/icons/Help'
import WarningIcon from '@material-ui/icons/Warning'
import RemoveIcon from '@material-ui/icons/Remove';
import { green, orange, red, yellow } from '@material-ui/core/colors'
import YellowCardIcon from 'assets/icons/YellowCard'
import RedCardIcon from 'assets/icons/RedCard'
import YellowRedCardIcon from 'assets/icons/YellowRedCard'

export const MATCH_SECTIONS = {
  matchLineup:'matchLineup',
  highlights:'highlights',
  notes:'notes',
  ratePlayers:'ratePlayers',
  videoSequences:'videoSequences',
  tasks:'tasks',
  tickers:'tickers'
}

export const MATCH_HIGHLIGHT_TEAMS = {own:'OWN',opponent:'OPPONENT'}
export const MATCH_HIGHLIGHT_TYPES = {goal:'goal',card:'card',substitution:'substitution',note:'note'}
export const GOAL_TYPES = [
    {key:"insidethebox", displayValue:<Translate id="matches.highlights.insidethebox" />},
    {key:"outsidethebox", displayValue: <Translate id="matches.highlights.outsidethebox" />},
    {key:"directfreekick", displayValue: <Translate id="matches.highlights.directfreekick" />},
    {key:"indirectfreekick", displayValue: <Translate id="matches.highlights.indirectfreekick" />},
    {key:"cornerkickright", displayValue: <Translate id="matches.highlights.cornerkickright" />},
    {key:"cornerkickleft", displayValue: <Translate id="matches.highlights.cornerkickleft" />},
    {key:"counterkick", displayValue: <Translate id="matches.highlights.counterkick" />},
    {key:"penalty", displayValue: <Translate id="matches.highlights.penalty" />},
    {key:"owngoal", displayValue: <Translate id="matches.highlights.owngoal" />},
    {key:"other", displayValue: <Translate id="matches.highlights.other" />},
]
export const CARD_TYPES = [
  {key:"yellow", displayValue:<Translate id="matches.highlights.yellow" />},
  {key:"red", displayValue:<Translate id="matches.highlights.red" />},
  {key:"yellow-red", displayValue:<Translate id="matches.highlights.yellow-red" />},
]
export const CARD_REASONS = [
  {key:"foul", displayValue:<Translate id="matches.highlights.foul" />},
  {key:"unsporting-behaviour", displayValue:<Translate id="matches.highlights.unsporting-behaviour" />},
  {key:"violent-conduct", displayValue:<Translate id="matches.highlights.violent-conduct" />},
  {key:"tactical", displayValue:<Translate id="matches.highlights.tactical" />},
  {key:"other", displayValue:<Translate id="matches.highlights.other" />},
]
export const SWAP_REASONS = [
  {key:"minimise-risk", displayValue:<Translate id="matches.highlights.minimise-risk" />},
  {key:"player-injured", displayValue:<Translate id="matches.highlights.player-injured" />},
  {key:"rest-player", displayValue:<Translate id="matches.highlights.rest-player" />},
  {key:"tactical-change", displayValue:<Translate id="matches.highlights.tactical-change" />},
  {key:"player-tired", displayValue:<Translate id="matches.highlights.player-tired" />},
  {key:"insuf-performance", displayValue:<Translate id="matches.highlights.insuf-performance" />},
  {key:"other", displayValue:<Translate id="matches.highlights.other" />},
]
export const MATCH_TYPES = [
  {type:"league", title:<Translate id="matches.league-match" />},
  {type:"friendly", title: <Translate id="matches.friendly-match" />},
  {type:"cup", title: <Translate id="matches.cup-match" />},
  {type:"non-conference", title: <Translate id="matches.non-conference" />},
  {type:"conference", title: <Translate id="matches.conference-game" />},
  {type:"playoff", title: <Translate id="matches.playoff-game" />},
  {type:"other", title: <Translate id="matches.other-match" />},
]
export const PLAYER_AVAILABILITY_TAGS = [
  {
    key: 'available',
    title: <Translate id="player.available" />,
    icon:  <ThumbUpIcon fontSize='small' style={{ color: green[500] }}/>,
    type: 'availability',
    value:"AVAILABLE"

  },
  {
    key: 'not_available',
    title: <Translate id="player.not-available" />,
    icon: <ThumbDownIcon fontSize='small' style={{ color: red[500] }}/>,
    type: 'availability',
    value:"NOT_AVAILABLE"
  },
  {
    key: 'not_responded',
    title: <Translate id="matches.not-responded" />,
    icon: <HelpIcon fontSize='small' style={{ color: orange[500] }}/>,
    type: 'availability',
    value:"NOT_RESPONDED"
  },

]
export const PLAYER_STATUS_TAGS = [
  {
    key: 'none',
    title: "",
    icon:  <RemoveIcon fontSize='small'/>,
    type: 'status',
    value:""
  },
  {
    key: 'injured',
    title: <Translate id="player.injured" />,
    icon:  <WarningIcon fontSize='small' style={{ color: yellow[600] }}/>,
    type: 'status',
    value:"injured"
  },
  {
    key: 'yellow_card',
    title: <Translate id="player.yellow-cards" />,
    icon: <YellowCardIcon fontSize="small" />,
    type: 'status',
    value:"yellow_card"
  },
  {
    key: 'red_card',
    title: <Translate id="player.red-card" />,
    icon: <RedCardIcon fontSize="small" />,
    type: 'status',
    value:"red_card"
  },
  {
    key: 'yellow_red_card',
    title: <Translate id="player.y-r-card" />,
    icon: <YellowRedCardIcon fontSize="small"/>,
    type: 'status',
    value:"yellow_red_card"
  },

]