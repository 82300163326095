import React from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import {
  Box,
  Avatar,
  Checkbox,
  Typography,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0.625rem 0',
    padding: '0.425rem 0.5rem 0.425rem 1rem',
    borderRadius: '8px',
    cursor: 'pointer',
    color: '#151515',
    border: '1px solid #ededed',
    boxShadow: 'none',
    '& .MuiBox-root': {
      display: 'flex',
      alignItems: 'center',
    },
    background: (props) => (props.isActive ? '#ffffff' : '#ededed'),
  },
  avatar: {
    marginRight: '10px',
  },
}));

const PlayerItem = ({
  name,
  isSelected,
  id,
  index,
  iconSrc,
  onSelect,
  isAnyPlayerSelected,
  notAvailableReason,
}) => {
  const classes = useStyles({ isActive: isSelected || !isAnyPlayerSelected });

  return (
    <Draggable key={id} draggableId={id} index={index} isDragDisabled={!isSelected && isAnyPlayerSelected}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          role="presentation"
        >
          {notAvailableReason ? (
            <Box component="div" className={classes.container} style={{ cursor: 'auto' }}>
              <Box>
                <Avatar src={iconSrc} className={classes.avatar} />
                <Typography component="h5" variant="h5">
                  {name}
                </Typography>
              </Box>
              <Tooltip title={notAvailableReason} placement="right">
                <InfoIcon fontSize="small" style={{ color: 'rgba(0, 0, 0, 0.26)' }} />
              </Tooltip>
            </Box>
          ) : (
            <Box onClick={onSelect}>
              <Box component="div" className={classes.container}>
                <Box>
                  <Avatar src={iconSrc} className={classes.avatar} />
                  <Typography component="h5" variant="h5">
                    {name}
                  </Typography>
                </Box>
                <Checkbox defaultChecked={isSelected} />
              </Box>
            </Box>
          )}
        </div>
      )}
    </Draggable>
  );
};

PlayerItem.defaultProps = {
  isSelected: false,
  isAnyPlayerSelected: false,
  notAvailableReason: null,
};

PlayerItem.propTypes = {
  name: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]).isRequired,
  index: PropTypes.number.isRequired,
  iconSrc: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  isAnyPlayerSelected: PropTypes.bool,
  notAvailableReason: PropTypes.string,
};

export default PlayerItem;
