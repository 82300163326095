import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { events } from 'redux/modules'
import Loader from './Loader'
import PROP_TYPES from 'constants/propTypes'

const SeasonsEventsLoader = props => {
  const {
    children,
    fetchSeasonEvents,
    teams,
    match: {
      params: { seasonId },
    },
    ...restProps
  } = props

  const fetchMethod = () => fetchSeasonEvents(seasonId)
  const triggers = [seasonId]

  return (
    <Loader fetchMethod={fetchMethod} triggers={triggers} {...restProps}>
      {children}
    </Loader>
  )
}

SeasonsEventsLoader.propTypes = {
  children: PROP_TYPES.children.isRequired,
  match: PROP_TYPES.match.isRequired,
  teams: PropTypes.shape().isRequired,
  fetchSeasonEvents: PropTypes.func.isRequired,
}

export default compose(
  withRouter,
  connect(
    ({ teams }) => ({ teams }),
    { fetchSeasonEvents: events.fetchSeasonEvents }
  )
)(SeasonsEventsLoader)
