import { withStyles } from '@material-ui/core/styles'
import {
  Card,
  Box,
  CardMedia as MuiCardMedia,
  CardActions as MUiCardActions,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  Toolbar as MuiToolbar,
  FormControl as MuiFormControl,
  IconButton as MuiIconButton,
  Breadcrumbs as MuiBreadcrumbs,
  Menu as MuiMenu,
  Grid,
} from '@material-ui/core'

export const CardMedia = withStyles(() => ({
  root: {
    height: 140,
  },
}))(MuiCardMedia)

export const CardActions = withStyles((theme) => ({
  root: {
    '& .MuiBox-root': {
      flex: '1 1 0',
    },
    '& .MuiButton-root': {
      marginLeft: theme.spacing(0.8),
    },
  },
}))(MUiCardActions)

export const MuiCard = withStyles((theme) => ({
  root: {
    border: '1px solid #e8e8e8',
    '& .MuiCardContent-root': {
      padding: theme.spacing(2, 2, 1),
    },
    '& .MuiButton-root': {
      minWidth: 46,
    },
  },
}))(Card)

export const MuiBox = withStyles((theme) => ({
  root: {
    '& .MuiBox-root': {
      margin: theme.spacing(0, 1.5, 0, 0),

      '& .MuiTypography-root': {
        fontWeight: 500,
        display: 'inline-block',
        color: theme.palette.action,
        marginRight: theme.spacing(0.5),
      },
    },
  },
}))(Box)

export const DialogTitle = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    '& .MuiIconButton-root': {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  },
}))(MuiDialogTitle)

export const DialogContent = withStyles(() => ({
  root: {
    minHeight: '255px',
    borderBottom: 'none',
  },
}))(MuiDialogContent)

export const Toolbar = withStyles((theme) => ({
  root: {
    minHeight: 50,
    '& .MuiBreadcrumbs-root': {
      margin: theme.spacing(0.9, 0),
      '& .MuiTypography-root': {
        display: 'flex',
        alignItems: 'center',
        '& .MuiSvgIcon-root': {
          marginRight: theme.spacing(1.5),
        },
      },
    },
  },
}))(MuiToolbar)

export const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

export const FormControl = withStyles((theme) => ({
  root: {
    '& .MuiInputLabel-formControl': {
      fontSize: '0.8rem',
      marginBottom: 0,
      textAlign: 'right',
    },
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      right: 8,
      bottom: 8,
    },
  },
}))(MuiFormControl)

export const IconButton = withStyles((theme) => ({
  root: {
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
      fontSize: '1.8rem',
    },
  },
}))(MuiIconButton)

export const Breadcrumbs = withStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(0.5),
      width: 20,
      height: 20,
    },
    '& .MuiTypography-root': {
      display: 'flex',
    },
  },
}))(MuiBreadcrumbs)

export const MuiGrid = withStyles(() => ({
  root: {
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 20,
  },
}))(Grid)
export const Menu = withStyles((theme) => ({
  paper: {
    border: `1px solid ${theme.palette.grey[300]}`,
    boxShadow: `1px 1px 12px 0 ${theme.palette.grey[400]}`,
  },
}))(MuiMenu)
