import React, { useState, useEffect } from 'react'
import { Translate } from 'react-localize-redux'
import { groupBy } from 'lodash'
import { connect } from 'react-redux'
import { Paper, Box, Grid, Typography, Divider } from '@material-ui/core'
import { articles as articlesModule } from 'redux/modules'
import { InnrHead } from 'containers'
import ArticleItem from './ArticleItem'

import PROP_TYPES from 'constants/propTypes'

const NewArticles = ({ activeLocale, favoritesArticles, fetchArticles }) => {
  const [articles, changeArticles] = useState([])

  useEffect(() => {
    fetchArticles({
      order_by: 'date',
      limit: 4,
      locale: activeLocale,
    }).then(({ response: { data = [] } }) => changeArticles(data))
  }, [activeLocale])

  const articlesWithoutFirst = [...articles].splice(1)

  const { 0: firstLineIndexes = [] } = groupBy(
    articlesWithoutFirst.map((a, index) => index),
    index => index % 1
  )

  const firstLineArticles = firstLineIndexes.map(
    index => articlesWithoutFirst[index]
  )

  const getIsFavorite = id => Boolean(favoritesArticles.findById(id))

  return (
    <>
      <Paper component={Box} mt={2}>
        <InnrHead
          title={
            <Typography variant="h4">
              <Translate id="articles.latest-article" />
            </Typography>
          }
          divider={<Divider />}
        />
        <Box px={3} pb={3}>
          <Grid container spacing={3}>
            {articles.length ? (
              <ArticleItem
                article={articles[0]}
                isFavorite={getIsFavorite(articles[0].id)}
                isFirstArticle
              />
            ) : (
              <div />
            )}
            {firstLineArticles.map(article => (
              <ArticleItem
                key={article.id}
                article={article}
                isFavorite={getIsFavorite(article.id)}
              />
            ))}
          </Grid>
        </Box>
      </Paper>
    </>
  )
}

NewArticles.defaultProps = {
  favoritesArticles: [],
}

NewArticles.propTypes = {
  activeLocale: PROP_TYPES.string.isRequired,
  favoritesArticles: PROP_TYPES.arrayOfObjects,
  fetchArticles: PROP_TYPES.func.isRequired,
}

export default connect(
  ({ favorites }) => ({ favoritesArticles: favorites.items.articles }),
  { fetchArticles: articlesModule.fetchArticles }
)(NewArticles)
