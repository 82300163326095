import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import { connect } from 'react-redux'
import { generalEvents } from 'redux/modules'
import AddReasonDialog from './AddReasonDialog'
import { useStyles } from './styles'
import PropTypes from 'prop-types'

const Source = ({
  menuItems,
  defaultSelectedItem,
  buttonLabel,
  updateEventParticipant,
  eventPlayerUuid,
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedItem, setSelectedItem] = useState(null)
  const [availabilityStatus, setAvailabilityStatus] = useState(null)
  const [open, setOpen] = useState(false)

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const handleSubmit = (values) => {
    const data = {
      reason: values.reason,
      availability: availabilityStatus,
    }

    updateEventParticipant(eventPlayerUuid, data).then(() => {
      // Just assign unavailable icon to show icon on list as selected value
      setSelectedItem(menuItems[1].icon)

      handleClose()
      setAvailabilityStatus(null)
    })
  }

  const handleChange = (item) => {
    let data = {}
    handleMenuClose()

    // If player unavailable then ask for "reason" before sending request
    if (item.key === 'not_available') {
      setAvailabilityStatus(item.value)
      handleOpen()

      return
    }

    // Handle both availability, card status of player and pass request body accordingly
    if (item.type === 'status') {
      data = {
        tag: item.value,
      }
    } else {
      data = {
        availability: item.value,
      }
    }
    // Assign list item icon to show icon on list as selected value
    setSelectedItem(item.icon)

    updateEventParticipant(eventPlayerUuid, data)
  }

  useEffect(() => {
    if (defaultSelectedItem && menuItems.length > 0) {
      const tempItem = menuItems.find(({ key }) => defaultSelectedItem === key)
      setSelectedItem(tempItem ? tempItem?.icon : menuItems[0].icon)
    }
  }, [defaultSelectedItem])

  return (
    <>
      {open && (
        <AddReasonDialog
          open={open}
          handleClose={handleClose}
          onSubmit={handleSubmit}
        />
      )}
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleMenuOpen}
        variant="outlined"
        className={classes.button}
        size="small"
        endIcon={anchorEl ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      >
        {selectedItem || buttonLabel}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        className={classes.menu}
      >
        {menuItems.map((item) => (
          <MenuItem key={item.key} onClick={() => handleChange(item)}>
            {item.icon}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

Source.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.object.isRequired),
  defaultSelectedItem: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  updateEventParticipant: PropTypes.func.isRequired,
  eventPlayerUuid: PropTypes.string.isRequired,
}

export default connect(null, {
  updateEventParticipant: generalEvents.updateEventParticipant,
})(Source)
