import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import parse from 'autosuggest-highlight/parse'
import throttle from 'lodash/throttle'
import { Translate } from 'react-localize-redux'

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };
const placesService = {current: null};

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "#255f64",
    marginRight: theme.spacing(1),
  }
}))

const PlacesSearchField = ({
  eventData,
  changeFormData,
  savedValue,
  onFieldChange
}) => {
  const classes = useStyles()
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyDeUIvoBuLmewm7jOtrVrnPDqtkHb4xVdI&libraries=places",
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  const setFieldsOnchange=(event, newValue)=>{
    setOptions(newValue ? [newValue, ...options] : options);
    setValue(newValue);
    let postalCode = null
    let street = null
    let city = null
    let country = null
    let lat = null
    let lng = null
    let location = null
    let shortaddress = null
    let state = null

    if (newValue) {
      location = newValue.description;
      const placeId = newValue.place_id;
      placesService.current = new window.google.maps.places.PlacesService(document.createElement("div")).getDetails({placeId,fields: ["address_components","geometry"]},
      details => {
        details?.address_components?.forEach(entry => {
          switch (entry.types?.[0]) {
            case "postal_code":
              postalCode = entry.long_name
              break;
            case "street_number":
              street = entry.long_name
                break;
            case "route":
                if (street) {
                  street = `${entry.long_name} ${street}`
                }else{
                  street = entry.long_name
                }
                break;
            case "locality":
              city = entry.long_name
              break;
            case "postal_town":
              if (!city) {
                city = entry.long_name
              }
              break;
            case "administrative_area_level_2":
              state = entry.long_name
              break;
            case "country":
              country = entry.long_name
              break;
            default:
              break;
          }
        })
        if (street && city) {
          shortaddress = `${street}, ${city}`;
        }else if(street && !city){
          shortaddress = street;
        }else{
          shortaddress = city;
        }
        lat = details?.geometry?.location?.lat() || null
        lng = details?.geometry?.location?.lng() || null
        onFieldChange({lat,lng,postal_code:postalCode,city,country,street,location,short_address:shortaddress,state})
        changeFormData({ ...eventData, lat,lng,postal_code:postalCode,city,country,street,location,short_address:shortaddress,state })
      });      
    }else{
      onFieldChange({lat,lng,postal_code:postalCode,city,country,street,location,short_address:shortaddress,state})
      changeFormData({ ...eventData, lat:null,lng:null,postal_code:null,city:null,country:null,street:null,location:null,short_address:null,state:null })
    }
  }
  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Translate>
      {({ translate }) => (
        <Autocomplete
          id="google-map-demo"
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.description
          }
          filterOptions={(x) => x}
          options={options}
          autoComplete
          includeInputInList
          freeSolo
          filterSelectedOptions
          value={savedValue}
          // noOptionsText={translate('events.no-option-map-search')}
          onChange={(event, newValue)=>setFieldsOnchange(event, newValue)}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => (
            <TextField 
            {...params} 
            variant="outlined" 
            fullWidth 
            id="location"
            name="location"
            margin="dense"
            label={translate('events.location')}
            />
            
          )}
          renderOption={(option) => {
            const matches =
              option.structured_formatting.main_text_matched_substrings
            const parts = parse(
              option.structured_formatting.main_text,
              matches.map((match) => [match.offset, match.offset + match.length]),
            )

            return (
              <Grid container alignItems="center">
                <Grid item>
                  <LocationOnIcon className={classes.icon} />
                </Grid>
                <Grid item xs>
                  {parts.map((part) => (
                    <span
                      key={part.id}
                      style={{ fontWeight: part.highlight ? 700 : 400 }}
                    >
                      {part.text}
                    </span>
                  ))}

                  <Typography variant="body2" color="textSecondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            )
          }}
          
        />
      )}
    </Translate>
  );
}
PlacesSearchField.defaultProps = {
  eventData: null, 
  onFieldChange:()=>0,
  savedValue:'',
}
PlacesSearchField.propTypes = {
  eventData: PropTypes.shape(),
  changeFormData: PropTypes.func.isRequired,
  savedValue:PropTypes.string,
  onFieldChange: PropTypes.func
}
export default PlacesSearchField