import React from 'react'
import PropTypes from 'prop-types'
import { Radio, FormLabel, FormHelperText, Box } from '@material-ui/core'
import PROP_TYPES from 'constants/propTypes'

const RadioGroup = ({ input, meta, label, options }) => {
  const error = meta.touched && meta.error

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <FormLabel component={Box} mb={0}>
        {label} {label && ':'}
      </FormLabel>
      <Box display="flex" alignItems="center">
        {options.map(({ value, title }) => (
          <>
            <Radio
              key={value}
              value={value}
              checked={value === input.value}
              onChange={() => input.onChange(value)}
            />{' '}
            {title}
          </>
        ))}
      </Box>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </Box>
  )
}

RadioGroup.defaultProps = {
  label: '',
  options: [],
}

RadioGroup.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
  label: PropTypes.string,
  options: PROP_TYPES.options,
}

export default RadioGroup
