import React from 'react'
import TaskGroupLoader from 'loaders/TaskGroupLoader'
import TaskGroupContainer from './TaskGroupContainer'
import PROP_TYPES from 'constants/propTypes'

const Task = ({
  match: {
    params: { taskGroupId },
  },
}) => {
  return (
    <TaskGroupLoader taskGroupId={+taskGroupId}>
      <TaskGroupContainer />
    </TaskGroupLoader>
  )
}

Task.propTypes = {
  match: PROP_TYPES.match.isRequired,
}
export default Task
