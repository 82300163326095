export const actionsTypesGenerator = name => ({
  request: `${name}_REQUEST`,
  success: `${name}_SUCCESS`,
  failure: `${name}_FAILURE`,
})

export const createUrlParamsFromObject = data => {
  return Object.entries(data)
    .map(([key, val]) => `${key}=${val}`)
    .join('&')
}

export const createUrlWithParamsFromObject = (path, data) => {
  if (!Object.values(data).length) {
    return path
  }

  return `${path}?${createUrlParamsFromObject(data)}`
}

export const updateItems = (items, data) => {
  return items.findById(data.id)
    ? items.updateById(data.id, data)
    : [...items, data]
}

export const onRequest = state => ({ ...state, isLoading: true })

export const onSuccess = state => ({ ...state, isLoading: false, error: null })

export const onFailure = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload,
})
