import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Container, Typography } from '@material-ui/core';

const NoMatch = () => (
  <Container>
    <Typography variant='p'>No match</Typography>
    <Link to="/">
      <Button>Go to main page</Button>
    </Link>
  </Container>
)

export default NoMatch
