import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { taskGroups } from 'redux/modules'
import Loader from './Loader'
import PROP_TYPES from 'constants/propTypes'

const TaskGroupLoader = ({ taskGroupId, fetchTaskGroup, children, ...restProps }) => {
  const fetchMethod = (params) => fetchTaskGroup(taskGroupId,params)
  const triggers = [taskGroupId]

  return (
    <Loader fetchMethod={fetchMethod} triggers={triggers} {...restProps}>
      {children}
    </Loader>
  )
}

TaskGroupLoader.propTypes = {
  children: PROP_TYPES.children.isRequired,
  taskGroupId: PROP_TYPES.id.isRequired,
  fetchTaskGroup: PropTypes.func.isRequired,
}

export default connect(null, { fetchTaskGroup: taskGroups.fetchTaskGroup })(TaskGroupLoader)
