import React from 'react'
import { Translate } from 'react-localize-redux'
import PublishIcon from '@material-ui/icons/Publish'
import { DroppableArea, CollapsibleOnlyHide } from 'components'
import { Box, Paper } from '@material-ui/core'
import { InnrHead } from 'containers'
import VideosList from './VideosList'
import PROP_TYPES from 'constants/propTypes'

const Completed = ({ videos }) => (
  <Paper>
    <InnrHead title={<Translate id="videos.completed" />} />
    <Box>
      <DroppableArea relation="completed">
        <>
          <strong>
            <Translate id="videos.drag-finished-videos" />
          </strong>
          <PublishIcon />
        </>
      </DroppableArea>
      <CollapsibleOnlyHide>
        <VideosList videos={videos} relation="completed" />
      </CollapsibleOnlyHide>
    </Box>
  </Paper>
)

Completed.defaultProps = {
  videos: [],
}

Completed.propTypes = {
  videos: PROP_TYPES.arrayOfObjects,
}

export default Completed
