import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Box } from '@material-ui/core'

const TabPanel = (props) => {
  const { children, value, index, padding, margin, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={padding} m={margin}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}
TabPanel.defaultProps = {
  padding: 3,
  margin: 0,
}
TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  padding: PropTypes.number,
  margin: PropTypes.number,
}

export default TabPanel
