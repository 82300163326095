import React from 'react'
import {
  ArticleLoader,
  FavoritesLoader,
  ArticlesCategoriesLoader,
} from 'loaders'
import ArticleContainer from './ArticleContainer'
import PROP_TYPES from 'constants/propTypes'

const Article = ({
  history,
  location,
  match,
  match: {
    params: { articleId },
  },
}) => (
  <ArticleLoader articleId={articleId} alwaysRenderChildren passIsLoading>
    <ArticlesCategoriesLoader>
      <FavoritesLoader entity="articles">
        <ArticleContainer history={history} location={location} match={match} />
      </FavoritesLoader>
    </ArticlesCategoriesLoader>
  </ArticleLoader>
)

Article.propTypes = {
  history: PROP_TYPES.history.isRequired,
  location: PROP_TYPES.location.isRequired,
  match: PROP_TYPES.match.isRequired,
}

export default Article
