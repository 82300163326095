import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { users } from 'redux/modules'
import Loader from './Loader'
import PROP_TYPES from 'constants/propTypes'

const UserLoader = ({ fetchUser, children, ...restProps }) => {
  return (
    <Loader fetchMethod={fetchUser} {...restProps}>
      {children}
    </Loader>
  )
}

UserLoader.propTypes = {
  children: PROP_TYPES.children.isRequired,
  fetchUser: PropTypes.func.isRequired,
}

export default connect(
  null,
  { fetchUser: users.fetchUser }
)(UserLoader)
