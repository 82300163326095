import React from 'react'
import { EventsLoader } from 'loaders'
import TrainingsCalendarContainer from './TrainingsCalendarContainer'

const TrainingsCalendar = () => (
  <EventsLoader alwaysRenderChildren>
    <TrainingsCalendarContainer />
  </EventsLoader>
)

export default TrainingsCalendar
