import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core"
import { Translate } from "react-localize-redux"

const TransferPlayerConfirm = ({ open, onClose, onSubmit }) => {
  return (
    <Dialog
      open={ open }
      onClose={ onClose }
    >
      <DialogTitle>
        <Translate id="movePlayers.are-you-sure"/>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Translate id="movePlayers.once-the-player-is-moved"/>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={ onClose } variant="contained">
          <Translate id="button.close"/>
        </Button>
        <Button onClick={ onSubmit } autoFocus color="primary" variant="contained">
          <Translate id="button.ok"/>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TransferPlayerConfirm
