import { PLAYER_POSITIONS } from 'constants/player'

export default players => {
  const result = {}

  PLAYER_POSITIONS.forEach(({ value, pluralValue }) => {
    result[pluralValue] = players.filter(({ position }) => position === value)
  })

  return result
}
