import React from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { MATCH_HIGHLIGHT_TEAMS } from 'constants/match'

const TeamSelection = ({ selectedTeam, handleTeam, ownTeamName }) => {
  return (
    <FormControl variant="outlined" margin="dense">
      <InputLabel id="demo-simple-select-label">
        <Translate id="team.team" />
      </InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="match-type-selected-outlined"
        value={selectedTeam}
        onChange={(event) => handleTeam(event.target.value)}
        label={<Translate id="team.team" />}
      >
        <MenuItem value={MATCH_HIGHLIGHT_TEAMS.own}>{ownTeamName}</MenuItem>
        <MenuItem value={MATCH_HIGHLIGHT_TEAMS.opponent}>
          <Translate id="team.opponent" />
        </MenuItem>
      </Select>
    </FormControl>
  )
}

TeamSelection.propTypes = {
  handleTeam: PropTypes.func.isRequired,
  selectedTeam: PropTypes.string.isRequired,
  ownTeamName: PropTypes.string.isRequired,
}
export default connect(({ team }) => ({
  ownTeamName: team.name,
}))(TeamSelection)
