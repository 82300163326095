import React from 'react'
import { Translate } from 'react-localize-redux'

export const TASK_GROUP_QUESTION_TYPES = [
  { type: 'TEXT', title: <Translate id="task-management.task-question.text" /> },
  { type: 'NUMBER', title: <Translate id="task-management.task-question.number" /> },
  { type: 'RATING', title: <Translate id="task-management.task-question.rating" /> },
]

export const TASK_GROUP_DUE_DATES = [
  { value: '-5', title: <Translate id="task-management.due-date-selection.5-days-prior" /> },
  { value: '-4', title: <Translate id="task-management.due-date-selection.4-days-prior" /> },
  { value: '-3', title: <Translate id="task-management.due-date-selection.3-days-prior" /> },
  { value: '-2', title: <Translate id="task-management.due-date-selection.2-days-prior" /> },
  { value: '-1', title: <Translate id="task-management.due-date-selection.1-days-prior" /> },
  { value: '0', title: <Translate id="task-management.due-date-selection.on-event-day" /> },
  { value: '1', title: <Translate id="task-management.due-date-selection.1-day-after" /> },
  { value: '2', title: <Translate id="task-management.due-date-selection.2-days-after" /> },
  { value: '3', title: <Translate id="task-management.due-date-selection.3-days-after" /> },
  { value: '4', title: <Translate id="task-management.due-date-selection.4-days-after" /> },
  { value: '5', title: <Translate id="task-management.due-date-selection.5-days-after" /> },
]

export const TASK_SERIES_DUE_DATES = [
  { value: '-5', title: <Translate id="task-management.due-date-task-series-selection.5-days-prior" /> },
  { value: '-4', title: <Translate id="task-management.due-date-task-series-selection.4-days-prior" /> },
  { value: '-3', title: <Translate id="task-management.due-date-task-series-selection.3-days-prior" /> },
  { value: '-2', title: <Translate id="task-management.due-date-task-series-selection.2-days-prior" /> },
  { value: '-1', title: <Translate id="task-management.due-date-task-series-selection.1-days-prior" /> },
  { value: '0', title: <Translate id="task-management.due-date-task-series-selection.on-task-day" /> },
  { value: '1', title: <Translate id="task-management.due-date-task-series-selection.1-day-after" /> },
  { value: '2', title: <Translate id="task-management.due-date-task-series-selection.2-days-after" /> },
  { value: '3', title: <Translate id="task-management.due-date-task-series-selection.3-days-after" /> },
  { value: '4', title: <Translate id="task-management.due-date-task-series-selection.4-days-after" /> },
  { value: '5', title: <Translate id="task-management.due-date-task-series-selection.5-days-after" /> },
]
