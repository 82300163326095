import React, { useEffect, useState } from 'react'
import {
  Box,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import ControlPointIcon from '@material-ui/icons/ControlPoint'
import { connect } from 'react-redux'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import DeleteIcon from '@material-ui/icons/Delete'
import { AlertDialog, LoadingSpinner } from 'components'
import LensIcon from '@material-ui/icons/Lens'
import PropTypes from 'prop-types'
import {
  formatCostumDateForUI,
  formatUtcDateTimeToUserTimezoneDateTime,
} from 'utils/formatDate'
import {
  createAttachmentTemporarilyUrl,
  deletePlayerAttachment,
  getPlayerDocuments,
  updateAttachmentVisibility,
} from '../api'
import { useStyles } from './styles'

const OtherDocuments = ({
  toggleOtherDocumentUploadDialog,
  playerUuid,
  datetimeFormat,
  timezone,
  isAddedOrDeletedDocument,
  setIsAddedOrDeletedDocument,
}) => {
  const [documents, setDocuments] = useState([])
  const [loading, setLoading] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false)
  const [selectedAttachmentUuid, setSelectedAttachmentUuid] = useState(null)
  const [error, setError] = useState(false)
  const classes = useStyles()

  const toggleAlertOpen = () => {
    setAlertOpen(!alertOpen)
  }

  const handleAttachmentVisibilityChange = (attachmentUuid, visibility) => {
    updateAttachmentVisibility(attachmentUuid, { visibility }).then(() => {
      setDocuments(
        documents.map((doc) => {
          if (doc.uuid === attachmentUuid) {
            return {
              ...doc,
              visibility,
            }
          }

          return doc
        })
      )
    })
  }

  const handlePlayerAttachmentDelete = (attachmentUuid) => {
    setSelectedAttachmentUuid(attachmentUuid)
    toggleAlertOpen()
  }

  const handleAlertConfirm = () => {
    deletePlayerAttachment(selectedAttachmentUuid).then(() => {
      setDocuments(documents.filterByUuid(selectedAttachmentUuid))
    })
    toggleAlertOpen()
    setSelectedAttachmentUuid(null)
  }

  const handleDocumentTitleClick = (attachmentUuid) => {
    createAttachmentTemporarilyUrl(attachmentUuid).then((res) => {
      const attachmentUrl = res.data
      window.open(attachmentUrl, '_blank')
    })
  }

  const getPlayerAttachments = () => {
    getPlayerDocuments(playerUuid)
      .then((res) => {
        setDocuments(res.data)
        setLoading(false)
      })
      .catch((err) => {
        console.error(err)

        setError(true)
        setLoading(false)
      })
  }

  // When first time component loaded
  useEffect(() => {
    setLoading(true)
    setError(false)

    getPlayerAttachments()
  }, [])

  // When any document added or deleted
  useEffect(() => {
    if (isAddedOrDeletedDocument) {
      getPlayerAttachments()
      setIsAddedOrDeletedDocument(false)
    }
  }, [isAddedOrDeletedDocument])

  return (
    <>
      {alertOpen && (
        <AlertDialog
          open={alertOpen}
          onClose={toggleAlertOpen}
          onSuccess={handleAlertConfirm}
          onCancel={toggleAlertOpen}
          title={<Translate id="button.delete" />}
          subTitle={<Translate id="events.are-you-sure-you-want-to-delete" />}
          submitButtonLabel={<Translate id="button.ok" />}
          cancelButtonLabel={<Translate id="button.close" />}
        />
      )}
      {!loading && documents.length > 0 && (
        <Grid container className={classes.maxHeight}>
          <Grid item xs={12}>
            <List className={classes.paddingDiv}>
              {documents.map((doc) => (
                <ListItem key={doc.uuid} divider disableGutters>
                  <ListItemIcon style={{ minWidth: '35px' }}>
                    <DescriptionOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.listItemText}
                    primary={
                      <Tooltip title={doc.name} placement="top" arrow>
                        <Typography
                          variant="body2"
                          className={classes.listItemTextEllipsis}
                          onClick={() => handleDocumentTitleClick(doc.uuid)}
                          noWrap
                        >
                          {doc.name}
                        </Typography>
                      </Tooltip>
                    }
                    secondary={
                      <Grid container spacing={1}>
                        <Grid item>
                          <small>
                            {formatCostumDateForUI(
                              formatUtcDateTimeToUserTimezoneDateTime(
                                doc.created_at,
                                timezone
                              ),
                              datetimeFormat
                            )}
                          </small>
                        </Grid>
                        <Grid item>
                          <LensIcon
                            color="disabled"
                            style={{ fontSize: '7px' }}
                          />
                        </Grid>
                        <Grid item>
                          <small>
                            <Translate id="team.uploaded-by" />{' '}
                            {doc.author_first_name} {doc.author_last_name}
                          </small>
                        </Grid>
                      </Grid>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Grid container alignItems="center">
                      {doc.can.includes('update') && (
                        <Grid item>
                          <FormGroup aria-label="position" row>
                            <FormControlLabel
                              value="bottom"
                              control={
                                <Switch
                                  color="secondary"
                                  size="small"
                                  checked={doc.visibility}
                                  onChange={() =>
                                    handleAttachmentVisibilityChange(
                                      doc.uuid,
                                      !doc.visibility
                                    )
                                  }
                                />
                              }
                              label={
                                <small>
                                  <Translate id="team.visible-to-players" />
                                </small>
                              }
                              labelPlacement="bottom"
                            />
                          </FormGroup>
                        </Grid>
                      )}
                      {doc.can.includes('delete') && (
                        <Grid item>
                          <Tooltip
                            title={<Translate id="button.delete" />}
                            placement="top"
                            arrow
                          >
                            <IconButton
                              size="small"
                              onClick={() =>
                                handlePlayerAttachmentDelete(doc.uuid)
                              }
                            >
                              <DeleteIcon fontSize="inherit" />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      )}
                    </Grid>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      )}

      {loading && <LoadingSpinner />}

      {!loading && documents.length === 0 && (
        <Grid
          container
          className={classes.minHeight}
          direction="column"
          justifyContent="center"
        >
          {error && <Box textAlign="center"> Something went wrong! </Box>}
          {!error && documents.length === 0 && (
            <Grid item xs={12}>
              <Box textAlign="center">
                <IconButton
                  onClick={toggleOtherDocumentUploadDialog}
                  color="primary"
                >
                  <ControlPointIcon />
                </IconButton>
                <Typography variant="h6">
                  <Translate id="team.no-documents-text" />
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      )}
    </>
  )
}

OtherDocuments.propTypes = {
  toggleOtherDocumentUploadDialog: PropTypes.func.isRequired,
  playerUuid: PropTypes.string.isRequired,
  datetimeFormat: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
  isAddedOrDeletedDocument: PropTypes.shape().isRequired,
  setIsAddedOrDeletedDocument: PropTypes.func.isRequired,
}
export default connect(({ players, auth }) => ({
  playerUuid: players.current.uuid,
  datetimeFormat: auth.user.datetime_format,
  timezone: auth.user.time_zone,
}))(OtherDocuments)
