import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'components'
import { Translate } from 'react-localize-redux'
import SequencesImage from 'assets/images/video-sequences.svg'
import {
  Box,
  Chip,
  Typography,
  IconButton,
  CardActions,
  Tooltip,
} from '@material-ui/core'
import FavoriteIcon from '@material-ui/icons/Favorite'
import ReactPlayer from 'react-player'
import { useSequencesStyles } from '../styledComponents'

const SequenceModal = ({ sequence, onHide, open, onFavoriteToggle }) => {
  const classes = useSequencesStyles()
  return (
    <Modal
      title={sequence.title}
      onHide={onHide}
      icon={SequencesImage}
      open={open}
      maxWidth="md"
    >
      <CardActions className={classes.cardActions}>
        <Typography
          gutterBottom
          className={classes.title}
          variant="h4"
          component="h4"
          noWrap
        >
          {sequence.notes}
        </Typography>
        <Box my={1} px={2}>
          <Chip
            label={sequence.tag}
            size="small"
            color="primary"
            component={Box}
            mr={0.5}
          />
          <Tooltip
            title={<Translate id="videos.favorite" />}
            placement="top"
            arrow
          >
            <IconButton onClick={onFavoriteToggle} size="small">
              <FavoriteIcon
                color={sequence.favorite ? 'primary' : 'default'}
                fontSize="small"
              />
            </IconButton>
          </Tooltip>
        </Box>
      </CardActions>
      <Box my={1} px={2} display="flex" className={classes.videoContainer}>
        <ReactPlayer
          url={sequence.video_url}
          className={classes.reactPlayer}
          playing={false}
          controls={true}
          width="100%"
          height="100%"
        />
      </Box>
    </Modal>
  )
}

SequenceModal.propTypes = {
  sequence: PropTypes.shape().isRequired,
  onFavoriteToggle: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default SequenceModal
