import React, { useState, useEffect } from 'react'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { SubLink } from 'containers'
import { Box } from '@material-ui/core'
import { Carousel } from 'components'

import { articles as articlesModule } from 'redux/modules'
import ArticleItem from './ArticleItem'
import PROP_TYPES from 'constants/propTypes'
import { ARTICLES_ALL_DASHBOARD_ROUTE } from 'constants/routes'

const AllArticles = ({ activeLocale, favoritesArticles, fetchArticles }) => {
  const [articles, changeArticles] = useState([])

  useEffect(() => {
    fetchArticles({
      order_by: 'title',
      limit: 30,
      locale: activeLocale,
    }).then(({ response: { data = [] } }) => changeArticles(data))
  }, [activeLocale])

  const getIsFavorite = (id) => Boolean(favoritesArticles.findById(id))

  return (
    <>
      {articles.length > 0 && (
        <Box component={SubLink} to={ARTICLES_ALL_DASHBOARD_ROUTE}>
          <Translate id="articles.all-articles" />
        </Box>
      )}
      <Carousel>
        {articles.map((article) => (
          <ArticleItem
            key={article.id}
            article={article}
            isFavorite={getIsFavorite(article.id)}
          />
        ))}
      </Carousel>
    </>
  )
}

AllArticles.defaultProps = {
  favoritesArticles: [],
}

AllArticles.propTypes = {
  activeLocale: PROP_TYPES.string.isRequired,
  favoritesArticles: PROP_TYPES.arrayOfObjects,
  fetchArticles: PROP_TYPES.func.isRequired,
}

export default connect(
  ({ favorites }) => ({ favoritesArticles: favorites.items.articles }),
  { fetchArticles: articlesModule.fetchArticles }
)(AllArticles)
