import React, { useState, useEffect } from 'react'
import { Button, Container, Typography } from '@material-ui/core'
import PROP_TYPES from 'constants/propTypes'

const ErrorBoundary = ({ children }) => {
  const [error, setError] = useState(null)

  const componentDidCatch = (error) => {
    setError(error);
  };

  useEffect(() => {
    componentDidCatch()
  }, [])

  if (error) {
    return (
      <Container>
        <Typography variant="h2">Something went wrong</Typography>
        <a href="/">
          <Button variant="contained" color="primary">
            Go to main page
          </Button>
        </a>
      </Container>
    );
  }

  return children
}

ErrorBoundary.propTypes = {
  children: PROP_TYPES.children.isRequired
}

export default ErrorBoundary