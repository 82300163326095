import { matchPath } from 'react-router-dom'
import { push } from 'connected-react-router'
import urlConstructor from 'utils/urlConstructor'
import { INITIAL_ROUTE } from 'constants/routes'

const getPathnameWithTeamIdAndSeasonId = pathname => {
  const match = matchPath(pathname, {
    path: INITIAL_ROUTE,
  }) || { params: {} }

  const {
    params: { teamId, seasonId },
  } = match

  if (!teamId || !seasonId) {
    return null
  }

  return urlConstructor(teamId, seasonId)
}

export default store => next => action => {
  const { type, payload } = action

  if (type === '@@router/LOCATION_CHANGE' && payload.isFirstRendering) {
    const previousPathname = store.getState().router.location.pathname
    const params = new URLSearchParams(window.location.search).toString()

    if (payload.location.pathname !== '/' || previousPathname === '/') {
      store.dispatch(push(payload.location.pathname))

      const newPath = `${payload.location.pathname}?${params}`
      store.dispatch(push(newPath))
    } else {
      const normalizedPathname =
        getPathnameWithTeamIdAndSeasonId(previousPathname) ||
        payload.location.pathname
      const newPath = `${normalizedPathname}?${params}`

      store.dispatch(push(newPath))
    }
  }

  return next(action)
}
