import React from 'react'
import Carousel from 'react-slick'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import grey from '@material-ui/core/colors/grey'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { IconButton } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .slick-next': {
      right: -13,
    },
    '& .slick-prev': {
      left: -13,
      '& .MuiSvgIcon-root': {
        marginLeft: 3,
      },
    },
  },
  slickArrow: {
    zIndex: 1,
    marginTop: '-15px',
    width: 34,
    height: 34,
    position: 'absolute',
    opacity: 1,
    background: grey[200],
    borderRadius: theme.spacing(1),
    boxShadow: `0px 0px 10px ${grey[600]}`,
    '&:hover': {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '&:focus,': {
      background: theme.palette.primary.contrastText,
      color: grey[500],
    },
    '& .MuiSvgIcon-root': {
      fontSize: 18,
      textAlign: 'center',
    },
    '&:before, &:after': {
      display: 'none',
    },
  },
}))

const PrevArrowIcon = ({ onClick }) => {
  const classes = useStyles()

  return (
    <IconButton
      className={`slick-prev slick-arrow ${classes.slickArrow}`}
      onClick={onClick}
      aria-label="Previous"
    >
      <ArrowBackIosIcon />
    </IconButton>
  )
}

const NextArrowIcon = ({ onClick }) => {
  const classes = useStyles()

  return (
    <IconButton
      className={`slick-next slick-arrow ${classes.slickArrow}`}
      onClick={onClick}
      aria-label="Next"
    >
      <ArrowForwardIosIcon />
    </IconButton>
  )
}

const OwnCarousel = ({ children }) => {
  const classes = useStyles()
  const settings = {
    className: 'slider variable-width',
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    variableWidth: true,
    prevArrow: <PrevArrowIcon />,
    nextArrow: <NextArrowIcon />,
  }

  return (
    <Carousel {...settings} className={classes.root}>
      {children}
    </Carousel>
  )
}

OwnCarousel.propTypes = {
  children: PropTypes.node.isRequired,
}

export default OwnCarousel