import React from 'react'
import { connect } from 'react-redux'
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from '@material-ui/core'
import { SubLink } from 'containers'
import { Card, ActionWithPrompt } from 'components'
import { lineups as lineupsModule } from 'redux/modules'
import PROP_TYPES from 'constants/propTypes'
import { LINEUP_CREATE_ROUTE, LINEUP_EDIT_ROUTE } from 'constants/routes'

/**
 * @todo: #roles check permissions or move functionality
 *
 * Currently lineups are global, not assigned to clubs or teams. Can be managed only by "old root" users.
 * Lineups management disabled as temporary solution.
 */
const LineupsDashboardContainer = ({ lineups, deleteLineup }) => {
  const canManageLineups =
    process.env.REACT_APP_ENABLE_LINEUPS_MANAGEMENT === 'true' || false
  const canCreate = canManageLineups
  const canUpdate = canManageLineups
  const canDelete = canManageLineups

  return (
    <Card>
      <Card.Title className="d-flex justify-content-between">
        <span>Lineups</span>
        {canCreate && (
          <SubLink to={LINEUP_CREATE_ROUTE}>
            <Button variant="contained" color="secondary">
              Create new
            </Button>
          </SubLink>
        )}
      </Card.Title>
      <Table>
        <TableBody>
          {lineups.map((lineup) => (
            <TableRow key={lineup.uuid}>
              <TableCell>{lineup.title}</TableCell>
              <TableCell>
                <div className="d-flex justify-content-end">
                  {canUpdate && (
                    <SubLink
                      to={LINEUP_EDIT_ROUTE.populateURLParams({
                        lineupUuid: lineup.uuid,
                      })}
                    >
                      <Button variant="contained" color="primary">
                        Edit
                      </Button>
                    </SubLink>
                  )}
                  {canDelete && (
                    <ActionWithPrompt
                      className="text-danger border-danger"
                      onSuccess={() => deleteLineup(lineup.uuid)}
                      message="Are you sure you want to delete the Lineup?"
                      title="Delete"
                    />
                  )}
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  )
}

LineupsDashboardContainer.defaultProps = {
  lineups: [],
}

LineupsDashboardContainer.propTypes = {
  lineups: PROP_TYPES.arrayOfObjects,
  deleteLineup: PROP_TYPES.func.isRequired,
}

export default connect(
  ({ lineups }) => ({
    lineups: lineups.items,
  }),
  {
    deleteLineup: lineupsModule.deleteLineup,
  }
)(LineupsDashboardContainer)
