import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Grid from '@material-ui/core/Grid'
import {
  Card,
  CardContent,
  Box,
  CardHeader,
  Typography,
  makeStyles,
  Button,
} from '@material-ui/core'
import BookmarkIcon from '@material-ui/icons/Bookmark'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import AlertDialog from 'components/AlertDialog'
import FieldArea from './FieldArea'
import LineupsFilter from './LineupsFilter'
import PlayersListsContainer from './PlayersListsContainer'
import AddLineupDialog from './AddLineupDialog'

const useStyles = makeStyles((theme) => ({
  cardContent: {
    backgroundColor: theme.palette.grey[100],
  },
}))

const LineUpContainer = ({ lineups, participants, matchLineup }) => {
  const classes = useStyles()
  const [lineupPositions, setLineupPositions] = useState([])
  const [addLineupDialogOpen, setAddLineupDialogOpen] = useState(false)
  const [isEmptyLineup, setIsEmptyLineup] = useState(false)

  const handleAddLineupDialog = () => {
    const participantsOnField = participants.filter((p) => p.participant)
    if (participantsOnField.length === 0) {
      setIsEmptyLineup(true)

      return
    }

    setAddLineupDialogOpen(true)
  }

  const handleAddLineupDialogClose = () => {
    setAddLineupDialogOpen(false)
  }

  useEffect(() => {
    if (participants && matchLineup) {
      let tempPostions = []

      let tempLineup = lineups.find((l) => l.uuid === matchLineup.uuid)

      // #important
      // If tempLineup empty means match lineup is a saved lineup and its deleted by coach or management
      // But it will not affect matches which has this lineup selected so will assign it and use its positions
      if (!tempLineup && matchLineup.deleted_at) {
        tempLineup = matchLineup
      }

      tempPostions = tempLineup.lineup.map((position) => {
        const matchingPlayers = participants.filter(
          (p) =>
            (position.x === `${p.position_x}` &&
              position.y === `${p.position_y}`) || // Compared as string because lineup contains positions as string and not float
            // Player should be in saved lineup and participant in match to render on the field
            (position.player_uuid === p.player_uuid && p.participant)
        )

        if (matchingPlayers.length > 0) {
          return {
            ...position,
            matchPlayer: matchingPlayers[0], // Array contains only one matching player
          }
        }

        return {
          x: position.x,
          y: position.y,
          player_uuid: position.player_uuid,
        }
      })
      setLineupPositions(tempPostions)
    }
  }, [participants, matchLineup])

  return (
    <Card>
      {isEmptyLineup && (
        <AlertDialog
          open={isEmptyLineup}
          onClose={() => setIsEmptyLineup(false)}
          onSuccess={() => setIsEmptyLineup(false)}
          onCancel={() => setIsEmptyLineup(false)}
          title={<Translate id="matches.save-lineup" />}
          subTitle={<Translate id="matches.empty-lineup-save-alert" />}
          submitButtonLabel={<Translate id="button.ok" />}
          cancelButtonLabel={<Translate id="button.close" />}
        />
      )}
      {addLineupDialogOpen && (
        <AddLineupDialog
          open={addLineupDialogOpen}
          handleClose={handleAddLineupDialogClose}
        />
      )}
      <CardHeader
        action={
          <Button onClick={handleAddLineupDialog}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <BookmarkIcon fontSize="small" />
              <Typography variant="caption">
                <Translate id="matches.save-lineup" />
              </Typography>
            </Grid>
          </Button>
        }
        title={
          <LineupsFilter
            lineups={lineups}
            lineupPositions={lineupPositions}
            setLineupPositions={setLineupPositions}
          />
        }
      />
      <CardContent component={Box} m={0} p={0} className={classes.cardContent}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12} sm={12} lg={6}>
            <FieldArea lineupPositions={lineupPositions} />
          </Grid>
          <Grid item md={6} xs={12} sm={12} lg={6}>
            <PlayersListsContainer players={participants} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

LineUpContainer.propTypes = {
  lineups: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  participants: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  matchLineup: PropTypes.shape().isRequired,
}

export default compose(
  connect(({ matches: { current }, lineups: { items } }) => ({
    participants: current.relationships.participants,
    matchLineup: current.relationships.lineup,
    lineups: items,
  }))
)(LineUpContainer)
