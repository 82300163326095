import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Typography, TableBody, TableCell, TableRow } from '@material-ui/core'
import { SubLink } from 'containers'
import CloseIcon from '@material-ui/icons/Close'
import DoneIcon from '@material-ui/icons/Done'
import { connect } from 'react-redux'
import { LoadingSpinner } from 'components'
import { useStyles, StickyTableCell } from '../style/styled'
import {
  Avatar,
  Player,
  Table,
  TableContainer,
} from '../style/styledComponents'
import { formatCostumDateForUI } from '../../../../utils/formatDate'
import EnhancedTableHead from '../EnhancedTableHead'
import { PLAYER_POSITIONS } from 'constants/player'
import { TEAM_DASHBOARD_ROUTE } from 'constants/routes'

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }

  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order

    return a[1] - b[1]
  })

  return stabilizedThis.map((el) => el[0])
}

const AttendanceTable = ({ rows, theme, dateFormat, loading }) => {
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('first_name')
  const [isSticky] = useState(true)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }
  const classes = useStyles(theme)

  const [headCells, setHeadCells] = useState([])

  useEffect(() => {
    if (rows && rows.length > 0) {
      setHeadCells([
        ...rows[0].events.map((d) => ({
          id: d.id,
          numeric: false,
          disablePadding: false,
          label: formatCostumDateForUI(d.date, dateFormat),
        })),
      ])
    } else {
      setHeadCells([])
    }
  }, [rows])

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            stickyHeader
            aria-label="sticky table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              headCells={headCells}
              isSticky={isSticky}
              orderBy={['first_name', 'events_attended']}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy)).map(
                (row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`

                  return (
                    <TableRow hover tabIndex={-1} key={row.uuid}>
                      <StickyTableCell className={classes.headCellFs}>
                        <TableCell component="th" id={labelId} scope="row">
                          <Player
                            component={SubLink}
                            to={`${TEAM_DASHBOARD_ROUTE}/player/${row.uuid}/overview`}
                          >
                            {row.avatar_image !== null ? (
                              <Avatar
                                alt={row.first_name}
                                src={row.avatar_image}
                              />
                            ) : (
                              <Avatar alt={row.first_name} />
                            )}
                            <div>
                              <Typography variant="h4">
                                {`${row.first_name} ${row.last_name}`}
                              </Typography>
                              <Typography noWrap>
                                {
                                  PLAYER_POSITIONS.find(
                                    (p) => p.value === row.position
                                  ).title
                                }
                              </Typography>
                            </div>
                          </Player>
                        </TableCell>
                        <TableCell align="left" className={classes.cell}>
                          {row.events_attended} (
                          {Math.round(
                            (row.events_attended * 100) / row.total_events
                          )}
                          %)
                        </TableCell>
                      </StickyTableCell>
                      {row.events.map((t) => {
                        return t.attended ? (
                          <TableCell align="left" className={classes.cell}>
                            <DoneIcon color="primary" fontSize="small" />
                          </TableCell>
                        ) : (
                          <TableCell align="left">
                            <CloseIcon color="disabled" fontSize="small" />
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}

AttendanceTable.defaultProps = {
  loading: false,
}

AttendanceTable.propTypes = {
  rows: PropTypes.shape().isRequired,
  dateFormat: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  theme: PropTypes.oneOfType([
    PropTypes.shape().isRequired,
    PropTypes.oneOf([null]).isRequired,
  ]).isRequired,
}

export default connect(
  ({ auth }) => ({
    dateFormat: auth.user.datetime_format,
  }),
  null
)(AttendanceTable)
