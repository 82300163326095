const urlConstructor = (teamId, seasonId, restPath = '') => {
  if (teamId && seasonId) {
    return `/team/${teamId}/season/${seasonId}${
      restPath === '/' ? '' : restPath
    }`
  }

  return `${restPath}`
}

export const createSubUrl = ({ params: { teamId, seasonId } }, path = '') =>
  urlConstructor(teamId, seasonId, path)

export default urlConstructor
