import { getRequest, postRequest, deleteRequest, putRequest } from 'utils/axios'

export const fetchSkills = (teamId, params = {}) => {
  const url = `/teams/${teamId}/skills`

  return getRequest(url, params)
}
export const createTeamSkill = (teamId, data) => {
  const url = `/teams/${teamId}/skills`

  return postRequest(url, data)
}
export const updateTeamSkill = (skillId, data) => {
  const url = `/skills/${skillId}`

  return putRequest(url, data)
}
export const deleteTeamSkill = (skillId) => {
  const url = `/skills/${skillId}`

  return deleteRequest(url)
}
