import { withTheme } from '@material-ui/core'
import { rgba } from 'polished'
import React from 'react'
import { Radar } from 'react-chartjs-2'
import PropTypes from 'prop-types'

const RadarChart = ({ theme, skills }) => {
  const firstRadarDataColor = rgba(theme.palette.primary.main, 0.3)
  const firstRadarDataBorderColor = rgba(theme.palette.primary.main, 1)

  // Split chart label in 2 words substrings in each line to avoid long texts in a single line
  // To fix long labels text overlapping issues
  const adjustChartLabel = (skill) => {
    const skillSplitArr = skill.split(' ')
    if (skillSplitArr.length > 2) {
      const maxWordCountEachRow = 2
      let count = 0
      const skillArr = []
      let tempArr = []
      let tempStr = ''

      skillSplitArr.map((s, key) => {
        count += 1

        if (count <= maxWordCountEachRow) {
          tempArr.push(s)
        }
        if (count === maxWordCountEachRow || key === skillSplitArr.length - 1) {
          tempStr = tempArr.join(' ')
          skillArr.push(tempStr)
          count = 0
          tempArr = []
        }

        return true
      })

      return skillArr
    }

    return skill.charAt(0).toUpperCase() + skill.slice(1)
  }

  const radarData = {
    labels: skills.map((p) => adjustChartLabel(p.name)),
    datasets: [
      {
        backgroundColor: firstRadarDataColor,
        borderColor: firstRadarDataBorderColor,
        pointBorderColor: firstRadarDataColor,
        pointBackgrounColor: firstRadarDataColor,
        pointRadius: 1,
        pointBorderWidth: 1,
        data: skills.map((p) => p.skill_avg),
      },
    ],
    options: {
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        r: {
          beginAtZero: true,
          ticks: {
            stepSize: 10,
            display: false,
          },
          pointLabels: {
            fontSize: 16,

            display: true,
          },
        },
      },
    },
  }

  return <Radar data={radarData} options={radarData.options} />
}

RadarChart.propTypes = {
  theme: PropTypes.shape().isRequired,
  skills: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      skill_avg: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
}

const RadarChartTeamSkills = withTheme(RadarChart)

export default RadarChartTeamSkills
