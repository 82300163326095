import React, { useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import { ArticleCreateEditForm } from 'containers'
import { articles } from 'redux/modules'
import { createSubUrl } from 'utils/urlConstructor'
import getTargetTranslationFields from 'utils/getTargetTranslationFields'
import { toast } from 'react-toastify'
import { ARTICLES_DASHBOARD_ROUTE } from 'constants/routes'
import PROP_TYPES from 'constants/propTypes'

const FIELDS_WITH_TRANSLATION = ['title', 'body']

const ArticleEditContainer = ({
  history,
  match,
  location: { fromUrl, fromPathname = 'Articles' },
  article: { translations, image_url: image, podcast, video, ...article },
  articlesCategories,
  updateArticle,
  activeLanguage: { code: activeLocale },
}) => {
  const linkBack = fromUrl || createSubUrl(match, ARTICLES_DASHBOARD_ROUTE)

  const goBack = () => history.push(linkBack)
  const [selectedType, setSelectedType] = useState('article')

  const onSubmit = (values) => {
    let data = { ...values }

    if (selectedType === 'video') {
      delete data.podcast
      delete data.body

      // @tofix Fix validation using JOI if possible.
      if (!data.video) {
        toast.error('Select a video first')

        return
      }
    } else if (selectedType === 'podcast') {
      delete data.video
      delete data.body

      // @tofix Fix validation using JOI if possible.
      if (!data.podcast) {
        toast.error('Select an audio first')

        return
      }
    } else {
      delete data.video
      delete data.podcast

      // @tofix Fix validation using JOI if possible.
      if (!data.body) {
        toast.error('Please explain the article content')

        return
      }
    }

    data = { ...data, type: selectedType }

    updateArticle(match.params.articleId, data).then(goBack)
  }

  return (
    <ArticleCreateEditForm
      form="articleEdit"
      initialValues={{
        ...article,
        image,
        podcast: podcast ? podcast.full_url : null,
        video: video ? video.full_url : null,
        ...getTargetTranslationFields(
          translations,
          activeLocale,
          FIELDS_WITH_TRANSLATION
        ),
        locale: activeLocale,
      }}
      fromPath={{
        value: linkBack,
        title: fromPathname,
      }}
      onSubmit={onSubmit}
      articlesCategories={articlesCategories}
      isEditForm
      setSelectedType={setSelectedType}
    />
  )
}

ArticleEditContainer.defaultProps = {
  articlesCategories: [],
}

ArticleEditContainer.propTypes = {
  history: PROP_TYPES.history.isRequired,
  match: PROP_TYPES.match.isRequired,
  location: PROP_TYPES.location.isRequired,
  article: PROP_TYPES.shape().isRequired,
  articlesCategories: PROP_TYPES.arrayOfObjects,
  updateArticle: PROP_TYPES.func.isRequired,
  activeLanguage: PROP_TYPES.shape().isRequired,
}

export default compose(
  connect(
    ({
      articles: {
        current: { articlesCategories, ...article },
      },
    }) => ({ article, articlesCategories }),
    {
      updateArticle: articles.updateArticle,
    }
  ),
  withLocalize
)(ArticleEditContainer)
