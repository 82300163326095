import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Box, Link, Button, Typography, Avatar } from '@material-ui/core'
import axios from 'axios'
import GoogleLogo from 'assets/images/google_icon.png'
import FacebookLogo from 'assets/images/facebook-logo.png'
import useStyles from './styleComponents'

window.REACT_APP_API_URL = process.env.REACT_APP_API_ENDPOINT

const SocialLogin = ({ page, history, theme, googleLink, facebookLink }) => {
  const classes = useStyles(theme)

  const [socialLoginLink, changeLoginLink] = React.useState({
    google: '',
    facebook: '',
  })

  const {
    location: { search },
  } = history

  if (search) {
    localStorage.setItem('utmtags', search)
  }

  // @todo: can't it be done on front-end via .env?
  const getSocalLinks = () => {
    axios
      .get(`${window.REACT_APP_API_ENDPOINT}/social/redirect/urls?page=${page}`)
      .then((res) => {
        if (res.status === 200) {
          return res.data
        }
        throw Error
      })
      .then((res) => {
        if (res) {
          changeLoginLink({
            google: res.google,
            facebook: res.facebook,
          })
        }
      })
      .catch(() => {})
  }

  React.useEffect(() => {
    getSocalLinks()

    return () => {
      localStorage.removeItem('utmtags')
    }
  }, [])

  return (
    <>
      <Box mt={3} mb={3}>
        <Grid container spacing={3}>
          <Grid item md={12} className="orDivider">
            <hr />
            <Typography className="orDividerText">or</Typography>
          </Grid>
        </Grid>
      </Box>
      <Box mb={2}>
        <Grid container spacing={2} className="socialWrap">
          <Grid item xs={12}>
            <Button
              variant="outlined"
              size="small"
              href={socialLoginLink.google}
              className={classes.socialLogin}
              component={Link}
              startIcon={<Avatar alt="Google" src={GoogleLogo} />}
            >
              {googleLink}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              size="small"
              href={socialLoginLink.facebook}
              className={classes.socialLogin}
              component={Link}
              startIcon={<Avatar alt="Google" src={FacebookLogo} />}
            >
              {facebookLink}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

SocialLogin.propTypes = {
  page: PropTypes.string.isRequired,
  history: PropTypes.shape().isRequired,
  googleLink:PropTypes.shape().isRequired,
  facebookLink:PropTypes.shape().isRequired,
}
export default SocialLogin
