import { handleActions, combineActions } from 'redux-actions'
import { actionsTypesGenerator, onFailure, onRequest, onSuccess } from 'utils/reduxHelpers'
import initialState from '../initialState'

const namespace = 'EVENTS'

const FETCH_SEASONS_EVENTS = actionsTypesGenerator(`FETCH_${namespace}`)
const FETCH_SEASONS_RECENT_EVENTS = actionsTypesGenerator(`FETCH_RECENT_${namespace}`)
const FETCH_SEASONS_CALENDAR_EVENTS = actionsTypesGenerator(`FETCH_Calendar_${namespace}`)

export const fetchSeasonEvents = (seasonId,params) => ({
  types: FETCH_SEASONS_EVENTS,
  callAPI: {
    method: 'GET',
    entity: 'Events',
    path: `/events/${seasonId}/paginated_events`,
    params: {...params, limit:10}
  },
})
export const fetchSeasonRecentEvents = (seasonId,params) => ({
  types: FETCH_SEASONS_RECENT_EVENTS,
  callAPI: {
    method: 'GET',
    entity: 'Events',
    path: `/events/${seasonId}/get_recent_events`,
    params
  },
})
export const fetchCalendarEvents = (seasonId,params) => ({
  types: FETCH_SEASONS_CALENDAR_EVENTS,
  callAPI: {
    method: 'GET',
    entity: 'Events',
    path: `/events/${seasonId}/get_calendar_events`,
    params
  },
})

export default handleActions(
  {
    [combineActions(
      FETCH_SEASONS_EVENTS.request,
      FETCH_SEASONS_RECENT_EVENTS.request,
      FETCH_SEASONS_CALENDAR_EVENTS.request
      )]: onRequest,
    [FETCH_SEASONS_EVENTS.success]: (state, { response: { data = {} } }) =>
      onSuccess({ ...state, items: data }),
    [FETCH_SEASONS_RECENT_EVENTS.success]: (state, { response: { data:recent = {} } }) =>
      onSuccess({ ...state, recent }),
    [FETCH_SEASONS_CALENDAR_EVENTS.success]: (state, { response: { data:calendarEvents = {} } }) =>
      onSuccess({ ...state, calendarEvents }),
    [combineActions(
      FETCH_SEASONS_EVENTS.failure,
      FETCH_SEASONS_RECENT_EVENTS.failure,
      FETCH_SEASONS_CALENDAR_EVENTS.failure,
    )]: onFailure,
  },
  initialState.events
)
