import React from 'react'
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import PlayerTaskRow from './PlayerTaskRow'
import PROP_TYPES from 'constants/propTypes'

const useRowStyles = makeStyles({
  root: {
    '& table thead th:first-child': {
      position: 'sticky',
      left: 0,
      background: 'white',
    },
    '& table tbody th': {
      position: 'sticky',
      left: 0,
      background: 'white',
    },
  },
})

const TasksTable = ({ taskGroup, dateFormat, event }) => {
  const classes = useRowStyles()

  return (
    <TableContainer className={classes.root}>
      <Table aria-label="tasks table">
        <TableHead>
          <TableRow>
            <TableCell style={{ minWidth: '200px' }}>
              <Translate id="task-management.assignees" />
            </TableCell>
            <TableCell>
              <Translate id="player.status" />
            </TableCell>
            {taskGroup?.questions?.map((ques) => (
              <TableCell key={ques.id} style={{ minWidth: '150px' }}>
                {ques.question}
              </TableCell>
            ))}
            <TableCell style={{ minWidth: '150px' }}>
              <Translate id="task-management.completion-date" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <PlayerTaskRow event={event} dateFormat={dateFormat} taskGroup={taskGroup} />
        </TableBody>
      </Table>
    </TableContainer>
  )
}
TasksTable.propTypes = {
  event: PROP_TYPES.shape().isRequired,
  taskGroup: PROP_TYPES.shape().isRequired,
  dateFormat: PROP_TYPES.string.isRequired,
}
export default TasksTable
