export default [
  {
    value: 'group_a',
    title: 'Group A',
  },
  {
    value: 'group_b',
    title: 'Group B',
  },
  {
    value: 'group_c',
    title: 'Group C',
  },
  {
    value: 'group_d',
    title: 'Group D',
  },
]
