import { styled } from '@material-ui/core/styles';

export default styled('textarea')(({ theme }) => ({
  width: '100%',
  height: '100%',
  padding: theme.spacing(1.5),
  borderRadius: theme.spacing(1),
  resize: 'none',
  background: 'transparent',
  border: `1px solid ${theme.palette.grey[400]}`,
  '& .teamTextArea': {
    height: '7rem',
  },
  '& .player-development-tracking': {
    minHeight: '8.438rem',
  },
}))
