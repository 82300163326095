import { getRequest } from 'utils/axios'

export const getPlayerTransferHistory = (playerUuid) => {
  const url = `v2/players/${playerUuid}/transfer-history`

  return getRequest(url)
}

export const getPlayerReport = (reportUuid) => {
  const url = `v2/player-report/${reportUuid}`

  return getRequest(url)
}

