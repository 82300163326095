import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { matches } from 'redux/modules'
import Loader from './Loader'
import PROP_TYPES from 'constants/propTypes'

const MatchLoader = props => {
  const { children, matchId, fetchMatch, ...restProps } = props

  const fetchMethod = params => fetchMatch(matchId, params)
  const triggers = [matchId]

  return (
    <Loader fetchMethod={fetchMethod} triggers={triggers} {...restProps}>
      {children}
    </Loader>
  )
}

MatchLoader.propTypes = {
  children: PROP_TYPES.children.isRequired,
  matchId: PropTypes.string.isRequired,
  fetchMatch: PropTypes.func.isRequired,
}

export default connect(
  null,
  { fetchMatch: matches.fetchMatch }
)(MatchLoader)
