import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { videos } from 'redux/modules'
import Loader from './Loader'
import PROP_TYPES from 'constants/propTypes'

const VideoLoader = props => {
  const { children, videoId, fetchVideo, ...restProps } = props

  const fetchMethod = params => fetchVideo(videoId, params)
  const triggers = [videoId]

  return (
    <Loader fetchMethod={fetchMethod} triggers={triggers} {...restProps}>
      {children}
    </Loader>
  )
}

VideoLoader.propTypes = {
  children: PROP_TYPES.children.isRequired,
  videoId: PropTypes.string.isRequired,
  fetchVideo: PropTypes.func.isRequired,
}

export default connect(
  null,
  { fetchVideo: videos.fetchVideo }
)(VideoLoader)
