import { styled } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import PlayIconSVG from 'assets/images/video-play.svg';
import Fast from '../../assets/images/fast-forward.png'
import Muted from '../../assets/images/muted.png'
import PauseButton from '../../assets/images/pause-button.png'
import PlayButton from '../../assets/images/play-button.png'
import Speaker from '../../assets/images/speaker.png'

export const Button = styled(Box)({
  fontSize: 16,
  cursor: 'pointer',
  margin: '0 2px',
  width: 20,
  height: 20,
  textAlign:'center',
  border: 'var(--dark-grey-solid) 1px',
  borderRadius: 10,
})

export const VideoButton = styled(Button)({
  color: 'var(--dark)',
  position: 'relative',
  border: '1px solid var(--dark)',
  '& .play': {
    width: 12,
    height: 12,
    backgroundImage: `url(${PlayButton})`,
    backgroundSize: 12,
    position: 'absolute',
    left: 0,
    right: 0,
    margin: '0 auto',
    top: 4,
    backgroundRepeat: 'no-repeat',
  },
  '& .pause': {
    width: 12,
    height: 12,
    background: `url(${PauseButton})`,
    position: 'absolute',
    backgroundSize: 12,
    left: 0,
    right: 0,
    margin: '0 auto',
    top: 4,
    backgroundRepeat: 'no-repeat',
  },
});

export const SpeedButton = styled(Button)({
  color: 'var(--dark)',
  border: '1px solid var(--dark)',
  fontSize: 12,
  width: 36,
  '& .fast': {
    width: 10,
    height: 10,
    marginLeft: 2,
    background: `url(${Fast})`,
    top: 4,
    backgroundSize: 10,
    backgroundRepeat: 'no-repeat',
  },
})

export const MutedButton = styled(Button)({
  color: 'var(--dark)',
  border: '1px solid var(--dark)',
  position: 'relative',
  '& .speaker': {
    width: 14,
    height: 14,
    background: `url(${Speaker})`,
    position: 'absolute',
    backgroundSize: 14,
    backgroundRepeat: 'no-repeat',
    imageRendering: 'auto',
    left: 0,
    right: 0,
    margin: '0 auto',
    top: 3,
  },
  '& .muted': {
    width: 13,
    height: 13,
    background: `url(${Muted})`,
    position: 'absolute',
    backgroundSize: 13,
    backgroundRepeat: 'no-repeat',
    left: 0,
    right: 0,
    margin: '0 auto',
    top: 3,
  },
})

export const Tag = styled(Box)(({ positionLeft, color, isNewSequence }) => ({
  '&:before': {
    content: '"\\261F"',
    fontSize: 24,
  },
  position: 'absolute',
  top: -30,
  color: prop => prop.color || 'green',
  left: prop => `${prop.positionLeft}%`,
  ... prop => (prop.isNewSequence ? { cursor: 'e-resize' } : {}),
}))

export const PlayIcon = styled(Box)({
  position: 'absolute',
  left: '50%',
  top: '50%',
  margin: '-75px 0 0 -75px',
  zIndex: 1,
  width: 150,
  height: 150,
  background: `url(${PlayIconSVG}) no-repeat center`,
  backgroundSize: 'contain',
  cursor: 'pointer',
})

export const TagButton = styled(Box)({
  position: 'absolute',
  left: 20,
  top: 20,
  zIndex: 1,
  fontWeight: 'bold',
  height: 30,
  minWidth: 100,
  lineHeight:'25px',
  textAlign:'center',
  padding: '0 12px',
  borderRadius: 16,
  cursor: 'pointer',
})

export const AddTag = styled(TagButton)({
  color: 'var(--secondary-green)',
  border: 'var(--secondary-green) solid 2px',
})

export const StopTag = styled(TagButton)({
  color: 'salmon',
  background: 'rgba(250, 128, 114, 0.3)',
  border: 'salmon solid 2px',
})
