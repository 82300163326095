import { store } from 'redux/store'
import { BASIC } from 'constants/plans'

const getPlan = () => {
  const state = store.getState()
  const { plan } = state.team ? state.team : BASIC

  return plan.replace('_annual', '').toUpperCase()
}

export default getPlan
