import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withLocalize } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import { Box as MuiBox } from '@material-ui/core'
import { setLocale, getLocale } from 'utils/localstorage'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { auth as authModule } from 'redux/modules'
import { Box } from '../styledComponents'

const LanguageToggle = ({ languages, setActiveLanguage, user, updateUser }) => {
  setLocale(getLocale())
  const [langs, setLangs] = useState(
    languages.map((p) => {
      const lang = { ...p }

      return {
        value: lang.code,
        title: lang.name,
        active: lang.code === getLocale(),
      }
    })
  )

  const updateLanguages = (tempLang) => {
    setLangs(
      langs.map((p) => {
        const lang = { ...p }
        if (lang.value === tempLang) {
          lang.active = true
        } else {
          lang.active = false
        }

        return lang
      })
    )
  }

  const handleLang = (code) => {
    setActiveLanguage(code)
    setLocale(code)

    updateLanguages(code)

    // Update user language
    updateUser({ id: user.id, language: code, preventToastNotification: true })
  }

  useEffect(() => {
    if (!user.language) {
      setActiveLanguage(getLocale())
    } else {
      setActiveLanguage(user.language)
    }

    updateLanguages(user.language)
  }, [user.language])

  return (
    <Box px={2} py={2} textAlign="center">
      {langs.map(({ value, title, active }) => {
        return (
          <MuiBox
            variant="span"
            component="span"
            className={active ? 'active' : ''}
            key={value}
            onClick={() => handleLang(value)}
          >
            {title}
          </MuiBox>
        )
      })}
    </Box>
  )
}

LanguageToggle.propTypes = {
  languages: PropTypes.arrayOf().isRequired,
  setActiveLanguage: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired,
  updateUser: PropTypes.func.isRequired,
}

export default compose(
  connect(
    ({ auth }) => ({
      user: auth.user,
    }),
    {
      updateUser: authModule.updateUser,
    }
  ),
  withLocalize,
  withRouter
)(LanguageToggle)
