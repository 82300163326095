import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { Translate } from 'react-localize-redux'
import { InnrHead } from 'containers'
import { Button, Grid } from '@material-ui/core'
import Input from 'components/inputes/Input'
import { Field, reduxForm } from 'redux-form'
import { compose } from 'redux'
import createJoiValidator from 'utils/createJoiValidator'
import Joi from 'joi'
import PropTypes from 'prop-types'

const validationSchema = Joi.object().keys({
  reason: Joi.string().max(255),
})

const AddUnavailabilityReasonDialog = ({ open, handleClose, handleSubmit }) => {
  return (
    <Dialog onClose={handleClose} open={open} maxWidth="xs" fullWidth>
      <InnrHead title={<Translate id="matches.reason-for-unavailability" />}>
        <IconButton onClick={handleClose} size="small">
          <CloseIcon />
        </IconButton>
      </InnrHead>
      <DialogContent>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item xs={12}>
            <Field
              name="reason"
              label={<Translate id="matches.highlights.reason" />}
              component={Input}
            />
          </Grid>
          <Grid item>
            <Button color="primary" onClick={handleClose}>
              <Translate id="button.cancel" />
            </Button>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              <Translate id="button.save" />
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
AddUnavailabilityReasonDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

export default compose(
  reduxForm({
    form: 'addUnavailablityReasonForm',
    validate: createJoiValidator(validationSchema),
  })
)(AddUnavailabilityReasonDialog)
