import React, { useState } from 'react'
import ArrowDownImage from 'assets/images/arrow-down.svg'
import PropTypes from 'prop-types'
import { styled } from '@material-ui/core/styles'
import PROP_TYPES from 'constants/propTypes'

const ArrowDownIcon = styled('div')(({ isActive }) => ({
  display: 'block',
  width: '15px',
  height: '10px',
  background: `url(${ArrowDownImage}) no-repeat center`,
  backgroundSize: 'contain',
  transform: isActive ? 'rotate(180deg)' : 'rotate(0deg)',
}))

const Container = styled('div')({
  '& > div:first-child': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
})

const ToggleButton = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(2),
}))

const CollapsibleOnlyHide = ({ children, className, defaultIsActive }) => {
  const [isActive, changeIsActive] = useState(defaultIsActive)

  const onClick = () => changeIsActive(!isActive)

  return (
    <Container className={className} isActive={isActive}>
      <ToggleButton onClick={onClick} role="presentation">
        <ArrowDownIcon isActive={isActive} />
      </ToggleButton>
      {isActive && children}
    </Container>
  )
}

CollapsibleOnlyHide.defaultProps = {
  className: '',
  defaultIsActive: false,
}

CollapsibleOnlyHide.propTypes = {
  className: PropTypes.string,
  children: PROP_TYPES.children.isRequired,
  defaultIsActive: PropTypes.bool,
}

export default CollapsibleOnlyHide
