import React from 'react'
import { TeamsLoader, SeasonsLoader } from 'loaders'
import FirstEntryRouterContainer from './FirstEntryRouterContainer'

const FirstEntryRouter = () => (
  <TeamsLoader>
    <SeasonsLoader>
      <FirstEntryRouterContainer />
    </SeasonsLoader>
  </TeamsLoader>
)

export default FirstEntryRouter
