import React from 'react'
import { AllStaffLoader } from 'loaders'
import TeamAccessDashboard from './TeamAccessDashboard'
import PROP_TYPES from 'constants/propTypes'

const TeamAccess = ({
  match: {
    params: { teamId },
  },
}) => (
  <AllStaffLoader teamId={+teamId} params={{ page: 'Team', tabTitle: 'Staff' }}>
    <TeamAccessDashboard />
  </AllStaffLoader>
)

TeamAccess.propTypes = {
  match: PROP_TYPES.match.isRequired,
}

export default TeamAccess
