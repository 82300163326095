import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import PROP_TYPES from 'constants/propTypes'
import SEQUENCE_TAGS from 'constants/sequenceTags'
import { Box } from '@material-ui/core'
import { red, green, grey } from '@material-ui/core/colors'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexGrow: 1,
    alignSelf: 'center',
    position: 'relative',
    margin: '2px 2px 0',
    height: 15,
    borderRadius: 15,
    background: grey[500],
    cursor: 'pointer',
    position: 'relative',
  },
  seeked: {
    position: 'absolute',
    height: 'inherit',
    borderRadius: 'inherit',
    background: grey[400],
  },
  played: {
    position: 'absolute',
    height: 'inherit',
    borderRadius: 'inherit',
    background: green[700],
    opacity: 0.5,
    '&:hover': {
      zIndex: 10,
    },
  },
  sequences: {
    position: 'absolute',
    width: '100%',
    height: 'inherit',
    borderRadius: 'inherit',
    opacity: 0.5,
  },
  sequenceRange: {
    position: 'absolute',
    height: 'inherit',
    borderRadius: 'inherit',
    background: red[500],
  },
  sequenceRangeBorder: {
    position: 'absolute',
    height: 'inherit',
    borderRadius: 'inherit',
    '&:hover': {
      zIndex: 10,
    },
  },
  sequenceRangeLabel: {
    position: 'absolute',
    fontSize: '200%',
    width: 25,
    marginLeft: -15,
    textAlign: 'center',
    marginTop: -30,
    borderRadius: '50% 50% 0',
    transform: 'rotate(45deg)',
    '&:before': {
      content: '""',
      display: 'block',
      width: '25px',
      height: '25px',
    },
  },
}))

const ProgressRange = ({
  children,
  played,
  duration,
  onSeekChange,
  sequences,
  isNewSequence,
  id,
  changeProgressRangePosition,
}) => {
  const classes = useStyles()
  const [position, changePosition] = useState({})
  useEffect(() => {
    const element = document.getElementById(id)
    if (element) {
      const { left, width } = element.getBoundingClientRect()
      changePosition({ left, width })
      changeProgressRangePosition({ left, width })
    }
  }, [id, document.getElementById(id) && document.getElementById(id).offsetWidth])

  const [seeked, changeSeeked] = useState(0)

  const onMouseMove = e => {
    e.persist()

    if (isNewSequence) {
      return
    }

    setTimeout(() => {
      changeSeeked((e.pageX - position.left) / position.width)
    }, 50)
  }

  const resetSeeked = () => {
    setTimeout(() => {
      changeSeeked(0)
    }, 50)
  }

  return (
    <Box
      className={classes.container}
      id={id}
      onClick={e => {
        onSeekChange((e.pageX - position.left) / position.width)
      }}
      onMouseMove={onMouseMove}
      onMouseOut={resetSeeked}
      onBlur={resetSeeked}
      onDrop={e => e.preventDefault()}
      onDragOver={e => {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'move';
      }}
    >
      <Box className={classes.seeked} style={{ width: `${seeked * 100}%` }} />
      <Box className={classes.played} style={{ width: `${played * 100}%` }} />
      <Box className={classes.sequences}>
        {sequences.map(({ id: sequenceId, from, to }) => (
          <Box
            key={sequenceId}
            className={classes.sequenceRange}
            style={{ left: `${(from / duration) * 100}%`, width: `${((to - from) / duration) * 100}%` }}
          />
        ))}
      </Box>
      {sequences.map(({ id: sequenceId, from, to, tag }) => {
        const tagData =
        SEQUENCE_TAGS.find(({ value }) => value === tag) || SEQUENCE_TAGS.find(({ value }) => value === 'others')

        return (
          <Box
            key={sequenceId}
            className={classes.sequenceRangeBorder}
            style={{
              left: `${(from / duration) * 100}%`,
              width: `${((to - from) / duration) * 100}%`,
              border: `${tagData.background} solid 1px`,
              backgroundColor: `${tagData.background}`,
            }}
          />
        );
      })}
      {sequences.map(({ id: sequenceId, from, to, tag }) => {
        const tagData =
        SEQUENCE_TAGS.find(({ value }) => value === tag) || SEQUENCE_TAGS.find(({ value }) => value === 'others')

        return (
          <Box
            key={sequenceId}
            className={classes.sequenceRangeLabel}
            style={{
              left: `${((from + (to - from) / 2) / duration) * 100}%`,
              color: `${tagData.background}`,
              backgroundColor: `${tagData.background}`,
            }}
          />
        );
      })}
      {children}
    </Box>
  )
}

ProgressRange.defaultProps = {
  duration: 1,
  sequences: [],
  isNewSequence: false,
}

ProgressRange.propTypes = {
  children: PROP_TYPES.children.isRequired,
  played: PropTypes.number.isRequired,
  duration: PropTypes.number,
  onSeekChange: PropTypes.func.isRequired,
  sequences: PROP_TYPES.arrayOfObjects,
  isNewSequence: PropTypes.bool,
  id: PropTypes.string.isRequired,
  changeProgressRangePosition: PropTypes.func.isRequired,
}

export default ProgressRange