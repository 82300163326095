import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  sessionDrillRow: {
    backgroundColor: theme.palette.grey[100],
    borderRadius: '8px',
  },
  tableRowHover: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  rating: {
    color: theme.palette.primary.main,
  },
  drillIcon: {
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.secondary,
    },
  },
  createTrainingWrapper: {
    '& .MuiInputAdornment-root .MuiSvgIcon-root': {
      color: theme.palette.text.secondary,
    },
  },
  textarea: {
    '& .MuiInputBase-root': {
      height: 'unset',
      paddingTop: 'unset',
      paddingBottom: 'unset',
    },
  },
  infoIcon: {
    position: 'absolute',
    right: 25,
    top: 20,
  },
  select: {
    paddingRight: 0,
  },
  textBold: {
    fontWeight: 600,
  },
  moreVertIcon: {
    padding: 0,
  },
  dialogContainer: {
    padding: '12px 24px',
  },
  phaseMenuBox: {
    display: 'flex',
    flexDirection: 'row-reverse',
    padding: '15px',
  },
  dialogContent: {
    display: 'flex',
    height: '80vh',
    padding: 0,
  },
  gridContainer: {
    height: '100%',
    margin: 0,
  },
  mediaSection: {
    height: '100%',
    padding: theme.spacing(2.5),
  },
  detailsSection: {
    height: '100%',
    overflowY: 'auto',
    padding: theme.spacing(5),
  },
  mediaSectionContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #e8e8e8',
    borderRadius: '5px',
    padding: theme.spacing(2.5),
  },
  contentContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #e8e8e8',
    borderRadius: '5px',
    padding: '20px',
  },
  imageContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  image: {
    maxHeight: '100%',
    maxWidth: '100%',
    objectFit: 'contain',
  },
  videoContainer: {
    width: '100%',
    paddingTop: '56.25%',
    position: 'relative',
    overflow: 'hidden',
  },
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  centerText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  }
}))
