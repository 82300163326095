import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { LinearProgress } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
})

const UploadingLinearProgress = ({ progressValue }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" value={progressValue} />
        </Box>
        <Box>
          {progressValue !== 100 ? (
            <Typography variant="body2" color="textSecondary">
              {progressValue}%
            </Typography>
          ) : (
            <CheckCircleIcon fontSize="small" color="primary" />
          )}
        </Box>
      </Box>
    </div>
  )
}

UploadingLinearProgress.propTypes = {
  progressValue: PropTypes.number.isRequired,
}

export default UploadingLinearProgress
