import React from 'react'
import { withRouter } from 'react-router-dom';
import { ArticlesLoader, FavoritesLoader } from 'loaders'
import ArticlesWidgetContainer from './ArticlesWidgetContainer'

const ArticlesWidget = ({
  match: {
    params: { teamId, seasonId }
  },
  clubid
}) => (
  <ArticlesLoader
    params={{
      limit: 20,
      order_by: 'random',
      team_id: teamId,
      season_id: seasonId,
      clubid,
    }}
    alwaysRenderChildren
    passIsLoading
  >
    <FavoritesLoader entity="articles">
      <ArticlesWidgetContainer clubid={clubid}/>
    </FavoritesLoader>
  </ArticlesLoader>
)

export default withRouter(ArticlesWidget)
