import React, { useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { Box, Grid, IconButton, Typography } from '@material-ui/core'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'
import { makeStyles } from '@material-ui/core/styles'
import UploadingLinearProgress from 'components/loader/UploadingLinearProgress'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import DeleteIcon from '@material-ui/icons/Delete'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

const useStyles = makeStyles((theme) => ({
  root: { width: '100%' },
  dropZone: {
    outline: 'none',
    display: 'flex',
    width: '100%',
    height: '250px',
    overflow: 'hidden',
    textAlign: 'center',
    position: 'relative',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 'auto',
    padding: theme.spacing(5, 0),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#F4F6F8',
    border: '1px dashed rgba(0, 0, 0, 0.12)',
    '&:hover': {
      opacity: 0.72,
      cursor: 'pointer',
    },
  },
  isDragAccept: {
    color: theme.palette.success.main,
    borderColor: theme.palette.success.light,
  },
  isDragActive: {
    opacity: 0.72,
  },
  isDragReject: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.light,
  },
  media: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'calc(100% - 16px)',
    height: 'calc(100% - 16px)',
    top: 8,
    borderRadius: 8,
    objectFit: 'cover',
    position: 'absolute',
  },
  dropZoneText: {
    textAlign: 'center',
  },
  invalidHelperText: {
    display: 'block',
    color: '#f44336',
  },
  fileInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
}))

const DOCUMENT_MAX_SIZE = 10 * 1024 * 1024 // 10MB in bytes
const validFileTypes = {
  document: [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ],
}

const getFileType = (fileType) => {
  if (validFileTypes.document.includes(fileType)) {
    return 'document'
  }

  return 'unsupported'
}

const DocumentUploaderWithProgressBar = ({
  input,
  meta = {},
  isMulti = true,
  dropOrSelectMessage,
  uploadApi,
  deleteApi,
  setIsAddedOrDeletedDocument,
}) => {
  const [validSelectedFiles, setValidSelectedFiles] = useState([]) // Used to show the files list
  const [uploadProgress, setUploadProgress] = useState({}) // Used to show the progress of file upload
  const [uploadedFilesUuids, setUploadedFilesUuids] = useState({}) // Used to delete uploaded files from Uploader component
  const classes = useStyles()

  const checkSizeValidation = (rejectedFile) => {
    if (rejectedFile.size > DOCUMENT_MAX_SIZE) {
      toast.error(`File size exceeds the limit of ${DOCUMENT_MAX_SIZE}`)

      return true
    }

    return false
  }

  const handleDelete = (attachmentUuid, keyToDelete) => {
    deleteApi(attachmentUuid).then(() => {
      setIsAddedOrDeletedDocument(true)
    })
    setValidSelectedFiles(
      validSelectedFiles.filter((file) => file.key !== keyToDelete)
    )
    setUploadProgress((prevProgress) => {
      const { [keyToDelete]: _, ...remainingProgress } = prevProgress

      return remainingProgress
    })
    setUploadedFilesUuids((prevUuids) => {
      const { [keyToDelete]: _, ...remainingUuids } = prevUuids

      return remainingUuids
    })
  }

  const handleFileChange = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      const rejectedFile = rejectedFiles[0].file
      const selectedFileType = getFileType(rejectedFile.type)

      if (checkSizeValidation(rejectedFile)) {
        return false
      }

      switch (selectedFileType) {
        case 'document':
          toast.error(
            'Invalid file type. Please upload a document (.pdf / .doc / .docx / .xls / .xlsx)'
          )
          break
        default:
          toast.error('Unsupported file type.')
      }
    } else if (acceptedFiles.length > 0) {
      const newFiles = acceptedFiles.map((file) => ({
        file,
        key: Date.now(), // Unique key using Date.now()
      }))

      setValidSelectedFiles((prevFiles) => [...prevFiles, ...newFiles])

      newFiles.forEach(({ file, key }) => {
        const formData = new FormData()
        formData.append('file', file)

        uploadApi(formData, {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )
            setUploadProgress((prevProgress) => ({
              ...prevProgress,
              [key]: progress,
            }))
          },
        })
          .then((res) => {
            setIsAddedOrDeletedDocument(true)
            setUploadedFilesUuids((p) => ({
              ...p,
              [key]: res.data.uuid,
            }))

            toast.success(`File uploaded successfully`)
          })
          .catch(() => {
            toast.error(`Failed to upload file`)
          })
      })

      input.onChange(newFiles.map(({ file }) => file))
    } else {
      toast.error('Please select a file.')
    }

    return true
  }

  const getAcceptedFileTypes = () => validFileTypes.document.join(',')

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    isDragAccept,
  } = useDropzone({
    onDrop: handleFileChange,
    maxSize: DOCUMENT_MAX_SIZE,
    accept: getAcceptedFileTypes(),
    multiple: isMulti,
  })

  const onDragMessage = isDragAccept ? (
    <>
      <Box>
        <CheckCircleIcon />
      </Box>
      <Translate id="uploader.drop-or-select-file" />
    </>
  ) : (
    <>
      <Box>
        <WarningIcon />
      </Box>
      <Translate id="uploader.only-document-files-supports" />
    </>
  )

  const isInvalid = meta.touched && meta.error

  return (
    <>
      <div
        className={clsx(classes.dropZone, {
          [classes.isDragActive]: isDragActive,
          [classes.isDragAccept]: isDragAccept,
          [classes.isDragReject]: isDragReject,
        })}
      >
        <Box
          {...getRootProps()}
          className={clsx(classes.media)}
          sx={{
            top: 8,
            borderRadius: 1,
            objectFit: 'cover',
            position: 'absolute',
            width: 'calc(100% - 16px)',
            height: 'calc(100% - 16px)',
          }}
        >
          <input {...getInputProps()} />
          <Box className={clsx(classes.dropZoneText)}>
            {isDragActive ? (
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {onDragMessage}
              </Typography>
            ) : (
              <>
                <CloudUploadIcon />
                <Typography variant="body2">
                  {dropOrSelectMessage || (
                    <Translate id="uploader.drop-or-select-file" />
                  )}
                </Typography>
              </>
            )}
          </Box>
        </Box>
      </div>

      {validSelectedFiles.length > 0 && (
        <Box m={1}>
          {validSelectedFiles.map((doc) => (
            <Grid
              key={doc.key} // Use the unique key generated by Date.now()
              container
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                xs={12}
                container
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={1}>
                  <DescriptionOutlinedIcon />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="body2">{doc.file.name}</Typography>
                  <UploadingLinearProgress
                    progressValue={uploadProgress[doc.key] || 0}
                  />
                </Grid>
                <Grid xs={1}>
                  {uploadedFilesUuids[doc.key] && (
                    <Box textAlign="center">
                      <IconButton
                        size="small"
                        onClick={() =>
                          handleDelete(uploadedFilesUuids[doc.key], doc.key)
                        } // Pass both the UUID and the unique key
                      >
                        <DeleteIcon fontSize="inherit" />
                      </IconButton>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Box>
      )}

      {isInvalid && (
        <Box type="invalid" className={classes.invalidHelperText}>
          {meta.error}
        </Box>
      )}
    </>
  )
}

DocumentUploaderWithProgressBar.defaultProps = {
  meta: {},
  isMulti: true,
  dropOrSelectMessage: <Translate id="uploader.drop-or-select-file" />,
}

DocumentUploaderWithProgressBar.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape(),
  isMulti: PropTypes.bool,
  dropOrSelectMessage: PropTypes.shape(),
  uploadApi: PropTypes.func.isRequired,
  deleteApi: PropTypes.func.isRequired,
  setIsAddedOrDeletedDocument: PropTypes.func.isRequired,
}

export default DocumentUploaderWithProgressBar
