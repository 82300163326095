import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { getLoginURL } from 'utils/localstorage'
import { userlaneIdentify, userlaneInit } from 'utils/userlane'
import PROP_TYPES from 'constants/propTypes'

const CheckAuth = ({ auth, children, location: { pathname } }) => {
  if (auth.token) {
    userlaneIdentify(
      auth.userId,
      auth.user.language,
      auth.signupClubId,
      auth.signupTeamId
    )
    userlaneInit()

    return children
  }

  return (
    <Redirect
      to={{
        pathname: getLoginURL(),
        fromPathname: pathname,
      }}
    />
  )
}

CheckAuth.propTypes = {
  auth: PROP_TYPES.shape().isRequired,
  children: PROP_TYPES.children.isRequired,
  location: PROP_TYPES.location.isRequired,
}

export default compose(
  withRouter,
  connect(({ auth }) => ({
    auth,
  }))
)(CheckAuth)
