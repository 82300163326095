import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const Circle = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '110px',
  borderBottom: '1px solid #f3f3f3',
  height: '60px',
  margin: '12px auto 12px',
  textAlign: 'center',
  '@media (max-width: 1024px)': {
    width: '105px',
    height: '105px',
    '& .col-md-4 &': {
      width: '95px',
      height: '95px',
    },
  },
});

const TypographyCustom = styled(Typography)({
  fontSize: '1.5rem',
  lineHeight: '1.5',
})

const TotalValue = styled('div')({
  margin: '0 10px',
  fontSize: '1.5rem',
  lineHeight: '1.5',
  textAlign: 'center',
})

const Title = styled('div')({
  fontSize: '16px',
  marginTop: '20px',
  letterSpacing: '0.3px',
  textAlign: 'center',
})

const SummaryCircle = ({ value, maxValue, title, inverse }) => (
  <div>
    <Title inverse={inverse}>{title}</Title>
    <Circle>
      <TypographyCustom
        variant="h3"
        component="h3"
        inverse={inverse}
        isSingle={!maxValue}
      >       
        {value}
      </TypographyCustom>
    </Circle>
    <TotalValue inverse={inverse}>{maxValue}</TotalValue>
  </div>
)

SummaryCircle.defaultProps = {
  maxValue: null,
  inverse: false,
}

SummaryCircle.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]).isRequired,
  maxValue: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  title: PropTypes.string.isRequired,
  inverse: PropTypes.bool,
}

export default SummaryCircle