import { makeStyles, withStyles, TableCell } from '@material-ui/core'
import { rgba } from 'polished'

export const useStyles = makeStyles(theme => ({

    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        minHeight: '50px',
        borderBottom: '1px solid #e8e8e8',
        '& .MuiBox-root a:hover':{
          textDecoration: 'blink'
        }
      },
      csvButton: {
        margin:theme.spacing(0.95,0,0,0),
        fontSize:'0.8125rem',
        textTransform: 'uppercase',
        letterSpacing: '.3px !important',
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1,4),
       '& .MuiSvgIcon-fontSizeSmall': {
        fontSize: 18,
        lineHeight: 1.75,
       }
      },
      title: {
        flex: '1 1 30%',
      },
      boxWidth: {
        width:'calc(100% - 360px)'
      },
      cell: {
        minWidth: 110,
      },
      headCellFs: {
        '& .MuiTableCell-root:nth-child(1)': {
            width: 220,
        }
      },
      head: {
        backgroundColor: theme.palette.background.paper,
        minWidth: 110,
      },

    table: {
        position: 'relative',
        '& tr.MuiTableRow-root:hover': {
            backgroundColor: rgba(theme.Dataset.color_1, 0.1),
        },
        '& .MuiChip-root': {
            width: 100
        },
        '& .MuiTableCell-stickyHeader': {
            backgroundColor: '#fff',
        },
        '& .MuiAvatar-root': {
            boxShadow: '14px 5px 35px 0 rgba(0,0,0,0.09)',
            backgroundColor: '#eee',
            color: '#b5b5b5'
        },
        '& .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root': {
            borderTop: '1px solid rgba(224, 224, 224, 1)',
            padding: '11px 10px',
            fontSize: '.8rem',
            fontWeight: 500
        },

        '.MuiTableBody-root .MuiTableRow-root .MuiTableCell-root': {
            borderBottom: '0',
            padding: '10px'
        },
        '& .MuiBox-root.MuiBox-root-41': {
            position: 'absolute',
            left: '0',
            right: '0',
            top: '39%',
        },
        '& .MuiTableSortLabel-icon':{
          transition: 'none'
        }
    },
}))

export const StickyTableCell = withStyles((theme) => ({
    head: {
      left: 0,
      position: "sticky",
      padding: '0 !important',
      border: 0,
      borderRight: '1px solid #e8e8e8',
      zIndex: theme.zIndex.appBar + 2,
      width: 343
    },
    body: {
      backgroundColor: theme.palette.background.paper,
      minWidth: "50px",
      left: 0,
      position: "sticky",
      padding: 0,
      border: 0,
      borderRight: '1px solid #e8e8e8',
      zIndex: theme.zIndex.appBar + 1,
      width: 343
    }
  }))(TableCell);
