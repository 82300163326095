/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import { Translate } from 'react-localize-redux'
import EditIcon from '@material-ui/icons/Edit'
import { makeStyles } from '@material-ui/core/styles'
import GradeIcon from '@material-ui/icons/Grade'
import { SubLink, Avatar } from 'containers'
import PersonIcon from '@material-ui/icons/Person'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import { connect } from 'react-redux'
import { players as playersModule } from 'redux/modules'
import {
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListItemAvatar,
  Typography,
  Tooltip,
} from '@material-ui/core'
import PlayerEditDialog from 'routes/PlayerDashboard/components/PlayerEditDialog'
import PROP_TYPES from 'constants/propTypes'
import { TEAM_PLAYER_DASHBOARD_ROUTE } from 'constants/routes'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    height: 204,
    paddingTop: theme.spacing(0),
    '& .MuiList-padding': {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },
    '& .MuiListItem-root': {
      padding: theme.spacing(0),
      '& .MuiListItemText-primary': {
        '& .MuiTypography-root': {
          color: theme.palette.card.color,
          [theme.breakpoints.only('md')]: {
            fontSize: theme.typography.pxToRem(13),
          },
          [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.pxToRem(12),
          },
        },
      },
      '& .MuiListItemText-secondary': {
        fontSize: theme.typography.pxToRem(13),
        [theme.breakpoints.down('sm')]: {
          fontSize: theme.typography.pxToRem(12),
        },
      },
    },
    '& .MuiListItemSecondaryAction-root': {
      fontWeight: 600,
      display: 'flex',
      alignItems: 'center',
      '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(0.8),
      },
    },
  },
}))

const HighestRank = ({ players, title, updatePlayer }) => {
  const classes = useStyles()
  const fromPathname = ''

  const [initialValues, setInitialValues] = React.useState(null)
  const [open, setOpen] = React.useState(false)

  const handleOpen = player => {
    setOpen(true)
    setInitialValues(player)
  }
  const handleClose = () => {
    setOpen(false)
    setInitialValues(null)
  }
  const changeEditablePlayer = player => {
    players[players.findIndex(p => p.id === player.id)] = player
  }

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="h4">
            {title}
          </Typography>
        }
      />

      <CardContent className={classes.root}>
        {players
          .sort((a, b) => (a.rating < b.rating ? 1 : -1))
          .map(item => (
            <List key={item.id}>
              <ListItem key={item.id}>
                <ListItemAvatar>
                  {item.avatar !== null ? (
                    <Avatar alt={item.first_name} src={item.avatar?item.avatar.full_url:null}>
                      <PersonIcon />
                    </Avatar>
                  ) : (
                    <Avatar alt={item.first_name}>
                      {item.first_name.charAt(0)}
                    </Avatar>
                  )}
                </ListItemAvatar>
                <ListItemText
                  id={1}
                  primary={
                    <>
                      <SubLink
                        to={TEAM_PLAYER_DASHBOARD_ROUTE.populateURLParams({
                          playerId: item.id,
                        })}
                        fromPathname={fromPathname}
                        key={item.id}
                      >
                        <Typography variant="h4" component="span" noWrap>
                          {item.first_name} {item.last_name}
                        </Typography>
                      </SubLink>
                      {item.user_id ? (
                        <Translate>
                          {({ translate }) => (
                            <Tooltip title={translate('player.invited-player')}>
                              <VerifiedUserIcon
                                fontSize="small"
                                color="primary"
                              />
                            </Tooltip>
                          )}
                        </Translate>
                      ) : (
                        <Translate>
                          {({ translate }) => (
                            <Tooltip
                              title={translate('player.not-invited-player')}
                            >
                              <VerifiedUserIcon
                                fontSize="small"
                                color="error"
                              />
                            </Tooltip>
                          )}
                        </Translate>
                      )}
                      <Translate>
                        {({ translate }) => (
                          <Tooltip title={translate('team.edit-player')}>
                            <EditIcon
                              onClick={() => handleOpen(item)}
                              fontSize="small"
                            />
                          </Tooltip>
                        )}
                      </Translate>
                    </>
                  }
                  secondary={
                    <>
                      <Translate id={`player.${item.position}`} />
                    </>
                  }
                />
                <ListItemSecondaryAction style={{ right:"0px" }}>
                  <span>
                    <GradeIcon fontSize="small" color="primary" />
                  </span>
                  {item.rating ? item.rating.toFixed(2) : '0.00'}
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          ))}
      </CardContent>
      {open && initialValues && (
        <PlayerEditDialog
          open={open}
          handleOpen={handleOpen}
          handleClose={handleClose}
          initialValues={{ ...initialValues }}
          setInitialValues={setInitialValues}
          updatePlayer={updatePlayer}
          changeEditablePlayer={changeEditablePlayer}
        />
      )}
    </Card>
  )
}

HighestRank.propTypes = {
  players: PROP_TYPES.arrayOfObjects.isRequired,
  title: PROP_TYPES.string.isRequired,
  updatePlayer: PROP_TYPES.func.isRequired,
}

export default connect(
  null,
  {
    updatePlayer: playersModule.updatePlayer,
  }
)(HighestRank)
