import { styled } from '@material-ui/core/styles'

export default styled('div')(({ active }) => ({
  '&:before': {
    content: '"\\2605"',
  },
  '&:hover': {
    color: 'gold',
  },
  color: active ? 'gold' : 'lightgrey',
  cursor: 'pointer',
}));
