import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  sessionDrillRow: {
    backgroundColor: theme.palette.grey[100],
    borderRadius: '8px',
  },
  tableRowHover: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  rating: {
    color: theme.palette.primary.main,
  },
  editDrillIcon: {
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.secondary,
    },
  },
  createTrainingWrapper: {
    '& .MuiInputAdornment-root .MuiSvgIcon-root': {
      color: theme.palette.text.secondary,
    },
  },
  textarea: {
    '& .MuiInputBase-root': {
      height: 'unset',
      paddingTop: 'unset',
      paddingBottom: 'unset',
    },
  },
  infoIcon: {
    position: 'absolute',
    right: 25,
    top: 20,
  },
  select: {
    paddingRight: 0,
  },
  textBold: {
    fontWeight: 600,
  },
}))
