import React from 'react'
import PlayerEditDialog from 'routes/PlayerDashboard/components/PlayerEditDialog'
import PropTypes from 'prop-types'

const EditDialog = (props) => {
  const { onClose, open, player, updatePlayer } = props

  const handleClose = () => {
    onClose()
  }

  const handleOpen = () => {
    onClose(false)
  }

  return (
    <>
      {open && (
        <PlayerEditDialog
          open={open}
          handleOpen={handleOpen}
          handleClose={handleClose}
          initialValues={{ ...player }}
          setInitialValues={() => {}}
          updatePlayer={updatePlayer}
          changeEditablePlayer={() => {}}
        />
      )}
    </>
  )
}

EditDialog.propTypes = {
  player: PropTypes.shape().isRequired,
  updatePlayer: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default EditDialog
