import React, { useState } from 'react';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { DroppableArea } from 'components';
import { makeStyles } from '@material-ui/core/styles';
import GROUPS from '../../../groupConstant';
import PlayersList from './PlayersList';

const useStyles = makeStyles(theme => ({
  emptyGroup: {
    padding: '5px',
    '& > div': {
      marginBottom: '5px',
      minHeight: '80px',
      lineHeight: '17px',
      letterSpacing: '0.1px',
      fontWeight: 300,
    },
    '& > div > div:first-child': {
      marginBottom: '5px',
      color: theme.palette.primary.main,
    },
  },
  group: {
    display: 'flex',
    marginBottom: '5px',
    padding: '5px',
    '& > div': {
      flex: '1',
      padding: '5px',
      border: '1px solid #ededed',
      background: '#fff',
      borderRadius: '8px',
      boxShadow: '14px 5px 35px 0 rgba(0, 0, 0, 0.08)',
      maxWidth: '100%',
    },
  },
  groupTitle: {
    background: theme.palette.primary.main,
    width: '100% !important',
    minHeight: '40px !important',
    padding: '10px',
    borderRadius: '8px !important',
    fontSize: '0.9rem !important',
    fontWeight: '500 !important',
    textAlign: 'center',
    color: theme.palette.primary.contrastText,
    marginBottom: '9px !important',
  },
  summary: {
    display: 'flex',
    flexDirection: 'column',
    margin: '5px 0',
    cursor: 'pointer',
    '& > span': {
      fontSize: '22px',
      lineHeight: '25px',
      letterSpacing: '0.16px',
      color: theme.palette.secondary.main,
    },
    '& > div': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '40px',
      width: '40px',
      border: '1px solid var(--text-dark)',
      borderRadius: '50%',
      fontSize: '19px',
      lineHeight: '17px',
      letterSpacing: '0.13px',
      color: 'var(--text-dark)',
    },
  },
}));

const GroupsList = ({
  groupsCount,
  players,
  changeIsSelectedById,
  isAnyPlayerSelected,
}) => {
  const classes = useStyles();
  const [isOpen, changeIsOpen] = useState(true);

  const toggleOpen = () => changeIsOpen(!isOpen);

  const filterPlayersByGroup = group =>
    players.filter(player => player.relation === group);

  return (
    <Grid container spacing={2}>
      {GROUPS.slice(0, groupsCount).map(group => {
        const groupPlayers = filterPlayersByGroup(group.value);

        if (!groupPlayers.length) {
          return (
            <Grid item xs={12} sm={6} md={12 / groupsCount} key={group.value} className={classes.emptyGroup}>
              <div className={classes.groupTitle}>
              <Translate id={`training.${group.title.toLowerCase()}`} />
              </div>
              <DroppableArea relation={group.value}>
                <>
                  <span>
                    <Translate id="training.choose-player" />
                  </span>
                </>
              </DroppableArea>
            </Grid>
          );
        }

        return (
          <Grid item xs={12} sm={6} md={12 / groupsCount} key={group.value} className={classes.group}>
            <PlayersList
              relation={group.value}
              players={isOpen ? groupPlayers : []}
              changeIsSelectedById={changeIsSelectedById}
              isAnyPlayerSelected={isAnyPlayerSelected}
            >
              <div onClick={toggleOpen} role="presentation" className={classes.summary}>
                <div className={classes.groupTitle}>{group.title}</div>
                <div>{groupPlayers.length}</div>
              </div>
            </PlayersList>
          </Grid>
        );
      })}
    </Grid>
  );
};

GroupsList.defaultProps = {
  players: [],
  isAnyPlayerSelected: false,
};

GroupsList.propTypes = {
  groupsCount: PropTypes.oneOf([2, 3, 4]).isRequired,
  players: PropTypes.arrayOf(PropTypes.object.isRequired),
  changeIsSelectedById: PropTypes.func.isRequired,
  isAnyPlayerSelected: PropTypes.bool,
};

export default GroupsList;
