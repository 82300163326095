import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
  },
  MobileContainer: {},
  [theme.breakpoints.down('sm')]: {
    MobileContainer: {
      padding: `${theme.spacing(0)} !important`,
      '& [role="tabpanel"] .MuiBox-root': {
        marginTop: theme.spacing(1),
        padding: 0,
      },
    },
  },
}))

export default useStyles
