import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Avatar,
  Box,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  TableBody,
  Typography,
  Grid,
} from '@material-ui/core'
import PlayersTableHead from './PlayersTableHead'
import AvailabilityAndStatusSelectMenu from './AvailabilityAndStatusSelectMenu'
import PROP_TYPES from 'constants/propTypes'
import { PLAYER_AVAILABILITY_TAGS, PLAYER_STATUS_TAGS } from 'constants/match'

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: 215,
    '& .MuiTableCell-stickyHeader': {
      backgroundColor: 'white',
      padding: theme.spacing(0, 2),
      textAlign: 'center',
    },
    '& .MuiAvatar-root': {
      marginRight: theme.spacing(1.5),
      height: 30,
      width: 30,
    },
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  reason: {
    fontWeight: 'bold',
  },
  tableRow: {
    verticalAlign: 'baseline',
  },
  [theme.breakpoints.down('sm')]: {
    root: {
      '& .MuiTableCell-sizeSmall': {
        padding: theme.spacing(0.6, 1.2),
      },
      '& .MuiButton-sizeSmall': {
        padding: theme.spacing(0.4, 0.8),
        minWidth: 20,
        '& .MuiSvgIcon-fontSizeSmall': {
          fontSize: 16,
        },
      },
      '& .MuiButton-endIcon.MuiButton-iconSizeSmall': {
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0),
      },
      '& .MuiAvatar-root': {
        marginRight: theme.spacing(0.6),
        height: 26,
        width: 26,
      },
      '& .MuiTypography-noWrap': {
        width: 90,
      },
    },
  },
}))

const PlayersTable = ({ matchPlayers }) => {
  const classes = useStyles()

  return (
    <TableContainer className={classes.root}>
      <Table size="small" stickyHeader aria-label="sticky table">
        <PlayersTableHead />
        <TableBody>
          {matchPlayers.map((player) => (
            <TableRow
              key={player.uuid}
              className={player.reason && classes.tableRow}
            >
              <TableCell
                style={{ minWidth: 20 }}
                className={classes.textAlignCenter}
              >
                {player.number}
              </TableCell>
              <TableCell>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="baseline"
                >
                  <Box display="flex" alignItems="center">
                    <Avatar
                      alt={player.first_name}
                      src={player.relationships?.avatar?.full_url || ''}
                    />
                    <Typography noWrap>
                      {player.first_name} {player.last_name}
                    </Typography>
                  </Box>
                  {player.reason && (
                    <Typography variant="caption">
                      <span className={classes.reason}>Reason:</span>{' '}
                      {player.reason}
                    </Typography>
                  )}
                </Grid>
              </TableCell>
              <TableCell className={classes.textAlignCenter}>
                <AvailabilityAndStatusSelectMenu
                  menuItems={PLAYER_AVAILABILITY_TAGS}
                  buttonLabel="Tag"
                  defaultSelectedItem={player.availability?.toLowerCase()}
                  matchPlayerUuid={player.matchPlayerUuid}
                />
              </TableCell>
              <TableCell className={classes.textAlignCenter}>
                <AvailabilityAndStatusSelectMenu
                  menuItems={PLAYER_STATUS_TAGS}
                  buttonLabel="Status"
                  defaultSelectedItem={player.tag || 'none'}
                  matchPlayerUuid={player.matchPlayerUuid}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
PlayersTable.defaultProps = {
  matchPlayers: [],
}
PlayersTable.propTypes = {
  matchPlayers: PROP_TYPES.arrayOfObjects,
}
export default PlayersTable
