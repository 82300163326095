import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { drillsCategories } from 'redux/modules'
import Loader from './Loader'
import { ageClasses as ageClassesModule } from 'redux/modules'
const ClubAgeClassesLoader = ({
  children,
  clubId,
  fetchClubAgeClasses,
}) => {
  return (
    <Loader fetchMethod={() => fetchClubAgeClasses(clubId)}>{children}</Loader>
  )
}

ClubAgeClassesLoader.propTypes = {
  fetchClubAgeClasses: PropTypes.func.isRequired,
}

export default connect(
  ({ clubs }) => ({
    club: clubs.current,
  }),
  {
    fetchClubAgeClasses: ageClassesModule.fetchClubAgeClasses,
  }
)(ClubAgeClassesLoader)
