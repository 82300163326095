import React from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core"
import PropTypes from "prop-types"
import { Translate } from "react-localize-redux"

// @todo: #transfer add translations
const DeactivatePlayerDialog = ({
  open,
  onClose,
  onSubmit,
}) => {
  return (
    <Dialog open={ open } onClose={ onClose }>
      <DialogTitle>
        <Translate id="movePlayers.deactivate"/>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          <Translate id="movePlayers.are-you-sure-you-want-to-deactivate-the-player"/>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={ onClose } variant="contained">
          <Translate id="button.close"/>
        </Button>
        <Button onClick={ onSubmit } color="primary" variant="contained" autoFocus>
          <Translate id="button.ok"/>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DeactivatePlayerDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default DeactivatePlayerDialog
