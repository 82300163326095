import globalTranslations from 'translations/global.json'

export const enErrors = {
  'any.required': `{{#label}} ${globalTranslations.joi.any.required[0]}`,
  'any.empty': `{{#label}} ${globalTranslations.joi.any.empty[0]}`,
  'string.required': `{{#label}} ${globalTranslations.joi.any.required[0]}`,
  'string.empty': `{{#label}} ${globalTranslations.joi.any.empty[0]}`,
  'string.base': `{{#label}} ${globalTranslations.joi.string.base[0]}`,
  'string.min': `{{#label}} ${globalTranslations.joi.string.min[0]}`,
  'string.max': `{{#label}} ${globalTranslations.joi.string.max[0]}`,
  'string.email': `{{#label}} ${globalTranslations.joi.string.email[0]}`,
  'string.pattern.base': `{{#label}} ${globalTranslations.joi.string.regex[0]}`,
  'number.base': `{{#label}} ${globalTranslations.joi.number.base[0]}`,
  'number.min':`{{#label}} ${globalTranslations.joi.number.min[0]}`,
  'number.max':`{{#label}} ${globalTranslations.joi.number.max[0]}`,
  'array.base':`{{#label}} ${globalTranslations.joi.array.base[0]}`,
  'array.min':`{{#label}} ${globalTranslations.joi.array.min[0]}`,
  'array.max':`{{#label}} ${globalTranslations.joi.array.max[0]}`,
}

export const deErrors = {
  'any.required': `{{#label}} ${globalTranslations.joi.any.required[1]}`,
  'any.empty': `{{#label}} ${globalTranslations.joi.any.empty[1]}`,
  'string.required': `{{#label}} ${globalTranslations.joi.any.required[1]}`,
  'string.empty': `{{#label}} ${globalTranslations.joi.any.empty[1]}`,
  'string.base': `{{#label}} ${globalTranslations.joi.string.base[1]}`,
  'string.min': `{{#label}} ${globalTranslations.joi.string.min[1]}`,
  'string.max': `{{#label}} ${globalTranslations.joi.string.max[1]}`,
  'string.email': `{{#label}} ${globalTranslations.joi.string.email[1]}`,
  'string.pattern.base': `{{#label}} ${globalTranslations.joi.string.regex[1]}`,
  'number.base': `{{#label}} ${globalTranslations.joi.number.base[1]}`,
  'number.min':`{{#label}} ${globalTranslations.joi.number.min[1]}`,
  'number.max':`{{#label}} ${globalTranslations.joi.number.max[1]}`,
  'array.base':`{{#label}} ${globalTranslations.joi.array.base[1]}`,
  'array.min':`{{#label}} ${globalTranslations.joi.array.min[1]}`,
  'array.max':`{{#label}} ${globalTranslations.joi.array.max[1]}`,
}
export const esErrors = {
  'any.required': `{{#label}} ${globalTranslations.joi.any.required[2]}`,
  'any.empty': `{{#label}} ${globalTranslations.joi.any.empty[2]}`,
  'string.required': `{{#label}} ${globalTranslations.joi.any.required[2]}`,
  'string.empty': `{{#label}} ${globalTranslations.joi.any.empty[2]}`,
  'string.base': `{{#label}} ${globalTranslations.joi.string.base[2]}`,
  'string.min': `{{#label}} ${globalTranslations.joi.string.min[2]}`,
  'string.max': `{{#label}} ${globalTranslations.joi.string.max[2]}`,
  'string.email': `{{#label}} ${globalTranslations.joi.string.email[2]}`,
  'string.pattern.base': `{{#label}} ${globalTranslations.joi.string.regex[2]}`,
  'number.base': `{{#label}} ${globalTranslations.joi.number.base[2]}`,
  'number.min':`{{#label}} ${globalTranslations.joi.number.min[2]}`,
  'number.max':`{{#label}} ${globalTranslations.joi.number.max[2]}`,
  'array.base':`{{#label}} ${globalTranslations.joi.array.base[2]}`,
  'array.min':`{{#label}} ${globalTranslations.joi.array.min[2]}`,
  'array.max':`{{#label}} ${globalTranslations.joi.array.max[2]}`,
}
export const itErrors = {
  'any.required': `{{#label}} ${globalTranslations.joi.any.required[3]}`,
  'any.empty': `{{#label}} ${globalTranslations.joi.any.empty[3]}`,
  'string.required': `{{#label}} ${globalTranslations.joi.any.required[3]}`,
  'string.empty': `{{#label}} ${globalTranslations.joi.any.empty[3]}`,
  'string.base': `{{#label}} ${globalTranslations.joi.string.base[3]}`,
  'string.min': `{{#label}} ${globalTranslations.joi.string.min[3]}`,
  'string.max': `{{#label}} ${globalTranslations.joi.string.max[3]}`,
  'string.email': `{{#label}} ${globalTranslations.joi.string.email[3]}`,
  'string.pattern.base': `{{#label}} ${globalTranslations.joi.string.regex[3]}`,
  'number.base': `{{#label}} ${globalTranslations.joi.number.base[3]}`,
  'number.min':`{{#label}} ${globalTranslations.joi.number.min[3]}`,
  'number.max':`{{#label}} ${globalTranslations.joi.number.max[3]}`,
  'array.base':`{{#label}} ${globalTranslations.joi.array.base[3]}`,
  'array.min':`{{#label}} ${globalTranslations.joi.array.min[3]}`,
  'array.max':`{{#label}} ${globalTranslations.joi.array.max[3]}`,
}
export const plErrors = {
  'any.required': `{{#label}} ${globalTranslations.joi.any.required[4]}`,
  'any.empty': `{{#label}} ${globalTranslations.joi.any.empty[4]}`,
  'string.required': `{{#label}} ${globalTranslations.joi.any.required[4]}`,
  'string.empty': `{{#label}} ${globalTranslations.joi.any.empty[4]}`,
  'string.base': `{{#label}} ${globalTranslations.joi.string.base[4]}`,
  'string.min': `{{#label}} ${globalTranslations.joi.string.min[4]}`,
  'string.max': `{{#label}} ${globalTranslations.joi.string.max[4]}`,
  'string.email': `{{#label}} ${globalTranslations.joi.string.email[4]}`,
  'string.pattern.base': `{{#label}} ${globalTranslations.joi.string.regex[4]}`,
  'number.base': `{{#label}} ${globalTranslations.joi.number.base[4]}`,
  'number.min':`{{#label}} ${globalTranslations.joi.number.min[4]}`,
  'number.max':`{{#label}} ${globalTranslations.joi.number.max[4]}`,
  'array.base':`{{#label}} ${globalTranslations.joi.array.base[4]}`,
  'array.min':`{{#label}} ${globalTranslations.joi.array.min[4]}`,
  'array.max':`{{#label}} ${globalTranslations.joi.array.max[4]}`,
}
export const jpErrors = {
  'any.required': `{{#label}} ${globalTranslations.joi.any.required[5]}`,
  'any.empty': `{{#label}} ${globalTranslations.joi.any.empty[5]}`,
  'string.required': `{{#label}} ${globalTranslations.joi.any.required[5]}`,
  'string.empty': `{{#label}} ${globalTranslations.joi.any.empty[5]}`,
  'string.base': `{{#label}} ${globalTranslations.joi.string.base[5]}`,
  'string.min': `{{#label}} ${globalTranslations.joi.string.min[5]}`,
  'string.max': `{{#label}} ${globalTranslations.joi.string.max[5]}`,
  'string.email': `{{#label}} ${globalTranslations.joi.string.email[5]}`,
  'string.pattern.base': `{{#label}} ${globalTranslations.joi.string.regex[5]}`,
  'number.base': `{{#label}} ${globalTranslations.joi.number.base[5]}`,
  'number.min':`{{#label}} ${globalTranslations.joi.number.min[5]}`,
  'number.max':`{{#label}} ${globalTranslations.joi.number.max[5]}`,
  'array.base':`{{#label}} ${globalTranslations.joi.array.base[5]}`,
  'array.min':`{{#label}} ${globalTranslations.joi.array.min[5]}`,
  'array.max':`{{#label}} ${globalTranslations.joi.array.max[5]}`,
}
