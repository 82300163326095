import React from 'react'
import PropTypes from 'prop-types'
import { Droppable } from 'react-beautiful-dnd'
import PlayerPlaceholder from 'assets/images/avatar.png'
import PlayerItem from './PlayerItem'

const PLAYER_ICON_SRC = PlayerPlaceholder

const PlayersList = ({
  children,
  relation,
  players = [],
  name,
  image,
  changeIsSelectedById,
  changeIsSelectedByRelation,
  isAnyPlayerSelected,
}) => {
  const isAllSelected =
    !!players.length && players.every(({ isSelected }) => isSelected)

  const onSelectAll = () => changeIsSelectedByRelation(relation, !isAllSelected)

  return (
    <Droppable droppableId={relation} isDropDisabled={!!name}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          {children}
          <div
            style={{
              marginBottom: '-5px',
            }}
          >
            {name && (
              <PlayerItem
                name={name}
                isSelected={isAllSelected}
                id={`all-${relation}`}
                index={0}
                iconSrc={image}
                onSelect={onSelectAll}
                isAnyPlayerSelected={isAnyPlayerSelected || !players.length}
              />
            )}
            {players.map((player, index) => {
              const newIndex = name ? index + 1 : index

              const onSelect = () => changeIsSelectedById(player.uuid)

              return (
                <PlayerItem
                  key={`player-drag-${player.uuid}`}
                  name={player.name}
                  isSelected={player.isSelected}
                  id={`player_drag_${player.uuid}`}
                  index={newIndex}
                  iconSrc={player.avatar || PLAYER_ICON_SRC}
                  onSelect={onSelect}
                  isAnyPlayerSelected={isAnyPlayerSelected}
                  notAvailableReason={player.notAvailableReason}
                />
              )
            })}
          </div>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

PlayersList.defaultProps = {
  children: <div />,
  players: [],
  name: null,
  image: null,
  changeIsSelectedByRelation: () => 0,
  isAnyPlayerSelected: false,
}

PlayersList.propTypes = {
  children: PropTypes.element,
  relation: PropTypes.string.isRequired,
  players: PropTypes.arrayOf(PropTypes.object.isRequired),
  name: PropTypes.string,
  image: PropTypes.string,
  changeIsSelectedById: PropTypes.func.isRequired,
  changeIsSelectedByRelation: PropTypes.func,
  isAnyPlayerSelected: PropTypes.bool,
}

export default PlayersList
