import { styled, withStyles, makeStyles } from '@material-ui/core/styles'
import {
  Typography,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
} from '@material-ui/core'

export const Heading = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  padding: theme.spacing(0.3, 1),
  fontSize: 16,
  fontWeight: 600,
}))

export const useLayoutStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
  },
  MobileContainer: {},
  [theme.breakpoints.down('sm')]: {
    MobileContainer: {
      padding: `${theme.spacing(0)} !important`,
      '& [role="tabpanel"] .MuiBox-root': {
        marginTop: theme.spacing(1),
        padding: 0,
      },
    },
  },
}))

export const useCardStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    boxShadow: '10px 5px 35px 0 rgba(0,0,0,0.08)',
    border: '1px solid #eee',
    padding: theme.spacing(0, 1, 0),
    borderRadius: 10,
    marginBottom: 4,
    '& .MuiCardHeader-root': {
      padding: theme.spacing(0, 1),
    },
    '& .MuiCardContent-root:last-child': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
    },
    '& .MuiCardHeader-title': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
      borderRadius: theme.spacing(0, 0, 1.5, 1.5),
      padding: theme.spacing(0, 1.5),
      display: 'inline-block',
    },
  },
  [theme.breakpoints.down('sm')]: {
    root: {
      '& .MuiCardContent-root': {
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
      },
    },
  },
}))

export const useInfoStyles = makeStyles((theme) => ({
    StyledTitle: {
        position: 'relative',
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
        padding: theme.spacing(0, 1, 0),
        top: -5,
        borderRadius: '0 0 9px 9px',
        zIndex: 1078,
    
        '&:before, &:after': {
          content: '""',
          position: 'absolute',
          width: 0,
          height: 0,
          borderStyle: 'solid',
          zIndex: 1075,
          color: 'transparent',
        },
        '&:before': {
          borderBottomWidth: 41,
          borderRightWidth: 42,
          borderBottomColor: 'transparent',
          borderRightColor: theme.palette.primary.light,
          top: -4,
          left: -41,
        },
        '&:after': {
          borderBottomWidth: 41,
          borderLeftWidth: 42,
          borderTopColor: 'transparent',
          borderLeftColor: theme.palette.primary.light,
          top: -4,
          right: -41,
        },
      },

  noteContent: {
    boxShadow: '10px 5px 35px 0 rgba(0,0,0,0.08)',
  },
  styledCardHeader: {
    textAlign: 'center',
    padding: theme.spacing(2.1),
    position: 'relative',
    '& .MuiCardHeader-action': {
      position: 'absolute',
      right: 0,
      top: 0,
      margin: theme.spacing(0.5),
      [theme.breakpoints.down('sm')]: {
        zIndex: 1098,
        top: 14,
      },
    },
  },
  vsIcon: {
    backgroundColor: theme.palette.primary.light,
  },
  [theme.breakpoints.down('sm')]: {
    StyledTitle: {
        zIndex: 1,
        '&:before, &:after': {
          zIndex: 2,
        },
      },
    mobileGrid: {
      padding: `${theme.spacing(0)} !important`,
      fontSize: 11,
      '& u': {
        fontSize: 11,
      },
      '& .MuiGrid-item small': {
        display: 'none',
      },
    },
    vsIcon: {
      height: 28,
      width: 28,
    },
  },
}))

export const useInfoFormStyles = makeStyles(() => ({
  textarea: {
    '& .MuiInputBase-root': {
      height: 'unset',
      paddingTop: 'unset',
      paddingBottom: 'unset',
    },
  },
  infoIcon: {
    position: 'absolute',
    right: 25,
    top: 20,
  },
}))

export const DialogTitle = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    '& .MuiIconButton-root': {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  },
}))(MuiDialogTitle)

export const DialogActions = withStyles((theme) => ({
  root: {
    marginLeft: 'auto',
    padding: theme.spacing(1, 0, 0),
  },
}))(MuiDialogActions)

export const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    '& .MuiTypography-root': {
      marginBottom: theme.spacing(2),
    },
  },
}))(MuiDialogContent)

export const useRootStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))
