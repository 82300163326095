import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  fitnessDataBox: {
    maxHeight: '20vh',
    overflowY: 'auto',
  },
  minHeight: {
    minHeight: '27vh',
  },
  maxHeight: {
    maxHeight: '27vh',
    overflowY: 'auto',
  },
  paddingDiv: {
    padding: '.5rem 1.5rem',
  },
  listItemText: {
    maxWidth: '60%',
  },
  listItemTextEllipsis: {
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}))
