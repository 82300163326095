import React, { useState, useEffect } from 'react'
import {
  TableRow,
  TableCell,
  Collapse,
  Typography,
  Box,
} from '@material-ui/core'
import {
  attachTrainingDrill,
  deleteTrainingDrill,
  updateTrainingDrill,
  fetchTrainingDetails,
} from 'containers/ActivitiesContainer/api'
import Rating from '@material-ui/lab/Rating'
import LoadingSpinner from 'components/loader/LoadingSpinner'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import Snackbars from 'components/Snackbars'
import { useStyles } from '../styles'
import PhaseSection from './PhaseSection'

const phases = [
  { key: 'warmup', label: <Translate id="training.warmup" /> },
  { key: 'main_part', label: <Translate id="training.main_part" /> },
  { key: 'final_part', label: <Translate id="training.final_part" /> },
]

const TrainingSessionRow = ({
  training,
  drill,
  defaultOpened,
  setCreatedTrainingUuid,
}) => {
  const classes = useStyles()
  const [collapsed, setCollapsed] = useState(true)
  const [phasesData, setPhasesData] = useState({
    warmup: [],
    main_part: [],
    final_part: [],
  })
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [snackbar, changeSnackbar] = useState({
    show: false,
    message: '',
    error: false,
    info: false,
    warning: false,
  })

  useEffect(() => {
    if (!collapsed) {
      setIsLoading(true)
      setError(null)

      fetchTrainingDetails(training.uuid)
        .then((response) => {
          const fetchedTrainingDrills = response.data.relationships.drills
          const newPhasesData = { warmup: [], main_part: [], final_part: [] }
          fetchedTrainingDrills.forEach((trainingDrill) => {
            newPhasesData[trainingDrill.session_part].push({
              ...trainingDrill.relationships.drill,
              trainingDrillUuid: trainingDrill.uuid,
              customized_duration: trainingDrill.customized_duration,
            })
          })
          setPhasesData(newPhasesData)
        })
        .catch((err) => {
          console.error('Error fetching training drills:', err)
          setError('Something went wrong!')
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [collapsed, training.uuid])

  const handleToggleCollapse = () => {
    setCollapsed(!collapsed)
  }

  // Open created training collapsible and reset newly created training uuid as null
  useEffect(() => {
    if (defaultOpened) {
      handleToggleCollapse()
      setCreatedTrainingUuid(null)
    }
  }, [])

  const handleAddToPhase = (phase) => {
    const params = {
      drill_uuid: drill.uuid,
      session_part: phase,
    }
    attachTrainingDrill(training.uuid, params)
      .then((response) => {
        const trainingDrillUuid = response.data.uuid
        setPhasesData((prevPhasesData) => ({
          ...prevPhasesData,
          [phase]: [
            ...prevPhasesData[phase],
            {
              ...drill,
              trainingDrillUuid,
              customized_duration: drill.duration,
            },
          ],
        }))
      })
      .catch((err) => {
        console.error('Error adding drill to phase:', err)
        changeSnackbar({
          show: true,
          message: 'Something went wrong!',
          error: true,
        })
      })
  }

  const handleRemoveDrill = (phase, trainingDrillUuid) => {
    deleteTrainingDrill(trainingDrillUuid)
      .then(() => {
        setPhasesData((prevPhasesData) => ({
          ...prevPhasesData,
          [phase]: prevPhasesData[phase].filter(
            (phaseDrill) => phaseDrill.trainingDrillUuid !== trainingDrillUuid
          ),
        }))
      })
      .catch((err) => {
        console.error('Error removing drill from phase:', err)
        changeSnackbar({
          show: true,
          message: 'Something went wrong!',
          error: true,
        })
      })
  }

  const handleUpdateDuration = (phase, trainingDrillUuid, newDuration) => {
    // Restrict negative value
    if (newDuration < 0) return

    updateTrainingDrill(trainingDrillUuid, { customized_duration: newDuration })
      .then(() => {
        setPhasesData((prevPhasesData) => ({
          ...prevPhasesData,
          [phase]: prevPhasesData[phase].map((phaseDrill) =>
            phaseDrill.trainingDrillUuid === trainingDrillUuid
              ? { ...phaseDrill, customized_duration: newDuration }
              : phaseDrill
          ),
        }))
      })
      .catch((err) => {
        console.error('Error updating drill duration:', err)
        changeSnackbar({
          show: true,
          message: 'Something went wrong!',
          error: true,
        })
      })
  }

  return (
    <>
      {snackbar.show && <Snackbars {...snackbar} />}
      <TableRow
        key={training.uuid}
        onClick={handleToggleCollapse}
        hover
        className={classes.tableRowHover}
      >
        <TableCell>{training.date}</TableCell>
        <TableCell>{training.time}</TableCell>
        <TableCell>{training.focus}</TableCell>
        <TableCell>{training.location}</TableCell>
        <TableCell>
          <Rating
            size="small"
            value={training.intensity}
            precision={0.5}
            readOnly
            classes={{ root: classes.rating }}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell padding="none" colSpan={5}>
          <Collapse in={!collapsed} timeout="auto" unmountOnExit>
            <Box m={2}>
              {isLoading && (
                <Box display="flex" justifyContent="center" padding={2}>
                  <LoadingSpinner />
                </Box>
              )}
              {!isLoading && !error && (
                <>
                  <Typography variant="h4" component="h4" gutterBottom>
                    <Translate id="training.select-phase" />
                  </Typography>
                  {phases.map(({ key, label }) => (
                    <PhaseSection
                      key={key}
                      phase={key}
                      phaseName={label}
                      drills={phasesData[key]}
                      onAddDrill={handleAddToPhase}
                      onRemoveDrill={handleRemoveDrill}
                      onUpdateDrill={handleUpdateDuration}
                    />
                  ))}
                </>
              )}
              {error && (
                <Box textAlign="center">
                  <Typography variant="body1">{error}</Typography>
                </Box>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

TrainingSessionRow.propTypes = {
  training: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    time: PropTypes.string,
    focus: PropTypes.string,
    location: PropTypes.string,
    intensity: PropTypes.number,
  }).isRequired,
  drill: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
  }).isRequired,
  defaultOpened: PropTypes.bool.isRequired,
  setCreatedTrainingUuid: PropTypes.func.isRequired,
}

export default TrainingSessionRow
