export const THEME_SET = 'THEME_SET'
export const THEMES = {
  DEFAULT: 'DEFAULT',
  BVB: 'BVB',
  ALIANZA: 'ALIANZA',
  VFLWOLFSBURG: 'VFLWOLFSBURG',
  PSG: 'PSG',
  WESTONFC: 'WESTONFC',
  OEFB: 'OEFB',
}
