import React from 'react'
import { Dialog } from '@material-ui/core'
import PropTypes from 'prop-types'
import TrainingPackageDialogHeader from './TrainingPackageDialogHeader'
import TrainingPackageDialogBody from './TrainingPackageDialogBody'

const TrainingPackageViewDialog = ({
  trainingPackage,
  open,
  handleDialogOpen,
  duration,
}) => {
  return (
    <Dialog open={open} onClose={handleDialogOpen} fullWidth maxWidth="xl">
      <TrainingPackageDialogHeader
        trainingPackage={trainingPackage}
        onClose={handleDialogOpen}
        duration={duration}
      />
      <TrainingPackageDialogBody trainingPackage={trainingPackage} />
    </Dialog>
  )
}

TrainingPackageViewDialog.propTypes = {
  trainingPackage: PropTypes.shape().isRequired,
  open: PropTypes.bool.isRequired,
  handleDialogOpen: PropTypes.func.isRequired,
  duration: PropTypes.number.isRequired,
}

export default TrainingPackageViewDialog
