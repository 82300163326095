import React, { useEffect, useMemo, useState } from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Button,
  Grid,
} from '@material-ui/core'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded'
import { makeStyles } from '@material-ui/styles'
import { Translate } from 'react-localize-redux'
import { matchHighlights } from 'redux/modules'
import { track } from 'utils/segmentTracking'
import LoadingSpinner from 'components/loader/LoadingSpinner'
import MatchHighlightsDialog from './MatchHighlightsDialog'
import MatchHighlightsList from './MatchHighlightsList'

const useStyles = makeStyles(() => ({
  card: {
    boxShadow: 'unset',
  },
  cardContent: {
    maxHeight: '500px',
    overflowY: 'auto',
    paddingTop: '0',
  },
}))

const MatchHighlights = ({
  highlights,
  fetchMatchHighlights,
  currentMatch,
  participants,
  isLoading,
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const handleClickOpen = () => {
    setOpen(true)

    // Segment tracking
    track('WClick_MPHighlights_Add', {
      category: 'MP_Highlights',
    })
  }
  const handleClose = () => {
    setOpen(false)
  }

  const participantsList = useMemo(
    () =>
      participants.map((p) => {
        const { player } = p.relationships

        return {
          value: player.uuid,
          title: `${player.first_name} ${player.last_name}`,
        }
      }),
    [participants]
  )
  useEffect(() => {
    if (currentMatch) {
      fetchMatchHighlights(currentMatch.uuid)
    }
  }, [currentMatch])

  return (
    <Translate>
      {({ translate }) => (
        <>
          {open && (
            <MatchHighlightsDialog
              onClose={handleClose}
              participantsList={participantsList}
              open={open}
            />
          )}
          <Card className={classes.card}>
            <CardHeader
              action={
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleClickOpen}
                  >
                    {translate('matches.highlights.add-highlight')}
                  </Button>
                </div>
              }
              title={
                <div>
                  <Grid container spacing={1}>
                    <Grid item xs="auto">
                      <Typography variant="h4" component="h4">
                        {translate('matches.highlights.title')}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              }
            />
            <CardContent className={classes.cardContent}>
              {isLoading && <LoadingSpinner />}
              {!isLoading && (
                <Grid item xs={12} md={12}>
                  {highlights && (
                    <MatchHighlightsList participantsList={participantsList} />
                  )}
                  {!highlights.length > 0 && (
                    <Typography
                      variant="body2"
                      color="primary"
                      style={{ textAlign: 'center' }}
                      onClick={handleClickOpen}
                    >
                      <IconButton size="small" color="primary">
                        <AddCircleRoundedIcon />
                      </IconButton>
                      {translate(
                        'matches.highlights.start-adding-match-highlight'
                      )}
                    </Typography>
                  )}
                </Grid>
              )}
            </CardContent>
          </Card>
        </>
      )}
    </Translate>
  )
}
MatchHighlights.propTypes = {
  participants: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  highlights: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  fetchMatchHighlights: PropTypes.func.isRequired,
  currentMatch: PropTypes.shape().isRequired,
  isLoading: PropTypes.bool.isRequired,
}
export default connect(
  ({ matches: { current }, matchHighlights: { items, isLoading } }) => ({
    currentMatch: current,
    participants: current.relationships.participants,
    highlights: items,
    isLoading,
  }),
  {
    fetchMatchHighlights: matchHighlights.fetchMatchHighlights,
  }
)(MatchHighlights)
