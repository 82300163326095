import { styled } from '@material-ui/core/styles'
import AddImage from 'assets/images/icon-add.svg'
import PlayerImage from 'assets/images/player.svg'
import DummyPlayerImage from 'assets/images/player-dummy.svg'

const Body = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: 52,
  height: 50,

  '& .dashboard-team-player': {
    height: 80,
    width: 85,
  },
  [theme.breakpoints.down('sm')]: {
    width: 35,
    height: 34,
  },
}))

const DummyBody = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: 52,
  height: 50,

  '& .dashboard-team-player': {
    height: 80,
    width: 85,
  },
  margin: '24px auto',
});

export const PlayerBody = styled(Body)({
  background: `url(${PlayerImage}) no-repeat center`,
  backgroundSize: 'cover',
})

export const StaffBody = styled(Body)({
  background: `url(${PlayerImage}) no-repeat center`,
  backgroundSize: 'cover',
})

export const PlayerDummyBody = styled(DummyBody)({
  background: `url(${DummyPlayerImage}) no-repeat center`,
  backgroundSize: 'cover',
})

export const StaffDummyBody = styled(DummyBody)({
  background: `url(${DummyPlayerImage}) no-repeat center`,
  backgroundSize: 'cover',
})

export const Container = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: '10px 10px',
})

export const Number = styled('div')({
  color: '#fff',
  fontSize: 14,
  '@media (max-width: 767px)': {
    fontSize: 12,
  },
});

export const SubPosition = styled('div')({
  position: 'absolute',
  left: -3,
  top: 18,
  color: '#999999',
  fontSize: 12,
  fontWeight: 'bold',
});

export const FullName = styled('div')({
  fontSize: 12,
  fontWeight: 400,
  letterSpacing: 0.09,
  textAlign: 'center',
  color: '#7a7a7a',
});

export const Text = styled('div')({
  fontSize: 12,
  fontWeight: 400,
  letterSpacing: 0.09,
  textAlign: 'center',
  color: '#999999',
  '&.expired': {
    color: 'red',
  },
});

export const AddIcon = styled('div')({
  width: 30,
  height: 30,
  background: `url(${AddImage}) no-repeat center`,
});

export const EditAboButton = styled('a')({
  width: '100%',
  padding: '0 8px',
  background: '#0e4b4d',
  borderRadius: 8,
  color: 'white',
  display: 'inline-block',
  textAlign: 'center',
  cursor: 'pointer',
  maxHeight: 25,
  margin: '0 5px 10px 5px',
  fontSize: 12,
});