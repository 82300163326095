import React from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { Grid, Typography, Box } from '@material-ui/core'
import { Collapsible, ImagesSlider } from 'components'
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import { Breadcrumbs } from "./StyledComponents"

const DrillPanel = ({ drillList, sectionName, sectionIndex }) => {
  return (
    <>
      {drillList.map(
        (
          {
            uuid,
            title,
            description,
            relationships: { images, age_classes },
            min_players: minPlayers,
            max_players: maxPlayers,
            duration,
            drill_flow: drillFlow,
            organization,
            coaching_points: coachingPoints,
            variations,
          },
          index
        ) => (
          <Collapsible
            key={`${sectionName}.${uuid}`}
            title={`${sectionIndex}. ${title} (${index + 1})`}
            titleRight={sectionName}
            eventId={index}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Typography color="inherit">
                    <PersonAddOutlinedIcon />
                    {age_classes.map((ageClass) => ageClass.name).join(', ')}
                  </Typography>
                  <Typography color="inherit">
                    <PeopleOutlineIcon />
                    {maxPlayers > '0'
                      ? `${minPlayers} - ${maxPlayers}`
                      : `min ${minPlayers}`}
                  </Typography>
                  <Typography color="inherit">
                    <AccessTimeIcon />
                    {duration} min
                  </Typography>
                </Breadcrumbs>
                <Typography variant="body1" component="p">
                  {description}
                </Typography>
              </Grid>
              <Grid item md={6}>
                {images.length > 0 && <ImagesSlider images={images} />}
              </Grid>
              <Grid item md={6}>
                <Box>
                  <Typography variant="h3" component="h3" color="primary">
                    <Translate id="drills.drill-flow" />
                  </Typography>
                  <Box mt={2} mb={2}>
                    <div dangerouslySetInnerHTML={{ __html: drillFlow }} />
                  </Box>
                </Box>
                <Box>
                  <Typography variant="h3" component="h3" color="primary">
                    <Translate id="drills.organization" />
                  </Typography>
                  <Box mt={2} mb={2}>
                    <div dangerouslySetInnerHTML={{ __html: organization }} />
                  </Box>
                </Box>
                <Box>
                  <Typography variant="h3" component="h3" color="primary">
                    <Translate id="drills.coaching-points" />
                  </Typography>
                  <Box mt={2} mb={2}>
                    <div dangerouslySetInnerHTML={{ __html: coachingPoints }} />
                  </Box>
                </Box>
                <Box>
                  <Typography variant="h3" component="h3" color="primary">
                    <Translate id="drills.variations" />
                  </Typography>
                  <Box mt={2} mb={2}>
                    <div dangerouslySetInnerHTML={{ __html: variations }} />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Collapsible>
        )
      )}
    </>
  )
}

DrillPanel.defaultProps = {
  drillList: {
    min_players: 0,
  },
}

DrillPanel.propTypes = {
  drillList: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      relationships: PropTypes.shape({
        images: PropTypes.arrayOf(
          PropTypes.shape({
            full_url: PropTypes.string.isRequired,
          })
        ).isRequired,
        age_classes: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
          })
        ).isRequired,
      }).isRequired,
      min_players: PropTypes.number,
      max_players: PropTypes.number.isRequired,
      duration: PropTypes.number.isRequired,
      drill_flow: PropTypes.string,
      organization: PropTypes.string,
      coaching_points: PropTypes.string,
      variations: PropTypes.string,
    })
  ),
  sectionName: PropTypes.string.isRequired,
  sectionIndex: PropTypes.number.isRequired,
}

export default DrillPanel
