import React from 'react'
import { Translate } from 'react-localize-redux'
import { Grid, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { MATCH_TYPES } from 'constants/match'
import PROP_TYPES from 'constants/propTypes'

const useStyles = makeStyles((theme) => ({
  StyledTitle: {
    position: 'relative',
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(0, 1, 0),
    top: -5,
    borderRadius: '0 0 9px 9px',
    zIndex: 1078,

    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      width: 0,
      height: 0,
      borderStyle: 'solid',
      zIndex: 1075,
      color:"transparent"
    },
    '&:before': {
      borderBottomWidth: 41,
      borderRightWidth: 42,
      borderBottomColor: 'transparent',
      borderRightColor: theme.palette.primary.light,
      top: -4,
      left: -41,
    },
    '&:after': {
      borderBottomWidth: 41,
      borderLeftWidth: 42,
      borderTopColor: 'transparent',
      borderLeftColor: theme.palette.primary.light,
      top: -4,
      right: -41,
    },
  },
  [theme.breakpoints.down('sm')]: {
    StyledTitle: {
      zIndex: 1,
      '&:before, &:after': {
        zIndex: 2,
      },
    },
  },
}))

const MatchTitle = ({ currentMatch }) => {
  const classes = useStyles()

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item>
        <Grid container className={classes.StyledTitle} spacing={3}>
          <Grid item>
            <Box>
              <strong>
                {+currentMatch.home === 1 ? (
                  <Translate id="matches.home-game-title" />
                ) : (
                  <Translate id="matches.away-game-title" />
                )}
              </strong>
            </Box>
          </Grid>
          <Grid item>
            <strong>-</strong>
          </Grid>
          <Grid item>
            <strong>
              {MATCH_TYPES.map(
                (element) => currentMatch.type === element.type && element.title
              )}
            </strong>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
MatchTitle.propTypes = {
  currentMatch: PROP_TYPES.shape().isRequired,
}
export default MatchTitle
