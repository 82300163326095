export default (translations, activeLocale, fieldsNames) => {

  // @fixme: #roles always return translations because forms accessible only by admins
  if (false) {
    return {}
  }

  return Object.fromEntries(
    fieldsNames.map(fieldName => [
      fieldName,
      translations.findEndGetProp(
        ({ locale }) => locale === activeLocale,
        fieldName
      ),
    ])
  )
}
