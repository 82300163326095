import React from 'react'
import { Translate } from 'react-localize-redux'
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  Tooltip,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded'

const useStyles = makeStyles(() => ({
  ulListSrcoll: {
    minHeight: 60,
    maxHeight: 250,
    width: '100%',
    overflowY: 'auto',
    '& .MuiListItemSecondaryAction-root': {
      right: 10,
      '& .MuiSvgIcon-root': {
        width: 20,
        height: 20,
      },
    },
    '& .MuiListItemIcon-root': {
      minWidth: 35,
    },
  },
}))

const PlayerAnalysisList = ({
  entities,
  handleClickOpenEdit,
  deleteRecord,
  handleClickOpenfor,
  label,
  type,
}) => {
  const classes = useStyles()

  return (
    <Grid item sm={12} xs={12} md={6}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Typography variant="h4" component={Box}>
          {label} ({entities.length})
        </Typography>
        <IconButton size="small" color="primary">
          <AddCircleRoundedIcon onClick={() => handleClickOpenfor(type)} />
        </IconButton>
      </Grid>
      <List className={classes.ulListSrcoll} dense disablePadding>
        {entities &&
          entities.map((entity) => {
            return (
              <ListItem
                key={type + entity.id}
                alignItems="center"
                disableGutters
              >
                <ListItemIcon>
                  {type === 'weakness' ? (
                    <RemoveIcon fontSize="small" />
                  ) : (
                    <AddIcon fontSize="small" />
                  )}
                </ListItemIcon>
                <ListItemText primary={entity.name} />
                <ListItemSecondaryAction>
                  <Tooltip
                    title={<Translate id="button.edit" />}
                    placement="top"
                    arrow
                  >
                    <IconButton edge="end" color="action" size="small">
                      <EditIcon
                        size="small"
                        onClick={() =>
                          handleClickOpenEdit(type, entity.name, entity.id)
                        }
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={<Translate id="button.delete" />}
                    placement="top"
                    arrow
                  >
                    <IconButton edge="end" color="action" size="small">
                      <DeleteIcon
                        size="small"
                        onClick={() => deleteRecord(entity.id)}
                      />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
            )
          })}
      </List>
    </Grid>
  )
}

PlayerAnalysisList.propTypes = {
  handleClickOpenEdit: PropTypes.func.isRequired,
  handleClickOpenfor: PropTypes.func.isRequired,
  deleteRecord: PropTypes.func.isRequired,
  entities: PropTypes.shape().isRequired,
  label: PropTypes.shape().isRequired,
  type: PropTypes.string.isRequired,
}
export default PlayerAnalysisList
