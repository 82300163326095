import React from 'react'
import { Translate } from 'react-localize-redux'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Card, CardHeader, CardContent, Typography } from '@material-ui/core'
import { SubLink } from 'containers'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import Rating from '@material-ui/lab/Rating'
import { connect } from 'react-redux'
import { formatCostumDateMonthNoForUI } from 'utils/formatDate'
import { MATCH_DASHBOARD_ROUTE } from 'constants/routes'
import PROP_TYPES from 'constants/propTypes'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
})
const StyledRating = withStyles(theme => ({
  root: {
    alignItems: 'center',
    color: theme.palette.primary.main,
  },
}))(Rating)

const MatchDayJournal = ({ journal, dateFormat }) => {
  const classes = useStyles()

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="h4">
            <Translate id="team.match-day-journal" />
          </Typography>
        }
      />

      <CardContent>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Translate id="team.date" />
                </TableCell>
                <TableCell>
                  <Translate id="team.opponent" />
                </TableCell>
                <TableCell>
                  <Translate id="team.rating" />
                </TableCell>
                <TableCell>
                  <Translate id="team.match-notes" />
                </TableCell>
                <TableCell>
                  <Translate id="team.goals-scored" />
                </TableCell>
                <TableCell>
                  <Translate id="team.minutes-played" />
                </TableCell>
                <TableCell>
                  <Translate id="team.goals-assisted" />
                </TableCell>
                <TableCell>
                  <Translate id="player.yellow-cards" />
                </TableCell>
                <TableCell>
                  <Translate id="player.red-cards" />
                </TableCell>
                <TableCell>
                  <Translate id="player.y-r-cards" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {journal.map(item => (
                <TableRow key={item.matchUuid}>
                  <TableCell md={2} className="date">
                    <SubLink
                      to={MATCH_DASHBOARD_ROUTE.populateURLParams({
                        matchId: item.matchUuid,
                      })}
                    >
                      {formatCostumDateMonthNoForUI(item.date,dateFormat)}
                    </SubLink>
                  </TableCell>
                  <TableCell md={2} className="opponent">
                    {item.opponent || ''}
                  </TableCell>
                  <TableCell md={2} className="rating">
                    <StyledRating
                      name="simple-controlled"
                      value={item.rating}
                      readOnly
                      precision={0.5}
                    />
                  </TableCell>
                  <TableCell md={2} className="notes">
                    {item.notes}
                  </TableCell>
                  <TableCell md={2} className="goals-scored">
                    {item.goals_scored}
                  </TableCell>
                  <TableCell md={1} className="playing_minutes">
                    {item.playing_minutes}
                  </TableCell>
                  <TableCell md={1} className="goals-assisted">
                    {item.goals_assisted}
                  </TableCell>
                  <TableCell md={1} className="goals-assisted">
                    {item.ycard}
                  </TableCell>
                  <TableCell md={1} className="goals-assisted">
                    {item.rcard}
                  </TableCell>
                  <TableCell md={1} className="goals-assisted">
                    {item.yrcard}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  )
}

MatchDayJournal.defaultProps = {
  journal: [],
}

MatchDayJournal.propTypes = {
  journal: PROP_TYPES.arrayOfObjects,
  dateFormat: PROP_TYPES.string.isRequired,
}

export default connect(
  ({ auth }) => ({
    dateFormat: auth.user.datetime_format,
  }),
  null
)(MatchDayJournal)
