import React, { useState } from 'react'
import { Dialog, Divider } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { generalEvents } from 'redux/modules'
import {
  checkIsAfterDateTime,
  concatDateAndTimeOfTwoDatesWithUserTimezoneAndUtcFormat,
  formatDateTimeToServer,
  formatDateTimeToUtcDateTimeWithUserTimezone,
  formatUtcDateTimeToUserTimezoneDateTime,
} from 'utils/formatDate'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { DialogTitle, DialogContent } from '../styles'
import EventInfoEditForm from './EventInfoEditForm'

const EventInfoEditDialog = ({
  open,
  handleClose,
  currentEvent,
  updateEvent,
  timezone,
}) => {
  const [eventData, setEventData] = useState({
    start_datetime: formatUtcDateTimeToUserTimezoneDateTime(
      currentEvent.start_datetime,
      timezone
    ),
    end_datetime: currentEvent.end_datetime
      ? formatUtcDateTimeToUserTimezoneDateTime(currentEvent.end_datetime, timezone)
      : null,
    location: currentEvent.location ?? '',
    lat: currentEvent.lat ?? '',
    lng: currentEvent.lng ?? '',
    street: currentEvent.stree ?? '',
    city: currentEvent.city ?? '',
    postal_code: currentEvent.postal_code ?? '',
    state: currentEvent.state ?? '',
    country: currentEvent.country ?? '',
  })
  const [dateTimeError, setDateTimeError] = useState(null)

  const handleDateChange = (uiDate) => {
    setEventData({
      ...eventData,
      start_datetime: formatDateTimeToServer(uiDate),
    })
  }
  const handleEndTimeChange = (uiDate) => {
    setEventData({ ...eventData, end_datetime: formatDateTimeToServer(uiDate) })
  }

  const handleEventInfoSubmit = (values) => {
    const {
      name,
      details,
      availability_cutoff_time,
    } = values
    const tempEventData = {
      ...eventData,
      start_datetime: formatDateTimeToUtcDateTimeWithUserTimezone(
        eventData.start_datetime,
        timezone
      ),
      end_datetime: eventData.end_datetime
        ? concatDateAndTimeOfTwoDatesWithUserTimezoneAndUtcFormat(
            eventData.start_datetime,
            eventData.end_datetime,
            timezone
          )
        : null,
    }

    const body = {
      name,
      details,
      availability_cutoff_time,
      ...tempEventData,
    }
      // Check if end time is before start time 
      if (
        checkIsAfterDateTime(
          tempEventData.start_datetime,
          tempEventData.end_datetime
        )
      ) {
        setDateTimeError('matches.end-time-error')
  
        return
      }
    updateEvent(currentEvent.uuid, body).then(() => {
      handleClose()
    })
  }

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="edit-match-info-dialog-title"
        open={open}
      >
        <DialogTitle id="alert-dialog-title">
          <Translate id="events.event-details" />
          <IconButton aria-label="close" size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <EventInfoEditForm
            initialValues={{ ...currentEvent }}
            onSubmit={handleEventInfoSubmit}
            handleDateChange={handleDateChange}
            handleEndTimeChange={handleEndTimeChange}
            datetimesAndLocationData={eventData}
            setEventData={setEventData}
            handleCancel={handleClose}
            dateTimeError={dateTimeError}
          />
        </DialogContent>
        <Divider />
      </Dialog>
    </div>
  )
}
EventInfoEditDialog.propTypes = {
  currentEvent: PropTypes.shape().isRequired,
  updateEvent: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  timezone: PropTypes.string.isRequired,
}

export default connect(
  ({ generalEvents: { current }, auth }) => ({
    currentEvent: current,
    timezone: auth.user.time_zone,
  }),
  { updateEvent: generalEvents.updateEvent }
)(EventInfoEditDialog)
