import React, { useState } from 'react'
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Tooltip,
} from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import { Input } from 'components'
import PROP_TYPES from 'constants/propTypes'
import { INVITE_ROLE } from 'constants/player'

const InviteFields = ({
  setInvites,
  inviteIndex,
  invite,
  isLoading,
  setIsFormValid,
}) => {
  const [emailValidation, setEmailValidation] = useState({
    valid: true,
    message: '',
  })

  const handleEmail = (event) => {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (event.target.value.length === 0) {
      setEmailValidation({
        valid: true,
        message: '',
      })
      setIsFormValid(true)

      return
    }
    if (!event.target.value.match(pattern)) {
      setEmailValidation({
        valid: false,
        message: <Translate id="login.valid.email" />,
      })
      setIsFormValid(false)

      return
    }
    setEmailValidation({ valid: true, message: '' })
    setIsFormValid(true)
  }
  const handleInput = (event) => {
    const {
      target: { name, value },
    } = { ...event }

    if (name === 'email') {
      handleEmail(event)
    }
    setInvites((u) =>
      u.map((element, index) => {
        if (index === inviteIndex) {
          return { ...element, [name]: value }
        }

        return element
      })
    )
  }

  const handleAccess = (event) => {
    const {
      target: { name, checked },
    } = { ...event }
    setInvites((u) =>
      u.map((element, index) => {
        if (index === inviteIndex) {
          return { ...element, [name]: checked ? 1 : 0 }
        }

        return element
      })
    )
  }

  return (
    <>
      <Grid item xs={12} sm={3}>
        <Input
          label="eg. name@email.com"
          margin="dense"
          onChange={handleInput}
          name="email"
          defaultValue={invite.email}
          disabled={isLoading}
          error={!emailValidation.valid}
          helperText={emailValidation.message}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Input
          label={<Translate id="form.first-name" />}
          margin="dense"
          onChange={handleInput}
          name="firstName"
          defaultValue={invite.firstName}
          disabled={isLoading}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Input
          label={<Translate id="player.invite-user.lname" />}
          margin="dense"
          onChange={handleInput}
          name="lastName"
          defaultValue={invite.lastName}
          disabled={isLoading}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <FormControl fullWidth variant="outlined" margin="dense">
          <InputLabel id="role-label">
            <Translate id="player.invite-user.role" />
          </InputLabel>
          <Select
            labelId="role-label"
            name="role"
            value={invite.role}
            label={<Translate id="player.invite-user.role" />}
            onChange={handleInput}
            disabled={isLoading}
          >
            {INVITE_ROLE.map((p) => {
              return (
                <MenuItem key={p.role} value={p.role}>
                  {p.title}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={1}>
        <Tooltip
          title={invite.access == 1 ? 'Remove access' : 'Give access'}
          placement="top"
          arrow
        >
          <Switch
            checked={invite.access == 1}
            onChange={handleAccess}
            name="access"
            disabled={isLoading}
          />
        </Tooltip>
      </Grid>
    </>
  )
}

InviteFields.propTypes = {
  invite: PROP_TYPES.shape().isRequired,
  setInvites: PROP_TYPES.func.isRequired,
  inviteIndex: PROP_TYPES.number.isRequired,
  isLoading: PROP_TYPES.bool.isRequired,
  setIsFormValid: PROP_TYPES.func.isRequired,
}
export default InviteFields
