import React from 'react'
import PropTypes from 'prop-types'
import { Card } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const MuiCard = withStyles(theme => ({
  root: {
    width: 240,
    margin: theme.spacing(0, 2.2, 0, 0),
    border: '1px solid #e8e8e8',
    '& .MuiCardContent-root': {
      padding: theme.spacing(2, 2, 0),
    },
  },
}))(Card)

const CarouselItem = ({ children, className }) => (
  <MuiCard className={className}>{children}</MuiCard>
)

CarouselItem.defaultProps = {
  children: <div />,
  className: '',
}

CarouselItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  ]),
  className: PropTypes.string,
}

export default CarouselItem
