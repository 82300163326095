import React, { useState } from 'react'
import { connect } from 'react-redux'
import Joi from 'joi'
import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Button,
  IconButton,
  makeStyles,
  Box,
  FormHelperText,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { Input, PopperTooltip } from 'components'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import EditIcon from '@material-ui/icons/Edit'
import { Translate } from 'react-localize-redux'
import { toast } from 'react-toastify'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { createTeamSkill, deleteTeamSkill, updateTeamSkill } from '../api'

const useStyles = makeStyles((theme) => {
  const color = theme.palette.background.default

  return {
    listRoot: {
      backgroundColor: color,
      maxHeight: 160,
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    list: {
      backgroundColor: color,
      maxWidth: 276,
      margin: theme.spacing(0.3),
    },
    listInput: {
      marginBottom: theme.spacing(0),
    },
    btn: {
      marginLeft: theme.spacing(1),
    },
  }
})
const AddEditTeamSkillsDialog = ({ arrow, teamId, skills, setSkills }) => {
  const classes = useStyles()

  const [open, setOpen] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [errorText, setErrorText] = useState(null)
  const [labelFieldValue, setLabelFieldValue] = useState('')
  const [isEdit, setIsEdit] = useState(false)
  const [selectedSkill, setSelectedSkill] = useState(null)

  const schema = Joi.string().min(1).max(255)

  const handleLabelFieldChange = (event) => {
    const newValue = event.target.value
    setLabelFieldValue(newValue)
    // Validate the input using Joi schema
    const { error } = schema.validate(newValue)
    if (error) {
      setIsValid(false)
      setErrorText(error.details[0].message)
    } else {
      setIsValid(true)
      setErrorText(null)
    }
    setIsValid(!error)
  }

  const handleAddSkill = () => {
    if (isValid) {
      // Call create team skill API
      createTeamSkill(teamId, { name: labelFieldValue })
        .then((res) => {
          const newSkill = res.data

          setSkills([
            ...skills,
            {
              id: newSkill.id,
              uuid: newSkill.uuid,
              name: newSkill.name,
              skill_avg: 0.0,
            },
          ])
          setLabelFieldValue('')
        })
        .catch((error) => {
          console.error('Error in saving skill:', error)
        })
    }
  }

  const handleEditModeOpen = (skill) => {
    setIsEdit(true)
    setLabelFieldValue(skill.name)
    setSelectedSkill(skill)
  }

  const handleEditModeClose = () => {
    setIsEdit(false)
    setLabelFieldValue('')
    setSelectedSkill(null)
  }

  const handleUpdateSkill = () => {
    // checking validation for name
    if (isValid) {
      if (!selectedSkill) {
        toast('Skill not found for Edit')

        return
      }

      if (selectedSkill.name === labelFieldValue) return

      // calling update API
      updateTeamSkill(selectedSkill.id, { name: labelFieldValue })
        .then((res) => {
          const updatedSkill = res.data

          handleEditModeClose()

          setSkills(
            skills.map((p) => {
              const skill = { ...p }
              if (skill.id === updatedSkill.id) {
                skill.name = updatedSkill.name
              }

              return skill
            })
          )

          const radarSkillsIndex = skills.findIndex(
            (rd) => rd.name === selectedSkill.name
          )
          const radarSkillsTmp = [...skills]
          radarSkillsTmp[radarSkillsIndex].name = updatedSkill.name
          setSkills(radarSkillsTmp)
        })
        .catch((error) => {
          console.error('Error in updating skill:', error)
        })
    }
  }

  const handleDeleteSkill = (id) => {
    deleteTeamSkill(id)
      .then(() => {
        const filteredSkills = skills.filter((s) => s.id !== id)

        setSkills(filteredSkills)
      })
      .catch((error) => {
        console.error('Error in deleting skill:', error)
      })
  }

  return (
    <PopperTooltip
      open={open}
      onClose={() => setOpen(false)}
      arrow={arrow}
      placement="bottom"
      content={
        <>
          <List dense className={classes.listRoot}>
            {skills.map((skill) => (
              <>
                <ListItem key={skill.id} className={classes.list}>
                  <ListItemText>{skill.name}</ListItemText>
                  <ListItemSecondaryAction>
                    <IconButton
                      onClick={() => handleEditModeOpen(skill)}
                      size="small"
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDeleteSkill(skill.id)}
                      size="small"
                    >
                      <DeleteOutlineIcon fontSize="small" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </>
            ))}
          </List>
          <Box px={2} pt={1.5} display="flex">
            <Input
              label="New Label"
              name="skill_label"
              value={labelFieldValue}
              className={classes.listInput}
              error={errorText}
              onChange={handleLabelFieldChange}
            />
            <Button
              variant="contained"
              size="medium"
              color="primary"
              className={classes.btn}
              mr={2}
              disabled={!isValid}
              onClick={isEdit ? handleUpdateSkill : handleAddSkill}
            >
              {isEdit ? (
                <Translate id="button.update" />
              ) : (
                <Translate id="button.add" />
              )}
            </Button>
            {isEdit && (
              <Button
                variant="contained"
                size="medium"
                className={classes.btn}
                onClick={handleEditModeClose}
              >
                <Translate id="button.cancel" />
              </Button>
            )}
          </Box>
          <Box px={2.1} pb={1.5}>
            {errorText && <FormHelperText error>{errorText}</FormHelperText>}
          </Box>
        </>
      }
    >
      <Button
        variant="contained"
        size="small"
        color="primary"
        onClick={() => setOpen(!open)}
      >
        <Translate id="team.manage-skills" />
      </Button>
    </PopperTooltip>
  )
}

AddEditTeamSkillsDialog.propTypes = {
  arrow: PropTypes.bool.isRequired,
  teamId: PropTypes.number.isRequired,
  skills: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      uuid: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      skill_avg: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
  setSkills: PropTypes.func.isRequired,
}

export default compose(
  withRouter,
  connect(({ team }) => ({
    teamId: team.id,
  }))
)(AddEditTeamSkillsDialog)
