import { styled } from '@material-ui/core/styles'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Box, Typography, Card,   CardActions as MUiCardActions, } from '@material-ui/core'

export const Heading = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  padding: theme.spacing(0.3, 1),
  fontSize: 16,
  fontWeight: 600,
}))

export const DraggableArea = styled(Box)(({ theme }) => ({
  margin: theme.spacing(0),
  border: '#e9ecef dashed 3px',
  minHeight: 192,
  borderRadius: 8,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export  const useSequencesStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('xl')]: {
      '& .MuiGrid-grid-xl-2': {
        maxWidth: '25%',
        flexBasis: '25%',
      },
    },
  },
  link: {
    width: '100%', 
  },
  media: {
    height: 134,
  },
  cardActions: {
    paddingLeft: theme.spacing(2),
  },
  title: {
    flex: '1 1 0',
  },
  videoContainer: {
    position: 'relative',
    width: '100%',      
    height: '500px',    
    maxWidth: '1000px',  
    margin: '0 auto',   
  },
  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  PlayCircle: {
    position: 'absolute',
    top: '27%',
    height: 65,
    width: 65,
    padding: 0,
    backgroundColor: '#fff',
    borderRadius: '50%',
    left: 0,
    right: 0,
    margin: 'auto',
    '& .MuiSvgIcon-root': {
      fontSize: 67,
    },
  },
}))

export const MuiCard = withStyles((theme) => ({
  root: {
    border: '1px solid #e8e8e8',
    '& .MuiCardContent-root': {
      padding: theme.spacing(2, 2, 1),
    },
    '& .MuiButton-root': {
      minWidth: 46,
    },
  },
}))(Card)

export const CardActions = withStyles((theme) => ({
  root: {
    '& .MuiBox-root': {
      flex: '1 1 0',
    },
    '& .MuiButton-root': {
      marginLeft: theme.spacing(0.8),
    },
  },
}))(MUiCardActions)