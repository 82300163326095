import React, { useState, useEffect } from 'react'
import { Translate } from 'react-localize-redux'
import { loadStripe } from '@stripe/stripe-js'
import { Button } from '@material-ui/core'
import { connect } from 'react-redux'
import { subscription } from 'redux/modules'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import PROP_TYPES from 'constants/propTypes'
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

/* eslint-disable-next-line */
const ProductDisplay = ({ handleClick }) => (
  <section>
    <Button
      variant="contained"
      color="primary"
      fullWidth
      id="checkout-button"
      onClick={handleClick}
    >
      <Translate id="button.pay-now" />
    </Button>
  </section>
)
/* eslint-disable-next-line */
const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
)

const StripeCheckout = ({
  interval,
  planId,
  currency,
  createSessionForBilling,
}) => {
  const [message, setMessage] = useState('')

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search)

    if (query.get('success')) {
      setMessage('Order placed! You will receive an email confirmation.')
    }

    if (query.get('canceled')) {
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      )
    }
  }, [])

  /* eslint-disable-next-line */
  const handleClick = async () => {
    const stripe = await stripePromise

    const response = await createSessionForBilling({
      interval,
      planId,
      currency,
      preventToastNotification: true,
    })

    const session = await response.response.data.id
    localStorage.setItem('stripe_sessionId', session)

    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: session,
    })

    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.

      console.error(result)
    }
  }

  return message ? (
    <Message message={message} />
  ) : (
    <ProductDisplay handleClick={handleClick} />
  )
}

StripeCheckout.propTypes = {
  createSessionForBilling: PROP_TYPES.func.isRequired,
  interval: PROP_TYPES.string.isRequired,
  planId: PROP_TYPES.string.isRequired,
  currency: PROP_TYPES.string.isRequired,
}

export default compose(
  withRouter,
  connect(
    null,
    {
      createSessionForBilling: subscription.createSessionForBilling,
    }
  )
)(StripeCheckout)
