import React from 'react'
import PropTypes from 'prop-types'
import { withLocalize } from 'react-localize-redux'
import { MenuItem, Select, FormControl, InputLabel } from '@material-ui/core'
import useStyles from 'routes/styleComponents'
import languages from '../constants/languages'

const LanguageDropdown = ({
  language,
  handleChange, // @todo: send value instead of object
  margin,
  theme,
  label,
}) => {
  const classes = useStyles(theme)

  return (
    <FormControl
      variant="outlined"
      className={classes.formControl}
      margin={margin}
    >
      <InputLabel htmlFor="language-switcher">{label}</InputLabel>
      <Select
        labelId="language-switcher-label"
        label={label}
        value={language}
        onChange={handleChange}
        inputProps={{
          id: 'language-switcher',
        }}
      >
        {languages.map(({ code, name }) => (
          <MenuItem key={code} value={code}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

LanguageDropdown.defaultProps = {
  margin: 'normal',
  theme: {},
}

LanguageDropdown.propTypes = {
  theme: PropTypes.shape(),
  handleChange: PropTypes.func.isRequired,
  margin: PropTypes.string,
  language: PropTypes.string.isRequired,
  label: PropTypes.shape().isRequired,
}
export default withLocalize(LanguageDropdown)
