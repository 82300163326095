import React, { useEffect, useState } from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { matches } from 'redux/modules'
import {
  Typography,
  CardHeader,
  Button,
  Card,
  Grid,
  TextareaAutosize,
  IconButton,
  CardContent,
  Box,
} from '@material-ui/core'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined'
import { Snackbars, ReactSelect, LoadingSpinner } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { formateDate } from 'utils/formatDate'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiCardContent-root': {
      padding: 0,
    },
  },
  note: {
    boxShadow: '1px 1px 10px 0 rgba(0,0,0,0.2)',
    padding: `${theme.spacing(1.4, 2)} !important`,
  },
  typographySpacing: {
    marginRight: theme.spacing(2),
    fontWeight: 600,
  },

  notePlayersSelect: {
    width: 'calc(50% - 200px)',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 95px)',
    },
  },
  customTextArea: {
    borderRadius: '4px',
    minHeight: '40px',
    padding: '0.5rem',
    width: '100%',
    marginBottom: theme.spacing(1),
    border: '1px solid',
    borderColor: theme.palette.grey[400],
    '&:focus': {
      borderColor: '#29a173',
    },
  },
  noteRow: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    '& .MuiButtonBase-root': {
      position: 'absolute',
      right: '5px',
      top: '5px',
      [theme.breakpoints.down('sm')]: {
        right: '1px',
        position: 'absolute',
      },
    },
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
    border: '1px solid',
  },
}))

const MatchAllNotes = ({
  createMatchNote,
  updateMatchNote,
  deleteMatchNote,
  fetchMatchNotes,
  currentMatchUuid,
  participants,
}) => {
  const classes = useStyles()
  const [isLoading, setLoading] = useState(false)
  const emptyNote = {
    note: '',
    uuid: null,
    relationships: { players: [] },
    changed: false,
  }

  const participantsList = participants.map((p) => {
    const { player } = p.relationships

    return {
      value: player.uuid,
      label: `@${player.first_name} ${player.last_name ?? player.last_name}`,
      uuid: player.uuid,
    }
  })

  const [notes, setNotes] = useState([emptyNote])

  const handleAddNoteClick = () => {
    const tempNewNotes = [...notes]
    const tempNote = emptyNote
    tempNewNotes.push(tempNote)

    setTimeout(() => {
      window.scrollBy({
        top: document.body.scrollHeight,
        left: 0,
        behavior: 'smooth',
      })
    }, 200)

    setNotes(tempNewNotes)
  }
  const handleTextareaChange = ({ target: { value } }, index) => {
    const tempNewNotes = [...notes]
    tempNewNotes[index].note = value
    tempNewNotes[index].changed =
      tempNewNotes[index].note &&
      tempNewNotes[index].relationships.players.length

    setNotes(tempNewNotes)
  }

  const handleDeleteNote = (index) => {
    const tempNewNotes = [...notes]
    const matchNoteUuid = tempNewNotes[index].uuid ?? null

    if (matchNoteUuid) {
      deleteMatchNote(matchNoteUuid)
    }
    tempNewNotes.splice(index, 1)
    setNotes(tempNewNotes)
  }
  if (notes.length === 0) {
    setNotes([emptyNote])
  }

  const handleSaveOrUpdateNote = (index) => {
    const noteToUpdate = notes[index]
    let createdNote = {}

    const selectedPlayersUuids = participantsList
      .filter((playerSuggestion) =>
        notes[index].relationships.players.some(
          (selectedPlayerValue) =>
            selectedPlayerValue === playerSuggestion.uuid ||
            selectedPlayerValue.uuid === playerSuggestion.uuid
        )
      )
      .map((playerSelected) => playerSelected.uuid || playerSelected)

    if (noteToUpdate.uuid) {
      updateMatchNote(noteToUpdate.uuid, {
        note: noteToUpdate.note,
        players: selectedPlayersUuids,
      }).then((res) => {
        createdNote = res.response.data
        const updatedNotes = [...notes]
        updatedNotes[index] = createdNote
        updatedNotes[index].changed = false

        setNotes(updatedNotes)
      })
    } else {
      createMatchNote(currentMatchUuid, {
        note: noteToUpdate.note,
        players: selectedPlayersUuids,
      }).then((res) => {
        createdNote = res.response.data
        const updatedNotes = [...notes]
        updatedNotes[index] = createdNote
        updatedNotes[index].changed = false

        setNotes(updatedNotes)
      })
    }
  }

  const onChangePlayers = (newPlayers, option, index) => {
    const tempNewNotes = [...notes]
    const tempNote = { ...tempNewNotes[index] }
    let notePlayers = tempNote.relationships.players

    if (option && option.value === 'everyOne') {
      notePlayers = [...new Set(participantsList.map((p) => p.uuid))]
    } else if (option) {
      notePlayers.push(option.value)
      notePlayers = [...new Set(notePlayers)]
    } else if (!option && newPlayers) {
      notePlayers = [...new Set(newPlayers.map((p) => p.uuid))]
    } else if (!option && !newPlayers) {
      notePlayers = []
    }
    tempNote.relationships.players = notePlayers
    tempNote.changed = true
    tempNewNotes[index] = tempNote

    setNotes(tempNewNotes)

    return notePlayers
  }
  useEffect(() => {
    setLoading(true)
    fetchMatchNotes(currentMatchUuid)
      .then((res) => {
        const tempNotes = res.response.data
        setNotes(tempNotes)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  return (
    <Card className={classes.root}>
      <CardHeader
        action={
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleAddNoteClick}
          >
            <Translate id="matches.add-note" />
          </Button>
        }
        title={
          <Grid container spacing={1}>
            <Grid item xs="auto">
              <Typography variant="h4" component="h4">
                <Translate id="matches.match-notes" />
              </Typography>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {isLoading && <LoadingSpinner />}
        {!isLoading &&
          notes &&
          notes.map((item, index) => {
            const createdDate = formateDate(item.created_at)
            const selectedPlayer = item.relationships?.players?.map(
              (player) => {
                return {
                  ...participantsList.find(
                    (p) => p.uuid === player.uuid || p.uuid === player
                  ),
                }
              }
            )

            return (
              <Box className={classes.note} key={item.uuid} p={1} m={1}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    {item.created_at && (
                      <small>{`${createdDate[4]} ${createdDate[3]}, ${createdDate[0]}, ${createdDate[5]}:${createdDate[6]}`}</small>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center">
                      <Typography className={classes.typographySpacing}>
                        <Translate id="matches.assigned-to" />
                      </Typography>
                      <Box className={classes.notePlayersSelect}>
                        <ReactSelect
                          value={selectedPlayer}
                          suggestions={participantsList}
                          onChange={onChangePlayers}
                          placeholder={<Translate id="videos.tag-person" />}
                          isEveryOne
                          index={index}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.noteRow}>
                      <TextareaAutosize
                        className={classes.customTextArea}
                        aria-label="Match Note"
                        minRows={6}
                        value={item.note}
                        onChange={(event) => handleTextareaChange(event, index)}
                      />
                    </div>
                    <Box display="flex" justifyContent="end">
                      {item.changed && (
                        <IconButton
                          className={classes.buttonIcon}
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => handleSaveOrUpdateNote(index)}
                        >
                          <SaveOutlinedIcon />
                        </IconButton>
                      )}
                      <IconButton
                        size="small"
                        variant="outlined"
                        className={classes.buttonIcon}
                        onClick={() => handleDeleteNote(index)}
                      >
                        <DeleteOutlineOutlinedIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )
          })}
      </CardContent>
    </Card>
  )
}

MatchAllNotes.defaultProps = {}

MatchAllNotes.propTypes = {
  deleteMatchNote: PropTypes.func.isRequired,
  createMatchNote: PropTypes.func.isRequired,
  updateMatchNote: PropTypes.func.isRequired,
  fetchMatchNotes: PropTypes.func.isRequired,
  currentMatchUuid: PropTypes.string.isRequired,
  participants: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
}

export default connect(
  ({ players: { items }, matches: { current } }) => ({
    players: items,
    currentMatchUuid: current.uuid,
    participants: current.relationships.participants,
  }),
  {
    createMatchNote: matches.createMatchNote,
    updateMatchNote: matches.updateMatchNote,
    deleteMatchNote: matches.deleteMatchNote,
    fetchMatchNotes: matches.fetchMatchNotes,
  }
)(MatchAllNotes)
