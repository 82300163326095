import React from 'react'
import { CardHeader, Card, CardContent } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Heading } from '../styledComponents'
import PROP_TYPES from 'constants/propTypes'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    boxShadow: '10px 5px 35px 0 rgba(0,0,0,0.08)',
    border: '1px solid #eee',
    padding: theme.spacing(0, 1, 0),
    borderRadius: 10,
    marginBottom: 4,
    '& .MuiCardHeader-root': {
      padding: theme.spacing(0, 1),
    },
    '& .MuiCardContent-root:last-child': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
    },
    '& .MuiCardHeader-title': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
      borderRadius: theme.spacing(0, 0, 1.5, 1.5),
      padding: theme.spacing(0, 1.5),
      display: 'inline-block',
    },
  },
  [theme.breakpoints.down('sm')]: {
    root: {
      '& .MuiCardContent-root': {
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
      },
    }
  }
}))

const PlayersListCard = ({ title, children }) => {
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardHeader
        title={
          <Heading variant="h4" component="h4">
            {title}
          </Heading>
        }
      />
      <CardContent>{children}</CardContent>
    </Card>
  )
}

PlayersListCard.propTypes = {
  title: PROP_TYPES.node.isRequired,
  children: PROP_TYPES.node.isRequired,
}
export default PlayersListCard
