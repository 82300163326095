import React from 'react'
import { Translate } from 'react-localize-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Tabs, Tab, Toolbar, Box, Paper } from '@material-ui/core'
import TeaserHeader from 'components/Teaser/TeaserHeader'
import hasSubscription from 'utils/hasSubscription'
import TeamInsightsContainers from '../containers/TeamPlayersData'
import TeamDashboard from './TeamDashboard'
import TeamSettings from './TeamSettings'
import { PRO } from 'constants/plans'

const useStyles = makeStyles(theme => ({
  toolbar: {
    minHeight: 56,
    '& .MuiTab-root': {
      minHeight: 56,
    },
  },
  button: {
    margin: theme.spacing(0, 1.6),
  },
  title: {
    flex: '1 1 0',
    [theme.breakpoints.down('md')]: { width: '30%' },
  },
}))

const TeamMenu = history => {
  const [value, setValue] = React.useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const classes = useStyles()

  return (
    <>
    {hasSubscription() !== PRO ? (
    <TeaserHeader/>): ''}
      <Paper component={Box} mb={2}>
      <Toolbar disableGutters="false" className={classes.toolbar}>
          <Box className={classes.title}>
            <Translate>
              {({ translate }) => (
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab label={translate('team.team')} />
                  <Tab label={translate('team.team-insights')} />
                  <Tab label={translate('player.settings')} />
                </Tabs>
              )}
            </Translate>
          </Box>
        </Toolbar>
      </Paper>
      {value === 0 && (
        <TeamDashboard
          history={history}
          match={history.match}
          changeTab={setValue}
        />
      )}
      {value === 1 && <TeamInsightsContainers changeTab={setValue} />}
      {value === 2 && <TeamSettings changeTab={setValue} />}
    </>
  )
}

export default TeamMenu