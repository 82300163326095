// import React from 'react'
import { styled } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'

const DrillsSnackbar = styled(Snackbar)({
  '& .drillImg': {
    width: '100%',
    height: '6.25rem',
    position: 'inherit',
    margin: 0,
    padding: 0,
    borderRadius: 0,
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    background: 'white',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
    },
    '@media only screen and (max-width: 769px)': {
      width: '5rem',
      marginRight: '0.8rem',
      height: '5rem',
    },
  },
  '& .rec-carousel-wrapper': {
    width: '100% !important',
  },
  '@media only screen and (max-width: 769px)': {
    '& .MuiAlert-root': {
      width: '100%',
      height: '7rem',
      backgroundColor: '#fff',
      alignItems: 'flex-start',
      border: '#ededed solid 1px',
      boxShadow: '0 0px 36px 0 rgba(0, 0, 0, 0.05)',
      '& .MuiAlert-message': {
        width: 'calc(100% - 34px)',
        '& .extraAction': {
          display: 'flex',
          '& .time-content': {
            display: 'flex',
            marginRight: '0.6rem',
            alignItems: 'center',
            '& .MuiCardMedia-img': {
              width: '1rem',
              height: '1rem',
              marginRight: '0.3rem',
            },
          },
        },
      },
      '& .MuiAlert-icon': {
        display: 'none',
      },
    },
  },
});

export default DrillsSnackbar
