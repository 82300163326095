import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    background: theme.palette.primary.main,
    padding: theme.spacing(0.4),
    borderRadius: 10,
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      marginLeft: 0,
    },
  },
  eventIcon: {
    display: 'flex',
    color: theme.palette.common.white,
    marginRight: theme.spacing(4),
    marginLeft: theme.spacing(4),
    cursor: 'pointer',
  },
  activeIconBackground: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 10,
    padding: theme.spacing(0.1),
  },
  activeIcon: {
    color: theme.palette.grey[500],
  },
}))

const ThreeStateRadioGroup = ({ value, options, onChange }) => {
  const classes = useStyles()

  const handleChange = (newValue) => {
    if (newValue !== value) {
      onChange(newValue)
    }
  }

  return (
    <Box className={classes.iconContainer}>
      {options.map((option, index) => (
        <Box
          key={index}
          className={option.value === value ? classes.activeIconBackground : ''}
          onClick={() => handleChange(option.value)}
        >
          <Box
            className={`${classes.eventIcon} ${
              option.value === value ? classes.activeIcon : ''
            }`}
          >
            {option.icon}
          </Box>
        </Box>
      ))}
    </Box>
  )
}

ThreeStateRadioGroup.propTypes = {
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default ThreeStateRadioGroup
