import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Joi from 'joi'
import { reduxForm, Field } from 'redux-form'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {
  HasClubPermission,
  ImageUploader,
  LanguageSelectorForAdmins,
} from 'containers'
import {
  Paper,
  Grid,
  Box,
  Button,
  Typography,
  Toolbar,
  Divider,
  Tabs,
  Tab,
} from '@material-ui/core'
import {
  Input,
  LinkBack,
  Checkbox,
  ActionWithPrompt,
  FroalaTextEditor,
  CategoriesSelector,
  InputNumber,
} from 'components'
import createJoiValidator from 'utils/createJoiValidator'
import { Translate } from 'react-localize-redux'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'
import HeadsetOutlinedIcon from '@material-ui/icons/HeadsetOutlined'
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined'
import TabPanel from 'components/material/TabPanel'
import PERMISSIONS from 'constants/permissions'
import PROP_TYPES from 'constants/propTypes'

const MuiBox = withStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 0),
    '& .MuiBox-root': {
      margin: theme.spacing(0, 1.5, 0, 0),
      '& .MuiTypography-root': {
        fontWeight: 500,
        color: theme.palette.action,
        marginRight: theme.spacing(0.5),
      },
    },
  },
}))(Box)

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(2, 0, 0),
    padding: theme.spacing(3),
  },
  toolbar: {
    minHeight: 56,
    padding: theme.spacing(0, 2),
  },
  tabLabelContainer: {
    '&.MuiTab-labelIcon': {
      minHeight: '45px',
    },
    '& .MuiTab-wrapper': {
      flexDirection: 'row-reverse', // Used to place the icon in the right because material not giving an option
      fontSize: '0.8rem',
    },
    '& .MuiSvgIcon-root': {
      marginLeft: 8, // Adjust the space before the icon
      marginBottom: 'unset !important', // Important used to overide the default icon style in material tab
    },
  },
  descriptionCell: {
    whiteSpace: 'nowrap',
    maxWidth: 'calc(100vw - 20px)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  media: {
    height: 140,
  },
  title: {
    flex: '1 1 0',
  },
  fab: {
    position: 'absolute',
    top: theme.spacing(-3),
    right: theme.spacing(2),
  },
  spacing: {
    marginRight: theme.spacing(2),
  },
}))

const validationSchema = Joi.object().keys({
  title: Joi.string().max(255).required(),
  reading_time: Joi.optional(),
  body: Joi.string().required(),
  global: Joi.any().allow(0, 1),
  player_access: Joi.any().allow(0, 1),
  image: Joi.any().required(),
  podcast: Joi.any(),
  video: Joi.any(),
  categories: Joi.array().has(Joi.number()),
  featured: Joi.any().allow(0, 1),
})

// For material tabs
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const ArticleCreateEditForm = ({
  fromPath,
  articlesCategories,
  isEditForm,
  showDeleteButton,
  handleDelete,
  handleSubmit,
  setSelectedType,
  initialValues, // Used by JOI validator to add default values if any to form fields automcatically without any specific assignment
}) => {
  const classes = useStyles()
  const [activeTabName, setActiveTabName] = useState('article')
  const [dropOrSelectMessage, setDropOrSelectMessage] = useState(
    <Translate id="uploader.drop-or-select-file" />
  )
  const [contentTime, setContentTimeLabel] = useState(
    <Translate id="time.reading-time" />
  )

  // Set different labels on the basis of tab selection or we can say file type change
  const setLabels = (type) => {
    if (type === 'video') {
      setDropOrSelectMessage(<Translate id="uploader.select-video-thumbnail" />)
      setContentTimeLabel(<Translate id="time.viewing-time" />)
      setSelectedType('video')
    } else if (type === 'podcast') {
      setDropOrSelectMessage(<Translate id="uploader.select-audio-thumbnail" />)
      setContentTimeLabel(<Translate id="time.listening-time" />)
      setSelectedType('podcast')
    } else {
      setDropOrSelectMessage(<Translate id="uploader.drop-or-select-file" />)
      setContentTimeLabel(<Translate id="time.reading-time" />)
      setSelectedType('article')
    }
  }
  const handleTabChange = (event, newValue) => {
    setActiveTabName(newValue)
    setLabels(newValue)
  }
  useEffect(() => {
    if (initialValues.type) {
      const { type } = initialValues
      setActiveTabName(type)
      setLabels(type)
    }
  }, [])

  return (
    <Translate>
      {({ translate }) => (
        <>
          <Toolbar className={classes.toolbar} component={Paper}>
            <Typography variant="h6" component="h6" className={classes.title}>
              <Tabs
                value={activeTabName}
                onChange={handleTabChange}
                aria-label="simple tabs example"
                indicatorColor="primary"
              >
                <Tab
                  label={<Translate id="articles.create-article" />}
                  {...a11yProps('article')}
                  value="article"
                  icon={<AssignmentOutlinedIcon fontSize="small" />}
                  className={classes.tabLabelContainer}
                  disabled={isEditForm}
                />

                <Tab
                  label={<Translate id="articles.create-audio" />}
                  icon={<HeadsetOutlinedIcon fontSize="small" />}
                  {...a11yProps('podcast')}
                  value="podcast"
                  className={classes.tabLabelContainer}
                  disabled={isEditForm}
                />
                <Tab
                  label={<Translate id="articles.create-video" />}
                  icon={<VideocamOutlinedIcon fontSize="small" />}
                  {...a11yProps('video')}
                  value="video"
                  className={classes.tabLabelContainer}
                  disabled={isEditForm}
                />
              </Tabs>
            </Typography>
            <LinkBack
              to={fromPath.value}
              className={classes.spacing}
              title={translate('button.cancel')}
              isFullPath
            />
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              {translate('button.save')}
            </Button>
          </Toolbar>

          {/* Language selector for admins in edit mode  */}
          {isEditForm && (
            <Box mt={2}>
              <Typography variant="h4" component="h4" className={classes.title}>
                <LanguageSelectorForAdmins />
              </Typography>
            </Box>
          )}

          <form>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={7} lg={8} xl={8}>
                <Paper className={classes.root}>
                  <Box pb={2}>
                    <Field
                      name="title"
                      label={translate('articles.title')}
                      component={Input}
                      required
                    />
                  </Box>
                  <TabPanel value={activeTabName} index="article" padding={0}>
                    <Field
                      name="body"
                      placeholder={translate('froala.type-something')}
                      component={FroalaTextEditor}
                    />
                  </TabPanel>
                  <TabPanel value={activeTabName} index="podcast" padding={0}>
                    <Field
                      name="podcast"
                      component={ImageUploader}
                      fileType="audio"
                    />
                  </TabPanel>
                  <TabPanel value={activeTabName} index="video" padding={0}>
                    <Field
                      name="video"
                      component={ImageUploader}
                      fileType="video"
                    />
                  </TabPanel>
                </Paper>

                <Box py={2}>
                  <Typography
                    variant="h4"
                    component="h4"
                    className={classes.title}
                  >
                    {translate('drills.categories')}
                  </Typography>
                  <Field
                    name="categories"
                    component={CategoriesSelector}
                    items={articlesCategories}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
                <Paper className={classes.root}>
                  <Field
                    name="image"
                    component={ImageUploader}
                    dropOrSelectMessage={dropOrSelectMessage}
                  />
                  <Box>
                    <Field
                      name="featured"
                      label={translate('form.featured')}
                      component={Checkbox}
                    />
                  </Box>
                  <Box>
                    <HasClubPermission
                      ability={PERMISSIONS.CREATE_GLOBAL_ARTICLE}
                    >
                      <Field
                        name="global"
                        label={translate('form.global-checkbox-text')}
                        component={Checkbox}
                      />
                    </HasClubPermission>
                  </Box>
                  <Box>
                    <Field
                      name="player_access"
                      label={translate('articles.visible-for-players')}
                      component={Checkbox}
                    />
                  </Box>
                  <MuiBox>
                    <Box>
                      <Typography
                        variant="h4"
                        component="h4"
                        className={classes.title}
                      >
                        {translate('time.minutes')}
                      </Typography>
                    </Box>
                    <Box className={classes.title}>
                      <Field
                        id="reading_time"
                        label={contentTime}
                        component={InputNumber}
                        name="reading_time"
                        minLimit={0}
                      />
                    </Box>
                  </MuiBox>
                  <Divider />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {showDeleteButton && (
                  <ActionWithPrompt
                    className="text-danger border-danger"
                    onSuccess={handleDelete}
                    message={translate(
                      'articles.are-you-sure-you-want-to-delete-the-article'
                    )}
                    title="Delete"
                  />
                )}
              </Grid>
            </Grid>
          </form>
        </>
      )}
    </Translate>
  )
}

ArticleCreateEditForm.defaultProps = {
  showDeleteButton: false,
  handleDelete: () => 0,
  articlesCategories: [],
  isEditForm: false,
}

ArticleCreateEditForm.propTypes = {
  form: PropTypes.string.isRequired, // eslint-disable-line
  initialValues: PropTypes.shape(), // eslint-disable-line
  fromPath: PropTypes.shape({
    value: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired, // eslint-disable-line
  articlesCategories: PROP_TYPES.arrayOfObjects,
  isEditForm: PROP_TYPES.bool,
  showDeleteButton: PROP_TYPES.bool,
  handleDelete: PROP_TYPES.func,
  handleSubmit: PropTypes.func.isRequired,
  setSelectedType: PropTypes.func.isRequired,
}

export default reduxForm({
  enableReinitialize: true,
  validate: createJoiValidator(validationSchema),
})(ArticleCreateEditForm)
