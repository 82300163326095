import React, { useState } from 'react'
import { Box, Typography, IconButton } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  collapsibleWrapper: {
    margin: theme.spacing(1, 0),
    borderRadius: '4px',
    overflow: 'hidden',
  },
  header: {
    backgroundColor: '#f5f5f5',
    padding: '6px 10px',
    borderRadius: '8px',
    cursor: 'pointer',
  },
  contentBox: {
    backgroundColor: '#fff',
    padding: '2px 6px',
  },
}))

const AccordionItem = ({ title, children, defaultOpen }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(defaultOpen)

  return (
    <Box className={classes.collapsibleWrapper}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className={classes.header}
        onClick={() => setOpen((prevOpen) => !prevOpen)}
      >
        <Typography variant="h4">{title}</Typography>
        <IconButton size="small">
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>
      {open && <Box className={classes.contentBox}>{children}</Box>}
    </Box>
  )
}

AccordionItem.defaultProps = {
  defaultOpen: false,
}

AccordionItem.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  defaultOpen: PropTypes.bool,
}

export default AccordionItem
