import React, { useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { DatePicker } from 'components'
import { formatDateForServer } from 'utils/formatDate'
import useStyles from './styles'

const PlayerPauseDialog = ({ open, handleOpenClose, onSubmit, dateFormat }) => {
  const classes = useStyles()

  const [reason, setReason] = useState('')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [startDateError, setStartDateError] = useState('')
  const [endDateError, setEndDateError] = useState('')
  const [reasonError, setReasonError] = useState('')

  const validatePauseFormData = () => {
    if (!reason) {
      setReasonError(<Translate id="player.required-pause-reason" />)
    } else if (reason.length > 255) {
      setReasonError(<Translate id="player.required-reason-length" />)
    } else {
      setReasonError('')
    }
    if (!startDate) {
      setStartDateError(<Translate id="player.required-start-date" />)
    } else {
      setStartDateError('')
    }
    if (!endDate) {
      setEndDateError(<Translate id="player.required-end-date" />)
    } else if (new Date(endDate) <= Date.now()) {
      setEndDateError(<Translate id="player.required-future-end-date" />)
    } else {
      setEndDateError('')
    }

    return reason && startDate && endDate
  }

  const handleSubmit = () => {
    if (validatePauseFormData()) {
      const formattedStartDate = formatDateForServer(startDate)
      const formattedEndDate = formatDateForServer(endDate)
      onSubmit({
        reason,
        start_date: formattedStartDate,
        end_date: formattedEndDate,
      })
    }
  }

  return (
    <Dialog open={open} onClose={handleOpenClose} fullWidth>
      <Box py={2} px={3}>
        <Typography variant="h4">
          <Translate id="player.pause-player" />
        </Typography>
      </Box>
      <DialogContent className={classes.contentPadding}>
        <Box display="flex" justifyContent="space-between" mb={2}>
          <DatePicker
            required
            label={<Translate id="player.pause-from-date" />}
            selectedValue={startDate}
            onDateChange={setStartDate}
            isEditMode={false}
            error={!!startDateError}
            helperText={startDateError}
            className={classes.marginRight}
            maxDate={endDate}
            dateFormat={dateFormat}
          />
          <DatePicker
            required
            label={<Translate id="player.pause-to-date" />}
            selectedValue={endDate}
            onDateChange={setEndDate}
            isEditMode={false}
            error={!!endDateError}
            helperText={endDateError}
            className={classes.marginLeft}
            minDate={startDate}
            dateFormat={dateFormat}
          />
        </Box>
        <TextField
          label={<Translate id="player.pause-reason" />}
          required
          variant="outlined"
          fullWidth
          multiline
          minRows={4}
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          margin="dense"
          error={!!reasonError}
          helperText={reasonError}
          className={classes.textarea}
        />
      </DialogContent>
      <DialogActions className={classes.sectionPadding}>
        <Button
          variant="contained"
          onClick={handleOpenClose}
          className={classes.marginRight}
        >
          <Translate id="button.cancel" />
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          <Translate id="button.pause" />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

PlayerPauseDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleOpenClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  dateFormat: PropTypes.string.isRequired,
}

export default PlayerPauseDialog
