import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { InnrHead } from 'containers'
import { makeStyles } from '@material-ui/core/styles'
import {
  Paper,
  Button,
  Box,
  Hidden,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { DataGrid } from '@mui/x-data-grid'
import { players as playersModule } from 'redux/modules'
import { SelectedMenu } from 'components'
import AddPlayersDialog from '../AddPlayersDialog'
import { DEFAULT_CLUB_ID } from '../../../../constants/club'
import columns from './GridColumns'

const useStyles = makeStyles(() => ({
  dataGridTable: {
    borderLeft: '0 !important',
    borderRight: '0 !important',
    borderBottom: '0 !important',
    '& .MuiDataGrid-cell': {
      borderBottom: '0 !important',
      textTransform: 'capitalize',
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      padding: '0 !important',
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none !important ',
    },
  },
}))

const TeamPlayersGrid = ({
  clubId,
  players,
  createPlayerQuick,
  updatePlayer,
  deletePlayer,
}) => {
  const classes = useStyles()

  const [addPlayerIsOpen, setAddPlayerIsOpen] = useState(false)
  const [addPlayerIsLoading, setAddPlayerIsLoading] = useState(false)
  const [sortModel, handleSortModel] = useState([
    {
      field: 'firstName',
      sort: 'asc',
    },
  ])
  const [statusFilter, setStatusFilter] = useState('active')

  const rows = useMemo(() => {
    return players
      .filter((player) => {
        if (statusFilter === 'active') {
          return ['ACTIVE', 'IN_PROGRESS'].includes(player.status)
        }

        return ['DELETED', 'DEACTIVATED', 'TRANSFERRED'].includes(player.status)
      })
      .map((player) => ({
        id: player.id,
        uuid: player.uuid,
        avatar: player.avatar ? player.avatar.full_url : null,
        fullName: `${player.first_name} ${player.last_name}`,
        number: player.number ? player.number : '',
        position: player.position,
        status: player.status.toLowerCase(),
        onUpdate: (id, data) => {
          return updatePlayer(id, data)
        },
        onDelete: () => {
          return deletePlayer(player.id)
        },
        player, // needed because of most crazy EditDialog implementation I can image :/
        showTransferOption: clubId !== DEFAULT_CLUB_ID,
      }))
  }, [players, statusFilter])

  const handleAddPlayersOpen = () => {
    setAddPlayerIsLoading(false)
    setAddPlayerIsOpen(true)
  }

  const handleAddPlayersClose = () => {
    setAddPlayerIsOpen(false)
  }

  const handleAddPlayersSubmit = (data) => {
    setAddPlayerIsLoading(false)

    // @fixme: remove "quick" methods from front-end and back-end
    createPlayerQuick(data)
      .then(() => {
        setAddPlayerIsLoading(false)
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error)
        setAddPlayerIsLoading(false)
      })
  }

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value)
  }

  return (
    <Hidden only={['xs', 'sm']}>
      <Paper>
        <AddPlayersDialog
          open={addPlayerIsOpen}
          onClose={handleAddPlayersClose}
          handleSave={handleAddPlayersSubmit}
          loadingcircular={addPlayerIsLoading}
        />
        <InnrHead title={<Translate id="matches.all-players" />}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={handleAddPlayersOpen}
          >
            <Translate id="team-invite.add-players" />
          </Button>
        </InnrHead>
        <Box mx={2}>
          <SelectedMenu
            id="status-type"
            value={statusFilter}
            style={{ width: 180 }}
            onChange={handleStatusFilterChange}
            label={<Translate id="form.status" />}
          >
            <MenuItem value="active">
              <Translate id="movePlayers.active" />
            </MenuItem>
            <MenuItem value="inactive">
              <Translate id="movePlayers.inactive" />
            </MenuItem>
          </SelectedMenu>
        </Box>
        <Box px={2} pb={2} style={{ height: 464 }}>
          <DataGrid
            className={classes.dataGridTable}
            rows={rows}
            columns={columns}
            pageSize={100}
            rowHeight={60}
            headerHeight={40}
            rowsPerPageOptions={[100]}
            disableSelectionOnClick
            disableColumnMenu
            disableColumnFilter
            disableColumnSelector
            sortModel={sortModel}
            onSortModelChange={(model) => handleSortModel(model)}
          />
        </Box>
      </Paper>
    </Hidden>
  )
}

TeamPlayersGrid.propTypes = {
  clubId: PropTypes.number.isRequired,
  players: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      uuid: PropTypes.string.isRequired,
      avatar: PropTypes.shape({
        full_url: PropTypes.string.isRequired,
      }),
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      number: PropTypes.number,
      position: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    })
  ).isRequired,
  createPlayerQuick: PropTypes.func.isRequired,
  updatePlayer: PropTypes.func.isRequired,
  deletePlayer: PropTypes.func.isRequired,
}

export default connect(
  ({ clubs, players }) => ({
    clubId: clubs.current.id,
    players: players.items,
  }),
  {
    createPlayerQuick: playersModule.createPlayerQuick,
    updatePlayer: playersModule.updatePlayer,
    deletePlayer: playersModule.deletePlayer,
  }
)(TeamPlayersGrid)
