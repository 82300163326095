import { getRequest } from 'utils/axios'

export const fetchPlayersStatistics = (seasonId, params = {}) => {
  const url = `/seasons/${seasonId}/player-statistics`

  return getRequest(url, params)
}

export const fetchPlayersAttendance = (seasonId, params = {}) => {
  const url = `/seasons/${seasonId}/players-attendance`

  return getRequest(url, params)
}

export const fetchTeamPlayers = (teamId, params = {}) => {
  const url = `/teams/${teamId}/players`

  return getRequest(url, params)
}
