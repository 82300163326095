import React from 'react'
import { Translate } from 'react-localize-redux'

const ROLES_OPTIONS = [
  {
    value: 'coach',
    label: <Translate id="userRoles.coach" />,
  },
  {
    value: 'co_coach',
    label: <Translate id="userRoles.co-coach" />,
  },
  {
    value: 'technical_director',
    label: <Translate id="userRoles.technical-director" />,
  },
  {
    value: 'managing_director',
    label: <Translate id="userRoles.managing-director" />,
  },
  {
    value: 'player',
    label: <Translate id="events.table-player" />,
  },
  {
    value: 'other',
    label: <Translate id="form.other" />,
  },
]

export default ROLES_OPTIONS
