import React from 'react'
import PropTypes from 'prop-types'
import Joi from 'joi'
import { reduxForm, Field, FieldArray } from 'redux-form'
import { Card, LinkBack, Input } from 'components'
import { Button } from '@material-ui/core'
import createJoiValidator from 'utils/createJoiValidator'
import FieldContainer from './components/FieldContainer'
import { LINEUPS_DASHBOARD_ROUTE } from 'constants/routes'

const validationSchema = Joi.object().keys({
  title: Joi.string()
    .max(255)
    .required(),
  group: Joi.string()
    .max(255)
    .required(),
})

const LineupCreateEditForm = ({ caption, handleSubmit, reset }) => (
  <Card>
    <Card.Title>
      <LinkBack to={LINEUPS_DASHBOARD_ROUTE} title="Lineups" />
    </Card.Title>
    <Card.Title>{caption}</Card.Title>
    <Field name="title" placeholder="Title" component={Input} />
    <Field name="group" placeholder="Group Name" component={Input} />
    <FieldArray
      name="lineup"
      component={FieldContainer}
      validate={value => (value && value.length ? undefined : 'required')}
    />
    <form>
      <div className="d-flex justify-content-end pt-3">
        <Button variant="contained" onClick={reset}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>Save</Button>
      </div>
    </form>
  </Card>
)

LineupCreateEditForm.propTypes = {
  form: PropTypes.string.isRequired, // eslint-disable-line
  initialValues: PropTypes.shape(), // eslint-disable-line
  caption: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired, // eslint-disable-line
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
}

export default reduxForm({
  enableReinitialize: true,
  validate: createJoiValidator(validationSchema),
})(LineupCreateEditForm)
