import React from 'react'
import { LineupsLoader } from 'loaders'
import LineupsDashboardContainer from './LineupsDashboardContainer'

const LineupsDashboard = () => (
  <LineupsLoader>
    <LineupsDashboardContainer />
  </LineupsLoader>
)

export default LineupsDashboard
