import React from 'react'
import { withLocalize } from 'react-localize-redux'
import { FavoritesLoader, ArticlesCategoriesLoader } from 'loaders'
import ArticlesCategoriesViewContainer from './ArticlesCategoriesViewContainer'

const ArticlesCategoriesView = (location, match) => (
  <FavoritesLoader entity="articles">
    <ArticlesCategoriesLoader>
      <ArticlesCategoriesViewContainer location={location} match={match} />
    </ArticlesCategoriesLoader>
  </FavoritesLoader>
)

ArticlesCategoriesView.propTypes = {}

export default withLocalize(ArticlesCategoriesView)
