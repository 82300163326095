import React from 'react'
import { MatchLoader } from 'loaders'
import MatchDashboardContainer from '../../components/MatchDashboard/MatchDashboardContainer'
import PROP_TYPES from 'constants/propTypes'

const MatchDashboard = ({
  match: {
    params: { matchId },
  },
}) => (
  <MatchLoader
    matchId={matchId}
    additionalFields={[
      'players',
      'sequences',
      'lineups',
      'matches',
      'goals',
      'team',
    ]}
  >
    <MatchDashboardContainer matchId={matchId} />
  </MatchLoader>
)

MatchDashboard.propTypes = {
  match: PROP_TYPES.match.isRequired,
}

export default MatchDashboard
