import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Popover, Select, Box } from '@material-ui/core'
import { PlayerStaffItem as PlayerItem } from 'components'
import { PLAYER_POSITIONS } from 'constants/player'

const Overlay = ({ playerPosition, handleChangePosition, handleDelete }) => (
    <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Select
        value={playerPosition}
        onChange={handleChangePosition}
        options={PLAYER_POSITIONS}
      />
      <Button onClick={handleDelete}>Delete</Button>
    </Box>
);

const NewForLineup = ({
  number,
  playerPosition,
  onChangePosition,
  onDelete,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const handleDelete = () => {
    handlePopoverClose()
    onDelete()
  }

  const handleChangePosition = (newPosition) => {
    handlePopoverClose()
    onChangePosition(newPosition)
  }

  return (
    <PlayerItem.Container>
      <PlayerItem.PlayerDummyBody>
        <PlayerItem.Number>{number}</PlayerItem.Number>
        
        <Button onClick={handlePopoverOpen}>Change</Button>

        <Popover
          id="overlay-popover"
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Overlay
            playerPosition={playerPosition}
            handleChangePosition={handleChangePosition}
            handleDelete={handleDelete}
          />
        </Popover>

        <PlayerItem.FullName>{playerPosition}</PlayerItem.FullName>
      </PlayerItem.PlayerDummyBody>
    </PlayerItem.Container>
  )
}

NewForLineup.propTypes = {
  number: PropTypes.number.isRequired,
  playerPosition: PropTypes.string.isRequired,
  onChangePosition: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default NewForLineup
