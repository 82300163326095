import React from 'react'
import { Container, Typography, Box } from '@material-ui/core'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import grey from '@material-ui/core/colors/grey'
import { Translate } from 'react-localize-redux'

const NotFound = () => (
  <Container maxWidth="xl">
    <Box display="flex" justifyContent="center" alignItems="center" p={5} m={5}>
      <NotInterestedIcon
        style={{ color: grey[500], marginRight: 2 }}
        fontSize="small"
      />
      <Typography variant="h5">
        <Translate id="errors.not-found" />
      </Typography>
    </Box>
  </Container>
)

export default NotFound
