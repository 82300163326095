import React, { useState, useEffect } from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import SearchInput, { createFilter } from 'react-search-input'
import { styled } from '@material-ui/core/styles'

const Container = styled('div')(({ theme }) => ({
  position: 'relative',

  '& input': {
    padding: theme.spacing(0,1),
    background: 'transparent',
    color: '#888',

    border: '#888 solid 1px',
    borderRadius: 12,
    height: 24,
    margin: theme.spacing(0,1),

    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    width: '95%',
    height: 'calc(2.25rem + 2px)',

    '&::placeholder': {
      fontSize: '80%',
    },
    '& .packageEditSearch &': {
      marginBottom: theme.spacing(2),
    },
  },
}));

const Suggestions = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: -5,
  width: '100%',
  maxHeight: 220,
  borderRadius: 4,
  border: '1px solid #bbc1d0',
  overflowY: 'auto',
  zIndex: 10,
  background: 'white',
  transform: 'translate(0, 100%)',

  '& div': {
    padding: theme.spacing(2),
    cursor: 'pointer',
  },

  '& div:hover': {
    background: '#eee',
  },
}))
const SearchInputContainer = ({
  value: initialValue,
  suggestions,
  placeholder,
  onChange,
  isNew,
  isEveryOne,
  fetchDrill,
}) => {
  const [searchTerm, changeSearchTerm] = useState('')
  const [isShown, changeIsShown] = useState(false)

  useEffect(() => {
    const suggestion = suggestions.find(({ value }) => value === initialValue)

    if (suggestion) {
      changeSearchTerm(suggestion.title)
    }

    if (+initialValue === -1) {
      changeSearchTerm('@everyone tag everyone')
    }
  }, [initialValue])

  const updateSearchTerm = newSearchTerm => {
    changeSearchTerm(newSearchTerm)

    if (searchTerm !== newSearchTerm) {
      changeIsShown(Boolean(newSearchTerm))
    }

    if (!newSearchTerm) {
      onChange(null)
    }
  }

  const filteredSuggestions = suggestions
    .filter(createFilter(searchTerm, 'title'))
    .slice(0, 11)

  const onSelect = (newValue, title) => {
    onChange(newValue)

    changeIsShown(false)

    changeSearchTerm(isNew ? '' : title)
    fetchDrill(newValue, { added: true, track: 'training_package' })
  }

  return (
    <Container>
      <SearchInput
        value={searchTerm}
        onChange={updateSearchTerm}
        placeholder={placeholder}
      />
      {isShown && (filteredSuggestions.length > 0 || isEveryOne) && (
        <Suggestions>
          {isEveryOne && (
            <div
              onClick={() => {
                return onSelect(-1, <Translate id="videos.tag-everyone" />)
              }}
              role="presentation"
            >
              <Translate id="videos.tag-everyone" />
            </div>
          )}
          {filteredSuggestions.map(({ value, title }) => (
            <div
              key={value}
              onClick={() => onSelect(value, title)}
              role="presentation"
            >
              {title}
            </div>
          ))}
        </Suggestions>
      )}
    </Container>
  )
}

SearchInputContainer.defaultProps = {
  value: null,
  suggestions: [],
  placeholder: '',
  isNew: false,
  isEveryOne: false,
  fetchDrill: () => 0,
}

SearchInputContainer.propTypes = {
  value: PropTypes.number,
  suggestions: PropTypes.arrayOf(PropTypes.object.isRequired),
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  fetchDrill: PropTypes.func,
  isNew: PropTypes.bool,
  isEveryOne: PropTypes.bool,
}

export default SearchInputContainer
