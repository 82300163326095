import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import { connect } from 'react-redux'
import { matches } from 'redux/modules'
import AddUnavailabilityReasonDialog from './AddUnavailabilityReasonDialog'
import PROP_TYPES from 'constants/propTypes'

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 20,
  },
  menu: {
    '& .MuiPopover-paper': {
      border: `1px solid ${theme.palette.grey[300]}`,
      boxShadow: `1px 1px 10px 0 ${theme.palette.grey[200]}`,
      borderRadius: 10,
    },
  },
  [theme.breakpoints.up('md')]: {
    button: {
      width: 66.44,
      height: 30.83,
    },
  },
}))

const AvailabilityAndStatusSelectMenu = ({
  menuItems,
  defaultSelectedItem,
  buttonLabel,
  updateMatchParticipant,
  matchPlayerUuid,
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedItem, setSelectedItem] = useState(null)
  const [availabilityStatus, setAvailabilityStatus] = useState(null)
  const [open, setOpen] = useState(false)

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const handleSubmit = (values) => {
    const data = {
      reason: values.reason,
      availability: availabilityStatus,
    }

    updateMatchParticipant(matchPlayerUuid, data).then(() => {
      // Just assign unavailable icon to show icon on list as selected value
      setSelectedItem(menuItems[1].icon)

      handleClose()
      setAvailabilityStatus(null)
    })
  }

  const handleChange = (item) => {
    let data = {}
    handleMenuClose()

    // If player unavailable then ask for "reason" before sending request
    if (item.key === 'not_available') {
      setAvailabilityStatus(item.value)
      handleOpen()

      return
    }

    // Handle both availability, card status of player and pass request body accordingly
    if (item.type === 'status') {
      data = {
        tag: item.value,
      }
    } else {
      data = {
        availability: item.value,
      }
    }
    // Assign list item icon to show icon on list as selected value
    setSelectedItem(item.icon)

    updateMatchParticipant(matchPlayerUuid, data)
  }

  useEffect(() => {
    if (defaultSelectedItem && menuItems.length > 0) {
      const tempItem = menuItems.find(({ key }) => defaultSelectedItem === key)
      setSelectedItem(tempItem ? tempItem?.icon : menuItems[0].icon)
    }
  }, [defaultSelectedItem])

  return (
    <>
      {open && (
        <AddUnavailabilityReasonDialog
          open={open}
          handleClose={handleClose}
          onSubmit={handleSubmit}
        />
      )}
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleMenuOpen}
        variant="outlined"
        className={classes.button}
        size="small"
        endIcon={anchorEl ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      >
        {selectedItem || buttonLabel}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        className={classes.menu}
      >
        {menuItems.map((item) => (
          <MenuItem key={item.key} onClick={() => handleChange(item)}>
            {item.icon}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

AvailabilityAndStatusSelectMenu.propTypes = {
  menuItems: PROP_TYPES.arrayOfObjects.isRequired,
  defaultSelectedItem: PROP_TYPES.string.isRequired,
  buttonLabel: PROP_TYPES.string.isRequired,
  updateMatchParticipant: PROP_TYPES.func.isRequired,
  matchPlayerUuid: PROP_TYPES.string.isRequired,
}

export default connect(null, {
  updateMatchParticipant: matches.updateMatchParticipant,
})(AvailabilityAndStatusSelectMenu)
