import React from 'react'
import { Translate } from 'react-localize-redux'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

const headCells = [
  { id: 'number', numeric: false, width: 60, disablePadding: true, label: 'No' },
  { id: 'name', numeric: true, width: 230, disablePadding: false, label: <Translate id="matches.name"/> },
  {
    id: 'availability',
    numeric: true,
    width: 120,
    disablePadding: false,
    label: <Translate id="matches.availability"/>,
  },
  { id: 'status', numeric: true, width: 'auto', disablePadding: false, label: <Translate id="player.status"/> },
]

function PlayersTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            width={headCell.width}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default PlayersTableHead
