import React from 'react'
import { styled , withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import { Menu, Box as MuiBox }  from '@material-ui/core'

export const StyledMenu = withStyles({
  paper: {
    border: '1px solid #fff',
    boxShadow: '16px 16px 32px 0 rgba(0,0,0,0.08)',
    Width: 312,
    minWidth: 312,
    marginTop: '32px',
    overflow: 'visible',
    '& .MuiList-root': {
      padding: 0,
    },
    '&:before': {
      content: '""',
      borderLeft: '10px solid transparent',
      borderRight: '10px solid transparent',
      borderBottom: '10px solid #fff',
      position: 'absolute',
      top: -6,
      right: 23,
    },
  
  },
})(props => (
    <Menu
      elevation={2}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  )
)

export const Box = styled(MuiBox)(({ theme }) => ({
  background: 'rgba(0, 0, 0, 0.04)',
  '& span.MuiBox-root': {
    padding: theme.spacing(0.3, 0.4),
    margin: theme.spacing(0, 0.4),
    fontSize: 13,
    width: 66,
    display: 'inline-block',
    textAlign: 'center',
    borderRadius: 7,
  },
  '& span.MuiBox-root.active': {
    background: theme.palette.primary.main,
    color: 'var(--white)',
  },
  '& span.MuiBox-root:hover': {
    cursor: 'pointer',
    background: theme.palette.primary.main,
    color: 'var(--white)',
  },
}))

export const StyledLink = styled(Link)(({ theme }) => ({
'&:hover': {
  textDecoration: 'none',
  color: theme.palette.primary.main,
}
}))
