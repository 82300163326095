import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Switch,
  IconButton,
  Popover,
  Box,
  Typography,
  Tooltip,
} from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import CloseIcon from '@material-ui/icons/Close'
import { formatCostumDateForUI } from 'utils/formatDate'
import { Translate } from 'react-localize-redux'
import PauseIcon from '@material-ui/icons/Pause'
import { pausePlayer, unpausePlayer } from 'routes/PlayerDashboard/api'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { players as playersModule } from 'redux/modules'
import PlayerPauseDialog from './components/PlayerPauseDialog'
import PlayerUnpauseDialog from './components/PlayerUnpauseDialog'
import useStyles from './components/styles'
import { PLAYER_STATUS_DEACTIVATED } from 'constants/player'

const PlayerStatusToggle = ({
  player,
  showText,
  dateFormat,
  updatePlayerPauseStatus,
}) => {
  const classes = useStyles()

  const [isPaused, setIsPaused] = useState(player.paused)
  const [anchorEl, setAnchorEl] = useState(null)
  const [isPauseDialogOpen, setIsPauseDialogOpen] = useState(false)
  const [isUnpauseDialogOpen, setIsUnpauseDialogOpen] = useState(false)
  const [playerData, setPlayerData] = useState(player)

  useEffect(() => {
    setIsPaused(playerData.paused)
  }, [playerData.paused])

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const handleSwitchChange = () => {
    if (isPaused) {
      setIsUnpauseDialogOpen(true)
    } else {
      setIsPauseDialogOpen(true)
    }
  }

  const updatePlayerData = (pauseData) => {
    setPlayerData((prev) => ({ ...prev, ...pauseData }))
  }

  const handlePausePlayerSubmit = (formData) => {
    const { reason, start_date: startDate, end_date: endDate } = formData
    pausePlayer(playerData.uuid, formData)
      .then(() => {
        const submittedData = {
          paused: true,
          paused_reason: reason,
          paused_start_date: startDate,
          paused_end_date: endDate,
        }
        updatePlayerData(submittedData)
        updatePlayerPauseStatus(playerData.uuid, submittedData)
        setIsPauseDialogOpen(false)
      })
      .catch((error) => {
        console.error('Failed to pause player:', error)
      })
  }

  const handleUnpausePlayerSubmit = () => {
    unpausePlayer(playerData.uuid)
      .then(() => {
        const submittedData = {
          paused: false,
          paused_reason: null,
          paused_start_date: null,
          paused_end_date: null,
        }
        updatePlayerData(submittedData)
        updatePlayerPauseStatus(playerData.uuid, submittedData)
        setIsUnpauseDialogOpen(false)
      })
      .catch((error) => {
        console.error('Failed to unpause player:', error)
      })
  }

  return (
    <>
      {isPauseDialogOpen && (
        <PlayerPauseDialog
          handleOpenClose={() => setIsPauseDialogOpen(false)}
          open={isPauseDialogOpen}
          onSubmit={handlePausePlayerSubmit}
          dateFormat={dateFormat}
        />
      )}
      {isUnpauseDialogOpen && (
        <PlayerUnpauseDialog
          handleOpenClose={() => setIsUnpauseDialogOpen(false)}
          open={isUnpauseDialogOpen}
          onSubmit={handleUnpausePlayerSubmit}
        />
      )}
      <Tooltip
        title={
          player.status === PLAYER_STATUS_DEACTIVATED ? (
            <Translate id="player.pause-disabled-tooltip" />
          ) : (
            ''
          )
        }
        arrow
      >
        <span>
          <Switch
            checked={isPaused}
            onChange={handleSwitchChange}
            disableRipple
            disabled={player.status === PLAYER_STATUS_DEACTIVATED}
            classes={{
              root: classes.root,
              switchBase: classes.switchBase,
              track: classes.track,
              checked: classes.checked,
              disabled: classes.disabled,
            }}
            icon={
              <span className={classes.iconContainer}>
                <PauseIcon className={classes.pauseIcon} />
              </span>
            }
            checkedIcon={
              <span className={classes.iconContainer}>
                <PauseIcon className={classes.pauseIcon} />
              </span>
            }
          />
        </span>
      </Tooltip>
      {showText && (
        <Box display="flex" alignItems="center" ml={2}>
          <Typography variant="body1">
            <Translate id={isPaused ? 'player.paused' : 'player.available'} />
          </Typography>
        </Box>
      )}
      {isPaused && (
        <>
          <IconButton
            onClick={handlePopoverOpen}
            size="small"
            className={classes.marginLeft}
          >
            <InfoOutlinedIcon variant="outlined" fontSize="small" />
          </IconButton>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            PaperProps={{
              className: classes.popoverPaper,
            }}
          >
            <Box p={2}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={1}
              >
                <Typography variant="h4">
                  <Translate id="player.pause-information" />
                </Typography>

                <IconButton size="small" onClick={handlePopoverClose}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
              <Typography variant="h4" gutterBottom>
                <Translate id="player.pause-duration" />
              </Typography>
              <Typography variant="body2" gutterBottom>
                {formatCostumDateForUI(
                  playerData.paused_start_date,
                  dateFormat
                )}{' '}
                -{' '}
                {formatCostumDateForUI(playerData.paused_end_date, dateFormat)}
              </Typography>
              <Typography variant="h4" gutterBottom>
                <Translate id="player.pause-reason" />:
              </Typography>
              <Typography variant="body2">
                {playerData.paused_reason}
              </Typography>
            </Box>
          </Popover>
        </>
      )}
    </>
  )
}

PlayerStatusToggle.defaultProps = {
  showText: false,
}

PlayerStatusToggle.propTypes = {
  player: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    paused: PropTypes.bool.isRequired,
    paused_start_date: PropTypes.string,
    paused_end_date: PropTypes.string,
    paused_reason: PropTypes.string,
    status: PropTypes.string.isRequired,
  }).isRequired,
  showText: PropTypes.bool,
  dateFormat: PropTypes.string.isRequired,
  updatePlayerPauseStatus: PropTypes.func.isRequired,
}

export default compose(
  connect(
    ({ auth }) => ({
      dateFormat: auth.user.datetime_format,
    }),
    {
      updatePlayerPauseStatus: playersModule.updatePlayerPauseStatus,
    }
  )
)(PlayerStatusToggle)
