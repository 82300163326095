import { formValueSelector } from 'redux-form'

export default (
  state,
  {
    match: {
      params: { teamId },
    },
  }
) => {
  const selector = formValueSelector('seasons')

  return {
    initialValues: {
      seasons: state.seasons.items[teamId] || [],
    },
    seasons: selector(state, 'seasons'),
    auth: state.auth
  }
}
