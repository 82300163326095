import React from 'react'
import { ArticleLoader, ArticlesCategoriesLoader } from 'loaders'
import ArticleEditContainer from './ArticleEditContainer'
import PROP_TYPES from 'constants/propTypes'

const ArticleEdit = ({
  history,
  match: {
    params: { articleId },
  },
  match,
  location,
}) => (
  <ArticlesCategoriesLoader alwaysRenderChildren passIsLoading>
    <ArticleLoader articleId={articleId}>
      <ArticleEditContainer
        history={history}
        match={match}
        location={location}
      />
    </ArticleLoader>
  </ArticlesCategoriesLoader>
)

ArticleEdit.propTypes = {
  history: PROP_TYPES.history.isRequired,
  match: PROP_TYPES.match.isRequired,
  location: PROP_TYPES.location.isRequired,
}

export default ArticleEdit
