import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { teams } from 'redux/modules'
import { getSignupURL } from 'utils/localstorage'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import Loader from './Loader'
import PROP_TYPES from 'constants/propTypes'

const TeamsLoader = ({
  history,
  children,
  fetchTeams,
  additionalParams,
  ...restProps
}) => {
  const fetchMethod = () => fetchTeams(additionalParams)

  return (
    <Loader
      fetchMethod={() => {
        return fetchMethod().then((res) => {
          if (res.response.data.length === 0) {
            history.push(getSignupURL())
          }
        })
      }}
      {...restProps}
    >
      {children}
    </Loader>
  )
}

TeamsLoader.defaultProps = {
  additionalParams: {},
}
TeamsLoader.propTypes = {
  history: PROP_TYPES.history.isRequired,
  children: PROP_TYPES.children.isRequired,
  fetchTeams: PropTypes.func.isRequired,
  additionalParams: PropTypes.shape(),
}

export default compose(
  withRouter,
  connect(null, {
    fetchTeams: teams.fetchTeams,
  })
)(TeamsLoader)
