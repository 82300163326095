import { getRequest, postRequest } from 'utils/axios'

export const fetchClubAgeClasses = (clubUuid) => {
  const url = `v2/clubs/${clubUuid}/age-classes`

  return getRequest(url)
}

export const fetchClubTrainingPackages = (clubUuid, filterParams = {}) => {
  const url = `v2/clubs/${clubUuid}/training-packages`

  return getRequest(url, filterParams)
}

export const fetchTeamTrainingPackages = (teamUuid, filterParams = {}) => {
  const url = `v2/teams/${teamUuid}/training-packages`

  return getRequest(url, filterParams)
}

export const fetchFavouriteTrainingPackages = (teamUuid, filterParams = {}) => {
  const url = `v2/teams/${teamUuid}/favorite-training-packages`

  return getRequest(url, filterParams)
}

export const updateFavoriteTrainingPackage = (
  trainingPackageUuid,
  favorite
) => {
  const url = `v2/training-packages/${trainingPackageUuid}/favorite`
  const data = { favorite }

  return postRequest(url, data)
}
