import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import moment from 'moment'
import 'moment-duration-format'
import screenfull from 'screenfull'
import ReactPlayer from 'react-player'
import { videos as videosModule } from 'redux/modules'
import {
  AddTag,
  VideoButton,
  SpeedButton,
  MutedButton,
  PlayIcon,
  StopTag,
  Tag,
} from './styledComponents'
import ProgressRange from './components/ProgressRange'
import PROP_TYPES from 'constants/propTypes'

class VideoPlayer extends Component {
  state = {
    isReady: false,
    playing: false,
    volume: 0.8,
    muted: false,
    played: 0,
    playbackRate: 1.0,
    duration: 0,
    playedSeconds: 0,
    isSequenceStarted: false,
    sequenceStart: {
      absolute: 0,
      relative: 0,
    },
    sequenceTags: {
      start: 0,
      end: 1,
    },
    progressRangePosition: {},
  }

  playPause = () => {
    const { playing } = this.state

    this.setState({ playing: !playing })
  }

  handleClickFullscreen = () => {
    screenfull.request(findDOMNode(this.player))
  }

  toggleMuted = () => {
    const { muted } = this.state

    this.setState({ muted: !muted })
  }

  setPlaybackRate = value => {
    this.setState({ playbackRate: parseFloat(value) })
  }

  onSeekChange = value => {
    const { sequenceStart, duration } = this.state
    const {
      video: { id, from = 0, to = 0 },
    } = this.props

    if (id === 'new' && (value * duration < from || value * duration > to)) {
      return
    }

    if (value > sequenceStart.relative) {
      this.player.seekTo(value)
    }
  }

  onProgress = state => {
    const { played, sequenceTags, playing } = this.state
    const {
      video: { id },
    } = this.props

    this.setState(state)

    if (id === 'new' && played > sequenceTags.end / 100 && playing) {
      this.setState({ playing: false })

      this.player.seekTo(sequenceTags.end / 100)
    }
  }

  onDuration = duration => {
    const {
      video: { id, from = 0, to = 0 },
    } = this.props

    const played = from / duration

    const sequenceTags = {
      start: played * 100,
      end: (to / duration) * 100,
    }

    this.setState({ duration, sequenceTags })

    if (id === 'new') {
      this.player.seekTo(played)
    }
  }

  ref = player => {
    this.player = player
  }

  addTag = e => {
    const { duration, playedSeconds } = this.state

    e.stopPropagation()

    const sequenceStartAbsolute = playedSeconds > 1 ? playedSeconds - 1 : 0
    const sequenceStartRelative = sequenceStartAbsolute / duration

    this.setState({
      sequenceStart: {
        absolute: sequenceStartAbsolute,
        relative: sequenceStartRelative,
      },
      isSequenceStarted: true,
    })
  }

  stopTag = async e => {
    const { playedSeconds, sequenceStart, duration } = this.state
    const {
      video: { id, url },
      changeSelectedSequenceId,
      addNewVideoSequence,
    } = this.props

    e.stopPropagation()

    await this.setState({ playing: false })

    if (playedSeconds - sequenceStart.absolute < 1) {
      alert('Sequence must be at list one second')

      return
    }

    await addNewVideoSequence({
      data: {
        id: 'new',
        video_url: url,
        video_id: id,
        from: sequenceStart.absolute,
        to: playedSeconds,
        duration,
      },
    })

    this.setState({ isSequenceStarted: false }, () =>
      changeSelectedSequenceId('new')
    )
  }

  onStartTagDrag = e => {
    const { progressRangePosition, sequenceTags, duration } = this.state

    e.persist()

    setTimeout(() => {
      const start =
        ((e.pageX - progressRangePosition.left) / progressRangePosition.width) *
        100

      if (
        e.pageX < progressRangePosition.left ||
        sequenceTags.end - start < 100 / duration
      ) {
        return
      }

      this.setState({ sequenceTags: { ...sequenceTags, start } })
    }, 50)
  }

  onEndTagDrag = e => {
    const { progressRangePosition, sequenceTags, duration } = this.state

    e.persist()

    setTimeout(() => {
      const end =
        ((e.pageX - progressRangePosition.left) / progressRangePosition.width) *
        100

      if (
        e.pageX < progressRangePosition.left ||
        e.pageX > progressRangePosition.left + progressRangePosition.width ||
        end - sequenceTags.start < 100 / duration
      ) {
        return
      }

      this.setState({ sequenceTags: { ...sequenceTags, end } })
    }, 50)
  }

  changeProgressRangePosition = progressRangePosition => {
    this.setState({ progressRangePosition })
  }

  render() {
    const {
      isReady,
      playing,
      volume,
      muted,
      played,
      playedSeconds,
      playbackRate,
      isSequenceStarted,
      sequenceStart,
      duration = 1,
      sequenceTags,
    } = this.state
    const {
      video: { id, url },
      isSequence,
      sequences,
      updateFromTo,
    } = this.props

    const isNewSequence = id === 'new'

    const videoTime = `
    ${moment
      .duration(playedSeconds, 'seconds')
      .format('HH:mm:ss', { stopTrim: 'mm' })}/${moment
      .duration(duration, 'seconds')
      .format('HH:mm:ss', { stopTrim: 'mm' })} 
    `

    return (
      <>
        <div
          onClick={this.playPause}
          role="presentation"
          style={{position: 'relative'}}
        >
          {!isSequence &&
            isReady &&
            (!isSequenceStarted ? (
              <AddTag onClick={this.addTag}><Translate id="videos.start-tag" /></AddTag>
            ) : (
              <StopTag onClick={this.stopTag}><Translate id="videos.stop-tag" /></StopTag>
            ))}
          {!playing && <PlayIcon />}
          <ReactPlayer
            ref={this.ref}
            width="100%"
            height="100%"
            url={url}
            playing={playing}
            playbackRate={playbackRate}
            volume={volume}
            muted={muted}
            onProgress={this.onProgress}
            onDuration={this.onDuration}
            progressInterval={100}
            onReady={() => this.setState({ isReady: true })}
          />
        </div>
        <div style={{display: "flex"}}>
          <VideoButton onClick={this.playPause} style={{  border: '1px solid black'}}>
            {playing ? <span className="pause" /> : <span className="play" />}
          </VideoButton>
          {videoTime}
          <ProgressRange
            played={played}
            duration={duration}
            onSeekChange={this.onSeekChange}
            sequences={sequences}
            isNewSequence={isNewSequence}
            id={`player-progress-range-${id}`}
            changeProgressRangePosition={this.changeProgressRangePosition}
          >
            <div>
              {isSequenceStarted && (
                <Tag positionLeft={sequenceStart.relative * 100} />
              )}
              {isNewSequence && (
                <>
                  <Tag
                    isNewSequence
                    positionLeft={sequenceTags.start}
                    draggable
                    onDragStart={e => {
                      const img = document.createElement('img')
                      e.dataTransfer.setDragImage(img, 0, 0)
                    }}
                    onDrag={this.onStartTagDrag}
                    onDragEnd={() => updateFromTo(sequenceTags)}
                  />
                  <Tag
                    isNewSequence
                    positionLeft={sequenceTags.end}
                    color="red"
                    draggable
                    onDragStart={e => {
                      const img = document.createElement('img')
                      e.dataTransfer.setDragImage(img, 0, 0)
                    }}
                    onDrag={this.onEndTagDrag}
                    onDragEnd={() => updateFromTo(sequenceTags)}
                  />
                </>
              )}
            </div>
          </ProgressRange>
          <SpeedButton onClick={() => this.setPlaybackRate(1)} style={{  border: '1px solid black'}}>
            1x <span className="fast" />
          </SpeedButton>
          <SpeedButton onClick={() => this.setPlaybackRate(2)} style={{  border: '1px solid black'}}>
            2x <span className="fast" />
          </SpeedButton>
          <MutedButton onClick={this.toggleMuted} style={{  border: '1px solid black'}}>
            <span className={muted ? 'muted' : 'speaker'} />
          </MutedButton>
          <button type="submit" onClick={this.handleClickFullscreen}>
           <Translate id="videos.fullscreen"/>
          </button>
        </div>
      </>
    )
  }
}

VideoPlayer.defaultProps = {
  isSequence: false,
  changeSelectedSequenceId: () => 0,
  sequences: [],
  updateFromTo: () => 0,
}

VideoPlayer.propTypes = {
  video: PropTypes.shape({
    id: PROP_TYPES.id.isRequired,
    url: PropTypes.string.isRequired,
    from: PropTypes.number,
    to: PropTypes.number,
  }).isRequired,
  isSequence: PropTypes.bool,
  changeSelectedSequenceId: PropTypes.func,
  addNewVideoSequence: PropTypes.func.isRequired,
  sequences: PROP_TYPES.arrayOfObjects,
  updateFromTo: PropTypes.func,
}

export default connect(
  null,
  {
    addNewVideoSequence: videosModule.addNewVideoSequence,
  }
)(VideoPlayer)
