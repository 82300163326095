import { styled } from '@material-ui/core/styles'

export default styled('div')(({ theme }) => ({
  '&:before': {
    content: '"\\2715"',
  },
  fontSize: 16,
  width: theme.spacing(5),
  height: theme.spacing(5),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))


