import { styled } from '@material-ui/core/styles'
import { List as MuiList, Paper as MuiPaper } from '@material-ui/core'

export const Paper = styled(MuiPaper)(({ theme }) => ({
  background: theme.palette.background.paper,
}))
export const List = styled(MuiList)(({ theme }) => ({
  '& .MuiTypography-body1': {
    fontSize: 13,
  },
  '& .MuiListItem-button': {
    borderLeft: '4px solid transparent',
  },
  '& .MuiListItem-button:hover': {
    borderLeft: ' 4px solid',
    borderColor: theme.palette.primary.main,
    '& .MuiSvgIcon-root': {
      fill: theme.palette.primary.main,
    },
    '& .MuiTypography-root': {
      color: theme.palette.primary.main,
    },
  },
  '& .MuiListItem-root.selected': {
    borderLeft: '4px solid ',
    borderColor: theme.palette.primary.main,
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    '& .MuiSvgIcon-root': {
      fill: theme.palette.primary.main,
    },
    '& .MuiTypography-root': {
      color: theme.palette.primary.main,
    },
  },
  '& .MuiListItem-root': {
    flexDirection: 'column',
    '& .MuiSvgIcon-root': {
      fill: theme.sidebar.color,
    },
    '& .MuiTypography-root': {
      color: theme.sidebar.color,
    },
  },
  '& .MuiListItemIcon-root': {
    justifyContent: 'center',
  },
  [theme.breakpoints.down('sm')]: {
    '& .MuiTypography-body1': {
      fontSize: 16,
    },
    '& .MuiListItem-root': {
      flexDirection: 'row !important',
    },
  },
}))
