export default {
  CREATE_GLOBAL_ARTICLE: 'create-global-article', // @todo: #roles move functionality outside
  UPDATE_ARTICLE: 'update-article',
  CREATE_ARTICLE_CATEGORY: 'create-article-category',
  DELETE_ARTICLE: 'delete-article',
  CREATE_DRILL_CATEGORY: 'create-drill-category',
  UPDATE_DRILL: 'update-drill',
  CREATE_GLOBAL_DRILL: 'create-global-drill', // @todo: #roles move functionality outside
  UPDATE_TRAINING_PACKAGE: 'update-training-package',
  CREATE_GLOBAL_TRAINING_PACKAGE: 'create-global-training-package', // @todo: #roles move functionality outside
  TRANSLATE: 'translate', // @todo: #roles review

  // TEAMS
  UPDATE_TEAM: 'update-team',

  // INVITE PLAYERS
  IMPORT_TEAM_PLAYERS_CSV: 'import-team-players-csv',
  INVITE_PLAYER_USERS: 'invite-player-users',

  // TASKS MANAGEMENT
  MANAGE_TASK_GROUPS: 'manage-task-groups',
}
