import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import { SequenceModal, SubLink, VideoPlayer } from 'containers'
import { Card, ActionWithPrompt, CloseButton, TextArea } from 'components'
import { OutlinedInput } from '@mui/material'
import { videos as videosModule } from 'redux/modules'
import filterObject from 'utils/filterObject'
import { Grid, Button, Box, makeStyles } from '@material-ui/core'
import Sequences from './components'
import { VIDEO_ANALYSIS_ROUTE } from 'constants/routes'

const useStyles = makeStyles((theme) => ({
  videoContainer: {
    padding: '15px 0',
    position: 'relative',
  },
  leftPart: {
    display: 'table',
  },
  rightPart: {
    overflowY: 'auto',
    paddingRight: 0,
  },
  input: {
    marginRight: '1rem',
    borderRadius: '.5rem!important',
    boxShadow: '0 0 3px rgba(0, 0, 0, 0.1)',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
      borderRadius: 0,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
}))

const VideoLayout = ({
  video,
  historySubPush,
  updateVideo,
  deleteVideo,
  updateVideoSequence,
  deleteVideoSequence,
}) => {
  const [videoState, changeVideoState] = useState(video)
  useEffect(() => {
    changeVideoState({ ...videoState, sequences: video.sequences })
  }, [video.sequences])

  const [selectedSequenceId, changeSelectedSequenceId] = useState(null)

  const leftPart =
    document.getElementById('video-left-part') &&
    document.getElementById('video-left-part').getBoundingClientRect()

  useEffect(() => {
    document.getElementById('video-right-part').style.height = `${
      document.getElementById('video-left-part').clientHeight - 15
    }px`
  }, [leftPart])

  const {
    id: videoId,
    title,
    notes,
    video_url: videoUrl,
    sequences,
    players,
    matches,
    training_id: trainingId,
    match_id: matchId,
  } = videoState

  const onHide = useCallback(() => changeSelectedSequenceId(null), [])

  const selectedSequence = useMemo(
    () => sequences.findById(selectedSequenceId),
    [selectedSequenceId]
  )

  const onChangeTitle = ({ target: { value } }) =>
    changeVideoState({ ...videoState, title: value })
  const onChangeNotes = ({ target: { value } }) =>
    changeVideoState({ ...videoState, notes: value })

  const handleSave = () => {
    updateVideo(
      videoId,
      filterObject(
        { title, notes, training_id: trainingId, match_id: matchId },
        Boolean
      )
    ).then(() => historySubPush(VIDEO_ANALYSIS_ROUTE))
  }

  const handleDelete = () => {
    historySubPush(VIDEO_ANALYSIS_ROUTE)

    deleteVideo(videoId)
  }
  const classes = useStyles()

  return (
    <>
      {selectedSequenceId && (
        <SequenceModal
          onHide={onHide}
          sequence={selectedSequence}
          updateSequence={updateVideoSequence}
          deleteSequence={deleteVideoSequence}
          players={players}
          matches={matches}
          open={Boolean(selectedSequenceId)}
        />
      )}
      <Translate>
        {({ translate }) => (
          <Card>
            <Card.Title
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Translate id="videos.videos" />
              <SubLink to={VIDEO_ANALYSIS_ROUTE}>
                <CloseButton />
              </SubLink>
            </Card.Title>
            <Grid container spacing={2}>
              <Grid item md={10}>
                <Grid item className={classes.leftPart} id="video-left-part">
                  <OutlinedInput
                    variant="outlined"
                    value={title}
                    className={classes.input}
                    onChange={onChangeTitle}
                  />
                  <Box className={classes.videoContainer}>
                    <VideoPlayer
                      video={{ id: videoId, url: videoUrl }}
                      changeSelectedSequenceId={changeSelectedSequenceId}
                      sequences={sequences}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid item md={2}>
                <Grid item className={classes.rightPart} id="video-right-part">
                  <Sequences
                    sequences={sequences}
                    changeSelectedSequenceId={changeSelectedSequenceId}
                  />
                </Grid>
              </Grid>
              <Grid item md={10}>
                <TextArea
                  value={notes || ''}
                  onChange={onChangeNotes}
                  placeholder={translate('videos.notes')}
                />
              </Grid>
              <Grid item md={2}>
                <ActionWithPrompt
                  onSuccess={handleDelete}
                  className="flex-grow-1 text-danger border-danger"
                  message={translate(
                    'videos.are-you-sure-you-want-to-delete-the-video'
                  )}
                  title={translate('button.delete')}
                />

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                >
                  <Translate id="button.save" />
                </Button>
              </Grid>
            </Grid>
          </Card>
        )}
      </Translate>
    </>
  )
}

VideoLayout.propTypes = {
  video: PropTypes.shape().isRequired,
  historySubPush: PropTypes.func.isRequired,
  updateVideo: PropTypes.func.isRequired,
  deleteVideo: PropTypes.func.isRequired,
  updateVideoSequence: PropTypes.func.isRequired,
  deleteVideoSequence: PropTypes.func.isRequired,
}

export default connect(
  ({ videos }) => ({
    video: videos.current,
  }),
  {
    updateVideo: videosModule.updateVideo,
    deleteVideo: videosModule.deleteVideo,
    updateVideoSequence: videosModule.updateVideoSequence,
    deleteVideoSequence: videosModule.deleteVideoSequence,
  }
)(VideoLayout)
