import { combineActions, handleActions } from 'redux-actions'
import {
  actionsTypesGenerator,
  onRequest,
  onSuccess,
  onFailure,
} from 'utils/reduxHelpers'
import initialState from '../initialState'

const namespace = 'TEAM'
const FETCH_TEAM = actionsTypesGenerator(`FETCH_${namespace}`)
const UPDATE_TEAM = actionsTypesGenerator(`UPDATE_${namespace}`)
const CREATE_TEAM_BY_CLUB = actionsTypesGenerator(`CREATE_TEAM_BY_CLUB`)
const PATCH_TEAM = actionsTypesGenerator(`PATCH_${namespace}`)
const FETCH_TEAM_STATISTICS = actionsTypesGenerator(
  `FETCH_${namespace}_STATISTICS`
)

export const fetchTeam = (id, params) => ({
  types: FETCH_TEAM,
  callAPI: {
    method: 'GET',
    entity: 'Team',
    path: `/teams/${id}`,
    params,
  },
})

export const createTeamByClub = (clubUuid, data) => ({
  types: CREATE_TEAM_BY_CLUB,
  callAPI: {
    method: 'POST',
    entity: 'Team',
    path: `/v2/clubs/${clubUuid}/teams`,
    data,
  },
})

export const updateTeam = (id, data) => ({
  types: UPDATE_TEAM,
  callAPI: {
    method: 'PUT',
    entity: 'Team',
    path: `/teams/${id}`,
    data,
  },
})
export const patchTeam = (uuid, data) => ({
  types: PATCH_TEAM,
  callAPI: {
    method: 'PATCH',
    entity: 'Team',
    path: `/v2/teams/${uuid}/`,
    data,
  },
})
export const getTeamSeasonStatistics = (seasonUuid, data) => ({
  types: FETCH_TEAM_STATISTICS,
  callAPI: {
    method: 'GET',
    entity: 'Team Statistics',
    path: `/seasons/${seasonUuid}/statistics`,
    data,
  },
})

export default handleActions(
  {
    [combineActions(
      FETCH_TEAM.request,
      CREATE_TEAM_BY_CLUB.request,
      UPDATE_TEAM.request,
      PATCH_TEAM.request
    )]: onRequest,
    [combineActions(
      CREATE_TEAM_BY_CLUB.success,
      UPDATE_TEAM.success,
      FETCH_TEAM.success
    )]: (state, { response: { data } }) => {
      return onSuccess({
        ...state,
        ...data,
      })
    },
    [PATCH_TEAM.success]: (state, { response: { data } }) => {
      return onSuccess({
        ...state,
        share_player_information: data.share_player_information,
        share_player_skills_with_player: data.share_player_skills_with_player,
        share_player_skills_with_team: data.share_player_skills_with_team,
        share_player_statistics: data.share_player_statistics,
        default_player_attendance_status: data.default_player_attendance_status,
        availability_cutoff_time: data.availability_cutoff_time,
      })
    },
    [combineActions(
      FETCH_TEAM.failure,
      CREATE_TEAM_BY_CLUB.failure,
      UPDATE_TEAM.failure,
      PATCH_TEAM.failure
    )]: onFailure,
  },
  initialState.team
)
