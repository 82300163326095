import React from 'react'
import { LineupLoader } from 'loaders'
import LineupEditContainer from './LineupEditContainer'
import PROP_TYPES from 'constants/propTypes'

const LineupEdit = ({ history, match }) => (
  <LineupLoader lineupUuid={match.params.lineupUuid}>
    <LineupEditContainer history={history} match={match} />
  </LineupLoader>
)

LineupEdit.propTypes = {
  history: PROP_TYPES.history.isRequired,
  match: PROP_TYPES.match.isRequired,
}

export default LineupEdit
