import { handleActions, combineActions } from 'redux-actions'
import {
  actionsTypesGenerator,
  onRequest,
  onSuccess,
  onFailure,
} from 'utils/reduxHelpers'
import initialState from '../initialState'

const namespace = 'LINEUP'
const entity = 'Lineup'

const FETCH_LINEUPS = actionsTypesGenerator(`FETCH_${namespace}S`)
const FETCH_LINEUP = actionsTypesGenerator(`FETCH_${namespace}`)
const CREATE_LINEUP = actionsTypesGenerator(`CREATE_${namespace}`)
const SAVE_MATCH_LINEUP = actionsTypesGenerator(`SAVE_MATCH_${namespace}`)
const UPDATE_LINEUP = actionsTypesGenerator(`UPDATE_${namespace}`)
const DELETE_LINEUP = actionsTypesGenerator(`DELETE_${namespace}`)

export const fetchLineups = (teamUuid) => ({
  types: FETCH_LINEUPS,
  callAPI: {
    method: 'GET',
    entity: `${entity}s`,
    path: `/v2/teams/${teamUuid}/lineups`,
  },
})

export const fetchLineup = (uuid) => ({
  types: FETCH_LINEUP,
  callAPI: {
    method: 'GET',
    entity,
    path: `/lineups/${uuid}`,
  },
})

export const saveMatchLineup = (teamUuid, data) => ({
  types: SAVE_MATCH_LINEUP,
  callAPI: {
    method: 'POST',
    entity,
    path: `/v2/teams/${teamUuid}/lineups`,
    data,
  },
})

export const createLineup = (data) => ({
  types: CREATE_LINEUP,
  callAPI: {
    method: 'POST',
    entity,
    path: '/lineups',
    data,
  },
})

export const updateLineup = (uuid, data) => ({
  types: UPDATE_LINEUP,
  callAPI: {
    method: 'PUT',
    entity,
    path: `/lineups/${uuid}`,
    data,
  },
})

export const deleteLineup = (uuid) => ({
  types: DELETE_LINEUP,
  callAPI: {
    method: 'DELETE',
    entity,
    path: `/v2/lineups/${uuid}`,
  },
  payload: { uuid }, // Include lineup uuid in payload to delete record on success when success and response empty
})

export default handleActions(
  {
    [combineActions(
      FETCH_LINEUPS.request,
      FETCH_LINEUP.request,
      CREATE_LINEUP.request,
      UPDATE_LINEUP.request,
      DELETE_LINEUP.request,
      SAVE_MATCH_LINEUP.request
    )]: onRequest,
    [FETCH_LINEUPS.success]: (state, { response: { data: items = [] } }) =>
      onSuccess({ ...state, items }),
    [combineActions(FETCH_LINEUP.success, UPDATE_LINEUP.success)]: (
      state,
      { response: { data: current = {} } }
    ) => onSuccess({ ...state, current: { ...state.current, ...current } }),
    [combineActions(CREATE_LINEUP.success, SAVE_MATCH_LINEUP.success)]: (
      state,
      { response: { data = {} } }
    ) =>
      onSuccess({
        ...state,
        items: [...state.items, data],
      }),
    [DELETE_LINEUP.success]: (state, { uuid }) =>
      // Uuid is came from payload because response is empty on delete
      onSuccess({ ...state, items: state.items.filterByUuid(uuid) }),
    [combineActions(
      FETCH_LINEUPS.failure,
      FETCH_LINEUP.failure,
      CREATE_LINEUP.failure,
      UPDATE_LINEUP.failure,
      DELETE_LINEUP.failure
    )]: onFailure,
  },
  initialState.lineups
)
