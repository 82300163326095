import React from 'react'
import { connect } from 'react-redux'
import { SubLink, HeartIcon } from 'containers'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {
  Grid,
  Card,
  CardActionArea,
  CardActions,
  CardMedia,
  Typography,
} from '@material-ui/core'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
import { ProcessingIcon } from 'components'
import PROP_TYPES from 'constants/propTypes'
import {
  //  VIDEO_ANALYSIS_ROUTE,
  VIDEO_ROUTE,
} from 'constants/routes'

const MuiCard = withStyles(theme => ({
  root: {
    border: '1px solid #e8e8e8',
    '& .MuiCardContent-root': {
      padding: theme.spacing(2, 2, 0),
    },
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
}))(Card)
const useStyles = makeStyles(theme => ({
  media: {
    height: 134,
  },
  cardActions: {
    paddingLeft: theme.spacing(2),
  },
  title: {
    flex: '1 1 0',
  },
  PlayCircle: {
    position: 'absolute',
    top: '27%',
    height: 67,
    width: 67,
    backgroundColor: '#fff',
    borderRadius: '50%',
    left: theme.spacing(0),
    right: theme.spacing(0),
    margin: theme.spacing(0, 'auto'),
    '& .MuiSvgIcon-root': {
      fontSize: 67,
    },
  },
}))

const VideosCarousel = ({ favoritesVideos }) => {
  const classes = useStyles()

  return (
    <>
      {/* {favoritesVideos.length > 0 && (
      <SubLinkVideos to={VIDEO_ANALYSIS_ROUTE}>
        <span>Videos</span>
      </SubLinkVideos>
    )} */}
      <Grid container spacing={3}>
        {favoritesVideos.map(
          ({
            id,
            thumbnail_url: thumbnail,
            processed,
            title: titleValue,
            error_details: errorDetails,
          }) => (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={id}>
              <MuiCard>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image={processed === 1 && thumbnail}
                    processed={processed}
                  />
                  {processed === 1 ? (
                    <SubLink
                      to={VIDEO_ROUTE.populateURLParams({ videoId: id })}
                      className={classes.PlayCircle}
                    >
                      <PlayCircleFilledIcon fontSize="large" />
                    </SubLink>
                  ) : (
                    <ProcessingIcon
                      placeholder={processed === -1 && errorDetails}
                    />
                  )}
                </CardActionArea>
                <CardActions className={classes.cardActions}>
                  <Typography
                    gutterBottom
                    className={classes.title}
                    variant="h4"
                    component="h4"
                  >
                    {titleValue}
                  </Typography>

                  {processed === 1 ? (
                    <HeartIcon isActive entity="videos" entityId={id} />
                  ) : (
                    <ProcessingIcon
                      placeholder={processed === -1 && errorDetails}
                    />
                  )}
                </CardActions>
              </MuiCard>
            </Grid>
          )
        )}
      </Grid>
    </>
  )
}

VideosCarousel.defaultProps = {
  favoritesVideos: [],
}

VideosCarousel.propTypes = {
  favoritesVideos: PROP_TYPES.arrayOfObjects,
}

export default connect(({ favorites }) => ({
  favoritesVideos: favorites.items.videos,
}))(VideosCarousel)
