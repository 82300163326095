import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import { Translate } from 'react-localize-redux'
import DialogContent from '@material-ui/core/DialogContent'
import { Field, reduxForm } from 'redux-form'
import DocumentUploaderWithProgressBar from 'components/inputes/DocumentUploaderWithProgressBar'
import { connect } from 'react-redux'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { Box, Button, Typography } from '@material-ui/core'
import { deletePlayerAttachment, uploadPlayerAttachment } from '../api'

const OtherDocumentUploadDialog = ({
  open,
  hanldeOpenClose,
  playerUuid,
  setIsAddedOrDeletedDocument,
}) => {
  const handleUploadAttachment = async (formData, config) => {
    return uploadPlayerAttachment(playerUuid, formData, config)
  }

  const handleDeleteAttachment = (attachmentUuid) => {
    return deletePlayerAttachment(attachmentUuid)
  }

  return (
    <Dialog onClose={hanldeOpenClose} open={open} fullWidth maxWidth="sm">
      <DialogContent>
        <Typography variant="h1" gutterBottom>
          <Translate id="team.upload-files" />
        </Typography>

        <Typography variant="body2" gutterBottom>
          <Translate id="uploader.supported-document-type-info" />
        </Typography>
        <Typography variant="body2">
          <Translate id="uploader.max-file-size-document" />
        </Typography>
        <Box mt={3}>
          <form>
            <Field
              name="document"
              component={DocumentUploaderWithProgressBar}
              fileType="document"
              uploadApi={handleUploadAttachment}
              deleteApi={handleDeleteAttachment}
              setIsAddedOrDeletedDocument={setIsAddedOrDeletedDocument}
            />
          </form>
        </Box>
        <Box mt={3} mb={2} textAlign="end">
          <Button onClick={hanldeOpenClose} variant="contained" color="primary">
            <Translate id="button.complete" />
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
OtherDocumentUploadDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  hanldeOpenClose: PropTypes.func.isRequired,
  playerUuid: PropTypes.string.isRequired,
  setIsAddedOrDeletedDocument: PropTypes.func.isRequired,
}

export default compose(
  connect(({ players }) => ({
    playerUuid: players.current.uuid,
  })),
  reduxForm({
    form: 'uploadDocumentForm',
  })
)(OtherDocumentUploadDialog)
