import React from 'react'
import { styled } from '@material-ui/core/styles'
import { PlayIcon, ProcessingIcon, BorderedGreenButton } from 'components'
import PROP_TYPES from 'constants/propTypes'
import SEQUENCE_TAGS from 'constants/sequenceTags'
const Video = styled('div')(({ image }) => ({
  height: '65px',
  borderRadius: '12px',
  margin: '0 5px 2px',
  border: 'var(--grey-solid) 1px',
  backgroundImage: image ? `url(${image})` : 'none',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const Tag = styled(BorderedGreenButton)({
  height: '18px',
  fontSize: '70%',
});

const VideoContainer = styled('div')({
  marginBottom: '15px',
});

const Sequences = ({ sequences, changeSelectedSequenceId }) => {


return (
  <>
    {sequences
      .filterById('new')
      .map(
        ({
          id,
          thumbnail_url: thumbnail,
          processed,
          error_details: errorDetails,
          tag,
        }) => {
          const tagData =
          SEQUENCE_TAGS.find(({ value }) => value === tag)

          return (
            <VideoContainer key={id}>
              <Video image={processed === 1 && thumbnail}>
                {processed === 1 ? (
                  <PlayIcon
                    color="primary"
                    onClick={() => changeSelectedSequenceId(id)}
                  />
                ) : (
                  <ProcessingIcon
                    placeholder={processed === -1 && errorDetails}
                  />
                )}
              </Video>
              <Tag background={tagData.background} color={tagData.color}>
                {tagData.title}
              </Tag>
            </VideoContainer>
          )
        }
      )}
  </>
)
}

Sequences.defaultProps = {
  sequences: [],
}

Sequences.propTypes = {
  sequences: PROP_TYPES.arrayOfObjects,
  changeSelectedSequenceId: PROP_TYPES.func.isRequired,
}

export default Sequences
