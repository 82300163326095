import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { makeStyles } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import FormControl from '@material-ui/core/FormControl'
import { Input, RadioGroup } from 'components'
import { Button, Grid } from '@material-ui/core'
import { Translate } from 'react-localize-redux'

const useStyles = makeStyles((theme) => ({
  label: {
    display: 'flex',
    alignItems: 'center',

    '& .MuiBox-root': {
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
  },
  errorOnBottom: {
    '& .MuiFormHelperText-root.Mui-error': {
      position: 'absolute',
      bottom: '-20px',
      marginLeft: '14px',
    },
  },
}))

const validateName = (value) => {
  return value ? undefined : <Translate id="myteams.required-name" />

}

const validateAgeClass = (value) => {
  return value ? undefined : <Translate id="myteams.required-age-class" />

}

const GENDER_OPTIONS = [
  {
    value: 'm',
    title: 'Male',
  },
  {
    value: 'f',
    title: 'Female',
  },
]

const CreateTeamForm = ({
  open,
  onClose,
  handleSubmit,
  ageClasses,
  initialValues,
}) => {
  const classes = useStyles()

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        <Translate id="myteams.create-team" />
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                name="name"
                label={<Translate id="myteams.team-name" />}
                component={Input}
                validate={validateName}
              />
            </Grid>
            <Grid item xs={12}>
              <label className={classes.label}>
                <FormControl className={classes.errorOnBottom}>
                  <Field
                    name="age_class_uuid"
                    label={<Translate id="team.age-class" />}
                    component={RadioGroup}
                    options={(ageClasses || []).map((ageClass) => ({
                      value: ageClass.uuid,
                      title: ageClass.name,
                    }))}
                    validate={validateAgeClass}
                  />
                </FormControl>
              </label>
            </Grid>
            <Grid item xs={12}>
              <Field
                name="gender"
                label={<Translate id="team.gender" />}
                component={RadioGroup}
                options={GENDER_OPTIONS}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            <Translate id="button.cancel" />
          </Button>
          <Button type="submit" color="primary">
            <Translate id="button.save" />
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

CreateTeamForm.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  ageClasses: PropTypes.array,
}

export default reduxForm({
  form: 'createTeamForm',
})(CreateTeamForm)
