const getFields = (key, value) => {
  if (!Array.isArray(value)) {
    return [{ key, value }]
  }

  if (!value.length) {
    return [{ key: `${key}[]`, value }]
  }

  const fields = []

  value.forEach((valueItem, valueIndex) => {
    if (typeof valueItem !== 'object') {
      fields.push({
        key: `${key}[${valueIndex}]`,
        value: valueItem,
      })
    } else {
      Object.entries(valueItem).forEach(item => {
        fields.push(...getFields(`${key}[${valueIndex}][${item[0]}]`, item[1]))
      })
    }
  })

  return fields
}

export default (data = {}) => {
  const result = {}

  Object.entries(data).forEach(([key, value]) => {
    getFields(key, value).forEach(field => {
      result[field.key] = field.value
    })
  })

  return result
}
