import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  IconButton,
} from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import CloseIcon from '@material-ui/icons/Close'
import { useStyles } from '../styles'
import CategoryItem from './CategoryItem'

const CategoriesDialog = ({
  isOpen,
  onClose,
  categories,
  initialSelectedCategories,
  onCategoryChange,
}) => {
  const classes = useStyles()

  const [selectedCategories, setSelectedCategories] = useState(
    initialSelectedCategories
  )

  const findCategoryByUuid = (allCategories, targetUuid) => {
    const searchCategories = (currentCategories) => {
      const foundCategory = currentCategories.find(
        (category) => category.uuid === targetUuid
      )

      if (foundCategory) return foundCategory
      const children = currentCategories.flatMap(
        (category) => category.relationships.children || []
      )

      return searchCategories(children)
    }

    return searchCategories(allCategories)
  }

  const updateCategorySelection = (categoryUuid, isSelected) => {
    const updatedSelectedCategories = new Set(selectedCategories)

    const selectAllSubcategories = (currentUuid) => {
      const category = findCategoryByUuid(categories, currentUuid)

      if (category) {
        if (isSelected) {
          updatedSelectedCategories.add(currentUuid)
        } else {
          updatedSelectedCategories.delete(currentUuid)
        }
        const { children } = category.relationships
        if (children && children.length > 0) {
          children.forEach((child) => selectAllSubcategories(child.uuid))
        }
      }
    }

    selectAllSubcategories(categoryUuid)
    setSelectedCategories([...updatedSelectedCategories])
  }

  const handleCategoryToggle = (uuid, selected) => {
    updateCategorySelection(uuid, selected)
  }

  const handleSave = () => {
    onCategoryChange(selectedCategories)
    onClose()
  }

  const handleReset = () => {
    setSelectedCategories([])
    onCategoryChange([])
    onClose()
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="lg"
      classes={{ paper: classes.dialogWidth }}
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Translate id="training.select-filters" />
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box>
          {categories.map((category) => (
            <CategoryItem
              key={category.uuid}
              category={category}
              selectedCategories={selectedCategories}
              onToggle={handleCategoryToggle}
            />
          ))}
        </Box>
      </DialogContent>
      <DialogActions className={classes.stickyButtons}>
        <Button variant="contained" onClick={handleReset}>
          <Translate id="button.reset" />
        </Button>
        <Button color="primary" variant="contained" onClick={handleSave}>
          <Translate id="button.save" />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

CategoriesDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      relationships: PropTypes.shape({
        children: PropTypes.arrayOf(PropTypes.shape({})),
      }).isRequired,
    })
  ).isRequired,
  initialSelectedCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
  onCategoryChange: PropTypes.func.isRequired,
}

export default CategoriesDialog
