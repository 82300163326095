import React from 'react'
import { CardHeader, Card, CardContent } from '@material-ui/core'
import { useCardStyles, Heading } from '../styles'
import PropTypes from 'prop-types'

const PlayersListCard = ({ title, children }) => {
  const classes = useCardStyles()

  return (
    <Card className={classes.root}>
      <CardHeader
        title={
          <Heading variant="h4" component="h4">
            {title}
          </Heading>
        }
      />
      <CardContent>{children}</CardContent>
    </Card>
  )
}

PlayersListCard.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
}
export default PlayersListCard
