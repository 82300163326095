import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import AccordionItem from 'components/AccordionItem'
import { Translate } from 'react-localize-redux'

const accordions = [
  { key: 'drill_flow', title: 'drills.drill-flow', defaultOpen: true },
  { key: 'organization', title: 'drills.organization' },
  { key: 'coaching_points', title: 'drills.coaching-points' },
  { key: 'variations', title: 'drills.variations' },
]

const DrillDetailsSection = ({ drill }) => {
  return (
    <>
      {drill.description && (
        <Typography variant="h5" component="h5">
          {drill.description}
        </Typography>
      )}
      {accordions.map(({ key, title, defaultOpen }) =>
        drill[key] ? (
          <AccordionItem
            key={key}
            defaultOpen={defaultOpen}
            title={<Translate id={title} />}
          >
            <div dangerouslySetInnerHTML={{ __html: drill[key] }} />
          </AccordionItem>
        ) : null
      )}
    </>
  )
}

DrillDetailsSection.propTypes = {
  drill: PropTypes.shape({
    description: PropTypes.string,
    drill_flow: PropTypes.string,
    organization: PropTypes.string,
    coaching_points: PropTypes.string,
    variations: PropTypes.string,
  }).isRequired,
}

export default DrillDetailsSection
