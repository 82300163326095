/* eslint-disable no-useless-escape */
/* eslint-disable no-restricted-syntax */
import { formatDateFromServer, formatDateTimeToServer } from 'utils/formatDate'

export const validateEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return re.test(String(email).toLowerCase())
}

export const handleTextField = ({ target: { name, value } }, data, setData) => {
  let finalData = { ...data }

  if (value && value.length > 0 && name.includes('email')) {
    finalData = { ...finalData, [`${name}_error_valid`]: !validateEmail(value) }
  } else if (finalData[`${name}_error_valid`]) {
    delete finalData[`${name}_error_valid`]
  }

  setData({
    ...finalData,
    [name]: value,
    [`${name}_error`]: !value || value === '' || value === undefined,
  })
}
export const handleCheckBox = (
  { target: { name, checked } },
  data,
  setData
) => {
  setData({
    ...data,
    [name]: checked ? 1 : 0,
  })
}

export const handleDateChange = (date, key, data, setData) =>
  setData({ ...data, [key]: formatDateFromServer(date)[0] })

export const handleDateTimeChange = (dateTime, key, data, setData) =>
  setData({ ...data, [key]: formatDateTimeToServer(dateTime) })

export const checkTextFieldError = (objects, keys) => {
  for (const key of keys) {
    if (
      key in objects &&
      (!objects[key] ||
        objects[key] === '' ||
        objects[key] === undefined ||
        objects[key].length === 0)
    ) {
      return `${key}_error`
    }
  }

  return ''
}

export const checkError = data => {
  for (const key of Object.keys(data)) {
    if (key.includes('error') && data[key]) {
      return key
    }
  }

  return ''
}

export const filterDataWithoutError = data => {
  const finalData = {}

  for (const key of Object.keys(data)) {
    if (!key.includes('error')) {
      finalData[key] = data[key]
    }
  }

  return finalData
}
