import React from 'react'
import Menu from '@material-ui/core/Menu'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  menu: {
    '& .MuiPopover-paper': {
    border: '1px solid #eee',
    boxShadow: '1px 1px 10px 0 rgba(0,0,0,0.1)',
    borderRadius: 10,
  },
  '& .MuiSvgIcon-root': {
    marginRight: theme.spacing(1.5)
  }
},
}))

const DropDownMenu = ({
  id,
  anchorEl,
  open,
  edge,
  onClose,
  getContentAnchorEl,
  anchorOrigin,
  transformOrigin,
  elevation,
  children,
  classNameLabel,
}) => {
  const classes = useStyles()

  return (
    <Menu
      id={id}
      anchorEl={anchorEl}
      elevation={elevation}
      open={open}
      edge={edge}
      getContentAnchorEl={getContentAnchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onClose={onClose}
      className={clsx(classes.menu, classNameLabel )}
    >
      {children}
    </Menu>
  )
}

export default DropDownMenu
