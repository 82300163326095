import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { DragSource } from 'react-dnd'
import PROP_TYPES from 'constants/propTypes'

const DraggablePlayer = ({
  children,
  connectDragSource,
  connectDragPreview,
  playerId,
  handleSave,
}) => {
  const [offset, changeOffset] = useState({ offsetX: 0, offsetY: 0 })

  useEffect(() => {
    const { width, height } = document
      .getElementById(playerId)
      .getBoundingClientRect()

    changeOffset({ offsetX: width / 2, offsetY: height / 2 })
  }, [])

  return connectDragPreview(
    connectDragSource(
      <div id={playerId} onDragEnd={handleSave} >
        {children}
      </div>
    ),
    offset
  )
}

DraggablePlayer.propTypes = {
  children: PROP_TYPES.children.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  connectDragPreview: PropTypes.func.isRequired,
  playerId: PROP_TYPES.id.isRequired,
}

export default DragSource(
  'player',
  {
    beginDrag: ({ playerId }) => ({ playerId }),
  },
  connect => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
  })
)(DraggablePlayer)
