import { combineActions, handleActions, createAction } from 'redux-actions'
import {
  actionsTypesGenerator,
  onRequest,
  onFailure,
  onSuccess,
} from 'utils/reduxHelpers'
import initialState from '../initialState'

const namespace = 'VIDEO'

const UPLOAD_VIDEO = actionsTypesGenerator(`UPLOAD_${namespace}`)
const FETCH_VIDEOS = actionsTypesGenerator(`FETCH_${namespace}S`)
const FETCH_VIDEO = actionsTypesGenerator(`FETCH_${namespace}`)
const UPDATE_VIDEO = actionsTypesGenerator(`UPDATE_${namespace}`)
const DELETE_VIDEO = actionsTypesGenerator(`DELETE_${namespace}`)
const CREATE_VIDEO_SEQUENCE = actionsTypesGenerator(`CREATE_VIDEO_SEQUENCE`)
const UPDATE_VIDEO_SEQUENCE = actionsTypesGenerator(`UPDATE_VIDEO_SEQUENCE`)
const DELETE_VIDEO_SEQUENCE = actionsTypesGenerator(`DELETE_VIDEO_SEQUENCE`)

const UPDATE_VIDEO_LOCAL = `UPDATE_${namespace}_LOCAL`
const ADD_NEW_VIDEO_SEQUENCE = `ADD_NEW_${namespace}_SEQUENCE`
const REMOVE_NEW_VIDEO_SEQUENCE = `REMOVE_NEW_${namespace}_SEQUENCE`

export const uploadVideo = (data, onProgress) => ({
  types: UPLOAD_VIDEO,
  callAPI: {
    method: 'POST',
    entity: 'Video',
    path: '/videos',
    data,
    onProgress,
  },
})

export const fetchVideos = () => ({
  types: FETCH_VIDEOS,
  callAPI: {
    method: 'GET',
    entity: 'Videos',
    path: '/videos',
  },
})

export const fetchVideo = (id, params) => ({
  types: FETCH_VIDEO,
  callAPI: {
    method: 'GET',
    entity: 'Video',
    path: `/videos/${id}`,
    params,
  },
})

export const updateVideo = (id, data) => ({
  types: UPDATE_VIDEO,
  callAPI: {
    method: 'PUT',
    entity: 'Video',
    path: `/videos/${id}`,
    data,
  },
})

export const deleteVideo = id => ({
  types: DELETE_VIDEO,
  callAPI: {
    method: 'DELETE',
    entity: 'Video',
    path: `/videos/${id}`,
  },
  payload: { id },
})

export const createVideoSequence = (videoId, data) => ({
  types: CREATE_VIDEO_SEQUENCE,
  callAPI: {
    method: 'POST',
    entity: 'Video sequence',
    path: '/sequences',
    data,
  },
  payload: { videoId },
})

export const updateVideoSequence = (id, data) => ({
  types: UPDATE_VIDEO_SEQUENCE,
  callAPI: {
    method: 'PUT',
    entity: 'Video sequence',
    path: `/sequences/${id}`,
    data,
  },
  payload: { id },
})

export const deleteVideoSequence = id => ({
  types: DELETE_VIDEO_SEQUENCE,
  callAPI: {
    method: 'DELETE',
    entity: 'Video sequence',
    path: `/sequences/${id}`,
  },
  payload: { id },
})

export const updateVideoLocal = createAction(UPDATE_VIDEO_LOCAL)
export const addNewVideoSequence = createAction(ADD_NEW_VIDEO_SEQUENCE)
export const removeNewVideoSequence = createAction(REMOVE_NEW_VIDEO_SEQUENCE)

export default handleActions(
  {
    [combineActions(
      FETCH_VIDEOS.request,
      FETCH_VIDEO.request,
      UPDATE_VIDEO.request,
      DELETE_VIDEO.request,
      CREATE_VIDEO_SEQUENCE.request,
      UPDATE_VIDEO_SEQUENCE.request,
      DELETE_VIDEO_SEQUENCE.request
    )]: onRequest,
    [UPLOAD_VIDEO.request]: state => ({ ...state, isSaving: true }), // TODO: if upload multiple will reset after first uploaded
    [UPLOAD_VIDEO.success]: (state, { response: { data = {} } }) => ({
      ...state,
      isSaving: false,
      error: null,
      items: [data, ...state.items],
    }),
    [FETCH_VIDEOS.success]: (state, { response: { data: items = [] } }) =>
      onSuccess({ ...state, items }),
    [FETCH_VIDEO.success]: (state, { response: { data: current = {} } }) =>
      onSuccess({ ...state, current }),
    [UPDATE_VIDEO.success]: (state, { response: { data = {} } }) => {
      const items = state.items.updateById(data.id, data)

      return onSuccess({
        ...state,
        items,
        current: { ...state.current, ...data },
      })
    },
    [DELETE_VIDEO.success]: (state, { id }) => {
      const items = state.items.filterById(id)

      return onSuccess({
        ...state,
        items,
      })
    },
    [CREATE_VIDEO_SEQUENCE.success]: (
      state,
      { response: { data = {} }, videoId }
    ) => {
      const current =
        state.current.id === videoId
          ? { ...state.current, sequences: [data, ...state.current.sequences] }
          : state.current

      return onSuccess({
        ...state,
        current,
      })
    },
    [UPDATE_VIDEO_SEQUENCE.success]: (
      state,
      { response: { data = {} }, id }
    ) => {
      const sequences = state.current.sequences.updateById(id, data)

      return onSuccess({
        ...state,
        current: {
          ...state.current,
          sequences,
        },
      })
    },
    [DELETE_VIDEO_SEQUENCE.success]: (state, { id }) => {
      const current = {
        ...state.current,
        sequences: state.current.sequences.filterById(id),
      }

      return onSuccess({
        ...state,
        current,
      })
    },
    [updateVideoLocal]: (state, { payload: { id, data } }) => {
      const items = state.items.updateById(id, data)

      return {
        ...state,
        items,
        current: { ...state.current, ...data },
      }
    },
    [addNewVideoSequence]: (state, { payload: { data } }) => ({
      ...state,
      current: {
        ...state.current,
        sequences: [data, ...state.current.sequences],
      },
    }),
    [removeNewVideoSequence]: state => ({
      ...state,
      current: {
        ...state.current,
        sequences: state.current.sequences.filterById('new'),
      },
    }),
    [combineActions(
      FETCH_VIDEOS.failure,
      FETCH_VIDEO.failure,
      UPDATE_VIDEO.failure,
      DELETE_VIDEO.failure,
      CREATE_VIDEO_SEQUENCE.failure,
      UPDATE_VIDEO_SEQUENCE.failure,
      DELETE_VIDEO_SEQUENCE.failure
    )]: onFailure,
    [UPLOAD_VIDEO.failure]: (state, { payload }) => ({
      ...state,
      isSaving: false,
      error: payload,
    }),
  },
  initialState.videos
)
