import React, { useState } from 'react'
import {
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  makeStyles,
} from '@material-ui/core'
import ShareIcon from '@material-ui/icons/Share'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import TelegramIcon from '@material-ui/icons/Telegram'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import LinkIcon from '@material-ui/icons/Link'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

const useStyles = makeStyles((theme) => ({
  menuList: {
    display: 'flex',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '8px',
    padding: theme.spacing(0.9),
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: 30,
    textAlign: 'center',
    paddingRight: '8px',
    paddingLeft: '8px',
  },
  menuItemIcon: {
    marginBottom: theme.spacing(0.5),
    color: theme.palette.text.secondary,
  },
  menuItemText: {
    fontSize: '0.625rem',
    color: theme.palette.text.secondary,
  },
}))

const SHARE_TYPES = {
  PDF: 'pdf',
  EMAIL: 'email',
  COPY: 'copy',
  WHATSAPP: 'whatsapp',
}

const ShareMenu = ({ onShare }) => {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState(null)
  const [downloadUrl, setDownloadUrl] = useState(null)

  const handleShareClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleShare = async (shareType) => {
    try {
      let downloadUrlResponse = downloadUrl

      if (!downloadUrl) {
        const response = await onShare()
        downloadUrlResponse = response.data
        setDownloadUrl(downloadUrlResponse)
      }

      switch (shareType) {
        case SHARE_TYPES.PDF:
          window.open(downloadUrlResponse, '_blank')
          break
        case SHARE_TYPES.EMAIL:
          const emailSubject = encodeURIComponent(
            'Share training via Coachbetter'
          )
          window.location.href = `mailto:?body=${encodeURIComponent(
            downloadUrlResponse
          )}&subject=${emailSubject}`
          break
        case SHARE_TYPES.COPY:
          navigator.clipboard.writeText(downloadUrlResponse)
          break
        case SHARE_TYPES.WHATSAPP:
          window.location.href = `whatsapp://send?text=${encodeURIComponent(
            downloadUrlResponse
          )}`
          break
        default:
          break
      }
    } catch (error) {
      toast.error(<Translate id="errors.drills.share" />)
      console.error('Error during share action:', error)
    } finally {
      handleClose()
    }
  }

  return (
    <>
      <Tooltip title="Share">
        <IconButton onClick={handleShareClick} size='small'>
          <ShareIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        classes={{ list: classes.menuList }}
      >
        <MenuItem
          className={classes.menuItem}
          onClick={() => handleShare(SHARE_TYPES.PDF)}
        >
          <InsertDriveFileIcon size="small" className={classes.menuItemIcon} />
          <span className={classes.menuItemText}>
            <Translate id="button.pdf" />
          </span>
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={() => handleShare(SHARE_TYPES.EMAIL)}
        >
          <TelegramIcon className={classes.menuItemIcon} />
          <span className={classes.menuItemText}>
            <Translate id="button.mail" />
          </span>
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={() => handleShare(SHARE_TYPES.COPY)}
        >
          <LinkIcon className={classes.menuItemIcon} />
          <span className={classes.menuItemText}>
            <Translate id="button.copy" />
          </span>
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={() => handleShare(SHARE_TYPES.WHATSAPP)}
        >
          <WhatsAppIcon className={classes.menuItemIcon} />
          <span className={classes.menuItemText}>
            <Translate id="button.whatapp" />
          </span>
        </MenuItem>
      </Menu>
    </>
  )
}

ShareMenu.propTypes = {
  onShare: PropTypes.func.isRequired,
}

export default ShareMenu
