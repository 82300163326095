import { handleActions, combineActions } from 'redux-actions'
import {
  actionsTypesGenerator,
  onRequest,
  onSuccess,
  onFailure,
} from 'utils/reduxHelpers'
import initialState from '../initialState'

const namespace = 'TRAINING_PACKAGE'
const entity = 'Training package'

const FETCH_TRAINING_PACKAGES = actionsTypesGenerator(`FETCH_${namespace}S`)
const FETCH_TRAINING_PACKAGE = actionsTypesGenerator(`FETCH_${namespace}`)
const CREATE_TRAINING_PACKAGE = actionsTypesGenerator(`CREATE_${namespace}`)
const UPDATE_TRAINING_PACKAGE = actionsTypesGenerator(`UPDATE_${namespace}`)
const DELETE_TRAINING_PACKAGE = actionsTypesGenerator(`DELETE_${namespace}`)

export const fetchTrainingPackages = params => ({
  types: FETCH_TRAINING_PACKAGES,
  callAPI: {
    method: 'GET',
    entity: `${entity}s`,
    path: '/training_packages',
    params,
  },
})

export const fetchTrainingPackage = (id, params) => ({
  types: FETCH_TRAINING_PACKAGE,
  callAPI: {
    method: 'GET',
    entity,
    path: `/training_packages/${id}`,
    params,
  },
})

export const createTrainingPackage = data => ({
  types: CREATE_TRAINING_PACKAGE,
  callAPI: {
    method: 'POST',
    entity,
    path: '/training_packages',
    data,
  },
})

export const updateTrainingPackage = (id, data) => ({
  types: UPDATE_TRAINING_PACKAGE,
  callAPI: {
    method: 'PUT',
    entity,
    path: `/training_packages/${id}`,
    data,
  },
})

export const deleteTrainingPackage = id => ({
  types: DELETE_TRAINING_PACKAGE,
  callAPI: {
    method: 'DELETE',
    entity,
    path: `/training_packages/${id}`,
  },
  payload: { id },
})

export default handleActions(
  {
    [combineActions(
      FETCH_TRAINING_PACKAGES.request,
      FETCH_TRAINING_PACKAGE.request,
      CREATE_TRAINING_PACKAGE.request,
      UPDATE_TRAINING_PACKAGE.request,
      DELETE_TRAINING_PACKAGE.request
    )]: onRequest,
    [FETCH_TRAINING_PACKAGES.success]: (
      state,
      { response: { data: items = [] } }
    ) => onSuccess({ ...state, items }),
    [FETCH_TRAINING_PACKAGE.success]: (
      state,
      { response: { data: current = {} } }
    ) => onSuccess({ ...state, current: { ...state.current, ...current } }),
    [CREATE_TRAINING_PACKAGE.success]: (state, { response }) => {
      const { data = {} } = response

      return onSuccess({
        ...state,
        items: [data, ...state.items],
      })
    },
    [UPDATE_TRAINING_PACKAGE.success]: (state, { response }) => {
      const { data = {} } = response

      return onSuccess({
        ...state,
        current: { ...state.current, ...data },
      })
    },
    [DELETE_TRAINING_PACKAGE.success]: (state, { id }) =>
      onSuccess({ ...state, items: state.items.filterById(id) }),
    [combineActions(
      FETCH_TRAINING_PACKAGES.failure,
      FETCH_TRAINING_PACKAGE.failure,
      CREATE_TRAINING_PACKAGE.failure,
      UPDATE_TRAINING_PACKAGE.failure,
      DELETE_TRAINING_PACKAGE.failure
    )]: onFailure,
  },
  initialState.trainingPackages
)
