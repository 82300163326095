/* eslint-disable react/no-multi-comp */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {
  IconButton,
  Divider,
  Typography,
  Tabs,
  Tab,
  useMediaQuery,
  Box,
  Dialog,
} from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import CloseIcon from '@material-ui/icons/Close'
import { track } from 'utils/segmentTracking'
import { withRouter } from 'react-router-dom'
import TimerIcon from '@material-ui/icons/Timer'
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents'
import EventIcon from '@material-ui/icons/Event'
import DateRangeIcon from '@material-ui/icons/DateRange'
import AlertDialog from 'components/AlertDialog'
import TrainingAndGame from './TrainingAndGame'
import { match, training } from 'constants/trackeventsname'
import PROP_TYPES from 'constants/propTypes'
import { TRAININGS_GAME } from 'constants/calender'
import UserActionDialog from './UserActionDialog'

const styles = (theme) => ({
  root: {
    margin: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: '18px',
    paddingBottom: '18px',
    textAlign: 'left',
  },

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0.4),
    color: theme.palette.grey[500],
  },
})
const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      '& .MuiDialog-paper': {
        minWidth: '95%',
      },
    },
  },
}))
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h4">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          size="medium"
          className={classes.closeButton}
        >
          <CloseIcon onClick={onClose} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})
const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    '& .MuiTab-root': {
      minWidth: '100px',
    },
  },
  [theme.breakpoints.down('xs')]: {
    root: {
      '& .MuiTab-root': {
        minWidth: '68px',
      },
      '& .MuiTab-wrapper': {
        fontSize: '0.75em',
      },
    },
  },
}))(MuiDialogContent)

const TabPanel = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}
const EventPopup = ({
  open,
  onClose,
  onOK,
  trainingAndGame,
  createTraining,
  updateTraining,
  createMatch,
  updateMatch,
  isAlertBox,
  duplicateData,
  calenderDateSelected,
  calenderDate,
  createEvent,
  updateEvent,
  scheduleTrainings,
  match: { params },
  selectedEventType,
  isDuplicate,
  isTrainingSeries,
  isTrainingDialogOpen
}) => {
  const classes = useStyles()
  const { teamId } = params
  const [activeTabName, setActiveTabName] = useState(TRAININGS_GAME.training)
  useEffect(() => {
    if (!isAlertBox) {
      if (
        trainingAndGame === TRAININGS_GAME.training ||
        trainingAndGame === TRAININGS_GAME.multiTraining
      ) {
        if (duplicateData) {
          track(training.training_duplicate_popup, {
            team_id: +teamId,
            category: training.training,
            duplicate_training_id: duplicateData.id,
          })
        } else {
          track(training.training_create_popup, {
            team_id: +teamId,
            category: training.training,
          })
        }
      } else if (trainingAndGame === TRAININGS_GAME.game) {
        if (duplicateData) {
          track(match.match_duplicate_popup, {
            team_id: +teamId,
            category: match.match,
            duplicate_match_id: duplicateData.id,
          })
        } else {
          track(match.match_create_popup, {
            team_id: +teamId,
            category: match.match,
          })
        }
      }
    }
    if (selectedEventType === TRAININGS_GAME.event) {
      setActiveTabName(TRAININGS_GAME.event)
    } else if (selectedEventType === TRAININGS_GAME.game) {
      setActiveTabName(TRAININGS_GAME.game)
    } else if (selectedEventType === TRAININGS_GAME.training) {
      setActiveTabName(TRAININGS_GAME.training)
    }
  }, [selectedEventType])

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }
  const handleTabChange = (event, newValue) => {
    setActiveTabName(newValue)
  }
  const isMobile = useMediaQuery(`(max-width: 600px)`)

  return (
    <>
      {!isAlertBox && !isTrainingDialogOpen ? (
        <Dialog open={open} onClose={onClose} className={classes.root}>
          <DialogTitle onClose={onClose}>
            <Translate id="button.plan-event" />
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Tabs
              value={activeTabName}
              onChange={handleTabChange}
              variant={
                isMobile
                  ? 'scrollable'
                  : !selectedEventType
                  ? 'fullWidth'
                  : 'standard'
              }
              centered={!!selectedEventType}
            >
              <Tab
                icon={<EventIcon color="action" />}
                label={<Translate id="menu.training" />}
                {...a11yProps(TRAININGS_GAME.training)}
                style={{
                  display:
                    selectedEventType &&
                    activeTabName !== TRAININGS_GAME.training
                      ? 'none'
                      : 'block',
                }}
                value={TRAININGS_GAME.training}
              />
              <Tab
                icon={<EmojiEventsIcon color="action" />}
                label={<Translate id="button.match" />}
                {...a11yProps(TRAININGS_GAME.game)}
                style={{
                  display:
                    selectedEventType && activeTabName !== TRAININGS_GAME.game
                      ? 'none'
                      : 'block',
                }}
                value={TRAININGS_GAME.game}
              />
              <Tab
                icon={<TimerIcon color="action" />}
                label={<Translate id="button.event" />}
                {...a11yProps(TRAININGS_GAME.event)}
                style={{
                  display:
                    selectedEventType && activeTabName !== TRAININGS_GAME.event
                      ? 'none'
                      : 'block',
                }}
                value={TRAININGS_GAME.event}
              />
              <Tab
                icon={<DateRangeIcon color="action" />}
                label={<Translate id="button.training-series" />}
                {...a11yProps(TRAININGS_GAME.multiTraining)}
                style={{
                  display:
                    selectedEventType &&
                    activeTabName !== TRAININGS_GAME.multiTraining
                      ? 'none'
                      : 'block',
                }}
                value={TRAININGS_GAME.multiTraining}
              />
            </Tabs>
            <TabPanel value={activeTabName} index={TRAININGS_GAME.training}>
              <TrainingAndGame
                trainingAndGame={TRAININGS_GAME.training}
                createTraining={createTraining}
                updateTraining={updateTraining}
                scheduleTrainings={scheduleTrainings}
                createMatch={createMatch}
                createEvent={createEvent}
                updateEvent={updateEvent}
                updateMatch={updateMatch}
                duplicateData={duplicateData}
                calenderDateSelected={calenderDateSelected}
                calenderDate={calenderDate}
                isDuplicate={isDuplicate}
                isTrainingSeries={isTrainingSeries}
              />
            </TabPanel>
            <TabPanel value={activeTabName} index={TRAININGS_GAME.game}>
              <TrainingAndGame
                trainingAndGame={TRAININGS_GAME.game}
                createTraining={createTraining}
                updateTraining={updateTraining}
                scheduleTrainings={scheduleTrainings}
                createMatch={createMatch}
                createEvent={createEvent}
                updateEvent={updateEvent}
                updateMatch={updateMatch}
                duplicateData={duplicateData}
                calenderDateSelected={calenderDateSelected}
                calenderDate={calenderDate}
                isDuplicate={isDuplicate}
              />
            </TabPanel>
            <TabPanel value={activeTabName} index={TRAININGS_GAME.event}>
              <TrainingAndGame
                trainingAndGame={TRAININGS_GAME.event}
                createTraining={createTraining}
                updateTraining={updateTraining}
                scheduleTrainings={scheduleTrainings}
                createMatch={createMatch}
                createEvent={createEvent}
                updateEvent={updateEvent}
                updateMatch={updateMatch}
                duplicateData={duplicateData}
                calenderDateSelected={calenderDateSelected}
                calenderDate={calenderDate}
                isDuplicate={isDuplicate}
              />
            </TabPanel>
            <TabPanel
              value={activeTabName}
              index={TRAININGS_GAME.multiTraining}
            >
              <TrainingAndGame
                trainingAndGame={TRAININGS_GAME.multiTraining}
                createTraining={createTraining}
                updateTraining={updateTraining}
                scheduleTrainings={scheduleTrainings}
                createMatch={createMatch}
                updateMatch={updateMatch}
                createEvent={createEvent}
                updateEvent={updateEvent}
                duplicateData={duplicateData}
                calenderDateSelected={calenderDateSelected}
                calenderDate={calenderDate}
                isDuplicate={isDuplicate}
                isTrainingSeries={isTrainingSeries}
              />
            </TabPanel>
          </DialogContent>
        </Dialog>
      ) : isTrainingSeries || isTrainingDialogOpen ?  (
        <UserActionDialog
          open={open}
          onClose={onClose}
          onActionOne={() => {
            onOK("SINGLE")
          }}
          onActionTwo={() => {
            onOK("SINGLE_AND_FOLLOWING")
          }}
          title={<Translate id="training.delete" />}
          subTitle={<Translate id="training.delete-option" />}
          buttonOneLabel={<Translate id="training.delete-this" />}
          buttonTwoLabel={<Translate id="training.delete-this-following" />}
        />
      ) : (
        <AlertDialog
          open={isAlertBox}
          onClose={onClose}
          onSuccess={() => onOK("SINGLE")}
          onCancel={onClose}
          title={<Translate id="button.delete" />}
          subTitle={<Translate id="events.are-you-sure-you-want-to-delete" />}
          submitButtonLabel={<Translate id="button.ok" />}
          cancelButtonLabel={<Translate id="button.close" />}
        />
      )}
    </>
  )
}

EventPopup.defaultProps = {
  isAlertBox: false,
  calenderDateSelected: false,
  trainingAndGame: '',
  scheduleTrainings: () => 0,
  createTraining: () => 0,
  updateTraining: () => 0,
  createMatch: () => 0,
  updateMatch: () => 0,
  onOK: () => 0,
  duplicateData: null,
  calenderDate: null,
  isDuplicate: false,
}

EventPopup.propTypes = {
  isAlertBox: PropTypes.bool,
  calenderDateSelected: PropTypes.bool,
  duplicateData: PropTypes.shape(),
  match: PropTypes.shape().isRequired,
  calenderDate: PROP_TYPES.date,
  onOK: PropTypes.func,
  trainingAndGame: PropTypes.string,
  createTraining: PropTypes.func,
  scheduleTrainings: PropTypes.func,
  createMatch: PropTypes.func,
  updateMatch: PropTypes.func,
  updateTraining: PropTypes.func,
  isDuplicate: PropTypes.bool,
}
export default withRouter(EventPopup)
