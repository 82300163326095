import React from 'react'
import { Translate } from 'react-localize-redux'
import groupPlayersByPosition from 'utils/groupPlayersByPosition'
import PlayersTable from './PlayersTable'
import PlayersListCard from './PlayersListCard'
import PROP_TYPES from 'constants/propTypes'

const PlayersListsContainer = ({ players }) => {
  const { goalkeepers, defenders, midfielders, strikers } =
    groupPlayersByPosition(
      players.map((p) => {
        return {
          ...p.relationships.player,
          availability: p.availability,
          tag: p.tag,
          matchPlayerUuid: p.uuid,
          reason: p.reason,
        }
      })
    )

  return (
    <>
      {goalkeepers && (
        <PlayersListCard title={<Translate id="matches.goalkeeper" />}>
          <PlayersTable matchPlayers={goalkeepers} />
        </PlayersListCard>
      )}

      {defenders && (
        <PlayersListCard title={<Translate id="matches.defender" />}>
          <PlayersTable matchPlayers={defenders} />
        </PlayersListCard>
      )}

      {midfielders && (
        <PlayersListCard title={<Translate id="matches.midfield" />}>
          <PlayersTable matchPlayers={midfielders} />
        </PlayersListCard>
      )}
      {strikers && (
        <PlayersListCard title={<Translate id="matches.striker" />}>
          <PlayersTable matchPlayers={strikers} />
        </PlayersListCard>
      )}
    </>
  )
}

PlayersListsContainer.defaultProps = {
  players: [],
}

PlayersListsContainer.propTypes = {
  players: PROP_TYPES.arrayOfObjects,
}

export default PlayersListsContainer
