import React from 'react'
import TopBar from './components/TopBar'
import TransferHistoryTable from './components/TransferHistoryTable'

const PlayerTransferHistoryContainer = () => {
  return (
    <>
      <TopBar />
      <TransferHistoryTable />
    </>
  )
}

export default PlayerTransferHistoryContainer
