export const track = (event, data) => {
  if (!event || event === '') {
    throw new Error('Event cant be blank')
  }
  window.analytics.track(event, {
    ...data,
  })
}

export const page = () => {}

export const identify = (userId, data = {}) => {
  window.analytics.identify(userId, { ...data })
}
