import React, { useEffect, useState } from 'react'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import PropTypes from 'prop-types'

const PlayersAutocomplete = ({
  savedPlayer,
  label,
  setPValue,
  players,
  resetPlayer,
  setResetPlayer,
}) => {
  const [player, setPlayer] = useState()
  const defaultProps = {
    options: players,
    getOptionLabel: (option) => {
      if (option) {
        return option.title
      }

      return ''
    },
  }
  const onPlayerChange = (event, newValue) => {
    setPlayer(newValue)

    return newValue ? setPValue(newValue.value) : setPValue(null)
  }
  useEffect(() => {
    const temp = players.filter((e) => e.value === savedPlayer)
    setPlayer(temp[0])
    if (temp[0]) {
      setPValue(temp[0].value)
    } else {
      setPValue('')
      setResetPlayer(false)
    }
  }, [savedPlayer, resetPlayer])

  return (
    <Autocomplete
      {...defaultProps}
      id="player-autocomplete"
      onChange={(event, newValue) => onPlayerChange(event, newValue)}
      value={player || ''}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          margin="dense"
          variant="outlined"
        />
      )}
    />
  )
}
PlayersAutocomplete.defaultProps = {
  savedPlayer: 0,
  resetPlayer: false,
}
PlayersAutocomplete.propTypes = {
  setPValue: PropTypes.func.isRequired,
  label: PropTypes.shape().isRequired,
  savedPlayer: PropTypes.number,
  setResetPlayer: PropTypes.func.isRequired,
  resetPlayer: PropTypes.bool,
  players: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}
export default PlayersAutocomplete
