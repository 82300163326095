import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Chip, Box, Popover } from '@material-ui/core'
import PROP_TYPES from 'constants/propTypes'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

const useStyles = makeStyles((theme) => ({
  popoverPaper: {
    position: 'relative',
    borderRadius: 8,
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
    width: 400,
    maxHeight: 300,
    overflowY: 'auto',
  },
  chips: {
    display: 'flex',
    direction: 'rtl',
    '& .MuiChip-icon': {
      margin: theme.spacing(0),
    },
    '& .MuiChip-label': {
      marginLeft: theme.spacing(1),
      padding: theme.spacing(1),
    },
    '& .MuiSvgIcon-root': {
      height: theme.spacing(2),
      width: theme.spacing(2),
      marginRight: theme.spacing(1),
    },
  },
}))

const AdditionalTag = ({
  selectedTags,
  changeSelectedTags,
  additionalDropdownTags,
}) => {
  const [anchorEl0, setAnchorEl0] = useState(null)

  const isSelectedTag = anchorEl0

  const handleTagClick = (event) => {
    setAnchorEl0(event.currentTarget)
  }

  const handleTagClose = () => {
    setAnchorEl0(null)
  }
  const classes = useStyles()
  const openTag = Boolean(anchorEl0)
  const popoverIdTag = openTag ? 'popover0' : undefined

  return (
    <>
      <Chip
        label={<Translate id="videos.more" />}
        icon={<ArrowDropDownIcon />}
        component={Box}
        className={classes.chips}
        m={(0.3, 0.6)}
        p={2}
        color={isSelectedTag ? 'primary' : ''}
        onClick={handleTagClick}
      />
      <Popover
        id={popoverIdTag}
        open={openTag}
        anchorEl={anchorEl0}
        onClose={handleTagClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          className: classes.popoverPaper,
        }}
      >
        {additionalDropdownTags.map(
          ({ value, title: tagTitle, background, color }) => {
            const isSelected = selectedTags.includes(value)

            const onClick = () => {
              if (!isSelected) {
                return changeSelectedTags([...selectedTags, value])
              }

              return changeSelectedTags([
                ...selectedTags.filter((tag) => tag !== value),
              ])
            }

            return (
              <Chip
                label={tagTitle}
                key={value}
                component={Box}
                m={(0.3, 0.6)}
                p={2}
                color={isSelected ? 'primary' : ''}
                onClick={onClick}
                onDelete={isSelected ? onClick : ''}
              />
            )
          }
        )}
      </Popover>
    </>
  )
}

AdditionalTag.defaultProps = {
  selectedTags: [],
  additionalDropdownTags: [],
}

AdditionalTag.propTypes = {
  additionalDropdownTags: PropTypes.arrayOfObjects,
  selectedTags: PropTypes.arrayOfObjects,
  changeSelectedTags: PROP_TYPES.func.isRequired,
}

export default AdditionalTag
