import React from 'react'
import { Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import { SubLink } from '../containers'

const LinkBack = ({ to, title, isFullPath, className }) => (
  <Button
    variant="text"
    component={SubLink}
    to={to}
    isFullPath={isFullPath}
    className={className}
  >
    {title}
  </Button>
)

LinkBack.defaultProps = {
  isFullPath: false,
}

LinkBack.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isFullPath: PropTypes.bool,
  className: PropTypes.string.isRequired,
}

export default LinkBack
