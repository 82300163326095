import React, { useState } from 'react'
import {
  Typography,
  ListItem,
  List,
  ListItemAvatar,
  Divider,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import binIcon from 'assets/images/16px/bin@1x.svg'
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer'
import editIcon from 'assets/images/16px/edit@1x.svg'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import NoteIcon from '@material-ui/icons/Note'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Translate } from 'react-localize-redux'
import { matchHighlights } from 'redux/modules'
import { connect } from 'react-redux'
import YellowCardIcon from 'assets/icons/YellowCard'
import YellowRedCardIcon from 'assets/icons/YellowRedCard'
import RedCardIcon from 'assets/icons/RedCard'
import { track } from 'utils/segmentTracking'
import AlertDialog from '../../../AlertDialog'
import MatchHighlightsDialog from './MatchHighlightsDialog'

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      '& .MuiListItemSecondaryAction-root': {
        right: '0px',
      },
      '& .MuiListItem-gutters': {
        paddingLeft: '0',
      },
    },
    '& .MuiListItem-secondaryAction': {
      paddingRight: '65px',
    },
  },
  marginR1: {
    marginRight: theme.spacing(1),
  },
  minute: {
    fontWeight: 'bold',
    marginRight: '1rem',
    minWidth: '25px',
    maxWidth: '25px',
  },
}))
const MatchHighlightsList = ({
  highlights,
  participantsList,
  deleteHighlight,
  updateHighlight,
  fetchMatchHighlights,
  matchUuid,
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [deleteHighlightUuid, setDeleteHighlightUuid] = useState('')
  const [existingData, setExistingData] = useState([])
  const [openEdit, setEditOpen] = useState(false)

  const handleDeleteHighlight = (uuid) => {
    deleteHighlight(uuid).then(() => {
      fetchMatchHighlights(matchUuid)
    })
  }
  const handleUpdateHighlight = (uuid, data) => {
    updateHighlight(uuid, data).then(() => {
      fetchMatchHighlights(matchUuid)
    })
  }

  const handleDelete = () => {
    setOpen(false)
    handleDeleteHighlight(deleteHighlightUuid)
  }
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const openAlertBox = (uuid) => {
    setDeleteHighlightUuid(uuid)
    handleOpen()

    // Segment tracking
    track('WClick_MPHighlights_Delete', {
      category: 'MP_Highlights',
    })
  }
  const handleEditClose = () => {
    setEditOpen(false)
  }
  const handleEditClickOpen = (uuid) => {
    setExistingData(
      highlights.filter((highlight) => {
        return highlight.uuid === uuid
      })[0]
    )
    setEditOpen(true)

    // Segment tracking
    track('WClick_MPHighlights_Edit', {
      category: 'MP_Highlights',
    })
  }

  const getCardIcon = (highlight) => {
    if (highlight.card_type === 'yellow') {
      return <YellowCardIcon />
    }
    if (highlight.card_type === 'red') {
      return <RedCardIcon />
    }

    return <YellowRedCardIcon />
  }
  const getPlayerFullName = (player) => {
    return `${player.first_name} ${player.last_name}`
  }

  return (
    <Translate>
      {({ translate }) => (
        <>
          {openEdit && (
            <MatchHighlightsDialog
              onClose={handleEditClose}
              handleUpdate={handleUpdateHighlight}
              existingData={existingData}
              participantsList={participantsList}
              open={openEdit}
            />
          )}
          {open && (
            <AlertDialog
              open={open}
              onClose={handleClose}
              onSuccess={handleDelete}
              onCancel={handleClose}
              className={classes.root}
              title={<Translate id="button.delete" />}
              subTitle={
                <Translate id="events.are-you-sure-you-want-to-delete" />
              }
              submitButtonLabel={<Translate id="button.ok" />}
              cancelButtonLabel={<Translate id="button.close" />}
            />
          )}
          <List className={classes.root}>
            {highlights
              .sort(
                (a, b) => b.minute * 60 + b.second - (a.minute * 60 + a.second)
              )
              .map((highlight) => {
                return (
                  <>
                    <ListItem key={highlight.uuid}>
                      <Typography
                        component="span"
                        className={classes.minute}
                        color="secondary"
                      >
                        {`${highlight.minute}'`}
                      </Typography>
                      <ListItemAvatar className="text-secondary align-items-center">
                        {(() => {
                          switch (highlight.type) {
                            case 'substitution':
                              return <SwapHorizIcon color="secondary" />
                            case 'goal':
                              return <SportsSoccerIcon color="secondary" />
                            case 'card':
                              return getCardIcon(highlight)
                            default:
                              return <NoteIcon color="secondary" />
                          }
                        })()}
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <>
                            {(() => {
                              switch (highlight.type) {
                                case 'note':
                                  return highlight.note
                                case 'substitution':
                                  return (
                                    <Typography>
                                      {`${translate(
                                        'matches.highlights.in'
                                      )}: ${
                                        highlight.relationships.player_2 &&
                                        getPlayerFullName(
                                          highlight.relationships.player_2
                                        )
                                      }`}
                                    </Typography>
                                  )

                                case 'goal':
                                  return (
                                    <>
                                      <Typography
                                        component="span"
                                        className={classes.marginR1}
                                        color="secondary"
                                      >
                                        {`${highlight.score.own} - `}
                                        <span style={{ color: 'red' }}>
                                          {highlight.score.opponent}
                                        </span>
                                      </Typography>
                                      {highlight.relationships.player_1 &&
                                        getPlayerFullName(
                                          highlight.relationships.player_1
                                        )}
                                    </>
                                  )
                                default:
                                  return (
                                    highlight.relationships.player_1 &&
                                    getPlayerFullName(
                                      highlight.relationships.player_1
                                    )
                                  )
                              }
                            })()}
                          </>
                        }
                        secondary={
                          <>
                            {(() => {
                              switch (highlight.type) {
                                case 'substitution':
                                  return (
                                    <>
                                      {`${
                                        highlight.relationships.player_1
                                          ?.first_name
                                          ? `${translate(
                                              'matches.highlights.out'
                                            )}: ${getPlayerFullName(
                                              highlight.relationships.player_1
                                            )}`
                                          : ''
                                      }`}
                                      {highlight.reason &&
                                        ` - ${translate(
                                          'matches.highlights.reason'
                                        )}: ${translate(
                                          `matches.highlights.${highlight.reason}`
                                        )}`}
                                    </>
                                  )
                                case 'card':
                                  return (
                                    highlight.reason &&
                                    `${translate(
                                      'matches.highlights.reason'
                                    )}: ${translate(
                                      `matches.highlights.${highlight.reason}`
                                    )}`
                                  )
                                case 'goal':
                                  return highlight.goal_category &&
                                    highlight.goal_category === 'regular'
                                    ? highlight.relationships.player_2
                                      ? ` Assist: ${getPlayerFullName(
                                          highlight.relationships.player_2
                                        )}`
                                      : ''
                                    : `${translate(
                                        `matches.highlights.${highlight.goal_category}`
                                      )}${
                                        highlight.relationships.player_2
                                          ? ` - Assist: ${getPlayerFullName(
                                              highlight.relationships.player_2
                                            )}`
                                          : ''
                                      }`
                                default:
                                  return ''
                              }
                            })()}
                          </>
                        }
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="edit"
                          onClick={() => handleEditClickOpen(highlight.uuid)}
                        >
                          <Tooltip
                            title={translate('button.edit')}
                            placement="top"
                            arrow
                          >
                            <img src={editIcon} alt={editIcon} />
                          </Tooltip>
                        </IconButton>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => openAlertBox(highlight.uuid)}
                        >
                          <Tooltip
                            title={translate('button.delete')}
                            placement="top"
                            arrow
                          >
                            <img src={binIcon} alt={binIcon} />
                          </Tooltip>
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                  </>
                )
              })}
          </List>
        </>
      )}
    </Translate>
  )
}
MatchHighlightsList.propTypes = {
  highlights: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  updateHighlight: PropTypes.func.isRequired,
  deleteHighlight: PropTypes.func.isRequired,
  participantsList: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  fetchMatchHighlights: PropTypes.func.isRequired,
  matchUuid: PropTypes.string.isRequired,
}
export default connect(
  ({
    matches: {
      isLoading,
      current: { uuid },
    },
    matchHighlights: { items },
  }) => ({
    isLoading,
    highlights: items,
    matchUuid: uuid,
  }),
  {
    fetchMatchHighlights: matchHighlights.fetchMatchHighlights,
    deleteHighlight: matchHighlights.deleteMatchHighlight,
    updateHighlight: matchHighlights.updateMatchHighlight,
  }
)(MatchHighlightsList)
