import {
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  Paper,
} from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import grey from '@material-ui/core/colors/grey'
import { styled } from '@material-ui/core/styles'
import { Translate } from 'react-localize-redux'

const MenuPaper = styled(Paper)({
  border: `1px solid ${grey[300]}`,
  boxShadow: `1px 1px 12px 0 ${grey[400]}`,
})

const SelectList = ({
  input,
  label,
  meta,
  children,
  id,
  size,
  required,
  margin,
  ...custom
}) => {
  const inputError = meta.touched && meta.error

  return (
    <FormControl
      variant="outlined"
      size={size}
      error={inputError}
      required={required}
      margin={margin}
    >
      <InputLabel id="select-label">{label}</InputLabel>
      <Select
        labelId="select-label"
        id={id}
        label={label}
        MenuProps={{
          PaperProps: {
            component: MenuPaper,
          },
        }}
        {...input}
        {...custom}
      >
        {children}
      </Select>
      {inputError && (
        <FormHelperText>
          {inputError ? <Translate id={`joi.error.${meta.error}`} /> : ''}
        </FormHelperText>
      )}
    </FormControl>
  )
}

SelectList.defaultProps = {
  meta: {},
  label: {},
  size: 'small',
  variant: 'outlined',
  margin: 'dense',
  id: '',
  name: '',
  required: false,
}

SelectList.propTypes = {
  children: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape(),
  label: PropTypes.shape(),
  size: PropTypes.string,
  variant: PropTypes.string,
  margin: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
}

export default SelectList
