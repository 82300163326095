import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Paper, Box, Grid } from '@material-ui/core'
import { articles as articlesModule } from 'redux/modules'
import ArticleItem from './ArticleItem'
import PROP_TYPES from 'constants/propTypes'

const MyArticles = ({ activeLocale, favoritesArticles, fetchArticles,user }) => {
  const [articles, changeArticles] = useState([])
  useEffect(() => {
    fetchArticles({
      order_by: 'date',
      locale: activeLocale,
    }).then(({ response: { data = [] } }) => changeArticles(data))
  }, [activeLocale])

  const myArticlesList = [...articles]

  const getIsFavorite = id => Boolean(favoritesArticles.findById(id))

  return (
    <>
      <Paper component={Box} px={3} py={3} mt={2}>
        <Grid container spacing={3}>
          {myArticlesList
            .filter(e => e.user_id === user.id)
            .map(article => (
              <ArticleItem
                key={article.id}
                article={article}
                isFavorite={getIsFavorite(article.id)}
              />
            ))}
        </Grid>
      </Paper>
    </>
  )
}

MyArticles.defaultProps = {
  favoritesArticles: [],
}

MyArticles.propTypes = {
  activeLocale: PROP_TYPES.string.isRequired,
  favoritesArticles: PROP_TYPES.arrayOfObjects,
  fetchArticles: PROP_TYPES.func.isRequired,
  user: PROP_TYPES.shape().isRequired,
}

export default connect(
  ({ favorites,auth }) => ({ favoritesArticles: favorites.items.articles,user: auth.user, }),
  { fetchArticles: articlesModule.fetchArticles }
)(MyArticles)
