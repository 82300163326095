import React from 'react'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { makeStyles, createTheme } from '@material-ui/core/styles'
import { SubLink, HeartIcon, InnrHead, HasClubPermission } from 'containers'
import {
  Paper,
  Chip,
  Box,
  Divider,
  IconButton,
  Tooltip,
  Button,
  Grid,
  Typography,
} from '@material-ui/core'
import ShareIcon from '@material-ui/icons/Share'
import timeIcon from 'assets/images/time@1x.svg'
import KnowledgeBaseIcon from '@material-ui/icons/School'
import { ActionWithPrompt } from 'components'
import { articles as articlesModule } from 'redux/modules'
import { createSubUrl } from 'utils/urlConstructor'
import { Categories } from '../ArticlesCategoriesView/components'
import PropTypes from 'prop-types'
import { ARTICLE_EDIT_ROUTE, ARTICLES_DASHBOARD_ROUTE } from 'constants/routes'
import PERMISSIONS from 'constants/permissions'

const theme = createTheme({})
const useStyles = makeStyles({
  articleTag: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(1.1, 0),
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  paper: {
    margin: 'auto',
    maxWidth: 900,
    padding: theme.spacing(2, 0),
  },
  button: {
    margin: theme.spacing(0, 1),
  },
  timingBox: {
    marginLeft: 'auto',
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
  StyledImage: {
    background: '#ccc',
    width: '100%',
    borderRadius: '12px',
  },
  [theme.breakpoints.down('sm')]: {},
})

const ArticleContainer = ({
  history,
  location: { fromUrl },
  match: {
    params: { articleId },
  },
  match,
  article,
  article: {
    title,
    image_url: imageUrl,
    body,
    categories,
    reading_time: readingTime,
    user_id: userId,
    podcast,
    video,
    type,
  },
  favoritesArticles,
  articlesCategories,
  deleteArticle,
  teamUuid,
  language,
  timezone,
  user,
}) => {
  const linkBack = fromUrl || createSubUrl(match, ARTICLES_DASHBOARD_ROUTE)
  const handleDelete = () =>
    deleteArticle(+articleId).then(() => history.push(linkBack))

  const getIsFavorite = (id) => Boolean(favoritesArticles.findById(id))

  const handleShareClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const shareUrl = `${process.env.REACT_APP_API_URL}/articles/${article.uuid}/share?language=${language}&time_zone=${timezone}&team=${teamUuid}`
    window.open(shareUrl, '_blank')
  }
  const classes = useStyles()

  return (
    <>
      <InnrHead
        title={
          <IconButton component={SubLink} to={linkBack} isFullPath>
            <KnowledgeBaseIcon fontSize="small" />
          </IconButton>
        }
      >
        <HasClubPermission
          ability={PERMISSIONS.DELETE_ARTICLE}
          or={userId === user.id}
        >
          {/* @todo Replace this prompt with common delete alert dialog */}
          <ActionWithPrompt
            className={classes.button}
            onSuccess={handleDelete}
            message="Are you sure you want to delete the Article?"
            title={<Translate id="button.delete" />}
          />
        </HasClubPermission>
        <HasClubPermission
          ability={PERMISSIONS.UPDATE_ARTICLE}
          or={userId === user.id}
        >
          <Button
            variant="contained"
            color="primary"
            component={SubLink}
            to={ARTICLE_EDIT_ROUTE.populateURLParams({ articleId })}
          >
            <Translate id="button.edit" />
          </Button>
        </HasClubPermission>
      </InnrHead>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={7} lg={9} xl={9}>
          <Paper component={Box} p={3}>
            <Box className={classes.paper}>
              <Typography variant="h3">{title}</Typography>
              <Box className={classes.articleTag}>
                {articlesCategories &&
                  articlesCategories.length > 0 &&
                  categories.map((p) => {
                    const { title: catTitle } = articlesCategories.findById(p)

                    return <Chip label={catTitle} key={p} variant="outlined" />
                  })}
              </Box>

              <Box display="flex" my={2}>
                {readingTime && (
                  <Box
                    component="span"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.timingBox}
                  >
                    <img src={timeIcon} alt="" />
                    <Box component="span" ml={0.5} color="text.secondary">
                      {`${readingTime}`} <Translate id="articles.min-read" />
                    </Box>
                  </Box>
                )}
              </Box>
              <Box display="flex" my={1}>
                <HeartIcon
                  isActive={getIsFavorite(+articleId)}
                  entity="articles"
                  entityId={+articleId}
                  isPositionRelative
                />
                <Tooltip
                  title={<Translate id="button.share" />}
                  placement="top-start"
                  arrow
                >
                  <IconButton onClick={handleShareClick}>
                    <ShareIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
              {type === 'article' && (
                <img src={imageUrl} alt="" className={classes.StyledImage} />
              )}
              {podcast && (
                <Box component="div" my={3}>
                  <audio controls style={{ width: '100%' }}>
                    <source src={podcast.full_url} type="audio/mpeg" />
                    <track kind="captions" />
                    Your browser does not support the audio element.
                  </audio>
                </Box>
              )}
              {video && (
                <Box component="div" my={3}>
                  <video controls style={{ width: '100%' }} preload="metadata">
                    <source src={video.full_url} type="video/mp4" />
                    <track kind="captions" />
                    Your browser does not support the video tag.
                  </video>
                </Box>
              )}
              {body && (
                <Box
                  component="div"
                  dangerouslySetInnerHTML={{ __html: body }}
                />
              )}

              <Box display="flex" my={1}>
                <HeartIcon
                  isActive={getIsFavorite(+articleId)}
                  entity="articles"
                  entityId={+articleId}
                  isPositionRelative
                />
                <Tooltip
                  title={<Translate id="button.share" />}
                  placement="top-start"
                  arrow
                >
                  <IconButton onClick={handleShareClick}>
                    <ShareIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>

              <Divider className={classes.divider} />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={3} xl={3}>
          <Paper component={Box} className={classes.paper}>
            <Categories selectedCategory={categories} />
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

ArticleContainer.defaultProps = {
  favoritesArticles: [],
  articlesCategories: [],
}

ArticleContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      articleId: PropTypes.number.isRequired,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  teamUuid: PropTypes.string.isRequired,
  article: PropTypes.shape().isRequired,
  favoritesArticles: PropTypes.shape().isRequired,
  articlesCategories: PropTypes.shape(),
  shareArticle: PropTypes.func.isRequired,
  deleteArticle: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  user: PropTypes.shape().isRequired,
}

export default connect(
  ({ team, articles, favorites, auth }) => ({
    article: articles.current,
    articlesCategories: articles.current.articlesCategories,
    favoritesArticles: favorites.items.articles,
    user: auth.user,
    teamUuid: team.uuid,
    timezone: auth.user.time_zone,
    language: auth.user.language,
  }),
  {
    deleteArticle: articlesModule.deleteArticle,
  }
)(ArticleContainer)
