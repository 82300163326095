import React from 'react'
import withDndContext from 'withDndContext'
import { Grid, Paper } from '@material-ui/core'
// import { TopBar } from './components'
import GeneralMatchInfoContainer from './components/GeneralMatchInfo'
import TabViewContainer from './components/TabViewContainer'
import useStyles from './MatchMobileLayoutStyled'

const MatchDashboardLayout = () => {
  const classes = useStyles()

  return (
    <Grid container spacing={3} className={classes.root}>
      {/* @todo hidden because Not clear if users using it or not */} 
      {/* <Grid item xs={12}>
        <TopBar />
      </Grid> */}

      <Grid item xs={12} className={classes.MobileContainer}>
        <Paper elevation={3}>
          <Grid container>
            <Grid item xs={12}>
              <GeneralMatchInfoContainer />
            </Grid>
            <Grid item xs={12}>
              <TabViewContainer />
            </Grid>
          </Grid>
        </Paper>
      </Grid>

    </Grid>
  )
}

export default withDndContext(MatchDashboardLayout)
