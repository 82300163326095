// import { Translate } from 'react-localize-redux'

export const plans = [
  {
    value: 'basic',
    label: 'Essential',
    monthly: 0,
    annual: 0,
    benefits: [
      'Personal Coaching Dashboard',
      'Season & Session Planner (with more than 250 activities pre-loaded)',
      'Team Management & Player Performance Statistics',
      'Knowledge Pool to keep learning',
    ],
    benefitsDesc: 'Improve your coaching game',
  },
  {
    value: 'plus',
    label: 'Plus',
    monthly: 19.90,
    annual: 199.00,
    benefits: [
      'Personal Coaching Dashboard',
      'Season & Session Planner (with more than 250 activities pre-loaded)',
      'Team Management & Player Performance Statistics',
      'Knowledge Pool to keep learning',
      'Game Day Preparation & evaluation',
    ],
    benefitsDesc: 'Enhance player performance',
  },
  {
    value: 'elite',
    label: 'Pro',
    monthly: 29.90,
    annual: 299.00,
    benefits: [
      'Personal Coaching Dashboard',
      'Season & Session Planner (with more than 250 activities pre-loaded)',
      'Team Management & Player Performance Statistics',
      'Knowledge Pool to keep learning',
      'Game Day Preparation & evaluation',
      'Video Analysis for match & training sessions',
    ],
    benefitsDesc: 'Train like a pro',
  },
]

export const billingCycle = [
  {value: 'monthly', label: 'Monthly',period:'month'},
  {value: 'annual', label: 'Annual',period:'year'},
]

export const seats = [
  {
    plan: 'basic',
    seat: 2,
  },
  {
    plan: 'plus',
    seat: 5,
  },
  {
    plan: 'elite',
    seat: 1000,
  },
  {
    plan: 'champion',
    seat: 1000,
  },
]
// @todo Replace ELITE with PRO when backend send 'pro' instead of 'elite'
export const PRO = "ELITE"
export const PLUS = "PLUS"
export const BASIC = "BASIC"
