import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { generalEvents } from 'redux/modules'
import Loader from './Loader'

const EventLoader = (props) => {
  const { children, eventUuid, fetchEvent, ...restProps } = props

  const fetchMethod = (params) => fetchEvent(eventUuid, params)
  const triggers = [eventUuid]

  return (
    <Loader fetchMethod={fetchMethod} triggers={triggers} {...restProps}>
      {children}
    </Loader>
  )
}

EventLoader.propTypes = {
  children: PropTypes.node.isRequired,
  fetchEvent: PropTypes.func.isRequired,
  eventUuid: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
}

export default connect(null, { fetchEvent: generalEvents.fetchEvent })(
  EventLoader
)
