import thunk from 'redux-thunk'
import { routerMiddleware } from 'connected-react-router'
import { history } from '../router'
import callAPIMiddleware from './callAPIMiddleware'
import firstRenderingMiddleware from './firstRenderingMiddleware'

export default [
  thunk,
  routerMiddleware(history),
  callAPIMiddleware,
  firstRenderingMiddleware,
]
