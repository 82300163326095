import { combineActions, handleActions } from 'redux-actions'
import {
  actionsTypesGenerator,
  onRequest,
  onSuccess,
  onFailure,
} from 'utils/reduxHelpers'
import initialState from '../initialState'

const namespace = 'PLAYER'

const FETCH_TEAM_PLAYERS = actionsTypesGenerator(`FETCH_TEAM_${namespace}S`)
const FETCH_TEAM_PLAYER = actionsTypesGenerator(`FETCH_TEAM_${namespace}`)
const CREATE_PLAYER = actionsTypesGenerator(`CREATE_${namespace}`)
const CREATE_PLAYER_QUICK = actionsTypesGenerator(`CREATE_${namespace}`)
const UPDATE_PLAYER_QUICK = actionsTypesGenerator(`UPDATE_${namespace}`)
const UPDATE_PLAYER = actionsTypesGenerator(`UPDATE_${namespace}`)
const SHARE_PLAYER = actionsTypesGenerator(`SHARE_${namespace}`)
const DELETE_PLAYER = actionsTypesGenerator(`DELETE_${namespace}`)
const CREATE_PLAYER_PHYSIQUE = actionsTypesGenerator(
  `CREATE_${namespace}_PHYSIQUE`
)
const UPDATE_PLAYER_PHYSIQUE = actionsTypesGenerator(
  `UPDATE_${namespace}_PHYSIQUE`
)
const DELETE_PLAYER_PHYSIQUE = actionsTypesGenerator(
  `DELETE_${namespace}_PHYSIQUE`
)
const CREATE_PLAYER_INJURY = actionsTypesGenerator(`CREATE_${namespace}_INJURY`)
const UPDATE_PLAYER_INJURY = actionsTypesGenerator(`UPDATE_${namespace}_INJURY`)
const DELETE_PLAYER_INJURY = actionsTypesGenerator(`DELETE_${namespace}_INJURY`)
const CREATE_PLAYER_NOTE = actionsTypesGenerator(`CREATE_${namespace}_NOTE`)
const UPDATE_PLAYER_NOTE = actionsTypesGenerator(`UPDATE_${namespace}_NOTE`)
const DELETE_PLAYER_NOTE = actionsTypesGenerator(`DELETE_${namespace}_NOTE`)
const CREATE_PLAYER_ANALYSIS = actionsTypesGenerator(
  `CREATE_${namespace}_ANALYSIS`
)
const UPDATE_PLAYER_ANALYSIS = actionsTypesGenerator(
  `CREATE_${namespace}_ANALYSIS`
)
const DELETE_PLAYER_ANALYSIS = actionsTypesGenerator(
  `DELETE_${namespace}_ANALYSIS`
)
const UPDATE_PLAYER_AVATAR = actionsTypesGenerator(`UPDATE_${namespace}_AVATAR`)
const INVITE_USER_FOR_PLAYER = actionsTypesGenerator(`INVITE_${namespace}`)
const UPDATE_PLAYER_SKILL = actionsTypesGenerator(`UPDATE_${namespace}_SKILL`)
const DEACTIVATE_PLAYER = actionsTypesGenerator('DEACTIVATE_PLAYER')
const FETCH_PLAYER_TRANSFER_DESTINATIONS = actionsTypesGenerator(
  'FETCH_PLAYER_TRANSFER_DESTINATIONS'
)
const TRANSFER_PLAYER = actionsTypesGenerator('TRANSFER_PLAYER')
const UPDATE_PLAYER_PAUSE_STATUS = 'UPDATE_PLAYER_PAUSE_STATUS'

export const updatePlayerPauseStatus = (playerUuid, pauseData) => {
  return {
    type: 'UPDATE_PLAYER_PAUSE_STATUS',
    payload: {
      playerUuid,
      pauseData,
    },
  }
}

export const fetchTeamPlayers = (teamId, params) => ({
  types: FETCH_TEAM_PLAYERS,
  callAPI: {
    method: 'GET',
    entity: 'Players',
    path: `/teams/${teamId}/players`,
    params,
  },
})

export const fetchTeamPlayer = (playerId, params) => ({
  types: FETCH_TEAM_PLAYER,
  callAPI: {
    method: 'GET',
    entity: 'Player',
    path: `/players/${playerId}`,
    params,
  },
})

export const createPlayer = (data) => ({
  types: CREATE_PLAYER,
  callAPI: {
    method: 'POST',
    entity: 'Player',
    path: '/players',
    data,
  },
})

export const createPlayerQuick = (data) => ({
  types: CREATE_PLAYER_QUICK,
  callAPI: {
    method: 'POST',
    entity: 'Player',
    path: '/players/quick',
    data,
  },
})

export const updatePlayer = (id, data) => ({
  types: UPDATE_PLAYER,
  callAPI: {
    method: 'PUT',
    entity: 'Player',
    path: `/players/${id}`,
    data,
  },
})

export const updatePlayerQuick = (id, data) => ({
  types: UPDATE_PLAYER,
  callAPI: {
    method: 'PUT',
    entity: 'Player',
    path: `/players/quick/${id}`,
    data,
  },
})

export const updatePlayerAvatar = (id, data) => ({
  types: UPDATE_PLAYER_AVATAR,
  callAPI: {
    method: 'PUT',
    entity: 'Player',
    path: `/players/update_avatar/${id}`,
    data,
  },
})

export const updatePlayerAvailability = (
  eventId,
  type,
  playerId,
  availability,
  data = {}
) => ({
  types: UPDATE_PLAYER,
  callAPI: {
    method: 'PUT',
    entity: 'Player',
    path: `/players/change/availability/${eventId}/${type}/${playerId}/${availability}`,
    data,
  },
})

export const sharePlayer = (id, paramsString, data = {}) => ({
  types: SHARE_PLAYER,
  callAPI: {
    method: 'SHARE',
    entity: 'Player',
    path: `/players/${id}/share`,
    paramsString,
    data,
  },
})

export const deletePlayer = (id) => ({
  types: DELETE_PLAYER,
  callAPI: {
    method: 'DELETE',
    entity: 'Player',
    path: `/players/${id}`,
  },
  payload: { id },
})

export const createPlayerPhysique = (id, data) => ({
  types: CREATE_PLAYER_PHYSIQUE,
  callAPI: {
    method: 'POST',
    entity: 'Player',
    path: `/players/add_physical_record/${id}`,
    data,
  },
})

export const updatePlayerPhysique = (id, data) => ({
  types: UPDATE_PLAYER_PHYSIQUE,
  callAPI: {
    method: 'PUT',
    entity: 'Player',
    path: `/players/update_physical_record/${id}`,
    data,
  },
  payload: { id },
})

export const deletePlayerPhysique = (id) => ({
  types: DELETE_PLAYER_PHYSIQUE,
  callAPI: {
    method: 'DELETE',
    entity: 'Player',
    path: `/players/delete_physical_record/${id}`,
  },
  payload: { id },
})

export const createPlayerInjury = (id, data) => ({
  types: CREATE_PLAYER_INJURY,
  callAPI: {
    method: 'POST',
    entity: 'Player',
    path: `/players/add_injury_record/${id}`,
    data,
  },
})

export const updatePlayerInjury = (id, data) => ({
  types: UPDATE_PLAYER_INJURY,
  callAPI: {
    method: 'PUT',
    entity: 'Player',
    path: `/players/update_injury_record/${id}`,
    data,
  },
  payload: { id },
})

export const deletePlayerInjury = (id) => ({
  types: DELETE_PLAYER_INJURY,
  callAPI: {
    method: 'DELETE',
    entity: 'Player',
    path: `/players/delete_injury_record/${id}`,
  },
  payload: { id },
})

export const createPlayerNote = (id, data) => ({
  types: CREATE_PLAYER_NOTE,
  callAPI: {
    method: 'POST',
    entity: 'Player',
    path: `/players/add_note_record/${id}`,
    data,
  },
})

export const updatePlayerNote = (id, data) => ({
  types: UPDATE_PLAYER_NOTE,
  callAPI: {
    method: 'PUT',
    entity: 'Player',
    path: `/players/update_note_record/${id}`,
    data,
  },
  payload: { id },
})

export const deletePlayerNote = (id) => ({
  types: DELETE_PLAYER_NOTE,
  callAPI: {
    method: 'DELETE',
    entity: 'Player',
    path: `/players/delete_note_record/${id}`,
  },
  payload: { id },
})

export const createPlayerAnalysis = (id, data) => ({
  types: CREATE_PLAYER_ANALYSIS,
  callAPI: {
    method: 'POST',
    entity: 'Player Analysis',
    path: `/players/add_analysis_record/${id}`,
    data,
  },
})

export const updatePlayerAnalysis = (id, data) => ({
  types: UPDATE_PLAYER_ANALYSIS,
  callAPI: {
    method: 'PUT',
    entity: 'Player Analysis',
    path: `/players/update_analysis_record/${id}`,
    data,
  },
  payload: { id },
})

export const deletePlayerAnalysis = (id) => ({
  types: DELETE_PLAYER_ANALYSIS,
  callAPI: {
    method: 'DELETE',
    entity: 'Player Analysis',
    path: `/players/delete_analysis_record/${id}`,
  },
  payload: { id },
})
export const sendInvitations = (teamId, data) => ({
  types: INVITE_USER_FOR_PLAYER,
  callAPI: {
    method: 'POST',
    entity: 'User invites',
    path: `/team/${teamId}/users/invite`,
    data,
  },
})

export const updateSkillValue = (uuid, value) => ({
  types: UPDATE_PLAYER_SKILL,
  callAPI: {
    method: 'PATCH',
    entity: 'Player skill',
    path: `/v2/player-skills/${uuid}`,
    data: {
      value,
    },
  },
})

export const deactivatePlayer = (playerUuid) => ({
  types: DEACTIVATE_PLAYER,
  callAPI: {
    method: 'POST',
    entity: 'Player deactivate',
    path: `/v2/players/${playerUuid}/deactivate`,
  },
})

export const fetchPlayerTransferDestinations = (playerUuid) => ({
  types: FETCH_PLAYER_TRANSFER_DESTINATIONS,
  callAPI: {
    method: 'GET',
    path: `/v2/players/${playerUuid}/transfer-destinations`,
    data: {
      preventToastNotification: true,
    },
  },
})

export const transferPlayer = (playerUuid, data) => ({
  types: TRANSFER_PLAYER,
  callAPI: {
    method: 'POST',
    entity: 'Player transfer',
    path: `/v2/players/${playerUuid}/transfer`,
    data,
  },
})

export default handleActions(
  {
    [combineActions(
      FETCH_TEAM_PLAYERS.request,
      FETCH_TEAM_PLAYER.request,
      CREATE_PLAYER.request,
      UPDATE_PLAYER.request,
      DELETE_PLAYER.request,
      CREATE_PLAYER_PHYSIQUE.request,
      UPDATE_PLAYER_PHYSIQUE.request,
      DELETE_PLAYER_PHYSIQUE.request,
      CREATE_PLAYER_INJURY.request,
      UPDATE_PLAYER_INJURY.request,
      DELETE_PLAYER_INJURY.request,
      CREATE_PLAYER_NOTE.request,
      UPDATE_PLAYER_NOTE.request,
      DELETE_PLAYER_NOTE.request,
      CREATE_PLAYER_ANALYSIS.request,
      DELETE_PLAYER_ANALYSIS.request,
      DELETE_PLAYER_ANALYSIS.request,
      UPDATE_PLAYER_AVATAR.request,
      CREATE_PLAYER_QUICK.request,
      INVITE_USER_FOR_PLAYER.request,
      UPDATE_PLAYER_SKILL.request,
      DEACTIVATE_PLAYER.request,
      FETCH_PLAYER_TRANSFER_DESTINATIONS.request,
      TRANSFER_PLAYER.request
    )]: onRequest,
    [FETCH_TEAM_PLAYERS.success]: (state, { response }) => {
      const { data = [] } = response

      return onSuccess({
        ...state,
        items: data,
      })
    },
    [FETCH_TEAM_PLAYER.success]: (state, { response }) => {
      const { data = {} } = response

      return onSuccess({
        ...state,
        current: data,
      })
    },
    [combineActions(
      CREATE_PLAYER_QUICK.success,
      CREATE_PLAYER.success,
      CREATE_PLAYER_NOTE.success,
      CREATE_PLAYER_INJURY.success,
      CREATE_PLAYER_PHYSIQUE.success,
      CREATE_PLAYER_ANALYSIS.success,
      UPDATE_PLAYER_NOTE.success,
      UPDATE_PLAYER_PHYSIQUE.success,
      UPDATE_PLAYER_INJURY.success
    )]: (state, { response }) => {
      const { data = {} } = response

      return onSuccess({
        ...state,
        items: [data, ...state.items],
      })
    },
    [UPDATE_PLAYER_SKILL.success]: (state, { response: { data } }) => {
      return onSuccess({
        ...state,
        current: {
          ...state.current,
          skills: state.current.skills.map((skill) =>
            skill.uuid === data.uuid ? { ...skill, value: data.value } : skill
          ),
        },
      })
    },
    [combineActions(
      UPDATE_PLAYER.success,
      UPDATE_PLAYER_QUICK.success,
      UPDATE_PLAYER_AVATAR.success,
      INVITE_USER_FOR_PLAYER.success
    )]: (state, { response }) => {
      const { data = {} } = response
      const { id } = data
      const items = state.items.updateById(id, { ...data, isNew: false })

      return onSuccess({
        ...state,
        items,
        current: { ...state.current, ...data },
      })
    },
    [combineActions(
      DELETE_PLAYER.success,
      DELETE_PLAYER_PHYSIQUE.success,
      DELETE_PLAYER_INJURY.success,
      DELETE_PLAYER_NOTE.success,
      UPDATE_PLAYER_ANALYSIS.success,
      DELETE_PLAYER_ANALYSIS.success
    )]: (state, { id }) =>
      onSuccess({ ...state, items: state.items.filterById(id) }),
    [DEACTIVATE_PLAYER.success]: (state, { response: { data } }) => {
      const { uuid } = data
      const items = state.items.updateByUuid(uuid, { ...data })

      return onSuccess({
        ...state,
        items,
      })
    },
    [FETCH_PLAYER_TRANSFER_DESTINATIONS.success]: (
      state,
      { response: { data } }
    ) => {
      return onSuccess({
        ...state,
      })
    },
    [TRANSFER_PLAYER.success]: (state, { response: { data } }) => {
      const { uuid } = data
      const items = state.items.updateByUuid(uuid, { ...data })

      return onSuccess({
        ...state,
        items,
      })
    },
    [UPDATE_PLAYER_PAUSE_STATUS]: (state, { payload }) => {
      return {
        ...state,
        items: state.items.updateByUuid(payload.playerUuid, payload.pauseData),
      }
    },
    [combineActions(
      FETCH_TEAM_PLAYERS.failure,
      FETCH_TEAM_PLAYER.failure,
      CREATE_PLAYER.failure,
      CREATE_PLAYER_QUICK.failure,
      UPDATE_PLAYER.failure,
      UPDATE_PLAYER_QUICK.failure,
      UPDATE_PLAYER_AVATAR.failure,
      DELETE_PLAYER.failure,
      CREATE_PLAYER_PHYSIQUE.failure,
      UPDATE_PLAYER_PHYSIQUE.failure,
      DELETE_PLAYER_PHYSIQUE.failure,
      CREATE_PLAYER_INJURY.failure,
      UPDATE_PLAYER_INJURY.failure,
      DELETE_PLAYER_INJURY.failure,
      CREATE_PLAYER_NOTE.failure,
      UPDATE_PLAYER_NOTE.failure,
      DELETE_PLAYER_NOTE.failure,
      CREATE_PLAYER_ANALYSIS.failure,
      UPDATE_PLAYER_ANALYSIS.failure,
      DELETE_PLAYER_ANALYSIS.failure,
      INVITE_USER_FOR_PLAYER.failure,
      UPDATE_PLAYER_SKILL.failure,
      DEACTIVATE_PLAYER.failure,
      FETCH_PLAYER_TRANSFER_DESTINATIONS.failure,
      TRANSFER_PLAYER.failure
    )]: onFailure,
  },
  initialState.players
)
