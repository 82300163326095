import React, { useState, useEffect } from 'react'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import CloseIcon from '@material-ui/icons/Close'
import FilterNoneOutlinedIcon from '@material-ui/icons/FilterNoneOutlined'
import {
  Typography,
  IconButton,
  Dialog,
  Button,
  Box,
  DialogContent,
  Tabs,
  Tab,
  AppBar,
  Grid,
  DialogActions,
} from '@material-ui/core'
import { InnrHead } from 'containers'
import TabPanel from 'components/material/TabPanel'
import { Snackbars as Snackbar } from 'components'
import CircularSpinner from 'components/loader/CircularSpinner'
import { track } from 'utils/segmentTracking'
import PERMISSIONS from '../../../constants/permissions'
import TeamPlayersGridDialog from './TeamPlayersGrid/AddPlayerManually'
import PlayersImportDialog from './TeamPlayersGrid/PlayersBulkUpload'
import PROP_TYPES from 'constants/propTypes'
import { team as teamEvent } from 'constants/trackeventsname'
import { makeStyles } from '@material-ui/core/styles';
import { fetchInviteUrl } from "../../../redux/modules/teams"

const useStyles = makeStyles((theme) => ({
  tabPanel: {
    paddingTop: '.5rem' 
  },
  invitationLink:{
    marginBottom: '.5rem',
  },
  closeButton:{
    paddingRight: '3rem',
    paddingLeft: '3rem',
    marginTop: '1.5rem'
  },
  teamNamePosition:{
    marginLeft: '.25rem'
  },
  filterIcon:{
    marginLeft: '.5rem'
  }
}))

const AddPlayersDialog = ({
  open,
  onClose,
  team,
  user,
  handleSave,
  tabDisabled,
  loadingcircular,
  teamPermissions,
  fetchInviteUrl,
}) => {
  const canImportCsv = teamPermissions.includes(PERMISSIONS.IMPORT_TEAM_PLAYERS_CSV)

  const [deepLink, setDeepLink] = useState('Loading...')
  const [value, setValue] = useState(0)
  const [snackbar, setSnackbar] = useState(false)
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    }
  }

  useEffect(() => {
    if (open) {
      fetchInviteUrl(team.uuid).then(({ response: { data } }) => {
        setDeepLink(data.url)
      })
    }
  }, [open])

  return (
    <>
    
      {open && (
        <Dialog open={open} onClose={onClose} fullWidth>
          {loadingcircular && (
            <CircularSpinner loadingcircular={loadingcircular} />
          )}
          <InnrHead
            title={<Translate id="team-invite.add-players" />}
            style={{ marginBottom: 0 }}
          >
            {' '}
            <IconButton onClick={onClose} size="small">
              <CloseIcon />
            </IconButton>
          </InnrHead>
          <DialogContent>
            <AppBar position="static" color="transparent">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab
                  label={<Translate id="team-invite.team-invite-tab" />}
                  {...a11yProps(0)}
                />
                {!tabDisabled ? (<Tab
                  label={<Translate id="team-invite.manual-entry-tab" />}
                  {...a11yProps(1)}

                />) : ''}

                <Tab
                  label={<Translate id="team-invite.bulk-upload-tab" />}
                  {...a11yProps(2)}
                  disabled={!canImportCsv}
                  // Not needed for now
                  style={{display: 'none'}}
                />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0} className={classes.tabPanel}>
              <Grid spacing={2} container alignItems="center">
                {/* @todo display QR code section after QR integration */}
                <Grid xs={12} md={3} style={{display: 'none'}}>
                  QR CODE
                </Grid>
                <Grid xs={12}>
                  <Typography variant="h2" component="h2" className={classes.invitationLink}>
                    <Translate id="team-invite.send-link-title" />
                  </Typography>
                  <Typography variant="h6" component="h6" paragraph>
                    <Translate id="team-invite.send-link-subtitle" />
                    <Typography
                      variant="strong"
                      component="strong"
                      className={classes.teamNamePosition}
                    >
                      &quot;{team.name}&quot;
                    </Typography>
                  </Typography>
                  <a
                    onClick={(e) => {
                      e.preventDefault()
                      navigator.clipboard.writeText(deepLink)
                      setSnackbar({
                        message: <Translate id="team-invite.link-copied" />,
                        success: true,
                      })
                      track(teamEvent.team_players_invite_link_copy, {
                        team_id: +team.id,
                        category: teamEvent.team,
                      })
                    }}
                  >
                    <Box
                      width="100%"
                      mb={1}
                      display="flex"
                      alignItems="center"
                      textAlign="left"
                    >
                      <a href={deepLink}>
                        {deepLink}
                        <FilterNoneOutlinedIcon
                          fontSize="inherit"
                          className="ml-2"
                          className={classes.filterIcon}
                        />
                      </a>
                    </Box>
                  </a>
                  {/* @todo display after QR integration */}
                  <Typography variant="h6" component="h6" paragraph style={{display: 'none'}}>
                    <Translate id="team-invite.players-can-scan" />
                  </Typography>
                </Grid>
              </Grid>
              <DialogActions>
                {/* @todo uncomment after QR integration done and saving feature is ready */}
                {/* <Button onClick={onClose} color="primary" className="px-5">
                  <Translate id="team-invite.save-qr" />
                </Button> */}
                <Button
                  onClick={onClose}
                  color="primary"
                  variant="contained"
                  className={classes.closeButton}
                >
                  <Translate id="button.close" />
                </Button>
              </DialogActions>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <TeamPlayersGridDialog
                handleSave={handleSave}
                onClose={onClose}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <PlayersImportDialog
                teamId={team.id}
                user={user}
                onClose={onClose}
              />
            </TabPanel>
          </DialogContent>
        </Dialog>
      )}
      {snackbar && <Snackbar {...snackbar} />}
    </>
  )
}

AddPlayersDialog.propTypes = {
  open: PROP_TYPES.bool.isRequired,
  tabDisabled: PROP_TYPES.bool.isRequired,
  title: PROP_TYPES.string.isRequired,
  user: PROP_TYPES.shape.isRequired,
  team: PROP_TYPES.shape.isRequired,
  onClose: PROP_TYPES.func.isRequired,
  deepLink: PROP_TYPES.string.isRequired,
  handleSave: PROP_TYPES.func.isRequired,
  loadingcircular: PROP_TYPES.bool.isRequired,
  teamPermissions: PROP_TYPES.arrayOf(PROP_TYPES.string).isRequired,
  fetchInviteUrl: PROP_TYPES.func.isRequired,
}

export default compose(
  withRouter,
  connect(({ team, auth }) => ({
    team,
    user: auth.user,
    teamPermissions: auth.permissions.teams.find(item => item.id === team.id)?.permissions ?? []
  }), {
    fetchInviteUrl,
  })
)(AddPlayersDialog)
