import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  textarea: {
    '& .MuiInputBase-root': {
      height: 'unset',
      paddingTop: 'unset',
      paddingBottom: 'unset',
    },
  },
  contentPadding: {
    padding: '5px 24px',
  },
  marginRight: {
    marginRight: '8px',
  },
  marginLeft: {
    marginLeft: '8px',
  },
  sectionPadding: {
    padding: '16px 24px',
  },
  popoverPaper: {
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
    borderRadius: '8px',
    maxWidth: '350px',
    width: '250px',
  },
  root: {
    width: 40,
    height: 20,
    padding: 0,
  },
  switchBase: {
    padding: 1,
    background: theme.palette.common.white,
    width: 18,
    height: 18,
    left: 1,
    top: 1,
    '&$checked': {
      transform: 'translateX(20px)',
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 'none',
      },
    },
    '&:hover': {
      background: theme.palette.common.white,
    },
    '&$checked:hover': {
      background: theme.palette.common.white,
    },
    '&.MuiIconButton-root.Mui-disabled': {
      background: theme.palette.common.white,
    },
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  pauseIcon: {
    fontSize: '13px',
    color: '#000',
  },
  track: {
    borderRadius: 14,
    backgroundColor: theme.palette.grey[400],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
}))

export default useStyles
