import React from 'react'
import { Grid, Paper } from '@material-ui/core'
import EventInfoContainer from './components/EventInfo'
import TabViewContainer from './components/TabViewContainer'
import { useLayoutStyles } from './styles'

const GeneralEventContainer = () => {
  const classes = useLayoutStyles()
  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid item xs={12} md={12} lg={12} className={classes.MobileContainer}>
        <Paper elevation={3}>
          <Grid container>
            <Grid item xs={12}>
              <EventInfoContainer />
            </Grid>
            <Grid item xs={12}>
              <TabViewContainer />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default GeneralEventContainer
