import { handleActions, combineActions } from 'redux-actions'
import {
  actionsTypesGenerator,
  onRequest,
  onSuccess,
  onFailure,
} from 'utils/reduxHelpers'
import initialState from '../initialState'

const FETCH_DRILLS_CATEGORIES = actionsTypesGenerator(
  `FETCH_DRILLS_CATEGORIES`
)

export const fetchDrillsCategories = (club) => ({
  types: FETCH_DRILLS_CATEGORIES,
  callAPI: {
    method: 'GET',
    entity: 'Drills Categories',
    path: `/clubs/${club}/drill-categories`,
  },
})

export default handleActions(
  {
    [combineActions(FETCH_DRILLS_CATEGORIES.request)]: onRequest,
    [FETCH_DRILLS_CATEGORIES.success]: (
      state,
      { response: { data = [] } }
    ) => {
      return onSuccess({
        ...state,
        items: data,
      })
    },
    [combineActions(FETCH_DRILLS_CATEGORIES.failure)]: onFailure,
  },
  initialState.drillsCategories
)
