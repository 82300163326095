import { InputAdornment } from '@material-ui/core'
import InputNumber from 'components/inputes/InputNumber'
import React, { useState } from 'react'
import { Translate } from 'react-localize-redux'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import PROP_TYPES from 'constants/propTypes'

const InputPlayingMinutes = ({
  initialValue,
  onChangePlayerTime,
  matchPlayerUuid,
}) => {
  const [playingMinutes, setPlayingMinutes] = useState(initialValue)

  const handleChange = (e) => {
    // Update the local state with the new value
    setPlayingMinutes(e.target.value)
  }

  const handleBlur = (e) => {
    // Call the onChangePlayerTime function when the input loses focus
    onChangePlayerTime(e, matchPlayerUuid)
  }

  return (
    <InputNumber
      minLimit={0}
      label={<Translate id="matches.minutes-played" />}
      value={playingMinutes}
      onChange={handleChange}
      onBlur={handleBlur}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <AccessTimeIcon fontSize="small" />
          </InputAdornment>
        ),
      }}
    />
  )
}
InputPlayingMinutes.propTypes = {
  initialValue: PROP_TYPES.number.isRequired,
  matchPlayerUuid: PROP_TYPES.string.isRequired,
  onChangePlayerTime: PROP_TYPES.func.isRequired,
}
export default InputPlayingMinutes
