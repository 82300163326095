import React, { useState } from 'react'
import { Translate } from 'react-localize-redux'
import { Button, Grid, MenuItem } from '@material-ui/core'
import { Input, SelectedMenu } from 'components'
import { PLAYER_POSITIONS } from 'constants/player'
import PropTypes from 'prop-types'

const MIN_NUMBER = 1
const MAX_NUMBER = 999

const AddPlayerManually = ({ handleSave, onClose }) => {
  const [firstNameError, setFirstNameError] = useState(false)
  const [lastNameError, setlLastNameError] = useState(false)
  const [numberError, setNumberError] = useState(false)

  const emptyPlayer = {
    first_name: '',
    last_name: '',
    number: '',
    position: 'midfield',
  }

  const [player, setPlayer] = useState(emptyPlayer)
  const resetForm = () => {
    setPlayer(emptyPlayer)
    setFirstNameError(false)
    setlLastNameError(false)
    setNumberError(false)
  }

  const handleInput = (event) => {
    const { name, value } = event.target
    setPlayer({ ...player, [name]: value })
    if (value) {
      if (name === 'first_name') {
        setFirstNameError(false)
      }
      if (name === 'last_name') {
        setlLastNameError(false)
      }
      if (name === 'number') {
        setNumberError(false)
      }
    }
  }

  const validateFields = () => {
    const { first_name: firstName, last_name: lastName, number } = player

    let hasError = false

    if (!firstName) {
      setFirstNameError(true)
      hasError = true
    }
    if (!lastName) {
      setlLastNameError(true)
      hasError = true
    }
    if (
      !number ||
      parseInt(number, 10) < MIN_NUMBER ||
      parseInt(number, 10) > MAX_NUMBER
    ) {
      setNumberError(true)
      hasError = true
    }

    return hasError
  }

  const handleSaveDialog = (isClose) => {
    if (validateFields()) {
      return
    }

    handleSave(player)

    if (isClose) {
      onClose()
    }
    resetForm()
  }

  return (
    <>
      <Grid spacing={2} container>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Input
            label={<Translate id="form.first-name" />}
            margin="dense"
            onChange={handleInput}
            name="first_name"
            value={player.first_name}
            error={firstNameError}
            helperText={
              firstNameError ? <Translate id="form.first-name-required" /> : ''
            }
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Input
            label={<Translate id="form.last-name" />}
            margin="dense"
            onChange={handleInput}
            name="last_name"
            value={player.last_name}
            error={lastNameError}
            helperText={
              lastNameError ? <Translate id="form.last-name-required" /> : ''
            }
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Input
            type="number"
            label={<Translate id="form.number" />}
            margin="dense"
            onChange={handleInput}
            name="number"
            value={player.number}
            error={numberError}
            helperText={
              numberError ? <Translate id="form.number-too-large" /> : ''
            }
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <SelectedMenu
            id="position"
            value={player.position}
            margin="dense"
            onChange={handleInput}
            label={<Translate id="form.position" />}
          >
            {PLAYER_POSITIONS.map((option) => (
              <MenuItem key={`${option.value}`} value={option.value}>
                {option.title}
              </MenuItem>
            ))}
          </SelectedMenu>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        style={{ marginTop: '1rem', marginBottom: '1rem' }}
      >
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSaveDialog(false)}
            fullWidth
          >
            <Translate id="matches.highlights.save-n-add" />
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSaveDialog(true)}
            fullWidth
          >
            <Translate id="matches.highlights.add-n-exit" />
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
AddPlayerManually.propTypes = {
  handleSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}
export default AddPlayerManually
