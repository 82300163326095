import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter, matchPath } from 'react-router-dom'
import { Translate } from 'react-localize-redux'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import { MenuItem, Link } from '@material-ui/core'
import { SelectedMenu } from 'components'
import urlConstructor from 'utils/urlConstructor'
import { INITIAL_ROUTE, SEASONS_ROUTE } from 'constants/routes'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    '& .MuiInputBase-formControl:before': {
      border: '0 solid',
    },
    '& .MuiInputBase-formControl:hover:before': {
      border: '0 solid',
    },
    '& label': {
      color: theme.header.label,
    },
    '& .MuiSelect-root': {
      color: theme.header.dropdown,
    },
    '& fieldset': {
      borderColor: theme.header.label,
      '&:hover': {
        borderColor: theme.header.label,
      },
    },
    '& .MuiSelect-icon': {
      color: theme.header.label,
      right: '5px',
    },
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

const createPathname = (newSeasonId, pathname) => {
  const match = matchPath(pathname, {
    path: INITIAL_ROUTE,
  })

  const startPositionOfPathWithoutTeamIdAndSeasonId = match.url.length

  const pathWithoutTeamIdAndSeasonId = pathname.slice(
    startPositionOfPathWithoutTeamIdAndSeasonId
  )

  return urlConstructor(
    match.params.teamId,
    newSeasonId,
    pathWithoutTeamIdAndSeasonId
  )
}

const SeasonSelector = (props) => {
  const {
    location: { pathname },
    match: {
      isExact,
      params: { teamId, seasonId },
    },
    seasons: { items },
  } = props
  const classes = useStyles()
  const seasonsForCurrentTeam = items[teamId] || []

  const normalizedSeasons = seasonsForCurrentTeam.map((item) => {
    const { start_date: startDay, end_date: endDay, id: value } = item

    const title = `${moment(startDay).format('MM.YYYY')} - ${moment(
      endDay
    ).format('MM.YYYY')}`

    return { value, title, disabled: !isExact }
  })

  const onSelect = (newSeasonId) => {
    const newPathname = createPathname(newSeasonId, pathname)

    window.location = newPathname
  }

  const [currentSeason, changeCurrentSeason] = React.useState(
    +seasonId
  )

  React.useEffect(() => {
    changeCurrentSeason(+seasonId)
  }, [seasonId])

  return (
    <SelectedMenu
      id="current-season"
      value={currentSeason}
      style={{ width: 180 }}
      onChange={(event) => {
        changeCurrentSeason(+event.target.value)
        onSelect(event.target.value)
      }}
      label={<Translate id="menu.current-season" />}
    >
      {normalizedSeasons.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.title}
        </MenuItem>
      ))}
      <Link href={`/team/${teamId}/season/${seasonId}${SEASONS_ROUTE}`}>
        <MenuItem>
          <Translate id="season.manage" />
        </MenuItem>
      </Link>
    </SelectedMenu>
  )
}

SeasonSelector.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  match: PropTypes.shape().isRequired,
  seasons: PropTypes.shape().isRequired,
}

export default compose(
  withRouter,
  connect(({ seasons }) => ({ seasons }))
)(SeasonSelector)
