import { makeStyles } from '@material-ui/core/styles'
import BackgroundCoach from '../assets/images/login-coach.jpg'

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    backgroundColor: props.backgroundcolor,
    '& .MuiButton-contained:hover': {
      background: props.primarycolor,
    },
    '& .MuiStepIcon-active, & .MuiStepIcon-completed': {
      color: props.primarycolor,
    },
  }),
  button: (props) => ({
    background: props.primarycolor,
    color: props.buttoncolor,
  }),
  link: (props) => ({
    color: props.textcolor,
    fontSize: 14,
  }),
  typography: (props) => ({
    color: props.textcolor,
  }),
  background: (props) => ({
    backgroundSize: 'cover',
    backgroundImage: `url(${props.backgroundimage || BackgroundCoach})`,
    backgroundPosition: 'center',
    // borderRadius: '0 40px 40px 0',
    height: '100vh',
    width: '100%',
    position: 'relative',
    '&::before': {
      content: "''",
      position: 'absolute',
      top: 0,
      left: 0,
      width: '30%',
      height: '100%',
      backgroundImage: 'linear-gradient(to left, rgba(255,0,0,0), #111425)',
    },
  }),
  SignupTopText: (props) => ({
    background: props.primarycolor,
    color: props.buttoncolor,
    position: 'fixed',
    textAlign: 'center',
    padding: theme.spacing(1),
    width: '100%',
  }),
  loginBox: {
    maxWidth: 360,
    width: '100%',
  },
  centeredGridContent: {
    display: 'flex',
    justifyContent: 'center',
    height: '100vh',
    alignItems: 'center',
    padding: '2rem',
  },
  heading: (props) => ({
    fontFamily: 'Chakra Petch, sans-serif',
    color: props.textcolor,
    marginBottom: theme.spacing(3),
    fontSize: 25,
  }),
  paragraph: (props) => ({
    color: props.textcolor,
  }),
  socialLogin: {
    width: '100%',
    "& img":{
      width:theme.spacing(2),
      height:theme.spacing(2)
    }
  },
  step: (props) => ({
    background: 'transparent',
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    '& .MuiStepIcon-text': {
      fontSize: 12,
      fill: props.buttoncolor,
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
  }),
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  BoxLarge: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  signupLogo: {
    height: '100%',
    width: 150,
  },
  formControl: (props) => ({
    '& .MuiFormLabel-root': {
      color: props.textcolor,
    },
    '& .MuiInputBase-input, & .MuiFormLabel-root': {
      color: props.textcolor,
    },
    '& .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
      {
        borderColor: props.textcolor,
      },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
      {
        borderColor: `${props.primarycolor} !important`,
      },
    '& .MuiInputLabel-root.Mui-error': {
      color: `${props.redcolor} !important`,
    },
    '& .Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-error:hover .MuiOutlinedInput-notchedOutline':
      {
        borderColor: props.redcolor,
      },
    '& .Mui-focused': {
      color: props.primarycolor,
    },
    '& .Mui-error': {
      color: props.redcolor,
    },
  }),
  [theme.breakpoints.down('sm')]: {
    centeredGridContent: {
      padding: '1.2rem',
    },
    '& .MuiStepIcon-text': {
      fontSize: 12,
    },
  },
}))

export default useStyles
