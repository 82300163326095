import React from 'react'
import { ArticlesCategoriesLoader } from 'loaders'
import ArticlesCategoriesContainer from './ArticlesCategoriesContainer'
import PROP_TYPES from 'constants/propTypes'

const ArticlesCategories = ({ location, match }) => (
  <ArticlesCategoriesLoader>
    <ArticlesCategoriesContainer location={location} match={match} />
  </ArticlesCategoriesLoader>
)

ArticlesCategories.propTypes = {
  location: PROP_TYPES.location.isRequired,
  match: PROP_TYPES.match.isRequired,
}

export default ArticlesCategories
