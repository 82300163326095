import React from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { SummaryCircle } from 'components'
import { Card } from '@material-ui/core'

const useStyles = makeStyles({
  cardMui: {
    height: '100%',
    boxShadow: 'unset'
  },
  circlesWrapper: {
    display: 'flex',
    justifyContent: 'space-around'
  }
})

const PlayerStatisticsWidget = ({ statistics, player }) => {
  const classes = useStyles()

  return (
    <Translate>
      {({ translate }) => (
        <Card className={classes.cardMui}>
          <div className={classes.circlesWrapper}>
            <SummaryCircle
              title={translate('team.sessions')}
              value={
                Number.isNaN(
                  statistics.trainings_attended / statistics.trainings_total
                )
                  ? '0%'
                  : `${Math.round(
                      (statistics.trainings_attended /
                        statistics.trainings_total) *
                        100
                    )}%`
              }
              ratio={statistics.trainings_attended / statistics.trainings_total}
            />
            <SummaryCircle
              title={translate('player.playing-time')}
              value={player.totalMinutesPlayed}
            />
            <SummaryCircle
              title={translate('team.goals')}
              value={statistics.goals_scored}
              maxValue={statistics.goals_total}
              ratio={statistics.goals_scored / statistics.goals_total}
            />
            <SummaryCircle
              title={translate('team.assisted')}
              value={statistics.goals_assisted}
              maxValue={statistics.goals_assisted_total}
              ratio={
                statistics.goals_assisted / statistics.goals_assisted_total
              }
            />
          </div>
        </Card>
      )}
    </Translate>
  );
};

PlayerStatisticsWidget.propTypes = {
  statistics: PropTypes.shape().isRequired,
  player: PropTypes.shape().isRequired
}

export default PlayerStatisticsWidget
