import { combineActions, handleActions } from 'redux-actions'
import {
  actionsTypesGenerator,
  onRequest,
  onSuccess,
  onFailure,
} from 'utils/reduxHelpers'
import initialState from '../initialState'

const namespace = 'SUBSCRIPTION'

const CREATE_SUBSCRIPTION = actionsTypesGenerator(`CREATE_${namespace}`)
const CREATE_SESSION = actionsTypesGenerator(`CREATE_${namespace}`)
const CREATE_SUBSCRIPTION_WITHOUT_PAYMENT = actionsTypesGenerator(
  `CREATE_${namespace}_WITHOUT_PAYMENT`
)
const UPDATE_TRIAL_SUBSCRIPTION = actionsTypesGenerator(`UPDATE_${namespace}`)
const UPDATE_SUBSCRIPTION = actionsTypesGenerator(`UPDATE_${namespace}`)
const UPDATE_DEFAULT_CARD = actionsTypesGenerator(
  `UPDATE_DEFAULT_CARD_${namespace}`
)
const RESUME_SUBSCRIPTION = actionsTypesGenerator(`RESUME_${namespace}`)
const DELETE_SUBSCRIPTION = actionsTypesGenerator(`DELETE_${namespace}`)
const CANCEL_SUBSCRIPTIONS = actionsTypesGenerator(`DELETE_${namespace}`)
const UPDATE_CARD = actionsTypesGenerator(`UPDATE_CARD`)
const ALL_CARD = actionsTypesGenerator(`ALL_CARD`)

export const createSubscription = data => ({
  types: CREATE_SUBSCRIPTION,
  callAPI: {
    method: 'POST',
    entity: 'Subscription',
    path: '/billing/subscription/create',
    data,
  },
})
export const createSessionForBilling = data => ({
  types: CREATE_SESSION,
  callAPI: {
    method: 'POST',
    entity: 'Subscription',
    path: '/billing/create_session',
    data,
  },
})

export const retriveSessionOfBilling = (
  paramsString,
  data = { preventToastNotification: true }
) => ({
  types: CREATE_SESSION,
  callAPI: {
    method: 'POST',
    entity: 'Subscription',
    path: '/billing/retrive_session',
    data,
    paramsString,
  },
})

export const createSubscriptionWithoutPayment = data => ({
  types: CREATE_SUBSCRIPTION_WITHOUT_PAYMENT,
  callAPI: {
    method: 'POST',
    entity: 'Subscription',
    path: '/billing/subscription/create_subscription',
    data,
  },
})
export const createTrialSubscription = data => ({
  types: CREATE_SUBSCRIPTION_WITHOUT_PAYMENT,
  callAPI: {
    method: 'POST',
    entity: 'Subscription',
    path: '/billing/subscription/create_trial_subscription',
    data,
  },
})
export const updateSubscription = data => ({
  types: UPDATE_SUBSCRIPTION,
  callAPI: {
    method: 'POST',
    entity: 'Subscription',
    path: `/billing/change/plan`,
    data,
  },
})

export const updateSubscriptionTrial = data => ({
  types: UPDATE_TRIAL_SUBSCRIPTION,
  callAPI: {
    method: 'POST',
    entity: 'Subscription',
    path: `/billing/change/plan/trial`,
    data,
  },
})

export const deleteSubscription = id => ({
  types: DELETE_SUBSCRIPTION,
  callAPI: {
    method: 'DELETE',
    entity: 'Subscription',
    path: `/billing/subscription/cancel/${id}`,
  },
  payload: { id },
})

export const cancelSubscriptions = data => ({
  types: CANCEL_SUBSCRIPTIONS,
  callAPI: {
    method: 'POST',
    entity: 'Subscription',
    path: '/billing/subscriptions/cancel',
    data,
  },
})

export const resumeSubscription = id => ({
  types: RESUME_SUBSCRIPTION,
  callAPI: {
    method: 'POST',
    entity: 'Subscription',
    path: `/billing/subscription/resume/${id}`,
  },
  payload: { id },
})

export const updateCardApi = data => ({
  types: UPDATE_CARD,
  callAPI: {
    method: 'PUT',
    entity: 'Card',
    path: `/billing/card/edit`,
    data,
  },
})
export const updateDefaultCard = data => ({
  types: UPDATE_DEFAULT_CARD,
  callAPI: {
    method: 'POST',
    entity: 'Card',
    path: `/billing/change_default_card`,
    data,
  },
})
export const fetchAllCards = () => ({
  types: ALL_CARD,
  callAPI: {
    method: 'GET',
    entity: 'Card',
    path: `/billing/cards`,
  },
})

export default handleActions(
  {
    [combineActions(
      CREATE_SUBSCRIPTION.request,
      CREATE_SUBSCRIPTION_WITHOUT_PAYMENT.request,
      UPDATE_SUBSCRIPTION.request,
      UPDATE_TRIAL_SUBSCRIPTION.request,
      DELETE_SUBSCRIPTION.request,
      RESUME_SUBSCRIPTION.request,
      CANCEL_SUBSCRIPTIONS.request,
      UPDATE_CARD.request,
      UPDATE_DEFAULT_CARD.request,
      ALL_CARD.request
    )]: onRequest,
    [combineActions(
      CREATE_SUBSCRIPTION.success,
      CREATE_SUBSCRIPTION_WITHOUT_PAYMENT.success,
      UPDATE_SUBSCRIPTION.success,
      UPDATE_CARD.success,
      UPDATE_TRIAL_SUBSCRIPTION.success,
      DELETE_SUBSCRIPTION.success,
      RESUME_SUBSCRIPTION.success,
      CANCEL_SUBSCRIPTIONS.success,
      UPDATE_DEFAULT_CARD.success,
      ALL_CARD.success
    )]: (state, { response: { data } }) => {
      return onSuccess({
        ...state,
        current: data,
      })
    },
    [DELETE_SUBSCRIPTION.success]: state => onSuccess({ ...state }),
    [combineActions(
      CREATE_SUBSCRIPTION.failure,
      CREATE_SUBSCRIPTION_WITHOUT_PAYMENT.failure,
      UPDATE_SUBSCRIPTION.failure,
      UPDATE_TRIAL_SUBSCRIPTION.failure,
      UPDATE_CARD.failure,
      UPDATE_DEFAULT_CARD.failure,
      DELETE_SUBSCRIPTION.failure,
      RESUME_SUBSCRIPTION.failure,
      CANCEL_SUBSCRIPTIONS.failure,
      ALL_CARD.failure
    )]: onFailure,
  },
  initialState.subscription
)
