import React from 'react'
import PropTypes from 'prop-types'
import { Dialog } from '@material-ui/core'
import DrillHead from './DrillHead'
import DrillBody from './DrillBody'
import DrillFooter from './DrillFooter'
import { useStyles } from './styleComponents'

const DrillModal = ({
  drill,
  onHide,
  categoryType,
  plusButton,
  open,
  handleMenuClick,
  handleMenuClose,
  anchorEl,
  classes = useStyles(),
}) => {
  const { uuid } = drill

  return (
    <Dialog
      open={open}
      onClose={onHide}
      centered
      onHide={onHide}
      fullWidth
      maxWidth="xl"
    >
      <DrillHead
        drill={drill}
        handleMenuClick={handleMenuClick}
        handleMenuClose={handleMenuClose}
        anchorEl={anchorEl}
        plusButton={plusButton}
        categoryType={categoryType}
        onHide={onHide}
      />
      <DrillBody drill={drill} />

      <DrillFooter drill={drill} classes={classes} />
    </Dialog>
  )
}
DrillModal.defaultProps = {
  anchorEl: true,
  categoryType: [],
  plusButton: [],
  open: false,
}
DrillModal.propTypes = {
  drill: PropTypes.shape().isRequired,
  onHide: PropTypes.func.isRequired,
  classes: PropTypes.func.isRequired,
  fetchDrill: PropTypes.func.isRequired,
  categoryType: PropTypes.string,
  plusButton: PropTypes.string,
  open: PropTypes.bool,
  handleMenuClick: PropTypes.func,
  handleMenuClose: PropTypes.func,
  anchorEl: PropTypes.bool,
}

export default DrillModal
