import React, { useCallback } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { change } from 'redux-form'
import withDndContext from 'withDndContext'
import Field from './Field'
import PROP_TYPES from 'constants/propTypes'

const calculateRelativePosition = (position, fieldPosition) => ({
  position_x:
    Math.round(((position.x - fieldPosition.x) / fieldPosition.width) * 10000) /
    100,
  position_y:
    Math.round(
      ((position.y - fieldPosition.y) / fieldPosition.height) * 10000
    ) / 100,
})

const FieldContainer = ({ fields, meta: { form }, players, onFieldChange }) => {
  const onAddNew = () => {
    return fields.push({
      id: Date.now(),
      player_position: 'midfield',
      position_x: 50,
      position_y: 50,
    })
  }

  const onDelete = playerIndex => fields.remove(playerIndex)

  const onChangePlayerPosition = playerIndex => newPosition => {
    return onFieldChange(form, `${fields.name}[${playerIndex}]`, {
      ...players[playerIndex],
      player_position: newPosition,
    })
  }

  const onDrop = useCallback(
    (playerId, newPosition) => {
      const targetPlayerIndex = (players || []).findIndex(
        ({ id }) => id === playerId
      )

      const participantPosition = calculateRelativePosition(
        newPosition,
        document.getElementById('participantsField').getBoundingClientRect()
      )

      if (
        participantPosition.position_x < 0 ||
        participantPosition.position_y < 0 ||
        participantPosition.position_x > 100 ||
        participantPosition.position_y > 100
      ) {
        return
      }

      onFieldChange(form, `${fields.name}[${targetPlayerIndex}]`, {
        ...players[targetPlayerIndex],
        ...participantPosition,
      })
    },
    [players]
  )

  return (
    <Field
      players={players}
      onAddNew={onAddNew}
      onChangePlayerPosition={onChangePlayerPosition}
      onDelete={onDelete}
      onDrop={onDrop}
    />
  )
}

FieldContainer.defaultProps = {
  players: [],
}

FieldContainer.propTypes = {
  fields: PROP_TYPES.shape().isRequired,
  meta: PROP_TYPES.shape().isRequired,
  players: PROP_TYPES.arrayOfObjects,
  onFieldChange: PROP_TYPES.func.isRequired,
}

export default compose(
  withDndContext,
  connect(
    ({ form }, { meta }) => ({
      players:
        form[meta.form] &&
        form[meta.form].values &&
        form[meta.form].values.lineup,
    }),
    {
      onFieldChange: change,
    }
  )
)(FieldContainer)
