import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { staff } from 'redux/modules'
import Loader from './Loader'
import PROP_TYPES from 'constants/propTypes'

const AllStaffLoader = ({
  teamId,
  params,
  fetchAllStaff,
  children,
  ...restProps
}) => {
  const fetchMethod = () => fetchAllStaff(teamId, params)
  const triggers = [teamId]

  return (
    <Loader fetchMethod={fetchMethod} triggers={triggers} {...restProps}>
      {children}
    </Loader>
  )
}
AllStaffLoader.defaultProps = {
  params: {},
}
AllStaffLoader.propTypes = {
  children: PROP_TYPES.children.isRequired,
  teamId: PROP_TYPES.id.isRequired,
  fetchAllStaff: PropTypes.func.isRequired,
  params: PropTypes.shape(),
}

export default connect(
  null,
  { fetchAllStaff: staff.fetchAllStaff }
)(AllStaffLoader)
