/* eslint-disable no-unused-vars */
import React, { useState, useCallback, useEffect } from 'react'
// eslint-disable-next-line
import 'froala-editor/js/plugins.pkgd.min.js'
import 'froala-editor/css/froala_style.min.css'
import 'froala-editor/css/froala_editor.pkgd.min.css'
import FroalaEditor from 'react-froala-wysiwyg'
import { compose } from 'redux'
import { connect } from 'react-redux'
import config from './config'
import PROP_TYPES from 'constants/propTypes'

const FroalaTextEditor = ({ input: { onChange, value }, token }) => {
  const [content, setContent] = useState('')

  useEffect(() => {
    if (value && content && value === content) {
      return
    }

    if (value) {
      setContent(value)
    } else {
      setContent(null)
    }
  }, [value])

  const onEditorContentChange = useCallback(newContent => {
    // eslint-disable-next-line
    newContent = newContent.replace('id="isPasted"', '')
    setContent(newContent)
    onChange(newContent)
  }, [])

  return (
        <FroalaEditor
          tag="textarea"
          config={{
            ...config,
            requestHeaders: {
              Authorization: `Bearer ${token}`,
              Accept: 'application/json',
            }
          }}
          model={content}
          onModelChange={onEditorContentChange}
        />
  )
}

FroalaTextEditor.defaultProps = {}

FroalaTextEditor.propTypes = {
  input: PROP_TYPES.shape().isRequired,
  token: PROP_TYPES.string.isRequired,
}

export default compose(
  connect(
    ({ auth }) => ({
      token: auth.token,
    })
  )
)(FroalaTextEditor)
