import React from 'react'
import {
  FormGroup,
  FormControlLabel,
  Checkbox as CheckboxMui,
} from '@material-ui/core'
import PropTypes from 'prop-types'
//   margin-bottom: 15px;

//   > span {
//     :after {
//       content: '\\2715';
//       font-size: 12px;
//       align-self: center;
//       margin-left: 5px;

//       display: ${({ checked, isCloseButtonHidden }) =>
//         checked && !isCloseButtonHidden ? 'block' : 'none'};
//       color: ${({ checked, isCloseButtonHidden }) =>
//         checked && !isCloseButtonHidden
//           ? 'var(--secondary-green)'
//           : 'var(--dark-grey)'};
//     }

//     display: flex;
//     align-items: flex-start;

//     width: fit-content;
//     height: 20px;
//     border-radius: 11px;
//     padding: 2px 10px;

//     box-shadow: 0 1px 2px 1.2px var(--silver);
//     cursor: pointer;

//     color: ${({ checked }) =>
//       checked ? 'var(--secondary-green)' : 'var(--dark-grey)'};
//   }
//   .category-list & {
//     display: block;
//   }

//   ul.category-list li {
//     width: 25%;
//     float: left;
//   }
// `

const Checkbox = ({
  input: { value, onChange },
  label,
  isCloseButtonHidden,
  className,
}) => (
  <>
    <FormGroup row>
      <FormControlLabel
        control={
          <CheckboxMui
            checked={value}
            onChange={() => onChange(+!value)}
            isCloseButtonHidden={isCloseButtonHidden}
            className={className}
          />
        }
        label={label}
      />
    </FormGroup>
    {/* <Container
    checked={value}
    onClick={() => onChange(+!value)}
    isCloseButtonHidden={isCloseButtonHidden}
    className={className}
  >
    <span>{label}</span>
  </Container> */}
  </>
)

Checkbox.defaultProps = {
  isCloseButtonHidden: false,
  className: undefined,
}

Checkbox.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  isCloseButtonHidden: PropTypes.bool,
  className: PropTypes.string,
}

export default Checkbox
