import React, { useState, useRef } from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import {
  Card,
  Tabs,
  Tab,
  Divider,
  Grid,
  Button,
  Hidden,
} from '@material-ui/core'
import { Input } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import hasSubscription from 'utils/hasSubscription'
import { players as playersModule } from 'redux/modules'
import { connect } from 'react-redux'
import TabPanel from 'components/material/TabPanel'
import AddEditPlayerStrengthWeakness from './AddEditPlayerStrengthWeakness'
import PlayerAnalysisList from './PlayerAnalysisList'
import { BASIC } from 'constants/plans'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    '& .MuiGrid-grid-md-6': {
      maxWidth: '49.2%',
      flexBasis: '49.2%',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        flexBasis: '100%',
      },
    },
  },
  textarea: {
    '& .MuiInputBase-root': {
      height: 'unset',
      paddingTop: 'unset',
      paddingBottom: 'unset',
    },
  },
}))

const PlayerDevelopmentTracking = ({
  onChange,
  development_tracking: developmentTracking,
  createPlayerAnalysis,
  updatePlayerAnalysis,
  deletePlayerAnalysis,
  fetchTeamPlayer,
  player,
  handleSave,
}) => {
  const [updateID, setUpdateID] = useState(0)
  const [note, setNote] = useState(null)
  const [open, setOpen] = useState(false)
  const [selectedValue, selectedValueChange] = useState('strength')
  const DBStrength = player.strengths_weaknesses
    .filter((s) => s.type === 'strength')
    .map((st) => {
      return { id: st.id, name: st.note }
    })
  const DBWeakness = player.strengths_weaknesses
    .filter((w) => w.type === 'weakness')
    .map((weak) => {
      return { id: weak.id, name: weak.note }
    })
  const [strengths, setStrengths] = useState(DBStrength)
  const [weaknesses, setWeaknesses] = useState(DBWeakness)
  const nameRef = useRef()
  const classes = useStyles()

  const onFieldChange = ({ target: { value } }) =>
    onChange('development_tracking', value)

  const [value, setValue] = useState(hasSubscription() !== BASIC ? 1 : 0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleClickOpenfor = (value1) => {
    selectedValueChange(value1)
    setNote('')
    setUpdateID(0)
    handleClickOpen()
  }

  const handleClickOpenEdit = (analysisType, analysisNote, id) => {
    setNote(analysisNote)
    selectedValueChange(analysisType)
    setUpdateID(id)
    handleClickOpen()
  }

  const deleteRecord = (id) => {
    const strengthItems = strengths.filter((strength) => strength.id !== id)
    const weaknessItems = weaknesses.filter((weakness) => weakness.id !== id)
    setStrengths(strengthItems)
    setWeaknesses(weaknessItems)
    deletePlayerAnalysis(id)
  }

  return (
    <>
      <Card className={classes.root}>
        <Tabs value={value} onChange={handleChange}>
          {hasSubscription() !== BASIC && (
            <Tab label={<Translate id="team.development-tracking" />} />
          )}
          <Tab label={<Translate id="team.player-analysis" />} />
        </Tabs>
        <Divider />
        {hasSubscription() !== BASIC && (
          <TabPanel value={value} index={0}>
            <Grid container spacing={2}>
              <Input
                name="development_tracking"
                value={developmentTracking || ''}
                label={<Translate id="matches.additional-info" />}
                multiline
                minRows={8}
                onChange={onFieldChange}
                className={classes.textarea}
              />
              <Button variant="contained" color="primary" onClick={handleSave}>
                <Translate id="button.save" />
              </Button>
            </Grid>
          </TabPanel>
        )}
        <TabPanel value={value} index={hasSubscription() !== BASIC ? 1 : 0}>
          {open && (
            <AddEditPlayerStrengthWeakness
              open={open}
              selectedValue={selectedValue}
              onClose={handleClose}
              createPlayerAnalysis={createPlayerAnalysis}
              player={{ ...player }}
              nameRef={nameRef}
              updatePlayerAnalysis={updatePlayerAnalysis}
              fetchTeamPlayer={fetchTeamPlayer}
              setStrengths={setStrengths}
              setWeaknesses={setWeaknesses}
              updateID={updateID}
              note={note}
              setNote={setNote}
            />
          )}
          <Grid container spacing={2}>
            <PlayerAnalysisList
              entities={strengths}
              handleClickOpenEdit={handleClickOpenEdit}
              handleClickOpenfor={handleClickOpenfor}
              deleteRecord={deleteRecord}
              label={<Translate id="team.strengths" />}
              type="strength"
            />
            <Hidden smDown>
              <Divider
                orientation="vertical"
                flexItem
                style={{ marginLeft: 2, marginRight: 2 }}
              />
            </Hidden>
            <Hidden smUp>
              <Divider
                style={{ marginTop: 10, marginBottom: 10, width: '100%' }}
              />
            </Hidden>
            <PlayerAnalysisList
              entities={weaknesses}
              handleClickOpenEdit={handleClickOpenEdit}
              handleClickOpenfor={handleClickOpenfor}
              deleteRecord={deleteRecord}
              label={<Translate id="team.weaknesses" />}
              type="weakness"
            />
          </Grid>
        </TabPanel>
      </Card>
    </>
  )
}

PlayerDevelopmentTracking.defaultProps = {
  development_tracking: '',
}

PlayerDevelopmentTracking.propTypes = {
  onChange: PropTypes.func.isRequired,
  createPlayerAnalysis: PropTypes.func.isRequired,
  updatePlayerAnalysis: PropTypes.func.isRequired,
  deletePlayerAnalysis: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  development_tracking: PropTypes.string,
  fetchTeamPlayer: PropTypes.func.isRequired,
  player: PropTypes.shape().isRequired,
}

export default connect(
  ({
    router: {
      location: { fromUrl, fromPathname },
    },
    players,
  }) => ({
    fromUrl,
    fromPathname,
    player: players.current,
  }),
  {
    createPlayerAnalysis: playersModule.createPlayerAnalysis,
    updatePlayerAnalysis: playersModule.updatePlayerAnalysis,
    deletePlayerAnalysis: playersModule.deletePlayerAnalysis,
  }
)(PlayerDevelopmentTracking)
