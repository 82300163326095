import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Card,
  Typography,
  Box,
  MenuItem,
} from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { SelectedMenu } from 'components'
import { team as teamModule } from '../../redux/modules'
import PROP_TYPES from '../../constants/propTypes'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 'auto',
    padding: theme.spacing(4),
    marginBottom: '1rem',
  },
  title: {
    paddingBottom: theme.spacing(0.5),
  },
  section: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      marginRight: 0,
      marginLeft: 0,
      '& > :nth-child(1)': {
        paddingBottom: theme.spacing(2),
      },
    },
  },
  formControl: {
    marginLeft: '20px',
    maxWidth: '160px',
  },
}))

const PlayerEventResponse = ({ team, patchTeam }) => {
  const classes = useStyles()
  const [cutoffTime, setCutoffTime] = useState(team.availability_cutoff_time)
  const [cutoffTimeOptions, setCutoffTimeOptions] = useState([0, 6, 12, 24])

  const handleCutoffTimeChange = (event) => {
    const { value } = event.target
    setCutoffTime(value)
    patchTeam(team.uuid, { availability_cutoff_time: value })
  }

  return (
    <>
      <Card className={classes.root}>
        <Box>
          <Typography variant="h3" component="h3" className={classes.title}>
            <Translate id="player.event-response.title" />
          </Typography>
          <Typography variant="h6" component="h6">
            <Translate id="player.event-response.select-default" />
          </Typography>
        </Box>
        <Box className={classes.section}>
          <Typography variant="h4" component="h4">
            <Translate id="player.event-response.override-message" />
          </Typography>

          <SelectedMenu
            id="event-response.cutoff-time"
            value={cutoffTime}
            style={{ width: 180 }}
            onChange={handleCutoffTimeChange}
            label={<Translate id="player.event-response.cutoff-time" />}
          >
            {cutoffTimeOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option === 0 ? (
                  <Translate id="player.event-response.disabled" />
                ) : (
                  <>
                    {option} <Translate id="player.event-response.hours" />
                  </>
                )}
              </MenuItem>
            ))}
          </SelectedMenu>
        </Box>
      </Card>
    </>
  )
}

PlayerEventResponse.propTypes = {
  patchTeam: PROP_TYPES.func.isRequired,
  team: PROP_TYPES.shape().isRequired,
}

export default connect(
  ({ team }) => ({
    team,
  }),
  {
    patchTeam: teamModule.patchTeam,
  }
)(PlayerEventResponse)
