import React, { useContext, useEffect, useState } from 'react'
import { Translate } from 'react-localize-redux'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { trainings } from 'redux/modules'
import {
  Paper,
  Button,
  Card,
  Grid,
  TextareaAutosize,
  IconButton,
} from '@material-ui/core'
// import { SubLink } from '../../../../containers'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined'
import { Snackbars, ReactSelect, Collapsible } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { formateDate } from 'utils/formatDate'
import { Context } from '../TrainingStateContext'

const useStyles = makeStyles((theme) => ({
  collapsible: {
    borderTop: 'unset',
    '& .MuiAccordionDetails-root': {
      display: 'block',
    },
  },
  noteContent: {
    padding: '0rem 1rem 1rem 1rem',
  },
  addNoteBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0 1rem',
  },
  customTextArea: {
    borderRadius: '8px',
    minHeight: '40px',
    padding: '0.5rem',
    width: '100%',
    marginBottom: '1rem',
    '&:focus': {
      borderColor: '#29a173',
    },
  },
  noteRow: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    '& .MuiButtonBase-root': {
      position: 'absolute',
      right: '5px',
      top: '5px',
      [theme.breakpoints.down('sm')]: {
        right: '1px',
        position: 'absolute',
      },
    },
  },
  saveContainedButton: {
    margin: '0rem 2rem',
    marginTop: '.2rem',
  },
  noResNote: {
    pointerEvents: 'none',
    position: 'absolute',
    width: '50%',
    height: '100%',
    padding: '0.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    transform: 'translate(50%, 0%)',
    '& p': {
      textAlign: 'center',
    },
  },
  customReactSelect: {
    minHeight: '20vh',
    overflow: 'auto',
  },
  noteContainer: {
    borderTop: '1px solid #e0e0e0',
    paddingTop: '1rem',
    '&:first-child': {
      border: 'none',
    },
  },
  noteBody: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .notebody-select': {
      width: '33%',
    },
    '& .notebody-text': {
      width: '65%',
    },
  },
  [theme.breakpoints.down('sm')]: {
    customReactSelect: {
      minHeight: 'unset',
      overflow: 'unset',
    },
    noteBody: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      '& .notebody-select': {
        width: '100%',
        marginBottom: '1rem',
      },
      '& .notebody-text': {
        width: '100%',
      },
    },
  },
}))

const TrainingNotes = ({
  createTrainingNote,
  updateTrainingNote,
  deleteTrainingNote,
}) => {
  const classes = useStyles()

  const {
    trainingState: { normalizedPlayers, notes, uuid: trainingUuid },
    onFieldChange,
  } = useContext(Context)

  const playersSuggestions = normalizedPlayers
    .filter((player) => player.name !== null)
    .map((player) => ({
      value: player.id,
      label: `@${player.name}`,
      uuid: player.uuid,
    }))

  const noNoteArr = [{ notes: '', id: null, players: [] }]
  const [newNote, setNewNote] = useState(
    noNoteArr.map((note) => ({ ...note, changed: false }))
  )

  const addNewNote = () => {
    const tempNewNotes = [...newNote]
    const tempNote = noNoteArr[0]
    tempNewNotes.push(tempNote)

    setTimeout(() => {
      window.scrollBy({
        top: 200,
        left: 0,
        behavior: 'smooth',
      })
    }, 200)

    setNewNote(tempNewNotes)
    onFieldChange('notes', tempNewNotes)
  }
  const onChangeLoop = ({ target: { value } }, index) => {
    const tempNewNotes = [...newNote]
    tempNewNotes[index].notes = value
    tempNewNotes[index].changed =
      tempNewNotes[index].notes && tempNewNotes[index].players.length
        ? true
        : false
    setNewNote(tempNewNotes)
    onFieldChange('notes', tempNewNotes)
  }
  const deleteNote = (index) => {
    const tempNewNotes = [...newNote]
    const trainingNoteUuid = tempNewNotes[index].uuid ?? null

    if (trainingNoteUuid) {
      deleteTrainingNote(trainingNoteUuid)
    }
    tempNewNotes.splice(index, 1)
    setNewNote(tempNewNotes)
    onFieldChange('notes', tempNewNotes)
  }

  const saveNote = (index) => {
    const noteToUpdate = newNote[index];
    const selectedPlayersUuids = playersSuggestions
      .filter((playerSuggestion) =>
        newNote[index].players.some(
          (selectedPlayerValue) =>
            selectedPlayerValue === playerSuggestion.value
        )
      )
      .map((playerSelected) => playerSelected.uuid)
    if (noteToUpdate.uuid) {
      updateTrainingNote(noteToUpdate.uuid, {
        note: noteToUpdate.notes,
        players: selectedPlayersUuids,
      })
    } else {
      createTrainingNote(trainingUuid, {
        note: noteToUpdate.notes,
        players: selectedPlayersUuids,
      }).then((response) => {
        const createdNoteUuid = response.response.data.uuid;
        const updatedNotes = [...newNote];
        updatedNotes[index] = {
          ...noteToUpdate,
          uuid: createdNoteUuid,
          changed: false,
        };
        setNewNote(updatedNotes);
        onFieldChange('notes', updatedNotes);
      });
    }
    const tempNewNotes = [...newNote]
    tempNewNotes[index].changed = false
    setNewNote(tempNewNotes)
    onFieldChange('notes', tempNewNotes)
  }

  const onChangePlayers = (newPlayers, option, index) => {
    const tempNewNotes = [...newNote]
    const tempNote = { ...tempNewNotes[index] }

    if (option && option.value === 'everyOne') {
      tempNote.players = [...new Set(playersSuggestions.map((p) => p.value))]
    } else if (option) {
      tempNote.players.push(option.value)
      tempNote.players = [...new Set(tempNote.players)]
    } else if (!option && newPlayers) {
      tempNote.players = [...new Set(newPlayers.map((p) => p.value))]
    } else if (!option && !newPlayers) {
      tempNote.players = []
    }

    tempNewNotes[index] = tempNote
    tempNote.changed =
      tempNewNotes[index].notes && tempNewNotes[index].players.length
        ? true
        : false
    onFieldChange('notes', tempNewNotes)

    setNewNote(tempNewNotes)

    return tempNote.players
  }

  useEffect(() => {
    if (notes.length > 0) {
      setNewNote(notes)
    } else {
      setNewNote(noNoteArr)
    }
  }, [notes])

  return (
    <Translate>
      {({ translate }) => (
        <Collapsible
          title={translate('matches.match-notes')}
          className={classes.collapsible}
        >
          <br />
          <Paper className={classes.noteContent} elevation={0}>
            <Card>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div>
                    {newNote.map((item, index) => {
                      const createdDate = formateDate(item.created_at)
                      const selectedPlayer = item.players.map((player) => {
                        return {
                          ...playersSuggestions.find(
                            (p) => +p.value === +player
                          ),
                        }
                      })

                      return (
                        <div
                          className={classes.noteContainer}
                          key={item.id ? item.id : index}
                        >
                          <div className="w-100">
                            {item.created_at && (
                              <small>{`${createdDate[4]} ${createdDate[3]}, ${createdDate[0]}, ${createdDate[5]}:${createdDate[6]}`}</small>
                            )}
                          </div>
                          <div className={classes.noteBody}>
                            <div className="notebody-select">
                              <div>
                                <div className={classes.customReactSelect}>
                                  <ReactSelect
                                    value={selectedPlayer}
                                    suggestions={playersSuggestions}
                                    onChange={onChangePlayers}
                                    placeholder={translate('videos.tag-person')}
                                    isEveryOne
                                    index={index}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="notebody-text">
                              <div className={classes.noteRow}>
                                <TextareaAutosize
                                  className={classes.customTextArea}
                                  aria-label="Match Note"
                                  rowsMin={6}
                                  value={item.notes}
                                  placeholder=""
                                  onChange={(event) =>
                                    onChangeLoop(event, index)
                                  }
                                />
                                {item.changed && (
                                  <Button
                                    className={classes.saveContainedButton}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={() => saveNote(index, item.uuid)}
                                  >
                                    {item.uuid ? 'Update' : 'Save'}
                                  </Button>
                                )}
                                <IconButton
                                  size="small"
                                  onClick={() => deleteNote(index)}
                                >
                                  <DeleteOutlineOutlinedIcon />
                                </IconButton>
                                {item.notes === '' && (
                                  <div className={classes.noResNote}>
                                    <CommentOutlinedIcon />
                                    <p><Translate id="training.keep-all-your-game-notes-in-one-place"/></p>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </Grid>
              </Grid>
            </Card>
          </Paper>
          <div className={classes.addNoteBtn}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={addNewNote}
            >
            <Translate id="matches.add-note"/>
            </Button>
          </div>
        </Collapsible>
      )}
    </Translate>
  )
}

TrainingNotes.defaultProps = {
  players: [],
}

TrainingNotes.propTypes = {
  deleteTrainingNote: PropTypes.func.isRequired,
  players: PropTypes.arrayOf(PropTypes.object.isRequired),
}

export default connect(null, {
  createTrainingNote: trainings.createTrainingNote,
  updateTrainingNote: trainings.updateTrainingNote,
  deleteTrainingNote: trainings.deleteTrainingNote,
})(TrainingNotes)
