import React from 'react'
import { ToastContainer, Flip } from 'react-toastify'
import './ToastNotifications.css'

const ToastNotifications = () => (
  <ToastContainer
    className="toast-notification"
    position="bottom-right"
    closeButton={false}
    pauseOnFocusLoss={false}
    pauseOnHover={false}
    autoClose={1000}
    transition={Flip}
    hideProgressBar
  />
)

export default ToastNotifications
