import React from 'react'
import { DrillsCategoriesLoader } from 'loaders'
import DrillsCategoriesContainer from './DrillsCategoriesContainer'
import PROP_TYPES from 'constants/propTypes'

const DrillsCategories = ({ location, match }) => (
  <DrillsCategoriesLoader>
    <DrillsCategoriesContainer location={location} match={match} />
  </DrillsCategoriesLoader>
)

DrillsCategories.propTypes = {
  location: PROP_TYPES.location.isRequired,
  match: PROP_TYPES.match.isRequired,
}

export default DrillsCategories
