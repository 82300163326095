import { combineActions, handleActions } from 'redux-actions'
import {
  actionsTypesGenerator,
  onRequest,
  onSuccess,
  onFailure,
} from 'utils/reduxHelpers'
import initialState from '../initialState'

const namespace = 'AGE_CLASSES'

const V2_FETCH_ALL_AGE_CLASSES_OF_CLUB = actionsTypesGenerator(
  `V2_FETCH_ALL_CLUB_WISE_${namespace}`
)
const FETCH_CLUB_AGE_CLASSES = actionsTypesGenerator(
  'FETCH_CLUB_AGE_CLASSES'
)

export const V2FetchAllAgeClasses = (clubUuid) => ({
  types: V2_FETCH_ALL_AGE_CLASSES_OF_CLUB,
  callAPI: {
    method: 'GET',
    entity: 'All Age Classes of Club',
    path: `/v2/clubs/${clubUuid}/age-classes`,
  },
})


export const fetchClubAgeClasses = (clubId) => ({
  types: FETCH_CLUB_AGE_CLASSES,
  callAPI: {
    method: 'GET',
    entity: 'All Age Classes of Club',
    path: `/clubs/${clubId}/age-classes`,
  },
})

export default handleActions(
  {
    [combineActions(
      V2_FETCH_ALL_AGE_CLASSES_OF_CLUB.request,
      FETCH_CLUB_AGE_CLASSES.request
    )]: onRequest,
    [combineActions(
      V2_FETCH_ALL_AGE_CLASSES_OF_CLUB.success,
      FETCH_CLUB_AGE_CLASSES.success
    )]: (state, { response: { data = [] } }) =>
      onSuccess({
        ...state,
        items: data,
      }),
    [combineActions(
      V2_FETCH_ALL_AGE_CLASSES_OF_CLUB.failure,
      FETCH_CLUB_AGE_CLASSES.failure
    )]: onFailure,
  },
  initialState.ageClasses
)
