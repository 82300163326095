import React from 'react'
import { useSelector } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { LocalizeProvider } from 'react-localize-redux'
import { create } from 'jss'
import {
  StylesProvider,
  ThemeProvider,
  jssPreset,
} from '@material-ui/core/styles'
import AllRoutes from './AllRoutes'
import createTheme from './theme/index'
import {
  ToastNotifications,
  ErrorBoundary,
  LocalizeInitializer,
} from './containers'

import { history } from './redux/router'

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById('jss-insertion-point'),
})

const App = () => {
  const theme = useSelector(state => state.themes.current)

  return (
    <LocalizeProvider>
      <StylesProvider jss={jss}>
        <ThemeProvider theme={createTheme(theme.currentTheme)}>
            <ErrorBoundary>
              <LocalizeInitializer>
                  <ConnectedRouter history={history}>
                    <AllRoutes />
                  </ConnectedRouter>
                <ToastNotifications />
              </LocalizeInitializer>
            </ErrorBoundary>
        </ThemeProvider>
      </StylesProvider>
    </LocalizeProvider>
  )
}

export default App
