import React, { useMemo } from 'react';
import { Translate } from 'react-localize-redux';
import moment from 'moment';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import TimelineIcon from '@material-ui/icons/Timeline';
import { Grid } from '@material-ui/core';
import PROP_TYPES from 'constants/propTypes';

const CustomCardContent = {
  padding: '0 16px 16px',
};

const Title = {
  color: 'white',
  fontSize: '16px',
  lineHeight: 'inherit',
};

const ChartWrapper = {
  height: '254px',
  '& svg': {
    padding: 0,
    maxHeight: '95%',
  },
};

const PerformanceWidget = ({ title, data }) => {
  const dataWithFormattedDate = useMemo(
    () =>
      data.map(({ date, ...rest }) => ({
        date: moment(date).format('DD.MM.'),
        ...rest,
      }),
    ),
    [data]
  );

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="h4">
            {title}
          </Typography>
        }
      />
      <Grid container>
        <Grid item xs={12}>
          <div style={CustomCardContent}>
            <div style={ChartWrapper}>
              {dataWithFormattedDate.length > 0 ? (
                <ResponsiveContainer height="100%" width="100%">
                  <LineChart
                    data={dataWithFormattedDate}
                    margin={{ left: -45, right: 15, top: 15 }}
                  >
                    <XAxis dataKey="date" interval={0} />
                    <YAxis domain={[1, 5]} interval={0} padding={{ bottom: 10 }} />
                    <Tooltip />
                    <Line
                      type="linear"
                      dataKey="rating"
                      stroke="#29a373"
                      strokeWidth={2}
                      activeDot
                      isAnimationActive
                      animationDuration={2400}
                      connectNulls
                    />
                  </LineChart>
                </ResponsiveContainer>
              ) : (
                <Grid container spacing={2}>
                  <Grid item md={1}>
                    <TimelineIcon
                      style={{
                        fontSize: 100,
                        color: 'var(--primary-green)',
                        float: 'left',
                      }}
                    />
                  </Grid>
                  <Grid item md={11}>
                    <Typography
                      variant="h4"
                      component="h4"
                      style={{
                        fontSize: 20,
                        color: 'var(--primary-green)',
                        height: '100px',
                        verticalAlign: 'middle',
                        display: 'table-cell',
                        maxWidth: '400px',
                      }}
                    >
                      <Translate id="home.no-game-performance" />
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};

PerformanceWidget.defaultProps = {
  data: [],
};

PerformanceWidget.propTypes = {
  title: PROP_TYPES.string.isRequired,
  data: PROP_TYPES.arrayOfObjects,
};

export default PerformanceWidget;
