import { useState, useEffect } from 'react'

export default (player, initialState) => {
  const [editablePlayer, changeEditablePlayer] = useState(initialState)

  useEffect(() => {
    const {
      pace,
      shoot,
      dribbling,
      passing,
      defending,
      physique,
      strengths,
      weaknesses,
      development_tracking: developmentTracking,
    } = player

    changeEditablePlayer({
      pace,
      shoot,
      dribbling,
      passing,
      defending,
      physique,
      strengths,
      weaknesses,
      development_tracking: developmentTracking,
    })
  }, [player])

  return [editablePlayer, changeEditablePlayer]
}
