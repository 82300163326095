import { createTheme } from '@material-ui/core/styles'
import variants from './variants'
import typography from './typography'
import overrides from './overrides'
import breakpoints from './breakpoints'
import props from './props'
import shadows from './shadows'

const createCustomTheme = name => {
  let themeConfig = variants.find(variant => variant.name === name)

  if (!themeConfig) {
    console.warn(new Error(`The theme ${name} is not valid`))

    // eslint-disable-next-line prefer-destructuring
    themeConfig = variants[0]
  }

  return createTheme(
    {
      shape: {
        borderRadius: 8,
      },
      spacing: 8,
      breakpoints,
      overrides,
      props,
      typography,
      shadows,
      palette: themeConfig.palette,
    },
    {
      name: themeConfig.name,
      header: themeConfig.header,
      footer: themeConfig.footer,
      sidebar: themeConfig.sidebar,
      Dataset: themeConfig.Dataset,
      status: themeConfig.status,
    }
  )
}

export default createCustomTheme
