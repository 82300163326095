import React from 'react'
import { connect } from 'react-redux'
import { LineupCreateEditForm } from 'containers'
import { lineups as lineupsModule } from 'redux/modules'
import { createSubUrl } from 'utils/urlConstructor'
import { LINEUPS_DASHBOARD_ROUTE } from 'constants/routes'
import PROP_TYPES from 'constants/propTypes'

const LineupCreate = ({ history, match, createLineup }) => {
  const onSubmit = values => {
    createLineup(values).then(() =>
      history.push(createSubUrl(match, LINEUPS_DASHBOARD_ROUTE))
    )
  }

  return (
    <LineupCreateEditForm
      form="createLineup"
      caption="Create Lineup"
      onSubmit={onSubmit}
    />
  )
}

LineupCreate.propTypes = {
  history: PROP_TYPES.history.isRequired,
  match: PROP_TYPES.match.isRequired,
  createLineup: PROP_TYPES.func.isRequired,
}

export default connect(
  null,
  {
    createLineup: lineupsModule.createLineup,
  }
)(LineupCreate)
