import React, { useState } from 'react'
import { connect } from 'react-redux'
import { ArticleCreateEditForm } from 'containers'
import { articles } from 'redux/modules'
import { createSubUrl } from 'utils/urlConstructor'
import { toast } from 'react-toastify'
import { ARTICLES_DASHBOARD_ROUTE } from 'constants/routes'
import PROP_TYPES from 'constants/propTypes'

const ArticleCreateContainer = ({
  history,
  match,
  location: { fromUrl, fromPathname = 'Articles' },
  articlesCategories,
  createArticle,
}) => {
  const linkBack = fromUrl || createSubUrl(match, ARTICLES_DASHBOARD_ROUTE)

  const goBack = () => history.push(linkBack)
  const [selectedType, setSelectedType] = useState('article')

  const onSubmit = (values) => {
    let data = { ...values }

    if (selectedType === 'video') {
      delete data.podcast
      delete data.body

      // @tofix Fix validation using JOI if possible.
      if (!data.video) {
        toast.error('Select a video first')

        return
      }
    } else if (selectedType === 'podcast') {
      delete data.video
      delete data.body

      // @tofix Fix validation using JOI if possible.
      if (!data.podcast) {
        toast.error('Select an audio first')

        return
      }
    } else {
      delete data.video
      delete data.podcast

      // @tofix Fix validation using JOI if possible.
      if (!data.body) {
        toast.error('Please explain the article content')

        return
      }
    }
    data = { ...data, type: selectedType }

    createArticle(data).then(goBack)
  }

  return (
    <ArticleCreateEditForm
      form="articleCreate"
      fromPath={{
        value: linkBack,
        title: fromPathname,
      }}
      initialValues={{
        categories: [],
      }}
      onSubmit={onSubmit}
      articlesCategories={articlesCategories}
      setSelectedType={setSelectedType}
    />
  )
}

ArticleCreateContainer.defaultProps = {
  articlesCategories: [],
}

ArticleCreateContainer.propTypes = {
  history: PROP_TYPES.history.isRequired,
  match: PROP_TYPES.match.isRequired,
  location: PROP_TYPES.location.isRequired,
  articlesCategories: PROP_TYPES.arrayOfObjects,
  createArticle: PROP_TYPES.func.isRequired,
}

export default connect(
  ({
    articles: {
      current: { articlesCategories },
    },
  }) => ({ articlesCategories }),
  {
    createArticle: articles.createArticle,
  }
)(ArticleCreateContainer)
