import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import {
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Tabs,
  Tab,
  Dialog,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel'
import NoteIcon from '@material-ui/icons/Note'
import { Translate } from 'react-localize-redux'
import CircularSpinner from 'components/loader/CircularSpinner'
import { connect } from 'react-redux'
import { matchHighlights } from 'redux/modules'
import TabPanel from '../../../material/TabPanel'
import AddEditGoal from './AddEditGoal'
import AddEditCard from './AddEditCard'
import AddEditReplacement from './AddEditReplacement'
import AddEditSimpleNote from './AddEditSimpleNote'
import TimeSection from './TimeSection'
import { MATCH_HIGHLIGHT_TYPES } from 'constants/match'

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0.4),
    color: theme.palette.grey[500],
  },
  tabs: {
    marginTop: '15px',
    overflow: 'unset',
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .MuiTabs-fixed': {
      overflow: 'unset !important',
    },
  },
  tab: {
    minWidth: '65px',
    color: '#dcdcdc',
    border: '1px solid #dcdcdc',
    borderRadius: '7px',
    padding: '15px 10px 10px',
    margin: '10px',
    transition: 'box-shadow 400ms',
    '&.MuiTab-textColorInherit.Mui-selected': {
      color: '#ffffff',
      backgroundColor: '#255f64',
      border: '1px solid #255f64',
      boxShadow: '0px 0px 20px rgb(0 0 0 / 30%);',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '10px 10px',
      minWidth: '50px',
    },
  },
  tabPanel: {
    '& .MuiBox-root': {
      paddingTop: '15px',
    },
  },
}))
const MatchHighlightsDialog = ({
  isLoading,
  open,
  onClose,
  fetchMatchHighlights,
  createHighlight,
  handleUpdate,
  existingData,
  participantsList,
  matchUuid,
}) => {
  const classes = useStyles()
  const [activeTab, setActiveTab] = useState(MATCH_HIGHLIGHT_TYPES.goal)
  const [minute, setMinute] = useState(
    existingData && existingData.minute != null ? existingData.minute : 0
  )
 
  const [errorMinute, setErrorMinute] = useState(false)
  const handleChange = (event, newValue) => {
    setActiveTab(newValue)
  }
  const handleCreateHighlight = (data) => {
    return createHighlight(matchUuid, data).then(() => {
      fetchMatchHighlights(matchUuid)
    })
  }

  const resetCommonFields = () => {
    document.getElementById('textbox-minute').value = 0
    setMinute(0)
  }

  useEffect(() => {
    if (existingData) {
      setActiveTab(existingData.type)
    }
  }, [existingData])

  return (
    <Translate>
      {({ translate }) => (
        <Dialog open={open} maxWidth="sm" fullWidth onClose={onClose}>
          {isLoading && <CircularSpinner loadingcircular={isLoading} />}
          <DialogTitle>
            <Typography variant="h4" component="h4">
              {translate('matches.highlights.dialog-box-header-create')}
            </Typography>
            {onClose ? (
              <IconButton
                aria-label="close"
                size="medium"
                className={classes.closeButton}
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </DialogTitle>
          <DialogContent>
            <TimeSection
              existingData={existingData}
              minute={minute}
              setMinute={setMinute}
              errorMinute={errorMinute}
              setErrorMinute={setErrorMinute}
            />

            <Tabs
              value={activeTab}
              onChange={handleChange}
              className={classes.tabs}
              centered
            >
              <Tab
                icon={<SportsSoccerIcon />}
                className={classes.tab}
                style={{
                  display:
                    existingData &&
                    existingData.type !== MATCH_HIGHLIGHT_TYPES.goal
                      ? 'none'
                      : 'block',
                }}
                value={MATCH_HIGHLIGHT_TYPES.goal}
              />
              <Tab
                icon={<ViewCarouselIcon />}
                className={classes.tab}
                style={{
                  display:
                    existingData &&
                    existingData.type !== MATCH_HIGHLIGHT_TYPES.card
                      ? 'none'
                      : 'block',
                }}
                value={MATCH_HIGHLIGHT_TYPES.card}
              />
              <Tab
                icon={<SwapHorizIcon />}
                className={classes.tab}
                style={{
                  display:
                    existingData &&
                    existingData.type !== MATCH_HIGHLIGHT_TYPES.substitution
                      ? 'none'
                      : 'block',
                }}
                value={MATCH_HIGHLIGHT_TYPES.substitution}
              />
              <Tab
                icon={<NoteIcon />}
                className={classes.tab}
                style={{
                  display:
                    existingData &&
                    existingData.type !== MATCH_HIGHLIGHT_TYPES.note
                      ? 'none'
                      : 'block',
                }}
                value={MATCH_HIGHLIGHT_TYPES.note}
              />
            </Tabs>

            <TabPanel
              value={activeTab}
              index={MATCH_HIGHLIGHT_TYPES.goal}
              padding={0}
              className={classes.tabPanel}
            >
              <AddEditGoal
                onClose={onClose}
                existingData={existingData}
                participantsList={participantsList}
                handleUpdate={handleUpdate}
                handleSave={handleCreateHighlight}
                minute={minute}
                errorMinute={errorMinute}
                setErrorMinute={setErrorMinute}
                resetCommonFields={resetCommonFields}
              />
            </TabPanel>
            <TabPanel
              value={activeTab}
              index={MATCH_HIGHLIGHT_TYPES.card}
              padding={0}
              className={classes.tabPanel}
            >
              <AddEditCard
                onClose={onClose}
                existingData={existingData}
                participantsList={participantsList}
                handleUpdate={handleUpdate}
                handleSave={handleCreateHighlight}
                minute={minute}
                errorMinute={errorMinute}
                setErrorMinute={setErrorMinute}
                resetCommonFields={resetCommonFields}
              />
            </TabPanel>
            <TabPanel
              value={activeTab}
              index={MATCH_HIGHLIGHT_TYPES.substitution}
              padding={0}
              className={classes.tabPanel}
            >
              <AddEditReplacement
                onClose={onClose}
                existingData={existingData}
                participantsList={participantsList}
                handleUpdate={handleUpdate}
                handleSave={handleCreateHighlight}
                minute={minute}
                errorMinute={errorMinute}
                setErrorMinute={setErrorMinute}
                resetCommonFields={resetCommonFields}
              />
            </TabPanel>
            <TabPanel
              value={activeTab}
              index={MATCH_HIGHLIGHT_TYPES.note}
              padding={0}
              className={classes.tabPanel}
            >
              <AddEditSimpleNote
                onClose={onClose}
                existingData={existingData}
                handleUpdate={handleUpdate}
                handleSave={handleCreateHighlight}
                minute={minute}
                resetCommonFields={resetCommonFields}
                errorMinute={errorMinute}
                setErrorMinute={setErrorMinute}
              />
            </TabPanel>
          </DialogContent>
        </Dialog>
      )}
    </Translate>
  )
}
MatchHighlightsDialog.defaultProps = {
  onClose: () => 0,
  existingData: null,
}

MatchHighlightsDialog.propTypes = {
  onClose: PropTypes.func,
  createHighlight: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  participantsList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  existingData: PropTypes.shape(),
  isLoading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  matchUuid: PropTypes.string.isRequired,
  fetchMatchHighlights: PropTypes.func.isRequired,
}
export default connect(
  ({
    matches: {
      isLoading,
      current: { uuid },
    },
  }) => ({
    isLoading,
    matchUuid: uuid,
  }),
  {
    createHighlight: matchHighlights.createMatchHighlight,
    fetchMatchHighlights: matchHighlights.fetchMatchHighlights,
  }
)(MatchHighlightsDialog)
