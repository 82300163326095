import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Container as MuiContainer, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: {
    margin: '0 auto',
    padding: '100px',
    fontSize: '32px',
    width: '600px',
  },
});

const WrongPermission = () => {
  const classes = useStyles();

  return (
  <MuiContainer className={classes.container}>
    <Typography variant='p'>You do not have permission</Typography>
    <Link to="/">
      <Button variant="contained" color="primary">Go to main page</Button>
    </Link>{' '}
    <Link to="/login">
      <Button variant="contained" color="secondary">Login as another user</Button>
    </Link>
  </MuiContainer>
  );
};

export default WrongPermission
