import React, { useState } from 'react';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { DroppableArea } from 'components';
import { makeStyles } from '@material-ui/core/styles';
import PlayersList from './PlayersList';

const useStyles = makeStyles(theme => ({
  emptyArea: {
    '& > div': {
      minHeight: '80px',
      marginBottom: 0,
      lineHeight: '17px',
      letterSpacing: '0.1px',
      fontWeight: 300,
      color: theme.palette.text.dark,
    },
    '& > div > div:first-child': {
      marginBottom: '5px',
      color: theme.palette.secondary.green,
    },
  },
  container: {
    flex: 1,
    padding: '5px',
    border: '1px solid #ededed',
    background: '#fff',
    borderRadius: '8px',
    boxShadow: '14px 5px 35px 0 rgba(0, 0, 0, 0.08)',
    '& > div': {
      '& > div:nth-child(2)': {
        display: 'flex',
        flexWrap: 'wrap',
        margin: '0 -5px',
        '& > div': {
          flex: '0 0 25%',
          maxWidth: '25%',
          marginTop: '-10px',
          padding: '0 5px',
        },
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& > div': {
        '& > div:nth-child(2)': {
          display: 'flex',
          flexWrap: 'wrap',
          margin: '0 -5px',
          '& > div': {
            flex: '0 0 100%',
            maxWidth: '100%',
            marginTop: '-10px',
            padding: '0 5px',
          },
        },
      },
    },
  },
  summary: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    cursor: 'pointer',
    '& > span': {
      fontSize: '20px',
      lineHeight: '24px',
      letterSpacing: '0.14px',
      color: theme.palette.secondary.green,
    },
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      marginRight: '10px',
      textAlign: 'center',
      width: '40px',
      height: '40px',
      lineHeight: '38px !important',
      border: '1px solid #ededed',
      borderRadius: '50%',
      fontSize: '19px',
      letterSpacing: '0.13px',
      color: theme.palette.text.dark,
    },
  },
  trainingNotResponded: {
    background: '#ededed',
    width: '100%',
    padding: '10px',
    borderRadius: '8px',
    fontSize: '1.1rem',
    textAlign: 'center',
    color: '#898989',
  },
}));

const NotResponded = ({
  players,
  changeIsSelectedById,
  isAnyPlayerSelected,
}) => {
  const [isOpen, changeIsOpen] = useState(true);

  const toggleOpen = () => changeIsOpen(!isOpen);

  const classes = useStyles();

  if (!players.length) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className={classes.trainingNotResponded}>
            <Translate id="training.not-responded" />
          </div>
        </Grid>

        <Grid item xs={12}>
          <div className={classes.emptyArea}>
            <DroppableArea relation="notResponded">
              <span>
                <Translate id="training.choose-player" />
              </span>
            </DroppableArea>
          </div>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container>
      <div className={classes.container}>
        <PlayersList
          relation="notResponded"
          players={isOpen ? players : []}
          changeIsSelectedById={changeIsSelectedById}
          isAnyPlayerSelected={isAnyPlayerSelected}
        >
          <div className={classes.summary} onClick={toggleOpen} role="presentation">
            <div>{players.length}</div>
            <span>
              <Translate id="training.not-responded" />
            </span>
          </div>
        </PlayersList>
      </div>
    </Grid>
  );
};

NotResponded.defaultProps = {
  players: [],
  isAnyPlayerSelected: false,
};

NotResponded.propTypes = {
  players: PropTypes.arrayOf(PropTypes.object.isRequired),
  changeIsSelectedById: PropTypes.func.isRequired,
  isAnyPlayerSelected: PropTypes.bool,
};

export default NotResponded;