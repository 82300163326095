import React from 'react'
import clsx from 'clsx'
import { Translate } from 'react-localize-redux'
import { makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import {
  AppBar,
  Toolbar,
  Drawer,
  Divider,
  Container,
  Typography,
  Grid,
  Link as MuiLink,
  Box,
  Hidden,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { styled } from '@material-ui/core/styles'
import { SubLink } from 'containers'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import Logo from 'assets/images/coachbetter_Logo_Black.webp'
import { themes as themesModule } from 'redux/modules'
import { SeasonSelector, Sidebar, TeamsDropdown } from './components'
import { LogoImage } from './styledComponents'

const drawerWidth = 95
const drawerMobileWidth = 220
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& .MuiButton-containedPrimary': {
      color: theme.palette.primary.contrastText,
      fontWeight: 600,
    },
    '& .MuiTab-textColorInherit.Mui-selected': {
      fontWeight: 600,
    },
  },

  ToolbarNav: {
    minHeight: '80px',
  },
  trialText: {
    fontSize: '13px',
    lineHeight: '32px',
    color: theme.header.topbar.color,
    fontWeight: 500,
    '& .MuiLink-root': {
      color: theme.header.topbar.link,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.header.background,
    boxShadow: '14px 5px 35px 0 rgba(0,0,0,0.08)',
  },
  userDropdown: {
    // backgroundColor: '#29a373',
    margin: '10px 0',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },

  topNavBar: {
    display: 'flex',
    alignItems: 'center',
  },
  drawerPaper: {
    width: drawerWidth,
    top: '112px',
    background: theme.sidebar.background,
  },

  drawerMobilePaper: {
    width: drawerMobileWidth,
    marginTop: '0',
    background: theme.sidebar.background,
  },
  hide: {
    display: 'none',
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingTop: '8.5rem',
    width: 1,
    margin: '0 auto',
  },
  marginLeftAuto: {
    justifyContent: 'end',
    display: 'flex',
    alignItems: 'center',
  },
  SettingNavBarDropdown: {
    padding: '0 0 0 20px',
    '& .MuiAvatar-root': {
      height: '36px',
      width: '36px',
      background: theme.header.avatar,
    },
    '& .MuiButton-root:hover, & .MuiButton-root:active, & .MuiButton-root:focus':
      {
        background: 'transparent',
      },
  },
  borderRight: {
    borderRight: '1px solid #EDEDED',
    padding: '0 18px',
    '& .MuiFormControl-root': {
      margin: '0',
    },
    '& .MuiButton-root:hover, & .MuiButton-root:active, & .MuiButton-root:focus':
      {
        background: 'transparent',
      },
  },
  logo: {
    flexGrow: 1,
  },
  headTypography: {
    color: theme.header.topbar.color,
  },
  [theme.breakpoints.down('sm')]: {
    logo: {
      textAlign: 'center',
    },
    content: {
      flexGrow: 1,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: '90px',
      paddingTop: '7.063rem',
      width: '1px',
    },
    trialText: {
      fontWeight: 600,
      padding: '10px 30px',
      lineHeight: '18px',
    },
    ToolbarNav: {
      minHeight: '64px',
    },
    borderRight: {
      borderWidth: '0',
      padding: '0',
    },
    DrawerMenuIcon: {
      width: '48px',
      height: '48px',
    },

    SettingNavBarDropdown: {
      padding: '0',
    },
    brand: {
      height: '36px',
      width: 'auto',
      maxWidth: '95%',
    },
  },
}))
const Link = styled(MuiLink)(({ theme }) => ({
  borderRadius: '5px',
  display: 'inline-block',
  textAlign: 'center',
  fontSize: '14px',
}))

const TopBar = styled(Container)(({ theme }) => ({
  background: theme.header.topbar.background,
  color: `${theme.header.color} !important`,
  textAlign: 'center',
  maxWidth: '100% !important',
}))

const NavBar = ({
  children,
  match,
  getClubTheme,
  onlyTeamSelector = false,
  currentTeam,
}) => {
  const classes = useStyles()
  const {
    history,
    params: { teamId, seasonId },
  } = match
  const [open, setOpen] = React.useState(false)
  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  if (teamId && !currentTeam) {
    history.push('/teams')
  }
  const editTeam = (id, season) => {
    // return `/team/${id}/season/${seasonId}/current-team/edit`
    return `/team/${id}/season/${season}/current-team/menu`
  }

  const [isTopBarVisible, setTopBarVisible] = React.useState(
    // add condition here when you want to see the alert bar above navbar
    false
  )

  const closeTopBar = () => {
    setTopBarVisible(false)
  }
  React.useEffect(() => {
    if (currentTeam && currentTeam.club && currentTeam.club.theme) {
      getClubTheme(currentTeam.club.id)
    }
  }, [])

  return (
    <div className={isTopBarVisible ? 'withTopBar' : 'withOutTopBar'}>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar} elevation={0}>
          <Hidden only={['xs', 'sm']}>
            {isTopBarVisible && (
              <TopBar fullWidth>
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="between"
                  alignItems="center"
                  textAlign="center"
                  height="32px"
                >
                  <Box width="100%">
                    <Typography className={classes.trialText}>
                      <Translate id="checkout.trials_note_congrats" />
                      {parseInt(currentTeam.trialEndsAt, 10)}
                      <Translate id="checkout.trials_note" />
                      <Link
                        href={editTeam(teamId, seasonId)}
                      >
                        <Translate id="button.upgrade-now" />
                      </Link>
                      <Translate id="checkout.just49" />
                    </Typography>
                  </Box>
                  <IconButton onClick={closeTopBar}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Box>
              </TopBar>
            )}
          </Hidden>
          <Toolbar className={classes.ToolbarNav}>
            {currentTeam && currentTeam.club && currentTeam.club.theme && (
              <Hidden only={['md', 'lg', 'xl']}>
                {!open ? (
                  <IconButton
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    className={clsx(open && classes.hide)}
                  >
                    <MenuIcon />
                  </IconButton>
                ) : (
                  <IconButton onClick={handleDrawerClose}>
                    <CloseIcon />
                  </IconButton>
                )}
              </Hidden>
            )}
            <div className={classes.logo}>
              <SubLink to="/">
                <LogoImage
                  src={
                    currentTeam.club && currentTeam.club.theme
                      ? currentTeam.club.theme.logo
                      : Logo
                  }
                  alt="Coachbetter"
                  className={classes.brand}
                />
              </SubLink>
            </div>
            <Hidden only={['xs', 'sm']}>
              <div className={classes.logo}>
                <Typography variant="h6" className={classes.headTypography} component="h6">
                  {currentTeam.name}
                </Typography>
              </div>
              <Grid item className={classes.borderRight}>
                {!onlyTeamSelector && <SeasonSelector />}
              </Grid>
            </Hidden>
            <Grid item>
              <TeamsDropdown />
            </Grid>
          </Toolbar>
        </AppBar>

        {window.location.pathname === '/teams' ? null : (
          <>
            <Hidden only={['xs', 'sm']} className={classes.hide}>
              <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                <div className={classes.drawerContainer}>
                  <Sidebar onClose={handleDrawerClose} />
                </div>
              </Drawer>
            </Hidden>
            <Hidden only={['md', 'lg', 'xl']}>
              <Drawer
                className={classes.drawerMobile}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                  paper: classes.drawerMobilePaper,
                }}
              >
                <div item style={{ padding: '12px 18px' }}>
                  {window.location.pathname === '/teams' ? null : (
                    <>{!onlyTeamSelector && <SeasonSelector />}</>
                  )}
                </div>
                <Divider />
                <div className={classes.drawerContainer}>
                  <Sidebar onClose={handleDrawerClose} />
                </div>
              </Drawer>
            </Hidden>
          </>
        )}
        <main className={classes.content}>{children}</main>
      </div>
    </div>
  )
}

NavBar.defaultProps = {
  onlyTeamSelector: false,
}

NavBar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.arrayOf(PropTypes.element.isRequired).isRequired,
  ]).isRequired,
  onlyTeamSelector: PropTypes.bool,
  match: PropTypes.shape().isRequired,
  getClubTheme: PropTypes.func.isRequired,
  currentTeam: PropTypes.shape().isRequired,
}

export default compose(
  connect(
    ({ team }) => ({
      currentTeam: team,
    }),
    {
      getClubTheme: themesModule.getClubTheme,
    }
  ),
  withRouter
)(NavBar)
