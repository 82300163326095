import React, { useState } from 'react'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { articles as articlesModule } from 'redux/modules'
import { Paper, Box, Typography, Divider } from '@material-ui/core'
import { InnrHead } from 'containers'
import { FilterByCategories } from './components'

const ArticlesAllDashboardContainer = () => {
  const [activeCategories, changeActiveCategories] = useState([])

  return (
    <Paper component={Box} mt={2}>
      <InnrHead
        title={
          <Typography variant="h4">
            <Translate id="articles.categories" />
          </Typography>
        }
        divider={<Divider />}
      />
      <Box px={3} pb={3}>
        <FilterByCategories
          activeCategories={activeCategories}
          onChangeActiveCategories={changeActiveCategories}
        />
      </Box>
    </Paper>
  )
}

ArticlesAllDashboardContainer.propTypes = {}

export default connect(
  ({ favorites }) => ({ favoritesArticles: favorites.items.articles }),
  { fetchArticles: articlesModule.fetchArticles }
)(ArticlesAllDashboardContainer)
