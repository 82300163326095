import React, { useMemo, useState } from 'react'
import { Translate } from 'react-localize-redux'
import {
  Box,
  CardContent,
  Typography,
  IconButton,
  Tooltip,
  Divider,
  CardActionArea,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import FavoriteIcon from '@material-ui/icons/Favorite'
import { SubLink } from 'containers'
import EditIcon from '@material-ui/icons/Edit'
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import fallbackImageUrl from '../../assets/images/login-coach.jpg'
import {
  MuiCard,
  CardMedia,
  CardActions,
  MuiBox,
} from './components/StyledComponents'
import TrainingPackageViewDialog from './components/TrainingPackageViewDialog'
import { useStyles } from './styles'
import AddTrainingPackageToTrainingDialog from './components/AddTrainingPackageToTrainingDialog'
import { TRAINING_PACKAGE_EDIT_ROUTE } from 'constants/routes'

const TrainingPackageCard = ({
  trainingPackage,
  onFavoriteToggle,
  showAddToTrainingButton,
  showAddToPhaseButton,
  onTrainingPackageSelect,
  showTrainingPackageActions,
}) => {
  const classes = useStyles()
  const [trainingPackageViewDialogOpen, setTrainingPackageViewDialogOpen] =
    useState(false)
  const [addToTrainingModalOpen, setAddToTrainingModalOpen] = useState(false)

  const canUpdate = trainingPackage.can.includes('update')

  const toggleTrainingPackageViewDialog = () =>
    setTrainingPackageViewDialogOpen(!trainingPackageViewDialogOpen)

  const imageUrl =
    trainingPackage.relationships.images.length > 0
      ? trainingPackage.relationships.images[
          trainingPackage.relationships.images.length - 1
        ].full_url
      : fallbackImageUrl

  const calculateTotalDuration = () => {
    const drills = trainingPackage.relationships
    const sumDurations = (tempDrills) => {
      return tempDrills.reduce(
        (total, drill) => total + (drill.duration || 0),
        0
      )
    }
    const totalDuration =
      sumDurations(drills.main_part_drills || []) +
      sumDurations(drills.warmup_drills || []) +
      sumDurations(drills.final_part_drills || [])

    return totalDuration
  }
  const toggleAddToTrainingDialog = () => {
    setAddToTrainingModalOpen(!addToTrainingModalOpen)
  }

  const totalDuration = useMemo(calculateTotalDuration, [
    trainingPackage.relationships,
  ])

  const handleAddToPhase = (e) => {
    e.stopPropagation()
    onTrainingPackageSelect(trainingPackage.uuid)
  }

  return (
    <>
      {trainingPackageViewDialogOpen && (
        <TrainingPackageViewDialog
          trainingPackage={trainingPackage}
          handleDialogOpen={toggleTrainingPackageViewDialog}
          open={trainingPackageViewDialogOpen}
          duration={totalDuration}
        />
      )}
      {addToTrainingModalOpen && (
        <AddTrainingPackageToTrainingDialog
          open={addToTrainingModalOpen}
          handleClose={toggleAddToTrainingDialog}
          trainingPackage={trainingPackage}
        />
      )}
      <MuiCard>
        <CardActionArea onClick={toggleTrainingPackageViewDialog}>
          <CardMedia image={imageUrl} />
          <CardContent>
            <Typography gutterBottom variant="h4" component="h4" noWrap>
              {trainingPackage.title}
            </Typography>
            <MuiBox display="flex">
              <Box>
                <Typography>
                  <Translate id="training.duration" />
                </Typography>
                {totalDuration} min
              </Box>
              <Box>
                <Typography>
                  <Translate id="drills.players" /> :
                </Typography>
                {trainingPackage.number_of_players}
              </Box>
            </MuiBox>
          </CardContent>
        </CardActionArea>
        <Divider />
        <CardActions>
          <Box>
            {showAddToTrainingButton && (
              <Tooltip title={<Translate id="training.add-to-training" />}>
                <IconButton
                  onClick={toggleAddToTrainingDialog}
                  size="small"
                  color="primary"
                >
                  <AddCircleRoundedIcon />
                </IconButton>
              </Tooltip>
            )}
            {showAddToPhaseButton && (
              <Tooltip title={<Translate id="training.add-to-training" />}>
                <IconButton
                  onClick={handleAddToPhase}
                  size="small"
                  color="primary"
                >
                  <AddCircleIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          {showTrainingPackageActions && (
            <>
              {canUpdate && (
                <IconButton
                  size="small"
                  component={SubLink}
                  className={classes.editDrillIcon}
                  to={TRAINING_PACKAGE_EDIT_ROUTE.populateURLParams({
                    trainingPackageUuid: trainingPackage.uuid,
                  })}
                >
                  <Tooltip title={<Translate id="button.edit" />}>
                    <EditIcon fontSize="small" />
                  </Tooltip>
                </IconButton>
              )}
            </>
          )}
          <Tooltip title={<Translate id="drills.favorite" />}>
            <IconButton onClick={onFavoriteToggle} size="small">
              <FavoriteIcon
                color={trainingPackage.favorite ? 'primary' : 'inherit'}
                fontSize="small"
              />
            </IconButton>
          </Tooltip>
        </CardActions>
      </MuiCard>
    </>
  )
}

TrainingPackageCard.propTypes = {
  trainingPackage: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    number_of_players: PropTypes.number.isRequired,
    favorite: PropTypes.bool.isRequired,
    can: PropTypes.arrayOf(PropTypes.string),
    relationships: PropTypes.shape({
      images: PropTypes.arrayOf(
        PropTypes.shape({
          full_url: PropTypes.string.isRequired,
        })
      ).isRequired,
      main_part_drills: PropTypes.arrayOf(
        PropTypes.shape({
          duration: PropTypes.number.isRequired,
        })
      ).isRequired,
      warmup_drills: PropTypes.arrayOf(
        PropTypes.shape({
          duration: PropTypes.number.isRequired,
        })
      ).isRequired,
      final_part_drills: PropTypes.arrayOf(
        PropTypes.shape({
          duration: PropTypes.number.isRequired,
        })
      ).isRequired,
    }).isRequired,
  }).isRequired,
  onFavoriteToggle: PropTypes.func.isRequired,
  showAddToTrainingButton: PropTypes.bool.isRequired,
  showAddToPhaseButton: PropTypes.bool.isRequired,
  onTrainingPackageSelect: PropTypes.func.isRequired,
  showTrainingPackageActions: PropTypes.func.isRequired,
}

export default TrainingPackageCard
