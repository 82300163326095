import React, { useState, useEffect, cloneElement } from 'react'
import PropTypes from 'prop-types'
import PROP_TYPES from 'constants/propTypes'

const Loader = props => {
  const {
    children,
    alwaysRenderChildren,
    passIsLoading,
    parentIsLoading,
    fetchMethod,
    triggers = [],
    additionalFields = [],
  } = props

  const [isLoading, changeIsLoading] = useState(true)

  useEffect(() => {
    const params = {
      fields: additionalFields.join(','),
    }

    fetchMethod(params).then(() => changeIsLoading(false))
  }, triggers)

  if ((!isLoading || alwaysRenderChildren) && !parentIsLoading) {
    return !passIsLoading
      ? children
      : cloneElement(children, { parentIsLoading: isLoading })
  }

  return <div />
}

Loader.defaultProps = {
  alwaysRenderChildren: false,
  passIsLoading: false,
  parentIsLoading: false,
  triggers: [],
  additionalFields: [],
}

Loader.propTypes = {
  children: PROP_TYPES.children.isRequired,
  alwaysRenderChildren: PropTypes.bool,
  passIsLoading: PropTypes.bool,
  parentIsLoading: PropTypes.bool,
  fetchMethod: PropTypes.func.isRequired,
  triggers: PropTypes.arrayOf(PropTypes.any.isRequired),
  additionalFields: PropTypes.arrayOf(PropTypes.string.isRequired),
}

export default Loader
