import React from 'react'
import { FavoritesLoader, PlayersLoader } from 'loaders'
import FavoritesContainer from './FavoritesContainer'
import PROP_TYPES from 'constants/propTypes'

const Favorites = ({
  match: {
    params: { teamId },
  },
}) => (
  <FavoritesLoader entity="articles" alwaysRenderChildren>
    <FavoritesLoader entity="drills" alwaysRenderChildren>
      <FavoritesLoader entity="trainingPackages" alwaysRenderChildren>
        <FavoritesLoader entity="videos" alwaysRenderChildren>
          <FavoritesLoader entity="sequences" alwaysRenderChildren>
            <PlayersLoader teamId={+teamId} alwaysRenderChildren>
              <FavoritesContainer />
            </PlayersLoader>
          </FavoritesLoader>
        </FavoritesLoader>
      </FavoritesLoader>
    </FavoritesLoader>
  </FavoritesLoader>
)

Favorites.propTypes = {
  match: PROP_TYPES.match.isRequired,
}

export default Favorites
