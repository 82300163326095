import React from 'react'
import { connect } from 'react-redux'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { HeartIcon, SubLink } from 'containers'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {
  Grid,
  Card,
  CardActionArea,
  CardActions,
  CardMedia,
  Typography,
} from '@material-ui/core'
import { PlayIcon, ProcessingIcon } from 'components'
import { VIDEO_ROUTE } from 'constants/routes'
import PROP_TYPES from 'constants/propTypes'

const MuiCard = withStyles(theme => ({
  root: {
    border: '1px solid #e8e8e8',
    '& .MuiCardContent-root': {
      padding: theme.spacing(2, 2, 0),
    },
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
}))(Card)

const useStyles = makeStyles(theme => ({
  [theme.breakpoints.up('xl')]: {
    root: {
      '& .MuiGrid-grid-xl-2': {
        maxWidth: '25%',
        flexBasis: '25%',
      },
    },
  },
  media: {
    height: 134,
  },
  cardActions: {
    paddingLeft: theme.spacing(2),
  },
  title: {
    flex: '1 1 0',
  },
  PlayCircle: {
    position: 'absolute',
    top: '27%',
    height: 65,
    width: 65,
    padding: 0,
    backgroundColor: '#fff',
    borderRadius: '50%',
    left: theme.spacing(0),
    right: theme.spacing(0),
    margin: theme.spacing(0, 'auto'),
    '& .MuiSvgIcon-root': {
      fontSize: 67,
    },
  },
}))

const VideosList = ({ videos, relation, favoritesVideos }) => {
  const droppableId = `${relation}`
  const classes = useStyles()
  const getIsFavorite = id => Boolean(favoritesVideos.findById(id))

  return (
    <Droppable droppableId={droppableId} isDropDisabled>
      {provided => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          <Grid container spacing={2}>
            {videos.map(
              (
                {
                  id,
                  thumbnail_url: thumbnail,
                  processed,
                  error_details: errorDetails,
                  title,
                },
                index
              ) => (
                <Draggable key={id} draggableId={`${id}`} index={index}>
                  {providedDraggable => (
                    <>
                      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <MuiCard
                          ref={providedDraggable.innerRef}
                          {...providedDraggable.draggableProps}
                          {...providedDraggable.dragHandleProps}
                        >
                          <CardActionArea>
                            <CardMedia
                              className={classes.media}
                              ref={providedDraggable.innerRef}
                              {...providedDraggable.draggableProps}
                              {...providedDraggable.dragHandleProps}
                              image={processed === 1 && thumbnail}
                              processed={processed}
                            />
                            {processed === 1 ? (
                              <CardActionArea
                                className={classes.PlayCircle}
                                color="primary"
                                component={SubLink}
                                to={VIDEO_ROUTE.populateURLParams({
                                  videoId: id,
                                })}
                              >
                                <PlayIcon />
                              </CardActionArea>
                            ) : (
                              <ProcessingIcon
                                placeholder={processed === -1 && errorDetails}
                              />
                            )}
                          </CardActionArea>

                          <CardActions className={classes.cardActions}>
                            <Typography
                              gutterBottom
                              className={classes.title}
                              variant="h4"
                              component="h4"
                              noWrap
                            >
                              {title}
                            </Typography>

                            {processed === 1 ? (
                              <HeartIcon
                                isActive={getIsFavorite(id)}
                                entity="videos"
                                entityId={id}
                              />
                            ) : (
                              <ProcessingIcon
                                placeholder={processed === -1 && errorDetails}
                              />
                            )}
                          </CardActions>
                        </MuiCard>
                      </Grid>
                    </>
                  )}
                </Draggable>
              )
            )}
            {provided.placeholder}
          </Grid>
        </div>
      )}
    </Droppable>
  )
}

VideosList.defaultProps = {
  videos: [],
  favoritesVideos: [],
}

VideosList.propTypes = {
  videos: PROP_TYPES.arrayOfObjects,
  relation: PROP_TYPES.string.isRequired,
  favoritesVideos: PROP_TYPES.arrayOfObjects,
}

export default connect(({ favorites }) => ({
  favoritesVideos: favorites.items.videos,
}))(VideosList)
