import React from 'react'
import { DrillsLoader, AgeClassesLoader } from 'loaders'
import TrainingPackageCreateContainer from './TrainingPackageCreateContainer'
import PROP_TYPES from 'constants/propTypes'

const TrainingPackageCreate = ({ history, match, location }) => (
  <DrillsLoader>
    <AgeClassesLoader>
      <TrainingPackageCreateContainer {...{ history, match, location }} />
    </AgeClassesLoader>
  </DrillsLoader>
)

TrainingPackageCreate.propTypes = {
  history: PROP_TYPES.history.isRequired,
  match: PROP_TYPES.match.isRequired,
  location: PROP_TYPES.location.isRequired,
}

export default TrainingPackageCreate
