/* eslint-disable react/no-danger */
import React from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { Collapsible } from 'components'
import { Grid, Box, DialogContent, Hidden } from '@material-ui/core'
import getVideoId from 'get-video-id'
import ImagesSlider from '../ImagesSlider'

const DrillBody = ({ drill }) => {
  const {
    uuid,
    description,
    images,
    drill_flow: drillFlow,
    organization,
    coaching_points: coachingPoints,
    variations,
    video_url: videoUrl,
  } = drill

  const getVideoUrl=(tempurl) => {
    const a= getVideoId(tempurl)
    let url = "";
    if (a.service ==="youtube") {
      url = `https://www.youtube.com/embed/${a.id}`
    }else{
      url =  `https://player.vimeo.com/video/${a.id}`
    }

    return url
  }
  const showVideo =
    videoUrl !== null ? (
      <Box mt={1}>
        <iframe
          width="100%"
          height="300px"
          title="coachbetter Video"
          src={getVideoUrl(videoUrl)}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </Box>
    ) : (
      ''
    )

  return (
    <DialogContent dividers>
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          {description !== '' && description && (
            <>
              <p dangerouslySetInnerHTML={{ __html: description }} />
            </>
          )}
          <Translate>
            {({ translate }) => {
              return (
                <>
                  {drillFlow !== '' && drillFlow && (
                    <>
                      <Collapsible title={translate('drills.drill-flow')}>
                        <>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: drillFlow,
                            }}
                          />
                        </>
                      </Collapsible>
                    </>
                  )}

                  {organization !== '' && organization && (
                    <>
                      <Collapsible title={translate('drills.organization')}>
                        <>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: organization,
                            }}
                          />
                        </>
                      </Collapsible>
                    </>
                  )}

                  {coachingPoints !== '' && coachingPoints && (
                    <>
                      <Collapsible title={translate('drills.coaching-points')}>
                        <>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: coachingPoints,
                            }}
                          />
                        </>
                      </Collapsible>
                    </>
                  )}

                  {variations !== '' && variations && (
                    <>
                      <Collapsible title={translate('drills.variations')}>
                        <>
                          <p dangerouslySetInnerHTML={{ __html: variations }} />
                        </>
                      </Collapsible>
                    </>
                  )}
                </>
              )
            }}
          </Translate>

          {showVideo}
        </Grid>
        <Hidden only={['xs', 'sm']}>
          <Grid item md={6} xs={12}>
            {images.length > 0 && <ImagesSlider images={images} />}
          </Grid>
        </Hidden>
      </Grid>
    </DialogContent>
  )
}
DrillBody.defaultProps = {}
DrillBody.propTypes = {
  drill: PropTypes.shape().isRequired,
}

export default DrillBody
