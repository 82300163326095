import React from 'react'
import PropTypes from 'prop-types'
import { GridToolbarContainer, GridToolbarExport} from '@mui/x-data-grid'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingRight: theme.spacing(0),
    position: 'absolute',
    right: 14,
    top: -53
  },
  gridToolbarExport: {
   '& .MuiButton-root': {
      fontFamily: 'Open Sans !important',
      padding: '0 !important',
      color: '#fff !important',
      lineHeight:'1.70 !important',
      fontWeight: '600 !important'
    }
  }

}))

const DataGridExportToolbar = (props) => {
  const {csvOptions} = props
  const classes = useToolbarStyles()

  return (
    <GridToolbarContainer className={classes.root}>
      <Button variant="contained" className={classes.gridToolbarExport} color="primary" role="presentation">
        <GridToolbarExport  printOptions={{ disableToolbarButton: true }} csvOptions={csvOptions}/>
      </Button>
    </GridToolbarContainer>
  );
}
DataGridExportToolbar.defaultProps = {}

DataGridExportToolbar.propTypes = {
  csvOptions: PropTypes.shape().isRequired,
}
export default DataGridExportToolbar