import React from 'react'
import { Box, Typography, IconButton, Grid } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { useStyles } from '../styles'

const PhaseSection = ({
  phase,
  phaseName,
  drills,
  onRemoveDrill,
  onUpdateDrill,
}) => {
  const classes = useStyles()

  return (
    <Box key={phase} marginBottom={2}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Typography variant="body2" className={classes.textBold}>
          {phaseName}
        </Typography>
      </Grid>
      {drills.length > 0 ? (
        drills.map((drill) => (
          <Box
            display="flex"
            alignItems="center"
            key={drill.trainingDrillUuid}
            marginBottom={1}
            className={classes.sessionDrillRow}
            p={1}
          >
            <Box width={3 / 5}>
              <Typography variant="body2" noWrap>
                {drill.title}
              </Typography>
            </Box>
            <Box marginLeft="auto">
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <IconButton
                    onClick={() =>
                      onUpdateDrill(
                        phase,
                        drill.trainingDrillUuid,
                        drill.customized_duration - 1
                      )
                    }
                    size="small"
                  >
                    <RemoveIcon fontSize="small" />
                  </IconButton>
                </Grid>
                <Typography variant="body2">
                  {drill.customized_duration} mins
                </Typography>
                <Grid item>
                  <IconButton
                    onClick={() =>
                      onUpdateDrill(
                        phase,
                        drill.trainingDrillUuid,
                        drill.customized_duration + 1
                      )
                    }
                    size="small"
                  >
                    <AddIcon fontSize="small" />
                  </IconButton>
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    onClick={() =>
                      onRemoveDrill(phase, drill.trainingDrillUuid)
                    }
                    size="small"
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          </Box>
        ))
      ) : (
        <Box className={classes.sessionDrillRow} p={1} textAlign="center">
          <Typography variant="body2">
            <Translate id="training.no-activity-added" />
          </Typography>
        </Box>
      )}
    </Box>
  )
}

PhaseSection.propTypes = {
  phase: PropTypes.string.isRequired,
  phaseName: PropTypes.node.isRequired,
  drills: PropTypes.arrayOf(
    PropTypes.shape({
      trainingDrillUuid: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      customized_duration: PropTypes.number.isRequired,
    })
  ).isRequired,
  onRemoveDrill: PropTypes.func.isRequired,
  onUpdateDrill: PropTypes.func.isRequired,
}

export default PhaseSection
