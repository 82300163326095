import React from 'react'
import { Translate } from 'react-localize-redux'
import { SubLink } from 'containers'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Grid, Tabs, Tab, Button, Toolbar, Box } from '@material-ui/core'
import hasSubscription from 'utils/hasSubscription'
import TeaserTagOverlay from 'components/Teaser/TeaserTagOverlay'
import { usePermission } from '../../utils'
import { BASIC, PLUS } from '../../constants/plans'
import { NewArticles, MyArticles } from './components'
import ArticlesAllDashboardContainer from './ArticlesAllDashboardContainer'
import { ARTICLE_CREATE_ROUTE, ARTICLES_CATEGORIES } from 'constants/routes'
import PERMISSIONS from 'constants/permissions'
import PROP_TYPES from 'constants/propTypes'

const useStyles = makeStyles((theme) => ({
  toolbar: {
    minHeight: 54,
  },
  button: {
    margin: theme.spacing(0, 1),
  },

  title: {
    flex: '1 1 0',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

const ArticlesDashboardContainer = ({ activeLocale }) => {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const canManageCategories = usePermission(PERMISSIONS.CREATE_ARTICLE_CATEGORY)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Toolbar
            disableGutters="false"
            component={Paper}
            className={classes.toolbar}
          >
            <div className={classes.title}>
              <Tabs
                value={value}
                className={classes.title}
                onChange={handleChange}
                indicatorColor="primary"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label={<Translate id="articles.all-articles" />} />
                {!(hasSubscription() === BASIC) && (
                  <Tab label={<Translate id="articles.my-article" />} />
                )}
              </Tabs>
            </div>
            {canManageCategories && (
              <Button
                variant="contained"
                color="primary"
                component={SubLink}
                className={classes.button}
                to={ARTICLES_CATEGORIES}
              >
                <Translate id="articles.manage-categories" />
              </Button>
            )}

            <Box>
              <TeaserTagOverlay tagLabel={PLUS}>
                <Button
                  variant="contained"
                  color="primary"
                  component={SubLink}
                  className={classes.button}
                  to={ARTICLE_CREATE_ROUTE}
                >
                  <Translate id="articles.create-content" />
                </Button>
              </TeaserTagOverlay>
            </Box>
          </Toolbar>
        </Grid>
      </Grid>

      {value === 0 && (
        <>
          <ArticlesAllDashboardContainer activeLocale={activeLocale} />
          <NewArticles activeLocale={activeLocale} />
        </>
      )}
      {value === 1 && <MyArticles activeLocale={activeLocale} />}
    </>
  )
}

ArticlesDashboardContainer.propTypes = {
  activeLocale: PROP_TYPES.string.isRequired,
}

export default ArticlesDashboardContainer
