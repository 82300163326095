import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'

const useStyles = makeStyles(theme => ({
  icon: {
    left: 0,
    right: 0,
    width: 65,
    height: 65,
    cursor: 'pointer',
    margin: theme.spacing(0, 'auto'),
  },
}))

const PlayIcon = ({ fontSize = '', color = '', onClick }) => {
  const classes = useStyles()

  return (
    <PlayCircleOutlineIcon
      color={color}
      fontSize={fontSize}
      onClick={onClick}
      className={classes.icon}
    />
  )
}

PlayIcon.defaultProps = {
  fontSize: 'large',
  color: 'primary',
}

PlayIcon.propTypes = {
  fontSize: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}

export default PlayIcon
