import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { MuiGrid } from './StyledComponents'
import DrillPanel from './DrillPanel'

const TrainingPackageDialogBody = ({ trainingPackage }) => {
  const drillSections = [
    {
      drillList: trainingPackage.relationships.warmup_drills,
      sectionName: 'Warmup',
    },
    {
      drillList: trainingPackage.relationships.main_part_drills,
      sectionName: 'Main Part',
    },
    {
      drillList: trainingPackage.relationships.final_part_drills,
      sectionName: 'Final Part',
    },
  ]

  return (
    <Grid container>
      <MuiGrid item md={12} xs={12}>
        {drillSections.map((section, index) => (
          <DrillPanel
            key={section.sectionName}
            drillList={section.drillList}
            sectionName={section.sectionName}
            sectionIndex={index + 1}
          />
        ))}
      </MuiGrid>
    </Grid>
  )
}

TrainingPackageDialogBody.propTypes = {
  trainingPackage: PropTypes.shape({
    relationships: PropTypes.shape({
      warmup_drills: PropTypes.arrayOf(PropTypes.shape()).isRequired,
      main_part_drills: PropTypes.arrayOf(PropTypes.shape()).isRequired,
      final_part_drills: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    }).isRequired,
  }).isRequired,
}

export default TrainingPackageDialogBody
