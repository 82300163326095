import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { auth as authModule } from 'redux/modules'
import urlConstructor from 'utils/urlConstructor'
import { compose } from 'redux'
import { TEAMS_ROUTE } from '../../constants/routes'
import PERMISSIONS from '../../constants/permissions'
import PROP_TYPES from 'constants/propTypes'

const FirstEntryRouterContainer = ({
  history,
  teams,
  seasons,
  fetchLoginUserDetails,
  authUser,
  permissions
}) => {


  const firstTeam = teams.items.find(Boolean)
  const firstSeason = seasons.items[firstTeam.id].find(Boolean)

  const d = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate()
  )


  const firstActiveSeason = seasons.items[firstTeam.id].filter(
    season => d >= new Date(season.start_date) && d <= new Date(season.end_date)
  ).find(Boolean)

  if (!firstActiveSeason && !firstSeason) {
    return <div />
  }

  React.useEffect(()=>{

    if(!authUser || !authUser.first_name || !authUser.time_zone || !authUser.datetime_format){
      fetchLoginUserDetails()
    }

    if (teams.items.length > 1) {
      history.push(TEAMS_ROUTE)
    } else {
      const firstTeamPermissions = permissions.teams.find(item => item.id === firstTeam.id)?.permissions ?? []
      const canManageFirstTeam = firstTeamPermissions.includes(PERMISSIONS.UPDATE_TEAM)

      // Redirect to first team dashboard if the user has enough permissions,
      // otherwise redirect to teams selector screen.
      if (canManageFirstTeam) {
        history.push(urlConstructor(firstTeam.id, firstActiveSeason ? firstActiveSeason.id : firstSeason.id))
      } else {
        history.push(TEAMS_ROUTE)
      }
    }
  },[])

  return null
}

FirstEntryRouterContainer.propTypes = {
  history: PROP_TYPES.history.isRequired,
  teams: PropTypes.shape().isRequired,
  seasons: PropTypes.shape().isRequired,
  fetchLoginUserDetails: PropTypes.func.isRequired,
  authUser: PropTypes.shape().isRequired,
  permissions: PropTypes.arrayOf.isRequired
}

export default compose( 
  withRouter,
  connect(({ teams, seasons, auth }) => ({
    teams,
    seasons,
    authUser: auth.user,
    permissions: auth.permissions
  }),{
  fetchLoginUserDetails:authModule.fetchUser
})
)(FirstEntryRouterContainer)
