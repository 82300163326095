import React from 'react'
import { Box, Link } from '@material-ui/core'

const StaffEmailClickable = (params) => {

    return <Box display="flex" alignItems="center">
        <Link href={`mailto:${params.row.email}`}>{params.row.email}</Link>
    </Box>

}

export default StaffEmailClickable

