export const match = {
  match: 'match',
  match_create_popup: 'Match Create Popup',
  match_duplicate_popup: 'Match Duplicate Popup',
  match_duplicate: 'Match Duplicate',
}

export const training = {
  training: 'training',
  training_create_popup: 'Training Create Popup',
  training_duplicate_popup: 'Training Duplicate Popup',
  training_duplicate: 'Training Duplicate',
  training_onboarding_video_played: 'Training Video Played',
  training_onboarding_video_closed: 'Training Video Popup Closed',
}

export const team = {
  team: 'team',
  team_players_import: 'Team Players Bulk Upload',
  team_players_invite_link_copy:'Team Players Invite Link Copied',
}

export const user = {
  user: 'user',
}

export const video = {
  video: 'video',
}

export const status = {
  initial: 'initialized',
  complete: 'completed',
}
