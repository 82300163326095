import React from 'react'
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import useStyles from './styles'

const PlayerUnpauseDialog = ({ open, handleOpenClose, onSubmit }) => {
  const classes = useStyles()

  return (
    <Dialog
      open={open}
      onClose={handleOpenClose}
      fullWidth
      aria-labelledby="dialog-title"
    >
      <Box py={2} px={3}>
        <Typography variant="h4">
          <Translate id="player.unpause-player" />
        </Typography>
      </Box>
      <DialogContent className={classes.sectionPadding}>
        <Translate id="player.unpause-confirm" />
      </DialogContent>
      <DialogActions className={classes.sectionPadding}>
        <Button variant="contained" onClick={handleOpenClose} type="button">
          <Translate id="button.close" />
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
          type="button"
        >
          <Translate id="button.confirm" />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

PlayerUnpauseDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleOpenClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default PlayerUnpauseDialog
