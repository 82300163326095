import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import Grid from '@material-ui/core/Grid'
import groupPlayersByPosition from 'utils/groupPlayersByPosition'
import PlayersTable from 'components/PlayersCardList/PlayersTable'
import PlayersListCard from './PlayersListCard'
import { useRootStyles } from '../styles'
import PropTypes from 'prop-types'

const EventPlayers = ({ participants }) => {
  const classes = useRootStyles()

  const { goalkeepers, defenders, midfielders, strikers } =
    groupPlayersByPosition(
      participants.map((p) => {
        return {
          ...p.relationships.player,
          availability: p.availability,
          tag: p.tag,
          eventPlayerUuid: p.uuid,
          reason: p.reason,
        }
      })
    )

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item md={6} xs={12} sm={12} lg={6}>
        {goalkeepers && (
          <PlayersListCard title={<Translate id="matches.goalkeeper" />}>
            <PlayersTable eventPlayers={goalkeepers} />
          </PlayersListCard>
        )}

        {defenders && (
          <PlayersListCard title={<Translate id="matches.defender" />}>
            <PlayersTable eventPlayers={defenders} />
          </PlayersListCard>
        )}

        {midfielders && (
          <PlayersListCard title={<Translate id="matches.midfield" />}>
            <PlayersTable eventPlayers={midfielders} />
          </PlayersListCard>
        )}
        {strikers && (
          <PlayersListCard title={<Translate id="matches.striker" />}>
            <PlayersTable eventPlayers={strikers} />
          </PlayersListCard>
        )}
      </Grid>
    </Grid>
  )
}

EventPlayers.propTypes = {
  participants: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
}

export default compose(
  connect(({ generalEvents: { current } }) => ({
    participants: current.relationships.participants,
  }))
)(EventPlayers)
