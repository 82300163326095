import React from 'react'
import { styled } from '@material-ui/core/styles'
import SubLink from '../containers/SubLink'
import AddButton from './buttons/AddButton'
import PROP_TYPES from 'constants/propTypes'

const Container = styled('div')({
  position: 'relative',
})

const NextCalendarEventWidget = ({ children }) => (
  <Container>{children}</Container>
)

NextCalendarEventWidget.propTypes = {
  children: PROP_TYPES.children.isRequired,
}

NextCalendarEventWidget.Title = styled('h3')({
  marginBottom: '30px',
  fontSize: '20px',
  textAlign: 'center',
})

NextCalendarEventWidget.Placeholder = styled('p')({
  textAlign: 'center',
  width: '100%',
  height: '120px',
})

NextCalendarEventWidget.Calendar = styled(SubLink)({
  '& > div': {
    margin: '0 auto 10px',
    width: '115px',
    textAlign: 'center',
    boxShadow: '-1px 1px 5px 2px rgba(128, 128, 128, 0.5)',
    background: 'transparent',
  },
})

NextCalendarEventWidget.CalendarTop = styled('div')({
  border: '6px solid var(--primary-green)',
  padding: '13px 15px',
})

NextCalendarEventWidget.CalendarBottom = styled('div')({
  fontSize: '15px',
  padding: '5px',
  lineHeight: '1.1',
  color: 'black',
})

// TODO: used in other widgets on the dashboard
NextCalendarEventWidget.CircleNumber = styled('div')((props) => ({
  fontSize: '30px',
  borderBottom: '1px solid',
  borderColor: props.color || 'black',
  lineHeight: '1',
  textShadow: '0 0 5px rgba(128, 128, 128, 0.7)',
  color: props.color || 'black',
}))

NextCalendarEventWidget.CircleTitle = styled('div')((props) => ({
  fontSize: '15px',
  lineHeight: '2',
  color: props.color || 'black',
}))

NextCalendarEventWidget.AddButton = styled(AddButton)({
  position: 'absolute',
  width: '35px',
  height: '35px',
  borderRadius: '50%',
  left: '85%',
  top: '85%',
  boxShadow: '2px 2px 7px 1px rgba(0, 0, 0, 0.5)',
})

export default NextCalendarEventWidget