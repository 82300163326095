import { combineActions, handleActions } from 'redux-actions'
import {
  actionsTypesGenerator,
  onRequest,
  onFailure,
  onSuccess,
} from 'utils/reduxHelpers'
import initialState from '../initialState'

const namespace = 'SEQUENCE'

const FETCH_SEQUENCES = actionsTypesGenerator(`FETCH_${namespace}S`)
const UPDATE_SEQUENCE = actionsTypesGenerator(`UPDATE_${namespace}`)
const SHARE_SEQUENCE = actionsTypesGenerator(`SHARE_${namespace}`)
const DELETE_SEQUENCE = actionsTypesGenerator(`DELETE_${namespace}`)

export const fetchSequences = (paramsString = '', params) => ({
  types: FETCH_SEQUENCES,
  callAPI: {
    method: 'GET',
    entity: 'Sequences',
    path: '/sequences',
    params,
    paramsString,
  },
})

export const updateSequence = (id, data) => ({
  types: UPDATE_SEQUENCE,
  callAPI: {
    method: 'PUT',
    entity: 'Sequence',
    path: `/sequences/${id}`,
    data,
  },
  payload: { id },
})

export const shareSequence = (id, paramsString) => ({
  types: SHARE_SEQUENCE,
  callAPI: {
    method: 'SHARE',
    entity: 'Sequence',
    path: `/sequences/${id}/share`,
    paramsString,
  },
})

export const deleteSequence = id => ({
  types: DELETE_SEQUENCE,
  callAPI: {
    method: 'DELETE',
    entity: 'Sequence',
    path: `/sequences/${id}`,
  },
  payload: { id },
})

export default handleActions(
  {
    [combineActions(
      FETCH_SEQUENCES.request,
      UPDATE_SEQUENCE.request,
      DELETE_SEQUENCE.request
    )]: onRequest,
    [FETCH_SEQUENCES.success]: (state, { response: { data: items = [] } }) =>
      onSuccess({ ...state, items }),
    [UPDATE_SEQUENCE.success]: (state, { response: { data = {} }, id }) => {
      const items = state.items.updateById(id, data)

      return onSuccess({
        ...state,
        items,
      })
    },
    [DELETE_SEQUENCE.success]: (state, { id }) => {
      const items = state.items.filterById(id)

      return onSuccess({ ...state, items })
    },
    [combineActions(
      FETCH_SEQUENCES.failure,
      UPDATE_SEQUENCE.failure,
      DELETE_SEQUENCE.failure
    )]: onFailure,
  },
  initialState.sequences
)
