import React from 'react'
import TeamDashboardContainer from 'containers/TeamDashboard'
import PROP_TYPES from 'constants/propTypes'

const TeamDashboard = ({ history, changeTab }) => (
  <TeamDashboardContainer history={history} changeTab={changeTab} />
)

TeamDashboard.propTypes = {
  history: PROP_TYPES.history.isRequired,
  changeTab: PROP_TYPES.func.isRequired,
}

export default TeamDashboard
