import { styled } from '@material-ui/core/styles'
import {
  Box as MuiBox,
  Typography as MuiTypography,
  Fab as MuiFab,
  Button as MuiButton,
} from '@material-ui/core'
import BackgroundTeaser from 'assets/images/teasers-bg.png'

export const Teaser = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 10,
  cursor: 'not-allowed',
  margin: theme.spacing(0),
  paddingRight: theme.spacing(1),
  background: `url(${BackgroundTeaser}) repeat-x`,
}))

export const TeaserLabel = styled(MuiBox)(({ theme }) => ({
  margin: theme.spacing(0),
  width: 75,
  height: 33,
  position: 'relative',
  color: theme.palette.background.paper,
  top: -11,
  left: -29,
  transform: 'rotate(-42deg)',
  background: `linear-gradient(${theme.palette.secondary.main}, ${theme.palette.primary.main})`,
}))

export const TeaserIcon = styled(MuiBox)(({ theme }) => ({
  margin: theme.spacing('auto', 0, 'auto', 'auto'),
  color: 'rgb(118 118 118)',
}))

export const Text = styled(MuiTypography)(({ theme }) => ({
  margin: theme.spacing(2.3, 'auto', 0),
  letterSpacing: 1,
  fontSize: '10px !important',
  fontWeight: 600,
  color: theme.palette.background.paper,
  position: 'absolute',
  left: 0,
  right: 0,
  textAlign: 'center',
  textTransform: 'uppercase !important',
}))

export const TeaserBox = styled(MuiBox)(({ theme }) => ({
  margin: theme.spacing(0),
  width: '100%',
  position: 'relative',
  overflow: 'hidden',
}))

export const TeaserHead = styled(MuiBox)(({ theme }) => ({
  padding: theme.spacing(1.4, 2),
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  position: 'relative',
  border: '1px dashed',
  borderColor: theme.palette.background.paper,
  color: theme.palette.background.paper,
  fontWeight: 600,
  background: '#7BBFA3',
  borderRadius: theme.spacing(1),
  margin: theme.spacing(0, 0, 2, 'auto'),
}))

export const TeaserFab = styled(MuiFab)(({ theme }) => ({
  padding: theme.spacing(0.8, 0.7),
  borderRadius: theme.spacing(1),
  height: 40,
  minWidth: 40,
  marginRight: theme.spacing(1),
  color: theme.palette.background.paper,
}))

export const TeaserButton = styled(MuiButton)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  background: theme.palette.background.paper,
  color: theme.palette.primary.main,
  margin: theme.spacing(0, 0, 0, 'auto'),
  padding: theme.spacing(1, 2),
}))

