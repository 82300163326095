import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button, FormGroup } from '@material-ui/core'
import { Field, reduxForm } from 'redux-form'
import CheckboxGroup from 'components/inputes/CheckboxGroup'
import createJoiValidator from 'utils/createJoiValidator'
import Joi from 'joi'
import { Translate } from 'react-localize-redux'
import getTranslatedLabelForFormField from 'utils/getTranslatedLabelForFormField'
import useStyles from '../../styleComponents'
import ROLES_OPTIONS from 'constants/roles'

const validationSchema = Joi.object().keys({
  teamRoles: Joi.array()
    .items(Joi.string())
    .min(1)
    .required()
    .label(getTranslatedLabelForFormField('form.team-role')),
})

const RolesForm = ({ inProgress, theme, handleSubmit }) => {
  const classes = useStyles(theme)

  return (
    <Box>
      <Box mb={1}>
        <FormGroup>
          <Field
            name="teamRoles"
            component={CheckboxGroup}
            options={ROLES_OPTIONS}
          />
        </FormGroup>
      </Box>
      <Button
        onClick={handleSubmit}
        disabled={inProgress}
        fullWidth
        variant="contained"
        type="button"
        size="large"
        className={classes.button}
        mb={0.5}
      >
        <Translate id="signup.create-account" />
      </Button>
    </Box>
  )
}

RolesForm.defaultProps = {
  theme: {},
}
RolesForm.propTypes = {
  theme: PropTypes.shape(),
  handleSubmit: PropTypes.func.isRequired,
  inProgress: PropTypes.bool.isRequired,
}
export default reduxForm({
  form: 'rolesForm',
  validate: createJoiValidator(validationSchema),
})(RolesForm)
