import React, { useEffect, useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import AddIcon from '@material-ui/icons/Add'
import { Paper, Box, makeStyles, IconButton } from '@material-ui/core'
import { matches as matchesModule } from 'redux/modules'
import FieldBackground from 'assets/images/field.svg'
import PropTypes from 'prop-types'
import PlayersListPopover from './PlayersListPopover'
import PlayerFieldCard from './PlayerFieldCard'

const useStyles = makeStyles((theme) => ({
  styledField: {
    position: 'relative',
    width: '100%',
    height: '100%',
    background: `url(${FieldBackground}) no-repeat center`,
    backgroundSize: 'contain',
    margin: '0 auto',
  },
  styledPlayer: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
  },
  plusIcon: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.background.default,
    boxShadow: `0px 2px 6px ${theme.palette.grey[500]}`,
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: `0px 2px 10px ${theme.palette.grey[500]}`,
    },
  },
}))

const FieldArea = ({
  matchPlayers,
  updateMatchParticipant,
  lineupPositions,
}) => {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const [fieldPositions, setFieldPositions] = useState([])
  const [selectedPosition, setSelectedPosition] = useState()

  const handlePlusIconClick = (event, position) => {
    setAnchorEl(event.currentTarget)
    setSelectedPosition(position)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }
  const handlePlayerSelect = (position, player) => {
    setFieldPositions(
      fieldPositions.map((pos) => {
        if (pos.x === position.x && pos.y === position.y) {
          return { ...pos, player_uuid: player.uuid, player }
        }

        return pos
      })
    )
    setAnchorEl(null)

    updateMatchParticipant(player.matchPlayerUuid, {
      position_x: position.x,
      position_y: position.y,
    })
  }
  const handleClearPosition = (position) => {
    setFieldPositions(
      fieldPositions.map((pos) => {
        const temp = { ...pos }
        if (temp.x === position.x && temp.y === position.y) {
          delete temp.player
        }

        return temp
      })
    )
    updateMatchParticipant(position.uuid, {
      position_x: null,
      position_y: null,
    })
  }
  useEffect(() => {
    if (lineupPositions) {
      setFieldPositions(lineupPositions)
    }
  }, [lineupPositions])

  useEffect(() => {
    const { width } = document
      .getElementById('participantsField')
      .getBoundingClientRect()

    document.getElementById('participantsField').style.height = `${
      width * 1.613
    }px`
  }, [])

  return (
    <Paper>
      <div id="participantsField">
        <Box className={classes.styledField}>
          {fieldPositions.map((position) => (
            <Box
              key={`${position.x}${position.y}`}
              className={classes.styledPlayer}
              style={{
                left: `${position.x}%`,
                top: `${position.y}%`,
              }}
            >
              {position.matchPlayer ? (
                <PlayerFieldCard
                  handleClearPosition={handleClearPosition}
                  position={position.matchPlayer}
                />
              ) : (
                <>
                  <IconButton
                    className={classes.plusIcon}
                    onClick={(event) => handlePlusIconClick(event, position)}
                    aria-describedby={position.id}
                  >
                    <AddIcon fontSize="small" />
                  </IconButton>
                </>
              )}
            </Box>
          ))}
          {open && selectedPosition && (
            <PlayersListPopover
              open={open}
              players={matchPlayers}
              handlePlayerSelect={handlePlayerSelect}
              handlePopoverClose={handlePopoverClose}
              position={selectedPosition}
              anchorEl={anchorEl}
              handleClose={handlePopoverClose}
            />
          )}
        </Box>
      </div>
    </Paper>
  )
}

FieldArea.propTypes = {
  matchPlayers: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  lineupPositions: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  updateMatchParticipant: PropTypes.func.isRequired,
}

export default compose(
  withRouter,
  connect(
    ({ matches: { current } }) => ({
      currentMatch: current,
      matchPlayers: current.relationships.participants,
      lineup: current.relationships.lineup,
    }),
    {
      shareMatch: matchesModule.shareMatch,
      deleteMatch: matchesModule.deleteMatch,
      updateMatchParticipant: matchesModule.updateMatchParticipant,
    }
  )
)(FieldArea)
