import React from 'react'
import UserDashboard from './UserDashboard'

const User = () => (
    <UserDashboard />
)

User.propTypes = {}

export default User
