import React, { useMemo } from 'react'
import {
  Card,
  CardHeader,
  CardActionArea,
  Avatar,
  Typography,
  Box,
  CardContent,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import LogoImage from 'assets/images/coachbetter-ball.svg'
import GooglePlayLogo from 'assets/images/logos/google_play.svg'
import AppStoreLogo from 'assets/images/logos/app_store_black.svg'
import PropTypes from 'prop-types'
import PROP_TYPES from 'constants/propTypes'
import { Translate } from 'react-localize-redux'

const useStyles = makeStyles((theme) => ({
  teamCard: (props) => ({
    border: `1px solid ${props.primarycolor}`,
    borderRadius: '12px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    '& a:hover': {
      color: props.primarycolor,
    },
  }),
  cardHeader: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '1rem',
    '& .MuiCardHeader-avatar': {
      marginRight: '0',
    },
    '& .MuiAvatar-root': {
      height: '100px',
      width: '100px',
      marginBottom: '.5rem',
      marginRight: '0!important',
    },
  },
  teamCardContent: {
    display: 'flex',
    flexDirection: 'row',
    padding: '.5rem 1.5rem 3rem 1.5rem',
    justifyContent: 'space-between',
  },
  teamInfoItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '.2rem',
  },
  typographyColor: {
    color: 'black',
  },
  appStoreWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '1rem',
  },
  appStoreLink: {
    marginRight: theme.spacing(1),
  },
  appImg: {
    height: '40px',
    width: 'auto',
  },
}))

const TeamListItem = ({ team, clubTheme }) => {
  const classes = useStyles(clubTheme)

  const latestSeasonIdOfTeam = useMemo(() => {
    const d = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    )
    const activeSeason = team.relationships?.seasons.filter(
      (season) =>
        d >= new Date(season.start_date) && d <= new Date(season.end_date)
    )
    const latestSeason =
      activeSeason.length > 0
        ? activeSeason[0]
        : team.relationships?.seasons[team.relationships?.seasons.length - 1]
        
    return latestSeason?.id
  }, [team.relationships?.seasons])

  // @todo: change it to season uuid as id field will be deprecated
  const generateDashboardUrl = (id, seasonId) =>
    `/team/${id}/season/${seasonId}`

  const dashboardUrl = generateDashboardUrl(
    team.id,
    latestSeasonIdOfTeam
  )

  const userCanUpdateTeam = team.can.some(
    (permission) => permission === 'update'
  )

  return (
    <Box>
      <Card className={classes.teamCard}>
        <CardActionArea component="a" href={userCanUpdateTeam && dashboardUrl}>
          <CardHeader
            className={classes.cardHeader}
            avatar={
              <Avatar
                src={team.relationships?.logo?.full_url || LogoImage}
                alt={team.name}
              />
            }
            title={<Typography variant="h4">{team.name}</Typography>}
          />
          <CardContent className={classes.teamCardContent}>
            <Box className={classes.teamInfoItem}>
              <Typography
                noWrap
                variant="body2"
                color="textSecondary"
                component="p"
              >
                <Translate id="myteams.team-id" />
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.typographyColor}
                noWrap
              >
                {team.id}
              </Typography>
            </Box>
            <Box className={classes.teamInfoItem}>
              <Typography
                noWrap
                variant="body2"
                color="textSecondary"
                component="p"
              >
                <Translate id="myteams.age-group" />
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.typographyColor}
                noWrap
              >
                {team.relationships?.age_class?.name}
              </Typography>
            </Box>
            <Box className={classes.teamInfoItem}>
              <Typography
                noWrap
                variant="body2"
                color="textSecondary"
                component="p"
              >
                <Translate id="drills.players" />
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.typographyColor}
                noWrap
              >
                {team.players_count}
              </Typography>
            </Box>
          </CardContent>
        </CardActionArea>
        {!userCanUpdateTeam && (
          <div className={classes.appStoreWrapper}>
            <a
              className={classes.appStoreLink}
              href="https://apps.apple.com/us/app/coachbetter-player-app/id1568995326"
              disabled={userCanUpdateTeam}
            >
              <img
                className={classes.appImg}
                src={AppStoreLogo}
                alt="Download on the App Store"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.coachbetter.coachbetter"
              disabled={userCanUpdateTeam}
            >
              <img
                className={classes.appImg}
                src={GooglePlayLogo}
                alt="Get it on GooglePlay"
              />
            </a>
          </div>
        )}
      </Card>
    </Box>
  )
}

TeamListItem.propTypes = {
  team: PROP_TYPES.shape({
    name: PROP_TYPES.string.isRequired,
    logo: PROP_TYPES.string.isRequired,
    players_count: PROP_TYPES.number.isRequired,
    theme: PropTypes.oneOfType([
      PropTypes.shape().isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]).isRequired,
    id: PROP_TYPES.string.isRequired,
    ageClass: PROP_TYPES.string.isRequired,
  }).isRequired,
}

export default TeamListItem
