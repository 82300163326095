import { styled } from '@material-ui/core/styles'

export default styled('div')(({ theme, minWidth, background, color, fontSize, isActive }) => ({
  padding: '6px 12px',
  margin:  4,
  minWidth: minWidth ? `${minWidth}px` : 'auto',
  height: '26px',
  background: background || 'transparent',
  color: color || theme.palette.secondary.main,

  border: `1px solid transparent`, 
  borderRadius: '40px',

  textAlign: 'center',
  lineHeight: '10px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',

  cursor: 'pointer',

  fontSize: fontSize || '10px',

  '& #participantsField': {
    display: 'none',
  },

  ...(isActive && {
    boxShadow: '0 3px 10px 1px rgba(64, 64, 64, 0.5)',
    fontWeight: 'bold',
    fontSize: '10px',
  }),
}))
