import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Joi from 'joi'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { subscription } from 'redux/modules'
import { Translate } from 'react-localize-redux'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel'
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import { Button, Grid, Typography, Paper, Container } from '@material-ui/core'
import { reduxForm, Field } from 'redux-form'
import {
  Card,
  LinkBack,
  Input,
  ReactSelect,
  SelectMD,
  LoadingSpinner,
} from 'components'
import createJoiValidator from 'utils/createJoiValidator'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from 'utils/formatCard'
import { getCountry } from 'utils/localstorage'
import countries from 'constants/countries'

const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel)

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary)

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiExpansionPanelDetails)

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    width: '100%',
    margin: theme.spacing(0, 0, 2, 0),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  backButton: {
    float: 'right',
  },
  textRight: {
    textAlign: 'right',
    width: '100%',
  },
  textCenter: {
    textAlign: 'center',
    width: '100%',
    marginTop: 10,
    marginBottom: 10,
  },
  reducedWidth: {
    width: '50%',
  },
}))

const validationSchema = Joi.object().keys({
  cardnumber: Joi.string()
    .max(24)
    .required(),
  expiry: Joi.string()
    .max(10)
    .required(),
  cvc: Joi.number().required(),
  quantity: Joi.number().required(),
  plan: Joi.string().required(),
  city: Joi.string().required(),
  country: Joi.string().required(),
  address_1: Joi.string().required(),
  first_name: Joi.string().required(),
  last_name: Joi.string().required(),
})

const loadScript = (src, apiKey) => {
  if (!window.document.getElementById('stripe-script')) {
    const s = window.document.createElement('script')
    s.id = 'stripe-script'
    s.type = 'text/javascript'
    s.src = src
    s.onload = () => {
      window.Stripe.setPublishableKey(apiKey)
    }
    window.document.body.appendChild(s)
  }
}

const ensureCcNumberFormatting = value => formatCreditCardNumber(value)
const ensureCcExpiryFormatting = value => formatExpirationDate(value)
const ensureCcCvcFormatting = value => formatCVC(value)

const CreateCheckoutForm = ({
  title,
  planName,
  fromPath,
  handleSubmit,
  createOnlySubscription,
  apiKey,
  teamPlan,
  change,
  isCountryValid,
  changeCountyValid,
  getAllCards,
}) => {
  const [loader, changeLoader] = useState(false)

  const [expanded, setExpanded] = React.useState('panel1')

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const [stripeLoaded, setStripeLoaded] = useState(false)
  const [quantityAdded, setQuantity] = useState(1)
  const [selectedCard, changeSelectedCard] = useState(null)

  const [cards, setCards] = useState([])

  const handleSelectedCard = value => {
    changeSelectedCard(value)
  }

  const getAllcardsAPI = () => {
    changeLoader(!loader)
    getAllCards()
      .then(result => {
        const cardsOptions = result.response.data.cards.map(p => {
          const obj = {
            value: p.id,
            title: `${p.brand} | xxxx-xxxx-xxxx-${p.last4} | ${p.exp_month}/${
              p.exp_year
            }`,
          }

          return obj
        })
        setCards(cardsOptions)
        handleSelectedCard(
          cardsOptions.length > 0 ? cardsOptions[0].value : null
        )
        changeLoader(false)
        setExpanded(cardsOptions.length > 0 ? 'panel1' : 'panel2')
      })
      .catch(() => {
        changeLoader(false)
        setExpanded('panel2')
        handleSelectedCard(null)
      })
  }

  // eslint-disable-line react-hooks/exhaustive-deps
  const [country, setCountry] = React.useState(
    countries[countries.findIndex(index => index.value === getCountry())]
  )
  const changeCountry = value => {
    setCountry(value)
    setCountry(value.value)
    if (!getCountry() || getCountry() === '') {
      changeCountyValid(true)
    } else {
      changeCountyValid(false)
    }
  }
  const onChangeQty = qty => {
    if (qty.target.valueAsNumber) {
      setQuantity(qty.target.valueAsNumber)
    } else {
      setQuantity(1)
    }
  }
  const selectedPlanName =
    planName.selected === 'annual' ? `${planName.value}_annual` : planName.value
  change('plan', selectedPlanName)

  const classes = useStyles()

  useEffect(() => {
    const fetchData = () => {
      loadScript('https://js.stripe.com/v2/', apiKey)
      setStripeLoaded(true)
    }
    fetchData()

    getAllcardsAPI()
  }, [])

  return (
    <Translate>
      {({ translate }) => (
        <Card>
          <Card.Title>
            {fromPath && (
              <LinkBack to={fromPath.value} title={fromPath.title} />
            )}
          </Card.Title>
          <Card.Title>{title}</Card.Title>
          {stripeLoaded && (
            <Container maxWidth="lg">
              <Grid container spacing={2}>
                <Grid container item xs={8}>
                  <Paper className={classes.paper}>
                    <p>
                      <strong>
                        <Translate id="checkout.billing-details" />
                      </strong>
                    </p>
                    <Field
                      name="first_name"
                      placeholder={translate('form.first-name')}
                      component={Input}
                    />
                    <Field
                      name="last_name"
                      placeholder={translate('form.last-name')}
                      component={Input}
                    />
                    <Field
                      name="address_1"
                      placeholder={translate('form.address-name')}
                      component={Input}
                    />
                    <Field
                      name="city"
                      placeholder={translate('form.city')}
                      component={Input}
                    />

                    <Field
                      className="form-group"
                      placeholder={translate('form.country')}
                      component={ReactSelect}
                      suggestions={countries}
                      isMulti={false}
                      isEveryOne={false}
                      props={{
                        value: country,
                        onChange: changeCountry,
                      }}
                    />
                    {isCountryValid ? (
                      <div
                        style={{
                          color: '#dc3545',
                          fontSize: '80%',
                          width: '100%',
                          marginTop: '.25rem',
                        }}
                      >
                        {'"country" is required'}
                      </div>
                    ) : (
                      ''
                    )}
                  </Paper>
                </Grid>
                <Grid container item xs={4}>
                  <div>
                    {loader ? (
                      <LoadingSpinner />
                    ) : (
                      <>
                        {cards.length > 0 ? (
                          <>
                            <ExpansionPanel
                              square
                              expanded={expanded === 'panel1'}
                              onChange={handleChange('panel1')}
                            >
                              <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                              >
                                <Typography>
                                  <Translate id="checkout.pay-later" />
                                </Typography>
                              </ExpansionPanelSummary>
                              <ExpansionPanelDetails>
                                <Grid container >
                                  <Grid item md={12}>
                                    <Field
                                      name="position"
                                      props={{
                                        label: translate(
                                          'checkout.select-cards'
                                        ),
                                        value: selectedCard,
                                        onChange: handleSelectedCard,
                                      }}
                                      component={SelectMD}
                                      options={cards}
                                    />
                                  </Grid>
                                  <Grid item md={12}>
                                    <Button
                                      fullWidth
                                      color="primary"
                                      variant="contained"
                                      onClick={() =>
                                        createOnlySubscription({
                                          plan: selectedPlanName,
                                        })
                                      }
                                    >
                                      <Translate id="button.start-now-pay-later" />
                                    </Button>
                                  </Grid>
                                </Grid>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </>
                        ) : (
                          ''
                        )}
                      </>
                    )}

                    {cards.length > 0 ? (
                      ''
                    ) : (
                      <ExpansionPanel
                        square
                        expanded={expanded === 'panel2'}
                        onChange={handleChange('panel2')}
                      >
                        <ExpansionPanelSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="pane21d-content"
                          id="panel2d-header"
                        >
                          <Typography>
                            <Translate id="checkout.pay-later" />
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          <Button
                            fullWidth
                            color="primary"
                            variant="contained"
                            onClick={() =>
                              createOnlySubscription({ plan: selectedPlanName })
                            }
                          >
                            <Translate id="button.start-now-pay-later" />
                          </Button>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    )}
                    <ExpansionPanel
                      square
                      expanded={expanded === 'panel3'}
                      onChange={handleChange('panel3')}
                    >
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3d-content"
                        id="panel3d-header"
                      >
                        <Typography>
                          <Translate id="checkout.enter-payment-details" />
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Paper className={classes.paper}>
                          <Grid container spacing={0}>
                            <Grid item xs={6}>
                              <strong>
                                {planName.label} {planName.selected}
                              </strong>
                            </Grid>
                            <Grid container item xs={6}>
                              <p className={classes.textRight}>
                                {planName[planName.selected]}{' '}
                                {planName.currency}
                              </p>
                            </Grid>
                          </Grid>

                          <form>
                            <Field
                              component={Input}
                              name="cardnumber"
                              label={translate('checkout.creditcard')}
                              normalize={ensureCcNumberFormatting}
                              placeholder="**** **** **** ****"
                              type="tel"
                            />

                            <Field
                              name="expiry"
                              normalize={ensureCcExpiryFormatting}
                              placeholder={translate('checkout.mm-yy')}
                              label={translate('checkout.month-year')}
                              type="tel"
                              component={Input}
                            />

                            <Field
                              name="cvc"
                              normalize={ensureCcCvcFormatting}
                              type="tel"
                              label="CVV"
                              placeholder="123"
                              component={Input}
                            />

                            {teamPlan && false && (
                              <div className={classes.reducedWidth}>
                                <Field
                                  name="quantity"
                                  onChange={e => onChangeQty(e)}
                                  label={translate('checkout.quantity')}
                                  placeholder="1-25"
                                  type="number"
                                  component={Input}
                                />
                              </div>
                            )}
                            <Field
                              name="plan"
                              value={planName.value}
                              component={Input}
                              type="hidden"
                            />
                            <Grid container spacing={0}>
                              <Grid item xs={6}>
                                <strong>
                                  <Translate id="checkout.today-billed" />:
                                </strong>
                              </Grid>
                              <Grid  item xs={6}>
                                <p className={classes.textRight}>
                                  {parseFloat(
                                    quantityAdded * planName[planName.selected]
                                  ).toFixed(2)}{' '}
                                  {planName.currency}
                                </p>
                              </Grid>
                            </Grid>
                            <Button
                              fullWidth
                              color="primary"
                              variant="contained"
                              onClick={handleSubmit}
                            >
                              <Translate id="button.pay-now" />
                            </Button>
                          </form>
                        </Paper>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </div>
                </Grid>
              </Grid>
            </Container>
          )}
        </Card>
      )}
    </Translate>
  )
}

CreateCheckoutForm.propTypes = {
  form: PropTypes.string.isRequired, // eslint-disable-line
  title: PropTypes.string.isRequired,
  viewData: PropTypes.object.isRequired, // eslint-disable-line
  apiKey: PropTypes.string.isRequired,
  planName: PropTypes.object.isRequired, // eslint-disable-line
  onSubmit: PropTypes.func.isRequired, // eslint-disable-line
  edit: PropTypes.func.isRequired, // eslint-disable-line
  createOnlySubscription: PropTypes.func.isRequired, // eslint-disable-line
  handleSubmit: PropTypes.func.isRequired,
  fromPath: PropTypes.shape({
    value: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  change: PropTypes.func.isRequired,
  teamPlan: PropTypes.bool,
  getAllCards: PropTypes.func.isRequired,
  isCountryValid: PropTypes.bool.isRequired,
  changeCountyValid: PropTypes.func.isRequired,
}

CreateCheckoutForm.defaultProps = {
  teamPlan: true,
}

export default compose(
  connect(
    null,
    {
      updateDefaultCard: subscription.updateDefaultCard,
      getAllCards: subscription.fetchAllCards,
    }
  ),
  reduxForm({
    enableReinitialize: true,
    validate: createJoiValidator(validationSchema),
  })
)(CreateCheckoutForm)
