import { handleActions, combineActions } from 'redux-actions'
import {
  actionsTypesGenerator,
  onRequest,
  onSuccess,
  onFailure,
} from 'utils/reduxHelpers'
import initialState from '../initialState'

const namespace = 'SEASONS'

const FETCH_SEASONS = actionsTypesGenerator(`FETCH_${namespace}`)
const CREATE_SEASON = actionsTypesGenerator(`CREATE_${namespace}`)
const UPDATE_SEASON = actionsTypesGenerator(`UPDATE_${namespace}`)
const DELETE_SEASON = actionsTypesGenerator(`DELETE_${namespace}`)
const FETCH_PLAYER_ATTENDANCE = actionsTypesGenerator(`FETCH_${namespace}_ATTENDANCE`)

export const fetchSeasons = teamId => ({
  types: FETCH_SEASONS,
  callAPI: {
    method: 'GET',
    entity: 'Seasons',
    path: `/teams/${teamId}/seasons`,
  },
  payload: { teamId },
})

export const createSeason = (teamId, data) => ({
  types: CREATE_SEASON,
  callAPI: {
    method: 'POST',
    entity: 'Season',
    path: '/seasons',
    data,
  },
  payload: { teamId },
})

export const updateSeason = (teamId, seasonId, data) => ({
  types: UPDATE_SEASON,
  callAPI: {
    method: 'PUT',
    entity: 'Season',
    path: `/seasons/${seasonId}`,
    data,
  },
  payload: { teamId, seasonId },
})

export const deleteSeason = (teamId, seasonId) => ({
  types: DELETE_SEASON,
  callAPI: {
    method: 'DELETE',
    entity: 'Season',
    path: `/seasons/${seasonId}`,
  },
  payload: { teamId, seasonId },
})

export const fetchPlayersAttendance = (id, params) => ({
  types: FETCH_PLAYER_ATTENDANCE,
  callAPI: {
    method: 'GET',
    entity: 'Team players Attendance',
    path: `/seasons/${id}/players-attendance`,
    params,
  },
})

export default handleActions(
  {
    [combineActions(
      FETCH_SEASONS.request,
      CREATE_SEASON.request,
      UPDATE_SEASON.request,
      DELETE_SEASON.request,
      FETCH_PLAYER_ATTENDANCE.request,
    )]: onRequest,
    [FETCH_SEASONS.success]: (state, { response: { data = [] }, teamId }) =>
      onSuccess({ ...state, items: { ...state.items, [teamId]: data } }),
    [FETCH_PLAYER_ATTENDANCE.success]: (state, { response: { data = [] }}) =>
      onSuccess({ ...state, attendance: data }),
    [CREATE_SEASON.success]: (state, { response: { data = {} }, teamId }) =>
      onSuccess({
        ...state,
        items: { ...state.items, [teamId]: [...state.items[teamId], data] },
      }),
    [UPDATE_SEASON.success]: (
      state,
      { response: { data = {} }, teamId, seasonId }
    ) =>
      onSuccess({
        ...state,
        items: {
          ...state.items,
          [teamId]: state.items[teamId].updateById(+seasonId, data),
        },
      }),
    [DELETE_SEASON.success]: (state, { teamId, seasonId }) =>
      onSuccess({
        ...state,
        items: {
          ...state.items,
          [teamId]: state.items[teamId].filterById(+seasonId),
        },
      }),
    [combineActions(
      FETCH_SEASONS.failure,
      CREATE_SEASON.failure,
      UPDATE_SEASON.failure,
      DELETE_SEASON.failure
    )]: onFailure,
  },
  initialState.seasons
)
