import React, { useState, useMemo } from 'react'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  Grid,
} from '@material-ui/core'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import { HeartIcon } from 'containers'
import { DrillModal } from 'components'
import PROP_TYPES from 'constants/propTypes'

const MuiCard = withStyles((theme) => ({
  root: {
    border: '1px solid #e8e8e8',
    '& .MuiCardContent-root': {
      padding: theme.spacing(2, 2, 0),
    },
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
}))(Card)
const MuiBox = withStyles((theme) => ({
  root: {
    display: 'flex',

    '& .MuiBox-root': {
      margin: theme.spacing(0, 1.5, 0, 0),
      '& .MuiTypography-root': {
        fontWeight: 500,
        color: theme.palette.action,
        marginRight: theme.spacing(0.5),
      },
    },
  },
}))(Box)
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(0, 3, 3),
  },
  toolbar: {
    minHeight: 54,
  },
  descriptionCell: {
    whiteSpace: 'nowrap',
    maxWidth: 'calc(100vw - 20px)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  media: {
    height: 140,
  },
  cardActions: {
    paddingLeft: theme.spacing(2),
  },
  title: {
    flex: '1 1 0',
  },
  fab: {
    position: 'absolute',
    top: theme.spacing(-3),
    right: theme.spacing(2),
  },
}))

const DrillsCarousel = ({ favoritesDrills }) => {
  const classes = useStyles()
  const [openedDrillUuid, setOpenedDrillUuid] = useState(null)

  const openedDrill = useMemo(
    () => favoritesDrills.findByUuid(openedDrillUuid),
    [openedDrillUuid, favoritesDrills]
  )

  const closeOpenedDrill = () => setOpenedDrillUuid(null)

  return (
    <>
      {openedDrillUuid && (
        <DrillModal
          drill={openedDrill}
          onHide={closeOpenedDrill}
          open={Boolean(openedDrillUuid)}
        />
      )}
      <Grid container spacing={3}>
        {favoritesDrills.map(
          ({
            id: drillId,
            uuid: drillUuid,
            title: titleValue,
            images: imagesUrls,
            duration: readingTime,
            max_players: maxPlayers,
            min_players: minPlayers,
          }) => (
            <>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={drillUuid}>
                <MuiCard>
                  <CardActionArea onClick={() => setOpenedDrillUuid(drillUuid)}>
                    <CardMedia
                      className={classes.media}
                      image={
                        imagesUrls.length > 0
                          ? imagesUrls[imagesUrls.length - 1].full_url
                          : ''
                      }
                      title="placeholder"
                    />
                    <CardContent className={classes.CardContent}>
                      <Typography
                        gutterBottom
                        className={classes.descriptionCell}
                        variant="h4"
                        component="h4"
                      >
                        {titleValue}
                      </Typography>
                      <MuiBox>
                        <Box>
                          <Translate id="drills.players" /> :
                          {`${minPlayers} - ${maxPlayers}`}{' '}
                        </Box>
                      </MuiBox>
                    </CardContent>
                  </CardActionArea>
                  <CardActions className={classes.cardActions}>
                    <div className={classes.title}>
                      <MuiBox>
                        <Box>
                          {' '}
                          <Typography variant="span" component="span">
                            <AccessTimeIcon />
                          </Typography>{' '}
                          {`${readingTime}`} min
                        </Box>
                      </MuiBox>
                    </div>
                    <HeartIcon isActive entity="drills" entityId={drillId} />
                  </CardActions>
                </MuiCard>
              </Grid>
            </>
          )
        )}
      </Grid>
    </>
  )
}

DrillsCarousel.defaultProps = {
  favoritesDrills: [],
}

DrillsCarousel.propTypes = {
  favoritesDrills: PROP_TYPES.arrayOfObjects,
}

export default connect(({ favorites }) => ({
  favoritesDrills: favorites.items.drills,
}))(DrillsCarousel)
