import React from 'react'
import PropTypes from 'prop-types'
import { Box, Checkbox, FormControlLabel, FormHelperText } from '@material-ui/core'
import PROP_TYPES from 'constants/propTypes'
import { Translate } from 'react-localize-redux'

const CheckboxGroup = ({ input, meta, options }) => {
  const { value: selectedValues, onChange } = input
  const error = meta.touched && meta.error
  const handleCheckboxChange = (value) => {
    const updatedValues = [...selectedValues]
    if (updatedValues.includes(value)) {
      // If the value is already selected, unselect it
      updatedValues.splice(updatedValues.indexOf(value), 1)
    } else {
      // If the value is not selected, select it
      updatedValues.push(value)
    }
    // Call the provided onChange function with the updated values
    onChange(updatedValues)
  }

  return (
    <>
      {options.map(({ value, label }) => (
        <Box my={1} py={.5} px={1}  border={1} borderColor="grey.500" borderRadius={10} key={value}>
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedValues.includes(value)}
                onChange={() => handleCheckboxChange(value)}
              />
            }
            label={label}
          />
        </Box>
      ))}
      {error && <FormHelperText error>{ error ? <Translate id={`joi.error.${meta.error}`} /> : ''}</FormHelperText>}
    </>
  )
}

CheckboxGroup.defaultProps = {
  options: [],
}

CheckboxGroup.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.arrayOf(),
    onChange: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape().isRequired,
  options: PROP_TYPES.options,
}

export default CheckboxGroup
