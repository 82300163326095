import React, { cloneElement } from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { Collapsible } from 'components'

const GroupsAndParticipationWrapper = ({ children, ...restProps }) => (
  <Translate>
    {({ translate }) => (
      <Collapsible title={translate('training.groups-participation')}>
        {cloneElement(children, { ...restProps })}
      </Collapsible>
    )}
  </Translate>
)

GroupsAndParticipationWrapper.propTypes = {
  children: PropTypes.element.isRequired,
}

export default GroupsAndParticipationWrapper
