import React from 'react'
import { Box, Avatar } from '@material-ui/core'

const AvatarImage = (params) => {

    return <Box display="flex" alignItems="center">
        <Avatar
            style={{marginRight: '0.5rem'}}
            alt="avatar"
            src={params.row.avatar ? params.row.avatar : params.row.avatar_image}
        />
        {params.row.firstName}
    </Box>

}

export default AvatarImage

