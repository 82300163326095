import { styled } from '@material-ui/core/styles';

export default styled('div')(({ theme }) => ({
  padding: theme.spacing(0,1.9), 

  background: '#0e4b4d',
  borderRadius: '15px',
  color: 'white',

  textAlign: 'center',
  cursor: 'pointer',
  maxHeight: '25px',
  margin: theme.spacing(0,.5, 1.1, .5),
  fontSize: '16px',
}));