import React from 'react'
import GroupsAndParticipationWrapper from './GroupsAndParticipationWrapper'
import GroupsAndParticipationContainer from './GroupsAndParticipationContainer'

const GroupsAndParticipation = props => (
  <GroupsAndParticipationWrapper {...props}>
    <GroupsAndParticipationContainer />
  </GroupsAndParticipationWrapper>
)

export default GroupsAndParticipation
