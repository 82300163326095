import { makeStyles } from '@material-ui/core/styles'
import { rgba } from 'polished'

const useStyles = makeStyles(theme => ({
    root: {
        height: 'calc(100vh - 225px)',
        overflow: 'hidden'
    },

    toolbar: {
        minHeight: 49,
        marginBottom: theme.spacing(2),
    
      },
      button: {
        margin: theme.spacing(0, 1),
      },
    
      title: {
        flex: '1 1 0',
      },
      backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
      },
      
    table: {
        position: 'relative',
        padding: theme.spacing(0),
        borderLeft:'0 !important',
        borderRight:'0 !important',
        borderBottom:'0 !important',
        '& .MuiDataGrid-columnHeaderTitle': {
          fontWeight: 'normal',
          fontSize: 12.8
        },
        '& .MuiDataGrid-cell': {
            borderBottom:'0 !important',
            padding:'0 12px !important',
           },
        '& .MuiDataGrid-row:hover': {
            backgroundColor: `${rgba(theme.Dataset.color_1, 0.1)} !important`,
        },
        '& .MuiAvatar-root': {
            boxShadow: '14px 5px 35px 0 rgba(0,0,0,0.09)',
            backgroundColor: '#eee',
            color: '#b5b5b5'
        },
        '& .MuiDataGrid-columnHeaderTitleContainer': {
            padding:'0 !important',
          },
          '& .MuiTablePagination-displayedRows': {
            marginBottom: '0 !important',
          },
          '& .MuiDataGrid-columnSeparator': {
            display: 'none !important ',
          }
    },
}))

export default useStyles