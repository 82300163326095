import { combineActions, handleActions } from 'redux-actions'
import {
  actionsTypesGenerator,
  onRequest,
  onSuccess,
  onFailure,
} from 'utils/reduxHelpers'
import initialState from '../initialState'

const namespace = 'STAFF'

const FETCH_ALL_STAFF = actionsTypesGenerator(`FETCH_ALL_${namespace}`)
const FETCH_STAFF = actionsTypesGenerator(`FETCH_${namespace}`)
const CREATE_STAFF = actionsTypesGenerator(`CREATE_${namespace}`)
const UPDATE_STAFF = actionsTypesGenerator(`UPDATE_${namespace}`)
const DELETE_STAFF = actionsTypesGenerator(`DELETE_${namespace}`)

export const fetchAllStaff = (teamId, params = {}) => ({
  types: FETCH_ALL_STAFF,
  callAPI: {
    method: 'GET',
    entity: 'All Staff',
    path: `/teams/${teamId}/staff`,
    params
  },
})

export const fetchStaff = (staffId, params) => ({
  types: FETCH_STAFF,
  callAPI: {
    method: 'GET',
    entity: 'Staff',
    path: `/staff/${staffId}`,
    params,
  },
})

export const createStaff = (teamId, data) => ({
  types: CREATE_STAFF,
  callAPI: {
    method: 'POST',
    entity: 'Staff',
    path: `/teams/${teamId}/staff`,
    data,
  },
})

export const updateStaff = (id, data) => ({
  types: UPDATE_STAFF,
  callAPI: {
    method: 'PUT',
    entity: 'Staff',
    path: `/staff/${id}`,
    data,
  },
})

export const deleteStaff = id => ({
  types: DELETE_STAFF,
  callAPI: {
    method: 'DELETE',
    entity: 'Staff',
    path: `/staff/${id}`,
  },
  payload: { id },
})

export default handleActions(
  {
    [combineActions(
      FETCH_ALL_STAFF.request,
      FETCH_STAFF.request,
      CREATE_STAFF.request,
      UPDATE_STAFF.request,
      DELETE_STAFF.request
    )]: onRequest,
    [FETCH_ALL_STAFF.success]: (state, { response: { data = [] } }) =>
      onSuccess({
        ...state,
        items: data,
      }),
    [FETCH_STAFF.success]: (state, { response: { data = {} } }) =>
      onSuccess({
        ...state,
        current: data,
      }),
    [CREATE_STAFF.success]: (state, { response: { data = {} } }) =>
      onSuccess({
        ...state,
        items: [data, ...state.items],
      }),
    [UPDATE_STAFF.success]: (state, { response: { data = {} } }) =>
      onSuccess({
        ...state,
        current: { ...state.current, ...data },
        items: state.items.updateById(data.id, { ...data, isNew: false }),
      }),
    [DELETE_STAFF.success]: (state, { id }) =>
      onSuccess({ ...state, items: state.items.filterById(id) }),
    [combineActions(
      FETCH_ALL_STAFF.failure,
      FETCH_STAFF.failure,
      CREATE_STAFF.failure,
      UPDATE_STAFF.failure,
      DELETE_STAFF.failure
    )]: onFailure,
  },
  initialState.staff
)
