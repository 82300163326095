import React from 'react'
import { ArticlesCategoriesLoader } from 'loaders'
import ArticleCreateContainer from './ArticleCreateContainer'
import PROP_TYPES from 'constants/propTypes'

const ArticleCreate = ({ history, location, match }) => (
  <ArticlesCategoriesLoader>
    <ArticleCreateContainer
      history={history}
      location={location}
      match={match}
    />
  </ArticlesCategoriesLoader>
)

ArticleCreate.propTypes = {
  history: PROP_TYPES.history.isRequired,
  match: PROP_TYPES.match.isRequired,
  location: PROP_TYPES.location.isRequired,
}

export default ArticleCreate
