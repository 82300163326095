import React from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Typography,
  Breadcrumbs,
  Hidden,
  IconButton,
  Divider,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import { MuiGrid, DialogTitle, Toolbar } from "./StyledComponents"

const TrainingPackageDialogHeader = ({ trainingPackage, onClose, duration }) => {
  return (
    <>
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h4" component="h4">
          {trainingPackage.title}
        </Typography>
        <IconButton aria-label="close" size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <Toolbar>
        <Grid container spacing={1}>
          <Hidden only={['xs', 'sm']}>
            <Grid item md={6}>
              <Breadcrumbs aria-label="breadcrumb">
                <Typography color="inherit">
                  <PersonAddOutlinedIcon />
                  {trainingPackage.relationships.age_classes
                    .map((ageClass) => ageClass.name)
                    .join(', ')}
                </Typography>
                <Typography color="inherit">
                  <PeopleOutlineIcon />
                  {trainingPackage.number_of_players}
                </Typography>
                <Typography color="inherit">
                  <AccessTimeIcon />
                  {duration} min
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Hidden>
        </Grid>
      </Toolbar>
      <MuiGrid item md={12} xs={12}>
        <div
          dangerouslySetInnerHTML={{
            __html: trainingPackage.description,
          }}
        />
      </MuiGrid>
    </>
  )
}

TrainingPackageDialogHeader.propTypes = {
  trainingPackage: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    number_of_players: PropTypes.number.isRequired,
    relationships: PropTypes.shape({
      age_classes: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
        })
      ).isRequired,
    }).isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  duration: PropTypes.number.isRequired,
}

export default TrainingPackageDialogHeader
