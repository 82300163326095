import { styled } from '@material-ui/core/styles'

const defaultPlaceholder = 'Processing...';

export default styled('div')(({ placeholder }) => ({
  '&:before': {
    content: prop =>`"${prop.placeholder || defaultPlaceholder}"`,
  },
  fontSize: '80%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))