import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  link: {
    display: "flex",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  toolbar: {
    minHeight: 50
   },
   breadcrumb: {
    margin: theme.spacing(0.9, 0)
   },
  [theme.breakpoints.down('sm')]: {
    toolbar: {
     minHeight: 210,
     padding: theme.spacing(0)
    },
    dialogActions: {
      justifyContent: 'center'
    }
  }
}));

export const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(0.1),
      color: theme.palette.grey[500],
    },
  
  });


