import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { videos } from 'redux/modules'
import Loader from './Loader'
import PROP_TYPES from 'constants/propTypes'

const VideosLoader = ({ children, fetchVideos, ...restProps }) => (
  <Loader fetchMethod={fetchVideos} {...restProps}>
    {children}
  </Loader>
)

VideosLoader.propTypes = {
  children: PROP_TYPES.children.isRequired,
  fetchVideos: PropTypes.func.isRequired,
}

export default connect(
  null,
  { fetchVideos: videos.fetchVideos }
)(VideosLoader)
