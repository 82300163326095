import React from 'react'
import { Box, FormHelperText } from '@material-ui/core'
import { StyledCheckbox } from 'components'
import { makeStyles } from '@material-ui/styles'
import PROP_TYPES from 'constants/propTypes'
import { Translate } from 'react-localize-redux'

const useStyles = makeStyles(theme => ({
  chipLevel1:{
    backgroundColor:theme.palette.grey[100],
    margin:"5px 0px",
    padding:"5px 10px",
    borderRadius:"1%",
    "& label":{
      marginBottom:"unset",
      marginRight:"unset",
      "& .MuiFormControlLabel-label":{
        fontSize:".75rem"
      }
    },
    "& .MuiSvgIcon-root":{
      fontSize:"1.2rem"
    }
  },
  chipLevel2:{
    backgroundColor:theme.palette.grey[200],
    margin:"5px 10px",
    padding:"5px 10px",
    borderRadius:"1%",
  },
  chipLevel3:{
    backgroundColor:theme.palette.grey[300],
    margin:"5px",
    padding:"0px 10px",
    borderRadius:"5%",
  }
}))

const CategoriesSelector = ({ input, meta, items }) => {
  const classes = useStyles()
  const onChange = id => checked => {
    if (checked > 0) {
      input.onChange([...input.value, id])

      return
    }

    input.onChange(input.value.filter(value => value !== id))
  }

  const Category = ({ depth, parentId }) => (
    <Box display="flex" flexDirection = {depth === 3 ? "row" : "column"} flexWrap="wrap" color="primary">
      {items
        .filter(category => category.parent_id === parentId)
        .map(category => (
          <Box className={depth === 1 ? classes.chipLevel1 : depth === 2 ? classes.chipLevel2 : classes.chipLevel3} key={category.id} display="flex" flexDirection = "column">
            <StyledCheckbox
              input={{
                value: input.value.includes(category.id),
                onChange: onChange(category.id),
              }}
              label={category.title}
            />
            {depth < 3 && <Category depth={depth + 1} parentId={category.id} />}
          </Box>
        ))}
    </Box>
  )

  Category.defaultProps = {
    parentId: null,
  }

  Category.propTypes = {
    depth: PROP_TYPES.number.isRequired,
    parentId: PROP_TYPES.id,
  }

  const isInvalid = meta.touched && meta.error

  return (
    <Box>
    
      <Category depth={1} parentId={null} />
      {isInvalid && (
        <FormHelperText type="invalid" error>
          {isInvalid ? <Translate id={`joi.error.${meta.error}`}/> : ''}
        </FormHelperText>
      )}

    </Box>
  )
}

CategoriesSelector.defaultProps = {
  items: [],
}

CategoriesSelector.propTypes = {
  input: PROP_TYPES.shape().isRequired,
  meta: PROP_TYPES.shape().isRequired,
  items: PROP_TYPES.arrayOfObjects,
}

export default CategoriesSelector
