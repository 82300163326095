import React from 'react'
import { TaskGroupsLoader } from 'loaders'
import TaskGroupsDashboard from './TaskGroupsDashboard'
import PROP_TYPES from 'constants/propTypes'

const TaskGroups = ({
  match: {
    params: { teamId },
  },
}) => (
  <TaskGroupsLoader teamId={teamId}>
    <TaskGroupsDashboard />
  </TaskGroupsLoader>
)

TaskGroups.propTypes = {
  match: PROP_TYPES.match.isRequired,
}

export default TaskGroups
