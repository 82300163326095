import React from 'react'
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { DatePicker, Input, RadioGroup, TimePicker } from 'components'
import PlacesSearchField from 'components/googlemaps/PlacesSearchField'
import InputNumber from 'components/inputes/InputNumber'
import Joi from 'joi'
import { Translate } from 'react-localize-redux'
import { Field, reduxForm } from 'redux-form'
import createJoiValidator from 'utils/createJoiValidator'
import getTranslatedLabelForFormField from 'utils/getTranslatedLabelForFormField'
import InfoIcon from '@material-ui/icons/Info'
import { connect } from 'react-redux'
import { compose } from 'redux'
import SelectList from 'components/inputes/SelectList'
import { MATCH_TYPES } from 'constants/match'
import PROP_TYPES from 'constants/propTypes'

const useStyles = makeStyles(() => ({
  textarea: {
    '& .MuiInputBase-root': {
      height: 'unset',
      paddingTop: 'unset',
      paddingBottom: 'unset',
    },
  },
  infoIcon: {
    position: 'absolute',
    right: 25,
    top: 20,
  },
}))

const validationSchema = Joi.object().keys({
  opponent: Joi.string()
    .max(255)
    .required()
    .label(getTranslatedLabelForFormField('team.opponent')),
  type: Joi.string()
    .required()
    .label(getTranslatedLabelForFormField('matches.match-type')),
  goals_received: Joi.number()
    .min(0)
    .label(getTranslatedLabelForFormField('team.goals-received')),
  goals_scored: Joi.number()
    .min(0)
    .label(getTranslatedLabelForFormField('team.goals-scored')),
  home: Joi.number().min(0),
  availability_cutoff_time: Joi.number().label(
    getTranslatedLabelForFormField('player.event-response.cutoff-time')
  ),
  details: Joi.string()
    .label(getTranslatedLabelForFormField('matches.additional-info'))
    .allow(null),
})

// This small component is used to render Meeting and end time error with respect to start date time
const getDateTimeTranslatedError = (translationKey) => {
  return (
    <Typography variant="caption" color="error">
      <Translate id={translationKey} />
    </Typography>
  )
}

const MatchInfoEditForm = ({
  dateFormat,
  initialValues,
  handleCancel,
  handleSubmit,
  handleDateChange,
  handleEndTimeChange,
  handleMeetingTimeChange,
  datetimesAndLocationData,
  setMatchData,
  ownTeamName,
  dateTimeError,
}) => {
  const classes = useStyles()
  const cutoffTimeOptions = [0, 6, 12, 24]
  const matchHomeAwayOptions = [
    { value: 1, title: 'Home' },
    { value: 0, title: 'Away' },
  ]

  return (
    <Grid container>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={4}>
          <Box textAlign="center">{ownTeamName || '{team name}'}</Box>
        </Grid>
        <Grid item xs={1}>
          <Box textAlign="center">
            <Translate id="matches.vs" variant="h4" />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box textAlign="center">{initialValues.opponent}</Box>
        </Grid>
      </Grid>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={4}>
          <Box textAlign="center">
            <Field component={InputNumber} minLimit={0} name="goals_scored" />
          </Box>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={4}>
          <Box textAlign="center">
            <Field component={InputNumber} minLimit={0} name="goals_received" />
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div>
          <Field
            name="home"
            component={RadioGroup}
            options={matchHomeAwayOptions}
          />
        </div>
      </Grid>
      <Grid item xs={12}>
        <Field
          name="type"
          component={SelectList}
          label={<Translate id="matches.match-type" />}
          required
        >
          {MATCH_TYPES.map((element) => (
            <MenuItem key={element.type} value={element.type}>
              {element.title}
            </MenuItem>
          ))}
        </Field>
        <Field
          name="opponent"
          label={<Translate id="team.opponent" />}
          component={Input}
          margin="dense"
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <div>
                  <Translate id="matches.vs" />
                </div>
              </InputAdornment>
            ),
          }}
        />
        <DatePicker
          required
          label={<Translate id="training.date" />}
          selectedValue={datetimesAndLocationData.start_datetime}
          onDateChange={handleDateChange}
          name="start_datetime"
          isEditMode
          dateFormat={dateFormat}
        />
          <Field
            name="availability_cutoff_time"
            component={SelectList}
            label={<Translate id="player.event-response.cutoff-time" />}
            endAdornment={
              <InputAdornment position="start" className={classes.infoIcon}>
                <Tooltip
                  title={<Translate id="player.event-response.info-message" />}
                  placement="bottom-end"
                >
                  <InfoIcon />
                </Tooltip>
              </InputAdornment>
            }
          >
            {cutoffTimeOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option === 0 ? 'Disabled' : `${option} hours`}
              </MenuItem>
            ))}
          </Field>
        {/* <Grid item xs={12}>
        </Grid> */}
        <TimePicker
          selectedValue={datetimesAndLocationData.start_datetime}
          onDateChange={handleDateChange}
          name="start_datetime"
          label={<Translate id="matches.kickoff-time" />}
          isEditMode
        />
        <TimePicker
          selectedValue={datetimesAndLocationData.meeting_datetime}
          onDateChange={handleMeetingTimeChange}
          name="meeting_datetime"
          label={<Translate id="matches.meeting-time" />}
          isEditMode={!!datetimesAndLocationData.meeting_datetime}
        />
        <TimePicker
          selectedValue={datetimesAndLocationData.end_datetime}
          onDateChange={handleEndTimeChange}
          label={<Translate id="matches.end-time" />}
          isEditMode={!!datetimesAndLocationData.end_datetime}
          name="end_datetime"
        />
        <PlacesSearchField
          defaultValue={datetimesAndLocationData.location}
          savedValue={datetimesAndLocationData.location}
          name="location"
          changeFormData={setMatchData}
          eventData={datetimesAndLocationData}
        />
        <Field
          name="details"
          label={<Translate id="matches.additional-info" />}
          component={Input}
          multiline
          minRows={4}
          className={classes.textarea}
          margin="dense"
        />
      </Grid>
      <Grid item xs={12}>{dateTimeError && getDateTimeTranslatedError(dateTimeError)}</Grid>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Button color="primary" onClick={handleCancel}>
            <Translate id="button.cancel" />
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            <Translate id="button.save" />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}
MatchInfoEditForm.propTypes = {
  dateFormat: PROP_TYPES.string.isRequired,
  initialValues: PROP_TYPES.shape().isRequired,
  handleCancel: PROP_TYPES.func.isRequired,
  handleSubmit: PROP_TYPES.func.isRequired,
  handleDateChange: PROP_TYPES.func.isRequired,
  handleEndTimeChange: PROP_TYPES.func.isRequired,
  handleMeetingTimeChange: PROP_TYPES.func.isRequired,
  datetimesAndLocationData: PROP_TYPES.func.isRequired,
  setMatchData: PROP_TYPES.func.isRequired,
  ownTeamName: PROP_TYPES.string.isRequired,
  dateTimeError: PROP_TYPES.string.isRequired,
}

export default compose(
  connect(({ auth, team }) => ({
    dateFormat: auth.user.datetime_format,
    ownTeamName: team.name,
  })),
  reduxForm({
    form: 'matchInfoEditForm',
    validate: createJoiValidator(validationSchema),
  })
)(MatchInfoEditForm)
