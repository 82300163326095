import React from 'react'
import { Dialog } from '@material-ui/core'
import propTypes from 'prop-types'
import StaffFormContainer from 'routes/Staff/StaffFormContainer'
import { object } from 'joi'

const StaffCreateDialog = (props) => {
  const { onClose, open, initialValues } = props;
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
        <StaffFormContainer handleClose={handleClose} initialValues={initialValues}/>
    </Dialog>
  );
}

StaffCreateDialog.propTypes = {
  onClose: propTypes.func.isRequired,
  open: propTypes.bool.isRequired,
  initialValues: propTypes.arrayOf(object)
};
export default StaffCreateDialog


