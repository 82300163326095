import React from 'react'
import { Translate } from 'react-localize-redux'

export default [
  {
    value: 'wing-play',
    title: <Translate id="tagsequence.wing-play" />,
    background: '#8cba80',
    color: 'white',
  },
  {
    value: 'transition-play',
    title: <Translate id="tagsequence.transition-play" />,
    background: '#658e9c',
    color: 'white',
  },
  {
    value: 'loss-of-possession',
    title: <Translate id="tagsequence.loss-of-possession" />,
    background: '#4d5382',
    color: 'white',
  },
  {
    value: 'pressing',
    title: <Translate id="tagsequence.pressing" />,
    background: '#514663',
    color: 'white',
  },
  {
    value: 'shifting',
    title: <Translate id="tagsequence.shifting" />,
    background: '#e0d784',
    color: 'white',
  },
  {
    value: 'build-up-play',
    title: <Translate id="tagsequence.build-up-play" />,
    background: '#79addc',
    color: 'white',
  },
  {
    value: 'duels',
    title: <Translate id="tagsequence.duels" />,
    background: '#2a3b6d',
    color: 'white',
  },
  {
    value: 'defending',
    title: <Translate id="tagsequence.defending" />,
    background: '#ffc09f',
    color: 'white',
  },
  {
    value: 'creating-chances',
    title: <Translate id="tagsequence.creating-chances" />,
    background: '#a8d0c1',
    color: 'white',
  },
  {
    value: 'pre-orientation',
    title: <Translate id="tagsequence.pre-orientation" />,
    background: '#ff005a',
    color: 'white',
  },
  {
    value: 'set-pieces',
    title: <Translate id="tagsequence.set-pieces" />,
    background: '#0e7c7b',
    color: 'white',
  },
  {
    value: 'others',
    title: <Translate id="tagsequence.others" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'pressing-goal-kick',
    title: <Translate id="tagsequence.pressing-goal-kick" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'counter-press',
    title: <Translate id="tagsequence.counter-press" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'pressing-offensive',
    title: <Translate id="tagsequence.pressing-offensive" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'pressing-defensive',
    title: <Translate id="tagsequence.pressing-defensive" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'transition-offensive',
    title: <Translate id="tagsequence.transition-offensive" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'transition-defensive',
    title: <Translate id="tagsequence.transition-defensive" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'push-the-defense-out',
    title: <Translate id="tagsequence.push-the-defense-out" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'position-of-the-back-4',
    title: <Translate id="tagsequence.position-of-the-back-4" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'covering-in-the-defensive-line',
    title: <Translate id="tagsequence.covering-in-the-defensive-line" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'individual-mistakes',
    title: <Translate id="tagsequence.individual-mistakes" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'reaction-of-defensive-line-to-recover',
    title: <Translate id="tagsequence.reaction-of-defensive-line-to-recover" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'corner',
    title: <Translate id="tagsequence.corner" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'free-kick',
    title: <Translate id="tagsequence.free-kick" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'throw-in',
    title: <Translate id="tagsequence.throw-in" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'loose-balls',
    title: <Translate id="tagsequence.loose-balls" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'goal-kick-long',
    title: <Translate id="tagsequence.goal-kick-long" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'goal-kick-short',
    title: <Translate id="tagsequence.goal-kick-short" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'rest-defense',
    title: <Translate id="tagsequence.rest-defense" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'defending-cut-backs',
    title: <Translate id="tagsequence.defending-cut-backs" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'box-defense',
    title: <Translate id="tagsequence.box-defense" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'force-outside',
    title: <Translate id="tagsequence.force-outside" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'conceded-goal',
    title: <Translate id="tagsequence.conceded-goal" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'defensive-midfielder-in-front-of-the-defense',
    title: (
      <Translate id="tagsequence.defensive-midfielder-in-front-of-the-defense" />
    ),
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'one-touch-assist',
    title: <Translate id="tagsequence.one-touch-assist" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'build-up-with-two',
    title: <Translate id="tagsequence.build-up-with-two" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'build-up-with-three',
    title: <Translate id="tagsequence.build-up-with-three" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'build-up-with-four',
    title: <Translate id="tagsequence.build-up-with-four" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'dribbling',
    title: <Translate id="tagsequence.dribbling" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'crossing',
    title: <Translate id="tagsequence.crossing" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'counter-pressing',
    title: <Translate id="tagsequence.counter-pressing" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'collective-counter-movements',
    title: <Translate id="tagsequence.collective-counter-movements" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'counter-attack',
    title: <Translate id="tagsequence.counter-attack" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'header-goal',
    title: <Translate id="tagsequence.header-goal" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'long-ball',
    title: <Translate id="tagsequence.long-ball" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'centre',
    title: <Translate id="tagsequence.centre" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'through-pass',
    title: <Translate id="tagsequence.through-pass" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'split-pass',
    title: <Translate id="tagsequence.split-pass" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'sprint',
    title: <Translate id="tagsequence.sprint" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'transition',
    title: <Translate id="tagsequence.transition" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'long-shot',
    title: <Translate id="tagsequence.long-shot" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'goal',
    title: <Translate id="tagsequence.goal" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'assist',
    title: <Translate id="tagsequence.assist" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'penalty',
    title: <Translate id="tagsequence.penalty" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'goal-kick',
    title: <Translate id="tagsequence.goal-kick" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'breaking-lines',
    title: <Translate id="tagsequence.breaking-lines" />,
    background: '#e0e0e0',
    color: '#000000',
  },
  {
    value: 'intensity',
    title: <Translate id="tagsequence.intensity" />,
    background: '#e0e0e0',
    color: '#000000',
  },
]
