import React, { useState } from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { CSVLink } from 'react-csv'
import { Paper, Tabs, Tab, Toolbar, Button, Box } from '@material-ui/core'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import { useStyles } from './components/style/styled'

import {
  PlayersSkills,
  PlayersStatistics,
  PlayersAttendance,
  PlayersPersonalData,
} from './components'

const TeamPlayersDataContainer = ({ theme }) => {
  const classes = useStyles(theme)
  const tabs = ['stats', 'personal', 'skills']
  const [value, setValue] = useState(tabs[0])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }



  function sortCsvDataByPlayer(object1, object2) {
    if (object1.Player < object2.Player) return -1
    if (object1.Player > object2.Player) return 1

    return 0
  }
  const [csvData, setCsvData] = useState([]);

  return (
    <Paper className={classes.paper}>
      <Toolbar className={classes.root}>
        <Tabs
          value={value}
          className={classes.title}
          indicatorColor="primary"
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            label={<Translate id="tab.team-players-statistics" />}
            value="stats"
          />
          <Tab
            label={<Translate id="tab.team-players-attendance" />}
            value="attendance"
          />
          <Tab
            label={<Translate id="tab.team-players-player-data" />}
            value="personal"
          />
          <Tab
            label={<Translate id="tab.team-players-skills" />}
            value="skills"
          />
          <Box ml="auto">
            {value === 'stats' && (
              <CSVLink
                data={csvData ? csvData.sort(sortCsvDataByPlayer) : null}
                className={classes.csvLink}
              >
                <Button
                  variant="contained"
                  className={classes.csvButton}
                  size="samll"
                  startIcon={<SaveAltIcon fontSize="small" />}
                  color="primary"
                >
                  <Translate id="button.export" />
                </Button>
              </CSVLink>
            )}
          </Box>
        </Tabs>
      </Toolbar>
      {value === 'stats' && <PlayersStatistics setCsvData={setCsvData}/>}
      {value === 'attendance' && <PlayersAttendance />}
      {value === 'personal' && <PlayersPersonalData />}
      {value === 'skills' && <PlayersSkills />}
    </Paper>
  )
}

TeamPlayersDataContainer.propTypes = {
  theme: PropTypes.oneOfType([
    PropTypes.shape().isRequired,
    PropTypes.oneOf([null]).isRequired,
  ]).isRequired,
}

export default TeamPlayersDataContainer
