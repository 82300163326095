// @dependencycycle This injection creates a dependency cycle between redux store and local storage
import { store } from '../redux/store'

// @todo: #storage replace localStorage usages with Redux where possible

export const getToken = () => {
  const state = store.getState()
  const { token } = state.auth

  return token
}

export const getCountry = () => localStorage.getItem('country') || null
export const getLocale = () => localStorage.getItem('locale') || 'en'

/**
 * Get login URL based on current locale.
 *
 * @returns string
 */
export const getLoginURL = () => {
  const state = store.getState()
  const { locale } = state.auth

  return locale === 'en' ? '/login' : `/${locale}/login`
}

/**
 * Get signup URL based on current locale.
 *
 * @returns string
 */
export const getSignupURL = () => {
  const state = store.getState()
  const { locale } = state.auth

  return locale === 'en' ? '/signup' : `/${locale}/signup`
}

export const setCountry = (country) => localStorage.setItem('country', country)
export const setLocale = (locale) => localStorage.setItem('locale', locale)
export const setClub = (data) =>
  localStorage.setItem('club', JSON.stringify(data))
export const getClub = () => JSON.parse(localStorage.getItem('club'))
