import React from 'react'
import { connect } from 'react-redux'
import EventCalenderContainers from 'components/material/Calender/EventCalenderContainers'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'

const TrainingsCalendarContainer = () => {

  return (
    <EventCalenderContainers />
  )
}

TrainingsCalendarContainer.defaultProps = {}

TrainingsCalendarContainer.propTypes = {}

export default compose(
  withRouter,
  connect(null)
)(TrainingsCalendarContainer)
