import { combineActions, handleActions } from 'redux-actions'
import {
  actionsTypesGenerator,
  onRequest,
  onSuccess,
  onFailure,
} from 'utils/reduxHelpers'
import initialState from '../initialState'

const namespace = 'MATCH_HIGHLIGHT'

const FETCH_MATCH_HIGHLIGHTS = actionsTypesGenerator(`FETCH_${namespace}S`)
const CREATE_MATCH_HIGHLIGHT = actionsTypesGenerator(`CREATE_${namespace}`)
const UPDATE_MATCH_HIGHLIGHT = actionsTypesGenerator(`UPDATE_${namespace}`)
const DELETE_MATCH_HIGHLIGHT = actionsTypesGenerator(`DELETE_${namespace}`)

export const fetchMatchHighlights = (matchUuid, params) => ({
  types: FETCH_MATCH_HIGHLIGHTS,
  callAPI: {
    method: 'GET',
    entity: 'Match highlights',
    path: `/v2/matches/${matchUuid}/match-highlights`,
    params,
  },
})
export const createMatchHighlight = (matchUuid, data) => ({
  types: CREATE_MATCH_HIGHLIGHT,
  callAPI: {
    method: 'POST',
    entity: 'Match highlight',
    path: `/v2/matches/${matchUuid}/match-highlights`,
    data,
  },
})
export const updateMatchHighlight = (matchHighlightUuid, data) => ({
  types: UPDATE_MATCH_HIGHLIGHT,
  callAPI: {
    method: 'PATCH',
    entity: 'Match highlight',
    path: `/v2/match-highlights/${matchHighlightUuid}`,
    data,
  },
})
export const deleteMatchHighlight = (matchHighlightUuid) => ({
  types: DELETE_MATCH_HIGHLIGHT,
  callAPI: {
    method: 'DELETE',
    entity: 'Match highlight',
    path: `/v2/match-highlights/${matchHighlightUuid}`,
  },
  payload: { matchHighlightUuid }, // Include matchHighlightUuid in payload to delete record on success when success code
})

export default handleActions(
  {
    [combineActions(
      FETCH_MATCH_HIGHLIGHTS.request,
      CREATE_MATCH_HIGHLIGHT.request,
      UPDATE_MATCH_HIGHLIGHT.request,
      DELETE_MATCH_HIGHLIGHT.request
    )]: onRequest,
    [combineActions(FETCH_MATCH_HIGHLIGHTS.success)]: (
      state,
      { response: { data = [] } }
    ) =>
      onSuccess({
        ...state,
        items: data,
      }),
    [combineActions(CREATE_MATCH_HIGHLIGHT.success)]: (
      state,
      { response: { data = {} } }
    ) => {
      return onSuccess({
        ...state,
        items: [
          ...state.items,
          { ...data, minute: +data.minute, second: +data.second },
        ],
      })
    },
    [combineActions(UPDATE_MATCH_HIGHLIGHT.success)]: (
      state,
      { response: { data = {} } }
    ) => {
      const items = state.items.updateByUuid(data.uuid, { ...data })

      return onSuccess({
        ...state,
        items,
      })
    },
    [DELETE_MATCH_HIGHLIGHT.success]: (state, { matchHighlightUuid }) => {
      // Remove the deleted match highlight from the items array using payload matchHighlightUuid
      const updatedItems = state.items.filterByUuid(matchHighlightUuid)

      return onSuccess({
        ...state,
        items: updatedItems,
      })
    },
    [combineActions(
      FETCH_MATCH_HIGHLIGHTS.failure,
      CREATE_MATCH_HIGHLIGHT.failure,
      UPDATE_MATCH_HIGHLIGHT.failure,
      DELETE_MATCH_HIGHLIGHT.failure
    )]: onFailure,
  },
  initialState.matchHighlights
)
