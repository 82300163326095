import React, { useState, cloneElement } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { withStyles } from '@material-ui/core/styles'
import PROP_TYPES from 'constants/propTypes'
import { Divider } from '@material-ui/core'

const styles = {
  dialogPaper: {
    width: '300px',
    boxShadow: '0 0 250px 20px rgba(0, 0, 0, 0.9)',
  },
  dialog: {
    zIndex: '0 !important',
  },
  customZIndexDialog: {
    zIndex: '99999 !important',
  },
}

const Prompt = ({ onSuccess, onReject, message, classes, zIndexClass }) => (
  <Dialog
    onClose={onReject}
    open
    className={zIndexClass ? classes.customZIndexDialog : ''}
    classes={{ paper: classes.dialogPaper, root: classes.dialog }}
  >
    <DialogContent style={{ padding: '1rem' }}>{message}</DialogContent>
    <Divider />
    <DialogActions style={{ padding: '1rem' }}>
      <Button variant="contained" autoFocus onClick={onReject} color="#dc3545">
        NO
      </Button>
      <Button variant="contained" onClick={onSuccess} color="primary">
        YES
      </Button>
    </DialogActions>
  </Dialog>
)

Prompt.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
}

const ActionWithPrompt = ({
  className,
  onSuccess,
  message,
  title = 'Cancel',
  children,
  classes,
  zIndexClass
}) => {
  const [isPromptShown, changeIsPromptShown] = useState(false)

  const onReject = () => changeIsPromptShown(false)

  const extendedOnSuccess = () => {
    changeIsPromptShown(false)
    onSuccess()
  }

  const onClick = () => changeIsPromptShown(true)

  return (
    <>
      {isPromptShown && (
        <Prompt
          onReject={onReject}
          onSuccess={extendedOnSuccess}
          message={message}
          classes={classes}
          zIndexClass={zIndexClass}
        />
      )}
      {children ? (
        cloneElement(children, { onClick })
      ) : (
        <Button variant="contained" className={className} onClick={onClick}>
          {title}
        </Button>
      )}
    </>
  )
}

ActionWithPrompt.defaultProps = {
  className: null,
  title: 'Cancel',
  children: null,
  zIndexClass: false,
}

ActionWithPrompt.propTypes = {
  className: PropTypes.string,
  onSuccess: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  title: PropTypes.string,
  children: PROP_TYPES.children,
  classes: PropTypes.object.isRequired,
  zIndexClass: PropTypes.string,
}

export default withStyles(styles)(ActionWithPrompt)
