import {
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  Grid,
  FormLabel,
  InputAdornment,
  Typography,
  makeStyles,
  DialogTitle,
  Dialog,
} from '@material-ui/core'
import React, { useState } from 'react'
import { Translate } from 'react-localize-redux'
import CloseIcon from '@material-ui/icons/Close'
import AddIcon from '@material-ui/icons/Add'
import LinkIcon from '@material-ui/icons/Link'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { players as playersModule } from 'redux/modules'
import CircularSpinner from 'components/loader/CircularSpinner'
import InviteFields from './InviteFields'
import PROP_TYPES from 'constants/propTypes'

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0.4),
    color: theme.palette.grey[500],
  },
}))
const InviteDialog = ({ onClose, sendInvitations, open, players, team }) => {
  const classes = useStyles()
  const [isFormValid, setIsFormValid] = useState(false)
  const { isLoading, current: player } = players

  const setInvitesArr = (invites) => {
    const minDisplay = 3
    let i = []
    if (invites && invites.length > 0) {
      i = invites.map(
        ({
          email,
          first_name: firstName,
          last_name: lastName,
          role,
          access,
        }) => ({
          email,
          firstName,
          lastName,
          role,
          access,
        })
      )
      if (i.length < minDisplay) {
        const n = minDisplay - i.length
        for (let j = 0; j < n; j++) {
          i.push({
            email: '',
            firstName: '',
            lastName: '',
            role: 'relative',
            access: 1,
          })
        }
      }
    } else {
      for (let index = 0; index < 3; index++) {
        i.push({
          email: '',
          firstName: '',
          lastName: '',
          role: 'relative',
          access: 1,
        })
      }
    }

    return i
  }
  const [invites, setInvites] = useState(setInvitesArr(player.invites))

  const addNewInviteRow = () => {
    setInvites([
      ...invites,
      { email: '', firstName: '', lastName: '', role: 'relative', access: 1 },
    ])
  }
  const handleSubmit = () => {
    const temp = invites.filter((invite) => {
      return invite.email !== ''
    })
    const data = {
      invites: temp.map(({ email, firstName, lastName, role, access }) => ({
        email,
        first_name: firstName,
        last_name: lastName,
        role,
        access,
      })),
      model_type: 'player',
      model_id: player.id,
    }
    if (data.invites.length === 0) {
      return
    }
    sendInvitations(player.team_id, data).then((res) => {
      res.response.data.forEach((element) => {
        const index = player.invites.findIndex(
          (el) => el.email === element.email
        )
        if (index !== -1) {
          player.invites.splice(index, 1, element)
        } else {
          player.invites.push(element)
        }
      })
      onClose()
    })
  }

  return (
    
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>
        <Typography variant="h4" component="h4">
          <Translate id="player.invite-user.title" />
        </Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            size="medium"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <FormLabel id="demo-radio-buttons-group-label">
              <Translate id="team.e-mail" />
            </FormLabel>
          </Grid>
          <Grid item xs={3}>
            <FormLabel id="demo-radio-buttons-group-label">
              <Translate id="form.first-name" />{' '}
              <Translate id="player.invite-user.optional" />
            </FormLabel>
          </Grid>
          <Grid item xs={3}>
            <FormLabel id="demo-radio-buttons-group-label">
              <Translate id="player.invite-user.lname" />{' '}
              <Translate id="player.invite-user.optional" />
            </FormLabel>
          </Grid>
          <Grid item xs={2}>
            <FormLabel id="demo-radio-buttons-group-label">
              <Translate id="player.invite-user.role" />
            </FormLabel>
          </Grid>
          <Grid item xs={1}>
            <FormLabel id="demo-radio-buttons-group-label">
              <Translate id="player.invite-user.access" />
            </FormLabel>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          {invites.map((item, index) => {
            return (
              <InviteFields
                key={index}
                inviteIndex={index}
                setInvites={setInvites}
                invite={item}
                isLoading={isLoading}
                setIsFormValid={setIsFormValid}
              />
            )
          })}
          <Grid item xs={6}>
            <Button variant="text" color="primary" onClick={addNewInviteRow}>
              <AddIcon style={{ fontSize: 20 }} />{' '}
              <Translate id="player.invite-user.add-another" />
            </Button>
          </Grid>

          {/* hidden block as of now */}
          <Grid item xs={12} hidden>
            <FormLabel id="demo-radio-buttons-group-label">
              Or invite them by sending them this link
            </FormLabel>
            <TextField
              id="outlined-basic"
              disabled
              variant="outlined"
              margin="dense"
              placeholder="Optional"
              value="http://pixel-plus.beekeeper.io/signup"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button color="primary">
                      <LinkIcon /> Copy link
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          <Translate id="button.close" />
        </Button>
        <Button
          color="primary"
          variant="contained"
          autoFocus
          disabled={!isFormValid || isLoading}
          onClick={handleSubmit}
        >
          <Translate id="button.submit" />
        </Button>
      </DialogActions>
      {isLoading &&
        <CircularSpinner loadingcircular={isLoading}/>
      }
    </Dialog>
  )
}
InviteDialog.propTypes = {
  players: PROP_TYPES.shape().isRequired,
  team: PROP_TYPES.shape().isRequired,
  onClose: PROP_TYPES.func.isRequired,
  sendInvitations: PROP_TYPES.func.isRequired,
  open: PROP_TYPES.bool.isRequired,
}

export default compose(
  connect(({ team, players }) => ({ team, players }), {
    sendInvitations: playersModule.sendInvitations,
  })
)(InviteDialog)
