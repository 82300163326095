import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  circle: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 110,
    height: 110,
    margin: '0 auto 0',
    borderRadius: '50%',
    border: '4px solid var(--primary-green)',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      width: 105,
      height: 105,
      '& .col-md-4 &': {
        width: 95,
        height: 95,
      },
    },
  },
  actualValue: {
    fontSize: 30,
    letterSpacing: '0.2px',
    lineHeight: '33.5px',
  },
  totalValue: {
    margin: '0 10px',
    fontSize: 14,
    letterSpacing: '0.1px',
  },
  title: {
    fontSize: 16,
    letterSpacing: '0.3px',
    textAlign: 'center',
  },
});

const SummaryCircle = ({ classes, value, maxValue, title, inverse }) => (
  <div>
    <Paper className={classes.circle}>
      <Typography className={classes.actualValue} variant="h4" gutterBottom>
        {value}
      </Typography>
      <Typography className={classes.totalValue} variant="body2">
        {maxValue}
      </Typography>
    </Paper>
    <Typography className={classes.title} variant="subtitle1">
      {title}
    </Typography>
  </div>
);

SummaryCircle.defaultProps = {
  maxValue: null,
  inverse: false,
};

SummaryCircle.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]).isRequired,
  maxValue: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
  title: PropTypes.string.isRequired,
  inverse: PropTypes.bool,
};

export default withStyles(styles)(SummaryCircle);