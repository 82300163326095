import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 20,
  },
  menu: {
    '& .MuiPopover-paper': {
      border: `1px solid ${theme.palette.grey[300]}`,
      boxShadow: `1px 1px 10px 0 ${theme.palette.grey[200]}`,
      borderRadius: 10,
    },
  },
  [theme.breakpoints.up('md')]: {
    button: {
      width: 66.44,
      height: 30.83,
    },
  },
}))

