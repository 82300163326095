import React, { useState } from 'react'
import { Box, Divider, Tab, Tabs } from '@material-ui/core'
import TabPanel from 'components/material/TabPanel'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import { Ticker } from 'components'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { generalEvents } from 'redux/modules'
import EventPlayers from './EventPlayers'
import PropTypes from 'constants/propTypes'
import { EVENT_SECTIONS } from 'constants/generalEvents'

const TabViewContainer = ({ fetchEventTickers, eventUuid }) => {
  const [activeTab, setActiveTab] = useState(EVENT_SECTIONS.generalEvent)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const handleChange = (event, newValue) => {
    setActiveTab(newValue)
  }

  return (
    <Box m={2}>
      <Tabs
        value={activeTab}
        onChange={handleChange}
        variant={isMobile ? 'scrollable' : 'standard'}
        scrollButtons={isMobile ? 'auto' : 'off'}
        indicatorColor="primary"
      >
        <Tab
          label={<Translate id="matches.team-line-up" />}
          value={EVENT_SECTIONS.generalEvent}
        />
        <Tab
          label={<Translate id="ticker.tickers" />}
          value={EVENT_SECTIONS.tickers}
        />
      </Tabs>
      <Divider />
      <TabPanel
        value={activeTab}
        index={EVENT_SECTIONS.generalEvent}
        padding={1}
      >
        <EventPlayers />
      </TabPanel>
      <TabPanel value={activeTab} index={EVENT_SECTIONS.tickers}>
        <Ticker loader={() => fetchEventTickers(eventUuid)} />
      </TabPanel>
    </Box>
  )
}

TabViewContainer.propTypes = {
  eventUuid: PropTypes.number.isRequired,
  handleSave: PropTypes.func.isRequired,
  fetchEventTickers: PropTypes.func.isRequired,
}

export default compose(
  connect(
    ({
      generalEvents: { current },
    }) => ({
      eventUuid: current.uuid,
    }),
    {
      fetchEventTickers: generalEvents.fetchEventTickers,
    }
  )
)(TabViewContainer)
