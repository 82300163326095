import React from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { Translate } from 'react-localize-redux'
import { Box, Button, Typography } from '@material-ui/core'
import { TeaserTagOverlay } from 'components'
import { SubLink } from 'containers'
import {
  ACTIVITIES_ROUTE,
  TRAINING_PACKAGE_CREATE_ROUTE,
} from 'constants/routes'
import { PLUS } from 'constants/plans'

const useStyles = makeStyles((theme) => ({
  navigation: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 15,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  navList: {
    listStyle: 'none',
    padding: 0,
    display: 'flex',
  },
  navItem: {
    paddingRight: '35px',
  },
  link: {
    color: 'gray',
  },
  button: {
    margin: theme.spacing(0, 1),
  },
}))

const Navigation = () => {
  const { teamId, seasonId } = useParams()
  const baseRoute = `/team/${teamId}/season/${seasonId}`
  const classes = useStyles()

  return (
    <nav className={classes.navigation}>
      <ul className={classes.navList}>
        <li className={classes.navItem}>
          <NavLink
            to={`${baseRoute}${ACTIVITIES_ROUTE}`}
            className={classes.link}
          >
            <Typography variant="h3" component="h3">
              <Translate id="drills.activities" />
            </Typography>
          </NavLink>
        </li>
        <li>
          <Typography variant="h3" component="h3">
            <Translate id="navbar.training_packages" />
          </Typography>
        </li>
      </ul>
      <Box>
        <TeaserTagOverlay tagLabel={PLUS}>
          <Button
            variant="contained"
            color="primary"
            component={SubLink}
            className={classes.button}
            to={TRAINING_PACKAGE_CREATE_ROUTE}
          >
            <Translate id="training.create-training-packages" />
          </Button>
        </TeaserTagOverlay>
      </Box>
    </nav>
  )
}

export default Navigation
