import React from 'react'
import { connect } from 'react-redux'
import { lineups } from 'redux/modules'
import PropTypes from 'prop-types'
import Loader from './Loader'
import PROP_TYPES from 'constants/propTypes'

const LineupsLoader = ({ children, fetchLineups, teamUuid, ...restProps }) => {
  const fetchMethod = () => fetchLineups(teamUuid)

  return (
    <Loader fetchMethod={fetchMethod} {...restProps}>
      {children}
    </Loader>
  )
}

LineupsLoader.propTypes = {
  children: PROP_TYPES.children.isRequired,
  fetchLineups: PropTypes.func.isRequired,
  teamUuid: PropTypes.string.isRequired,
}

export default connect(
  ({ team }) => ({
    teamUuid: team.uuid,
  }),
  { fetchLineups: lineups.fetchLineups }
)(LineupsLoader)
