import React, { useState, useMemo } from 'react'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {
  Paper,
  Box,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
  Toolbar,
} from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import { SubLink, HeartIcon } from 'containers'
import { Carousel, DrillModal } from 'components'
import CarouselItem from 'components/material/CarouselItem'
import fallbackImageUrl from 'assets/images/carousel-item-placeholder.jpg'
import { getClub } from 'utils/localstorage'
import { ACTIVITIES_ROUTE } from 'constants/routes'
import { DEFAULT_CLUB_ID } from 'constants/club'
import PROP_TYPES from 'constants/propTypes'

const MuiBox = withStyles((theme) => ({
  root: {
    '& .MuiBox-root': {
      margin: theme.spacing(0, 1.5, 0, 0),
      '& .MuiTypography-root': {
        display: 'flex',
        fontWeight: 500,
        color: theme.palette.action,
        marginRight: theme.spacing(0.5),
      },
    },
  },
}))(Box)

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(0, 3, 3),
    '& .MuiBox-root': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  toolbar: {
    minHeight: 54,
  },
  descriptionCell: {
    whiteSpace: 'nowrap',
    maxWidth: 'calc(100vw - 20px)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  media: {
    height: 140,
  },
  title: {
    flex: '1 1 0',
  },
  cardActions: {
    paddingLeft: theme.spacing(2),
  },
  fab: {
    position: 'absolute',
    top: theme.spacing(-3),
    right: theme.spacing(2),
  },
}))

const DrillsWidgetContainer = ({ drills, favoritesDrills, clubid }) => {
  const [openedDrillUuid, setOpenedDrillUuid] = useState(null)

  const openedDrill = useMemo(
    () => drills.findByUuid(openedDrillUuid),
    [openedDrillUuid, drills]
  )

  const closeOpenedDrill = () => setOpenedDrillUuid(null)

  const getIsFavorite = (id) => Boolean(favoritesDrills.findById(id))
  const classes = useStyles()

  return (
    <>
      {openedDrillUuid && (
        <DrillModal
          drill={openedDrill}
          onHide={closeOpenedDrill}
          open={Boolean(openedDrillUuid)}
        />
      )}
      <Paper className={classes.root}>
        <Toolbar disableGutters="false" className={classes.toolbar}>
          <Typography variant="h4" component="h4" className={classes.title}>
            {clubid === DEFAULT_CLUB_ID ? (
              <Translate id="home.coachbetter_drills" />
            ) : (
              <>
                {`${getClub()?.name}`} <Translate id="home.drills" />
              </>
            )}
          </Typography>
          <Button
            color="primary"
            component={SubLink}
            to={ACTIVITIES_ROUTE}
            endIcon={<ChevronRightIcon />}
          >
            <Translate id="home.read-more" />
          </Button>
        </Toolbar>
        <Carousel>
          {drills.map(
            ({
              id: drillId,
              uuid: drillUuid,
              title,
              images,
              duration,
              max_players: maxPlayers,
              min_players: minPlayers,
              club_id: clubID,
            }) =>
              clubid === clubID && (
                <>
                  <CarouselItem key={drillUuid}>
                    <Box onClick={() => setOpenedDrillUuid(drillUuid)}>
                      <CardActionArea component={SubLink}>
                        <CardMedia
                          className={classes.media}
                          image={images.length ? images[images.length-1].full_url : fallbackImageUrl}
                          title="placeholder"
                        />

                        <CardContent pb={0.5} className={classes.CardContent}>
                          <Typography
                            gutterBottom
                            className={classes.descriptionCell}
                            variant="h4"
                            component="h4"
                          >
                            {title}
                          </Typography>
                          <MuiBox>
                            <Box>
                              {' '}
                              <Typography variant="span" component="span">
                                <Translate id="drills.players" /> :
                              </Typography>{' '}
                              {`${minPlayers} - ${maxPlayers}`}{' '}
                            </Box>
                          </MuiBox>
                        </CardContent>
                      </CardActionArea>
                    </Box>
                    <CardActions className={classes.cardActions}>
                      <div className={classes.title}>
                        <MuiBox>
                          <Box>
                            {' '}
                            <Typography variant="span" component="span">
                              <AccessTimeIcon color="disabled" />
                            </Typography>{' '}
                            {duration} min{' '}
                          </Box>
                        </MuiBox>
                      </div>
                      <HeartIcon
                        isActive={getIsFavorite(drillId)}
                        entity="drills"
                        entityId={drillId}
                      />
                    </CardActions>
                  </CarouselItem>
                </>
              )
          )}
        </Carousel>
      </Paper>
    </>
  )
}

DrillsWidgetContainer.defaultProps = {
  drills: [],
  favoritesDrills: [],
  clubid: null,
}

DrillsWidgetContainer.propTypes = {
  drills: PROP_TYPES.arrayOfObjects,
  favoritesDrills: PROP_TYPES.arrayOfObjects,
  clubid: PROP_TYPES.arrayOfObjects,
}

export default connect(({ drills, favorites }) => ({
  drills: drills.items,
  favoritesDrills: favorites.items.drills,
}))(DrillsWidgetContainer)
