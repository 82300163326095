import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { subscription } from 'redux/modules'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import PROP_TYPES from 'constants/propTypes'

const Invoice = ({ retriveSessionOfBilling, history }) => {
  const [response, setResponce] = useState(null)

  useEffect(() => {
    const stripeSessionId = localStorage.getItem('stripe_sessionId')
    if (stripeSessionId) {
      retriveSessionOfBilling(
        `&checkout_session=${stripeSessionId}&capture=true`
      )
        .then(res => {
          setResponce(res.response.data)
        })
        .catch(error => {
          setResponce({ payment_status: 'unpaid' })
        })
    } else {
      history.push('/teams')
    }

    return function cleanup() {
      localStorage.removeItem('stripe_sessionId')
    }
  }, [])

  return (
    <div>
      Invoice:{' '}
      {response && (
        <h1>
          {response && response.payment_status === 'unpaid'
            ? 'Error please try again.'
            : `Thank you`}
        </h1>
      )}
    </div>
  )
}

Invoice.propTypes = {
  retriveSessionOfBilling: PROP_TYPES.func.isRequired,
  history: PROP_TYPES.shape().isRequired,
}

export default compose(
  withRouter,
  connect(
    null,
    {
      retriveSessionOfBilling: subscription.retriveSessionOfBilling,
    }
  )
)(Invoice)
