import React from 'react'
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Dialog,
  makeStyles,
  IconButton,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0.4),
    color: theme.palette.grey[500],
  },
}))

const AlertDialog = ({
  open,
  onClose,
  onSuccess,
  onCancel,
  submitButtonLabel,
  cancelButtonLabel,
  title,
  subTitle,
}) => {
  const classes = useStyles()

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id="alert-dialog-title">
        {title}
        <IconButton
          aria-label="close"
          size="medium"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {subTitle}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="contained">
          {cancelButtonLabel}
        </Button>
        <Button onClick={onSuccess} color="primary" variant="contained" autoFocus>
          {submitButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

AlertDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  submitButtonLabel: PropTypes.shape().isRequired,
  cancelButtonLabel: PropTypes.shape().isRequired,
  title: PropTypes.shape().isRequired,
  subTitle: PropTypes.shape().isRequired,
}
export default AlertDialog

