import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { DragDropContext } from 'react-beautiful-dnd'
import { Translate } from 'react-localize-redux'
import Grid from '@material-ui/core/Grid'
import GROUPS from '../../groupConstant'
import {
  GroupsList,
  PlayersList,
  NotAvailable,
  NotResponded,
} from './components'
import PROP_TYPES from 'constants/propTypes'
import { PLAYER_POSITIONS } from 'constants/player'

const GroupsAndParticipationContainer = ({
  players,
  changePlayers,
  groupsCount = 4,
  training_players,
}) => {
  const preparedTrainingPlayers = useMemo(() =>
    training_players.map((trainingPlayer) => {
      const { availability, relationships, group_id, uuid } = trainingPlayer
      const avatar =
        trainingPlayer.relationships.player?.relationships.avatar?.full_url
      const name = `${trainingPlayer.relationships.player.first_name} ${trainingPlayer.relationships.player.last_name}`
      const { position } = trainingPlayer.relationships.player
      const relation =
        (GROUPS[trainingPlayer.group_id] &&
          GROUPS[trainingPlayer.group_id].value) ||
        (trainingPlayer.availability ? position : 'notAvailable')
      const isSelected = trainingPlayer.isSelected ?? false

      return {
        uuid,
        availability,
        name,
        avatar,
        group_id,
        position,
        isSelected,
        relationships,
        relation,
        notAvailableReason: trainingPlayer.reason,
      }
    })
  )

  const isAnyPlayerSelected = useMemo(
    () => preparedTrainingPlayers.some(({ isSelected }) => isSelected),
    [preparedTrainingPlayers]
  )

  const isDragging = (uuid, draggableId) => {
    return uuid.split('_')[0] === draggableId.split('_')[2]
  }

  const onDragEnd = useCallback(
    ({ destination, draggableId }) => {
      if (!destination) {
        const newPlayers = preparedTrainingPlayers.map((trainingPlayer) => {
          if (
            !trainingPlayer.isSelected &&
            !isDragging(trainingPlayer.uuid, draggableId)
          ) {
            return trainingPlayer
          }
          return {
            ...trainingPlayer,
            relation: trainingPlayer.position,
            group_id: null,
            availability: 'AVAILABLE',
            isSelected: false,
          }
        })
        changePlayers(newPlayers)

        return
      }

      const { droppableId: relation, index } = destination

      const playersWithoutDraggables = preparedTrainingPlayers.filter(
        (trainingPlayer) => {
          return (
            !trainingPlayer.isSelected &&
            !isDragging(trainingPlayer.uuid, draggableId)
          )
        }
      )

      let availabilityStatus = 'AVAILABLE'
      let groupId = null
      if (destination.droppableId === 'notResponded') {
        availabilityStatus = 'NOT_RESPONDED'
      } else if (destination.droppableId === 'notAvailable') {
        availabilityStatus = 'NOT_AVAILABLE'
      } else {
        groupId = GROUPS.findIndex((GROUP) => {
          return GROUP.value === relation
        })
      }

      const draggablePlayers = preparedTrainingPlayers
        .filter((trainingPlayer) => {
          return (
            trainingPlayer.isSelected ||
            isDragging(trainingPlayer.uuid, draggableId)
          )
        })
        .map((trainingPlayer) => ({
          ...trainingPlayer,
          relation,
          isSelected: false,
          availability: availabilityStatus,
          group_id: groupId,
        }))

      const indexOfFirstItemWithTargetRelation =
        playersWithoutDraggables.findIndex(
          (trainingPlayer) => trainingPlayer.relation === relation
        )

      const indexForInsert = indexOfFirstItemWithTargetRelation
        ? indexOfFirstItemWithTargetRelation + index
        : index

      playersWithoutDraggables.splice(indexForInsert, 0, ...draggablePlayers)

      changePlayers(playersWithoutDraggables)
    },
    [preparedTrainingPlayers, changePlayers]
  )

  const changeIsSelectedById = (playerUuid) => {
    const newNormalizedPlayers = preparedTrainingPlayers.map(
      (trainingPlayer) => {
        if (trainingPlayer.uuid === playerUuid) {
          return { ...trainingPlayer, isSelected: !trainingPlayer.isSelected }
        }
        return trainingPlayer
      }
    )

    changePlayers(newNormalizedPlayers)
  }

  const changeIsSelectedByRelation = (relation, isSelected) => {
    const newNormalizedPlayers = preparedTrainingPlayers.map(
      (trainingPlayer) => {
        if (
          trainingPlayer.relation === relation &&
          trainingPlayer.availability !== 'NOT_AVAILABLE'
        ) {
          return { ...trainingPlayer, isSelected }
        }

        return trainingPlayer
      }
    )

    changePlayers(newNormalizedPlayers)
  }

  const filterPlayersByPosition = useMemo(
    () => (position) =>
      preparedTrainingPlayers.filter(
        (trainingPlayer) =>
          trainingPlayer.position === position &&
          trainingPlayer.availability === 'AVAILABLE' &&
          trainingPlayer.group_id === null
      )
  )

  const notAvailablePlayers = useMemo(() =>
    preparedTrainingPlayers.filter(
      (trainingPlayer) => trainingPlayer.availability === 'NOT_AVAILABLE'
    )
  )

  const notRespondedPlayers = useMemo(() =>
    preparedTrainingPlayers.filter(
      (trainingPlayer) => trainingPlayer.availability === 'NOT_RESPONDED'
    )
  )

  return (
    <div
      style={{
        margin: '0 8px',
        width: "100%"
      }}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <GroupsList
          groupsCount={groupsCount}
          players={preparedTrainingPlayers}
          changeIsSelectedById={changeIsSelectedById}
          isAnyPlayerSelected={isAnyPlayerSelected}
        />
        <Translate>
          {({ translate }) => (
            <Grid container spacing={2}>
              {PLAYER_POSITIONS.map((position) => (
                <Grid item xs={12} sm={6} md={3} key={position.value}>
                  <PlayersList
                    relation={position.value}
                    players={filterPlayersByPosition(position.value)}
                    name={translate(`player.${position.value}`)}
                    image={position.image}
                    changeIsSelectedById={changeIsSelectedById}
                    changeIsSelectedByRelation={changeIsSelectedByRelation}
                    isAnyPlayerSelected={isAnyPlayerSelected}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Translate>
        <NotAvailable
          players={notAvailablePlayers}
          changeIsSelectedById={changeIsSelectedById}
          isAnyPlayerSelected={isAnyPlayerSelected}
        />
        <NotResponded
          players={notRespondedPlayers}
          changeIsSelectedById={changeIsSelectedById}
          isAnyPlayerSelected={isAnyPlayerSelected}
        />
      </DragDropContext>
    </div>
  )
}

GroupsAndParticipationContainer.defaultProps = {
  players: [],
  changePlayers: () => 0,
  groupsCount: 4,
}

GroupsAndParticipationContainer.propTypes = {
  players: PROP_TYPES.arrayOfObjects,
  changePlayers: PROP_TYPES.func,
  groupsCount: PropTypes.oneOf([2, 3, 4]),
}

export default GroupsAndParticipationContainer
