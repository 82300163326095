import React, { useState, useCallback, useMemo } from 'react'
import { connect } from 'react-redux'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {
  Grid,
  Card,
  CardActionArea,
  CardActions,
  CardMedia,
  Typography,
  Button,
} from '@material-ui/core'
import { HeartIcon, SequenceModal } from 'containers'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import { ProcessingIcon } from 'components'
import { sequences as sequencesModule } from 'redux/modules'
import PROP_TYPES from 'constants/propTypes'

const MuiCard = withStyles(theme => ({
  root: {
    border: '1px solid #e8e8e8',
    '& .MuiCardContent-root': {
      padding: theme.spacing(2, 2, 0),
    },
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
}))(Card)
const useStyles = makeStyles(theme => ({
  media: {
    height: 134,
  },
  cardActions: {
    paddingLeft: theme.spacing(2),
  },
  title: {
    flex: '1 1 0',
  },
  PlayCircle: {
    position: 'absolute',
    top: '27%',
    height: 67,
    width: 67,
    padding: 0,
    backgroundColor: '#fff',
    borderRadius: '50%',
    left: theme.spacing(0),
    right: theme.spacing(0),
    margin: theme.spacing(0, 'auto'),
    '& .MuiSvgIcon-root': {
      fontSize: 67,
    },
  },
}))

const SequencesCarousel = ({
  favoritesSequences,
  players,
  updateSequence,
  deleteSequence,
}) => {
  const [selectedSequenceId, changeSelectedSequenceId] = useState(null)
  const classes = useStyles()
  const onHide = useCallback(() => changeSelectedSequenceId(null), [])

  const selectedSequence = useMemo(
    () => favoritesSequences.findById(selectedSequenceId),
    [selectedSequenceId]
  )

  return (
    <>
      {selectedSequenceId && (
        <SequenceModal
          onHide={onHide}
          sequence={selectedSequence}
          updateSequence={updateSequence}
          deleteSequence={deleteSequence}
          players={players}
          opem={Boolean(selectedSequenceId)}
        />
      )}
      <Grid container spacing={3}>
        {favoritesSequences.map(
          ({
            id,
            thumbnail_url: thumbnail,
            processed,
            title: titleValue,
            error_details: errorDetails,
          }) => (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={id}>
              <MuiCard>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image={processed === 1 && thumbnail}
                    processed={processed}
                  />
                  {processed === 1 ? (
                    <CardActionArea
                      className={classes.PlayCircle}
                      component={Button}
                      color="primary"
                      onClick={() => changeSelectedSequenceId(id)}
                    >
                      <PlayCircleOutlineIcon />
                    </CardActionArea>
                  ) : (
                    <ProcessingIcon
                      placeholder={processed === -1 && errorDetails}
                    />
                  )}
                </CardActionArea>

                <CardActions className={classes.cardActions}>
                  <Typography
                    gutterBottom
                    className={classes.title}
                    variant="h4"
                    component="h4"
                  >
                    {titleValue}
                  </Typography>

                  {processed === 1 ? (
                    <HeartIcon isActive entity="sequences" entityId={id} />
                  ) : (
                    <ProcessingIcon
                      placeholder={processed === -1 && errorDetails}
                    />
                  )}
                </CardActions>
              </MuiCard>
            </Grid>
          )
        )}
      </Grid>
    </>
  )
}

SequencesCarousel.defaultProps = {
  favoritesSequences: [],
  players: [],
}

SequencesCarousel.propTypes = {
  favoritesSequences: PROP_TYPES.arrayOfObjects,
  players: PROP_TYPES.arrayOfObjects,
  updateSequence: PROP_TYPES.func.isRequired,
  deleteSequence: PROP_TYPES.func.isRequired,
}

export default connect(
  ({ favorites, players }) => ({
    favoritesSequences: favorites.items.sequences,
    players: players.items,
  }),
  {
    updateSequence: sequencesModule.updateSequence,
    deleteSequence: sequencesModule.deleteSequence,
  }
)(SequencesCarousel)
