import React from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useStyles, MenuPaper } from '../styles'

const Source = ({ selectedValue, options, onChange, label, labelId, ...rest }) => {
  const classes = useStyles()

  return (
    <FormControl
      variant="outlined"
      margin="dense"
      className={classes.formControl}
    >
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        labelId={labelId}
        value={selectedValue}
        onChange={onChange}
        label={label}
        fullWidth
        MenuProps={{
          PaperProps: {
            component: MenuPaper,
          },
        }}
        {...rest}
      >
        {options.map((option) => (
          <MenuItem key={option.uuid} value={option.uuid}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

Source.propTypes = {
  selectedValue: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  labelId: PropTypes.string.isRequired,
}

export default Source
