import { handleActions, combineActions } from 'redux-actions'
import {
  actionsTypesGenerator,
  onRequest,
  onSuccess,
  onFailure,
  updateItems,
} from 'utils/reduxHelpers'
import initialState from '../initialState'

const namespace = 'TASK_GROUP'

const FETCH_TASK_GROUPS = actionsTypesGenerator(`FETCH_${namespace}S`)
const CREATE_TASK_GROUP = actionsTypesGenerator(`CREATE_${namespace}`)
const UPDATE_TASK_GROUP = actionsTypesGenerator(`UPDATE_${namespace}`)
const DELETE_TASK_GROUP = actionsTypesGenerator(`DELETE_${namespace}`)
const FETCH_TASK_GROUP = actionsTypesGenerator(`FETCH_${namespace}`)
const FETCH_TASK_GROUP_TASKS = actionsTypesGenerator(`FETCH_${namespace}_TASKS`)
const FETCH_MATCH_TASK_GROUPS = actionsTypesGenerator(
  `FETCH_MATCH_${namespace}S`
)

export const fetchTaskGroups = (teamId, params) => ({
  types: FETCH_TASK_GROUPS,
  callAPI: {
    method: 'GET',
    entity: 'Task Groups',
    path: `/teams/${teamId}/task-groups`,
    params,
  },
})
export const createTaskGroup = (teamId, data) => ({
  types: CREATE_TASK_GROUP,
  callAPI: {
    method: 'POST',
    entity: 'Task Group',
    path: `/teams/${teamId}/task-groups`,
    data,
  },
})
export const updateTaskGroup = (id, data) => ({
  types: UPDATE_TASK_GROUP,
  callAPI: {
    method: 'PUT',
    entity: 'Task Group',
    path: `/task-groups/${id}`,
    data,
  },
})
export const deleteTaskGroup = (taskGroupId, data) => ({
  types: DELETE_TASK_GROUP,
  callAPI: {
    method: 'DELETE',
    entity: 'Task Group',
    path: `/task-groups/${taskGroupId}`,
    data,
  },
})
export const fetchTaskGroup = (taskGroupId) => ({
  types: FETCH_TASK_GROUP,
  callAPI: {
    method: 'GET',
    entity: 'Task Group',
    path: `/task-groups/${taskGroupId}`,
  },
})
export const fetchTasks = (taskGroupId) => ({
  types: FETCH_TASK_GROUP_TASKS,
  callAPI: {
    method: 'GET',
    entity: 'Tasks',
    path: `/task-groups/${taskGroupId}/tasks`,
  },
})

export const fetchMatchTaskGroups = (matchUuid) => ({
  types: FETCH_MATCH_TASK_GROUPS,
  callAPI: {
    method: 'GET',
    entity: 'Task Groups',
    path: `/matches/${matchUuid}/tasks`,
    data: {
      preventToastNotification: true,
    },
  },
})

export default handleActions(
  {
    [combineActions(
      FETCH_TASK_GROUPS.request,
      CREATE_TASK_GROUP.request,
      UPDATE_TASK_GROUP.request,
      DELETE_TASK_GROUP.request,
      FETCH_TASK_GROUP.request,
      FETCH_TASK_GROUP_TASKS.request,
      FETCH_MATCH_TASK_GROUPS.request
    )]: onRequest,
    [FETCH_TASK_GROUPS.success]: (state, { response: { data = [] } }) =>
      onSuccess({ ...state, items: data }),

    [FETCH_MATCH_TASK_GROUPS.success]: (state, { response: { data = [] } }) =>
      onSuccess({ ...state, items: data }),

    [combineActions(
      CREATE_TASK_GROUP.success,
      UPDATE_TASK_GROUP.success,
      FETCH_TASK_GROUP.success
    )]: (state, { response: { data = {} } }) => {
      let allExistingData = [...state.items]
      if (Array.isArray(data)) {
        data.forEach((p) => {
          allExistingData = updateItems(allExistingData, p)
        })
      } else if (data?.completed === 1) {
        allExistingData = allExistingData.filterById(data.id)
      } else {
        allExistingData = updateItems(allExistingData, data)
      }

      return onSuccess({
        ...state,
        items: allExistingData,
        current: data,
      })
    },
    [combineActions(FETCH_TASK_GROUP_TASKS.success)]: (
      state,
      { response: { data = {} } }
    ) =>
      onSuccess({
        ...state,
        current: { ...state.current, tasks: data },
      }),
    [DELETE_TASK_GROUP.success]: (
      state,
      {
        response: {
          data: { id },
        },
      }
    ) =>
      onSuccess({
        ...state,
        items: state.items.filterById(id),
      }),
    [combineActions(
      FETCH_TASK_GROUPS.failure,
      CREATE_TASK_GROUP.failure,
      UPDATE_TASK_GROUP.failure,
      DELETE_TASK_GROUP.failure,
      FETCH_TASK_GROUP.failure,
      FETCH_TASK_GROUP_TASKS.failure,
      FETCH_MATCH_TASK_GROUPS.failure
    )]: onFailure,
  },
  initialState.taskGroups
)
