import React from 'react'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import { withStyles } from '@material-ui/core/styles'
import { Dialog, DialogContent, Typography, Box } from '@material-ui/core'
import Slide from '@material-ui/core/Slide'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0.1),
    color: theme.palette.grey[500],
  },
})

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />
})

const OwnModal = ({ title, children, onHide, boldTitle, titleRight, maxWidth, open }) => {
  const Title = boldTitle ? (
    <Typography variant="h4" component="h4">
      {title}
    </Typography>
  ) : (
    title
  )

  return (
    <Dialog
      open={open}
      onClose={onHide}
      TransitionComponent={Transition}
      centered
      onHide={onHide}
      fullWidth
      maxWidth={maxWidth}
    >
      <DialogTitle id="dialog-title" onClose={onHide}>
        <Typography variant="h4" component="h4">
          {' '}
          {Title}
        </Typography>
        <Box>{titleRight}</Box>
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  )
}

OwnModal.defaultProps = {
  title: '',
  children: <div />,
  boldTitle: false,
  titleRight: '',
  maxWidth: 'xl',
  open: false,
}

OwnModal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  onHide: PropTypes.func.isRequired,
  boldTitle: PropTypes.string,
  open: PropTypes.bool,
  titleRight: PropTypes.string,
  maxWidth: PropTypes.string,
}

export default OwnModal
