import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Paper, Box, Grid } from '@material-ui/core'
import { articles as articlesModule } from 'redux/modules'
import { CategoryHead, ArticleItem, Categories } from './components'
import PROP_TYPES from 'constants/propTypes'

const ArticlesCategoriesViewContainer = ({
  activeLocale,
  favoritesArticles,
  fetchArticles,
}) => {
  const [articles, changeArticles] = useState([])
  const thePath = window.location.href
  const category = [parseInt(thePath.split('/').pop(), 10)]
  useEffect(() => {
    fetchArticles({
      order_by: 'date',
      locale: activeLocale,
      categories: category,
      category_filter: 1,
    }).then(({ response: { data = [] } }) => changeArticles(data))
  }, [activeLocale])

  const myArticlesList = [...articles]
 
  const getIsFavorite = id => Boolean(favoritesArticles.findById(id))


  return (
    <>
     <CategoryHead selectedCategory={category} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={7} lg={9} xl={9}>
          <Paper component={Box} p={3}>
            <Grid container spacing={3}>
              {myArticlesList.map(article => (
                <ArticleItem
                  key={article.id}
                  article={article}
                  isFavorite={getIsFavorite(article.id)}
                />
              ))}
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={3} xl={3}>
          <Paper component={Box} p={3}>
            <Categories selectedCategory={category} />
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

ArticlesCategoriesViewContainer.defaultProps = {
  favoritesArticles: [],
}

ArticlesCategoriesViewContainer.propTypes = {
  activeLocale: PROP_TYPES.string.isRequired,
  favoritesArticles: PROP_TYPES.arrayOfObjects,
  fetchArticles: PROP_TYPES.func.isRequired,
}

export default connect(
  ({
    favorites,
    articles: {
      current: { articlesCategories },
    },
  }) => ({ favoritesArticles: favorites.items.articles, articlesCategories }),
  { fetchArticles: articlesModule.fetchArticles }
)(ArticlesCategoriesViewContainer)
