import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { players } from 'redux/modules'
import Loader from './Loader'
import PROP_TYPES from 'constants/propTypes'

const PlayerLoader = ({
  playerId,
  fetchTeamPlayer,
  children,
  ...restProps
}) => {
  const fetchMethod = params => fetchTeamPlayer(playerId, params)
  const triggers = [playerId]

  return (
    <Loader fetchMethod={fetchMethod} triggers={triggers} {...restProps}>
      {children}
    </Loader>
  )
}

PlayerLoader.propTypes = {
  children: PROP_TYPES.children.isRequired,
  playerId: PROP_TYPES.id.isRequired,
  fetchTeamPlayer: PropTypes.func.isRequired,
}

export default connect(
  null,
  { fetchTeamPlayer: players.fetchTeamPlayer }
)(PlayerLoader)
