import React, { useState } from 'react'
import PropTypes from 'prop-types'

const ImagesSlider = ({ images }) => {

  return (
    <>
      <img alt="drill_image" src={images[images.length-1].full_url} width="100%"/>
    </>
  )
}

ImagesSlider.defaultProps = {
  images: [],
}

ImagesSlider.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape().isRequired),
}

export default ImagesSlider
