import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import { favorites } from 'redux/modules'
import Loader from './Loader'
import PROP_TYPES from 'constants/propTypes'

const ENTITY_TO_MODULE_MAPPER = {
  articles: favorites.fetchFavoritesArticles,
  drills: favorites.fetchFavoritesDrills,
  trainingPackages: favorites.fetchFavoritesTrainingPackages,
  videos: favorites.fetchFavoritesVideos,
  sequences: favorites.fetchFavoritesSequences,
}

const FavoritesLoader = ({
  children,
  fetchMethod,
  activeLanguage: { code: locale },
  ...restProps
}) => (
  <Loader
    fetchMethod={() => fetchMethod({ locale })}
    triggers={[locale]}
    {...restProps}
  >
    {children}
  </Loader>
)

FavoritesLoader.propTypes = {
  children: PROP_TYPES.children.isRequired,
  entity: PROP_TYPES.string.isRequired,
  fetchMethod: PROP_TYPES.func.isRequired,
  activeLanguage: PROP_TYPES.shape().isRequired,
}

export default compose(
  connect(
    null,
    (dispatch, { entity }) => ({
      fetchMethod: params => dispatch(ENTITY_TO_MODULE_MAPPER[entity](params)),
    })
  ),
  withLocalize
)(FavoritesLoader)
