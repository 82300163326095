import React from 'react'
import { EventLoader } from 'loaders'
import { GeneralEventContainer } from 'containers'
import PropTypes from 'prop-types'

const GeneralEventPage = ({
  match: {
    params: { eventUuid },
  },
}) => (
  <EventLoader eventUuid={eventUuid}>
    <GeneralEventContainer />
  </EventLoader>
)

GeneralEventPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }),
}

export default GeneralEventPage
