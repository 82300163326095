import React, { useEffect, useState } from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Button,
  IconButton,
  Box,
  TextField,
} from '@material-ui/core'
import ShareIcon from '@material-ui/icons/Share'
import InfoIcon from '@material-ui/icons/Info'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import { Translate } from 'react-localize-redux'
import {
  deleteTrainingDrill,
  updateTrainingDrill,
  updateTrainingSessionDrills,
} from 'containers/ActivitiesContainer/api'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import UserActionDialog from 'components/material/Calender/UserActionDialog'
import PropTypes from 'prop-types'
import DrillViewDialog from 'components/DrillCard/components/DrillViewDialog'
import {
  DraggableItemContainer,
  DraggableArea,
  DurationControls,
  useStyles,
} from './styles'

const TrainingPlan = ({
  sessionDrills,
  handleShareClick,
  trainingUuid,
  isTrainingSeries,
  handleSave,
  onUpdateDrills,
}) => {
  const classes = useStyles()

  const [phasesWithDrills, setPhasesWithDrills] = useState([])
  const [isDialogOpen, setIsDialogOpen] = React.useState(false)
  const [totalDuration, setTotalDuration] = useState(0)
  const [selectedDrill, setSelectedDrill] = useState(false)
  const [drillViewDialogOpen, setDrillViewDialogOpen] = useState(false)

  const handleSaveConfirmation = () => {
    if (isTrainingSeries) {
      setIsDialogOpen(true)
    } else {
      handleSave('SINGLE')
    }
  }

  useEffect(() => {
    setPhasesWithDrills([
      {
        key: 'warmup',
        label: <Translate id="training.warmup" />,
        items: sessionDrills.filter((drill) => drill.session_part === 'warmup'),
      },
      {
        key: 'main_part',
        label: <Translate id="training.main_part" />,
        items: sessionDrills.filter(
          (drill) => drill.session_part === 'main_part'
        ),
      },
      {
        key: 'final_part',
        label: <Translate id="training.final_part" />,
        items: sessionDrills.filter(
          (drill) => drill.session_part === 'final_part'
        ),
      },
    ])
  }, [sessionDrills])

  const calculateTotalDuration = (phases) => {
    return phases
      .flatMap((phase) => phase.items)
      .reduce((total, item) => total + (item.customized_duration || 0), 0)
  }

  useEffect(() => {
    const newTotalDuration = calculateTotalDuration(phasesWithDrills)
    setTotalDuration(newTotalDuration)
  }, [phasesWithDrills])

  const handleDeleteDrill = (drillUuid) => {
    deleteTrainingDrill(drillUuid)
      .then(() => {
        onUpdateDrills((prevDrills) =>
          prevDrills.filter((drill) => drill.uuid !== drillUuid)
        )
      })
      .catch((error) => {
        console.error('Failed to delete drill:', error)
      })
  }

  const handleUpdateDuration = (drillUuid, newDuration) => {
    const updatedPhases = phasesWithDrills.map((phase) => {
      const updatedItems = phase.items.map((item) =>
        item.uuid === drillUuid
          ? { ...item, customized_duration: newDuration }
          : item
      )

      return { ...phase, items: updatedItems }
    })

    setPhasesWithDrills(updatedPhases)
    onUpdateDrills((prevDrills) =>
      prevDrills.map((drill) =>
        drill.uuid === drillUuid
          ? { ...drill, customized_duration: newDuration }
          : drill
      )
    )
    updateTrainingDrill(drillUuid, { customized_duration: newDuration }).catch(
      (error) => {
        console.error('Failed to update duration:', error)
      }
    )
  }

  const handleDecreaseDuration = (drill) => {
    if (drill.customized_duration > 0) {
      const newDuration = drill.customized_duration - 1
      handleUpdateDuration(drill.uuid, newDuration)
    }
  }

  const handleIncreaseDuration = (drill) => {
    const newDuration = drill.customized_duration + 1
    handleUpdateDuration(drill.uuid, newDuration)
  }

  const onDragEnd = (result) => {
    const { source, destination } = result
    if (!destination) return

    const sourcePhase = phasesWithDrills.find(
      (phase) => phase.key === source.droppableId
    )
    const destinationPhase = phasesWithDrills.find(
      (phase) => phase.key === destination.droppableId
    )

    if (!sourcePhase || !destinationPhase) return

    const sourceItems = [...sourcePhase.items]
    const destinationItems = [...destinationPhase.items]
    const [movedDrill] = sourceItems.splice(source.index, 1)

    if (sourcePhase.key !== destinationPhase.key) {
      movedDrill.session_part = destinationPhase.key
      destinationItems.splice(destination.index, 0, movedDrill)
    } else {
      sourceItems.splice(destination.index, 0, movedDrill)
    }

    const updateDrillOrders = (items) =>
      items.map((drill, index) => ({ ...drill, order: index + 1 }))

    const updatedPhasesWithDrills = phasesWithDrills.map((phase) => {
      if (phase.key === sourcePhase.key) {
        return { ...phase, items: updateDrillOrders(sourceItems) }
      }
      if (phase.key === destinationPhase.key) {
        return { ...phase, items: updateDrillOrders(destinationItems) }
      }

      return phase
    })
    setPhasesWithDrills(updatedPhasesWithDrills)

    const allUpdatedDrills = updatedPhasesWithDrills.flatMap(
      (phase) => phase.items
    )
    onUpdateDrills(allUpdatedDrills)

    updateTrainingSessionDrills(trainingUuid, {
      training_drills: allUpdatedDrills,
    }).catch((error) => {
      console.error('Failed to update drill order:', error)
      setPhasesWithDrills((prevPhases) =>
        prevPhases.map((phase) => {
          if (phase.key === sourcePhase.key) {
            return { ...phase, items: sourcePhase.items }
          }
          if (phase.key === destinationPhase.key) {
            return { ...phase, items: destinationPhase.items }
          }

          return phase
        })
      )
    })
  }
  const handleDrillClick = (item) => {
    setSelectedDrill(item.relationships.drill)
    setDrillViewDialogOpen(true)
  }

  const toggleDrillViewDialogOpen = () =>
    setDrillViewDialogOpen(!drillViewDialogOpen)

  return (
    <>
      {drillViewDialogOpen && (
        <DrillViewDialog
          drill={selectedDrill}
          hanldeOpenClose={toggleDrillViewDialogOpen}
          open={drillViewDialogOpen}
        />
      )}
      <Card>
        <CardHeader
          className={classes.header}
          title={
            <Typography variant="h4" component="h4">
              <Translate id="training.session-summary" />
            </Typography>
          }
          action={
            <IconButton onClick={handleShareClick}>
              <ShareIcon fontSize="small" />
            </IconButton>
          }
        />
        <CardContent>
          <DragDropContext onDragEnd={onDragEnd}>
            {phasesWithDrills.map((phase) => (
              <Droppable droppableId={phase.key} key={phase.key}>
                {(droppableProvided) => (
                  <Box
                    mb={1}
                    ref={droppableProvided.innerRef}
                    {...droppableProvided.droppableProps}
                  >
                    <Typography>{phase.label}</Typography>
                    <Box pt={1}>
                      <DraggableArea>
                        {phase.items.length > 0 ? (
                          phase.items.map((item, index) => (
                            <Draggable
                              draggableId={item.uuid}
                              index={index}
                              key={item.uuid}
                            >
                              {(draggableProvided) => (
                                <DraggableItemContainer
                                  ref={draggableProvided.innerRef}
                                  {...draggableProvided.draggableProps}
                                  {...draggableProvided.dragHandleProps}
                                >
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    width="100%"
                                  >
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      overflow="hidden"
                                      whiteSpace="nowrap"
                                      className={classes.drillTitle}
                                      onClick={() => handleDrillClick(item)}
                                    >
                                      <InfoIcon className={classes.infoIcon} />
                                      <Typography variant="body2" noWrap>
                                        {item.relationships.drill.title}
                                      </Typography>
                                    </Box>
                                    <IconButton
                                      onClick={() =>
                                        handleDeleteDrill(item.uuid)
                                      }
                                      size="small"
                                      className={classes.deleteButton}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Box>
                                  <DurationControls>
                                    <IconButton
                                      onClick={() =>
                                        handleDecreaseDuration(item)
                                      }
                                      size="small"
                                    >
                                      <RemoveIcon />
                                    </IconButton>
                                    <Box>
                                      <TextField
                                        value={`${item.customized_duration}'`}
                                      />
                                    </Box>
                                    <IconButton
                                      onClick={() =>
                                        handleIncreaseDuration(item)
                                      }
                                      size="small"
                                    >
                                      <AddIcon />
                                    </IconButton>
                                  </DurationControls>
                                </DraggableItemContainer>
                              )}
                            </Draggable>
                          ))
                        ) : (
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            align="center"
                          >
                            <Translate id="training.drag-drills" />
                          </Typography>
                        )}
                        {droppableProvided.placeholder}
                      </DraggableArea>
                    </Box>
                  </Box>
                )}
              </Droppable>
            ))}
          </DragDropContext>
          <Typography className={classes.duration}>
            <Translate id="training.training-duration" />:{' '}
            <strong>{`${totalDuration}'`}</strong>
          </Typography>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={handleSaveConfirmation}
            className={classes.button}
            size="large"
          >
            <Translate id="button.save" />
          </Button>
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            size="large"
            onClick={handleShareClick}
          >
            <Translate id="button.download-as-pdf" />
          </Button>
        </CardContent>
        <UserActionDialog
          open={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          onActionOne={() => {
            handleSave('SINGLE')
            setIsDialogOpen(false)
          }}
          onActionTwo={() => {
            handleSave('SINGLE_AND_FOLLOWING')
            setIsDialogOpen(false)
          }}
          title={<Translate id="training.update" />}
          subTitle={<Translate id="training.update-option" />}
          buttonOneLabel={<Translate id="training.update-this" />}
          buttonTwoLabel={<Translate id="training.update-this-following" />}
        />
      </Card>
    </>
  )
}

TrainingPlan.propTypes = {
  sessionDrills: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      session_part: PropTypes.string.isRequired,
      customized_duration: PropTypes.number.isRequired,
      drill: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  handleShareClick: PropTypes.func.isRequired,
  trainingUuid: PropTypes.string.isRequired,
  isTrainingSeries: PropTypes.bool.isRequired,
  handleSave: PropTypes.func.isRequired,
  onUpdateDrills: PropTypes.func.isRequired,
}

export default TrainingPlan
