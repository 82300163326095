import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { team } from 'redux/modules'
import Loader from './Loader'
import PROP_TYPES from 'constants/propTypes'
import { WRONG_PERMISSION_ROUTE } from 'constants/routes'

const TeamLoader = ({
  additionalParams,
  children,
  teamId,
  fetchTeam,
  ...restProps
}) => {
  return (
    <Loader
      fetchMethod={(params) => {
        return fetchTeam(teamId, {
          ...params,
          ...additionalParams,
        }).then((res) => {
          const teamData = res.response.data
          const canViewTeamDashboard = teamData.can.some((permission) => permission === 'view-invite_url')
          
          // This security check helps to restrict players to open team dashboard using direct link
          if (!canViewTeamDashboard) {
            window.location.href = WRONG_PERMISSION_ROUTE
          }
        })
      }}
      triggers={[teamId]}
      {...restProps}
    >
      {children}
    </Loader>
  )
}

TeamLoader.defaultProps = {
  additionalParams: {},
}

TeamLoader.propTypes = {
  children: PROP_TYPES.children.isRequired,
  teamId: PROP_TYPES.id.isRequired,
  fetchTeam: PropTypes.func.isRequired,
  additionalParams: PropTypes.shape(),
}

export default connect(null, { fetchTeam: team.fetchTeam })(TeamLoader)
