import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { matches } from 'redux/modules'
import {
  checkIsAfterDateTime,
  concatDateAndTimeOfTwoDatesWithUserTimezoneAndUtcFormat,
  formatDateTimeToServer,
  formatDateTimeToUtcDateTimeWithUserTimezone,
  formatUtcDateTimeToUserTimezoneDateTime,
} from 'utils/formatDate'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import { InnrHead } from 'containers'
import MatchInfoEditForm from './MatchInfoEditForm'
import PROP_TYPES from 'constants/propTypes'

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const MatchInfoEditDialog = ({
  open,
  handleClose,
  currentMatch,
  updateMatch,
  timezone,
}) => {
  const [matchData, setMatchData] = useState({
    start_datetime: formatUtcDateTimeToUserTimezoneDateTime(
      currentMatch.start_datetime,
      timezone
    ),
    end_datetime: currentMatch.end_datetime
      ? formatUtcDateTimeToUserTimezoneDateTime(currentMatch.end_datetime, timezone)
      : null,
    meeting_datetime: currentMatch.meeting_datetime
      ? formatUtcDateTimeToUserTimezoneDateTime(currentMatch.meeting_datetime, timezone)
      : null,
    location: currentMatch.location ?? '',
    lat: currentMatch.lat ?? '',
    lng: currentMatch.lng ?? '',
    street: currentMatch.stree ?? '',
    city: currentMatch.city ?? '',
    postal_code: currentMatch.postal_code ?? '',
    state: currentMatch.state ?? '',
    country: currentMatch.country ?? '',
  })
  const [dateTimeError, setDateTimeError] = useState(null)

  const handleDateChange = (uiDate) => {
    setMatchData({
      ...matchData,
      start_datetime: formatDateTimeToServer(uiDate),
    })
  }
  const handleMeetingTimeChange = (uiDate) => {
    setMatchData({
      ...matchData,
      meeting_datetime: formatDateTimeToServer(uiDate),
    })
  }
  const handleEndTimeChange = (uiDate) => {
    setMatchData({ ...matchData, end_datetime: formatDateTimeToServer(uiDate) })
  }

  const handleMatchInfoSubmit = (values) => {
    const {
      goals_scored,
      goals_received,
      type,
      opponent,
      home,
      details,
      availability_cutoff_time,
    } = values

    const tempMatchData = {
      ...matchData,
      start_datetime: formatDateTimeToUtcDateTimeWithUserTimezone(matchData.start_datetime,timezone),
      meeting_datetime: matchData.meeting_datetime
        ? concatDateAndTimeOfTwoDatesWithUserTimezoneAndUtcFormat(
            matchData.start_datetime,
            matchData.meeting_datetime,
            timezone
          )
        : null,
      end_datetime: matchData.end_datetime
        ? concatDateAndTimeOfTwoDatesWithUserTimezoneAndUtcFormat(
            matchData.start_datetime,
            matchData.end_datetime,
            timezone
          )
        : null,
    }

    const body = {
      goals_scored,
      goals_received,
      type,
      opponent,
      home,
      details,
      availability_cutoff_time,
      ...tempMatchData,
    }
    // Check if meeting time is after start time 
    if (
      checkIsAfterDateTime(
        tempMatchData.meeting_datetime,
        tempMatchData.start_datetime
      )
    ) {
      setDateTimeError('matches.meeting-time-error')

      return
    }
    // Check if end time is before start time 
    if (
      checkIsAfterDateTime(
        tempMatchData.start_datetime,
        tempMatchData.end_datetime
      )
    ) {
      setDateTimeError('matches.end-time-error')

      return
    }

    updateMatch(currentMatch.uuid, body).then(() => {
      handleClose()
    })
  }

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="edit-match-info-dialog-title"
        open={open}
      >
        <InnrHead title={<Translate id="matches.match-details" />}>
          <IconButton onClick={handleClose} size="small">
            <CloseIcon />
          </IconButton>
        </InnrHead>
        <DialogContent dividers>
          <MatchInfoEditForm
            initialValues={{ ...currentMatch, home: +currentMatch.home }} // On update match success home value comes as string and cause binding issue
            onSubmit={handleMatchInfoSubmit}
            handleDateChange={handleDateChange}
            handleEndTimeChange={handleEndTimeChange}
            handleMeetingTimeChange={handleMeetingTimeChange}
            datetimesAndLocationData={matchData}
            setMatchData={setMatchData}
            handleCancel={handleClose}
            dateTimeError={dateTimeError}
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}
MatchInfoEditDialog.propTypes = {
  currentMatch: PROP_TYPES.shape().isRequired,
  updateMatch: PROP_TYPES.func.isRequired,
  handleClose: PROP_TYPES.func.isRequired,
  open: PROP_TYPES.bool.isRequired,
  timezone: PROP_TYPES.string.isRequired,
}

export default connect(
  ({ matches: { current }, auth }) => ({
    currentMatch: current,
    timezone: auth.user.time_zone,
  }),
  { updateMatch: matches.updateMatch }
)(MatchInfoEditDialog)
