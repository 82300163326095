import React from 'react'
import { connect } from 'react-redux'
import { favorites } from 'redux/modules'
import FavoriteIcon from '@material-ui/icons/Favorite'
import { IconButton } from '@material-ui/core'
import PROP_TYPES from 'constants/propTypes'

const ENTITY_TO_MODULE_MAPPER = {
  articles: isActive =>
    isActive
      ? favorites.deleteArticleFromFavorites
      : favorites.addArticleToFavorites,
  drills: isActive =>
    isActive
      ? favorites.deleteDrillFromFavorites
      : favorites.addDrillToFavorites,
  trainingPackages: isActive =>
    isActive
      ? favorites.deleteTrainingPackageFromFavorites
      : favorites.addTrainingPackageToFavorites,
  videos: isActive =>
    isActive
      ? favorites.deleteVideoFromFavorites
      : favorites.addVideoToFavorites,
  sequences: isActive =>
    isActive
      ? favorites.deleteSequenceFromFavorites
      : favorites.addSequenceToFavorites,
}

const HeartIcon = ({ isActive, action }) => (
  <IconButton>
    <FavoriteIcon
      className="heart-icon"
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()

        action()
      }}
      color={isActive ? 'primary' : 'disabled'}
      fontSize="small"
    />
  </IconButton>
)

HeartIcon.defaultProps = {
  isActive: false,
}

HeartIcon.propTypes = {
  isActive: PROP_TYPES.bool,
  entity: PROP_TYPES.string.isRequired, // eslint-disable-line
  entityId: PROP_TYPES.id.isRequired, // eslint-disable-line
  action: PROP_TYPES.func.isRequired,
}

export default connect(
  null,
  (dispatch, { isActive, entity, entityId }) => ({
    action: () => dispatch(ENTITY_TO_MODULE_MAPPER[entity](isActive)(entityId)),
  })
)(HeartIcon)
