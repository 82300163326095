import React, { useState, useRef }  from 'react'
import {  } from '@material-ui/core/styles'
import { Avatar, Button, Box, Slider, IconButton, makeStyles } from "@material-ui/core";
import { Translate } from 'react-localize-redux'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import EditIcon from '@material-ui/icons/Edit';
import BallIcon from 'assets/images/ballicon.svg'
// import PhotoCamera from '@material-ui/icons/PhotoCamera'
import AvatarEditor from "react-avatar-editor";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    '& .MuiOutlinedInput-adornedEnd': {
      marginRight: '0',
    },
  },
  AvatarWrap :{
    width: 180, height: 180, borderRadius: 90, padding: 3, border: '2px dashed #ccc', margin: '0 auto 30px', position:'relative' 
  },
  AvatarImage: {
    width: 170, height: 170
  },
  AvatarEditIcon: {
    position:'absolute', top:60, right:-48
  },
  AvatarDialogActions: {
    justifyContent:'space-between'
  }
}))

const AvatarMuiEditor = ({data, updateImage}) => {
    let editor = "";
    const [open, setOpen] = React.useState(false);

    const hiddenFileInput = useRef(null)
     const hiddenFileInputDialog = useRef(null)

    const [picture, setPicture] = useState({
      cropperOpen: false,
      img: null,
      zoom: 2,
      croppedImg:BallIcon
    });
  
  const [imgSource, setImgSource] = useState(data.avatar
    ? data.avatar.full_url
    : picture.croppedImg  
  );
    const handleSlider = (event, value) => {
      setPicture({
        ...picture,
        zoom: value
      });
    };
  
    const handleCancel = () => {
      setPicture({
        ...picture,
        cropperOpen: false
      });
      setOpen(false)
    };
  
    const setEditorRef = (ed) => {
      editor = ed;
    };

  
    const handleClose = () => {
      setOpen(false);
    };
    const handleSave = () => {
      if (setEditorRef) {
        const canvasScaled = editor.getImageScaledToCanvas();
        const croppedImg = canvasScaled.toDataURL();
        updateImage(data.id,{image:croppedImg}).then(res => {
          setImgSource(res.response.data.avatar.full_url)
        })
        .catch(e => {
          console.error(`Avatar not updated. ${e}`)
        })
        setPicture({
          ...picture,
          img: null,
          cropperOpen: false,
          croppedImg
          
        });
      }
      setOpen(false)
    };
    const handleClick = () => {
    
      hiddenFileInput.current.click();
      setOpen(true);
    };
    const handleClickDialog = () => {
      hiddenFileInputDialog.current.click();   
    };

    const handleFileChange = (e) => {
      const url = URL.createObjectURL(e.target.files[0]);
      setPicture({
        ...picture,
        img: url,
        cropperOpen: true
      });
    };
    const classes = useStyles()

  return (
  <>
   <Box display="flex">
        <Box  className={classes.AvatarWrap} >
        <Avatar src={imgSource} className={classes.AvatarImage} />
        <IconButton
            color="primary"
            size="small"
            onClick={()=>handleClick()}
            className={classes.AvatarEditIcon}
          >
           <EditIcon/>
           <input type="file" accept="image/*"  ref={hiddenFileInput} onChange={handleFileChange} style={{display: 'none'}}  />
          </IconButton>     
         
        </Box>
        {picture.cropperOpen && (
        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Edit Player Image</DialogTitle>
        <DialogContent dividers>
        {picture.cropperOpen ? (
          <Box display="block">
            
            <AvatarEditor
              ref={setEditorRef}
              image={picture.img}
              width={200}
              height={200}
              border={50}
              color={[255, 255, 255, 0.6]} // RGBA
              rotate={0}
              scale={picture.zoom}
              style={{ border: '1px solid #f3f3f3' }}
            />
           </Box>
          ): <AvatarEditor
          ref={setEditorRef}
          image={imgSource}
          width={200}
          height={200}
          border={50}
          color={[0, 0, 0, 0.6]} // RGBA
          rotate={0}
          scale={picture.zoom}
          style={{ border: '1px solid #f3f3f3' }}
        />}
         <Slider
              aria-label="raceSlider"
              value={picture.zoom}
              min={1}
              max={10}
              step={0.1}
              onChange={handleSlider}
             />
        </DialogContent>
    
        <DialogActions className={classes.AvatarDialogActions}>
        <Button onClick={handleClickDialog}  variant="contained" autoFocus>
            <Translate id="button.replace"/>
            <input type="file" accept="image/*"  ref={hiddenFileInputDialog} onChange={handleFileChange} style={{display: 'none'}}  />
          </Button>
          <Box>
          <Button  color="secondary" onClick={handleCancel}>
            <Translate id="button.cancel"/>
          </Button>
          <Button onClick={handleSave} color="primary" variant="contained" autoFocus>
            <Translate id="button.save"/>
          </Button>
          </Box>
        </DialogActions>
      </Dialog>
        )}
      </Box>
  </>
  )
}

AvatarMuiEditor.propTypes = {
 
}

export default AvatarMuiEditor