import React from 'react'
import PropTypes from 'prop-types'
import {
  TableHead,
  TableCell,
  TableRow,
  TableSortLabel,
} from '@material-ui/core'
import { StickyTableCell } from './style/styled'
import { ATTENDANCE_COLUMNS } from 'constants/player'

const EnhancedTableHead = (props) => {
  const {
    classes,
    order,
    orderBy,
    onRequestSort,
    headCells,
    isSticky,
    isPerformance,
  } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }
  const StickyColumns = ATTENDANCE_COLUMNS

  return (
    <TableHead>
      {isSticky && (
        <>
          <TableRow>
            <StickyTableCell className={classes.headCellFs}>
              {StickyColumns.map((StickyColumn) => (
                <TableCell
                  key={StickyColumn.id}
                  className={classes.head}
                  align={StickyColumn.numeric ? 'left' : 'left'}
                >
                  <TableSortLabel
                    active={orderBy === StickyColumn.id}
                    direction={orderBy === StickyColumn.id ? order : 'asc'}
                    onClick={createSortHandler(StickyColumn.id)}
                  >
                    {StickyColumn.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </StickyTableCell>

            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                className={classes.head}
                align={headCell.numeric ? 'left' : 'left'}
              >
                {headCell.sortable ? (
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                    className={classes.tableSortLabelIcon}
                  >
                    {headCell.label}
                  </TableSortLabel>
                ) : (
                  headCell.label
                )}
              </TableCell>
            ))}
          </TableRow>
        </>
      )}
      {isPerformance && (
        <TableRow>
          {headCells.map((headCell, index) => (
            <TableCell
              key={headCell.id}
              className={classes.head}
              align={headCell.numeric ? 'left' : 'left'}
            >
              {index === 0 ? (
                headCell.label
              ) : (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                  className={classes.tableSortLabelIcon}
                >
                  {headCell.label}
                </TableSortLabel>
              )}
            </TableCell>
          ))}
        </TableRow>
      )}
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.shape().isRequired,
  headCells: PropTypes.shape().isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.shape().isRequired,
  isSticky: PropTypes.bool.isRequired,
}

export default EnhancedTableHead
