import React, { useMemo } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemIcon,
  Box,
} from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { createSubUrl } from 'utils/urlConstructor'
import { makeStyles } from '@material-ui/core/styles'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import PROP_TYPES from 'constants/propTypes'
import { ARTICLES_CATEGORIES_VIEW } from 'constants/routes'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0),
    '& .MuiListItemIcon-root': {
      minWidth: 0,
    },
  },
  innerChild: {
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0),
  },
}))

const Categories = ({
  articlesCategories,
  history,
  match,
  selectedCategory,
}) => {
  const [selectedIndex, setSelectedIndex] = React.useState(
    parseInt(selectedCategory, 10)
  )
  const handleCategory = id => {
    setSelectedIndex(id)
    history.push(createSubUrl(match, `${ARTICLES_CATEGORIES_VIEW}/${id}`))
    window.location.reload()
  }

  const firstLevelCategories = useMemo(
    () => articlesCategories.filter(category => category.parent_id === null),
    [articlesCategories]
  )
  const secondLevelCategories = useMemo(
    () =>
      articlesCategories.filter(category =>
        firstLevelCategories.findById(category.parent_id)
      ),
    [articlesCategories, firstLevelCategories]
  )

  const thirdLevelCategories = useMemo(
    () =>
      articlesCategories.filter(category =>
        secondLevelCategories.findById(category.parent_id)
      ),
    [articlesCategories, secondLevelCategories]
  )
  const classes = useStyles()

  return (
    <>
      <Box mb={2}>
        <Typography variant="h4">
          <Translate id="articles.categories" />
        </Typography>
      </Box>

      {firstLevelCategories.map(category => (
        <>
          <List
            component="nav"
            key={category.id}
            className={classes.root}
            dense="true"
          >
            <ListItem
              button
              selected={selectedIndex === category.id}
              onClick={() => handleCategory(category.id)}
            >
              <ListItemIcon>
                <NavigateNextIcon />
              </ListItemIcon>
              <ListItemText
                primary={category.title}
                activeCategories={category.title}
              />
            </ListItem>
            {secondLevelCategories.map(categoryLevel1 => (
              <>
                {categoryLevel1.parent_id === category.id ? (
                  <List
                    className={classes.innerChild}
                    key={categoryLevel1.id}
                    dense="true"
                  >
                    <ListItem
                      button
                      selected={selectedIndex === categoryLevel1.id}
                      onClick={() => handleCategory(categoryLevel1.id)}
                    >
                      <ListItemIcon>
                        <NavigateNextIcon />
                      </ListItemIcon>
                      <ListItemText primary={categoryLevel1.title} />
                    </ListItem>
                    {thirdLevelCategories.map(categoryLevel2 => (
                      <>
                        {categoryLevel2.parent_id === categoryLevel1.id ? (
                          <List
                            className={classes.innerChild}
                            key={categoryLevel2.id}
                            dense="true"
                          >
                            <ListItem
                              button
                              selected={selectedIndex === categoryLevel2.id}
                              onClick={() => handleCategory(categoryLevel2.id)}
                            >
                              <ListItemIcon>
                                <NavigateNextIcon />
                              </ListItemIcon>
                              <ListItemText primary={categoryLevel2.title} />
                            </ListItem>
                          </List>
                        ) : (
                          <div />
                        )}
                      </>
                    ))}
                  </List>
                ) : (
                  <div />
                )}
              </>
            ))}
          </List>
        </>
      ))}
    </>
  )
}

Categories.defaultProps = {
  articlesCategories: [],
}

Categories.propTypes = {
  articlesCategories: PROP_TYPES.arrayOfObjects,
  history: PROP_TYPES.history.isRequired,
  match: PROP_TYPES.match.isRequired,
  selectedCategory: PROP_TYPES.number.isRequired,
}

export default compose(
  withRouter,
  connect(({ articles: { current: { articlesCategories } } }) => ({
    articlesCategories,
  }))
)(Categories)
