import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { ageClasses } from 'redux/modules'
import Loader from './Loader'
import PROP_TYPES from 'constants/propTypes'

const AgeClassesLoader = ({ children, team, fetchClubAgeClasses }) => {
  return (
    <Loader fetchMethod={() => fetchClubAgeClasses(team.club_id)}>
      {children}
    </Loader>
  )
}

AgeClassesLoader.propTypes = {
  children: PROP_TYPES.children.isRequired,
  fetchClubAgeClasses: PropTypes.func.isRequired,
  team: PROP_TYPES.arrayOfObjects.isRequired,
}

export default connect(
  ({ team }) => ({
    team,
  }),
  {
    fetchClubAgeClasses: ageClasses.fetchClubAgeClasses,
  }
)(AgeClassesLoader)
