import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { TeamSelector } from 'containers'
import Dropdown from './Dropdown'
import PROP_TYPES from 'constants/propTypes'

const TeamsDropdown = (props) => {
  const {
    match: {
      params: { teamId },
    },
    teams: { items = [] },
  } = props

  const normalizedTeams = items.map(
    ({ name: title, id: value, plan, imgUrl, seasons, logo }) => {
      return {
        value,
        title,
        imgUrl,
        logo,
        plan,
        seasons,
      }
    }
  )
  const firstTeam = normalizedTeams.find((tm) => tm.value === +teamId)

  return (
    <TeamSelector>
      <Dropdown isTeam options={normalizedTeams} firstTeam={firstTeam} />
    </TeamSelector>
  )
}

TeamsDropdown.propTypes = {
  match: PROP_TYPES.match.isRequired,
  teams: PropTypes.shape().isRequired,
}

export default compose(
  withRouter,
  connect(({ teams }) => ({ teams }))
)(TeamsDropdown)
