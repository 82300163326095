import React, { useState } from 'react'
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Box,
  IconButton,
} from '@material-ui/core'
import { ExpandMore, ExpandLess } from '@material-ui/icons'
import PropTypes from 'prop-types'
import { useStyles } from '../styles'

const CategoryItem = ({ category, selectedCategories, onToggle, level }) => {
  const { uuid, relationships } = category
  const [collapsed, setCollapsed] = useState(false)
  const classes = useStyles()

  const handleCategoryToggle = () => {
    onToggle(uuid, !selectedCategories.includes(uuid))
  }

  const toggleCollapse = () => {
    setCollapsed(!collapsed)
  }

  return (
    <Box
      className={`${level === 0 ? classes.categorySectionBackground : ''} ${
        level === 1 ? classes.widthFull : ''
      }`}
    >
      <Box display="flex" alignItems="center">
        {relationships.children && (
          <IconButton
            size="small"
            onClick={toggleCollapse}
            disabled={relationships.children.length === 0}
            className={classes.collapsibleIcon}
          >
            {collapsed ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        )}
        <FormControlLabel
          className={`${classes.formControlLabel} ${
            level > 0 ? classes.subcategoryBackground : ''
          }`}
          control={
            <Checkbox
              checked={selectedCategories.includes(uuid)}
              onChange={handleCategoryToggle}
              value={category.uuid}
              className={classes.checkbox}
              checkedIcon={
                <span className={`${classes.icon} ${classes.checkedIcon}`} />
              }
              icon={<span className={classes.icon} />}
            />
          }
          label={category.title}
        />
      </Box>
      {!collapsed &&
        relationships.children &&
        relationships.children.length > 0 && (
          <FormGroup
            className={`${
              level === 2
                ? classes.flexDirectionColumn
                : classes.flexDirectionRow
            } ${classes.paddingLeft}`}
          >
            {relationships.children.map((child) => (
              <CategoryItem
                key={child.uuid}
                category={child}
                selectedCategories={selectedCategories}
                onToggle={onToggle}
                level={level + 1}
              />
            ))}
          </FormGroup>
        )}
    </Box>
  )
}
CategoryItem.defaultProps = {
  level: 0,
}

CategoryItem.propTypes = {
  category: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    relationships: PropTypes.oneOfType([
      PropTypes.shape({
        children: PropTypes.arrayOf(PropTypes.shape({})),
      }),
      PropTypes.array,
    ]).isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  selectedCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggle: PropTypes.func.isRequired,
  level: PropTypes.number,
}

export default CategoryItem
