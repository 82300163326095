import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import { trainingPackages } from 'redux/modules'
import Loader from './Loader'
import PROP_TYPES from 'constants/propTypes'

const TrainingPackageLoader = props => {
  const {
    children,
    trainingPackageUuid,
    fetchTrainingPackage,
    activeLanguage: { code: locale },
    ...restProps
  } = props

  return (
    <Loader
      fetchMethod={params =>
        fetchTrainingPackage(trainingPackageUuid, { ...params, locale })
      }
      triggers={[locale]}
      {...restProps}
    >
      {children}
    </Loader>
  )
}

TrainingPackageLoader.propTypes = {
  children: PROP_TYPES.children.isRequired,
  trainingPackageUuid: PROP_TYPES.string.isRequired,
  fetchTrainingPackage: PROP_TYPES.func.isRequired,
  activeLanguage: PROP_TYPES.shape().isRequired,
}

export default compose(
  connect(
    null,
    { fetchTrainingPackage: trainingPackages.fetchTrainingPackage }
  ),
  withLocalize
)(TrainingPackageLoader)
