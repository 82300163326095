import React from 'react'
import { MenuItem, Chip, Typography } from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import { makeStyles } from '@material-ui/core/styles'
import DropDownMenu from 'components/DropDownMenu'
import groupPlayersByPosition from 'utils/groupPlayersByPosition'
import PROP_TYPES from 'constants/propTypes'

const useStyles = makeStyles((theme) => ({
  numberChip: {
    width: 25,
    marginRight: theme.spacing(1),
    '& .MuiChip-label': {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
  },
  menuArrow: {
    '& .MuiList-root': {
      maxHeight: 340,
      overflow: 'auto',
      paddingTop: theme.spacing(0),
    },
    '& .MuiMenu-paper': {
      overflow: 'visible',
      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
      marginTop: theme.spacing(1.5),
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        margin: theme.spacing(0, 'auto'),
        width: 10,
        height: 10,
        backgroundColor: theme.palette.common.white,
        transform: 'translateY(-50%) rotate(45deg)',
        zIndex: 0,
      },
    },
  },
  positionHeading: {
    color: theme.palette.common.white,
    fontWeight: 600,
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(0.8, 1.8),
    margin: theme.spacing(0.2, 0, 0.2),
  },
}))

const PositionMenuItems = ({ players, position, handlePlayerSelect }) => {
  return players.map((p) => (
    <MenuItem
      key={p.uuid}
      id={position.id}
      onClick={() => handlePlayerSelect(position, p)}
    >
      <Chip
        label={p.number}
        className={useStyles().numberChip}
        color="primary"
        variant="outlined"
      />
      {` ${p.first_name} ${p.last_name}`}
    </MenuItem>
  ))
}

const PlayersListPopover = ({
  open,
  position,
  handleClose,
  handlePlayerSelect,
  players,
  anchorEl,
}) => {
  const classes = useStyles()

  const { goalkeepers, defenders, midfielders, strikers } =
    groupPlayersByPosition(
      players
        .filter((p) => !p.participant)
        .map((p) => ({
          ...p.relationships.player, // Mapping as normal player because groupPlayersByPosition work on player array only
          availability: p.availability,
          tag: p.tag,
          matchPlayerUuid: p.uuid,
        }))
    )

  return (
    <DropDownMenu
      id={position.id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      classNameLabel={classes.menuArrow}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Typography className={classes.positionHeading}>
        <Translate id="matches.goalkeeper" />
      </Typography>
      <PositionMenuItems
        players={goalkeepers}
        position={position}
        handlePlayerSelect={handlePlayerSelect}
      />
      <Typography className={classes.positionHeading}>
        <Translate id="matches.defender" />
      </Typography>
      <PositionMenuItems
        players={defenders}
        position={position}
        handlePlayerSelect={handlePlayerSelect}
      />
      <Typography className={classes.positionHeading}>
        <Translate id="matches.midfield" />
      </Typography>
      <PositionMenuItems
        players={midfielders}
        position={position}
        handlePlayerSelect={handlePlayerSelect}
      />
      <Typography className={classes.positionHeading}>
        <Translate id="matches.striker" />
      </Typography>
      <PositionMenuItems
        players={strikers}
        position={position}
        handlePlayerSelect={handlePlayerSelect}
      />
    </DropDownMenu>
  )
}

PlayersListPopover.propTypes = {
  open: PROP_TYPES.bool.isRequired,
  anchorEl: PROP_TYPES.shape().isRequired,
  position: PROP_TYPES.shape().isRequired,
  handleClose: PROP_TYPES.func.isRequired,
  handlePlayerSelect: PROP_TYPES.func.isRequired,
  players: PROP_TYPES.arrayOfObjects.isRequired,
}

export default PlayersListPopover
