import React from 'react'
import PricingBilling from '../PricingDashboard'

const TeamEditContainer = () => {
  React.useEffect(() => {
    return function cleanup() {}
  }, [])

  return <PricingBilling changeTab={e => e} />
}

TeamEditContainer.propTypes = {}

export default TeamEditContainer
