import { makeStyles, styled } from '@material-ui/core/styles'
import { Box, Card } from '@material-ui/core'
import { rgba } from 'polished'

export const DraggableItemContainer = styled(Card)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start !important',
  flexDirection: 'column',
  borderRadius: '8px',
  margin: '2px 0',
  backgroundColor: '#f9f9f9',
  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12)',
  width: '100%',
  padding: theme.spacing(1.5),
  maxWidth: 'calc(100% - 4px)',
  boxSizing: 'border-box',
  '& .MuiSvgIcon-root': {
    fontSize: '1.4rem',
    color: 'gray',
    margin: theme.spacing(0, 0.1),
  },
}))

export const DraggableArea = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  marginBottom: theme.spacing(0.4),
  padding: theme.spacing(0.8),
  borderRadius: 8,
  border: `dashed 3px ${rgba(theme.palette.primary.main, 1)}`,
  backgroundColor: rgba(theme.palette.primary.main, 0.2),
  minHeight: (props) => (props.isDraggingOver ? '50px' : ''),
  cursor: 'copy',
  outline: 'none',
}))

export const DurationControls = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1),

  '& .MuiIconButton-root': {
    border: '1px solid',
    borderRadius: 0,
    '& .MuiSvgIcon-fontSizeSmall': {
      fontSize: '1.1285714285714284rem',
    },
  },
  '& .MuiInput-underline:before, & .MuiInput-underline:hover:not(.Mui-disabled):before, & .MuiInput-underline:after':
    {
      borderBottom: 0,
    },
  '& .MuiFormControl-root': {
    marginBottom: theme.spacing(0),
  },
  '& .MuiInput-root': {
    width: 30,
    height: 30,

    '& .MuiInputBase-input': {
      textAlign: 'center',
      padding: '0',
    },
  },
  '& .MuiIconButton-sizeSmall': {
    padding: theme.spacing(0.6),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.6),
      width: 30,
      height: 30,
    },
  },
}))

export const useStyles = makeStyles((theme) => ({
  header: {
    paddingBottom: 0,
  },
  duration: {
    marginBottom: theme.spacing(2),
    fontSize: 16,
  },
  button: {
    margin: theme.spacing(1, 0),
  },
  infoIcon: {
    marginRight: theme.spacing(1),
  },
  deleteButton: {
    padding: 0,
    flexShrink: 0,
  },
  sessionCategoryImage: {
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  drillTitle: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}))
