import React from 'react'
import { connect } from 'react-redux'
import { LineupCreateEditForm } from 'containers'
import { lineups as lineupsModule } from 'redux/modules'
import { createSubUrl } from 'utils/urlConstructor'
import { LINEUPS_DASHBOARD_ROUTE } from 'constants/routes'
import PROP_TYPES from 'constants/propTypes'

const LineupEditContainer = ({ history, match, lineup, updateLineup }) => {
  const onSubmit = values => {
    updateLineup(match.params.lineupUuid, values).then(() =>
      history.push(createSubUrl(match, LINEUPS_DASHBOARD_ROUTE))
    )
  }

  return (
    <LineupCreateEditForm
      form="editLineup"
      initialValues={lineup}
      caption="Edit Lineup"
      onSubmit={onSubmit}
    />
  )
}

LineupEditContainer.propTypes = {
  history: PROP_TYPES.history.isRequired,
  match: PROP_TYPES.match.isRequired,
  lineup: PROP_TYPES.shape().isRequired,
  updateLineup: PROP_TYPES.func.isRequired,
}

export default connect(
  ({ lineups }) => ({
    lineup: lineups.current,
  }),
  {
    updateLineup: lineupsModule.updateLineup,
  }
)(LineupEditContainer)
