import React from 'react'
import {
  Avatar,
  Box,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  makeStyles,
} from '@material-ui/core'
import { Rating } from '@material-ui/lab'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import { Translate } from 'react-localize-redux'
import RatingNotePlayerPopover from './RatingNotePlayerPopover'
import InputPlayingMinutes from './InputPlayingMinutes'
import PROP_TYPES from 'constants/propTypes'

const useStyles = makeStyles((theme) => ({
  tablerow: {
    '& .MuiAvatar-root': {
      marginRight: theme.spacing(1.5),
      height: 35,
      width: 35,
    },
  },
  inputNumberStyled: {
    minWidth: 100,
  },
  [theme.breakpoints.down('sm')]: {
    tablerow: {
      '& .MuiTableCell-root': {
        padding: theme.spacing(1,),
      },
      '& .MuiInputBase-root': {
        width: 100
      }
    },
   
  }
}))

const RatePlayerTableRow = ({
  matchPlayers,
  onChangeRating,
  onChangePlayerTime,
}) => {
  const classes = useStyles()

  return (
    <>
      {matchPlayers.map((matchPlayer) => (
        <TableRow key={matchPlayer.uuid} className={classes.tablerow}>
          <TableCell component="th" scope="row">
            <Box display="flex" alignItems="center">
              <Avatar
                alt={matchPlayer.first_name}
                src={matchPlayer.player.relationships.avatar?.full_url}
              />
              {matchPlayer.player.first_name} {matchPlayer.player.last_name}
            </Box>
          </TableCell>

          <TableCell>
            <Box component="span" style={{ display: 'flex' }}>
              <Rating
                size="small"
                precision={0.5}
                value={matchPlayer.rating || 0}
                onChange={(e, v) => onChangeRating(v, matchPlayer.uuid)}
              />
              {matchPlayer.rating > 0 && (
                <Tooltip
                  title={<Translate id="button.reset" />}
                  placement="top"
                  arrow
                >
                  <IconButton
                    size="small"
                    aria-label="delete"
                    onClick={() => onChangeRating(0, matchPlayer.uuid)}
                  >
                    <RotateLeftIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </TableCell>
          <TableCell>
            <RatingNotePlayerPopover
              matchPlayer={matchPlayer}
            />
          </TableCell>
          <TableCell>
            <InputPlayingMinutes
              initialValue={+matchPlayer.playing_minutes}
              matchPlayerUuid={matchPlayer.uuid}
              onChangePlayerTime={onChangePlayerTime}
            />
          </TableCell>
        </TableRow>
      ))}
    </>
  )
}
RatePlayerTableRow.defaultProps = {
  matchPlayers: [],
}
RatePlayerTableRow.propTypes = {
  matchPlayers: PROP_TYPES.arrayOf(PROP_TYPES.shape().isRequired),
  onChangeRating: PROP_TYPES.func.isRequired,
  onChangePlayerTime: PROP_TYPES.func.isRequired,
}
export default RatePlayerTableRow
