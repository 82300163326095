import { combineActions, handleActions } from 'redux-actions'
import {
  actionsTypesGenerator,
  onRequest,
  onSuccess,
  onFailure,
} from 'utils/reduxHelpers'
import initialState from '../initialState'

const namespace = 'USER'
const FETCH_USER = actionsTypesGenerator(`FETCH_${namespace}`)
const UPDATE_USER = actionsTypesGenerator(`UPDATE_${namespace}`)
const REVOKE_USER_ROLE = actionsTypesGenerator(`REVOKE_${namespace}_ROLE`)

export const fetchUser = () => ({
  types: FETCH_USER,
  callAPI: {
    method: 'GET',
    entity: 'User',
    path: `/user`,
  },
})

export const updateUser = (data) => ({
  types: UPDATE_USER,
  callAPI: {
    method: 'PATCH',
    entity: 'User',
    path: `/v2/users/${data.id}`,
    data,
  },
})

export const revokeUserRole = (data) => ({
  types: REVOKE_USER_ROLE,
  callAPI: {
    method: 'DELETE',
    entity: 'User role',
    path: `${data.staffUuid ? `/staff` : `/player`}-user-roles/${
      data.roleUuid
    }`,
    data,
  },
})

export default handleActions(
  {
    [combineActions(
      FETCH_USER.request,
      UPDATE_USER.request,
      REVOKE_USER_ROLE.request
    )]: onRequest,
    [UPDATE_USER.success]: (state, { response: { data = {} } }) => {
      return onSuccess({
        ...state,
        current: data,
      })
    },
    [FETCH_USER.success]: (state, { response: { data } }) => {
      return onSuccess({
        ...state,
        current: data,
      })
    },
    [combineActions(
      FETCH_USER.failure,
      UPDATE_USER.failure,
      REVOKE_USER_ROLE.failure
    )]: onFailure,
  },
  initialState.users
)
