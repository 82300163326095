import React from 'react'

const baseWidth = 25
const baseHeight = 25

const applyFontSize = (baseSize, fontSize) => {
  if (typeof fontSize === 'number') {
    return fontSize
  }

  const sizeMap = {
    small: 20,
    medium: 24,
    large: 35,
  }

  return sizeMap[fontSize] || baseSize
}

const YellowCardIcon = ({ fontSize = 'medium' }) => (
  <svg
    width={applyFontSize(baseWidth, fontSize)}
    height={applyFontSize(baseHeight, fontSize)}
    viewBox="0 0 10 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_71_1637)">
      <rect
        x="1"
        y="-0.000183105"
        width="8"
        height="14"
        rx="0.5"
        fill="#FDE100"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_71_1637"
        x="0"
        y="-0.000183105"
        width="10"
        height="16"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_71_1637"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_71_1637"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)
export default YellowCardIcon
