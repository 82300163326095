import React from 'react'
import PropTypes from 'prop-types'
import {
  DialogActions,
  Typography,
  Breadcrumbs,
  Hidden,
} from '@material-ui/core'
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined'
import AccessTimeIcon from '@material-ui/icons/AccessTime'

const DrillFooter = ({ drill, fetchDrill, classes }) => {
  const {
    uuid,
    age_class: ageClass,
    min_players: minPlayers,
    max_players: maxPlayers,
    duration,
  } = drill

  return (
    <Hidden only={['xl', 'lg', 'md']}>
      <DialogActions className={classes.dialogActions}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="inherit" className={classes.link}>
            <PersonAddOutlinedIcon className={classes.icon} />
            {ageClass}
          </Typography>
          <Typography color="inherit" className={classes.link}>
            <PeopleOutlineIcon className={classes.icon} />
            {maxPlayers > '0'
              ? `${minPlayers} - ${maxPlayers}`
              : `min ${minPlayers}`}
          </Typography>
          <Typography color="inherit">
            <AccessTimeIcon className={classes.icon} />
            {duration}min
          </Typography>
        </Breadcrumbs>
      </DialogActions>
    </Hidden>
  )
}
DrillFooter.defaultProps = {}
DrillFooter.propTypes = {
  drill: PropTypes.shape().isRequired,
  classes: PropTypes.func.isRequired,
}

export default DrillFooter
