/* eslint-disable */
import React from 'react'
import { withLocalize, Translate } from 'react-localize-redux'
import { makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import {
  AppBar,
  Toolbar,
  Box,
  Tooltip,
  IconButton,
  MenuItem,
} from '@material-ui/core'
import { setLocale, getLocale } from 'utils/localstorage'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { SubLink } from 'containers'
import { SelectedMenu } from 'components'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import Logo from 'assets/images/coachbetter_Logo_Black.svg'
import { LOGOUT_ROUTE } from 'constants/routes'
import { themes as themesModule, auth as authModule } from 'redux/modules'
import { LogoImage } from '../../../containers/NavBar/styledComponents'
import languages from 'constants/languages'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  link: {
    flex: '1 1 0',
  },
  Avatar: {
    width: 'auto',
    maxWidth: '95%',
  },
  select: {
    minWidth: '70px',
    marginRight: '10px',
  },
  formControl: (props) => ({
    '& .MuiSelect-icon': {
      top: `calc(50% - 14px)`,
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: props.primarycolor,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: props.primarycolor,
    },
  }),
  content: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingTop: '4rem',
    width: 1,
    margin: '0 auto',
  },
  logOutIconBtn: (props) => ({
    color: props.primarycolor,
  }),
}))

const TeamsHeader = ({
  children,
  teams,
  match,
  getClubTheme,
  clubTheme,
  setActiveLanguage,
  user,
  updateUser,
}) => {
  const classes = useStyles(clubTheme)

  const {
    params: { locale, teamId },
  } = match

  const [language, changeLanguage] = React.useState(getLocale('locale'))
  setLocale(language)

  const currentTeam = teams
    ? teams.find((team) => {
        return team.id == +teamId
      })
    : []
  const handleChange = (event) => {
    const lang = event.target.value
    changeLanguage(lang)
    setActiveLanguage(lang)
    setLocale(lang)
    updateUser({ id: user.id, language: lang, preventToastNotification: true })
  }
  React.useEffect(() => {
    if (currentTeam && currentTeam.club && currentTeam.club.theme) {
      getClubTheme(currentTeam.club.id)
    }
  }, [])

  return (
    <>
      <CssBaseline />
      <Box className={classes.root}>
        <AppBar position="fixed" color="" elevation={0}>
          <Toolbar variant="regular">
            <Box className={classes.link}>
              <SubLink to="/">
                <LogoImage
                  src={clubTheme ? clubTheme.logo : Logo}
                  className={classes.Avatar}
                  alt="Coachbetter"
                />{' '}
              </SubLink>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              style={{ minWidth: '100px' }}
            >
              <SelectedMenu
                id="language"
                value={language}
                className={classes.formControl}
                onChange={handleChange}
                label={<Translate id="login.language" />}
              >
                {languages.map(({ code, name }) => (
                  <MenuItem key={code} value={code}>
                    {name}
                  </MenuItem>
                ))}
              </SelectedMenu>
              <Box ml={0.5}>
                <SubLink to={LOGOUT_ROUTE}>
                  <Tooltip title={<Translate id="navbar.logout" />} arrow>
                    <IconButton
                      variant="contained"
                      size="medium"
                      color="primary"
                      className={classes.logOutIconBtn}
                    >
                      <ExitToAppIcon />
                    </IconButton>
                  </Tooltip>
                </SubLink>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        <Box className={classes.content}>{children}</Box>
      </Box>
    </>
  )
}

TeamsHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.arrayOf(PropTypes.element.isRequired).isRequired,
  ]).isRequired,
  theme: PropTypes.oneOfType([
    PropTypes.shape().isRequired,
    PropTypes.oneOf([null]).isRequired,
  ]).isRequired,
  teams: PropTypes.arrayOf(PropTypes.object.isRequired),
  match: PropTypes.shape().isRequired,
  getClubTheme: PropTypes.func.isRequired,
  setActiveLanguage: PropTypes.func.isRequired,
}

export default compose(
  connect(
    ({ auth }) => ({
      user: auth.user,
    }),
    {
      getClubTheme: themesModule.getClubTheme,
      updateUser: authModule.updateUser,
    }
  ),
  withLocalize,
  withRouter
)(TeamsHeader)
