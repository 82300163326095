import React from 'react'
import {
  Avatar,
  Box,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  TableBody,
  Typography,
  Grid,
} from '@material-ui/core'
import PlayersTableHead from './PlayersTableHead'
import AvailabilitySelectMenu from 'components/AvailabilitySelectMenu'
import PropTypes from 'prop-types'
import { PLAYER_AVAILABILITY_TAGS } from 'constants/match'
import { useStyles } from './styles'

const PlayersTable = ({ eventPlayers }) => {
  const classes = useStyles()

  return (
    <TableContainer className={classes.root}>
      <Table size="small" stickyHeader aria-label="sticky table">
        <PlayersTableHead />
        <TableBody>
          {eventPlayers.map((player) => (
            <TableRow
              key={player.uuid}
              className={player.reason && classes.tableRow}
            >
              <TableCell
                style={{ minWidth: 20 }}
                className={classes.textAlignCenter}
              >
                {player.number}
              </TableCell>
              <TableCell>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="baseline"
                >
                  <Box display="flex" alignItems="center">
                    <Avatar
                      alt={player.first_name}
                      src={player.avatar?.full_url || ''}
                    />
                    <Typography noWrap>
                      {player.first_name} {player.last_name}
                    </Typography>
                  </Box>
                  {player.reason && (
                    <Typography variant="caption">
                      <span className={classes.reason}>Reason:</span>{' '}
                      {player.reason}
                    </Typography>
                  )}
                </Grid>
              </TableCell>
              <TableCell className={classes.textAlignCenter}>
                <AvailabilitySelectMenu
                  menuItems={PLAYER_AVAILABILITY_TAGS}
                  buttonLabel="Tag"
                  defaultSelectedItem={player.availability?.toLowerCase()}
                  eventPlayerUuid={player.eventPlayerUuid}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
PlayersTable.defaultProps = {
  eventPlayers: [],
}
PlayersTable.propTypes = {
  eventPlayers: PropTypes.arrayOf(PropTypes.object.isRequired),
}
export default PlayersTable
