import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { formatDateFromServer, EUR_STANDARD } from 'utils/formatDate'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'

const DateInput = ({
  input: { value, onChange },
  meta = {},
  label,
  size = '',
  variant = '',
  margin = '',
  id = '',
  name = '',
  formatDate,
  min,
  max,
  placeholder,
  ...rest
}) => {
  const isInvalid = meta.touched && meta.error

  const [selectedDate, setSelectedDate] = React.useState(formatDate ? formatDateFromServer(value)[0] : value);

  const handleDateChange = (date) => {
    if (moment(date).isValid()) {
      onChange(date)
      setSelectedDate(date)
    }
  }

  return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        value={selectedDate}
        onChange={handleDateChange}
        label={label}
        format={formatDate || EUR_STANDARD}
        placeholder={placeholder}
        margin={margin}
        inputVariant="outlined"
        variant={variant}
        error={isInvalid}
        InputAdornmentProps={{ position: 'end', variant: 'standard' }}
        helperText={isInvalid}
        size={size}
        id={id}
        name={name}
        min={min}
        max={max}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  )
}

DateInput.defaultProps = {
  meta: {},
  label: '',
  size: 'small',
  variant: 'outlined',
  margin: 'none',
  id: '',
  name: '',
  formatDate: false,
  min: null,
  max: null,
  placeholder:null
}

DateInput.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape(),
  label: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
  margin: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  formatDate: PropTypes.bool,
  min: PropTypes.string,
  max: PropTypes.string,
  placeholder: PropTypes.string,
  
}

export default DateInput
