import React, { useEffect, useState } from 'react'
import { Translate } from 'react-localize-redux'
import {
  Dialog,
  Button,
  Checkbox,
  FormControlLabel,
  DialogContent,
  Box,
  Typography,
  TextField,
  DialogActions,
  DialogTitle,
  MenuItem,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { players as playersModule } from '../../../../redux/modules'
import useStyles from './RemovePlayerAccessStyles'

const TransferDestinationDialog = ({
  playerUuid,
  open,
  onClose,
  onChecked,
  setChecked,
  onSubmit,
  fetchTransferDestinations,
}) => {
  const [destinationTeams, setDestinationTeams] = useState([])
  const [teamUuid, setTeamUuid] = useState(null)

  useEffect(() => {
    if (!open) {
      return
    }

    // reset
    setDestinationTeams([])
    setTeamUuid(null)

    fetchTransferDestinations(playerUuid).then(({ response: { data } }) => {
      setDestinationTeams(data)
    })
  }, [open])

  const handleChange = (event) => {
    setChecked(event.target.checked)
  }
  const handleTeamChange = (e) => {
    setTeamUuid(e.target.value)
    // Prevent page crash due to data grid predefined method calling on Select list change
    e.stopPropagation()
  }

  const handleSubmit = () => {
    onSubmit(teamUuid, onChecked)
  }

  const classes = useStyles()

  // @todo: #transfer add translations
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" className={classes.root}>
      <DialogTitle>
        <Translate id="movePlayers.transfer-player" />
      </DialogTitle>
      <DialogContent>
        <List component="ul" disablePadding className={classes.orderedList}>
          <ListItem className={classes.listItem}>
            <ListItemText
              primary={
                <Typography color="textSecondary">
                  <Translate id="movePlayers.a-transferred-player-will-lose-access-to-the-current-team" />
                </Typography>
              }
            />
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText
              primary={
                <Typography color="textSecondary">

                  <Translate id="movePlayers.all-players-data-will-be-stored-in-the-transfer-history-and-shared-with-the-destination-team" />
                </Typography>
              }
            />
          </ListItem>
        </List>

        <TextField
          value={teamUuid}
          onChange={handleTeamChange}
          select
          label={<Translate id="menu.team" />}
          placeholder= {<Translate id="movePlayers.select-the-team-that-the-player-has-to-be-transferred-to" />}
          variant="outlined"
          required
          size="small"
        >
          {destinationTeams.map((t) => (
            <MenuItem key={t.uuid} value={t.uuid}>
              {t.name}
            </MenuItem>
          ))}
        </TextField>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={onChecked}
                onChange={handleChange}
                color="primary"
              />
            }
            className={classes.formControlLabel}
            label={
              <Translate id="movePlayers.remove-access-for-attached-parent-from-this-team" />
            }
          />
          <Typography className={classes.checkedLabel} color="textSecondary">
            <Translate id="movePlayers.this-will-remove-this-players-access-from-your-team" />
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
      <Button onClick={onClose} variant="contained">
          <Translate id="button.cancel" />
        </Button>
        <Button
          disabled={!teamUuid}
          onClick={handleSubmit}
          color="primary"
          variant="contained"
        >
          <Translate id="button.complete" />
        </Button>
       
      </DialogActions>
    </Dialog>
  )
}

TransferDestinationDialog.propTypes = {
  playerUuid: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  fetchTransferDestinations: PropTypes.func.isRequired,
}

export default connect(null, {
  fetchTransferDestinations: playersModule.fetchPlayerTransferDestinations,
})(TransferDestinationDialog)
