import React from 'react'
import { connect } from 'react-redux'
import { clubs } from 'redux/modules'
import { useParams } from 'react-router-dom'
import Loader from './Loader'
import PROP_TYPES from 'constants/propTypes'
import { WRONG_PERMISSION_ROUTE } from 'constants/routes'

/**
 * Load and store current team's club.
 */
const ClubLoader = ({
  children,
  fetchClub,
  teams,
  ...rest
}) => {

  // get teamId from URL
  // @todo: #roles get current team id from state
  const { teamId } = useParams()
  const parsedTeamId = parseInt(teamId, 10)

  const team = teams.find(t => t.id === parsedTeamId)

  // This is a teamporary fix untill we fix loaders
  // @tofix Remove this and it should be handled inside TeamLoader
  if (!team) {
    window.location.href = WRONG_PERMISSION_ROUTE
  }

  return (
    <>
      { team && (
        <Loader
          fetchMethod={ () => fetchClub(team.club_id) }
          triggers={ [ team.club_id ] }
          { ...rest }
        >
          { children }
        </Loader>
      ) }
    </>
  )
}

ClubLoader.propTypes = {
  children:PROP_TYPES.children.isRequired,
  fetchClub:PROP_TYPES.func.isRequired,
  teams:PROP_TYPES.arrayOfObjects.isRequired
}

export default connect(
  ({ teams }) => ({
    teams: teams.items
  }),
  { fetchClub:clubs.fetchClub }
)(ClubLoader)
