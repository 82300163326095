import React from 'react'
import SeasonsContainer from './SeasonsContainer'
import PROP_TYPES from 'constants/propTypes'

const Seasons = ({ match }) => <SeasonsContainer match={match} />

Seasons.propTypes = {
  match: PROP_TYPES.match.isRequired,
}

export default Seasons
