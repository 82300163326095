import React from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { styled } from '@material-ui/core/styles'
import PROP_TYPES from 'constants/propTypes'

const TrialsNoteDiv = styled('div')({
  color: 'black',
  margin: '0 20px 0 auto',
});

const EditButton = styled('a')({
  borderRadius: '5px',
  color: 'white',
  display: 'inline-block',
  textAlign: 'center',
  padding: '5px 10px',
  color: 'var(--dark-green)',
  margin: '0 5px',
  fontSize: '14px',
})

const editTeam = (id, seasonId) => {
  return `/team/${id}/season/${seasonId}/current-team/true/edit`;
}

const TrialsNote = (props) => {
  const {
    history,
    match: {
      params: { teamId, seasonId },
    },
    teams: { items = [] },
  } = props

  const teamTrialDays = items.filter((team) => {
    return team.id == teamId
  })

  if (teamId && teamTrialDays.length < 1) {
    history.push('/teams')
  }

  return (
    <Translate>
      {({ translate }) => (
        <TrialsNoteDiv>
          {teamTrialDays.length > 0 &&
            teamTrialDays[0].trialEndsAt !== null &&
            teamTrialDays[0].paid !== 1 &&
            teamTrialDays[0].trialEndsAt > 0 && (
              <>
                {translate('checkout.trials_note_congrats')}
                <EditButton href={editTeam(teamId, seasonId)}>
                  <Translate id="button.upgrade-now" />
                </EditButton>
                {teamTrialDays[0].trialEndsAt} $
                {translate('checkout.trials_note')}
                {translate('checkout.just49')}
              </>
            )}

          {teamTrialDays.length > 0 && teamTrialDays[0].trialEndsAt === null && (
            <div>
              <Translate id="checkout.trials-note-existing-user" />{' '}
              <EditButton href={editTeam(teamId, seasonId)}>
                <Translate id="button.upgrade-now" />
              </EditButton>
            </div>
          )}
        </TrialsNoteDiv>
      )}
    </Translate>
  )
}

TrialsNote.propTypes = {
  match: PROP_TYPES.match.isRequired,
  teams: PropTypes.shape().isRequired,
  history: PROP_TYPES.history.isRequired,
}

export default compose(withRouter, connect(({ teams }) => ({ teams })))(TrialsNote)
