import React, { useState } from 'react'
import { Translate } from 'react-localize-redux'
import { makeStyles } from '@material-ui/core/styles'
import { rgba } from 'polished'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Hidden,
  Button,
  MenuItem,
  ButtonGroup,
} from '@material-ui/core'
import { formatDateTimeToServer } from 'utils/formatDate'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import hasSubscription from 'utils/hasSubscription'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { events as eventModule } from 'redux/modules'
import { LoadingSpinner, SelectedMenu } from 'components'
import moment from 'moment'
import NoIcon from './NoIcon'
import CommonTableRow from './CommonTableRow'
import PROP_TYPES from 'constants/propTypes'
import { BASIC } from 'constants/plans'

const useStyles = makeStyles((theme) => ({
  table: {
    position: 'relative',
    minHeight: 'calc(100vh - 23rem)',
    paddingTop: theme.spacing(1.2),
    '& tr.MuiTableRow-root:hover': {
      backgroundColor: rgba(theme.Dataset.color_1, 0.1),
    },
    '& .MuiAvatar-root': {
      boxShadow: '14px 5px 35px 0 rgba(0,0,0,0.09)',
      backgroundColor: theme.palette.secondary.main,
    },
    '& .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root': {
      borderTop: '1px solid rgba(224, 224, 224, 1)',
      padding: '8px 16px',
      fontSize: '.8rem',
    },
    '& .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root': {
      borderBottom: '0',
    },
    '& .MuiBox-root.MuiBox-root-41': {
      position: 'absolute',
      left: '0',
      right: '0',
      top: '39%',
    },
  },
  tableSecondrow: {
    minWidth: '8rem',
  },
  tableRight: {
    minWidth: '9.575rem',
    '& .MuiRadio-root': {
      padding: '0.188rem !important',
    },
    '& .MuiFormGroup-root': {
      padding: '0 !important',
      '& .MuiFormControlLabel-root': {
        margin: '0 !important',
      },
      '& .MuiTypography-body1': {
        fontSize: '.9rem !important',
      },
    },
  },
  SubLink: {
    lineHeight: '0.8rem',
  },
  generalEventBorder: {
    backgroundColor: '#b5b5b5', //  FOR Event
    height: '50px',
    borderRadius: '8px',
    width: '3px',
  },
  gameBorder: {
    backgroundColor: theme.Dataset.color_1, //  FOR Game
    height: '50px',
    borderRadius: '8px',
    width: '3px',
  },
  trainingBorder: {
    backgroundColor: theme.Dataset.color_2, // '#F48056',  FOR Training
    height: '50px',
    borderRadius: '8px',
    width: '3px',
  },
  spacingRight: {
    marginRight: theme.spacing(2),
  },
  availabilityCell: {
    '& .MuiFormControl-root': {
      marginBottom: theme.spacing(0),
      '& .MuiFormControlLabel-root': {
        margin: theme.spacing(0, 0),
        justifyContent: 'flex-end',
      },
    },
  },
  [theme.breakpoints.down('sm')]: {
    table: {
      overflowX: 'hidden',

      '& .MuiTableRow-root': {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        marginBottom: '1.5rem',
      },
      '& .MuiTableCell-root': {
        padding: '2px 2px 2px 14px',
      },

      '& .MuiTableCell-root:nth-child(3)': {
        display: 'none',
      },
      '& .MuiTableCell-root:last-child': {
        position: 'absolute',
        right: 0,
        zIndex: 9,
        padding: '.4rem 0',
      },
    },
    availabilityCell: {
      position: 'static !important',
      width: '100% !important',
      textAlign: 'left',
      '& .MuiFormControl-root': {
        '& .MuiFormGroup-root': {
          flexDirection: 'row',
        },
        marginBottom: theme.spacing(0),
        '& .MuiFormControlLabel-root': {
          margin: theme.spacing(0, 0),
        },
      },
    },
    noIcon: {
      position: 'static !important',
      background: 'transparent !important',
      width: '100% !important',
    },
    gameBorderMobile: {
      borderLeft: '3px solid',
      borderColor: theme.Dataset.color_1,
    },
    trainingBorderMobile: {
      borderLeft: '3px solid',
      borderColor: theme.Dataset.color_2,
    },
    generalEventBorderMobile: {
      borderLeft: '3px solid #b5b5b5 ',
    },
  },
}))

const EventTable = ({
  events,
  openAlertBox,
  openDuplicateBox,
  calenderDate,
  handleOpen,
  submitPlayerAvailability,
  fetchSeasonEvents,
  isPast,
  filterBy: filterByDropdownValue,
  isLoading,
  match: {
    params: { seasonId: urlSessionId },
  },
  beforeSliceEventCount,
}) => {
  const classes = useStyles()
  const [eventsDisplay, setEventsDisplay] = useState([])
  const [filterByEventType, setFilterByEventType] = useState('all')

  const getEvents = (past, date, filterBy, fixed = null) => {
    let params = { past }
    if (date) {
      if (filterBy) {
        if (fixed) {
          params = { ...params, date, fixed, filterBy }
        } else {
          params = { ...params, date, filterBy }
        }
      } else if (fixed) {
        params = { ...params, date, fixed }
      } else {
        params = { ...params, date }
      }
    } else {
      if (filterBy) {
        if (fixed) {
          params = { ...params, fixed, filterBy }
        } else {
          params = { ...params, filterBy }
        }
      } else if (fixed) {
        params = { ...params, fixed }
      } else {
        params = { ...params }
      }
    }
    fetchSeasonEvents(urlSessionId, params)
  }

  const handleFilterByEventType = (event) => {
    const selectedValue = event.target.value
    setFilterByEventType(selectedValue)
    if (calenderDate) {
      getEvents(
        0,
        formatDateTimeToServer(calenderDate),
        selectedValue === 'all' ? null : selectedValue,
        1
      )
    } else {
      getEvents(0, null, selectedValue === 'all' ? null : selectedValue)
    }
  }

  const generateURL = (uuid, teamId, seasonId, relation) => {
    if (relation === 'training') {
      return `/team/${teamId}/season/${seasonId}/trainings/${uuid}`
    }
    if (relation === 'birthday') {
      return `/team/${teamId}/season/${seasonId}/current-team/player/${uuid}/overview`
    }
    if (relation === 'match' && hasSubscription() === BASIC) {
      return ''
    }
    if (relation === 'event') {
      return `/team/${teamId}/season/${seasonId}/general-events/${uuid}`
    }

    return `/team/${teamId}/season/${seasonId}/matches/${uuid}`
  }

  const onChangeAvailability = (data) => {
    if (parseInt(data.value, 2) < 1) {
      data.openDialog({
        id: data.id,
        relation: data.relation,
        playerId: data.playerId,
        value: data.value,
      })
    } else {
      submitPlayerAvailability(
        data.id,
        data.relation,
        data.playerId,
        data.value
      )
    }
  }

  const [paginateDate, setPaginate] = useState({
    previous: null,
    next: null,
  })

  const resetPaginate = (data) => {
    if (isPast === 1) {
      if (data.length > 0) {
        if (data.length < 10) {
          setPaginate({
            previous: null,
            next: events[0].date,
          })
        } else {
          setPaginate({
            previous: events[events.length - 1].date,
            next: events[0].date,
          })
        }
      } else {
        setPaginate({
          previous: null,
          next: null,
        })
      }
    } else {
      if (data.length > 0) {
        if (data.length < 10) {
          setPaginate({
            previous: events[0].date,
            next: null,
          })
        } else {
          setPaginate({
            previous: events[0].date,
            next: events[events.length - 1].date,
          })
        }
      } else {
        setPaginate({
          previous: null,
          next: null,
        })
      }
    }
  }

  React.useEffect(() => {
    setFilterByEventType(filterByDropdownValue || 'all')
    setEventsDisplay(events)
    resetPaginate(events)
  }, [events])

  React.useEffect(() => {
    if (calenderDate) {
      getEvents(
        0,
        formatDateTimeToServer(calenderDate),
        filterByDropdownValue,
        1
      )
    } else {
      setFilterByEventType('all')
      getEvents(0, null, null, 0)
    }
  }, [calenderDate])

  return (
    <>
      <TableContainer className={classes.table}>
        {eventsDisplay.length === 0 && (
          <ButtonGroup size="small" style={{ float: 'right' }}>
            <Button
              size="small"
              onClick={() =>
                getEvents(
                  1,
                  `${moment().format('YYYY-MM-DD')} 00:00:00`,
                  filterByEventType === 'all' ? null : filterByEventType,
                  calenderDate ? 1 : null
                )
              }
            >
              <ArrowBackIosIcon />
            </Button>
          </ButtonGroup>
        )}
        {eventsDisplay.length > 0 && (
          <>
            <ButtonGroup size="small" style={{ float: 'right' }}>
              <Button
                size="small"
                onClick={() =>
                  getEvents(
                    1,
                    paginateDate.previous,
                    filterByEventType === 'all' ? null : filterByEventType,
                    calenderDate ? 1 : null
                  )
                }
                disabled={
                  !paginateDate.previous ||
                  (isPast && beforeSliceEventCount <= 10)
                }
              >
                <ArrowBackIosIcon />
              </Button>
              <Button
                size="small"
                onClick={() =>
                  getEvents(
                    0,
                    paginateDate.next,
                    filterByEventType === 'all' ? null : filterByEventType,
                    calenderDate ? 1 : null
                  )
                }
                disabled={
                  !paginateDate.next || (!isPast && beforeSliceEventCount <= 10)
                }
              >
                <ArrowForwardIosIcon />
              </Button>
            </ButtonGroup>
          </>
        )}
        <SelectedMenu
          id="filter-by-type"
          value={filterByEventType}
          style={{ width: 200 }}
          onChange={handleFilterByEventType}
          label={<Translate id="events.filter-by-type" />}
        >
          <MenuItem value="all">
            <em>
              <Translate id="matches.all" />
            </em>
          </MenuItem>
          <MenuItem value="match">
            <Translate id="events.matches" />
          </MenuItem>
          <MenuItem value="training">
            <Translate id="events.trainings" />
          </MenuItem>
          <MenuItem value="event">
            <Translate id="events.event" />
          </MenuItem>
          <MenuItem value="birthday">
            <Translate id="events.birthday" />
          </MenuItem>
        </SelectedMenu>

        <Table aria-label="sticky table">
          <Hidden only={['xs', 'sm']}>
            <TableHead>
              <TableRow>
                <TableCell>Team</TableCell>
                <TableCell>
                  <Translate id="events.table-date" />
                </TableCell>
                <TableCell />
                <TableCell align="left">
                  <Translate id="events.table-time" />
                </TableCell>
                <TableCell align="left">
                  <Translate id="events.table-event" />{' '}
                </TableCell>
                <TableCell align="left">
                  <Translate id="events.table-player" />
                </TableCell>
                <TableCell align="right">
                  <Translate id="events.table-actions" />
                </TableCell>
              </TableRow>
            </TableHead>
          </Hidden>
          <TableBody>
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              eventsDisplay.map((event) => (
                <CommonTableRow
                  key={`${event.id}_${
                    Math.random() * (Math.random() - 100000) + Math.random()
                  }_${event.relation}`}
                  borderClassMobile={
                    event.relation === 'training'
                      ? classes.trainingBorderMobile
                      : event.relation === 'event'
                      ? classes.generalEventBorderMobile
                      : classes.gameBorderMobile
                  }
                  eventBorder={
                    event.relation === 'training'
                      ? classes.trainingBorder
                      : event.relation === 'event'
                      ? classes.generalEventBorder
                      : classes.gameBorder
                  }
                  event={event}
                  generateURL={generateURL}
                  onChangeAvailability={onChangeAvailability}
                  handleOpen={handleOpen}
                  openAlertBox={openAlertBox}
                  openDuplicateBox={openDuplicateBox}
                />
              ))
            )}

            {!isLoading && eventsDisplay.length === 0 && (
              <NoIcon handleOpen={handleOpen} />
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {eventsDisplay.length > 0 && (
        <>
          <ButtonGroup
            size="small"
            style={{ float: 'right', marginBottom: '10px' }}
          >
            <Button
              size="small"
              onClick={() =>
                getEvents(
                  1,
                  paginateDate.previous,
                  filterByEventType === 'all' ? null : filterByEventType,
                  calenderDate ? 1 : null
                )
              }
              disabled={
                !paginateDate.previous ||
                (isPast && beforeSliceEventCount <= 10)
              }
            >
              <ArrowBackIosIcon />
            </Button>
            <Button
              size="small"
              onClick={() =>
                getEvents(
                  0,
                  paginateDate.next,
                  filterByEventType === 'all' ? null : filterByEventType,
                  calenderDate ? 1 : null
                )
              }
              disabled={
                !paginateDate.next || (!isPast && beforeSliceEventCount <= 10)
              }
            >
              <ArrowForwardIosIcon />
            </Button>
          </ButtonGroup>
        </>
      )}
    </>
  )
}

EventTable.defaultProps = {
  calenderDate: null,
  openAlertBox: () => 0,
  handleOpen: () => 0,
  openDuplicateBox: () => 0,
  submitPlayerAvailability: () => 0,
}

EventTable.propTypes = {
  events: PROP_TYPES.arrayOfObjects.isRequired,
  calenderDate: PROP_TYPES.date,
  openAlertBox: PROP_TYPES.func,
  handleOpen: PROP_TYPES.func,
  openDuplicateBox: PROP_TYPES.func,
  fetchSeasonEvents: PROP_TYPES.func.isRequired,
  submitPlayerAvailability: PROP_TYPES.func,
  match: PROP_TYPES.match.isRequired,
  isPast: PROP_TYPES.number.isRequired,
  filterBy: PROP_TYPES.string.isRequired,
  isLoading: PROP_TYPES.bool.isRequired,
}
export default compose(
  connect(null, {
    fetchSeasonEvents: eventModule.fetchSeasonEvents,
  }),
  withRouter
)(EventTable)
