import React from 'react'
import { DrillsCategoriesLoader, AgeClassesLoader } from 'loaders'
import DrillCreateContainer from './DrillCreateContainer'
import PROP_TYPES from 'constants/propTypes'

const DrillCreate = ({ history, match, location }) => (
  <DrillsCategoriesLoader>
    <AgeClassesLoader>
      <DrillCreateContainer
        history={history}
        match={match}
        location={location}
      />
    </AgeClassesLoader>
  </DrillsCategoriesLoader>
)

DrillCreate.propTypes = {
  history: PROP_TYPES.history.isRequired,
  match: PROP_TYPES.match.isRequired,
  location: PROP_TYPES.location.isRequired,
}

export default DrillCreate
