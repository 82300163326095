import React from 'react'
import {
  VideosLoader,
  SequencesLoader,
  PlayersLoader,
  FavoritesLoader,
} from 'loaders'
import { Grid } from '@material-ui/core'
import { Videos, Sequences } from './components'
import CheckNotProcessedVideos from './CheckNotProcessedVideos'
import PROP_TYPES from 'constants/propTypes'

const VideoAnalysis = ({
  match: {
    params: { teamId },
  },
}) => (
  <Grid container spacing={2}>
    <Grid item xs={12} sm={12} md={5} lg={4}>
      <VideosLoader alwaysRenderChildren passIsLoading>
        <FavoritesLoader entity="videos">
          <CheckNotProcessedVideos>
            <Videos />
          </CheckNotProcessedVideos>
        </FavoritesLoader>
      </VideosLoader>
    </Grid>
    <Grid item xs={12} sm={12} md={7} lg={8}>
      <PlayersLoader teamId={teamId} alwaysRenderChildren passIsLoading>
        <FavoritesLoader entity="sequences" alwaysRenderChildren passIsLoading>
          <SequencesLoader>
            <Sequences />
          </SequencesLoader>
        </FavoritesLoader>
      </PlayersLoader>
    </Grid>
  </Grid>
)

VideoAnalysis.propTypes = {
  match: PROP_TYPES.match.isRequired,
}

export default VideoAnalysis
