import React from 'react'
import { DrillLoader, DrillsCategoriesLoader, AgeClassesLoader } from 'loaders'
import DrillEditContainer from './DrillEditContainer'
import PROP_TYPES from 'constants/propTypes'

const DrillEdit = ({
  history,
  match: {
    params: { drillUuid },
  },
  match,
  location,
}) => (
  <DrillLoader drillUuid={drillUuid} alwaysRenderChildren passIsLoading>
    <AgeClassesLoader>
      <DrillsCategoriesLoader>
        <DrillEditContainer
          history={history}
          match={match}
          location={location}
        />
      </DrillsCategoriesLoader>
    </AgeClassesLoader>
  </DrillLoader>
)

DrillEdit.propTypes = {
  history: PROP_TYPES.history.isRequired,
  match: PROP_TYPES.match.isRequired,
  location: PROP_TYPES.location.isRequired,
}

export default DrillEdit
