import React from 'react'
import {
  Grid,
  Typography
} from "@material-ui/core";
import DatePicker from "components/inputes/DatePicker";
import { makeStyles } from "@material-ui/core/styles";
import { Translate } from "react-localize-redux";
import moment from "moment/moment";
import SelectDays from "./SelectDays";
import PROP_TYPES from "constants/propTypes";

const useStyles = makeStyles((theme) => ({
  dateTimeContainer: {
    "& .MuiIconButton-root": {
      padding: 0
    },
    "& .MuiInputAdornment-root .MuiSvgIcon-root": {
      color: "#757575"
    }
  },
  pr1: {
    paddingRight: "0.25rem"
  },
  pl1: {
    paddingLeft: "0.25rem"
  },
  [theme.breakpoints.down("sm")]: {
    pr1: {
      paddingRight: "0"
    },
    pl1: {
      paddingLeft: "0"
    }
  },
  scheduleText:{
    marginBottom: "1rem"
  }
}));

const AddEditSeries = ({ dateFormat, taskSeries, handleSeriesChange, isEditMode }) => {
  const classes = useStyles();

  const dateRangeValidation = (task) => {
    const tmpTaskSeries = { ...task };

    const a = moment(tmpTaskSeries.start_date);
    const b = moment(tmpTaskSeries.end_date);

    tmpTaskSeries.dayCount = -(a.diff(b, "days"));

    if (tmpTaskSeries.day_of_week === 0 && tmpTaskSeries.dayCount > 31) {
      tmpTaskSeries.dateError = true;
      tmpTaskSeries.dateErrorText = `${moment(tmpTaskSeries.start_date).add("days", 31).format(dateFormat.replace("dd", "DD"))}`;
    } else if (tmpTaskSeries.day_of_week > 0 && tmpTaskSeries.dayCount > 180) {
      tmpTaskSeries.dateError = true;
      tmpTaskSeries.dateErrorText = `${moment(tmpTaskSeries.start_date).add("days", 180).format(dateFormat.replace("dd", "DD"))}`;
    } else {
      tmpTaskSeries.dateError = false;
      tmpTaskSeries.dateErrorText = "";
    }

    return tmpTaskSeries
  };

  const handleDay = (event) => {
    const { name, value } = event.target;

    const tmpTaskSeries = dateRangeValidation(taskSeries);
    handleSeriesChange({ ...tmpTaskSeries, [name]: value });
  };

  const handleStartDate = (date) => {
    let tmpTaskSeries = { ...taskSeries };
    tmpTaskSeries.start_date = date;

    tmpTaskSeries = dateRangeValidation(tmpTaskSeries);

    handleSeriesChange({ ...tmpTaskSeries });
  };

  const handleEndDate = (date) => {
    let tmpTaskSeries = { ...taskSeries };
    tmpTaskSeries.end_date = date;

    tmpTaskSeries = dateRangeValidation(tmpTaskSeries);

    handleSeriesChange({ ...tmpTaskSeries });
  };

  return (
    <>
      <Grid xs={12}>
        <Typography variant="h4" className={classes.scheduleText}>
          <Translate id="task-management.task-series-schedule-text" />
        </Typography>
        <Grid container className={classes.dateTimeContainer}>


          <Grid item xs={12} md={6} className={classes.pr1}>
            <DatePicker
              name="start_date"
              disabled={isEditMode}
              selectedValue={taskSeries.start_date}
              required
              label={<Translate id="form.from" />}
              dateFormat={dateFormat}
              onDateChange={handleStartDate}
              disablePast
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.pl1}>
            <DatePicker
              required
              disabled={isEditMode}
              name="end_date"
              selectedValue={taskSeries.end_date}
              label={<Translate id="form.to" />}
              dateFormat={dateFormat}
              disablePast
              onDateChange={handleEndDate}
              error={taskSeries?.dateError}
              helperText={taskSeries?.dateError ? <Translate id="task-management.task-series-dateError" /> : ""}
            />
          </Grid>

          <Grid item xs={12} md={6} className={classes.pr1}>
            <SelectDays isEditMode={isEditMode} day={taskSeries.day_of_week} onChange={handleDay} />
          </Grid>
        </Grid>

      </Grid>

    </>
  );
};
AddEditSeries.propTypes = {
  dateFormat: PROP_TYPES.string.isRequired,
  handleSeriesChange: PROP_TYPES.func.isRequired,
  taskSeries: PROP_TYPES.shape().isRequired,
  isEditMode: PROP_TYPES.bool.isRequired,
};
export default AddEditSeries;
