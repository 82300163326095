import React, { useState } from 'react'
import { Translate } from 'react-localize-redux'
import { makeStyles } from '@material-ui/core/styles'
import DescriptionIcon from '@material-ui/icons/Description'

import {
  Paper,
  Popover,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Button,
} from '@material-ui/core'

import { connect } from 'react-redux'
import { matches } from 'redux/modules'
import PROP_TYPES from 'constants/propTypes'

const useStyles = makeStyles((theme) => ({
  CustomPopper: {
    '& .MuiPopover-paper': {
      padding: theme.spacing(2),
    },
  },
  textarea: {
    '& .MuiInputBase-root': {
      height: 'unset',
      paddingTop: 'unset',
      paddingBottom: 'unset',
    },
  },
}))

const RatingNotePlayerPopover = ({ matchPlayer, updateMatchParticipant }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(false)
  const [note, setNote] = useState(matchPlayer.notes || '')

  const onChangeNotes = ({ target: { value } }) => {
    setNote(value)
  }

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget)
    setOpen(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setOpen(false)
  }
  const handleSave = () => {
    setAnchorEl(null)
    setOpen(false)

    updateMatchParticipant(matchPlayer.uuid, {
      notes: note,
    })
  }

  return (
    <>
      <Popover
        id={matchPlayer}
        open={open}
        anchorEl={anchorEl}
        className={classes.CustomPopper}
        transition
        onClose={handleClose}
        disablePortal={false}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <Paper>
          <Grid item>
            <TextField
              name="additinalInfo"
              variant="outlined"
              margin="dense"
              className={classes.textarea}
              multiline
              minRows={4}
              label={<Translate id="matches.match-notes" />}
              value={note}
              onChange={onChangeNotes}
              maxLength="5000"
            />
          </Grid>
          <Grid container spacing={2} className="my-2">
            <Grid item sm={6}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                fullWidth
                onClick={handleSave}
              >
                <Translate id="button.add" />
              </Button>
            </Grid>
            <Grid item sm={6}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                fullWidth
                onClick={handleClose}
              >
                <Translate id="button.close" />
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Popover>

      <Tooltip
        title={<Translate id="matches.add-note" />}
        placement="top"
        arrow
      >
        <IconButton size="small" onClick={handleClick}>
          <DescriptionIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  )
}

RatingNotePlayerPopover.propTypes = {
  matchPlayer: PROP_TYPES.shape().isRequired,
  updateMatchParticipant: PROP_TYPES.func.isRequired,
}

export default connect(null, {
  updateMatchParticipant: matches.updateMatchParticipant,
})(RatingNotePlayerPopover)
