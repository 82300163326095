import { styled } from '@material-ui/core/styles'

export default styled('div')(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  bottom: theme.spacing(1),
  cursor: 'pointer',
  height: '30px',
  width: '30px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'white',
  borderRadius: '15px',
  fontSize: '20px',
  color: theme.palette.secondary.main,
  boxShadow: '0 0 3px 1px rgba(128, 128, 128, 0.5)',

  '&:before': {
    content: '\\2715',
  },
}))

