import React from 'react'
import { IconButton, Tooltip } from '@material-ui/core'
import binIcon from 'assets/images/16px/bin@1x.svg'
import { Translate } from 'react-localize-redux'
import AlertDialog from 'components/AlertDialog'
import HistoryIcon from '@material-ui/icons/History'
import { withRouter } from 'react-router-dom/cjs/react-router-dom'
import urlConstructor from 'utils/urlConstructor'
import PropTypes from 'prop-types'
import { TEAM_PLAYER_TRANSFER_HISTORY_ROUTE } from 'constants/routes'

const Actions = ({
  row: { uuid, userRoleUuid, deletePlayer, showTransferOption },
  history,
  match: {
    params: { teamId, seasonId },
  },
}) => {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const handleDelete = (param) => {
    // @todo remove reload and do required changes after api success without reloading
    deletePlayer(param).then(() => {
      window.location.reload()
    })
    setOpen(false)
  }
  const handlePlayerTransferHistoryIconClick = () => {
    history.push(
      urlConstructor(
        teamId,
        seasonId,
        TEAM_PLAYER_TRANSFER_HISTORY_ROUTE.populateURLParams({
          playerId: uuid,
        })
      )
    )
  }

  return (
    <>
      {userRoleUuid && (
        <>
          {open && (
            <AlertDialog
              open={open}
              onClose={handleClose}
              onCancel={handleClose}
              onSuccess={() => handleDelete({ roleUuid: userRoleUuid })}
              title={<Translate id="button.delete" />}
              subTitle={
                <Translate id="events.are-you-sure-you-want-to-delete" />
              }
              submitButtonLabel={<Translate id="button.ok" />}
              cancelButtonLabel={<Translate id="button.close" />}
            />
          )}

          <Tooltip
            title={<Translate id="button.delete" />}
            placement="top"
            arrow
          >
            <IconButton onClick={handleClickOpen}>
              <img src={binIcon} alt={binIcon} />
            </IconButton>
          </Tooltip>
        </>
      )}
      {showTransferOption && (
        <Tooltip
          title={<Translate id="button.transfer-history" />}
          placement="top"
          arrow
        >
          <IconButton onClick={handlePlayerTransferHistoryIconClick}>
            <HistoryIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
    </>
  )
}

Actions.propTypes = {
  row: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    userRoleUuid: PropTypes.string.isRequired,
    deletePlayer: PropTypes.func.isRequired,
    showTransferOption: PropTypes.bool.isRequired,
  }).isRequired,
  history: PropTypes.shape().isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      seasonId: PropTypes.number.isRequired,
      teamId: PropTypes.number.isRequired,
    }),
  }).isRequired,
}

export default withRouter(Actions)
