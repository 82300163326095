import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialogActions-root': {
      padding: theme.spacing(2.6),
    },
  },
  orderedList: {
    listStyleType: 'disc',
    paddingLeft: theme.spacing(3),
    marginBottom: theme.spacing(2),
    '& .MuiListItem-root': {
      display: 'revert-layer',
    },
  },
  listItem: {
    padding: theme.spacing(0),
    '& .MuiTypography-root': {
      fontSize: 13,
    },
  },
  checkedLabel: {
    fontSize: 11,
    paddingLeft: theme.spacing(4.4),
    marginTop: theme.spacing(-1),
  },
}))

export default useStyles
