import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Tabs, Tab, Toolbar, Box } from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import { fetchPlayersAttendance } from 'containers/TeamPlayersData/api'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import { useStyles } from '../style/styled'
import { PLAYER_STATUS_ACTIVE } from 'constants/player'
import StatisticsFilter from '../StatisticsFilter/index'
import AttendanceTable from './AttendanceTable'

const PlayersAttendance = ({
  theme,
  match: {
    params: { seasonId },
  },
}) => {
  const tabs = ['TRAINING', 'MATCH', 'EVENT']
  const [eventTypeTabValue, changeEventTypeTabValue] = useState(tabs[0])
  const [playerStatus, setPlayerStatus] = useState(PLAYER_STATUS_ACTIVE)
  const [attendanceData, setAttendanceData] = useState([])
  const [loading, setLoading] = useState(false)
  const [matchTypeFilterValue, setMatchTypeFilterValue] = useState('')
  const [filterDate, setFilterDate] = useState({
    startDate: null,
    endDate: null,
  })
  const classes = useStyles(theme)

  const handleChange = (event, newValue) => {
    changeEventTypeTabValue(newValue)
    setFilterDate({
      startDate: null,
      endDate: null,
    })
    setMatchTypeFilterValue('')
  }

  const fetchAttendance = (params = {}) => {
    fetchPlayersAttendance(seasonId, {
      ...params,
      event_type: eventTypeTabValue,
    }).then(
      (res) => {
        setLoading(false)
        setAttendanceData(res.data)
      },
      () => {
        setLoading(false)
        setAttendanceData([])
      }
    )
  }

  useEffect(() => {
    const params = {}
    if (playerStatus) {
      params.status = playerStatus
    }
    if (filterDate.startDate ) {
      params.start_date = filterDate.startDate
    }
    if (filterDate.endDate) {
      params.end_date = filterDate.endDate
    }
    if (matchTypeFilterValue) {
      params.match_type = matchTypeFilterValue
    }
    setLoading(true)
    fetchAttendance(params)
  }, [eventTypeTabValue, playerStatus, filterDate, matchTypeFilterValue])

  return (
    <>
      <Toolbar className={classes.root}>
        <Tabs
          value={eventTypeTabValue}
          indicatorColor="primary"
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            label={<Translate id="tab.tab-attendance.trainings" />}
            value="TRAINING"
          />
          <Tab
            label={<Translate id="tab.tab-attendance.matches" />}
            value="MATCH"
          />
          <Tab
            label={<Translate id="tab.tab-attendance.events" />}
            value="EVENT"
          />
        </Tabs>
        <Box ml="auto" className={classes.boxWidth}>
          <StatisticsFilter
            muiSM1={6}
            muiMD1={12}
            muiSM2={2}
            tab={eventTypeTabValue}
            matchType={matchTypeFilterValue}
            setMatchType={setMatchTypeFilterValue}
            filterDate={filterDate}
            setFilterDate={setFilterDate}
            playerStatus={playerStatus}
            setPlayerStatus={setPlayerStatus}
          />
        </Box>
      </Toolbar>
      {eventTypeTabValue === 'TRAINING' && (
        <AttendanceTable
          changeTab={changeEventTypeTabValue}
          rows={attendanceData}
          loading={loading}
        />
      )}
      {eventTypeTabValue === 'MATCH' && (
        <AttendanceTable
          changeTab={changeEventTypeTabValue}
          rows={attendanceData}
          loading={loading}
        />
      )}
      {eventTypeTabValue === 'EVENT' && (
        <AttendanceTable
          changeTab={changeEventTypeTabValue}
          rows={attendanceData}
          loading={loading}
        />
      )}
    </>
  )
}

PlayersAttendance.propTypes = {
  theme: PropTypes.oneOfType([
    PropTypes.shape().isRequired,
    PropTypes.oneOf([null]).isRequired,
  ]).isRequired,
  match: PropTypes.shape().isRequired,
}

export default withRouter(PlayersAttendance)
