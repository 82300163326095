import React from 'react'
import { connect } from 'react-redux'
import { TrainingPackageCreateEditForm } from 'containers'
import { trainingPackages } from 'redux/modules'
import { createSubUrl } from 'utils/urlConstructor'
import { TRAINING_PACKAGES_ROUTE } from 'constants/routes'
import PROP_TYPES from 'constants/propTypes'

const TrainingPackageCreateContainer = ({
  history,
  match,
  location: { fromUrl, fromPathname = 'Training Packages' },
  availableDrills,
  createTrainingPackage,
}) => {
  const linkBack =
    fromUrl || createSubUrl(match, TRAINING_PACKAGES_ROUTE)

  const goBack = () => history.push(linkBack)

  const handleSubmit = ({
    number_of_players: numberOfPlayers,
    images,
    ...values
  }) => {
    const data = {
      ...values,
      number_of_players: +numberOfPlayers,
    }

    if (images && images.length) {
      images.forEach((image, index) => {
        data[`images[${index}]`] = image
      })
    }

    createTrainingPackage(data).then(goBack)
  }

  return (
    <TrainingPackageCreateEditForm
      form="trainingPackageCreate"
      initialValues={{
        global: 0,
        age_class: [],
        number_of_players: 12,
      }}
      caption="Create Training Package"
      fromPath={{
        value: linkBack,
        title: fromPathname,
      }}
      onSubmit={handleSubmit}
      availableDrills={availableDrills}
    />
  )
}

TrainingPackageCreateContainer.defaultProps = {
  availableDrills: [],
}

TrainingPackageCreateContainer.propTypes = {
  history: PROP_TYPES.history.isRequired,
  match: PROP_TYPES.match.isRequired,
  location: PROP_TYPES.location.isRequired,
  availableDrills: PROP_TYPES.arrayOfObjects,
  createTrainingPackage: PROP_TYPES.func.isRequired,
}

export default connect(({ drills }) => ({ availableDrills: drills.items }), {
  createTrainingPackage: trainingPackages.createTrainingPackage,
})(TrainingPackageCreateContainer)
