import React from 'react'
import {
  PlayerProfilePrivacy,
  PlayerAttendanceResponse,
  PlayerEventResponse,
} from 'containers'
import { Grid } from '@material-ui/core'

const showPanel =
  process.env.REACT_APP_SHOW_PLAYER_PRIVACY_PANEL === 'true' || false

function TeamSettings() {
  return (
    <Grid container spacing={2}>
      {showPanel && (
        <Grid item xs={12} md={4}>
          <PlayerProfilePrivacy />
        </Grid>
      )}
      <Grid item xs={12} md={8}>
        <Grid item xs={12}>
          <PlayerAttendanceResponse />
        </Grid>
        <Grid item xs={12}>
          <PlayerEventResponse />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default TeamSettings
