import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: '10px 30px 30px',
  },
  tabsRoot: {
    paddingBottom: '15px',
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  tab: {
    textTransform: 'none',
    fontWeight: 'bold',
    color: theme.palette.grey[800],
    fontWeight: 600,
    padding: 0,
    minWidth: 0,
    paddingRight: '35px',
    '&:hover': {
      color: theme.palette.primary.main,
    },
    '&.Mui-selected': {
      fontWeight: 'bold',
      color: theme.palette.text.primary,
    },
  },
  tabPanel: {
    padding: 0,
  },
}))
