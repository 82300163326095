import React from 'react'
import PropTypes from 'prop-types'
import { Droppable } from 'react-beautiful-dnd'
import { styled, withTheme } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import { rgba } from 'polished'
import PROP_TYPES from 'constants/propTypes'

const StyledArea = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  marginBottom: theme.spacing(0.4),
  padding: theme.spacing(0.8),
  borderRadius: 8,
  border: `dashed 3px ${rgba(theme.palette.primary.main, 1)}`,
  backgroundColor: rgba(theme.palette.primary.main, 0.2),
  minHeight: props => (props.isDraggingOver ? '50px' : ''),
  cursor: 'copy',
  outline: 'none',
}))

const Placeholder = styled('div')(({ isPlaceholderShown }) => ({
  display: props => props.isPlaceholderShown ? 'block' : 'none',
}));

const DroppableArea = ({
  relation,
  title,
  children,
  isPlaceholderShown = false,
  isDragging,
  isListEmpty,
}) => {


  return (
    <Droppable droppableId={relation}>
      {provided => (
        <StyledArea
          ref={provided.innerRef}
          {...provided.droppableProps}
          isDraggingOver={isDragging}
          isEmpty={isListEmpty}
          onDrop={() => console.log("ABD")}
        >
          {title && (
            <div>
              <strong>{title}</strong>
            </div>
          )}
          {children}
          <Placeholder isPlaceholderShown={isPlaceholderShown}>
            {provided.placeholder}
          </Placeholder>
        </StyledArea>
      )}
    </Droppable>
  )
}

DroppableArea.defaultProps = {
  title: '',
  isPlaceholderShown: false,
  isDragging: false,
  isListEmpty: false,
}

DroppableArea.propTypes = {
  relation: PropTypes.string.isRequired,
  title: PropTypes.string,
  children: PROP_TYPES.children.isRequired,
  isPlaceholderShown: PropTypes.bool,
  isDragging: PropTypes.bool,
  isListEmpty: PropTypes.bool,
}

export default withTheme(DroppableArea)
