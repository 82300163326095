import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import PROP_TYPES from '../../constants/propTypes'

/**
 * Check user ability on current team.
 */
const HasTeamPermission = ({ children, ability, or, permissions }) => {

  const can = !!(permissions && permissions.includes(ability))

  // noinspection PointlessBooleanExpressionJS
  return can || !!or ? children : (null)
}

HasTeamPermission.propTypes = {
  ability: PROP_TYPES.string.isRequired,
  permissions: PROP_TYPES.arrayOf(PROP_TYPES.string).isRequired,
  children: PROP_TYPES.children.isRequired,
  or: PropTypes.oneOfType([
    PropTypes.bool
  ])
}

export default connect(
  ({ auth, team }) => ({
    permissions: auth.permissions.teams.find(t => t.id === team.id)?.permissions
  })
)(HasTeamPermission)
