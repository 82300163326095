import { handleActions, combineActions, createAction } from 'redux-actions'
import {
  actionsTypesGenerator,
  onRequest,
  onSuccess,
  onFailure,
} from 'utils/reduxHelpers'
import initialState from '../initialState'

const namespace = 'MATCH'

const FETCH_SEASON_MATCHES = actionsTypesGenerator(
  `FETCH_SEASON_${namespace}ES`
)
const FETCH_MATCH = actionsTypesGenerator(`FETCH_${namespace}`)
const CREATE_MATCH = actionsTypesGenerator(`CREATE_${namespace}`)
const UPDATE_MATCH = actionsTypesGenerator(`UPDATE_${namespace}`)
const SHARE_MATCH = actionsTypesGenerator(`SHARE_${namespace}`)
const DELETE_MATCH = actionsTypesGenerator(`DELETE_${namespace}`)
const FETCH_MATCH_SEQUENCES = actionsTypesGenerator(`CREATE_${namespace}_SEQUENCES`)
const CREATE_MATCH_NOTE = actionsTypesGenerator(`CREATE_${namespace}_NOTE`)
const UPDATE_MATCH_NOTE = actionsTypesGenerator(`UPDATE_${namespace}_NOTE`)
const DELETE_MATCH_NOTE = actionsTypesGenerator(`DELETE_${namespace}_NOTE`)
const FETCH_MATCH_NOTE = actionsTypesGenerator(`FETCH_${namespace}_NOTE`)

const ADD_NEW_MATCH = `ADD_NEW_${namespace}`
const REMOVE_NEW_MATCHES = `REMOVE_NEW_${namespace}S`

const UPDATE_MATCH_PARTICIPANT = actionsTypesGenerator(
  `UPDATE_${namespace}_PARTICIPANT`
)
const UPDATE_MATCH_PARTICIPANTS = actionsTypesGenerator(
  `UPDATE_${namespace}_PARTICIPANTS`
)

const FETCH_TICKERS = actionsTypesGenerator(`FETCH_TICKERS`)

export const fetchSeasonMatches = (seasonId) => ({
  types: FETCH_SEASON_MATCHES,
  callAPI: {
    method: 'GET',
    entity: 'Matches',
    path: `/seasons/${seasonId}/matches`,
  },
})

export const fetchMatch = (matchUuid, params) => ({
  types: FETCH_MATCH,
  callAPI: {
    method: 'GET',
    entity: 'Match',
    path: `/v2/matches/${matchUuid}`,
    params,
  },
})

export const createMatch = (seasonUuid, data) => ({
  types: CREATE_MATCH,
  callAPI: {
    method: 'POST',
    entity: 'Match',
    path: `/v2/seasons/${seasonUuid}/matches`,
    data,
  },
})

export const updateMatch = (uuid, data) => ({
  types: UPDATE_MATCH,
  callAPI: {
    method: 'PATCH',
    entity: 'Match',
    path: `/v2/matches/${uuid}`,
    data,
  },
})

export const shareMatch = (id, paramsString, data = {}) => ({
  types: SHARE_MATCH,
  callAPI: {
    method: 'SHARE',
    entity: 'Match',
    path: `/matches/${id}/share`,
    paramsString,
    data,
  },
})

export const deleteMatch = (matchUuid) => ({
  types: DELETE_MATCH,
  callAPI: {
    method: 'DELETE',
    entity: 'Match',
    path: `/v2/matches/${matchUuid}`,
  },
})

export const fetchMatchNotes = (matchUuid) => ({
  types: FETCH_MATCH_NOTE,
  callAPI: {
    method: 'GET',
    entity: 'Match note',
    path: `/v2/matches/${matchUuid}/match-notes`,
  },
})
export const createMatchNote = (matchUuid, data) => ({
  types: CREATE_MATCH_NOTE,
  callAPI: {
    method: 'POST',
    entity: 'Match note',
    path: `/v2/matches/${matchUuid}/match-notes`,
    data,
  },
  payload: { matchUuid },
})

export const updateMatchNote = (matchNoteUuid, data) => ({
  types: UPDATE_MATCH_NOTE,
  callAPI: {
    method: 'PUT',
    entity: 'Match note',
    path: `/v2/match-notes/${matchNoteUuid}`,
    data,
  },
})
export const deleteMatchNote = (matchUuid) => ({
  types: DELETE_MATCH_NOTE,
  callAPI: {
    method: 'DELETE',
    entity: 'Match note',
    path: `/v2/match-notes/${matchUuid}`,
  },
  payload: { matchUuid },
})

export const updateMatchParticipant = (matchPlayer, data) => ({
  types: UPDATE_MATCH_PARTICIPANT,
  callAPI: {
    method: 'PATCH',
    entity: 'Match Participant',
    path: `/v2/match-players/${matchPlayer}/`,
    data: { ...data, preventToastNotification: true },
    showErrorToastOnly: true,
  },
})
export const updateMatchParticipantsInBulk = (matchUuid, data) => ({
  types: UPDATE_MATCH_PARTICIPANTS,
  callAPI: {
    method: 'PATCH',
    entity: 'Match Participants',
    path: `/v2/matches/${matchUuid}/participants`,
    data: { ...data, preventToastNotification: true },
    showErrorToastOnly: true,
  },
})

export const fetchMatchSequences = (matchUuid) => ({
  types: FETCH_MATCH_SEQUENCES,
  callAPI: {
    method: 'GET',
    entity: 'Match Sequences',
    path: `/v2/matches/${matchUuid}/sequences`,
  },
})
export const fetchMatchTickers = (matchUuid) => ({
  types: FETCH_TICKERS,
  callAPI: {
    method: 'GET',
    entity: 'Ticker',
    path: `/matches/${matchUuid}/tickers`,
    data: {
      preventToastNotification: true,
    },
  },
})

export const addNewMatch = createAction(ADD_NEW_MATCH)
export const removeNewMatches = createAction(REMOVE_NEW_MATCHES)

export default handleActions(
  {
    [combineActions(
      FETCH_SEASON_MATCHES.request,
      FETCH_MATCH.request,
      CREATE_MATCH.request,
      UPDATE_MATCH.request,
      DELETE_MATCH.request,
      CREATE_MATCH_NOTE.request,
      UPDATE_MATCH_NOTE.request,
      DELETE_MATCH_NOTE.request,
      SHARE_MATCH.request,
      FETCH_MATCH_NOTE.request,
      UPDATE_MATCH_PARTICIPANT.request,
      UPDATE_MATCH_PARTICIPANTS.request,
      FETCH_MATCH_SEQUENCES.request,
      FETCH_TICKERS.request
    )]: onRequest,

    [combineActions(
      FETCH_MATCH_NOTE.success,
      CREATE_MATCH_NOTE.success,
      UPDATE_MATCH_NOTE.success
    )]: (state) => onSuccess({ ...state }),

    [FETCH_SEASON_MATCHES.success]: (
      state,
      { response: { data: items = [] } }
    ) => onSuccess({ ...state, items }),
    [FETCH_MATCH_SEQUENCES.success]: (
      state,
      { response: { data: items = [] } }
    ) => onSuccess({ ...state, items }),
    [combineActions(FETCH_MATCH.success)]: (
      state,
      { response: { data: current = {} } }
    ) => onSuccess({ ...state, current }),

    [combineActions(CREATE_MATCH.success, UPDATE_MATCH.success)]: (
      state,
      { response: { data = {} }, id }
    ) => {
      const items = state.items.updateById(id, { ...data, isNew: false })

      return onSuccess({
        ...state,
        items,
        current: { ...state.current, ...data },
      })
    },
    [DELETE_MATCH.success]: (state, { id }) =>
      onSuccess({ ...state, items: state.items.filterById(id) }),

    [UPDATE_MATCH_PARTICIPANT.success]: (
      state,
      { response: { data = {} } }
    ) => {
      const items = state.current.relationships.participants.updateByUuid(
        data.uuid,
        {
          ...data,
        }
      )

      return onSuccess({
        ...state,
        current: {
          ...state.current,
          relationships: {
            ...state.current.relationships,
            participants: items,
          },
        },
      })
    },
    [UPDATE_MATCH_PARTICIPANTS.success]: (
      state,
      { response: { data = {} } }
    ) => {
      const items = state.current.relationships.participants.map((p) => {
        const matchingParticipants = data.filter(
          (participant) => participant.uuid === p.uuid
        )
        if (matchingParticipants.length > 0) {
          return matchingParticipants[0]
        }

        return p
      })

      return onSuccess({
        ...state,
        current: {
          ...state.current,
          relationships: {
            ...state.current.relationships,
            participants: items,
          },
        },
      })
    },

    [combineActions(SHARE_MATCH.success)]: (state) => onSuccess({ ...state }),
    [addNewMatch]: (state, { payload }) => {
      const { id, teamId, seasonId, date, status, isNew } = payload

      const newMatch = {
        id,
        team_id: +teamId,
        season_id: +seasonId,
        date,
        status,
        isNew,
      }

      return {
        ...state,
        items: [...state.items, newMatch],
      }
    },
    [removeNewMatches]: (state) => {
      const items = state.items.filter(({ isNew }) => !isNew)

      return {
        ...state,
        items,
      }
    },
    [combineActions(FETCH_TICKERS.success)]: (state) => {
      return onSuccess({
        ...state,
      })
    },
    [combineActions(
      FETCH_SEASON_MATCHES.failure,
      FETCH_MATCH_SEQUENCES.failure,
      FETCH_MATCH.failure,
      CREATE_MATCH.failure,
      UPDATE_MATCH.failure,
      DELETE_MATCH.failure,
      CREATE_MATCH_NOTE.failure,
      UPDATE_MATCH_NOTE.failure,
      DELETE_MATCH_NOTE.failure,
      UPDATE_MATCH_PARTICIPANT.failure,
      FETCH_TICKERS.failure,
      UPDATE_MATCH_PARTICIPANTS.failure
    )]: onFailure,
  },
  initialState.matches
)
