import { styled } from '@material-ui/core/styles'

export default styled('div')(({ theme }) => ({
  width: theme.spacing(3),
  height: theme.spacing(3),
  borderRadius: '50%',
  fontSize: '1.5rem',
  position: 'absolute',
  left: 'calc(50% - ' + theme.spacing(1.5) + ')',
  top: 'calc(50% - ' + theme.spacing(1.5) + ')', 
  background: 'var(--dark-green)', 
  color: 'white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  '&:hover': {
    background: '#255f64',
  },
  '.trainingPackage &': {
    top: 'calc(40% - ' + theme.spacing(1.5) + ')',
  },
}))
