import React, { useEffect, useState } from 'react'
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { Translate } from 'react-localize-redux'
import { InputAdornment } from '@material-ui/core'
import PropTypes from 'prop-types'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import { formatDateTimeToServer } from 'utils/formatDate'

const TimePickerComp = ({
  meta = {},
  label,
  variant,
  margin,
  onDateChange,
  selectedValue,
  style,
  isEditMode,
}) => {
  const dateError = meta.touched && meta.error

  const convertdate = (dateTemp) => {
    return formatDateTimeToServer(dateTemp).split('-').join('/')
  }
  const [selectedDate, handleDate] = useState(convertdate(selectedValue))
  const handleDateChange = (e) => {
    handleDate(e)
    onDateChange(e)
  }
  useEffect(() => {
    if (!isEditMode) {
      handleDate(selectedValue)
    } else {
      const temp = convertdate(selectedValue)
      handleDate(temp)
    }
  }, [selectedValue])

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <TimePicker
        inputVariant={variant}
        ampm={false}
        value={selectedDate}
        onChange={handleDateChange}
        minutesStep={5}
        label={label}
        margin={margin}
        okLabel={<Translate id="button.ok" />}
        cancelLabel={<Translate id="button.cancel" />}
        error={dateError}
        helperText={dateError}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <AccessTimeIcon />
            </InputAdornment>
          ),
        }}
        className={style}
      />
    </MuiPickersUtilsProvider>
  )
}

TimePickerComp.defaultProps = {
  meta: {},
  variant: 'outlined',
  margin: 'dense',
  onDateChange: () => 0,
  selectedValue: '',
  style: '',
}

TimePickerComp.propTypes = {
  meta: PropTypes.shape(),
  label: PropTypes.shape().isRequired,
  variant: PropTypes.string,
  margin: PropTypes.string,
  onDateChange: PropTypes.func,
  selectedValue: PropTypes.string,
  style: PropTypes.string,
  isEditMode: PropTypes.bool.isRequired,
}

export default TimePickerComp
