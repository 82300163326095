export default (
  object,
  compareValueFunction = Boolean,
  compareKeyFunction = () => true
) => {
  return Object.fromEntries(
    Object.entries(object).filter(
      ([key, val]) => compareValueFunction(`${val}`) && compareKeyFunction(key)
    )
  )
}
