import React, { useState, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { styled } from '@material-ui/core/styles'
import ImageUploader from './ImageUploader'

const StyledSelectedImage = styled('div')({
  position: 'relative',

  '> span': {
    position: 'absolute',
    zIndex: 1,
    top: '50%',
    width: '25px',
    height: '40px',
    marginTop: '-20px',
    color: 'white',
    background: 'rgba(32, 32, 32, 0.7)',
    cursor: 'pointer',
  },

  '> span.next-image': {
    right: 0,
  },
})

const ImagesUploader = ({ fields, meta }) => {
  const [activeImageIndex, changeActiveImageIndex] = useState(0)

  const isPrevImage = useMemo(() => activeImageIndex > 0, [activeImageIndex])

  const isNextImage = useMemo(
    () => activeImageIndex < fields.length - 1,
    [activeImageIndex, fields.length]
  )

  const prevImage = () =>
    isPrevImage && changeActiveImageIndex(activeImageIndex - 1)

  const nextImage = () =>
    isNextImage && changeActiveImageIndex(activeImageIndex + 1)

  const activeImage = useMemo(() => fields[activeImageIndex], [
    activeImageIndex,
    fields,
  ])

  const onChange = useCallback(
    newValue => {
      if (newValue) {
        fields.push(newValue)
        changeActiveImageIndex(fields.length)
      } else {
        fields.splice(activeImageIndex, 1)
        prevImage()
      }
    },
    [fields, activeImageIndex, prevImage]
  )

  return (
    <StyledSelectedImage>
      {isPrevImage && (
        <span onClick={prevImage} role="presentation">
          {'<'}
        </span>
      )}
      <ImageUploader
        input={{
          value: (activeImage && activeImage.full_url) || activeImage,
          onChange,
        }}
        meta={meta}
        isMulti
      />
      {isNextImage && (
        <span className="next-image" onClick={nextImage} role="presentation">
          {'>'}
        </span>
      )}
    </StyledSelectedImage>
  )
}

ImagesUploader.propTypes = {
  fields: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
}

export default ImagesUploader