import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, Breadcrumbs } from '@material-ui/core'
import { Modal } from 'components'
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import Drill from './Drill'

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'flex',
  },
  breadcrumb: {
    marginBottom: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));

const TrainingPackageModal = ({ trainingPackage, onHide, open }) => {
  const {
    title,
    description,
    age_class: ageClass,
    number_of_players: numberOfPlayers,
    duration,
    warmup_drills: warmupDrills,
    main_part_drills: mainPartDrills,
    final_part_drills: finalPartDrills,
  } = trainingPackage

  const classes = useStyles();

  return (
    <Modal title={title} onHide={onHide} boldTitle open={open}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb"  className={classes.breadcrumb}>
      <Typography color="inherit" className={classes.link}>
      <PersonAddOutlinedIcon className={classes.icon} />
        {ageClass}
      </Typography>
      <Typography
        color="inherit"
        className={classes.link}
      >
       <PeopleOutlineIcon className={classes.icon} />
       {numberOfPlayers}
      </Typography>
      <Typography color="inherit">
      <AccessTimeIcon className={classes.icon} />
      {duration} min
      </Typography>
    </Breadcrumbs>
 
          <p dangerouslySetInnerHTML={{ __html: description }} />
        </Grid>
        <Grid item xs={12}>
        <Drill drills={warmupDrills} category="Warmup" categoryIndex={1} classes={classes} />
        <Drill drills={mainPartDrills} category="Main Part" categoryIndex={2} classes={classes} />
        <Drill drills={finalPartDrills} category="Final Part" categoryIndex={3} classes={classes} />
        </Grid>
      </Grid>
   
    </Modal>
  )
}

TrainingPackageModal.propTypes = {
  trainingPackage: PropTypes.shape().isRequired,
  onHide: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default TrainingPackageModal
