import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { styled, withStyles } from '@material-ui/core/styles'
import { rgba } from 'polished'
import {
    Paper,
    Divider,
    Box as MuiBox,
    Typography,
} from '@material-ui/core'
import Slider from '@material-ui/core/Slider'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { InnrHead } from 'containers'
import { players as playersModule } from 'redux/modules'

const Box = styled(MuiBox)(({ theme }) => ({
    padding: theme.spacing(0, 2, 2),
    overflow: 'auto',
    '& .MuiBox-root': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& .MuiTypography-root': {
            width: 222,
        },
    },
}))

const PrettoSlider = withStyles(theme => ({
    root: {
        color: rgba(theme.palette.primary.main, 1),
        height: 7,
        width: 'calc(100% - 173px)',
    },
    thumb: {
        height: 18,
        width: 18,
        backgroundColor: '#fff',
        border: '4px solid',
        borderColor: rgba(theme.palette.primary.main, 1),
        marginTop: -6,
        marginLeft: -2,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        color: rgba(theme.palette.primary.main, 1),
        height: 7,
        borderRadius: 4,
    },
    rail: {
        color: '#D0D0D0',
        height: 7,
        borderRadius: 8,
    },
}))(Slider)

const SkillRatingsWidget = ({ skills, updateSkillValue }) => {

    // map skills from state to local state
    const [sliderSkills, setSliderSkills] = useState(skills.map(skill => {
        return {
            uuid: skill.uuid,
            name: skill.name.charAt(0).toUpperCase() + skill.name.slice(1), // capitalize first letter
            value: skill.value,
        }
    }))

    // handle skill value change in local state while dragging
    const handleSkillValueChange = (uuid, value) => {
        setSliderSkills(sliderSkills.map(skill =>
          skill.uuid === uuid
            ? { ...skill, value }
            : skill,
        ))
    }

    // commit actual skill value change
    const handleSkillValueCommit = (uuid, value) => {
        updateSkillValue(uuid, value)
    }

    return (
        <Paper className="h-100">
            <InnrHead title={<Translate id="team.player-skill-ratings" />} divider={<Divider />}/>
            <Box>
                {sliderSkills.map(skill => {
                    return (
                        <MuiBox key={skill.uuid}>
                            <Typography noWrap c>{skill.name}</Typography>
                            <PrettoSlider
                                max={10}
                                step={1}
                                value={skill.value}
                                onChange={(e, value) => handleSkillValueChange(skill.uuid, value)}
                                onChangeCommitted={(e, value) => handleSkillValueCommit(skill.uuid, value)}
                            />
                            <span>{skill.value}/10</span>
                        </MuiBox>
                    )
                })}
            </Box>
        </Paper>
    )
}

SkillRatingsWidget.propTypes = {
    skills: PropTypes.arrayOf(PropTypes.shape({
        uuid: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
    })).isRequired,
    updateSkillValue: PropTypes.func.isRequired,
}

export default connect(
    null,
    {
        updateSkillValue: playersModule.updateSkillValue
    }
)(SkillRatingsWidget)
