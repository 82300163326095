import React from 'react'
import { SubLink } from 'containers'
import { Box, Avatar } from '@material-ui/core'
import PropTypes from 'prop-types'
import { TEAM_PLAYER_DASHBOARD_ROUTE } from 'constants/routes'

const AvatarImage = ({ row: { uuid, avatar, firstName } }) => {
  return (
    <Box display="flex" alignItems="center">
      {avatar !== 'none' && (
        <Box mr={2}>
          <Avatar alt="avatar" src={avatar} />
        </Box>
      )}
      <Box>
        <SubLink
          to={TEAM_PLAYER_DASHBOARD_ROUTE.populateURLParams({
            playerId: uuid,
          })}
          fromPathname={null}
          key={`${uuid}`}
        >
          {firstName}
        </SubLink>
      </Box>
    </Box>
  )
}

AvatarImage.propTypes = {
  row: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
  }).isRequired,
}
export default AvatarImage
