import React, { useState } from 'react'
import { Box, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import getVideoId from 'get-video-id'
import { Translate } from 'react-localize-redux'
import { useStyles } from '../styles'
import { StyledToggleButtonGroup, StyledToggleButton } from './StyledComponents'

const MediaTabSection = ({ drill }) => {
  const classes = useStyles()
  const [selectedTab, setSelectedTab] = useState('video')

  const handleTabChange = (event, newValue) => {
    if (newValue) {
      setSelectedTab(newValue)
    }
  }

  const getVideoUrl = (url) => {
    const { service, id } = getVideoId(url)
    const baseUrl =
      service === 'youtube'
        ? 'https://www.youtube.com/embed/'
        : 'https://player.vimeo.com/video/'

    return `${baseUrl}${id}`
  }

  return (
    <Box className={classes.contentContainer}>
      <Box display="flex" justifyContent="center" mb={2}>
        <StyledToggleButtonGroup
          value={selectedTab}
          exclusive
          onChange={handleTabChange}
          aria-label="media type"
        >
          <StyledToggleButton value="image" aria-label="image">
            <Translate id="drills.image" />
          </StyledToggleButton>
          <StyledToggleButton value="video" aria-label="video">
            <Translate id="drills.video" />
          </StyledToggleButton>
        </StyledToggleButtonGroup>
      </Box>
      <Box className={classes.imageContainer}>
        {selectedTab === 'image' && (
          <Box className={classes.imageContainer}>
            {drill.relationships.images.length > 0 ? (
              <img
                src={
                  drill.relationships.images[
                    drill.relationships.images.length - 1
                  ].full_url
                }
                alt="Drill"
                className={classes.image}
              />
            ) : (
              <Typography variant="body2">
                <Translate id="drills.no-image-available" />
              </Typography>
            )}
          </Box>
        )}

        {selectedTab === 'video' && (
          <Box className={classes.videoContainer}>
            {drill.video_url ? (
              <iframe
                title="coachbetter Video"
                src={getVideoUrl(drill.video_url)}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className={classes.iframe}
              />
            ) : (
              <Typography variant="body2" className={classes.centerText}>
                <Translate id="drills.no-video-available" />{' '}
              </Typography>
            )}
          </Box>
        )}
      </Box>
    </Box>
  )
}

MediaTabSection.defaultProps = {
  drill: {
    relationships: {
      images: [],
    },
    video_url: null,
  },
}

MediaTabSection.propTypes = {
  drill: PropTypes.shape({
    relationships: PropTypes.shape({
      images: PropTypes.arrayOf(
        PropTypes.shape({
          full_url: PropTypes.string,
        })
      ).isRequired,
    }).isRequired,
    video_url: PropTypes.string,
  }),
}

export default MediaTabSection
