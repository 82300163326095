import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import { compose } from 'redux'
import { LoadingSpinner } from 'components'
import { formatCostumDateTimeForUI } from 'utils/formatDate'
import SubLink from 'containers/SubLink'
import CircularSpinner from 'components/loader/CircularSpinner'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import { Translate } from 'react-localize-redux'
import { getPlayerTransferHistory } from '../api'

const TransferHistoryTable = ({
  dateTimeFormat,
  match: {
    params: { playerId },
  },
  language,
  timezone,
}) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [isCircularLoading, setIsCircularLoading] = useState(false)
  const [playerTransferHistory, setPlayerTransferHistory] = useState([])

  const onReportDownloadClick = (e, sourcePlayerUuid, seasonId) => {
    e.preventDefault()
    e.stopPropagation()
    const shareUrl = `${process.env.REACT_APP_API_URL}/players/${sourcePlayerUuid}/share?language=${language}&time_zone=${timezone}&season=${seasonId}`
    window.open(shareUrl, '_blank')
  }
  useEffect(() => {
    setLoading(true)
    setError('')

    getPlayerTransferHistory(playerId)
      .then((res) => {
        setLoading(false)
        setPlayerTransferHistory(res.data)
      })
      .catch(() => {
        setError('Something went wrong!')
        setLoading(false)
      })
  }, [])

  return (
    <Paper elevation={3} style={{ minHeight: '10vh' }}>
      {isCircularLoading && (
        <CircularSpinner loadingcircular={isCircularLoading} />
      )}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="h4" component="h4">
                  <Translate id="player.transfer-history.transferred-from" />
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h4" component="h4">
                  <Translate id="player.transfer-history.transferred-to" />
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h4" component="h4">
                  <Translate id="season.title" />
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h4" component="h4">
                  <Translate id="player.transfer-history.transferred-by" />
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h4" component="h4">
                  <Translate id="player.transfer-history.date-and-time" />
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h4" component="h4">
                  <Translate id="player.transfer-history.attachments" />
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading &&
              playerTransferHistory.map((history) => (
                <TableRow key={history.uuid}>
                  <TableCell>{history.source_team_name}</TableCell>
                  <TableCell>{history.destination_team_name}</TableCell>
                  <TableCell>
                    {history.season ? history.season : `N/A`}
                  </TableCell>
                  <TableCell>{history.transferred_by}</TableCell>
                  <TableCell>
                    {formatCostumDateTimeForUI(
                      history.transferred_at,
                      `${dateTimeFormat} HH:mm:ss`
                    )}
                  </TableCell>
                  <TableCell>
                    {history.season_id ? (
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item>
                          <DescriptionOutlinedIcon fontSize="small" />
                        </Grid>
                        <Grid item>
                          <SubLink
                            onClick={(e) =>
                              onReportDownloadClick(
                                e,
                                history.source_player_uuid,
                                history.season_id
                              )
                            }
                          >
                            <Translate id="player.transfer-history.report-download" />
                          </SubLink>
                        </Grid>
                      </Grid>
                    ) : (
                      `N/A`
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {error && (
        <Box textAlign="center" py={8}>
          <Typography>{error}</Typography>
        </Box>
      )}
      {!loading && !error && !playerTransferHistory.length && (
        <Box textAlign="center" py={8}>
          <Typography>No Transfer History</Typography>
        </Box>
      )}
      {loading && <LoadingSpinner />}
    </Paper>
  )
}

TransferHistoryTable.propTypes = {
  dateTimeFormat: PropTypes.string.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      playerId: PropTypes.string.isRequired,
    }),
  }).isRequired,
  timezone: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
}

export default compose(
  withRouter,
  connect(({ auth }) => ({
    dateTimeFormat: auth.user.datetime_format,
    timezone: auth.user.time_zone,
    language: auth.user.language,
  }))
)(TransferHistoryTable)
