import React from 'react'
import { Box, Checkbox, FormControlLabel, FormGroup } from '@material-ui/core'
import PropTypes from 'prop-types'

const StyledCheckbox = ({
  input: { value, onChange },
  label,
  isCloseButtonHidden,
  className,
}) => (
  <Box display="flex" alignItems="center">
    <FormGroup>
      <FormControlLabel control={<Checkbox 
        checked={value}
        onClick={() => onChange(+!value)}
        isCloseButtonHidden={isCloseButtonHidden}
        className={className} 
      />} label={label} />
    </FormGroup>
  </Box>
)

StyledCheckbox.defaultProps = {
  isCloseButtonHidden: false,
  className: undefined,
}

StyledCheckbox.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  isCloseButtonHidden: PropTypes.bool,
  className: PropTypes.string,
}

export default StyledCheckbox
