// eslint-disable-next-line import/no-extraneous-dependencies
import merge from 'deepmerge'
import { THEMES } from '../constants'

const white = '#fff'

const defaultVariant = {
  name: THEMES.DEFAULT,
  palette: {
    type: 'light',
    primary: {
      main: '#3ea371',
      contrastText: white,
      contrastThreshold: 3,
      tonalOffset: 0.2,
    },
    secondary: {
      main: '#255f64',
      contrastText: white,
    },
    background: {
      default: '#F7F9FC',
      paper: white,
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.95)',
      secondary: 'rgba(0, 0, 0, 0.5)',
    },
    card: {
      color: '#062221',
    },
  },

  header: {
    color: '#062221',
    background: '#ffffff',
    topbar: {
      background: '#eefff7',
      color: '#000000',
    },
    indicator: {
      background: '#eefff7',
    },
  },
  sidebar: {
    color: '#a6a6a6',
    background: '#fff',
  },
  status: {
    statuscolor_1: '#255f64',
    statuscolor_2: '#f44336',
    statuscolor_3: '#ff9800',
  },
  Dataset: {
    color_1: '#255f64',
    color_2: '#f44336',
    color_3: '#ff9800',
  },
}

const BVBVariant = merge(defaultVariant, {
  name: THEMES.BVB,
  palette: {
    type: 'light',
    primary: {
      main: '#ffe600',
      contrastText: '#000000',
      contrastThreshold: 3,
      tonalOffset: 0.2,
    },
    secondary: {
      main: '#000000',
      contrastText: '#ffe600',
      contrastThreshold: 3,
      tonalOffset: 0.2,
    },
    background: {
      default: '#ffffff',
      paper: white,
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.95)',
      secondary: 'rgba(0, 0, 0, 0.5)',
    },
  },
  header: {
    color: white,
    background: '#ffe600',
    topbar: {
      background: '#000000',
      color: '#000000',
      link: '#ffe600',
    },
  },
  sidebar: {
    color: '#CACACA',
    background: '#000000',
  },
  status: {
    statuscolor_1: '#255f64',
    statuscolor_2: '#f44336',
    statuscolor_3: '#000000',
  },
  Dataset: {
    color_1: '#ffe600',
    color_2: '#000000',
    color_3: '#CACACA',
  },
})

const ALIANZAVariant = merge(defaultVariant, {
  name: THEMES.ALIANZA,
  palette: {
    type: 'light',
    primary: {
      main: '#c6a059',
      contrastText: '#ffffff',
      contrastThreshold: 3,
      tonalOffset: 0.2,
    },
    secondary: {
      main: '#000000',
      contrastText: '#ffffff',
      contrastThreshold: 3,
      tonalOffset: 0.2,
    },
    background: {
      default: '#ffffff',
      paper: white,
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.95)',
      secondary: 'rgba(0, 0, 0, 0.5)',
    },
  },
  header: {
    color: white,
    background: '#000000',
    avatar: '#c6a059 !important',
    label: '#ffffff !important',
    dropdown: '#ffffff !important',
    logo: '#ffffff',
    topbar: {
      background: '#f8f9fa',
      color: '#000000',
      link: '#c6a059',
    },
  },
  sidebar: {
    color: '#CACACA',
    background: '#000000',
  },
  status: {
    statuscolor_1: '#255f64',
    statuscolor_2: '#f44336',
    statuscolor_3: '#ff9800',
  },
  Dataset: {
    color_1: '#ffffff',
    color_2: '#000000',
    color_3: '#CACACA',
  },
})

const VFLWOLFSBURGVariant = merge(defaultVariant, {
  name: THEMES.VFLWOLFSBURG,
  palette: {
    type: 'light',
    primary: {
      main: '#64b42d',
      contrastText: '#ffffff',
      contrastThreshold: 3,
      tonalOffset: 0.2,
    },
    secondary: {
      main: '#000000',
      contrastText: '#ffffff',
      contrastThreshold: 3,
      tonalOffset: 0.2,
    },
    background: {
      default: '#ffffff',
      paper: white,
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.95)',
      secondary: 'rgba(0, 0, 0, 0.5)',
    },
  },
  header: {
    color: white,
    background: '#2b353e',
    avatar: '#64b42d !important',
    label: '#ffffff !important',
    dropdown: '#ffffff !important',
    logo: '#ffffff',
    topbar: {
      background: '#f8f9fa',
      color: '#000000',
      link: '#c6a059',
    },
  },
  sidebar: {
    color: '#CACACA',
    background: '#2b353e',
  },
  status: {
    statuscolor_1: '#255f64',
    statuscolor_2: '#f44336',
    statuscolor_3: '#ff9800',
  },
  Dataset: {
    color_1: '#ffffff',
    color_2: '#000000',
    color_3: '#CACACA',
  },
})

const PSGVariant = merge(defaultVariant, {
  name: THEMES.PSG,
  palette: {
    type: 'light',
    primary: {
      main: '#154583',
      contrastText: '#ffffff',
      contrastThreshold: 3,
      tonalOffset: 0.2,
    },
    secondary: {
      main: '#000000',
      contrastText: '#ffffff',
      contrastThreshold: 3,
      tonalOffset: 0.2,
    },
    background: {
      default: '#ffffff',
      paper: white,
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.95)',
      secondary: 'rgba(0, 0, 0, 0.5)',
    },
  },
  header: {
    color: white,
    background: '#ffffff',
    avatar: '#154583 !important',
    label: '#154583 !important',
    dropdown: '#154583 !important',
    logo: '#154583',
    topbar: {
      background: '#f8f9fa',
      color: '#000000',
      link: '#c6a059',
    },
  },
  sidebar: {
    color: '#CACACA',
    background: '#ffffff',
  },
  status: {
    statuscolor_1: '#255f64',
    statuscolor_2: '#f44336',
    statuscolor_3: '#ff9800',
  },
  Dataset: {
    color_1: '#154583',
    color_2: '#000000',
    color_3: '#CACACA',
  },
})

const WESTONFCVariant = merge(defaultVariant, {
  name: THEMES.WESTONFC,
  palette: {
    type: 'light',
    primary: {
      main: '#8AD3F4',
      contrastText: '#000000',
      contrastThreshold: 3,
      tonalOffset: 0.2,
    },
    secondary: {
      main: '#000000',
      contrastText: '#ffffff',
      contrastThreshold: 3,
      tonalOffset: 0.2,
    },
    background: {
      default: '#ffffff',
      paper: white,
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.95)',
      secondary: 'rgba(0, 0, 0, 0.5)',
    },
  },
  header: {
    color: white,
    background: '#ffffff',
    avatar: '#8AD3F4 !important',
    label: '#000000 !important',
    dropdown: '#000000 !important',
    logo: '#8AD3F4',
    topbar: {
      background: '#f8f9fa',
      color: '#000000',
      link: '#c6a059',
    },
  },
  sidebar: {
    color: '#ffffff',
    background: '#000000',
  },
  status: {
    statuscolor_1: '#255f64',
    statuscolor_2: '#f44336',
    statuscolor_3: '#ff9800',
  },
  Dataset: {
    color_1: '#8AD3F4',
    color_2: '#000000',
    color_3: '#CACACA',
  },
})

const OEFBVariant = merge(defaultVariant, {
  name: THEMES.OEFB,
  palette: {
    type: 'light',
    primary: {
      main: '#d51317',
      contrastText: '#000000',
      contrastThreshold: 3,
      tonalOffset: 0.2,
    },
    secondary: {
      main: '#000000',
      contrastText: '#ffffff',
      contrastThreshold: 3,
      tonalOffset: 0.2,
    },
    background: {
      default: '#ffffff',
      paper: white,
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.95)',
      secondary: 'rgba(0, 0, 0, 0.5)',
    },
  },
  header: {
    color: white,
    background: '#ffffff',
    avatar: '#d51317 !important',
    label: '#000000 !important',
    dropdown: '#000000 !important',
    logo: '#d51317',
    topbar: {
      background: '#f8f9fa',
      color: '#000000',
      link: '#c6a059',
    },
  },
  sidebar: {
    color: '#ffffff',
    background: '#000000',
  },
  status: {
    statuscolor_1: '#255f64',
    statuscolor_2: '#f44336',
    statuscolor_3: '#ff9800',
  },
  Dataset: {
    color_1: '#8AD3F4',
    color_2: '#000000',
    color_3: '#CACACA',
  },
})

const variants = [
  defaultVariant,
  BVBVariant,
  ALIANZAVariant,
  VFLWOLFSBURGVariant,
  PSGVariant,
  WESTONFCVariant,
  OEFBVariant,
]

export default variants
