import { makeStyles } from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  avatarupload: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(0.8),
    marginBottom: theme.spacing(1.6),
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    borderWidth: 2,
    borderStyle: 'dashed',
    position: 'relative',
    borderColor: grey[300],
    background: grey[50],
    flexDirection: 'row',
    '& .MuiAvatar-root': {
      margin: theme.spacing(0.5),
      width: 150,
      height: 150,
      borderWidth: 2,
      borderStyle: 'solid',

      borderColor: grey[300],
    },
    '& .MuiBox-root': {
      position: 'absolute',
      right: 2,
      bottom: 2,
    },
  },
  editiconwrap: {
    position: 'absolute',
    right: 4,
    bottom: 4,
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
}))
