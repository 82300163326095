import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Divider } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  divider: {
    margin: theme.spacing(0.8, 0),
    width: '100%',
  },
}))

const MuiDivider = ({ color = '' }) => {
  const classes = useStyles()

  return <Divider color={color} className={classes.divider} />
}

MuiDivider.defaultProps = {
  color: 'primary',
}

MuiDivider.propTypes = {
  color: PropTypes.string,
}

export default MuiDivider
