import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';

const CardTitle = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '30px',
  fontSize: '24px',
  fontWeight: 300,
  letterSpacing: '0.3px',
  color: '#545454',
  lineHeight: '28px',

  '& a': {
    color: '#545454',
  },
});

const CardTitleIcon = styled('div')(({ icon }) => ({
  display: 'inline-block',
  width: '25px',
  height: '25px',
  marginRight: '10px',
  background: `url(${icon}) no-repeat center`,
  backgroundSize: 'contain',
}));

const Card = ({ children, className, ...rest }) => (
  <div className={className} {...rest}>
    {children}
  </div>
);

Card.Title = CardTitle;

Card.Title.Icon = CardTitleIcon;

Card.defaultProps = {
  children: <div />,
};

Card.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  ]),
  className: PropTypes.string.isRequired,
};

const StyledCard = styled(Card)({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '2rem',
  background: '#ffffff',
  borderRadius: '8px',
  padding: '2rem',

  // ${({ fullHeight }) => fullHeight && 'height: calc(100% - 20px)'};
});

export default StyledCard;