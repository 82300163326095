/* eslint-disable */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Box, Toolbar, Typography } from '@material-ui/core'
import PROP_TYPES from 'constants/propTypes'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  title: {
    flex: '1 1 0',
  },
  toolbar: {
    minHeight: 56,
    padding: theme.spacing(0, 2),
  },
}))

const InnrHead = ({ children, title, divider, label }) => {
  const classes = useStyles()

  return (
    <Box>
      <Toolbar className={classes.toolbar} component={Paper}>
        <Typography variant="h4" component="h4" className={classes.title}>
          {title} {label}
        </Typography>
        {children}
      </Toolbar>
      {divider}
    </Box>
  )
}
InnrHead.defaultProps = {
  children: <div />,
}

InnrHead.propTypes = {
  children: PROP_TYPES.children,
  title: PropTypes.shape().isRequired,
  divider: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
}

export default InnrHead
