import { styled } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

const DrillsContainer = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  padding: '0.5rem 0',

  '& > div': {
    width: 'calc(20% - 12px)',
    margin: '6px',
    border: '1px solid #ededed',
    borderRadius: '8px',

    '@media only screen and (max-width: 1279px)': {
      width: 'calc(50% - 12px)',
    },

    '@media only screen and (max-width: 767px)': {
      width: '100%',
    },
  },

  '& .drillImg': {
    width: '100%',
    height: '6.25rem',
    position: 'inherit',
    margin: 0,
    padding: 0,
    borderRadius: 0,
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    background: 'white',

    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
    },

    '@media only screen and (max-width: 769px)': {
      width: '5rem',
      height: '5rem',
    },
  },

  '& .title': {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    width: 'calc(100% - 2rem)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '1.3',
  },

  '& .rec-carousel-wrapper': {
    width: '100% !important',
  },

  '@media only screen and (max-width: 769px)': {
    '& .MuiAlert-root': {
      width: '100%',
      height: '7rem',
      backgroundColor: '#fff !important',
      alignItems: 'flex-start',
      border: '#ededed solid 1px',
      boxShadow: '0 0px 36px 0 rgba(0, 0, 0, 0.05)',

      '& .MuiAlert-message': {
        width: 'calc(100% - 34px)',
      },
      '& .Snackbar-Box': {
        width: 'calc(100% - 100px)',
      },
      '& .MuiAlert-icon': {
        display: 'none',
      },
    },
    '& .drillContent': {
      width: 'calc(100% - 126px)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginLeft: '0.7rem',

      '& .extraAction': {
        display: 'flex',

        '& .time-content': {
          display: 'flex',
          marginLeft: '0.3rem',
          alignItems: 'center',

          '& .MuiCardMedia-img': {
            width: '1rem',
            height: '1rem',
            marginRight: '0.3rem',
          },
        },
      },
    },
  },
})

export default DrillsContainer
