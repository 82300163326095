import * as React from 'react'
import { DndProvider } from 'react-dnd'
import { MultiBackend } from 'react-dnd-multi-backend'
import { HTML5toTouch } from 'rdndmb-html5-to-touch' // or any other pipeline


export default Component => {
  return props => (
    <DndProvider backend={MultiBackend} options={HTML5toTouch}>
      <Component {...props} />
    </DndProvider>
  )
}
