import React, { useCallback, useEffect, useState } from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { Typography, MenuItem, Box } from '@material-ui/core'
import { SubLink } from 'containers'
import { formatCostumDateForUI, formatDateForServer } from 'utils/formatDate'
import { DataGrid } from '@mui/x-data-grid'
import DataGridExportToolbar from 'components/DataGridExportToolbar'
import { fetchTeamPlayers } from 'containers/TeamPlayersData/api'
import { connect } from 'react-redux'
import { LoadingSpinner, SelectedMenu } from 'components'
import { players as playersModule } from 'redux/modules'
import { Avatar, Player } from '../style/styledComponents'
import {
  PLAYER_POSITIONS,
  PLAYER_STATUS_ACTIVE,
  PLAYER_STATUS_DEACTIVATED,
} from 'constants/player'
import { TEAM_DASHBOARD_ROUTE } from 'constants/routes'

const PlayersPersonalData = ({ updatePlayer, teamId }) => {
  const [playersData, setPlayersData] = useState({})
  const [playerStatus, setPlayerStatus] = useState(PLAYER_STATUS_ACTIVE)
  const [loading, setLoading] = useState(false)

  const avatarImage = (params) => (
    <Player
      component={SubLink}
      to={`${TEAM_DASHBOARD_ROUTE}/player/${params.row.uuid}/overview`}
    >
      {params.row.avatar?.full_url !== null ? (
        <Avatar alt={params.row.first_name} src={params.row.avatar?.full_url} />
      ) : (
        <Avatar alt={params.row.first_name} />
      )}
      <div>
        <Typography variant="h4">
          {' '}
          {`${params.row.first_name} ${params.row.last_name}`}
        </Typography>
        <Typography variant="subtitle2" noWrap>
          {PLAYER_POSITIONS.find((p) => p.value === params.row.position).title}
        </Typography>
      </div>
    </Player>
  )

  const columns = [
    {
      field: 'uuid',
      headerName: 'UUID',
      headerAlign: 'left',
      headerClassName: 'primary',
      align: 'left',
      width: 120,
      hide: true,
      editable: true,
    },
    {
      field: 'avatar',
      headerName: <Translate id="events.table-player" />,
      width: 300,
      renderCell: avatarImage,
      editable: false,
      sortable: false,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'number',
      headerName: <Translate id="form.number" />,
      type: 'number',
      width: 110,
      editable: true,
    },
    {
      field: 'first_name',
      headerName: <Translate id="signup.first_name" />,
      width: 150,
      hide: true,
      editable: false,
    },
    {
      field: 'last_name',
      headerName: <Translate id="player.last_name" />,
      width: 150,
      hide: true,
      editable: false,
    },
    {
      field: 'birthday',
      headerName: <Translate id="player.birthday" />,
      type: 'date',
      valueFormatter: (params) =>
        params.value ? formatCostumDateForUI(params.value) : '',
      width: 110,
      editable: true,
    },
    {
      field: 'email_1',
      headerName: <Translate id="player.email" />,
      headerClassName: 'secondary',
      width: 300,
      editable: true,
    },
    {
      field: 'phone',
      headerName: <Translate id="player.phone" />,
      width: 200,
      editable: true,
    },
    {
      field: 'street',
      headerName: <Translate id="player.street" />,
      width: 300,
      editable: true,
    },
    {
      field: 'zip',
      headerName: <Translate id="player.postal-code" />,
      width: 200,
      editable: true,
    },
    {
      field: 'city',
      headerName: <Translate id="player.city" />,
      width: 200,
      editable: true,
    },
  ]
  const [sortModel, setSortModel] = useState([
    {
      field: 'first_name',
      sort: 'asc',
    },
  ])

  const handleEditCellModelChange = useCallback((model) => {
    const updateData = {
      birthday: model.row.birthday
        ? formatDateForServer(model.row.birthday)
        : null,
      city: model.row.city,
      number: model.row.number,
      phone: model.row.phone,
      street: model.row.street,
      zip: model.row.zip,
    }
    updatePlayer(model.row.id, updateData).then((res) => {
      return res
    })
  }, [])

  const handlePlayerStatusChange = (e) => {
    setPlayerStatus(e.target.value)
  }
  const fetchPlayers = (params) => {
    fetchTeamPlayers(teamId, params).then((res) => {
      setPlayersData(res.data)
      setLoading(false)
    })
  }

  useEffect(() => {
    if (!teamId) return
    const params = { status: playerStatus }
    setLoading(true)
    fetchPlayers(params)
  }, [teamId, playerStatus])

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          <Box mx={2}>
          <SelectedMenu
              id="status-type"
              value={playerStatus}
              style={{ width: 180 }}
              margin='dense'
              onChange={handlePlayerStatusChange}
              label={<Translate id="form.status" />}
            >
              <MenuItem value={PLAYER_STATUS_ACTIVE}>
                <Translate id="movePlayers.active" />
              </MenuItem>
              <MenuItem value={PLAYER_STATUS_DEACTIVATED}>
                <Translate id="movePlayers.inactive" />
              </MenuItem>
            </SelectedMenu>
          </Box>
          <div style={{ height: 'auto', width: '100%' }}>
            <DataGrid
              rows={playersData}
              columns={columns}
              pageSize={100}
              rowsPerPageOptions={[5]}
              checkboxSelection={false}
              sortModel={sortModel}
              onSortModelChange={(model) => setSortModel(model)}
              disableSelectionOnClick
              disableColumnFilter
              disableColumnMenu
              autoHeight
              editMode="row"
              rowsLoadingMode="server"
              onRowEditStop={(model) => handleEditCellModelChange(model)}
              components={{
                Toolbar: DataGridExportToolbar,
              }}
              componentsProps={{
                toolbar: {
                  csvOptions: {
                    fields: [
                      'first_name',
                      'last_name',
                      'number',
                      'birthday',
                      'email_1',
                      'phone',
                      'street',
                      'zip',
                      'city',
                    ],
                  },
                },
              }}
            />
          </div>
        </>
      )}
    </>
  )
}

PlayersPersonalData.propTypes = {
  teamId: PropTypes.number.isRequired,
  updatePlayer: PropTypes.func.isRequired,
}
export default connect(
  ({ team }) => ({
    teamId: team.id,
  }),
  {
    updatePlayer: playersModule.updatePlayer,
  }
)(PlayersPersonalData)
