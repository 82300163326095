import globalTranslations from 'translations/global.json'
import { store } from '../redux/store'

const getTranslatedLabelForFormField = (key) => {
  // Get locale from redux store
  const state = store.getState()
  const { locale } = state.auth

  // Use string split to create array of translation keys to traverse and fetch translations accordingly
  const keys = key.split('.') 
  const translations = keys.reduce((obj, k) => {
    if (obj && obj[k]) {
      return obj[k]
    }

    return null
  }, globalTranslations)

  if (translations) {
    // Return the translation in particular language as per  language index
    const index = ['en', 'de', 'es', 'it', 'pl', 'jp'].indexOf(locale)
    if (index !== -1 && translations[index]) {
      return translations[index]
    }
  }

  return keys[keys.length - 1] // Return the last key if translation not found
}

export default getTranslatedLabelForFormField
