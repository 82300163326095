import React, { useMemo } from 'react'
import { Field, FieldArray } from 'redux-form'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { SearchInput } from 'components'
import { drills as drillsModule } from 'redux/modules'
import { connect } from 'react-redux'
import drillCategories from 'constants/drillCategories'
import PROP_TYPES from 'constants/propTypes'

const DRILL_CATEGORY_TO_FIELD_NAME = {
  warmup: 'warmup_drills',
  main_part: 'main_part_drills',
  final_part: 'final_part_drills',
}

const DrillsSelector = ({ availableDrills, fetchDrill }) => {
  const drillsGroupedByType = useMemo(
    () =>
      drillCategories.map(({ value, title }) => ({
        name: DRILL_CATEGORY_TO_FIELD_NAME[value],
        title,
        options: availableDrills.map((drill) => ({
          value: drill.id,
          title: drill.title,
        })),
      })),
    [availableDrills]
  )

  return (
    <>
      {drillsGroupedByType.map(({ name, title, options }) => {
        return (
          <div key={name}>
            {title}
            <div className="packageEditSearch">
              <FieldArray
                name={name}
                component={({ fields }) => (
                  <>
                    {fields.map((subName, index) => {
                      let count = 1

                      return (
                        <Translate>
                          {({ translate }) => (
                            <Field
                              key={subName}
                              name={subName}
                              component={({ input, meta }) => (
                                <SearchInput
                                  value={+input.value}
                                  suggestions={options}
                                  onChange={(value) => {
                                    if (count === 1) {
                                      fetchDrill(+input.value, {
                                        removed: true,
                                        track: 'training_package',
                                      })
                                    }
                                    count += 1

                                    return value
                                      ? input.onChange
                                      : fields.remove(index)
                                  }}
                                  placeholder={translate(
                                    'training.select-drill'
                                  )}
                                  meta={meta}
                                />
                              )}
                            />
                          )}
                        </Translate>
                      )
                    })}
                    <Translate>
                      {({ translate }) => (
                        <SearchInput
                          suggestions={options.filter(
                            ({ value }) =>
                              !(fields.getAll() || []).includes(value)
                          )}
                          onChange={(value) => value && fields.push(value)}
                          placeholder={translate('training.select-drill')}
                          isNew
                          fetchDrill={fetchDrill}
                        />
                      )}
                    </Translate>
                  </>
                )}
              />
            </div>
          </div>
        )
      })}
    </>
  )
}

DrillsSelector.defaultProps = {
  availableDrills: [],
}

DrillsSelector.propTypes = {
  availableDrills: PROP_TYPES.arrayOfObjects,
  fetchDrill: PropTypes.func.isRequired,
}

export default connect(null, {
  fetchDrill: drillsModule.fetchDrill,
})(DrillsSelector)
