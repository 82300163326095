import React, { useState } from 'react'
import { IconButton, Tooltip } from '@material-ui/core'
import binIcon from 'assets/images/16px/bin@1x.svg'
import editIcon from 'assets/images/16px/edit@1x.svg'
import { Translate } from 'react-localize-redux'
import AlertDialog from 'components/AlertDialog'
import StaffCreateDialog from './StaffCreateDialog'

const Actions = (params) => {
  const [open, setOpen] = React.useState(false)
  const [openEdit, setOpenEdit] = React.useState(false)
  const [initialValues, setInitialValues] = useState({})
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const handleDelete = (parameters) => {
    if (parameters.roleId) {
      params.row
        .revokeUserRole({
          staffUuid: parameters.staffUuid,
          roleUuid: parameters.roleUuid,
        })
        .then(() => {
          // @todo remove reload and do required changes after api success without reloading
          window.location.reload()
        })
    } else if (parameters.staffUuid) {
      params.row.deleteStaff(parameters.staffUuid).then(() => {
        // @todo remove reload and remove staff row after api success without reloading
        window.location.reload()
      })
    }
    setOpen(false)
  }
  const handleEditDialog = (selectedStaff) => {
    setOpenEdit(true)
    setInitialValues({
      id: selectedStaff.id,
      first_name: selectedStaff.first_name,
      last_name: selectedStaff.last_name,
      email: selectedStaff.email,
      position: selectedStaff.position,
    })
  }
  const handleEditClose = () => {
    setOpenEdit(false)
  }

  return (
    <>
      {open && (
        <AlertDialog
          open={open}
          onClose={handleClose}
          onCancel={handleClose}
          onSuccess={() =>
            params.row.userRoleId
              ? handleDelete({
                  staffUuid: params.row.uuid,
                  roleId: params.row.userRoleId,
                  roleUuid: params.row.roleUuid,
                })
              : handleDelete({
                  staffUuid: params.row.uuid,
                  roleId: null,
                  roleUuid: null,
                })
          }
          title={<Translate id="button.delete" />}
          subTitle={<Translate id="events.are-you-sure-you-want-to-delete" />}
          submitButtonLabel={<Translate id="button.ok" />}
          cancelButtonLabel={<Translate id="button.close" />}
        />
      )}
      {openEdit && (
        <StaffCreateDialog
          open={openEdit}
          onClose={handleEditClose}
          initialValues={initialValues}
        />
      )}
      {params.row.userRoleId && (
        <Tooltip title={<Translate id="button.edit" />} placement="top" arrow>
          <IconButton onClick={() => handleEditDialog(params.row)}>
            <img src={editIcon} alt={editIcon} />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip
        title={
          <>
            <Translate id="button.delete" />
            &nbsp;
            {params.row.userRoleId ? (
              <Translate id="userRoles.role" />
            ) : (
              <Translate id="team.staff" />
            )}
          </>
        }
        placement="top"
        arrow
      >
        <IconButton onClick={handleClickOpen}>
          <img src={binIcon} alt={binIcon} />
        </IconButton>
      </Tooltip>
    </>
  )
}

export default Actions
