import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import { drills } from 'redux/modules'
import Loader from './Loader'
import PROP_TYPES from 'constants/propTypes'

const DrillLoader = props => {
  const {
    children,
    drillUuid,
    fetchDrill,
    activeLanguage: { code: locale },
    ...restProps
  } = props

  return (
    <Loader
      fetchMethod={() => fetchDrill(drillUuid, { locale })}
      triggers={[locale]}
      {...restProps}
    >
      {children}
    </Loader>
  )
}

DrillLoader.propTypes = {
  children: PROP_TYPES.children.isRequired,
  drillUuid: PROP_TYPES.string.isRequired,
  fetchDrill: PROP_TYPES.func.isRequired,
  activeLanguage: PROP_TYPES.shape().isRequired,
}

export default compose(
  connect(
    null,
    { fetchDrill: drills.fetchDrill }
  ),
  withLocalize
)(DrillLoader)
