import React, { useEffect, useState } from 'react'
import {
  Box,
  Chip,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { matches } from 'redux/modules'
import RatePlayerTableRow from './RatePlayerTableRow'
import { playerFilterCategories } from 'constants/player'
import PROP_TYPES from 'constants/propTypes'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paper': {
      maxWidth: 700,
    },
    '& .MuiTableCell-root': {
      padding: theme.spacing(1.2),
    },
    '& .MuiButtonBase-root': {
      marginRight: theme.spacing(1),
    },
  },
  ratings: {
    margin: theme.spacing(0, 1, 1),
  },
  rosterContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}))

const RatePlayers = ({ participants, updateMatchParticipant }) => {
  const classes = useStyles()
  const [startFormationPlayers, setStartFormationPlayers] = useState([])
  const [substitutePlayers, setSubstitutePlayers] = useState([])
  const [playerfilter, setPlayerfilter] = useState('all')
  const [matchPlayers, setMatchPlayers] = useState([])

  const setPlayersInLists = (filter) => {
    if (filter === 'all') {
      setStartFormationPlayers(
        matchPlayers.filter(
          (p) => p.participant && (p.position_x !== 0 || p.position_y !== 0)
        )
      )
      setSubstitutePlayers(
        matchPlayers.filter(
          (p) =>
            (p.availability === 'AVAILABLE' ||
              p.availability === 'NOT_RESPONDED') &&
            !p.participant
        )
      )
    } else {
      setStartFormationPlayers(
        matchPlayers.filter(
          (p) =>
            p.participant &&
            (p.position_x !== 0 || p.position_y !== 0) &&
            p.player.position === filter
        )
      )
      setSubstitutePlayers(
        matchPlayers.filter(
          (p) =>
            (p.availability === 'AVAILABLE' ||
              p.availability === 'NOT_RESPONDED') &&
            !p.participant &&
            p.player.position === filter
        )
      )
    }
  }

  const handleFilterClick = (value) => {
    setPlayerfilter(value)
    setPlayersInLists(value)
  }
  const onChangeRating = (newRating, matchPlayerUuid) => {
    updateMatchParticipant(matchPlayerUuid, {
      rating: newRating,
    })
  }
  const onChangePlayerTime = ({ target: { value: v } }, matchPlayerUuid) => {
    updateMatchParticipant(matchPlayerUuid, {
      playing_minutes: +v,
    })
  }
  useEffect(() => {
    setPlayersInLists(playerfilter)
  }, [matchPlayers])

  useEffect(() => {
    if (participants.length > 0) {
      setMatchPlayers(
        participants.map((p) => {
          return {
            ...p,
            player: p.relationships.player,
          }
        })
      )
    }
  }, [participants])

  return (
    <>
      <Grid item xs={12} md={12} lg={12}>
        <Paper elevation={3}>
          <Grid container>
            <Grid xs={12}>
              <Box mb={2}>
                <Typography variant="h4">
                  <Translate id="matches.rate-players.button" />
                </Typography>
              </Box>
            </Grid>
            <Grid xs={12}>
              {playerFilterCategories.map((cat) => (
                <Chip
                  key={cat.key}
                  label={cat.displayValue}
                  color="primary"
                  variant={playerfilter === cat.key ? 'default' : 'outlined'}
                  onClick={() => handleFilterClick(cat.key)}
                  className={classes.chip}
                />
              ))}
            </Grid>
            <Grid xs={12} className={classes.rosterContainer}>
              <Grid container>
                <Grid xs={8}>
                  <Typography variant="h4">
                    <Translate id="matches.rate-players.roster" />
                  </Typography>
                </Grid>
              </Grid>
              {startFormationPlayers.length > 0 ? (
                <TableContainer component={Paper}>
                  <Table aria-label="roster-players-table">
                    <TableBody>
                      <RatePlayerTableRow
                        matchPlayers={startFormationPlayers}
                        onChangeRating={onChangeRating}
                        onChangePlayerTime={onChangePlayerTime}
                      />
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography variant="h6">
                  <Translate id="matches.rate-players.no-players" />
                </Typography>
              )}

              <Typography variant="h4" className={classes.rosterContainer}>
                <Translate id="matches.rate-players.substitute" />
              </Typography>
              {substitutePlayers.length > 0 ? (
                <TableContainer component={Paper}>
                  <Table aria-label="substitute-players-table">
                    <TableBody>
                      <RatePlayerTableRow
                        matchPlayers={substitutePlayers}
                        onChangeRating={onChangeRating}
                        onChangePlayerTime={onChangePlayerTime}
                      />
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography variant="h6" className={classes.rosterContainer}>
                  <Translate id="matches.rate-players.no-players" />
                </Typography>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  )
}
RatePlayers.propTypes = {
  participants: PROP_TYPES.arrayOfObjects.isRequired,
  updateMatchParticipant: PROP_TYPES.func.isRequired,
}
export default connect(
  ({ matches: { current } }) => ({
    participants: current.relationships.participants,
  }),
  {
    updateMatchParticipant: matches.updateMatchParticipant,
  }
)(RatePlayers)
