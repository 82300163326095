import React, { useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  MenuItem,
  Checkbox,
  Grid,
  Box,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { AvatarMuiEditor, DatePicker, SelectedMenu } from 'components'

import {
  handleTextField,
  handleCheckBox,
  handleDateChange,
  checkError,
  checkTextFieldError,
} from 'utils/validate'
import { formatDateFromServer } from 'utils/formatDate'
import CircularSpinner from 'components/loader/CircularSpinner'
import {
  PLAYER_POSITIONS,
  PLAYER_SUB_POSITIONS,
  FOOT_OPTIONS,
  GENDER_OPTIONS,
} from 'constants/player'

const PlayerEditDialog = ({
  initialValues,
  open,
  handleClose,
  updatePlayer,
  updatePlayerAvatar,
  isPlayerDashboard,
  dateFormat,
  isLoading,
}) => {
  const MIN_NUMBER = 1
  const MAX_NUMBER = 999
  const tempPosition = initialValues.position || 'goalkeeper'
  const [numberError, setNumberError] = useState(false)
  const [playerData, setPlayerData] = React.useState({
    ...initialValues,
    captain: initialValues?.captain ? 1 : 0,
    position: tempPosition,
    sub_position: initialValues.sub_position
      ? initialValues.sub_position
      : PLAYER_SUB_POSITIONS[tempPosition][0].value,
  })

  const SUP_POSITIONS = PLAYER_SUB_POSITIONS[playerData.position]

  const handleSave = () => {
    const fieldsToCheck = [
      'email_1',
      'phone',
      'city',
      'zip',
      'street',
      'number',
    ]
    const errors = [
      'email_1_error',
      'phone_error',
      'city_error',
      'zip_error',
      'street_error',
      'number_error',
    ]

    fieldsToCheck.forEach((field, index) => {
      if (!playerData[field] || playerData[field].length === 0) {
        delete playerData[errors[index]]
      }
    })
    const isError = checkError(playerData)
    if (isError) {
      setPlayerData({ ...playerData, [isError]: true })

      return
    }
    const isErrorForNumberField = checkTextFieldError(playerData, ['number'])
    if (isErrorForNumberField) {
      setPlayerData({ ...playerData, [isErrorForNumberField]: true })

      return
    }
    const isErrorForFields = checkTextFieldError(playerData, ['first_name'])
    if (isErrorForFields) {
      setPlayerData({ ...playerData, [isErrorForFields]: true })

      return
    }
    const body = {
      ...playerData,
      birthday: moment(playerData.birthday, 'YYYY-MM-DD').isValid()
        ? formatDateFromServer(playerData.birthday)[0]
        : null,
      joining_date: moment(playerData.joining_date, 'YYYY-MM-DD').isValid()
        ? formatDateFromServer(playerData.joining_date)[0]
        : null,
    }
    delete body.skills

    updatePlayer(playerData.id, body).then((res) => {
      const afterResponse = {
        ...playerData,
        avatar: res.response.data.avatar,
      }
      setPlayerData(afterResponse)
      handleClose()
    })
  }
  const handlePositionWithSubPosition = (e) => {
    setPlayerData({
      ...playerData,
      position: e.target.value,
      sub_position: PLAYER_SUB_POSITIONS[e.target.value][0].value,
    })
    // Prevent page crash due to data grid predefined method calling on Select list change
    e.stopPropagation()
  }

  const handleDialogClose = () => {
    setPlayerData({
      ...initialValues,
      position: tempPosition,
      sub_position: initialValues.sub_position
        ? initialValues.sub_position
        : PLAYER_SUB_POSITIONS[tempPosition][0].value,
    })
    handleClose()
  }

  const handleJersyNumber = (e, jersyNumber, setJersyNumber) => {
    const {
      target: { name, value },
    } = { ...e }

    if (name === 'number') {
      if (!value) {
        setNumberError(true)
      } else {
        const number = parseInt(value, 10)
        setNumberError(number < MIN_NUMBER || number > MAX_NUMBER)
      }
    }
    handleTextField(e, jersyNumber, setJersyNumber)
  }
  const handleFieldChange = (e, player, setPlayer) => {
    handleTextField(e, player, setPlayer)
    // Prevent page crash due to data grid predefined method calling on Select list change
    e.stopPropagation()
  }

  return (
    <div>
      <Translate>
        {({ translate }) => (
          <Dialog
            open={open}
            onClose={handleDialogClose}
            aria-labelledby="form-dialog-title"
          >
            {isLoading && <CircularSpinner loadingcircular={isLoading} />}
            <DialogTitle>
              <Translate id="player.edit" />
            </DialogTitle>
            <DialogContent dividers>
              {isPlayerDashboard && (
                <Box className="profileContent">
                  <AvatarMuiEditor
                    data={playerData}
                    updateImage={updatePlayerAvatar}
                  />
                </Box>
              )}
              <Grid container spacing="2">
                <Grid item xs={6}>
                  <TextField
                    label={translate('form.first-name')}
                    variant="outlined"
                    autoFocus
                    id="first_name"
                    name="first_name"
                    size="small"
                    value={playerData.first_name}
                    fullWidth
                    onChange={(e) =>
                      handleTextField(e, playerData, setPlayerData)
                    }
                    error={playerData.first_name_error}
                    helperText={
                      playerData.first_name_error ? (
                        <Translate id="player.validation.first-name" />
                      ) : (
                        ''
                      )
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label={translate('form.last-name')}
                    variant="outlined"
                    autoFocus
                    id="last_name"
                    name="last_name"
                    size="small"
                    value={playerData.last_name}
                    fullWidth
                    onChange={(e) =>
                      handleTextField(e, playerData, setPlayerData)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    disableFuture
                    disableToolbar={false}
                    required
                    label={<Translate id="event.birthday" />}
                    selectedValue={playerData.birthday}
                    onDateChange={(date) =>
                      handleDateChange(
                        date,
                        'birthday',
                        playerData,
                        setPlayerData
                      )
                    }
                    openTo="date"
                    margin="none"
                    isEditMode
                    dateFormat={dateFormat}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SelectedMenu
                    id="gender"
                    name="gender"
                    value={playerData.gender || 'm'}
                    onChange={(e) =>
                      handleFieldChange(e, playerData, setPlayerData)
                    }
                    label={<Translate id="form.gender" />}
                  >
                    {GENDER_OPTIONS.map((gender) => (
                      <MenuItem value={gender.value} key={gender.value}>
                        {gender.title}
                      </MenuItem>
                    ))}
                  </SelectedMenu>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label={translate('form.e-mail-1')}
                    variant="outlined"
                    autoFocus
                    id="email_1"
                    name="email_1"
                    size="small"
                    value={playerData.email_1}
                    fullWidth
                    onChange={(e) =>
                      handleTextField(e, playerData, setPlayerData)
                    }
                    error={playerData.email_1_error_valid}
                    helperText={
                      playerData.email_1_error_valid ? (
                        <Translate id="player.validation.email-valid" />
                      ) : (
                        <></>
                      )
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    autoFocus
                    id="phone"
                    name="phone"
                    size="small"
                    label={translate('form.phone-number')}
                    type="tel"
                    value={playerData.phone}
                    fullWidth
                    onChange={(e) =>
                      handleTextField(e, playerData, setPlayerData)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    autoFocus
                    id="street"
                    name="street"
                    size="small"
                    label={translate('form.street')}
                    value={playerData.street}
                    fullWidth
                    onChange={(e) =>
                      handleTextField(e, playerData, setPlayerData)
                    }
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    autoFocus
                    id="zip"
                    name="zip"
                    size="small"
                    label={translate('form.postal-code')}
                    value={playerData.zip}
                    fullWidth
                    onChange={(e) =>
                      handleTextField(e, playerData, setPlayerData)
                    }
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    autoFocus
                    id="city"
                    name="city"
                    label={translate('form.city')}
                    size="small"
                    value={playerData.city}
                    fullWidth
                    onChange={(e) =>
                      handleTextField(e, playerData, setPlayerData)
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <Translate id="player.player-info" />
                </Grid>

                <Grid item xs={6}>
                  <SelectedMenu
                    id="position"
                    name="position"
                    value={playerData.position || 'goalkeeper'}
                    onChange={(e) =>
                      handleFieldChange(e, playerData, setPlayerData)
                    }
                    label={<Translate id="form.position" />}
                  >
                    {PLAYER_POSITIONS.map((position) => (
                      <MenuItem value={position.value} key={position.value}>
                        {position.title}
                      </MenuItem>
                    ))}
                  </SelectedMenu>
                </Grid>

                <Grid item xs={6}>
                  <SelectedMenu
                    id="sub-position"
                    name="sub_position"
                    value={playerData.sub_position}
                    onChange={(e) =>
                      handleFieldChange(e, playerData, setPlayerData)
                    }
                    label={<Translate id="form.sub_position" />}
                  >
                    {SUP_POSITIONS.map((position) => (
                      <MenuItem value={position.value} key={position.value}>
                        {position.title}
                      </MenuItem>
                    ))}
                  </SelectedMenu>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    label={translate('form.number')}
                    variant="outlined"
                    autoFocus
                    id="number"
                    name="number"
                    size="small"
                    defaultValue={playerData.number}
                    fullWidth
                    type="number"
                    onChange={(e) =>
                      handleJersyNumber(e, playerData, setPlayerData)
                    }
                    error={numberError}
                    helperText={
                      numberError && <Translate id="form.number-too-large" />
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <SelectedMenu
                    id="foot"
                    name="foot"
                    value={playerData.foot}
                    onChange={(e) =>
                      handleFieldChange(e, playerData, setPlayerData)
                    }
                    label={<Translate id="form.gender" />}
                  >
                    {FOOT_OPTIONS.map((foot) => (
                      <MenuItem value={foot.value} key={foot.value}>
                        {foot.title}
                      </MenuItem>
                    ))}
                  </SelectedMenu>
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    disableFuture
                    disableToolbar={false}
                    required
                    label={<Translate id="player.joining-date" />}
                    selectedValue={playerData.joining_date}
                    onDateChange={(date) =>
                      handleDateChange(
                        date,
                        'joining_date',
                        playerData,
                        setPlayerData
                      )
                    }
                    openTo="date"
                    margin="none"
                    isEditMode
                    dateFormat={dateFormat}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={playerData.captain === 1 || playerData.captain}
                        onChange={(e) =>
                          handleCheckBox(e, playerData, setPlayerData)
                        }
                        name="captain"
                        id="captain"
                      />
                    }
                    label="Team Captain"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={handleDialogClose}>
                <Translate id="button.cancel" />
              </Button>
              <Button color="primary" variant="contained" onClick={handleSave}>
                <Translate id="button.save" />
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Translate>
    </div>
  )
}

PlayerEditDialog.defaultProps = {
  isPlayerDashboard: false,
}

PlayerEditDialog.propTypes = {
  initialValues: PropTypes.shape().isRequired,
  handleClose: PropTypes.func.isRequired,
  updatePlayer: PropTypes.func.isRequired,
  updatePlayerAvatar: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isPlayerDashboard: PropTypes.bool,
  dateFormat: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default connect(
  ({ auth, players }) => ({
    dateFormat: auth.user.datetime_format,
    isLoading: players.isLoading,
  }),
  null
)(PlayerEditDialog)
