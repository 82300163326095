import React from 'react'
import { Translate } from 'react-localize-redux'
import Select from 'react-select'
import PROP_TYPES from 'constants/propTypes'

const ReactSelect = ({
  value,
  suggestions,
  onChange,
  placeholder,
  isMulti,
  isEveryOne,
  index,
}) => {
  const options = isEveryOne
    ? [
        { value: 'everyOne', label: <Translate id="videos.tag-everyone" /> },
        ...suggestions,
      ]
    : suggestions

  const change = (newPlayers, { option }) => {
    onChange(newPlayers, option, index)
  }

  return (
    <Select
      value={value}
      isMulti={isMulti}
      options={options}
      onChange={index > -1 ? change : onChange}
      placeholder={placeholder}
    />
  )
}

ReactSelect.defaultProps = {
  value: [],
  suggestions: [],
  placeholder: '',
  isEveryOne: false,
  isMulti: true,
  index: -1,
}

ReactSelect.propTypes = {
  value: PROP_TYPES.arrayOfObjects,
  suggestions: PROP_TYPES.arrayOfObjects,
  onChange: PROP_TYPES.func.isRequired,
  placeholder: PROP_TYPES.string,
  isEveryOne: PROP_TYPES.bool,
  isMulti: PROP_TYPES.bool,
  index: PROP_TYPES.number,
}

export default ReactSelect
