import React, { useState } from 'react'
import { Translate } from 'react-localize-redux'
import { makeStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { SubLink, Avatar } from 'containers'
import PersonIcon from '@material-ui/icons/Person'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { players as playersModule } from 'redux/modules'
import {
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListItemAvatar,
  Typography,
  Tooltip,
  IconButton,
  Button,
  Box,
} from '@material-ui/core'
import AlertDialog from 'components/AlertDialog'
import CircularSpinner from 'components/loader/CircularSpinner'
import PlayerEditDialog from 'routes/PlayerDashboard/components/PlayerEditDialog'
import AddPlayersDialog from './AddPlayersDialog'
import PROP_TYPES from 'constants/propTypes'
import { TEAM_PLAYER_DASHBOARD_ROUTE } from 'constants/routes'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: 'auto',
    height: 204,
    padding: theme.spacing(0),
    '& .MuiListItemSecondaryAction-root': {
      right: '12px',
    },
  },
}))

const AllPlayers = ({
  players,
  title,
  updatePlayer,
  deletePlayer,
  createPlayerQuick,
  fetchTeamPlayers,
  updatePlayerAvatar,
  team,
}) => {
  const classes = useStyles()
  const fromPathname = ''
  const [initialValues, setInitialValues] = useState(null)
  const [open, setOpen] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const [deletePlayerId, setDeletePlayerId] = useState(0)
  const [playersArr, setPlayersArr] = useState(players)
  const [loadingcircular, setLoadingCircular] = useState(false)
  const [openAddPlayers, setOpenAddPlayers] = useState(false)

  const handleOpen = (player) => {
    setOpen(true)
    setInitialValues(player)
  }
  const handleClose = () => {
    setOpen(false)
    setOpenAlert(false)
    setInitialValues(null)
    setOpenAddPlayers(false)
    // update players list
    fetchTeamPlayers(team.id).then((result) => {
      setPlayersArr(
        result.response.data.filter((player) => player.status === 'ACTIVE')
      )
    })
  }
  const changeEditablePlayer = (player) => {
    playersArr[playersArr.findIndex((p) => p.id === player.id)] = player
  }
  const handleDelete = () => {
    setOpenAlert(false)
    setLoadingCircular(true)
    deletePlayer(+deletePlayerId).then(() => {
      let temp = []
      temp = playersArr.filter((obj) => obj.id !== deletePlayerId)
      setPlayersArr(temp)
      setLoadingCircular(false)
    })
  }

  const handleAlertOpen = () => {
    setOpenAlert(true)
  }
  const openAlertBox = (player) => {
    if (player) {
      setDeletePlayerId(player.id)
      handleAlertOpen()
    }
  }

  const fetchPlayers = (teamId) => {
    fetchTeamPlayers(teamId)
      .then((result) => {
        setPlayersArr(
          result.response.data.filter((player) => player.status === 'ACTIVE')
        )
      })
      .catch(() => {
        throw Error('Error in player API.')
      })
  }
  const handleSave = (data) => {
    setLoadingCircular(true)
    createPlayerQuick(data)
      .then(() => {
        fetchPlayers(team.id)
        setLoadingCircular(false)
      })
      .catch((error) => {
        setLoadingCircular(false)
        throw Error(error)
      })
  }

  const handleAddPlayersOpen = () => {
    setOpenAddPlayers(true)
  }

  return (
    <Card>
      {openAddPlayers && (
        <AddPlayersDialog
          open={openAddPlayers}
          onClose={handleClose}
          handleSave={handleSave}
          loadingcircular={loadingcircular}
        />
      )}
      <CardHeader
        title={
          <Typography variant="h4" component="h4">
            {title}
          </Typography>
        }
        action={
          <>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleAddPlayersOpen}
            >
              <Translate id="team-invite.add-players" />
            </Button>
          </>
        }
      />
      <CardContent className={classes.root}>
        {playersArr.length ? (
          playersArr
            .sort((a, b) =>
              a.first_name.toLowerCase() > b.first_name.toLowerCase() ? 1 : -1
            )
            .map((item) => (
              <List key={item.id} dense disablePadding>
                <ListItem key={item.id}>
                  <ListItemAvatar>
                    {item.avatar ? (
                      <Avatar alt={item.first_name} src={item.avatar.full_url}>
                        <PersonIcon />
                      </Avatar>
                    ) : (
                      <Avatar alt={item.first_name}>
                        {item.first_name.charAt(0)}
                      </Avatar>
                    )}
                  </ListItemAvatar>
                  <ListItemText
                    id={1}
                    primary={
                      <SubLink
                        to={TEAM_PLAYER_DASHBOARD_ROUTE.populateURLParams({
                          playerId: item.uuid,
                        })}
                        fromPathname={fromPathname}
                        key={item.uuid}
                      >
                        <Typography variant="h4" component="span" noWrap>
                          {item.first_name} {item.last_name}
                        </Typography>
                      </SubLink>
                    }
                    secondary={<Translate id={`player.${item.position}`} />}
                  />
                  <ListItemSecondaryAction>
                    <Translate>
                      {({ translate }) => (
                        <>
                          <IconButton
                            edge="end"
                            aria-label="edit"
                            onClick={() => handleOpen(item)}
                          >
                            <Tooltip
                              title={translate('button.edit')}
                              placement="top"
                              arrow
                            >
                              <EditIcon fontSize="small" />
                            </Tooltip>
                          </IconButton>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => openAlertBox(item)}
                          >
                            <Tooltip
                              title={translate('button.delete')}
                              placement="top"
                              arrow
                            >
                              <DeleteIcon fontSize="small" />
                            </Tooltip>
                          </IconButton>
                        </>
                      )}
                    </Translate>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            ))
        ) : (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            sx={{ height: '100%' }}
          >
            <Box py={2}>
              <Typography variant="h5" component="h5">
                <Translate id="matches.no-players" />
              </Typography>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={handleAddPlayersOpen}
              >
                <Translate id="team-invite.add-players" />
              </Button>
            </Box>
          </Box>
        )}
      </CardContent>
      {loadingcircular && <CircularSpinner loadingcircular={loadingcircular} />}
      {openAlert && (
        <AlertDialog
          open={openAlert}
          onClose={handleClose}
          onSuccess={handleDelete}
          onCancel={handleClose}
          title={<Translate id="button.delete" />}
          subTitle={<Translate id="events.are-you-sure-you-want-to-delete" />}
          submitButtonLabel={<Translate id="button.ok" />}
          cancelButtonLabel={<Translate id="button.close" />}
        />
      )}
      {open && initialValues && (
        <PlayerEditDialog
          open={open}
          handleOpen={handleOpen}
          handleClose={handleClose}
          initialValues={{ ...initialValues }}
          setInitialValues={setInitialValues}
          updatePlayer={updatePlayer}
          updatePlayerAvatar={updatePlayerAvatar}
          changeEditablePlayer={changeEditablePlayer}
          isPlayerDashboard
        />
      )}
    </Card>
  )
}
AllPlayers.defaultProps = {
  createPlayerQuick: () => 0,
  fetchTeamPlayers: () => 0,
}

AllPlayers.propTypes = {
  players: PROP_TYPES.arrayOfObjects.isRequired,
  title: PROP_TYPES.string.isRequired,
  updatePlayer: PROP_TYPES.func.isRequired,
  updatePlayerAvatar: PROP_TYPES.func.isRequired,
  deletePlayer: PROP_TYPES.func.isRequired,
  createPlayerQuick: PROP_TYPES.func,
  fetchTeamPlayers: PROP_TYPES.func,
  team: PROP_TYPES.shape.isRequired,
}

export default compose(
  withRouter,
  connect(
    ({ team }) => ({
      team,
    }),
    {
      updatePlayer: playersModule.updatePlayer,
      deletePlayer: playersModule.deletePlayer,
      createPlayerQuick: playersModule.createPlayerQuick,
      fetchTeamPlayers: playersModule.fetchTeamPlayers,
      updatePlayerAvatar: playersModule.updatePlayerAvatar,
    }
  )
)(AllPlayers)
