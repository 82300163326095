import React from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { styled } from '@material-ui/core/styles'
import { SummaryCircle } from 'components'
import GoalkeeperIcon from 'assets/images/goalkeeper-circle.svg'
import DefenderIcon from 'assets/images/defender-circle.svg'
import MidfieldIcon from 'assets/images/midfield-circle.svg'
import StrikerIcon from 'assets/images/striker-circle.svg'
import LeftFootIcon from 'assets/images/foot-left.svg'
import RightFootIcon from 'assets/images/foot-right.svg' // @todo: add right foot icon
import CaptainIcon from 'assets/images/player-captain.svg'
import { Card } from '@material-ui/core'

const CardMui = styled(Card)({
  height: '100%',
})

const Number = styled('div')({
  display: 'inline-block',
  padding: '5px',
  marginRight: '10px',
  border: '1px solid #fff',
  borderRadius: '50%',
  fontSize: '21px',
  lineHeight: '24px',
  letterSpacing: '0.26px',
  height: '36px',
  width: '36px',
  textAlign: 'center',
})

const Name = styled('div')({
  display: 'inline-block',
  fontSize: '24px',
  lineHeight: '28px',
  letterSpacing: '0.3px',
  fontWeight: 300,
})

const ItemsWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  height: '100%',
})

const Title = styled('div')({
  fontSize: '13px',
  lineHeight: '20px',
  textAlign: 'center',
  textTransform: ({ capitalize }) => (capitalize ? 'capitalize' : 'none'),
})

const CirclesWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-around',
  '> div > div:first-child': {
    border: '2px solid #fff',
  },
  '> div > div:last-child': {
    fontSize: '13px',
  },
})

const POSITION_TO_ICON_MAPPER = {
  goalkeeper: GoalkeeperIcon,
  defender: DefenderIcon,
  midfield: MidfieldIcon,
  striker: StrikerIcon,
}
// @todo: Translation position
const PlayerSummaryWidget = ({
  number,
  first_name: firstName,
  last_name: lastName,
  captain,
  position,
  foot,
  totalMinutesPlayed,
}) => (
  <CardMui fullHeight>
    <div>
      <Number>{number}</Number>
      <Name>
        {firstName} {lastName}
      </Name>
    </div>
    <ItemsWrapper>
      {captain === 1 && (
        <div>
          <img src={CaptainIcon} alt="" width="120" height="120" />
          <Title>
            <Translate id="form.team-captain" />
          </Title>
        </div>
      )}
      <div>
        <img
          src={POSITION_TO_ICON_MAPPER[position]}
          alt=""
          width="120"
          height="120"
        />
        <Title capitalize>{position}</Title>
      </div>
      <div>
        <CirclesWrapper>
          <SummaryCircle
            value={totalMinutesPlayed}
            ratio={totalMinutesPlayed}
            title="Total Playing Time"
          />
        </CirclesWrapper>
      </div>
      <div>
        <img
          src={foot === 'r' ? RightFootIcon : LeftFootIcon}
          alt=""
          width="120"
          height="120"
        />
        <Title>
          {foot === 'r' ? (
            <Translate id="team.right-footed" />
          ) : (
            <Translate id="team.left-footed" />
          )}{' '}
        </Title>
      </div>
    </ItemsWrapper>
  </CardMui>
)

PlayerSummaryWidget.propTypes = {
  number: PropTypes.number.isRequired,
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  captain: PropTypes.number.isRequired,
  totalMinutesPlayed: PropTypes.number.isRequired,
  position: PropTypes.string.isRequired,
  foot: PropTypes.string.isRequired,
}

export default PlayerSummaryWidget