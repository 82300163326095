import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
} from 'utils/axios'

export const uploadPlayerAttachment = (playerUuid, data, config = {}) => {
  const url = `v2/players/${playerUuid}/attachments`

  return postRequest(url, data, config)
}

export const getPlayerDocuments = (playerUuid) => {
  const url = `v2/players/${playerUuid}/attachments`

  return getRequest(url)
}

export const updateAttachmentVisibility = (attachmentUuid, data) => {
  const url = `v2/player-attachments/${attachmentUuid}`

  return patchRequest(url, data)
}

export const deletePlayerAttachment = (attachmentUuid) => {
  const url = `v2/player-attachments/${attachmentUuid}`

  return deleteRequest(url)
}

export const createAttachmentTemporarilyUrl = (attachmentUuid, data = {}) => {
  const url = `v2/player-attachments/${attachmentUuid}/generate-url`

  return postRequest(url, data)
}

export const pausePlayer = (playerUuid, data = {}) => {
  const url = `v2/players/${playerUuid}/pause`

  return postRequest(url, data)
}

export const unpausePlayer = (playerUuid, data = {}) => {
  const url = `v2/players/${playerUuid}/unpause`

  return postRequest(url, data)
}
