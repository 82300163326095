import React from 'react'
import { withRouter } from 'react-router-dom'
import TeamInsightsContainer from './TeamPlayersDataContainer'
import PROP_TYPES from 'constants/propTypes'

const TeamPlayersData = ({
  changeTab,
}) => (
    <TeamInsightsContainer changeTab={changeTab} />
)

TeamPlayersData.propTypes = {
  changeTab: PROP_TYPES.func.isRequired,
}

export default withRouter(TeamPlayersData)
