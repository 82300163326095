import React from 'react'
import AnnouncementIcon from '@material-ui/icons/Announcement'
import { Translate } from 'react-localize-redux'
import SubLink from 'containers/SubLink'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { TeaserHead, TeaserFab, TeaserButton } from './styledComponents'
import PROP_TYPES from 'constants/propTypes'
import { TEAM_SUBSCRIPTION_UPGRADE_ROUTE } from 'constants/routes'
import { DEFAULT_CLUB_ID } from 'constants/club'

const TeaserHeader = ({
  children,
  buttonLabel,
  isMessageTag,
  currentClubId,
}) => {
  return (
    <>
      {currentClubId === DEFAULT_CLUB_ID && (
        <TeaserHead>
          <TeaserFab variant="extended" color="primary">
            <AnnouncementIcon />
          </TeaserFab>
          {isMessageTag === true ? (
            <Translate id="teasers.message-pro" />
          ) : (
            <Translate id="teasers.message-plus" />
          )}
          <TeaserButton
            component={SubLink}
            to={TEAM_SUBSCRIPTION_UPGRADE_ROUTE}
            variant="contained"
            color="default"
          >
            {buttonLabel}
          </TeaserButton>
          {children}
        </TeaserHead>
      )}
    </>
  )
}

TeaserHeader.defaultProps = {
  buttonLabel: <Translate id="teasers.button" />,
  isMessageTag: true,
}

TeaserHeader.propTypes = {
  children: PROP_TYPES.children.isRequired,
  isMessageTag: PROP_TYPES.bool,
  buttonLabel: PROP_TYPES.string,
  currentClubId: PROP_TYPES.number.isRequired,
}

export default compose(
  connect(
    ({ clubs }) => ({
      currentClubId: clubs.current.id,
    }),
    {}
  )
)(TeaserHeader)
