import React, { useState, useCallback, useMemo } from 'react'
import { connect } from 'react-redux'
import { ShareMuiIcon } from 'containers'
import { PlayIcon, ProcessingIcon } from 'components'
import { getToken } from 'utils/localstorage'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {
  Grid,
  Card,
  CardActionArea,
  CardActions,
  CardMedia,
  Typography,
  Box,
  Chip,
  Divider,
} from '@material-ui/core'
import { sequences as sequencesModule } from '../redux/modules'
import HeartIcon from './HeartIcon'
import SequenceModal from './SequenceModal'
import PROP_TYPES from 'constants/propTypes'
import SEQUENCE_TAGS from 'constants/sequenceTags'

const MuiCard = withStyles((theme) => ({
  root: {
    border: '1px solid #e8e8e8',
    '& .MuiCardContent-root': {
      padding: theme.spacing(2, 2, 0),
    },
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
}))(Card)
const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.up('xl')]: {
    root: {
      '& .MuiGrid-grid-xl-2': {
        maxWidth: '25%',
        flexBasis: '25%',
      },
    },
  },
  media: {
    height: 134,
  },
  cardActions: {
    paddingLeft: theme.spacing(2),
  },
  title: {
    flex: '1 1 0',
  },
  PlayCircle: {
    position: 'absolute',
    top: '27%',
    height: 65,
    width: 65,
    padding: 0,
    backgroundColor: '#fff',
    borderRadius: '50%',
    left: theme.spacing(0),
    right: theme.spacing(0),
    margin: theme.spacing(0, 'auto'),
    '& .MuiSvgIcon-root': {
      fontSize: 67,
    },
  },
}))

const SequencesList = ({
  sequences,
  // orderState,
  players,
  favoritesSequences,
  updateSequence,
  shareSequence,
  deleteSequence,
}) => {
  //--------------
  const fullUrl = window.location.href
  const seasonString = fullUrl.match(/season(.*)videos/)
  const [matchData, setMatchData] = useState(0)
  if (seasonString !== null) {
    const seasonId = seasonString[1]
    const token = getToken()
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    }
    const url = `/seasons${seasonId}matches`
    // @todo check if we can remove this api call and use redux module
    fetch(encodeURI(`${window.REACT_APP_API_ENDPOINT}${url}`), {
      method: 'GET',
      headers,
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json()
        }
        throw Error
      })
      .then((res) => {
        if (matchData === 0) {
          setMatchData(res.data)
        }
      })
      .catch((e) => {
        /* eslint-disable-next-line */
      })
  }
  //--------------
  const [selectedSequenceId, changeSelectedSequenceId] = useState(null)

  const onHide = useCallback(() => changeSelectedSequenceId(null), [])
  const classes = useStyles()
  const selectedSequence = useMemo(
    () => sequences.findById(selectedSequenceId),
    [selectedSequenceId]
  )

  const getIsFavorite = (id) => Boolean(favoritesSequences.findById(id))

  return (
    <>
      {selectedSequenceId && (
        <SequenceModal
          onHide={onHide}
          sequence={selectedSequence}
          updateSequence={updateSequence}
          deleteSequence={deleteSequence}
          players={players}
          matches={matchData}
          open={Boolean(selectedSequenceId)}
        />
      )}
      <Grid container spacing={2} className={classes.root}>
        {sequences.map(
          ({
            id,
            thumbnail_url: thumbnail,
            processed,
            error_details: errorDetails,
            tag,
            title,
          }) => {
            const tagData = SEQUENCE_TAGS.find(({ value }) => value === tag)
            tagData.color = 'var(--dark-grey)'

            return (
              <>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={id}>
                  <MuiCard>
                    <CardActionArea>
                      <CardMedia
                        className={classes.media}
                        image={processed === 1 && thumbnail}
                        processed={processed}
                      />
                      {processed === 1 ? (
                        <CardActionArea className={classes.PlayCircle}>
                          <PlayIcon
                            onClick={() => changeSelectedSequenceId(id)}
                          />
                        </CardActionArea>
                      ) : (
                        <ProcessingIcon
                          placeholder={processed === -1 && errorDetails}
                        />
                      )}
                    </CardActionArea>

                    <CardActions className={classes.cardActions}>
                      <Typography
                        gutterBottom
                        className={classes.title}
                        variant="h4"
                        component="h4"
                        noWrap
                      >
                        {title}
                      </Typography>

                      {processed === 1 ? (
                        <>
                          <HeartIcon
                            isActive={getIsFavorite(id)}
                            entity="sequences"
                            entityId={id}
                            isPositionRelative
                          />
                          <ShareMuiIcon
                            share={shareSequence}
                            id={id}
                            shareBy="Sequence"
                          />
                        </>
                      ) : (
                        <ProcessingIcon
                          placeholder={processed === -1 && errorDetails}
                        />
                      )}
                    </CardActions>
                    <Divider />
                    <Box my={1} px={2}>
                      {tagData && (
                        <Chip
                          label={tagData.title}
                          size="small"
                          color="primary"
                          component={Box}
                          mr={0.5}
                        />
                      )}
                    </Box>
                  </MuiCard>
                </Grid>
              </>
            )
          }
        )}
      </Grid>
    </>
  )
}

SequencesList.defaultProps = {
  sequences: [],
  players: [],
  favoritesSequences: [],
}

SequencesList.propTypes = {
  sequences: PROP_TYPES.arrayOfObjects,
  players: PROP_TYPES.arrayOfObjects,
  favoritesSequences: PROP_TYPES.arrayOfObjects,
  updateSequence: PROP_TYPES.func.isRequired,
  shareSequence: PROP_TYPES.func.isRequired,
  deleteSequence: PROP_TYPES.func.isRequired,
}

export default connect(
  ({ players: playersState, favorites }, { players }) => ({
    players: players || playersState.items,
    favoritesSequences: favorites.items.sequences,
  }),
  {
    updateSequence: sequencesModule.updateSequence,
    shareSequence: sequencesModule.shareSequence,
    deleteSequence: sequencesModule.deleteSequence,
  }
)(SequencesList)
