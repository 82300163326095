import { styled } from '@material-ui/core/styles'

const Drill = styled('div')({
  '.MuiSnackbar-root': {
    '.MuiAlert-root': {
      '.drillImg': {
        marginRight: '0.7rem',
      },
      '.title': {
        marginLeft: 0,
      },
      '.extraAction': {
        display: 'flex',
        '.time-content': {
          display: 'flex',
          marginLeft: '0.3rem',
          alignItems: 'center',
          '.MuiCardMedia-img': {
            width: '1rem',
            height: '1rem',
            marginRight: '0.3rem',
          },
        },
      },
    },
  },
  '&:hover': {
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.25)',
  },
})

export default Drill