import { useEffect } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import { withLocalize } from 'react-localize-redux'
import globalTranslations from 'translations/global.json'
import { getLocale } from 'utils/localstorage'
import languages from 'constants/languages'

const LocalizeInitializer = ({ children, initialize, setActiveLanguage }) => {
  useEffect(() => {
    initialize({
      languages,
      translation: globalTranslations,
      options: { renderToStaticMarkup },
    })
    setActiveLanguage(getLocale())
  }, [])

  return children
}

export default withLocalize(LocalizeInitializer)
