import React from 'react'
import { Translate } from 'react-localize-redux'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Paper, Tabs, Tab, Toolbar, Typography, Box } from '@material-ui/core'
import hasSubscription from 'utils/hasSubscription'
import {
  ArticlesCarousel,
  DrillsCarousel,
  TrainingPackagesCarousel,
  VideosCarousel,
  SequencesCarousel,
} from './components'
import { BASIC, PLUS } from 'constants/plans'

const MuiBox = withStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}))(Box)
const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    minHeight: 50,
    borderBottom: '1px solid #e8e8e8',
  },
  title: {
    flex: '1 1 100%',
  },
}))

const FavoritesContainer = () => {
  const classes = useToolbarStyles()

  const [value, setValue] = React.useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <Toolbar className={classes.root}>
        <Typography variant="h4" component="h4">
          <Translate id="navbar.favorites" />
        </Typography>
      </Toolbar>
      <Paper className={classes.paper}>
        <Toolbar className={classes.root}>
          <>
            <Tabs
              value={value}
              className={classes.title}
              onChange={handleChange}
              variant="scrollable"
              indicatorColor="primary"
              scrollButtons="auto"
            >
              <Tab label={<Translate id="home.articles" />} />
              <Tab label={<Translate id="home.drills" />} />
              <Tab label={<Translate id="navbar.training_packages" />} />
              <Tab label={<Translate id="videos.videos" />} hidden={!!(hasSubscription() === BASIC || hasSubscription() === PLUS)}/>
              <Tab label={<Translate id="videos.sequences" />} hidden={!!(hasSubscription() === BASIC || hasSubscription() === PLUS)}/>
            </Tabs>
          </>
          {/* )} */}
        </Toolbar>
        <MuiBox>
          {value === 0 && <ArticlesCarousel changeTab={setValue} />}
          {value === 1 && <DrillsCarousel changeTab={setValue} />}
          {value === 2 && <TrainingPackagesCarousel changeTab={setValue} />}
          {value === 3 && <VideosCarousel changeTab={setValue} />}
          {value === 4 && <SequencesCarousel changeTab={setValue} />}
        </MuiBox>
      </Paper>
    </>
  )
}

export default FavoritesContainer
