import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import { trainingPackages } from 'redux/modules'
import Loader from './Loader'
import PROP_TYPES from 'constants/propTypes'

const TrainingPackagesLoader = ({
  children,
  params,
  fetchTrainingPackages,
  activeLanguage: { code: locale },
  ...restProps
}) => (
  <Loader
    fetchMethod={() =>
      fetchTrainingPackages({ ...params, locale, global_filter: 1 })
    }
    triggers={[locale]}
    {...restProps}
  >
    {children}
  </Loader>
)

TrainingPackagesLoader.defaultProps = {
  params: {},
}

TrainingPackagesLoader.propTypes = {
  children: PROP_TYPES.children.isRequired,
  params: PROP_TYPES.shape(),
  fetchTrainingPackages: PROP_TYPES.func.isRequired,
  activeLanguage: PROP_TYPES.shape().isRequired,
}

export default compose(
  connect(
    null,
    { fetchTrainingPackages: trainingPackages.fetchTrainingPackages }
  ),
  withLocalize
)(TrainingPackagesLoader)
