import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import { drills } from 'redux/modules'
import Loader from './Loader'
import PROP_TYPES from 'constants/propTypes'

const DrillsCategoriesLoader = ({
  children,
  fetchDrillsCategories,
  activeLanguage: { code: locale },
  ...restProps
}) => (
  <Loader
    fetchMethod={() => fetchDrillsCategories({ locale })}
    triggers={[locale]}
    {...restProps}
  >
    {children}
  </Loader>
)

DrillsCategoriesLoader.propTypes = {
  children: PROP_TYPES.children.isRequired,
  fetchDrillsCategories: PROP_TYPES.func.isRequired,
  activeLanguage: PROP_TYPES.shape().isRequired,
}

export default compose(
  connect(
    null,
    { fetchDrillsCategories: drills.fetchDrillsCategories }
  ),
  withLocalize
)(DrillsCategoriesLoader)
