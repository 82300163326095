import React from 'react';
import PropTypes from 'prop-types'
import { styled } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const PlanTitle = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '5px',
  fontSize: '18px',
  fontWeight: 300,
  letterSpacing: '0.3px',
  color: '#545454',
  lineHeight: '28px',
})

const PlanPrice = styled('div')({
  display: 'flex',
  width: '100%',
  flexWrap: 'wrap',
  alignItems: 'baseline',
  justifyContent: 'center',
  marginBottom: '5px',
  fontSize: '20px',
  fontWeight: 500,
  letterSpacing: '0.3px',
  color: '#111',
  lineHeight: '28px',
  '& span': {
    fontSize: '12px',
  },
  '& .radio-inp': {
    width: '16px',
    height: '16px',
    marginRight: '6px',
  },
});

const PlanList = styled('ul')({
  alignItems: 'center',
  borderTop: '1px solid #d7d4d4',
  width: '100%',
  padding: '10px 0 0',
  flexWrap: 'wrap',
  margin: '0 0 5px 0',
  listStyle: 'none',
})

const ListItem = styled('li')({
  margin: '5px 0',
  fontSize: '12px',
  fontWeight: 400,
  flex: 1,
  letterSpacing: '0.3px',
  color: '#545454',
  lineHeight: '20px',
  marginBottom: '8px',
  paddingLeft: '18px',
  position: 'relative',
  '& :before': {
    content: '"x"',
    position: 'absolute',
    top: '1px',
    left: '0',
    fontSize: '14px',
    marginRight: '5px',
  },
  '& .active': {
    color: '#111',
    fontWeight: 500,
    '& :before': {
      content: '"\\2713"',
    },
  },
})

const PlanButton = styled(Button)({
  borderRadius: '20px',
  marginBottom: '0',
  display: 'flex',
  padding: '0',
  justifySelf: 'flex-end',
  backgroundColor: '#0e4b4d !important',
  borderColor: '#0e4b4d !important',
  justifyContent: 'center',
  marginTop: 'auto',
  '&.active': {
    backgroundColor: '#fff !important',
    color: '#0e4b4d !important',
  },
})

const Plan = ({ children, title, className, ...rest }) => (
  <div className={className} {...rest}>
    <PlanTitle>{title}</PlanTitle>
    {children}
  </div>
)

Plan.Price = PlanPrice
Plan.Monthly = PlanPrice
Plan.Year = PlanPrice
Plan.List = PlanList
Plan.ListItem = ListItem
Plan.Button = PlanButton

Plan.defaultProps = {
  children: <div />,
}

Plan.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  ]),
  className: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

const StyledPlan = styled(Plan)({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '30px',
  background: '#f5f5f5',
  borderRadius: '8px',
  boxShadow: '-2px 2px 27px 0 rgba(176, 176, 176, 0.5)',
  padding: '20px',
  height: '100%',
})

export default StyledPlan