import React from 'react'
import { withRouter } from 'react-router-dom'
import PricingDashboardContainer from './PricingDashboardContainer'
import PROP_TYPES from 'constants/propTypes'

const PricingDashboard = ({ changeTab }) => (
  <PricingDashboardContainer changeTab={changeTab} />
)
PricingDashboard.propTypes = {
  changeTab: PROP_TYPES.func.isRequired,
}
export default withRouter(PricingDashboard)
